import React from "react";
import { getLang } from "app/feature/constants";
import ProductSelect from "../../select/productSelect";

export default function ProductSearchComponent({
  handleFormChange,
  lang,
  productDropdown,
  isFetchingProduct,
  productsSelected,
  disabled,
  editTemplate
}) {
  return (
    <ProductSelect
      searchable={true}
      value={productsSelected}
      placeholder={getLang(lang, 'placeholder.SELECT_A_PRODUCT')}
      searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
      isClearable={true}
      emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
      dropdownItem={productDropdown}
      isFetching={isFetchingProduct}
      handleChange={(value) => {
        let temp = [...productsSelected];
        if (temp.length === 0) {
          temp.push(value);
        }
        else {
          const isProductExist = temp.find((product) => product.id === value.id);

          if (isProductExist === undefined) {
            temp.push(value);
          }
          else {
            let filter = temp.filter(product => product.id !== value.id);
            temp = [...filter];
          }
        }

        handleFormChange(temp);
      }}
      handleDeleteChip={(value) => {
        let temp = [...productsSelected];

        const isProductExist = temp.find(product => product.id === value.id);
        if (isProductExist) {
          let filter = temp.filter(product => product.id !== value.id);
          temp = [...filter];

          handleFormChange(temp);
        }
      }}
      handleClear={() => {
        handleFormChange([]);
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      isEmpty={productDropdown.length === 0}
      disabled={disabled}
      editTemplate={editTemplate}
    />
  );
}
