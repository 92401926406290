import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider,
  Button
} from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { AddIcon } from "components/icon";
import LuckyDrawListingPanel from "../../components/panel/luckyDrawListingPanel";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function LuckyDrawPageComponent({
  hasLuckyDrawAddAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  lang
}) {
  const classes = useStyles();
  const luckyDrawRef = useRef();

  const openCreateLuckyDrawModal = () => {
    if(hasLuckyDrawAddAccessRight){
      luckyDrawRef.current.openCreateLuckyDrawModal();
    }
  };

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              {getLang(lang,"label.LUCKY_DRAW")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {tabLabels[activeIndex]}
              </Typography>
            </Box>
          </Box>
          {
            hasLuckyDrawAddAccessRight
              ?
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                }
                onClick={openCreateLuckyDrawModal}
              >
                {getLang(lang,"label.LUCKY_DRAW")}
              </Button>
              : null
          }
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={activeIndex} index={0}>
              <LuckyDrawListingPanel ref={luckyDrawRef} />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
