import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Divider,
  Button,
  Backdrop,
  RadioGroup,
  Radio,
  Paper,
  Tabs,
} from "@material-ui/core";
import { useHistory } from "react-router-dom"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import SuccessIllustration from "assets/img/assign-success-illustration.png";
// import AntSwitch from "components/switch";
import InputTextField from "components/input/inputTextField";
import UnassignedSerialNumberTable from "../../table/unassignSerialNumber";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  SERIAL_NUMBER_TYPE_NUMBER,
  SERIAL_NUMBER_TYPE_ALPHANUMERIC,
  ROOT,
} from "modules/serial-number";
import { getLang } from "app/feature/constants";
import BranchSingleFlatSelect from "components/select/branchSingleFlatSelect";
import DefaultImg from "assets/img/img-default.png";
import clsx from "clsx";
import CustomTab from "components/tab";
import CustomRangePanel from "../../panel/customRangePanel";
import SnAvailabilityListTable from "../../table/snAvailabilityListTable";
import ConfirmationDialog from "components/dialog/confirmation";
import CustomSerialNumberRangePanel from "../../panel/customSerialNumberRangePanel";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  productPhoto: {
    marginRight: 10,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: 80,
    height: 80,
    borderRadius: 4,
    flexShrink: 0,
    "& img": {
      height: "100%",
      width: "100%",
      borderRadius: 4,
    },
  },

  btnSecondary: {
    color: theme.palette.common.white,
  },
  form: {
    backgroundColor: "#F2F4F7",
  },
  successDesc: {
    textAlign: "center",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  illustration: {
    width: 300,
  },
  incompleteIndicator: {
    color: "#98A2B3",
    fontSize: 20,
    marginLeft: 8,
  },
  completeIndicator: {
    color: "#32D583",
    fontSize: 20,
    marginLeft: 8,
  },
  disable: {
    opacity: 0.5,
  },
  enable: {
    opacity: 1,
  },
  unselectedBox: {
    border: "2px solid #D0D5DD",
    borderRadius: 8,
    padding: "8px 16px 8px 8px",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#FFFFFF",
  },
  selectedBox: {
    border: "2px solid #6AAF68",
    borderRadius: 8,
    padding: "8px 16px 8px 8px",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#F7FBF7",
  },
  typeRadioGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(1),
  },
  quantityInput: {
    border: "2px solid #D0D5DD",
    borderRadius: "8px !important",
    width: 300,
    maxWidth: "100%",
    backgroundColor: "white !important",
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

export default function AssignSerialNumberDialogComponent({
  isOpen,
  handleClose,
  product,
  handleSubmit,
  success,
  isLoading,
  lang,
  handleRetry,
  fetchAvailableSN,
  selectedBranch,
  branchList,
  checkSNAvailability,
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  snAvailabilityList,
  resetAvailabilityList,
  isSNexceedRange,
}) {
  const history = useHistory();
  const classes = useStyle();
  const numberReg = /^-?\d*(\.\d*)?$/;

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: (
        <Typography
          variant="button"
          style={{ textTransform: "none" }}
          children={label}
        />
      ),
    };
  }

  const tabLabels = [
    getLang(lang, "label.SEQUENCE_NUMBER"),
    getLang(lang, "label.SERIAL_NUMBER"),
  ];

  return (
    <Dialog
      maxWidth={success ? "sm" : "lg"}
      open={isOpen}
      onClose={() => { }}
      aria-labelledby="modal-assign-serial-number-to-product"
      aria-describedby="modal-assign-serial-number-to-product"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.ASSIGN_SERIAL_NUMBER")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Formik
        initialValues={{
          product: product,
          branch: null,
          type: "",
          format: "",
          quantity: "",
          isCustomQuantity: false,
          availQty: 0,
          generateRangeType: "sequence_number",
          generateRanges: [
            {
              start: "",
              end: "",
            },
          ],
          forceReassign: false,
          preview: false,
          isSerialNumber: false,
        }}
        validationSchema={Yup.object({
          format: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          quantity: Yup.number().when("format", (format, schema) => {
            if (format !== "" && format !== undefined) {
              return schema
                .min(
                  1,
                  getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
                    value: 1,
                  })
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                );
            }
          }),
          generateRanges: Yup.array().of(
            Yup.object().shape({
              start: Yup.number().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              ),
              end: Yup.number().min(
                Yup.ref("start"),
                getLang(lang, "message.error.FORM_VALIDATE_SN_SELECTION_RANGES")
              ),
            })
          ),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            {success ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                px={4}
                pb={4}
              >
                <img
                  className={classes.illustration}
                  src={SuccessIllustration}
                  alt="success-assign-serial-numbers"
                />
                <Typography
                  className={classes.successDesc}
                  variant="body2"
                  style={{ paddingBottom: 15 }}
                >
                  <span className={classes.bold}>
                    {`${formik.values.quantity}`}{" "}
                    {getLang(lang, "label.SERIAL_NUMBER_LOWERCASE")}
                  </span>
                  &nbsp;{getLang(lang, "label.WILL_BE_ASSIGNED_TO")}&nbsp;
                  <span className={classes.bold}>{product.name}</span>
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.btnSecondary }}
                  disabled={isLoading}
                  onClick={() => history.push(`${ROOT}?tab=assign`)}
                >
                  {getLang(lang, "label.VIEW_PROGRESS")}
                </Button>
              </Box>
            ) : (
              <>
                <Box style={{ overflowY: "auto" }}>
                  <Box display="flex" alignItems="center" p={2}>
                    <Box className={classes.productPhoto}>
                      {!product?.picture ? (
                        <img src={DefaultImg} alt="emptyProduct" />
                      ) : (
                        <img src={product.picture} alt={product.name} />
                      )}
                    </Box>
                    <Typography variant="h6" style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                      <b>{product?.name || "-"}</b>
                    </Typography>
                  </Box>
                  <Box className={classes.form}>
                    <Box p={2}>
                      <Box>
                        <Box mb={1} display="flex" alignItems="center">
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            <b>1. {getLang(lang, "label.SELECT_BRANCH")}</b>
                          </Typography>
                          <CheckCircleRoundedIcon
                            className={clsx({
                              [classes.incompleteIndicator]:
                                !formik.values.branch,
                              [classes.completeIndicator]:
                                !!formik.values.branch,
                            })}
                          />
                        </Box>
                        <Box ml={2}>
                          <BranchSingleFlatSelect
                            searchable={true}
                            value={formik.values.branch}
                            placeholder={getLang(
                              lang,
                              "placeholder.SELECT_A_BRANCH"
                            )}
                            searchPlaceholder={getLang(
                              lang,
                              "placeholder.SEARCH_BRANCH_NAME"
                            )}
                            isClearable={false}
                            emptyMessage={getLang(
                              lang,
                              "message.info.NO_BRANCH_FOUND"
                            )}
                            dropdownItem={branchList}
                            handleChange={(value) => {
                              formik.setFieldValue("branch", value);
                              fetchAvailableSN(formik, value);
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            style={{
                              backgroundColor: "white",
                              borderRadius: 8,
                              borderWidth: 2,
                            }}
                            isEmpty={!formik.values.branch}
                          />
                        </Box>
                      </Box>
                      <Box my={3}>
                        <Divider />
                      </Box>
                      <Box
                        className={clsx({
                          [classes.disable]: !formik.values.branch,
                          [classes.enable]: !!formik.values.branch,
                        })}
                      >
                        <Box mb={1} display="flex" alignItems="center">
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            <b>
                              2.{" "}
                              {getLang(lang, "label.SELECT_SERIAL_NUMBER_TYPE")}
                            </b>
                          </Typography>
                          <CheckCircleRoundedIcon
                            className={clsx({
                              [classes.incompleteIndicator]:
                                !formik.values.type,
                              [classes.completeIndicator]: !!formik.values.type,
                            })}
                          />
                        </Box>
                        <Box ml={2}>
                          <Box>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              value={formik.values.type}
                              onChange={(event) => {
                                formik.setFieldValue("type", event.target.value);
                              }}
                              className={classes.typeRadioGroup}
                              disabled={!formik.values.branch}
                            >
                              <Box
                                className={clsx({
                                  [classes.unselectedBox]:
                                    formik.values.type !==
                                    SERIAL_NUMBER_TYPE_NUMBER,
                                  [classes.selectedBox]:
                                    formik.values.type ===
                                    SERIAL_NUMBER_TYPE_NUMBER,
                                })}
                                onClick={(event) => {
                                  if (formik.values.branch) {
                                    formik.setFieldValue(
                                      "type",
                                      SERIAL_NUMBER_TYPE_NUMBER
                                    );
                                    formik.setFieldValue("isCustomQuantity", false);
                                    formik.setFieldValue("generateRangeType", 'sequence_number');
                                    formik.setFieldValue("quantity", "");
                                  }
                                }}
                              >
                                <Radio
                                  value={SERIAL_NUMBER_TYPE_NUMBER}
                                  name="pattern-selection"
                                  size="small"
                                  style={{ padding: "6px 10px 6px 6px" }}
                                  disabled={!formik.values.branch}
                                />
                                <Box>
                                  <Typography variant="body1">
                                    {getLang(lang, "label.PATTERN_SELECTION")}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {getLang(
                                      lang,
                                      "paragraph.CHOOSE_PATTERN_ASSIGN"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                className={clsx({
                                  [classes.unselectedBox]:
                                    formik.values.type !==
                                    SERIAL_NUMBER_TYPE_ALPHANUMERIC,
                                  [classes.selectedBox]:
                                    formik.values.type ===
                                    SERIAL_NUMBER_TYPE_ALPHANUMERIC,
                                })}
                                onClick={(event) => {
                                  if (formik.values.branch) {
                                    formik.setFieldValue(
                                      "type",
                                      SERIAL_NUMBER_TYPE_ALPHANUMERIC
                                    );
                                    formik.setFieldValue("isCustomQuantity", false);
                                    formik.setFieldValue("generateRangeType", 'sequence_number');
                                    formik.setFieldValue("quantity", "");
                                  }
                                }}
                              >
                                <Radio
                                  value={SERIAL_NUMBER_TYPE_ALPHANUMERIC}
                                  name="random-alphanumeric"
                                  size="small"
                                  style={{ padding: "6px 10px 6px 6px" }}
                                  disabled={!formik.values.branch}
                                  onClick={() => {
                                    formik.setFieldValue('generateRangeType', 'sequence_number');
                                  }}
                                />
                                <Box>
                                  <Typography
                                    variant="body1"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {getLang(lang, "label.RANDOM_ALPHANUMERIC")}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {getLang(
                                      lang,
                                      "paragraph.SPECIFY_DESIRED_CODE_LENGTH"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </RadioGroup>
                          </Box>
                          {!!formik.values.type && (
                            <Box mt={2}>
                              <UnassignedSerialNumberTable
                                activeType={formik.values.type}
                                selectedBranch={selectedBranch}
                                handleRetry={handleRetry}
                                handleChange={(value) => {
                                  formik.setFieldValue("format", value.format);
                                  formik.setFieldValue(
                                    "availQty",
                                    value.quantity
                                  );
                                  formik.setFieldValue("quantity", "");
                                  formik.setFieldTouched(
                                    "quantity",
                                    false,
                                    false
                                  );
                                  formik.setFieldValue("generateRanges", [
                                    {
                                      start: "",
                                      end: "",
                                    },
                                  ]);
                                  if (snAvailabilityList.length) {
                                    resetAvailabilityList();
                                  }
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box my={3}>
                        <Divider />
                      </Box>
                      <Box
                        className={clsx({
                          [classes.disable]: !formik.values.format,
                          [classes.enable]: !!formik.values.format,
                        })}
                      >
                        <Box mb={1} display="flex" alignItems="center">
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            <b>3. {getLang(lang, "label.ASSIGN_METHOD")}</b>
                          </Typography>
                          <CheckCircleRoundedIcon
                            className={clsx({
                              [classes.incompleteIndicator]:
                                !formik.values.quantity,
                              [classes.completeIndicator]:
                                !!formik.values.quantity,
                            })}
                          />
                        </Box>
                        <Box ml={2} mb={1}>
                          <RadioGroup
                            aria-labelledby="sn-type-radio-buttons-group-label"
                            defaultValue="none"
                            name="sn-type-radio-buttons-group"
                            value={formik.values.isCustomQuantity}
                            onChange={(event) => {
                              // formik.setFieldValue(
                              //   "isCustomQuantity",
                              //   event.target.value === "true"
                              // );
                            }}
                            style={{ flexDirection: "row" }}
                            disabled={!formik.values.format}
                          >
                            <Box
                              className={clsx({
                                [classes.unselectedBox]:
                                  formik.values.isCustomQuantity ||
                                  !formik.values.format,
                                [classes.selectedBox]:
                                  !formik.values.isCustomQuantity &&
                                  !!formik.values.format,
                              })}
                              style={{ alignItems: "center" }}
                              mr={1}
                              onClick={(event) => {
                                formik.setFieldValue(
                                  "isCustomQuantity",
                                  false
                                );
                                formik.setFieldValue("quantity", "");
                              }}
                            >
                              <Radio
                                value={false}
                                name="enter-quantity"
                                disabled={!formik.values.format}
                                size="small"
                                style={{ padding: "2px 10px 2px 6px" }}
                              />
                              <Box>
                                <Typography variant="body2">
                                  {getLang(lang, "label.SEQUENTIAL_ASSIGN_BY_QUANTITY")}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              className={clsx({
                                [classes.unselectedBox]:
                                  !formik.values.isCustomQuantity ||
                                  !formik.values.format,
                                [classes.selectedBox]:
                                  formik.values.isCustomQuantity &&
                                  !!formik.values.format,
                              })}
                              style={{ alignItems: "center" }}
                              onClick={(event) => {
                                formik.setFieldValue(
                                  "isCustomQuantity",
                                  true
                                );
                                formik.setFieldValue("quantity", "");
                              }}
                            >
                              <Radio
                                value={true}
                                name="custom-range"
                                disabled={!formik.values.format}
                                size="small"
                                style={{ padding: "2px 10px 2px 6px" }}
                              />
                              <Box>
                                <Typography variant="body2">
                                  {getLang(lang, "label.ASSIGN_BY_CUSTOM_RANGE")}
                                </Typography>
                              </Box>
                            </Box>
                          </RadioGroup>
                        </Box>
                        {!formik.values.isCustomQuantity ? (
                          <Paper
                            elevation={0}
                            style={{ padding: 16, marginLeft: 16 }}
                          >
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="space-between"
                            >
                              <Box display="flex" alignItems="flex-start">
                                <Box mr={1}>
                                  <InputTextField
                                    hiddenLabel={true}
                                    variant="filled"
                                    fullWidth
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.ENTER_QUANTITY"
                                    )}
                                    type="number"
                                    size="small"
                                    inputProps={{
                                      min: 1,
                                    }}
                                    InputProps={{
                                      className: classes.quantityInput,
                                    }}
                                    disabled={!formik.values.format}
                                    {...formik.getFieldProps("quantity")}
                                    onChange={({ target: { value } }) => {
                                      if (
                                        !isNaN(value) &&
                                        numberReg.test(value)
                                      ) {
                                        formik.setFieldValue("quantity", value);
                                      }
                                    }}
                                    error={
                                      formik.touched.quantity &&
                                        formik.errors.quantity
                                        ? true
                                        : false
                                    }
                                    helperText={ErrorMessage({
                                      name: "quantity",
                                    })}
                                  />
                                </Box>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    formik.setFieldValue(
                                      "quantity",
                                      formik.values.availQty
                                    )
                                  }
                                  disableElevation
                                  style={{
                                    borderRadius: 8,
                                    height: 47,
                                    textTransform: "capitalize",
                                  }}
                                  disabled={!formik.values.format}
                                >
                                  {getLang(lang, "label.MAX")}
                                </Button>
                              </Box>
                              <Box mt={0.75}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  classes={{ root: classes.btnSecondary }}
                                  onClick={() => checkSNAvailability(formik)}
                                  disabled={
                                    !formik.values.quantity ||
                                    formik.values.quantity === "0"
                                  }
                                >
                                  {getLang(lang, "label.CHECK_AVAILABILITY")}
                                </Button>
                              </Box>
                            </Box>
                          </Paper>
                        ) : (
                          <Paper
                            elevation={0}
                            style={{ padding: "4px 16px 16px", marginLeft: 16 }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              style={{ backgroundColor: "white" }}
                            >
                              <Tabs
                                value={formik.values.generateRangeType === 'sequence_number' ? 0 : 1}
                                onChange={(event, value) => {
                                  formik.setFieldValue("generateRangeType", value === 0 ? 'sequence_number' : 'serial_number');
                                }}
                                aria-label="scrollable auto tabs example"
                                textColor="primary"
                                variant="scrollable"
                                indicatorColor="primary"
                              >
                                {tabLabels.map((item, index) => (
                                  <CustomTab
                                    key={index}
                                    value={index}
                                    disabled={
                                      item === getLang(lang, "label.SERIAL_NUMBER") &&
                                      formik.values.type === SERIAL_NUMBER_TYPE_ALPHANUMERIC
                                    }
                                    {...a11yProps(index, item)}
                                  />
                                ))}
                              </Tabs>
                            </Box>
                            <Divider />
                            <Box py={1}>
                              {tabLabels.map((item, index) => (
                                <TabPanel
                                  value={formik.values.generateRangeType === 'sequence_number' ? 0 : 1}
                                  index={index}
                                  key={index}
                                >
                                  <Box>
                                    {item === getLang(lang, "label.SEQUENCE_NUMBER") && (
                                      <CustomRangePanel
                                        formik={formik}
                                        lang={lang}
                                        disabled={!formik.values.format}
                                        type="sequence_number"
                                        availableQuantity={formik.values.availQty}
                                        updateQuantity={(quantity) => {
                                          formik.setFieldValue("quantity", quantity);
                                        }}
                                        checkSNAvailability={checkSNAvailability}
                                        isSNexceedRange={isSNexceedRange}
                                      />
                                    )}
                                    {item === getLang(lang, "label.SERIAL_NUMBER") && (
                                      <CustomSerialNumberRangePanel
                                        formik={formik}
                                        lang={lang}
                                        disabled={!formik.values.format}
                                        type="serial_number"
                                        availableQuantity={formik.values.availQty}
                                        updateQuantity={(quantity) => {
                                          formik.setFieldValue("quantity", quantity);
                                        }}
                                        checkSNAvailability={checkSNAvailability}
                                        isSNexceedRange={isSNexceedRange}
                                      />
                                    )}
                                  </Box>
                                </TabPanel>
                              ))}
                            </Box>
                          </Paper>
                        )}
                      </Box>
                      <Box my={3}>
                        <Divider />
                      </Box>
                      <Box
                        className={clsx({
                          [classes.disable]: !formik.values.preview,
                          [classes.enable]: !!formik.values.preview,
                        })}
                      >
                        <Box mb={1} display="flex" alignItems="center">
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            <b>
                              4. {getLang(lang, "label.PREVIEW_READY_ASSIGN")}
                            </b>
                          </Typography>
                        </Box>
                        <Box ml={2}>
                          <SnAvailabilityListTable
                            handleReload={() => checkSNAvailability(formik)}
                            format={formik.values.format}
                            activeType={formik.values.type}
                            formik={formik}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ background: "white" }}
                  p={2}
                >
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  &ensp;
                  <Button
                    variant="contained"
                    color="secondary"
                    classes={{ root: classes.btnSecondary }}
                    type="button"
                    disabled={
                      isLoading ||
                      !formik.values.quantity ||
                      formik.values.quantity === "0" ||
                      !snAvailabilityList.length
                    }
                    onClick={() => setConfirmationDialogOpen(true)}
                  >
                    {isLoading
                      ? getLang(lang, "label.LOADING")
                      : getLang(lang, "label.ASSIGN")}
                  </Button>
                </Box>
              </>
            )}
            <ConfirmationDialog
              isLoading={isLoading}
              isOpen={confirmationDialogOpen}
              handleClose={() => setConfirmationDialogOpen(false)}
              handleProceed={() => handleSubmit(formik.values)}
              title={getLang(lang, "label.ASSIGN_SERIAL_NUMBER")}
              description={getLang(
                lang,
                "paragraph.ASSIGN_SERIAL_NUMBER_CONFIRMATION"
              )}
              type="default"
              proceedLabel={getLang(lang, "label.ASSIGN")}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
}
