/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PeriodInfoFormComponent from "./periodInfoForm.component";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import moment from "moment";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import { JOIN_END_AT_COUNT_DOWN } from "modules/lucky-draw-v2/constants";

export default function PeriodInfoFormContainer({
  uuid,
  initialValues,
  setCurrentStep,
  getLuckyDrawDetail,
  type,
  currentStep,
  progress,
  ldCurrentState,
}) {
  const dispatch = useDispatch();
  const timezones = useSelector((state) => state.constant.timezones);
  const company_timezone = useSelector(
    (state) => state.session.company.timezone
  );
  const timezone = timezones.find((tz) => tz.id === company_timezone);
  const lang = useSelector((state) => state.constant.languages);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (initialValues && formRef.current) {
      let isNewlyCreated = progress && progress === progressStatus.setBasic;

      formRef.current.setValues({
        enablePreview:
          isNewlyCreated && !initialValues?.joinOpenTime
            ? 1
            : Number(initialValues?.enablePreview ?? 0),
        isAutoClose: Number(initialValues?.isAutoClose ?? 0),
        joinEndAction: initialValues?.joinEndAction ?? "",
        preStartDate: initialValues?.preStartDate ?? "",
        preStartTime: initialValues?.preStartTime ?? "",
        joinOpenDate: initialValues?.joinOpenDate ?? "",
        joinOpenTime: initialValues?.joinOpenTime ?? "",
        joinCloseDate: initialValues?.joinCloseDate ?? "",
        joinCloseTime: initialValues?.joinCloseTime ?? "",
        drawDate: initialValues?.drawDate ?? "",
        drawTime: initialValues?.drawTime ?? "",
        externalLink: initialValues?.externalLink ?? "",
        showConfirmDialog: false,
        showExtendConfirmDialog: false,
        isEditing: false,
        type: type,
        isPreviewValid: true,
        isJoinOpenValid: true,
        isJoinCloseValid: true,
        isDrawPeriodValid: true,
        errorMessage: {
          preview: "",
          joinOpen: "",
          joinClose: "",
          draw: "",
        },
      });
    }
  }, [initialValues]);

  const handleSubmit = (values) => {
    if (
      values.isEditing &&
      (!ldCurrentState || ldCurrentState !== ldState.ended)
    ) {
      setIsSubmitting(true);
      const body = {
        luckyDrawUuid: uuid,
        preStartAt: !!values.enablePreview
          ? moment(`${values.preStartDate} ${values.preStartTime}:00`)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")
          : null,
        joinOpenAt: moment(`${values.joinOpenDate} ${values.joinOpenTime}:00`)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        joinCloseAt:
          type === 2 || !!values.isAutoClose
            ? moment(`${values.joinCloseDate} ${values.joinCloseTime}:00`)
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        joinEndAction:
          type === 1 && !!values.isAutoClose
            ? Number(values.joinEndAction)
            : null,
        drawAt:
          !!values.isAutoClose && values.joinEndAction === "1"
            ? moment(`${values.drawDate} ${values.drawTime}:00`)
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        externalLink:
          !!values.isAutoClose && values.joinEndAction === "2"
            ? values.externalLink
            : null,
        type: type,
      };
      luckyDrawV2Api
        .updatePeriod(body)
        .then(() => {
          getLuckyDrawDetail(uuid);
          const params = new URLSearchParams(window.location.search);
          params.set(
            "step",
            values.goToPreview ? (type === 2 ? 6 : 5) : currentStep + 2
          );
          window.history.replaceState(null, null, `?${params}`);
          setCurrentStep(
            values.goToPreview ? (type === 2 ? 5 : 4) : currentStep + 1
          );
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
        })
        .finally(() => {
          if (isMounted.current) {
            setIsSubmitting(false);
          }
        });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.set(
        "step",
        values.goToPreview ? (type === 2 ? 6 : 5) : currentStep + 2
      );
      window.history.replaceState(null, null, `?${params}`);
      setCurrentStep(
        values.goToPreview ? (type === 2 ? 5 : 4) : currentStep + 1
      );
    }
  };

  const handlePrevious = () => {
    const params = new URLSearchParams(window.location.search);
    params.set("step", currentStep);
    window.history.replaceState(null, null, `?${params}`);
    setCurrentStep(currentStep - 1);
  };

  const validateFields = (formik) => {
    let isPublished = progress && progress === progressStatus.published;
    let isJoinStart =
      isPublished &&
      ldCurrentState &&
      [
        ldState.joinStart,
        ldState.joinEnd,
        ldState.drawStart,
        ldState.ended,
      ].includes(ldCurrentState);
    let isEditable =
      !isPublished ||
      !ldCurrentState ||
      (ldCurrentState !== ldState.ended &&
        ldCurrentState !== ldState.drawStart);

    let isPreviewValid = true;
    let isJoinOpenValid = true;
    let isJoinCloseValid = true;
    let isDrawPeriodValid = true;
    let errorMessage = { ...formik.values.errorMessage };

    let now = moment();

    const preStart =
      formik.values.preStartDate && formik.values.preStartTime
        ? moment(`${formik.values.preStartDate} ${formik.values.preStartTime}`)
        : null;
    const joinOpen = moment(
      `${formik.values.joinOpenDate} ${formik.values.joinOpenTime}`
    );
    const joinClose =
      formik.values.joinCloseDate && formik.values.joinCloseTime
        ? moment(
            `${formik.values.joinCloseDate} ${formik.values.joinCloseTime}`
          )
        : null;
    const drawTime =
      formik.values.drawDate && formik.values.drawTime
        ? moment(`${formik.values.drawDate} ${formik.values.drawTime}`)
        : null;

    // check join open
    if (formik.values.enablePreview && !isJoinStart) {
      if (joinOpen.isSameOrBefore(preStart)) {
        isJoinOpenValid = false;
        errorMessage.joinOpen = getLang(
          lang,
          "message.error.LD_PREVIEW_PERIOD_GREATER_JOIN_PERIOD"
        );
      }
    }

    // check join close
    if (joinClose) {
      if (type === 1) {
        if (formik.values.isAutoClose && joinClose.isSameOrBefore(joinOpen)) {
          isJoinCloseValid = false;
          errorMessage.joinClose = getLang(
            lang,
            "message.error.LD_JOIN_START_PERIOD_GREATER_JOIN_END_PERIOD"
          );
        }
      } else if (type === 2) {
        if (joinClose.isSameOrBefore(joinOpen)) {
          isJoinCloseValid = false;
          errorMessage.joinClose = getLang(
            lang,
            "message.error.LD_JOIN_START_PERIOD_GREATER_JOIN_END_PERIOD"
          );
        }
      }
    }

    // check draw time
    if (
      type === 1 &&
      formik.values.joinEndAction === JOIN_END_AT_COUNT_DOWN &&
      drawTime &&
      joinClose &&
      drawTime.isSameOrBefore(joinClose)
    ) {
      isDrawPeriodValid = false;
      errorMessage.draw = getLang(
        lang,
        "message.error.LD_COUNTDOWN_PERIOD_GREATER_JOIN_END_PERIOD"
      );
    }

    // check edited period cannot be earlier than today
    if (isPublished && isEditable) {
      if (formik.values.enablePreview && !isJoinStart) {
        let isPreviewEdited =
          preStart &&
          initialValues &&
          (initialValues.preStartDate !== formik.values.preStartDate ||
            initialValues.preStartTime !== formik.values.preStartTime);
        if (isPreviewEdited && now.isAfter(preStart)) {
          isPreviewValid = false;
          if (!errorMessage.preview) {
            errorMessage.preview = getLang(
              lang,
              "message.error.DATE_TIME_MUST_LATER_THAN_TODAY"
            );
          }
        }
      }

      if (!isJoinStart) {
        let isJoinOpenEdited =
          joinOpen &&
          initialValues &&
          (initialValues.joinOpenDate !== formik.values.joinOpenDate ||
            initialValues.joinOpenTime !== formik.values.joinOpenTime);
        if (isJoinOpenEdited && now.isAfter(joinOpen)) {
          isJoinOpenValid = false;
          if (!errorMessage.joinOpen) {
            errorMessage.joinOpen = getLang(
              lang,
              "message.error.DATE_TIME_MUST_LATER_THAN_TODAY"
            );
          }
        }
      }

      if ((type === 1 && formik.values.isAutoClose) || type === 2) {
        let isJoinCloseEdited =
          joinClose &&
          initialValues &&
          (initialValues.joinCloseDate !== formik.values.joinCloseDate ||
            initialValues.joinCloseTime !== formik.values.joinCloseTime);
        if (isJoinCloseEdited && now.isAfter(joinClose)) {
          isJoinCloseValid = false;
          if (!errorMessage.joinClose) {
            errorMessage.joinClose = getLang(
              lang,
              "message.error.DATE_TIME_MUST_LATER_THAN_TODAY"
            );
          }
        }
      }

      if (
        type === 1 &&
        formik.values.isAutoClose &&
        formik.values.joinEndAction === JOIN_END_AT_COUNT_DOWN
      ) {
        let isDrawEdited =
          drawTime &&
          initialValues &&
          (initialValues.drawDate !== formik.values.drawDate ||
            initialValues.drawTime !== formik.values.drawTime);
        if (isDrawEdited && now.isAfter(joinClose)) {
          isDrawPeriodValid = false;
          if (!errorMessage.draw) {
            errorMessage.draw = getLang(
              lang,
              "message.error.DATE_TIME_MUST_LATER_THAN_TODAY"
            );
          }
        }
      }
    }

    formik.setValues({
      ...formik.values,
      isPreviewValid,
      isJoinOpenValid,
      isJoinCloseValid,
      isDrawPeriodValid,
      errorMessage,
    });
  };

  return (
    <PeriodInfoFormComponent
      uuid={uuid}
      initialValues={initialValues}
      timezone={timezone.value}
      setCurrentStep={setCurrentStep}
      lang={lang}
      handleSubmitForm={handleSubmit}
      isSubmitting={isSubmitting}
      formRef={formRef}
      handlePrevious={handlePrevious}
      type={type}
      progress={progress}
      ldCurrentState={ldCurrentState}
      validateFields={validateFields}
    />
  );
}
