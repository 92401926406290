import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBatchRangeList,
  getListingSerialNumber,
  getDetailsSerialNumber,
  resetSNScanCount,
  getSerialNumberScanListing,
  getCampaignAssigned,
  getSerialNumberActivityLog,
  getScanTrackDetail,
  getUnassignedSerialNumberByBranch,
  checkSerialNumberAvailability,
  updateSNStatus,
  getSnSummaryCount,
} from "../action";
import { SERIAL_NUMBER } from "../../constants";

const initialState = {
  SerialNumberTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
    additionalFields: [],
  },
  generateSerialNumber: {
    batchRange: {
      totalQuantity: 0,
      consumedQuantity: 0,
      availableQuantity: 0,
      range: [],
      startingDigit: 0,
    },
  },
  isLoadingSerialNumberError: false,
  serialNumber: {
    productAssigned: false,
    info: {
      // companyShortName: "",
      // nodeShortName: "",
      productUuid: "",
      productImage: "",
      productName: "",
      attributes: [],
      serialNumber: "",
      sequenceNumber: "",
      batchNumber: "",
      type: "",
      branchName: "",
      dateCreated: "",
      warranty: "-",
      warrantyID: "",
      product: null,
      urlPrefix: "",
      scanLimit: "",
      scanCount: "",
      additionalInfos: [],
    },
    card: {
      scanLimit: "",
      scanCount: "",
      status: "",
    },
    scanLog: [],
    scanLogTotalCount: "",
    activityLog: [],
    campaignAssigned: [],
    viewWarranty: true,
  },
  unassignedSerialNumberList: {
    data: [],
    isError: false,
  },
  snAvailabilityList: {
    data: [],
    isForceReassign: false,
    isReady: false,
    isError: false,
    isSNexceedRange: false,
  },
  scanTrackDetail: {},
  scanTrackDetailFetchingIds: [],
  isLoadingScanLogError: false,
  isLoadingCampaignAssignedError: false,
  isLoadingActivityLogError: false,
  isLoadingScanTrackDetailError: false,
  summaryCount: {
    total: 0,
    assigned: 0,
    active: 0,
    suspend: 0,
    disabled: 0,
    replaced: 0,
    missing: 0,
    faulty: 0,
    adtInfo: 0,
  },
};

const serialNumberSlice = createSlice({
  name: SERIAL_NUMBER,
  initialState,
  reducers: {
    setListingSerialNumber(state, action) {
      const { payload } = action;
      const tempData = [];

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          productName: element.product_name || "",
          // companyShortName: element.company_short_name || null,
          // nodeShortName: element.node_short_name || null,
          urlPrefix: element.url_prefix || "",
          seqNumber: element.seq_number || 0,
          serialNumber: element.serial_number || "",
          status: element.status || "",
          subStatus: element.sub_status || "",
          batchNumber: element.batch_number || 0,
          genType: element.gen_type || "",
          branchName: element.branch_name || "",
          pin: element.pin || "",
          pinRequried: element.pin_required || "",
          datetime: element.datetime || "",
          encode: element.enc || "",
          scanLimit: element.scan_limit || 0,
          scanCount: element.scan_count || 0,
          additionalFields: element.additional_fields || [],
          attributes: element.attributes || [],
        };

        tempData.push(order);
      });

      state.SerialNumberTable.data = tempData;
      state.SerialNumberTable.additionalFields = payload.additionalFields || [];
    },
    setListingLength(state, action) {
      const { payload } = action;

      state.SerialNumberTable.totalRecords = payload.total;
      state.SerialNumberTable.filteredTotalRecords = payload.filtered;
    },
    resetGenerateRangeList(state, action) {
      state.generateSerialNumber.batchRange =
        initialState.generateSerialNumber.batchRange;
    },
    setViewWarranty(state) {
      state.serialNumber.viewWarranty = false;
    },
    resetViewWarranty(state) {
      state.serialNumber.viewWarranty = initialState.serialNumber.viewWarranty;
    },
    resetUnassignedSerialNumberList(state) {
      state.unassignedSerialNumberList =
        initialState.unassignedSerialNumberList;
    },
    resetSerialNumberDetail(state) {
      state.serialNumber = initialState.serialNumber;
      state.scanTrackDetail = initialState.scanTrackDetail;
      state.scanTrackDetailFetchingIds = initialState.scanTrackDetailFetchingIds;
    },
    resetScanTrackDetail(state) {
      state.scanTrackDetail = initialState.scanTrackDetail;
    },
    resetSNAvailabilityList(state) {
      state.snAvailabilityList = initialState.snAvailabilityList;
    },
    updateAdtInfoValues(state, action) {
      state.serialNumber.info.additionalInfos = action.payload;
    },
    resetIsSnExceedRange(state) {
      state.snAvailabilityList.isSNexceedRange =
        initialState.snAvailabilityList.isSNexceedRange;
    }
  },
  extraReducers: {
    [fetchBatchRangeList.fulfilled]: (state, action) => {
      const { payload } = action;
      const { batchRange } = state.generateSerialNumber;

      batchRange.totalQuantity = payload.total;
      batchRange.consumedQuantity = payload.used;
      batchRange.availableQuantity = payload.available;
      batchRange.range = payload.range;

      if (payload.range) {
        const availableRange = payload.range.filter((item) => !item.generated);

        if (availableRange.length > 0) {
          batchRange.startingDigit =
            availableRange[availableRange.length - 1].from_sn;
        } else {
          batchRange.startingDigit = 0;
        }
      }
    },
    [getListingSerialNumber.fulfilled]: (state) => {
      state.isLoadingSerialNumberError = false;
    },
    [getListingSerialNumber.rejected]: (state) => {
      state.isLoadingSerialNumberError = true;
    },
    [getDetailsSerialNumber.pending]: (state) => {
      state.serialNumber = initialState.serialNumber;
      state.activityLog = initialState.activityLog;
      state.activityLogTotalCount = initialState.activityLogTotalCount;
      state.viewWarranty = initialState.viewWarranty;
    },
    [getDetailsSerialNumber.fulfilled]: (state, action) => {
      const { payload } = action;
      const { serialNumber } = state;

      serialNumber.productAssigned =
        payload.product_name !== "" && payload.product_picture !== ""
          ? true
          : false;
      serialNumber.info.urlPrefix = payload.url_prefix;
      // serialNumber.info.companyShortName = payload.company_short_name
      // serialNumber.info.nodeShortName = payload.node_short_name
      serialNumber.info.productUuid = payload.product_uuid;
      serialNumber.info.productImage = payload.product_picture;
      serialNumber.info.productName = payload.product_name;
      serialNumber.info.product = payload.product;
      serialNumber.info.serialNumber = payload.serial_number;
      serialNumber.info.sequenceNumber = payload.sequence_number;
      serialNumber.info.batchNumber = payload.batch_number;
      serialNumber.info.type = payload.gen_type;
      serialNumber.info.branchName = payload.branch_name;
      serialNumber.info.dateCreated = payload.date_created;
      serialNumber.info.warranty = payload.warranty.name || "-";
      serialNumber.info.warrantyID = payload.warranty.id;
      serialNumber.info.scanLimit = payload.scan_limit || 0;
      serialNumber.info.scanCount = payload.scan_count || 0;
      serialNumber.info.additionalInfos = payload.additional_infos || [];
      serialNumber.card.scanLimit = payload.product_scan_limit || 0;
      serialNumber.card.scanCount = payload.scan_count || 0;
      serialNumber.card.status = payload.status;

      // if (payload.activity_log) {
      //   const activites = [];
      //   payload.activity_log.forEach(item => {
      //     activites.push({
      //       consumerEmail: item.email || "Non-Registered Consumer",
      //       location: item.location_address,
      //       created_at: item.created_at,
      //       status: item.status
      //     });
      //   })
      //   serialNumber.activityLog = activites
      //   serialNumber.activityLogTotalCount = payload.activity_log_total
      // }
    },
    [resetSNScanCount.fulfilled]: (state) => {
      const { serialNumber } = state;

      serialNumber.card.scanCount = 0;
      serialNumber.card.status = "active";
    },
    [getSerialNumberScanListing.pending]: (state) => {
      state.serialNumber.scanLog = initialState.serialNumber.scanLog;
      state.serialNumber.scanLogTotalCount =
        initialState.serialNumber.scanLogTotalCount;
      state.isLoadingScanLogError = initialState.isLoadingScanLogError;
    },
    [getSerialNumberScanListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.serialNumber.scanLog = payload.data;
      state.serialNumber.scanLogTotalCount = payload.recordsTotal;
    },
    [getSerialNumberScanListing.rejected]: (state) => {
      state.isLoadingScanLogError = true;
    },
    [getCampaignAssigned.pending]: (state) => {
      state.serialNumber.campaignAssigned =
        initialState.serialNumber.campaignAssigned;
      state.isLoadingCampaignAssignedError = initialState.isLoadingScanLogError;
    },
    [getCampaignAssigned.fulfilled]: (state, action) => {
      const { payload } = action;
      state.serialNumber.campaignAssigned = payload;
    },
    [getCampaignAssigned.rejected]: (state) => {
      state.isLoadingCampaignAssignedError = true;
    },
    [getSerialNumberActivityLog.pending]: (state) => {
      state.serialNumber.activityLog = initialState.serialNumber.activityLog;
      state.isLoadingActivityLogError = initialState.isLoadingActivityLogError;
    },
    [getSerialNumberActivityLog.fulfilled]: (state, action) => {
      const { payload } = action;
      state.serialNumber.activityLog = payload;
    },
    [getSerialNumberActivityLog.rejected]: (state) => {
      state.isLoadingActivityLogError = true;
    },
    [getScanTrackDetail.pending]: (state, action) => {
      state.scanTrackDetailFetchingIds = [...state.scanTrackDetailFetchingIds, action.meta.arg];
      state.scanTrackDetail[action.meta.arg] = [];
      state.isLoadingScanTrackDetailError =
        initialState.isLoadingScanTrackDetailError;
    },
    [getScanTrackDetail.fulfilled]: (state, action) => {
      const { payload, meta } = action;
      state.scanTrackDetailFetchingIds = state.scanTrackDetailFetchingIds.filter(e => e !== meta.arg);
      state.scanTrackDetail[meta.arg] = payload;
    },
    [getScanTrackDetail.rejected]: (state) => {
      state.isLoadingScanTrackDetailError = true;
    },
    [getUnassignedSerialNumberByBranch.pending]: (state) => {
      state.unassignedSerialNumberList =
        initialState.unassignedSerialNumberList;
    },
    [getUnassignedSerialNumberByBranch.rejected]: (state) => {
      state.unassignedSerialNumberList.isError = true;
    },
    [getUnassignedSerialNumberByBranch.fulfilled]: (state, action) => {
      const { payload } = action;

      state.unassignedSerialNumberList.data = payload;
      state.unassignedSerialNumberList.isError = false;
    },
    [checkSerialNumberAvailability.pending]: (state) => {
      state.snAvailabilityList = initialState.snAvailabilityList;
    },
    [checkSerialNumberAvailability.rejected]: (state) => {
      state.snAvailabilityList.isError = true;
    },
    [checkSerialNumberAvailability.fulfilled]: (state, action) => {
      const { payload } = action;

      state.snAvailabilityList.data = payload.data;
      state.snAvailabilityList.isForceReassign = payload.is_force_assign;
      state.snAvailabilityList.isError = false;
      state.snAvailabilityList.isSNexceedRange = payload.is_sn_exceed_range;
    },
    [updateSNStatus.fulfilled]: (state, action) => {
      const { payload } = action;
      state.serialNumber.card.status = payload.status;
      state.serialNumber.card.scanCount = payload.totalScanCount;
      state.SerialNumberTable.data = state.SerialNumberTable.data.map((row) => {
        return {
          ...row,
          status:
            row.encode === action.meta.arg.enc
              ? action.meta.arg.status
              : row.status,
        };
      });
    },
    [getSnSummaryCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.summaryCount = {
        total: payload.total_sn_count,
        assigned: payload.assigned_sn_count,
        active: payload.active_sn_count,
        suspend: payload.suspend_sn_count,
        disabled: payload.disabled_sn_count,
        replaced: payload.replaced_sn_count,
        missing: payload.missing_sn_count,
        faulty: payload.faulty_sn_count,
        adtInfo: payload.sn_with_adt_count,
      };
    },
  },
});

export const {
  setListingSerialNumber,
  setListingLength,
  resetGenerateRangeList,
  setViewWarranty,
  resetViewWarranty,
  resetUnassignedSerialNumberList,
  resetSerialNumberDetail,
  resetScanTrackDetail,
  resetSNAvailabilityList,
  updateAdtInfoValues,
  resetIsSnExceedRange
} = serialNumberSlice.actions;
export const serialNumberReducer = serialNumberSlice.reducer;
