import React, { useEffect, useState } from 'react'
import { selectBranchName } from 'modules/product-mgmt/redux/selector/productPage.selector'
import { useDispatch, useSelector } from 'react-redux'
import ComponentMenuPanelComponent from './componentMenuPanel.component'
import { setProductTemplateChange } from "../../../redux/slice/productPage.slice";
import { useHistory } from "react-router-dom";
import { editProductTemplate } from "../../../redux/action/template.action";
import { PRODUCT_TEMPLATE_VIEW, PRODUCT_PRODUCT_LIST_EDIT_TEMPLATE } from "lib/constants/accessRights"

export default function ComponentMenuPanelContainer({ isTemplateEditor }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const productInfo = useSelector(state => state.productPage)
  const branch = useSelector(state => selectBranchName(state))
  const lang = useSelector(state => state.constant.languages);
  const templateDropdown = useSelector(state => state.productPage.templateDropdown.map((item, index) => ({ ...item, index })));
  templateDropdown.unshift({ uuid: -1, name: '(No Template)' });
  const template = useSelector(state => state.productPage.template);
  const templateAfter = useSelector(state => state.productPage.templateAfter);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [updateDisable, setUpdateDisable] = useState(true);
  const hasProductTemplateViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_TEMPLATE_VIEW));
  const hasProductEditTemplateAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT_TEMPLATE));

  const onTemplateChange = (value) => {
    setSelectedTemplate(value);
    if (value.uuid === template || (value.uuid === -1 && template === null)) {
      setUpdateDisable(true);
    } else {
      setUpdateDisable(false);
    }
    dispatch(setProductTemplateChange(value));
  }

  const onTemplateListClick = () => {
    history.push('/admin/product?tab=landingPageTemplate')
  }

  const handleUpdateTemplate = () => {
    setUpdateDisable(true);
    dispatch(editProductTemplate({
      id: productInfo.productId,
      templateId: templateAfter === -1 ? null : templateAfter,
    }));
  }

  useEffect(() => {
    if (selectedTemplate === null) {
      const selectedTemplate = templateDropdown.find(item => item.uuid === (template !== null ? template : -1));
      if (selectedTemplate) {
        setSelectedTemplate(selectedTemplate);
      }
    }
  }, [template, selectedTemplate, templateDropdown]);

  return (
    <ComponentMenuPanelComponent
      productInfo={productInfo}
      branch={branch}
      lang={lang}
      isTemplateEditor={isTemplateEditor}
      templateDropdown={templateDropdown}
      onTemplateChange={onTemplateChange}
      templateAfter={templateAfter}
      onTemplateListClick={onTemplateListClick}
      handleUpdateTemplate={handleUpdateTemplate}
      selectedTemplate={selectedTemplate}
      updateDisable={updateDisable}
      hasProductEditTemplateAccessRight={hasProductEditTemplateAccessRight}
      hasProductTemplateViewAccessRight={hasProductTemplateViewAccessRight}
    />
  )
}
