import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  MenuItem,
  InputAdornment,
  IconButton,
  Popover,
  Button,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Fragment } from "react";
import InputTextField from "components/input/inputTextField";
import { useRef } from "react";
import { getLang } from "app/feature/constants";
import LoadingComponent from "components/loading";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
}));

export default function LuckyDrawSelectPanelComponent({
  value,
  anchorRef,
  open,
  handleClose,
  isFetching,
  luckyDraws,
  handleSelectLuckyDraw,
  searchable,
  placeholder,
  handleSearch,
  lang,
  totalFiltered,
  handleFetchMoreItems,
  hasNextPage,
  isError,
  currentTotal,
  handleReload,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      open={open}
      style={{ zIndex: 99 }}
      onClose={handleClose}
      anchorEl={anchorRef.current}
      role={undefined}
      disablePortal
      placement="bottom-start"
    >
      <Paper className={classes.paper}>
        <Box>
          {searchable && (
            <>
              <InputTextField
                variant="filled"
                size="small"
                fullWidth
                inputRef={textRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: 6 }}>
                      <IconButton
                        onClick={() => handleSearch(textRef.current.value)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: "#fff",
                    paddingRight: 0,
                  },
                }}
                inputProps={{
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch(textRef.current.value);
                    }
                  },
                  style: {
                    padding: "8px",
                  },
                }}
                placeholder={placeholder || getLang(lang, "placeholder.SEARCH")}
              />
              <Divider />
            </>
          )}
          <Box>
            {isError ? (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                pt={2}
                pb={3}
              >
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", paddingBottom: "8px" }}
                >
                  {getLang(lang, "label.NO_DATA_RECEIVED")}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                >
                  {getLang(lang, "label.SOMETHING_WENT_WRONG")}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReload}
                  disableElevation
                >
                  {getLang(lang, "label.REFRESH")}
                </Button>
              </Box>
            ) : (
              <Box overflow="auto">
                <InfiniteScroll
                  height="25vh"
                  className={classes.list}
                  dataLength={currentTotal}
                  next={() => {
                    if (hasNextPage) handleFetchMoreItems();
                  }}
                  hasMore={totalFiltered > currentTotal}
                  loader={
                    hasNextPage ? <LoadingComponent height={100} /> : null
                  }
                  endMessage={
                    isFetching ? (
                      <LoadingComponent height={100} />
                    ) : !luckyDraws.length ? (
                      <Box p={2} style={{ textAlign: "center" }}>
                        <Typography variant="body1">
                          {getLang(lang, "message.info.NO_RECORD_EXIST")}
                        </Typography>
                      </Box>
                    ) : null
                  }
                >
                  {[...luckyDraws].map((ld, index) => (
                    <Fragment key={index}>
                      <MenuItem
                        style={{
                          padding: 8,
                          backgroundColor:
                            value?.uuid === ld.uuid ? "#ececec" : "#ffffff",
                        }}
                        onClick={() => handleSelectLuckyDraw(ld)}
                      >
                        <Typography display="inline" variant="body2">
                          {ld.title}
                        </Typography>
                      </MenuItem>
                    </Fragment>
                  ))}
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Popover>
  );
}
