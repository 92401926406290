import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import {
  selectInvisibleTemplateComponentIndexes
} from "../../../redux/selector/productPage.selector";
import WebsiteForm from "../../form/websiteForm";
import InstagramForm from "../../form/instagramForm";
import TiktokForm from "../../form/tiktokForm";
import FacebookForm from "../../form/facebookForm";
import VideoForm from "../../form/videoForm";
import TextForm from "../../form/textForm";
import AuthForm from "../../form/authForm";
import MarketplaceForm from "../../form/marketplaceForm";
import GalleryForm from "../../form/galleryForm";
import Modal from "../../modal";
import ConfirmationDialog from "components/dialog/confirmation";
import { displayValueMessage } from "lib/helper";
import { getLang } from "app/feature/constants";
import { renameComponentTitle } from "modules/product-mgmt/utils/productPage.util";
import AdditionalInfoForm from "../../form/additionalInfoForm";
import { ADDITIONAL_INFO_VIEW } from "lib/constants/accessRights";
import SurveyForm from "../../form/surveyForm";
import LuckyDrawForm from "../../form/luckyDrawForm";
import InstantWinForm from "../../form/instantWinForm";
import {
  deleteTemplateComponent,
  hideTemplateComponent,
  reorderTemplateComponent,
  showMultipleTemplateComponents,
  showTemplateComponent,
  updateTemplateComponent
} from "../../../redux/slice/products.slice";
import ComponentActionPanelComponent from "../componentActionPanel/componentActionPanel.component";

function TemplateComponentActionPanelContainer({
  children,
  id,
  orderIndex,
  order,
  reorderComponent,
  deleteComponent,
  hideComponent,
  showComponent,
  isVisible,
  hiddenComponentIndexes,
  showMultipleComponents,
  draggableIndex,
  componentContent,
  updateComponent,
  isEditorMode,
  previewMode,
  type,
}) {
  const orderLength = order.length;
  const [isShowingAction, setIsShowingAction] = useState(false);

  const [hideShowButton, setHideShowButton] = useState(false);
  const [showMultipleComponentsButton, setShowMultipleComponentsButton] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const lang = useSelector((state) => state.constant.languages);
  const hasAdtInfoViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ADDITIONAL_INFO_VIEW)
  );

  const componentType = id.split("-")[0];

  useEffect(() => {
    if (isVisible) {
      setHideShowButton(false);
      setShowMultipleComponentsButton(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) return;
    const invisibleCompIndex = hiddenComponentIndexes.findIndex(
      (idx) => idx === orderIndex
    );
    const nextToInvisibleComponent =
      hiddenComponentIndexes[invisibleCompIndex + 1] === orderIndex + 1;
    const beforeInvisibleComponent =
      orderIndex !== 0
        ? hiddenComponentIndexes[invisibleCompIndex - 1] === orderIndex - 1
        : false;

    if (nextToInvisibleComponent) {
      setShowMultipleComponentsButton(true);
    }
    if (beforeInvisibleComponent) {
      setHideShowButton(true);
    }
  }, [hiddenComponentIndexes, orderIndex, isVisible]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleShowAction = () => {
    if (!isVisible || !isEditorMode || previewMode) return;

    setIsShowingAction(true);
  };

  const handleHideAction = () => {
    if (!isVisible || !isEditorMode || previewMode) return;
    setIsShowingAction(false);
  };

  const handleReorderBackward = () => {
    reorderComponent({
      id,
      sourceIndex: orderIndex,
      destinationIndex: orderIndex - 1,
    });
    handleHideAction();
  };

  const handleReorderForward = () => {
    reorderComponent({
      id,
      sourceIndex: orderIndex,
      destinationIndex: orderIndex + 1,
    });
    handleHideAction();
  };

  const handleDelete = () => {
    deleteComponent(id);
  };

  const handleHideComponent = () => {
    hideComponent(id);
    handleHideAction();
  };

  const handleShowComponent = () => {
    if (showMultipleComponentsButton) {
      const invisibleCompIndex = hiddenComponentIndexes.findIndex(
        (idx) => idx === orderIndex
      );
      let endIndex;

      for (
        let n = invisibleCompIndex + 1;
        n <= hiddenComponentIndexes.length;
        n++
      ) {
        const currentHiddenIndex = hiddenComponentIndexes[n];
        if (currentHiddenIndex === orderIndex + n) {
          endIndex = hiddenComponentIndexes[n];
        } else {
          break;
        }
      }
      showMultipleComponents({
        startIndex: orderIndex,
        endIndex,
      });
    } else {
      showComponent(id);
    }
  };

  const handleEditComponent = (content) => {
    updateComponent({ id, content });
    handleCloseModal();
  };

  function getComponentForm() {
    switch (componentType) {
      case "website":
        return (
          <WebsiteForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "instagram":
        return (
          <InstagramForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "tiktok":
        return (
          <TiktokForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "facebook":
        return (
          <FacebookForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "video":
        return (
          <VideoForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "text":
        return (
          <TextForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "authentication":
        return (
          <AuthForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "marketplace":
        return (
          <MarketplaceForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "gallery":
        return (
          <GalleryForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
          />
        );

      case "additional information":
        if (hasAdtInfoViewAccessRight)
          return (
            <AdditionalInfoForm
              type="edit"
              handleResult={handleEditComponent}
              handleDelete={handleOpenConfirmationDialog}
              handleClose={handleCloseModal}
              open={isModalOpen}
            />
          );
        break;

      case "survey":
        return (
          <SurveyForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
            open={isModalOpen}
          />
        );

      case "lucky draw":
        return (
          <LuckyDrawForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
            open={isModalOpen}
          />
        );

      case "instant win":
        return (
          <InstantWinForm
            type="edit"
            initialValues={componentContent}
            handleResult={handleEditComponent}
            handleDelete={handleOpenConfirmationDialog}
            handleClose={handleCloseModal}
            open={isModalOpen}
          />
        );

      default:
        break;
    }
  }

  const handleOpenConfirmationDialog = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleDeleteComponent = () => {
    handleDelete();
    handleCloseConfirmationDialog();
  };

  return (
    <>
      <ComponentActionPanelComponent
        id={id}
        type={type}
        children={children}
        orderIndex={orderIndex}
        orderLength={orderLength}
        handleReorderBackward={handleReorderBackward}
        handleReorderForward={handleReorderForward}
        isShowingAction={isShowingAction}
        handleShowAction={handleShowAction}
        handleHideAction={handleHideAction}
        handleDelete={handleOpenConfirmationDialog}
        handleHideComponent={handleHideComponent}
        isVisible={isVisible}
        handleShowComponent={handleShowComponent}
        hideShowButton={hideShowButton}
        showMultipleComponentsButton={showMultipleComponentsButton}
        draggableIndex={draggableIndex}
        handleOpenModal={handleOpenModal}
        isEditorMode={isEditorMode}
        previewMode={previewMode}
        lang={lang}
        hasAdtInfoViewAccessRight={hasAdtInfoViewAccessRight}
      />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        handleClose={handleCloseConfirmationDialog}
        handleProceed={handleDeleteComponent}
        description={displayValueMessage(
          getLang(lang, "paragraph.DELETE_COMPONENT_QUESTION"),
          { componentType: renameComponentTitle(lang, componentType) }
        )}
        type={"danger"}
      />
      <Modal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        title={displayValueMessage(getLang(lang, "paragraph.EDIT_COMPONENT"), {
          componentType: renameComponentTitle(lang, componentType),
        })}
        helperText={
          componentType === "gallery"
            ? getLang(lang, "paragraph.IMAGE_IN_CAROUSEL_FORMAT")
            : ""
        }
        maxWidth={componentType === "additional information" ? "sm" : "xs"}
      >
        {getComponentForm()}
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          handleClose={handleCloseConfirmationDialog}
          handleProceed={handleDeleteComponent}
          description={displayValueMessage(
            getLang(lang, "paragraph.DELETE_COMPONENT_QUESTION"),
            { componentType: componentType }
          )}
          type={"danger"}
        />
      </Modal>
    </>
  );
}

TemplateComponentActionPanelContainer.propTypes = {
  id: PropTypes.string.isRequired,
  draggableIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  orderIndex: state.products.template.workspaceSetting.setting.columns.compIds.findIndex(
    (id) => ownProps.id === id
  ),
  order: state.products.template.workspaceSetting.setting.columns.compIds,
  isVisible: state.products.template.workspaceSetting.setting.components[ownProps.id].isVisible,
  hiddenComponentIndexes: selectInvisibleTemplateComponentIndexes(state),
  componentContent: state.products.template.workspaceSetting.setting.components[ownProps.id].content,
  isEditorMode: state.products.template.workspaceSetting.isEditorMode,
});

const mapDispatchToProps = (dispatch) => ({
  reorderComponent: (payload) => dispatch(reorderTemplateComponent(payload)),
  deleteComponent: (id) => dispatch(deleteTemplateComponent(id)),
  hideComponent: (id) => dispatch(hideTemplateComponent(id)),
  showComponent: (id) => dispatch(showTemplateComponent(id)),
  showMultipleComponents: (payload) =>
    dispatch(showMultipleTemplateComponents(payload)),
  updateComponent: (payload) => dispatch(updateTemplateComponent(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateComponentActionPanelContainer);
