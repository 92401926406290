/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LuckyDrawParticipantsComponent from "./luckyDrawParticipants.component";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { LUCKY_DRAW_V2_VIEW } from "lib/constants/accessRights";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

function LuckyDrawParticipantsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const accessToken = useSelector((state) => state.session.accessToken);
  const hasLuckyDrawViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_VIEW)
  );

  const { luckyDrawUUID } = useParams();
  const [luckyDraw, setLuckyDraw] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!hasLuckyDrawViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasLuckyDrawViewAccessRight]);

  const handleDownloadReport = () => {
    luckyDrawV2Api.exportParticipantsList({
      uuid: luckyDrawUUID,
      token: accessToken,
    });
  };

  useEffect(() => {
    getData();
  }, [luckyDrawUUID]);

  const getData = () => {
    if (isError) setIsError(false);
    setIsFetching(true);
    luckyDrawV2Api
      .getLuckyDrawDetails(luckyDrawUUID)
      .then((response) => setLuckyDraw(response.data.data.lucky_draws))
      .catch((error) => {
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        );
        setIsError(true);
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <LuckyDrawParticipantsComponent
      luckyDraw={luckyDraw}
      lang={lang}
      handleDownloadReport={handleDownloadReport}
      isError={isError}
      isFetching={isFetching}
      handleReload={getData}
    />
  );
}

export default LuckyDrawParticipantsContainer;
