import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputTextField from "components/input/inputTextField";
// import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
// import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 375
  },
  button: {
    padding: theme.spacing(1),
    minWidth: 0
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  },
  menuListContainer: {
    maxHeight: "28px"
  },
  menuListOdd: {
    backgroundColor: "#EBEDEE"
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "250px"
  },
  emptyContainer: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center"
  },
  menuItemContainer: {
    flexFlow: "column",
    placeItems: "flex-start"
  }
}));

export default function SetWinnerPanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  isFetching,
  serialNumberData,
  // fetchMoreData,
  // hasMoretoLoad,
  handleSearch,
  // excludeList,
  lang,
  serialNumberStatus
}) {
  const classes = useStyle();
  const textRef = useRef();
  const isSearchingRef = useRef(false);

  const onSearch = () => {
    handleSearch(textRef.current.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isSearchingRef.current) {
      isSearchingRef.current = true;
      onSearch();
      // prevent user from spamming enter button
      setTimeout(() => {
        isSearchingRef.current = false;
      }, 1000); 
    }
  };

  const renderContent = () => {
    if (isFetching) {
      // Show loading spinner
      return <LoadingComponent height={50} />;
    } else if (serialNumberStatus === "initial") {
      // Initial state message when no search is performed
      return (
        <Box py={4} className={classes.emptyContainer}>
          <Typography variant="body1" style={{
            wordBreak: "break-word", 
            whiteSpace: "normal", 
          }}>
            {getLang(lang, "paragraph.ENTER_SERIAL_NUMBER_IN_TOP_BAR_AND_PRESS_SEARCH")}
          </Typography>
        </Box>
      );
    } else if (serialNumberStatus === "notFound") {
      // Display "no serial number found" if search returned no results
      return (
        <Box py={4} className={classes.emptyContainer}>
          <Typography variant="body1">
            {getLang(lang, "paragraph.SERIAL_NUMBER_NOT_FOUND")}
          </Typography>
        </Box>
      );
    } else if (serialNumberStatus === "duplicate") {
      return (
        <Box py={4} className={classes.emptyContainer}>
          <Typography variant="body1">
            {getLang(lang, "paragraph.SERIAL_NUMBER_DUPLICATED")}
          </Typography>
        </Box>
      );
    } else {
      // Display search result
      return (
          <React.Fragment>
            <MenuList
              autoFocusItem={isOpenList}
              id="menu-list-grow"
              onClick={() => {
                handleListKeyDown(
                  serialNumberData.serial_number,
                  serialNumberData.enc
                );
              }}
              className={clsx({
                [classes.menuListOdd]: true,
              })}
            >
              <MenuItem>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{
                    paddingLeft: "8px",
                    fontWeight: "bold"
                  }}
                >
                  {serialNumberData.serial_number}
                </Typography>
              </MenuItem>
            </MenuList>
          </React.Fragment>
      );
    }
  };

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box>
                  <InputTextField
                    inputRef={textRef}
                    variant="filled"
                    size="small"
                    fullWidth
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button
                            type="button"
                            className={classes.button}
                            onClick={onSearch}
                          >
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff"
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "14px"
                      },
                    }}
                    placeholder={getLang(lang, "placeholder.ENTER_SERIAL_NUMBER_HERE")}
                  />
                </Box>

                <Divider />
                {renderContent()}
                {/* {isFetching && serialNumberData.length === 0 ? (
                  <>
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </>
                ) : serialNumberData.length === 0 ? (
                  <Box py={4} className={classes.emptyContainer}>
                    <Typography variant="body1">
                      {getLang(lang,"paragraph.NO_SERIAL_NUMBER")}
                    </Typography>
                  </Box>
                ) : serialNumberData.length > 0 ? (
                  <InfiniteScroll
                    dataLength={serialNumberData.length}
                    next={fetchMoreData}
                    hasMore={hasMoretoLoad}
                    loader={<LoadingComponent height={50} />}
                    height={200}
                    style={{ position: "relative" }}
                  >
                    <>
                      {serialNumberData.map((field, index) => {
                        if (excludeList.length >= 1) {
                          if (!excludeList.find((e) => e.enc === field.enc)) {
                            return (
                              <React.Fragment key={index}>
                                <MenuList
                                  autoFocusItem={isOpenList}
                                  id="menu-list-grow"
                                  onClick={() => {
                                    handleListKeyDown(
                                      field.serialNumber,
                                      field.enc
                                    );
                                  }}
                                  className={clsx({
                                    [classes.menuListOdd]: index % 2 === 0,
                                    [classes.menuItemContainer]: true
                                  })}
                                >
                                  <MenuItem
                                    className={classes.menuItemContainer}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="primary"
                                      style={{
                                        paddingLeft: "8px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {field.serialNumber}
                                    </Typography>
                                  </MenuItem>
                                </MenuList>
                              </React.Fragment>
                            );
                          }
                          return null;
                        } else {
                          return (
                            <React.Fragment key={index}>
                              <MenuList
                                autoFocusItem={isOpenList}
                                id="menu-list-grow"
                                onClick={() => {
                                  handleListKeyDown(
                                    field.serialNumber,
                                    field.enc
                                  );
                                }}
                                className={clsx({
                                  [classes.menuListOdd]: index % 2 === 0,
                                  [classes.menuItemContainer]: true
                                })}
                              >
                                <MenuItem className={classes.menuItemContainer}>
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    style={{
                                      paddingLeft: "8px",
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {field.serialNumber}
                                  </Typography>
                                </MenuItem>
                              </MenuList>
                            </React.Fragment>
                          );
                        }
                      })}
                    </>
                    <Divider />
                  </InfiniteScroll>
                ) : null} */}

              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
