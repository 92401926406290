import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/product";

const post = ({
  productName,
  desc,
  companyId,
  nodes,
  uiPath,
  picture,
  scanLimit,
  warrantySettingId,
  category,
  brand,
  model,
  attributeoptions,
  sku,
  currency_code,
  unit_price,
  incentive_point,
  customFields
}) => {
  const body = {
    name: productName,
    description: desc,
    company_id: companyId,
    nodes,
    ui_path: uiPath,
    live_ui_path: uiPath,
    picture,
    scanlimit: scanLimit,
    warranty_setting_id: warrantySettingId,
    category,
    brand: brand === "-1" ? "" : brand,
    model: model === "-1" ? "" : model,
    attributeoptions,
    sku,
    currency_code,
    unit_price,
    incentive_point,
    custom_fields: customFields
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoint_url}/create`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({
  id,
  productName,
  desc,
  companyId,
  nodes,
  //uiPath,
  picture,
  scanLimit,
  warrantySettingId,
  category,
  brand,
  model,
  attributeoptions,
  sku,
  currency_code,
  unit_price,
  incentive_point,
  customFields
}) => {
  const body = {
    name: productName,
    description: desc,
    company_id: companyId,
    nodes,
    //ui_path: uiPath,
    picture,
    scanlimit: scanLimit,
    warranty_setting_id: warrantySettingId,
    sku,
    unit_price,
    category,
    brand,
    model,
    currency_code,
    attributeoptions,
    incentive_point,
    custom_fields: customFields
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoint_url}/update/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const get = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${endpoint_url}/get/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const product = {
  post,
  put,
  get
};

export default product;
