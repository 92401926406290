import React from "react";
import { Box, makeStyles, FormHelperText } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { TYPES } from "./luckyDrawForm.container";
import { ModalFooter } from "../../modal";
import * as Yup from "yup";
import { getLang } from "app/feature/constants";
import LuckyDrawSelect from "../../select/luckyDrawSelect";
import LuckyDrawDetailCard from "../../card/luckyDrawDetailCard";

const useStyle = makeStyles((theme) => ({
  container: {
    maxHeight: "75vh",
    overflowY: "auto",
    backgroundColor: "#f0f3f5",
    padding: 16,
  },
}));

export default function LuckyDrawFormComponent({
  type,
  initialValues,
  handleDelete,
  handleSubmit,
  lang,
  handleClose,
}) {
  const classes = useStyle();

  return (
    <Formik
      initialValues={{
        id: initialValues?.id ?? null,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        id: Yup.string()
          .nullable()
          .trim()
          .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
      })}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes.container}>
            <LuckyDrawSelect
              value={formik.values.id}
              handleChange={(value) => {
                if (value) {
                  formik.setFieldValue("id", value.uuid);
                } else {
                  formik.setFieldValue("id", null);
                }
              }}
              disabled={false}
              styles={{
                backgroundColor: "#FFFFFF",
                borderRadius: 8,
                border: "1px solid #D0D5DD",
              }}
              searchable={true}
              placeholder={getLang(lang, "placeholder.SELECT_LUCKY_DRAW")}
            />
            <FormHelperText
              error={formik.touched.id && formik.errors.id ? true : false}
              style={{ paddingLeft: 8 }}
            >
              {ErrorMessage({ name: "id" })}
            </FormHelperText>
            {!!formik.values.id && (
              <Box mt={2}>
                <LuckyDrawDetailCard id={formik.values.id} />
              </Box>
            )}
          </Box>
          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
