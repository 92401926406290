import axios from "axios";

const endpoint_url = "/campaignV2/customer/options/productNodes";

const getProductNodes = ({ productUuid }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          productUuid,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getProductNodes;
