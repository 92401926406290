import React from "react";
import UnassignDialogComponent from "./unassignDialog.component";
import { useDispatch, useSelector } from "react-redux";
import {
  checkSNUnassignAvailability,
  closeUnassignDialog,
  resetSNUnassignAvailabilityList,
  unassignSerialNumber,
} from "modules/serial-number/redux";
import { getLang } from "app/feature/constants";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoading } from "modules/notification";

function UnassignDialogContainer({ handleReload }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, unassignSerialNumber.typePrefix)
  );
  const isOpen = useSelector((state) => state.assignBatch.isOpenUnassignDialog);
  const snAvailabilityList = useSelector(
    (state) => state.assignBatch.snAvailabilityList.data
  );

  const handleSubmit = (values) => {
    let ranges = [];

    values.ranges.forEach((r) => {
      if (r.from) {
        ranges.push({
          from: r.from,
          to: r.to ? r.to : r.from,
        });
      }
    });

    dispatch(
      unassignSerialNumber({
        type: values.type,
        ranges: ranges,
      })
    )
      .then(unwrapResult)
      .then(() => {
        handleReload();
        closeDialog();
      });
  };

  const closeDialog = () => {
    dispatch(closeUnassignDialog());
  };

  const validate = (formik) => {
    let isValid = true;
    let overlap = false;

    formik.values.ranges.forEach((range, i) => {
      let rangeError = formik.values.errors.ranges;

      if (range.from.trim() === "" && range.to.trim() !== "") {
        rangeError[i] = {
          ...rangeError[i],
          from: getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"),
        };
        formik.setFieldTouched(`ranges.${i}.from`);
        isValid = false;
      }

      // serial number
      if (formik.values.type === 1) {
        let from = getSnFormat(range.from);
        let to = getSnFormat(range.to);

        if (
          from[0] !== to[0] ||
          from[2] !== to[2] ||
          from[1].length !== to[1].length
        ) {
          rangeError[i] = {
            ...rangeError[i],
            to: getLang(lang, "message.error.INVALID_SERIAL_NUMBER_RANGE"),
          };
          formik.setFieldTouched(`ranges.${i}.to`);
          isValid = false;
        } else {
          from = Number(from[1]);
          to = Number(to[1]);
          // check if the sn is valid format with digit
          if (isNaN(from) || isNaN(to)) {
            if (isNaN(from)) {
              rangeError[i] = {
                ...rangeError[i],
                from: getLang(lang, "message.error.INVALID_SERIAL_NUMBER"),
              };
              formik.setFieldTouched(`ranges.${i}.from`);
            }
            if (isNaN(to)) {
              rangeError[i] = {
                ...rangeError[i],
                to: getLang(lang, "message.error.INVALID_SERIAL_NUMBER"),
              };
              formik.setFieldTouched(`ranges.${i}.to`);
            }
            isValid = false;
          } else if (from > to) {
            // check if the sn range is valid, from < to
            rangeError[i] = {
              ...rangeError[i],
              to: getLang(
                lang,
                "message.error.FORM_VALIDATE_SN_SELECTION_RANGES"
              ),
            };
            formik.setFieldTouched(`ranges.${i}.to`);
            isValid = false;
          }
        }
      }

      // sequence number
      if (formik.values.type === 2) {
        let from = Number(range.from);
        let to = Number(range.to);

        if (range.from || range.to) {
          let temp = [...formik.values.ranges];
          temp.splice(i, 1);
          let overlapIdx = temp.findIndex(
            (r) =>
              (Number(r.from) <= from && to <= Number(r.to)) ||
              (Number(r.from) >= from && to >= Number(r.to)) ||
              Number(r.to) === from ||
              to === Number(r.from)
          );
          if (overlapIdx >= 0) {
            overlap = true;
            isValid = false;
          }
        }

        if (from > to) {
          rangeError[i] = {
            ...rangeError[i],
            to: getLang(
              lang,
              "message.error.FORM_VALIDATE_SN_SELECTION_RANGES"
            ),
          };
          formik.setFieldTouched(`ranges.${i}.to`);
          isValid = false;
        }
      }

      formik.setFieldValue("errors", {
        ranges: rangeError,
      });
    });

    formik.setFieldValue("hasOverlapped", overlap);

    return isValid;
  };

  const getSnFormat = (input) => {
    const regex = /^([A-Z]*)(0*\d+)([A-Z]*)$/;
    const matches = input.match(regex);

    if (!matches) {
      return [null, input, null];
    }

    // Extract the parts ensuring we return null for empty parts
    const prefix = matches[1] || null;
    const number = matches[2] || null;
    const suffix = matches[3] || null;

    return [prefix, number, suffix];
  };

  const handlePreview = (formik) => {
    let ranges = [];

    formik.values.ranges.forEach((r) => {
      if (r.from) {
        ranges.push({
          from: r.from,
          to: r.to ? r.to : r.from,
        });
      }
    });

    dispatch(
      checkSNUnassignAvailability({
        type: formik.values.type,
        ranges: ranges,
      })
    );
    formik.setFieldValue("preview", true);
  };

  const resetSNAvailabilityList = () => {
    dispatch(resetSNUnassignAvailabilityList());
  };

  return (
    <>
      <UnassignDialogComponent
        isOpen={isOpen}
        handleClose={closeDialog}
        lang={lang}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        validate={validate}
        handlePreview={handlePreview}
        resetSNAvailabilityList={resetSNAvailabilityList}
        snAvailabilityList={snAvailabilityList}
      />
    </>
  );
}

export default UnassignDialogContainer;
