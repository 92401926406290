import React, { useState, createContext, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranditionalRaffleWinnerDialogComponent from "./tranditionalRaffleWinnerDialog.component";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { addAlert } from "modules/notification";
import { PRIZE_TYPE_PRESET } from "modules/lucky-draw-v2/constants";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { getApiLang, getLang } from "app/feature/constants";
import { LUCKY_DRAW_V2_VIEW } from "lib/constants/accessRights";

export const PrizeContext = createContext();

export default function TranditionalRaffleWinnerDialogContainer({
  isOpen,
  handleClose,
  luckyDrawUuid,
  index,
  selectedLuckyDraw,
}) {
  const dispatch = useDispatch();
  const hasLuckyDrawV2ViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_VIEW)
  );
  const accessToken = useSelector((state) => state.session.accessToken);
  const [prizeList, setPrizeList] = useState([]);
  const [viewType] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [excludeList, setExcludeList] = useState([]);
  const winnerType = useState(PRIZE_TYPE_PRESET);
  const [isUpdating, setIsUpdating] = useState(false);

  const progress = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.progress
  );
  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false);
    }
  }, [isOpen]);

  const handleSaveWinner = async () => {
    dispatch(showBackdrop());

    let hasEmptyField = false;

    const prizesUpdateInfo = [];
    for (const prize of prizeList) {
      const prizeWinners = {
        id: prize.id,
        winnerParticipantIds: [],
      };
      let count = 0;
      for (const winner of prize.winners) {
        count++;

        if (winner.participantId) {
          prizeWinners.winnerParticipantIds.push(winner.participantId);
        }
      }

      if (prizeWinners.winnerParticipantIds < count) {
        hasEmptyField = true;
        break;
      }

      prizesUpdateInfo.push(prizeWinners);
    }

    if (hasEmptyField) {
      dispatch(hideBackdrop());
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FAILED_SET_WINNER"),
        })
      );
      return;
    }

    dispatch(hideBackdrop());
  };

  const handleConfirmWinner = async () => {
    const prizesUpdateInfo = [];
    for (const prize of prizeList) {
      const prizeWinners = {
        prizeId: prize.id,
        enc: [],
      };

      for (const winner of prize.winners) {
        if (!winner.status && winner.enc) prizeWinners.enc.push(winner.enc);
      }
      prizesUpdateInfo.push(prizeWinners);
    }
    dispatch(showBackdrop());
    await luckyDrawV2Api
      .presetWinner({
        luckyDrawUuid: luckyDrawUuid,
        winnerField: prizesUpdateInfo,
      })
      .then((response) => {
        setIsEdit(false);
        handleClose();
      })
      .catch((error) => {
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        );
      })
      .finally(() => {
        dispatch(hideBackdrop());
      });
  };

  const handleSetWinner = (index, winnerIndex, serialNumber, enc) => {
    const info = {
      index: index,
      winnerIndex: winnerIndex,
      serial_number: serialNumber,
      enc: enc,
    };
    const updatedPrizeList = [...prizeList];
    const prize = updatedPrizeList[index];
    const updatedWinners = [...prize.winners];

    updatedWinners[winnerIndex] = { ...updatedWinners[winnerIndex], ...info };
    updatedPrizeList[index] = { ...prize, winners: updatedWinners };

    let encs = updatedPrizeList.reduce((acc, prize) => {
      let winner = prize.winners;
      winner.forEach((element) => {
        if (element.enc) {
          acc.push({ enc: element.enc });
        }
      });

      return acc;
    }, []);

    setPrizeList(updatedPrizeList);
    setExcludeList(encs);
  };

  const handleDelete = (index, winnerIndex, serialNumber, enc) => {
    const updatedPrizeList = [...prizeList];
    const prize = updatedPrizeList[index];
    const updatedWinners = [...prize.winners];
    updatedWinners.splice(winnerIndex, 1);
    if (updatedWinners.length === 0) {
      updatedWinners.push({
        enc: null,
        serial_number: null,
        participant: null,
      });
    }

    updatedPrizeList[index] = { ...prize, winners: updatedWinners };

    let encs = updatedPrizeList.reduce((acc, prize) => {
      let winner = prize.winners;
      winner.forEach((element) => {
        if (element.enc) {
          acc.push({ enc: element.enc });
        }
      });

      return acc;
    }, []);

    setPrizeList(updatedPrizeList);
    setExcludeList(encs);
  };

  const fetchPrizes = useCallback(async () => {
    setIsFetching(true);
    await luckyDrawV2Api
      .getPrizes({
        luckyDrawUuid: luckyDrawUuid,
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          data.prizes.forEach((prize) => {
            const remainingQuantity = prize.quantity - prize.win_quantity;
            if (remainingQuantity <= 0) {
              return prize;
            }
            const remainingWinners = remainingQuantity - prize.winners.length;
            if (remainingWinners <= 0) {
              return prize;
            }

            for (let i = 0; i < remainingWinners; i++) {
              prize.winners.push({
                enc: null,
                serial_number: null,
                participant: null,
              });
            }
          });
          setPrizeList(data.prizes);
          setExcludeList(data.exclude_list);
        } else {
          setPrizeList([]);
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [luckyDrawUuid]);

  const [item, setItem] = useState([]);

  const toggleItem = (index) => {
    setItem((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const expandAll = () => {
    setItem(prizeList.map((_, index) => index));
  };

  const collapseAll = () => {
    setItem([]);
  };

  useEffect(() => {
    if (isOpen) {
      fetchPrizes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, luckyDrawUuid]);

  const handleDownloadWinner = (id) => {
    setIsExporting(true);
    luckyDrawV2Api
      .exportWinnerList({ id: id, token: accessToken })
      .finally(() => {
        setIsExporting(false);
        return;
      });
  };

  const handleUpdateClaimFulfillment = (status, uuid) => {
    setIsUpdating(true);
    luckyDrawV2Api
      .updateWinnerClaimFulfillmentStatus({
        luckyDrawUuid: selectedLuckyDraw.uuid,
        winnerUuid: uuid,
        status: status,
      })
      .then(() => {
        fetchPrizes();
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <>
      <PrizeContext.Provider
        value={{
          luckyDrawUuid,
          prizeList,
          excludeList,
          viewType,
          isEdit,
          isUpdating,
          handleSetWinner,
          handleDelete,
          handleUpdateClaimFulfillment,
          luckyDraw: selectedLuckyDraw,
        }}
      >
        <TranditionalRaffleWinnerDialogComponent
          isOpen={isOpen}
          handleClose={handleClose}
          handleSaveWinner={handleSaveWinner}
          winnerType={winnerType}
          changeToUpdate={() => setIsEdit(true)}
          changeToView={() => setIsEdit(false)}
          handleConfirmWinner={handleConfirmWinner}
          progress={progress}
          lang={lang}
          isFetching={isFetching}
          selectedLuckyDraw={selectedLuckyDraw}
          hasLuckyDrawV2ViewAccessRight={hasLuckyDrawV2ViewAccessRight}
          item={item}
          toggleItem={toggleItem}
          expandAll={expandAll}
          collapseAll={collapseAll}
          handleDownloadWinner={handleDownloadWinner}
          isExporting={isExporting}
        />
      </PrizeContext.Provider>
    </>
  );
}
