import React from "react";
import { useSelector } from "react-redux";
import ProductSearchComponent from "./productSearch.component";

export default function ProductSearchContainer({
  handleChange,
  isFetchingProduct,
  productDropdown,
  value,
  disabled,
  editTemplate,
}) {
  const lang = useSelector(state => state.constant.languages)

  const handleFormChange = async (productsSelected) => {
    handleChange(productsSelected);
  };

  return <ProductSearchComponent
    handleFormChange={handleFormChange}
    lang={lang}
    isFetchingProduct={isFetchingProduct}
    productDropdown={productDropdown}
    productsSelected={value}
    disabled={disabled}
    editTemplate={editTemplate}
  />
}
