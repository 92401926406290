/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import LuckyDrawBindingDialogComponent from "./luckyDrawBindingDialog.component";
import { useSelector, useDispatch } from "react-redux";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { getApiLang, getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import AlertIcon from "assets/svg/alert.svg";
import GeneralDialog from "components/dialog/generalDialog";
import { LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import { useLuckyDraw } from "modules/lucky-draw-v2/context/luckyDraw.context";

export default function LuckyDrawBindingDialogContainer({
  isOpen,
  handleClose,
  handleReload,
  luckyDraw,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { bindingInfo, setBindingInfo, setExcludeProdBindList } = useLuckyDraw();

  const inputRef = useRef(null);

  useEffect(() => {
    if (luckyDraw) {
      getData();
    }
  }, [luckyDraw]);

  useEffect(() => {
    if (isOpen) dispatch(getAllProducts());
  }, [isOpen]);

  const getData = () => {
    setIsLoading(true);
    luckyDrawV2Api
      .getLuckyDrawBindingInfo({ uuid: luckyDraw.uuid })
      .then((res) => {
        setBindingInfo({
          bindingType: res.data.bindingType,
          products: res.data.products.map((p) => ({
            product: p.product,
            nodes: p.nodes,
          })),
          serialNumbers: res.data.serial_numbers,
          sequenceNumbers: res.data.sequence_numbers,
        });
        setExcludeProdBindList(res.data.products.map((p) => p.product.id)); 
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBinding = () => {
    let values = inputRef.current;
    setIsSubmitting(true);
    luckyDrawV2Api
      .luckyDrawBinding({
        luckyDrawUuid: luckyDraw.uuid,
        productBindings: values.products.map((p) => ({
          uuid: null,
          productUuid: p.product.id,
          nodeUuids: p.nodes.map((n) => n.node_id),
        })),
        serial_number_bindings: [],
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsConfirmOpen(false);
        setIsSubmitting(false);
        handleClose();
        handleReload();
      });
  };

  const handleSubmit = (values) => {
    inputRef.current = values;
    setIsConfirmOpen(true);
  };

  return (
    <>
      <LuckyDrawBindingDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        lang={lang}
        isSubmitting={isSubmitting}
        luckyDraw={luckyDraw}
        handleSubmit={handleSubmit}
        bindingInfo={bindingInfo}
        isLoading={isLoading}
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
      />
      <GeneralDialog
        isOpen={isConfirmOpen}
        handleClose={() => setIsConfirmOpen(false)}
        handleProceed={handleBinding}
        isLoading={isSubmitting}
        type="danger"
        description={getLang(lang, "paragraph.BINDING_LUCKY_DRAW_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  );
}
