import React from "react";
import LuckyDrawCreateEditForm from "modules/lucky-draw-v2/components/form/luckyDrawCreateEditForm";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getLang } from "app/feature/constants";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";

export default function LuckyDrawPage({
  initialValues,
  luckyDrawUUID,
  getLuckyDrawDetail,
  isLoading,
  lang,
  hasLuckyDrawEditAccessRight,
}) {
  const history = useHistory();
  return (
    <Box>
      <Box
        mb={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="flex-start" fontWeight="bold">
          <Typography
            variant="body1"
            color="primary"
            style={{ color: "#818D91", cursor: "pointer" }}
            onClick={() => {
              history.push(ROOT);
            }}
          >
            {getLang(lang, "label.LUCKY_DRAW_LISTING")}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon
              color="disabled"
              style={{ color: "#818D91" }}
            />
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="primary"
              style={{ color: "#818D91" }}
            >
              <b>
                {getLang(
                  lang,
                  hasLuckyDrawEditAccessRight
                    ? "label.VIEW_EDIT_DETAIL"
                    : "label.VIEW_DETAIL"
                )}
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
      {Boolean(initialValues) ? (
        <LuckyDrawCreateEditForm
          uuid={luckyDrawUUID}
          initialValues={initialValues}
          type={initialValues.type}
          getLuckyDrawDetail={getLuckyDrawDetail}
          isLoading={isLoading}
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
