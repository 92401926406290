/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import WinnerDialogComponent from "./winnerDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert, setLoading } from "modules/notification";
import {
  PRIZE_TYPE_PRESET,
  SET_WINNER_LUCKY_DRAW_LOADING,
} from "modules/lucky-draw-v2/constants";
import { getApiLang } from "app/feature/constants";

function WinnerDialogContainer({
  isOpen,
  luckyDrawUuid,
  handleClose,
  selectedLuckyDraw,
}) {
  const dispatch = useDispatch();

  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );

  const [excludeList, setExcludeList] = useState([]);
  const [prizeList, setPrizeList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const lang = useSelector((state) => state.constant.languages);

  const handleSave = async () => {
    const prizesUpdateInfo = [];
    for (const prize of prizeList) {
      const prizeWinners = {
        prizeId: prize.id,
        enc: [],
      };

      for (const winner of prize.winners) {
        if (!winner.status) prizeWinners.enc.push(winner.enc);
      }
      prizesUpdateInfo.push(prizeWinners);
    }
    dispatch(setLoading({ id: SET_WINNER_LUCKY_DRAW_LOADING, state: true }));
    dispatch(showBackdrop());
    await luckyDrawV2Api
      .presetWinner({
        luckyDrawUuid: luckyDrawUuid,
        winnerField: prizesUpdateInfo,
      })
      .then((response) => {
        handleClose();
      })
      .catch((error) => {
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        );
      })
      .finally(() => {
        dispatch(hideBackdrop());
        dispatch(
          setLoading({ id: SET_WINNER_LUCKY_DRAW_LOADING, state: false })
        );
      });
  };

  const fetchPrizes = useCallback(async () => {
    setIsFetching(true);
    const response = await luckyDrawV2Api
      .getPrizes({
        luckyDrawUuid: luckyDrawUuid,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsFetching(false);
      });

    return response;
  }, [luckyDrawUuid]);

  const handleAddWinner = (index) => {
    const newPrizeList = [...prizeList];
    const newPrize = { ...newPrizeList[index] };
    newPrize.winners.push({
      serialNumber: "",
      serialNumberId: "",
      isNew: true,
      prizeType: PRIZE_TYPE_PRESET,
      prize_type: PRIZE_TYPE_PRESET,
      status: null,
    });
    newPrizeList[index] = newPrize;
    setPrizeList(newPrizeList);
  };

  const handleDelete = (index, winnerIndex) => {
    const newPrizeList = [...prizeList];
    const newPrize = {
      ...newPrizeList[index],
      balance: newPrizeList[index].balance + 1,
    };
    newPrize.winners.splice(winnerIndex, 1);
    newPrizeList[index] = newPrize;
    setPrizeList(newPrizeList);

    let encs = newPrizeList.reduce((acc, prize) => {
      let winner = prize.winners;
      winner.forEach((element) => {
        if (element.enc) {
          acc.push({ enc: element.enc });
        }
      });

      return acc;
    }, []);
    setExcludeList(encs);
  };

  useEffect(() => {
    if (isOpen) {
      fetchPrizes().then((response) => {
        if (response.prizes) {
          let filteredList = response.prizes.reduce((list, prize) => {
            if (!Number(prize.is_default)) {
              prize.winners = prize.winners.map((winner) => {
                return {
                  ...winner,
                  prizeType: winner.prize_type,
                  serialNumber: winner.serial_number,
                  isNew: false,
                };
              });
              list.push(prize);
            }
            return list;
          }, []);

          setPrizeList(filteredList);
          setExcludeList(response.exclude_list);
        }
      });
    }
  }, [isOpen, fetchPrizes]);

  return (
    <WinnerDialogComponent
      luckyDrawUuid={luckyDrawUuid}
      hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
      handleClose={() => handleClose()}
      isOpen={isOpen}
      prizeList={prizeList}
      setPrizeList={setPrizeList}
      handleSave={handleSave}
      lang={lang}
      handleAddWinner={handleAddWinner}
      handleDelete={handleDelete}
      excludeList={excludeList}
      setExcludeList={setExcludeList}
      isFetching={isFetching}
      selectedLuckyDraw={selectedLuckyDraw}
    />
  );
}

export default WinnerDialogContainer;
