/* eslint-disable react-hooks/exhaustive-deps */
import {
  COMPANY_CURRENCY_VIEW,
  COMPANY_INFO_VIEW,
  COMPANY_PRODUCT_VIEW,
  COMPANY_STOCK_VIEW,
  DKSH_INTEGRATION_SETTING_VIEW,
} from "lib/constants/accessRights";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MainPageComponent from "./account.page";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import qs from "query-string";
import { ROOT } from "modules/account/config/account.route";

function MainPageContainer() {
  const history = useHistory();
  const location = useLocation();
  const hasCompanyInfoViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_INFO_VIEW)
  );
  const hasCompanyProductViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_PRODUCT_VIEW)
  );
  const hasCompanyCurrencyViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_CURRENCY_VIEW)
  );
  const hasCompanyStockViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_STOCK_VIEW)
  );
  const hasIntegrationViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(DKSH_INTEGRATION_SETTING_VIEW)
  );
  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const lang = useSelector((state) => state.constant.languages);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || "";

  const availableTabs = {
    info: getLang(lang, "label.INFO_DOCUMENT"),
    product: getLang(lang, "label.PRODUCT_PAGE_SETTING"),
    currency: getLang(lang, "label.CURRENCY_SETTING"),
    stock: getLang(lang, "label.STOCK_MODULE_SETTING"),
    engagement: getLang(lang, "label.CONSUMER_ENGAGEMENT"),
    integration: getLang(lang, "label.INTEGRATION_SETTINGS"),
  };

  const tabLabels = [];
  if (hasCompanyInfoViewAccessRight) tabLabels.push(availableTabs.info);
  if (hasCompanyProductViewAccessRight) tabLabels.push(availableTabs.product);
  if (hasCompanyCurrencyViewAccessRight) tabLabels.push(availableTabs.currency);
  if (hasCompanyStockViewAccessRight) tabLabels.push(availableTabs.stock);
  if (hasIntegrationViewAccessRight) tabLabels.push(availableTabs.integration);
  if (!!disbursementInfos.length) tabLabels.push(availableTabs.engagement);

  useEffect(() => {
    if (
      !hasCompanyInfoViewAccessRight &&
      !hasCompanyProductViewAccessRight &&
      !hasCompanyCurrencyViewAccessRight &&
      !hasCompanyStockViewAccessRight &&
      !hasIntegrationViewAccessRight &&
      !disbursementInfos.length
    ) {
      history.push("/admin/dashboard");
    }
  }, [
    history,
    hasCompanyInfoViewAccessRight,
    hasCompanyProductViewAccessRight,
    hasCompanyCurrencyViewAccessRight,
    hasCompanyStockViewAccessRight,
    hasIntegrationViewAccessRight,
    disbursementInfos,
  ]);

  useEffect(() => {
    let tabIndex = -1;
    switch (currentSearch) {
      case "info":
        tabIndex = tabLabels.findIndex((t) => t === availableTabs.info);
        break;
      case "product":
        tabIndex = tabLabels.findIndex((t) => t === availableTabs.product);
        break;
      case "currency":
        tabIndex = tabLabels.findIndex((t) => t === availableTabs.currency);
        break;
      case "stock":
        tabIndex = tabLabels.findIndex((t) => t === availableTabs.stock);
        break;
      case "integration":
        tabIndex = tabLabels.findIndex((t) => t === availableTabs.integration);
        break;
      case "engagement":
        tabIndex = tabLabels.findIndex((t) => t === availableTabs.engagement);
        break;
      default:
        tabIndex = 0;
    }
    if (tabIndex < 0) {
      history.replace({
        pathname: ROOT,
        search: `?tab=${Object.keys(availableTabs).find(
          (key) => availableTabs[key] === tabLabels[0]
        )}`,
      });
    }
    setActiveIndex(tabIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch]);

  if (
    !hasCompanyInfoViewAccessRight &&
    !hasCompanyProductViewAccessRight &&
    !hasCompanyCurrencyViewAccessRight &&
    !hasCompanyStockViewAccessRight &&
    !hasIntegrationViewAccessRight
  )
    return <LoadingComponent />;

  const handleChange = (event, index) => {
    setActiveIndex(index);
    let selectedTab = tabLabels[index];

    history.replace({
      pathname: ROOT,
      search: `?tab=${Object.keys(availableTabs).find(
        (key) => availableTabs[key] === selectedTab
      )}`,
    });
  };

  return (
    <>
      <MainPageComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        lang={lang}
        availableTabs={availableTabs}
      />
    </>
  );
}

export default MainPageContainer;
