import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import LoadingComponent from "components/loading";
import IntegrationSettingCard from "../../card/integrationSettingCard";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
}));

export default function IntegrationSettingPanelComponent({
  isLoading,
  integrations,
  handleUpdateData,
}) {
  const classes = useStyle();

  if (isLoading) {
    return (
      <Box
        className={classes.container}
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        <LoadingComponent />
      </Box>
    );
  }

  return (
    <Box>
      {integrations.map((integration, index) => (
        <IntegrationSettingCard
          integration={integration}
          isLoading={isLoading}
          key={index}
          handleUpdateData={(data) => handleUpdateData(index, data)}
        />
      ))}
    </Box>
  );
}
