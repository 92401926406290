import React, { useRef } from "react";
import {
  Backdrop,
  Box,
  Paper,
  IconButton,
  makeStyles,
  Typography,
  Chip,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";
import Dialog from "components/dialog/customDialog";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import { Search as SearchIcon } from "@material-ui/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";

const styles = makeStyles((theme) => ({
  root: {
    height: "80%",
  },
  selectRoot: {
    padding: 12,
    backgroundColor: "white",
  },
  container: {
    boxShadow: "0px 2px 5px 0px #00000026",
    borderRadius: "4px",
    borderTop: "0.5px solid #A0A0A04D",
  },
  content: {
    flex: 1,
  },
  list: {
    maxHeight: "400px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
}));

export default function LuckyDrawDuplicateDialogComponent({
  headerType,
  isOpen,
  handleClose,
  luckyDraws,
  handleSubmit,
  isFetching,
  totalFiltered,
  handleSearch,
  handleFetchMoreItems,
  setResponseBody,
  responseBody,
}) {
  const classes = styles();
  const lang = useSelector((state) => state.constant.languages);

  const typeInfo = {
    1: {
      label: getLang(lang, "label.LUCKY_DRAW"),
      color: "#11A420",
      backgroundColor: "#E5FFF4",
    },
    2: {
      label: getLang(lang, "label.INSTANT_WIN"),
      color: "#3C6FF0",
      backgroundColor: "#E5EDFF",
    },
  };
  const textRef = useRef();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      size="lg"
      fullWidth={true}
    >
      <Paper elevation={0} className={classes.root}>
        <Box px={3} pb={3} pt={2}>
          <Box
            className={classes.header}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, "label.DUPLICATE_LUCKY_DRAW")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box className={classes.container}>
              <Box p={1.5}>
                <InputTextField
                  style={{ width: "100%" }}
                  variant="filled"
                  size="small"
                  placeholder="Search"
                  margin="none"
                  inputRef={textRef}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #D8D8D8",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ paddingRight: 6 }}
                      >
                        <SearchIcon
                          style={{ color: "#3A4D5480", cursor: "pointer" }}
                          onClick={() => handleSearch(textRef.current.value)}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleSearch(textRef.current.value);
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                maxHeight={400}
                style={{
                  borderTop: "1px solid #ECEFF0",
                  overflowY: "auto",
                }}
              >
                <InfiniteScroll
                  height="100%"
                  className={classes.list}
                  dataLength={luckyDraws.length}
                  next={() => {
                    handleFetchMoreItems();
                  }}
                  hasMore={totalFiltered > luckyDraws.length}
                  loader={<LoadingComponent height={100} />}
                  endMessage={
                    isFetching ? (
                      <LoadingComponent height={100} />
                    ) : !luckyDraws.length ? (
                      <Box px={1.5} py={4}>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          <b>
                            {getLang(
                              lang,
                              "message.info.NO_LUCKY_DRAW_AVAILABLE"
                            )}
                          </b>
                        </Typography>
                      </Box>
                    ) : null
                  }
                >
                  {luckyDraws.map((luckyDraw, index) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      key={index}
                      p={1.5}
                      onClick={() =>
                        setResponseBody({
                          ...responseBody,
                          luckyDrawUuid: luckyDraw.uuid,
                        })
                      }
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          responseBody.luckyDrawUuid === luckyDraw.uuid
                            ? "#ECEFF0"
                            : "white",
                      }}
                    >
                      <Box display="flex" style={{ gap: "1rem" }}>
                        <Typography variant="body2">
                          {luckyDraw.title}
                        </Typography>
                        <Chip
                          label={
                            <Typography variant="caption">
                              {typeInfo[luckyDraw.type].label.toUpperCase()}
                            </Typography>
                          }
                          color="primary"
                          size="small"
                          outline="true"
                          fontWeight="800"
                          style={{
                            borderRadius: "4px",
                            backgroundColor:
                              typeInfo[luckyDraw.type].backgroundColor,
                            color: typeInfo[luckyDraw.type].color,
                          }}
                        />
                      </Box>
                      <Box display="flex" style={{ gap: "1rem" }}>
                        <Typography
                          variant="body2"
                          style={{ color: "#A0A0A0" }}
                        >
                          {luckyDraw.winners_count}{" "}
                          {getLang(lang, "label.WINNER")}{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: "#A0A0A0" }}
                        >
                          {luckyDraw.participants_count}{" "}
                          {getLang(lang, "label.PARTICIPANTS")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </InfiniteScroll>
              </Box>
            </Box>
            {/* <Box display="flex" alignItems="center" mt={1}>
              <Box mr={1}>
                <Typography variant="body2">
                  <b>{getLang(lang, "label.IMPORT_PARTICIPANT_CONDITION")}:</b>
                </Typography>
              </Box>
              <FormControl variant="outlined">
                <Select
                  value={responseBody.inheritType}
                  onChange={(e) => {
                    setResponseBody({
                      ...responseBody,
                      inheritType: e.target.value,
                    });
                  }}
                  classes={{ root: classes.selectRoot }}
                  style={{
                    color: responseBody.inheritType ? "black" : "#3A4D5480",
                  }}
                  placeholder={getLang(lang, "placeholder.SELECT_ONE")}
                  displayEmpty
                  renderValue={
                    !!responseBody.inheritType
                      ? undefined
                      : () => getLang(lang, "placeholder.SELECT_ONE")
                  }
                >
                  <MenuItem value={0}>-</MenuItem>
                  <MenuItem value={1}>
                    {getLang(lang, "label.IMPORT_ALL_PARTICIPANTS")}
                  </MenuItem>
                  <MenuItem value={2}>
                    {getLang(lang, "label.EXCLUDE_WINNERS_ONLY")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box> */}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button variant="outlined" type="button" onClick={handleClose}>
                {getLang(lang, "label.CANCEL")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                type="button"
                disabled={!responseBody.luckyDrawUuid}
                onClick={() => handleSubmit()}
              >
                {getLang(lang, "label.CONFIRM")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
