import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/prizes";

const getPrizes = ({
  luckyDrawUuid
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          luckyDrawUuid: luckyDrawUuid,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getPrizes;
