import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import PhoneMockUpComponent from "./phoneMockUp.component";
import { getLang } from "app/feature/constants";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";
import LuckyDrawDetailPreviewPanel from "../luckyDrawDetailPreviewPanel";
import InstantWinDetailPreviewPanel from "../instantWinDetailPreviewPanel";

const useStyle = makeStyles((theme) => ({
  rootPreview: {
    display: "block",
    background: "white",
    position: "relative",
    width: "100%",
    maxWidth: 380,
    "&:hover iframe": {
      pointerEvents: "none",
    },
  },
  appBar: {
    padding: 12,
    transition: "all 400ms ease",
    position: "relative",
    zIndex: "99",
  },
  title: {
    zIndex: "1",
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: "20px",
  },
  gridTitle: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  componentWrapper: {
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function InteractiveEditorComponent({ luckyDraw, lang }) {
  const classes = useStyle();
  const InteractiveEditorNode = (
    <PerfectScrollbar className={classes.rootPreview}>
      {/* {!previewMode && (
        <Box position="relative" width="230px" height="100%"></Box>
      )} */}
      <Box className={classes.appBar}>
        <Grid container>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            style={{ width: "100%", alignSelf: "center" }}
          >
            <IconButton disabled size="small" style={{ color: "black" }}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8} sm={8} md={8} className={classes.gridTitle}>
            <Box className={classes.title} style={{ color: "black" }}>
              <Typography>
                <b>
                  {luckyDraw.type === 2
                    ? getLang(lang, "label.INSTANT_WIN")
                    : getLang(lang, "label.LUCKY_DRAW")}
                </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} md={2} />
        </Grid>
      </Box>
      <Box style={{ background: "white" }}>
        <Box className={classes.componentWrapper}>
          {luckyDraw.type === 2 ? (
            <InstantWinDetailPreviewPanel instantWin={luckyDraw} lang={lang} />
          ) : (
            <LuckyDrawDetailPreviewPanel luckyDraw={luckyDraw} lang={lang} />
          )}
        </Box>
      </Box>
    </PerfectScrollbar>
  );

  return <PhoneMockUpComponent>{InteractiveEditorNode}</PhoneMockUpComponent>;
}
