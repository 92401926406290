/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import DrawTextComponent from "./drawText.component";

function DrawTextContainer({
  text,
  isNewDraw,
  drawTime, // in miliseconds,
  needCensor = false,
}) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const stringLength = 10;

  const [message, setMessage] = useState("");
  const [isGenerating, setIsGenerating] = useState(true);

  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);
  const startTimeRef = useRef(null);
  const lastUpdateRef = useRef(0);
  const updateInterval = 100; // Slow down the updates to every 100 milliseconds

  useEffect(() => {
    let tempText = generateCensorText(text);

    if (text && isNewDraw) {
      if (isGenerating) {
        requestAnimationFrame(generateStringWithRequestAnimationFrame);
      } else {
        setMessage(tempText);
      }
    } else {
      setMessage(tempText);
    }

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, [text, isNewDraw, drawTime, isGenerating]);

  const generateCensorText = (text) => {
    let tempText = text;
    if (tempText && needCensor)
      if (tempText.length <= 1) {
        // tempText = text
      } else if (tempText.length < 4) {
        tempText = tempText.slice(0, -1) + "*";
      } else {
        tempText = tempText.slice(0, -4) + "****";
      }

    return tempText;
  };

  const generateStringWithRequestAnimationFrame = (timestamp) => {
    if (!startTimeRef.current) startTimeRef.current = timestamp;

    const elapsed = timestamp - startTimeRef.current;
    const timeSinceLastUpdate = timestamp - lastUpdateRef.current;

    let tempText = generateCensorText(text);

    if (elapsed < drawTime) {
      // Only update every 'updateInterval' milliseconds
      if (timeSinceLastUpdate >= updateInterval) {
        setMessage(generateRandomString());
        lastUpdateRef.current = timestamp; // Update last update time
      }
      requestAnimationFrame(generateStringWithRequestAnimationFrame); // Recursive call to the next frame
    } else {
      setMessage(tempText);
      setIsGenerating(false); // Stop generation after drawTime
    }
  };

  const generateRandomString = () => {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < stringLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  return <DrawTextComponent message={{ message }} />;
}

export default DrawTextContainer;
