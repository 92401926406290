import axios from "axios";

export function getTopSuspendedQrTrending({
  country,
  city_id,
  branch_uuid,
  category_uuid,
  brand_uuid,
  model_uuid,
  start_date,
  end_date,
  url
}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        country,
        city_id,
        branch_uuid,
        category_uuid,
        brand_uuid,
        model_uuid,
        start_date,
        end_date,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export default getTopSuspendedQrTrending;
