import React, { Fragment, useRef } from "react";
import {
  Typography,
  Box,
  Divider,
  MenuItem,
  MenuList,
  IconButton,
  InputAdornment,
  Checkbox,
  Popover,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import { Search as SearchIcon } from "@material-ui/icons";

export default function BranchSelectPanelComponent({
  anchorEl,
  open,
  handleClose,
  style,
  branchDropdown,
  handleSearch,
  handleSelectAll,
  isFetching,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  value,
  lang,
  isEmpty,
  selectAll,
}) {
  const textRef = useRef();

  return (
    <Popover
      id="branch-select-panel"
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: "27em" },
        ...PaperProps,
      }}
    >
      <Box>
        <Fragment>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 6 }}>
                  <IconButton
                    onClick={() => handleSearch(textRef.current.value)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0,
              },
            }}
            inputProps={{
              onChange: (e) => {
                handleSearch(textRef.current.value);
              },
              style: {
                padding: "8px",
              },
            }}
            placeholder={getLang(lang, "placeholder.SEARCH")}
          />
        </Fragment>
        <Divider />
        <MenuList id="menu-list-grow" style={{ padding: "0px" }}>
          <MenuItem
            onClick={() => {
              handleSelectAll(!selectAll);
            }}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Checkbox checked={!!selectAll} />
            <Typography variant="inherit" noWrap style={{ paddingLeft: "8px" }}>
              {getLang(lang, "label.ALL")}
            </Typography>
          </MenuItem>
        </MenuList>
        <Divider />
        {isFetching ? (
          <Box p={1}>
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
          </Box>
        ) : (
          <div>
            <Box height="25vh" overflow="auto">
              {branchDropdown.map((field, index) => {
                return (
                  <React.Fragment key={index}>
                    <MenuItem
                      onClick={() => handleChange(field)}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Checkbox
                        checked={
                          selectAll ||
                          value?.find((v) => v.node_id === field.node_id)
                            ? true
                            : false
                        }
                      />
                      <Typography
                        variant="inherit"
                        noWrap
                        style={{ paddingLeft: "8px" }}
                      >
                        {field.name}
                      </Typography>
                    </MenuItem>
                  </React.Fragment>
                );
              })}
            </Box>
          </div>
        )}
      </Box>
    </Popover>
  );
}
