import React, { useContext } from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { getLang } from "app/feature/constants";
import { PrizeContext } from "../../dialog/tranditionalRaffleWinnerDialog/tranditionalRaffleWinnerDialog.container";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 55,
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}));

export default function ViewTranditionalRaffleWinnerTableComponent({
  data,
  lang,
}) {
  const classes = useStyle();
  const { handleUpdateClaimFulfillment, isUpdating, luckyDraw } =
    useContext(PrizeContext);

  const finishDraw =
    luckyDraw?.draw_end_at &&
    moment.utc().isAfter(moment.utc(luckyDraw.draw_end_at));

  return (
    <>
      {data.length > 0 ? (
        <TableContainer style={{ borderRadius: "10px" }}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.WINNER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PHONE_NUMBER_LAST_FOUR_DIGIT")}
                </StyledTableCell>
                {finishDraw && (
                  <>
                    <StyledTableCell>
                      {getLang(lang, "label.CLAIM_PRIZE_STATUS")}
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                return (
                  <StyledTableRow key={index} hover tabIndex={-1}>
                    <StyledTableCell>
                      {(index + 1).toLocaleString("en-US")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.serial_number ? item.serial_number : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.participant?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.participant?.contact_no
                        ? "*******" +
                          item.participant?.contact_no.slice(
                            item.participant?.contact_no.length - 4
                          )
                        : "-"}
                    </StyledTableCell>
                    {finishDraw && (
                      <>
                        <StyledTableCell>
                          {item.status !== "completed" ? (
                            <Typography
                              variant="caption"
                              style={{ color: "#E21B1B" }}
                            >
                              {getLang(lang, "label.NOT_READY_TO_CLAIM")}
                            </Typography>
                          ) : Number(item.claim_fulfill_status) === 1 ? (
                            <Typography
                              variant="caption"
                              style={{ color: "#A0A0A0" }}
                            >
                              {getLang(lang, "label.CLAIM_DONE")}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              style={{ color: "#6AAF68" }}
                            >
                              {getLang(lang, "label.READY_TO_CLAIM")}
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" justifyContent="center">
                            {item.status !== "completed" ? (
                              <Typography
                                variant="caption"
                                style={{ color: "#E21B1B" }}
                              >
                                -
                              </Typography>
                            ) : Number(item.claim_fulfill_status) === 1 ? (
                              <Button
                                variant="contained"
                                style={{
                                  color: "#FFFFFF",
                                }}
                                onClick={() =>
                                  handleUpdateClaimFulfillment(0, item.uuid)
                                }
                                disabled={isUpdating}
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    color: "black",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {getLang(lang, "label.REVERT")}
                                </Typography>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleUpdateClaimFulfillment(1, item.uuid)
                                }
                                disabled={isUpdating}
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    color: "#ffffff",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {getLang(lang, "label.COMPLETE_CLAIM")}
                                </Typography>
                              </Button>
                            )}
                          </Box>
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.WINNER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PHONE_NUMBER_LAST_FOUR_DIGIT")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_WINNER")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
