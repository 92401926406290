/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntegrationSettingPanelComponent from "./integrationSettingPanel.component";
import { useHistory } from "react-router-dom";
import { DKSH_INTEGRATION_SETTING_VIEW } from "lib/constants/accessRights";
import { DASHBOARD } from "modules/dashboard/constants";
import accountApi from "app/api/account";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export default function IntegrationSettingPanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory;
  const lang = useSelector((state) => state.constant.languages);

  const hasIntegrationViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(DKSH_INTEGRATION_SETTING_VIEW)
  );

  const [isLoading, setIsLoading] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (hasIntegrationViewAccessRight) {
      getData();
    } else {
      history.push(DASHBOARD);
    }
  }, [history, dispatch, hasIntegrationViewAccessRight]);

  const getData = () => {
    setIsLoading(true);
    accountApi
      .getIntegrationSetting()
      .then((res) => {
        if (isMounted.current) {
          let temp = res.data.map((item) => {
            let settingGroups = [];

            let settings = {};
            item.settings.forEach((element) => {
              if (settings.hasOwnProperty(element.group)) {
                settings[element.group].push(element);
              } else {
                settings[element.group] = [element];
              }
            });

            Object.keys(settings).forEach((key) => {
              settingGroups.push({ group: key, settings: settings[key] });
            });

            item["settings"] = settingGroups;

            return item;
          });
          setIntegrations(temp);
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  };

  const handleUpdateData = (index, data) => {
    let temp = [...integrations];
    temp[index] = {
      ...data,
      is_setup: true,
    };
    setIntegrations(temp);
  };

  return (
    <IntegrationSettingPanelComponent
      isLoading={isLoading}
      integrations={integrations}
      handleUpdateData={handleUpdateData}
    />
  );
}
