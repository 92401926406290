import axios from "axios";

const endpoint_url = "/account/api/v1/users";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          draw: parameters.draw,
          length: parameters.length,
          start: parameters.start,
          column: parameters.column,
          order: parameters.order,
          search: parameters.search,
          type: parameters.type,
          value: parameters.value,
          user_group_id: parameters.userGroupId,
          node_id: parameters.nodeId
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllEmployee = {
  get
};

export default getAllEmployee;
