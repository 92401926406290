/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WinRateInfoFormComponent from "./winRateInfoForm.component";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export default function WinRateInfoFormContainer({
  uuid,
  initialValues,
  setCurrentStep,
  getLuckyDrawDetail,
  type,
  currentStep,
  ldCurrentState,
  progress,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disbursements, setDisbursements] = useState([]);

  const formRef = useRef();

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    setDisbursements(disbursementInfos);
  }, [disbursementInfos]);

  useEffect(() => {
    if (initialValues && formRef.current) {
      let hasDefaultPrize = initialValues.prizes?.some((v) => !!v.isDefault);
      let prizes = initialValues.prizes ?? [];

      let totalWinRate = prizes.length
        ? prizes.reduce((total, prize) => {
            if (prize.winRate && !Number(prize.isDefault)) {
              total += Number(prize.winRate);
            }
            return total;
          }, 0)
        : 0;
      let remainingWinRate = 100 - totalWinRate;

      formRef.current.setValues({
        prizes: initialValues.prizes ?? [],
        enableLossState: hasDefaultPrize,
        hasWinRate: Number(initialValues.hasWinRate || 0),
        remainingWinRate: remainingWinRate,
        totalWinRate: totalWinRate,
      });
    }
  }, [initialValues]);

  const handleSubmit = async (values) => {
    if (values.isEditing) {
      setIsSubmitting(true);
      let prizes = values.prizes.map((prize) => ({
        id: prize.id,
        uuid: prize.uuid,
        sequence: prize.sequence,
        winRate: values.hasWinRate
          ? prize.isDefault
            ? values.remainingWinRate
            : prize.winRate
          : 0,
      }));

      const body = {
        luckyDrawUuid: uuid || "74399954478e4634862c7d9acaf62206",
        prizes: prizes,
        hasWinRate: values.hasWinRate,
      };
      luckyDrawV2Api
        .updateWinRate(body)
        .then(() => {
          if (isMounted.current) {
            getLuckyDrawDetail(uuid);

            const params = new URLSearchParams(window.location.search);
            params.set(
              "step",
              values.goToPreview ? (type === 2 ? 6 : 5) : currentStep + 2
            );
            window.history.replaceState(null, null, `?${params}`);
            setCurrentStep(
              values.goToPreview ? (type === 2 ? 5 : 4) : currentStep + 1
            );
          }
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
        })
        .finally(() => {
          if (isMounted.current) {
            setIsSubmitting(false);
          }
        });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.set(
        "step",
        values.goToPreview ? (type === 2 ? 6 : 5) : currentStep + 2
      );
      window.history.replaceState(null, null, `?${params}`);
      setCurrentStep(
        values.goToPreview ? (type === 2 ? 5 : 4) : currentStep + 1
      );
    }
  };

  const handlePrevious = () => {
    const params = new URLSearchParams(window.location.search);
    params.set("step", currentStep);
    window.history.replaceState(null, null, `?${params}`);
    setCurrentStep(currentStep - 1);
  };

  return (
    <WinRateInfoFormComponent
      uuid={uuid}
      initialValues={initialValues}
      setCurrentStep={setCurrentStep}
      lang={lang}
      handleSubmitForm={handleSubmit}
      isSubmitting={isSubmitting}
      formRef={formRef}
      handlePrevious={handlePrevious}
      type={type}
      disbursementInfos={disbursements}
      ldCurrentState={ldCurrentState}
      progress={progress}
    />
  );
}
