import React, { useState } from "react";
import { useSelector } from "react-redux";
import WithProductDateProgressSearchComponent from "./withProductDateProgressSearch.component";

export default function WithProductGeneralSearchContainer({
  handleFilter,
  isFetchingProduct,
  productDropdown,
}) {
  const lang = useSelector(state => state.constant.languages)
  const [productsSelected, setProductsSelected] = useState([]);
  const [startPeriod, setStartPeriod] = useState();
  const [endPeriod, setEndPeriod] = useState();
  const [progressSelected, setProgressSelected] = useState([]);
  const [clearDate, setClearDate] = useState(false);

  const handleSearch = async (searchString) => {
    await handleFilter({
      searchString: searchString,
      product: productsSelected,
      startPeriod: startPeriod,
      endPeriod: endPeriod,
      progress: progressSelected
    });
  };

  const handleClear = async () => {
    setProductsSelected([]);
    setStartPeriod(null);
    setEndPeriod(null);
    setProgressSelected([]);
    setClearDate(true);
    await handleFilter({
      searchString: "",
      product: [],
      startPeriod: null,
      endPeriod: null,
      progress: []
    });
    setClearDate(false);
  }

  return <WithProductDateProgressSearchComponent
    handleSearch={handleSearch}
    lang={lang}
    isFetchingProduct={isFetchingProduct}
    productDropdown={productDropdown}
    productsSelected={productsSelected}
    setProductsSelected={setProductsSelected}
    progressSelected={progressSelected}
    setProgressSelected={setProgressSelected}
    startPeriod={startPeriod}
    setStartPeriod={setStartPeriod}
    endPeriod={endPeriod}
    setEndPeriod={setEndPeriod}
    handleClear={handleClear}
    clearDate={clearDate}
  />
}
