import React from "react";
import { makeStyles, ButtonBase, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    height: 43,
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default function SetTranditionalRaffleWinnerSelectComponent({
  handleClick,
  value,
  placeholder,
}) {
  const classes = useStyle();
  const isEmpty = value?.serial_number === null ? true : false;
  const canEdit =
    value?.serial_number !== null
      ? value?.status === null
        ? true
        : false
      : true;

  if (canEdit) {
    return (
      <ButtonBase className={classes.root} disableRipple onClick={handleClick}>
        <Typography
          variant="body2"
          className={clsx(classes.inputText, !isEmpty && classes.filled)}
        >
          {!value?.serial_number ? placeholder : value.serial_number}
        </Typography>
        <ArrowDropDownIcon fontSize="small" />
      </ButtonBase>
    );
  } else {
    return (
      <ButtonBase className={classes.root} disableRipple>
        <Typography
          variant="body2"
          className={clsx(classes.inputText, !isEmpty && classes.filled)}
        >
          {!value?.serial_number ? placeholder : value.serial_number}
        </Typography>
      </ButtonBase>
    );
  }
}
