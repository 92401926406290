import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASSIGN_BATCH } from "../../constants";
import serialNumberApi from "app/api/serialnumber";

export const getAssignListing = createAsyncThunk(
  `${ASSIGN_BATCH}/getAssignListing`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAssignListing(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAssignProgress = createAsyncThunk(
  `${ASSIGN_BATCH}/getAssignProgress`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAssignProgress(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAssignFailJob = createAsyncThunk(
  `${ASSIGN_BATCH}/getAssignFailJob`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAssignFailJob(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const retryAssignFailJob = createAsyncThunk(
  `${ASSIGN_BATCH}/retryAssignFailJob`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .retryAssignFailJob(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const unassignSerialNumber = createAsyncThunk(
  `${ASSIGN_BATCH}/unassignSerialNumber`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .unassignSerialNumber(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getUnassignListing = createAsyncThunk(
  `${ASSIGN_BATCH}/getUnassignListing`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getUnassignListing(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getUnassignProgress = createAsyncThunk(
  `${ASSIGN_BATCH}/getUnassignProgress`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getUnassignProgress(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const retryUnassignFailJob = createAsyncThunk(
  `${ASSIGN_BATCH}/retryUnassignFailJob`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .retryUnassignFailJob(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getUnassignSnListByProduct = createAsyncThunk(
  `${ASSIGN_BATCH}/getUnassignSnListByProduct`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getUnassignSnListByProduct(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const checkSNUnassignAvailability = createAsyncThunk(
  `${ASSIGN_BATCH}/checkSNUnassignAvailability`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .checkSNUnassignAvailability(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getUnassignDetail = createAsyncThunk(
  `${ASSIGN_BATCH}/getUnassignDetail`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getUnassignDetail(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
