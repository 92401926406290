import React, { useEffect, useState } from "react";
import UnassignListingComponent from "./unassignListing.page";
import { useDispatch, useSelector } from "react-redux";
import { getUnassignListing, retryUnassignFailJob } from "../../redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import ConfirmationDialog from "components/dialog/confirmation";
import UnassignDialog from "modules/serial-number/components/dialog/unassignDialog";
import { unwrapResult } from "@reduxjs/toolkit";
import { getLang } from "app/feature/constants";
import UnassignDetailDialog from "modules/serial-number/components/dialog/unassignDetailDialog";

function UnassignListingContainer() {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const productDropdown = useSelector((state) => state.products.allProducts);
  const unassignListData = useSelector(
    (state) => state.assignBatch.unassignTable.data
  );
  const totalRecords = useSelector(
    (state) => state.assignBatch.unassignTable.totalRecords
  );
  const totalFiltered = useSelector(
    (state) => state.assignBatch.unassignTable.totalFiltered
  );
  const isError = useSelector(
    (state) => state.assignBatch.unassignTable.isError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getUnassignListing.typePrefix)
  );

  const isSearchResult = totalFiltered < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    nodes: [],
    node_uuids: [],
    status: "",
  });
  const [retryLoading, setRetryLoading] = useState(false);
  const [showRetryConfirmDialog, setShowRetryConfirmDialog] = useState(false);
  const [selectedRetryBatch, setSelectedRetryBatch] = useState(null);
  const [selectedViewBatch, setSelectedViewBatch] = useState(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tableFilter]);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    dispatch(
      getUnassignListing({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        column: tableFilter.orderBy,
        search: tableFilter.search,
        node_uuids: tableFilter.node_uuids,
        status: tableFilter.status.toLowerCase(),
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    let tempItem = values.nodes.map((item) => item.id);

    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      nodes: values.nodes,
      node_uuids: tempItem,
      status: values.status,
    };
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      nodes: [],
      node_uuids: [],
    };
    setTableFilter(newData);
  };

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    };
    setTableFilter(newData);
  };

  const selectBatchForRetry = (batch) => {
    setSelectedRetryBatch(batch);
    setShowRetryConfirmDialog(true);
  };

  const retryUnassign = () => {
    setRetryLoading(true);
    dispatch(retryUnassignFailJob(selectedRetryBatch.id))
      .then(unwrapResult)
      .then(() => {
        getData();
      })
      .finally(() => {
        setRetryLoading(false);
        setSelectedRetryBatch(null);
        setShowRetryConfirmDialog(false);
      });
  };

  const viewDetail = (batch) => {
    setSelectedViewBatch(batch);
  };

  const handleReload = () => {
    let newData = {
      ...tableFilter,
      page: 0,
    };
    setTableFilter(newData);
  };

  return (
    <>
      <UnassignListingComponent
        assignList={unassignListData}
        isFetching={isFetching}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        tableFilter={tableFilter}
        totalFiltered={totalFiltered}
        isError={isError}
        handleSearch={handleSearch}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
        isSearchResult={isSearchResult}
        clearSearch={clearSearch}
        changeOrder={changeOrder}
        language={language}
        productDropdown={productDropdown}
        retryUnassign={selectBatchForRetry}
        viewDetail={viewDetail}
      />
      <UnassignDialog handleReload={handleReload} />
      <UnassignDetailDialog
        isOpen={!!selectedViewBatch}
        handleClose={() => setSelectedViewBatch(null)}
        unassign={selectedViewBatch}
      />
      <ConfirmationDialog
        isLoading={retryLoading}
        isOpen={showRetryConfirmDialog}
        handleClose={() => setShowRetryConfirmDialog(false)}
        handleProceed={retryUnassign}
        title={getLang(lang, "label.RETRY_SERIAL_NUMBER_ASSIGN")}
        description={getLang(lang, "paragraph.ASSIGN_SN_RETRY_CONFIRMATION")}
        type="default"
        proceedLabel={getLang(lang, "label.RETRY")}
      />
    </>
  );
}

export default UnassignListingContainer;
