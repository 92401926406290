import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/bindSerialNumber";

const luckyDrawBinding = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, parameters)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default luckyDrawBinding;
