import React from "react";
import {
  Box,
  ButtonBase,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { UploadImg } from "modules/profile/utils/static";
import { getLang } from "app/feature/constants";
import { ErrorMessage, Formik } from "formik";
import { isImage, isFileExceedLimit } from "lib/helper";
import classnames from "classnames";
import htmlToDraft from "html-to-draftjs";
import clsx from "clsx";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import { useHistory } from "react-router";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import * as Yup from "yup";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import AntSwitch from "components/switch";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
  },
  avatar: {
    height: 282,
    width: 282,
    border: "1px solid",
    borderRadius: "20px",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "contain",
      flexShrink: 0,
    },
  },
  logo: {
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "contain",
    flexShrink: 0,
  },
  label: {
    textTransform: "none",
  },
  input: {
    display: "none",
  },
  mr1: {
    // backgroundColor: "#4D626A",
    color: "#3A4D5480",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#C5C5C5",
    },
    display: "flex",
    flexFlow: "column",
  },
  text: {
    color: theme.palette.secondary.main,
  },
  dragging: {
    backgroundColor: "#D8D8D8",
    border: "dashed 2px",
    borderRadius: "8px",
  },
  editor: {
    border: "1px solid #D8D8D8",
    borderRadius: theme.spacing(0.5),
  },
  errorEditor: {
    border: "1px solid #f44336",
    borderRadius: theme.spacing(0.5),
  },
  dropzoneContainer: {
    border: "1px dashed #D8D8D8",
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#D8D8D84D",
    padding: theme.spacing(2),
  },
  dropdownToolbar: {
    border: "none",
  },
  required: {
    "&::after": {
      content: "' *'",
      color: "#E21B1B",
    },
  },
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  ldImage: {
    maxHeight: 220,
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const transformHTMLToRaw = (html) => {
  const blocksFromHTML = htmlToDraft(html);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(content);
};

export default function BasicInfoFormComponent({
  initialValues,
  handleDrop,
  handleDragEnter,
  handleDragLeave,
  dragging,
  lang,
  handleSubmitForm,
  isSubmitting,
  formRef,
  uuid,
  progress,
  ldCurrentState,
  type,
  enableGameRules,
  handleGameRulesChange,
}) {
  const classes = useStyles();
  const history = useHistory();
  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense",
  };

  function isValidURL(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  let isPublished = progress && progress === progressStatus.published;
  let isEditable =
    !isPublished ||
    !ldCurrentState ||
    (ldCurrentState !== ldState.ended && ldCurrentState !== ldState.drawStart);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        name: initialValues?.name ?? "",
        description: initialValues?.description ?? "",
        image: initialValues?.image ?? "",
        imagePath: isImage(initialValues?.image)
          ? URL.createObjectURL(initialValues?.image)
          : "",
        gameRulesType: initialValues?.gameRulesType ?? "text",
        rules:
          initialValues?.gameRulesType === "text"
            ? transformHTMLToRaw(initialValues?.rules ?? "<p></p>")
            : EditorState.createEmpty(),
        gameRulesFile:
          initialValues?.gameRulesType === "file" ||
            initialValues?.gameRulesType === "link"
            ? initialValues?.rules ?? ""
            : "",
        gameRulesLink:
          initialValues?.gameRulesType === "link"
            ? initialValues?.rules ?? ""
            : "",
        tncType: initialValues?.tncType ?? "text",
        tnc:
          initialValues?.tncType === "text"
            ? transformHTMLToRaw(initialValues?.tnc ?? "<p></p>")
            : EditorState.createEmpty(),
        tncFile:
          initialValues?.tncType === "file" ? initialValues?.tnc ?? "" : "",
        tncLink:
          initialValues?.tncType === "link" ? initialValues?.tnc ?? "" : "",
        showConfirmDialog: false,
        isEditing: false,
        goToPreview: false,
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .trim()
          .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        tncType: Yup.string()
          .trim()
          .matches(/(file|text|link)/),
        gameRulesType: Yup.string()
          .trim()
          .matches(/(file|text|link)/),
        image: Yup.lazy((value) => {
          if (value instanceof File) {
            return Yup.mixed()
              .test(
                "fileSize",
                getLang(lang, "message.error.FILE_TOO_LARGE"),
                (v) => {
                  return !isFileExceedLimit(v.size, 10, "MB");
                }
              )
              .test(
                "fileType",
                getLang(lang, "message.error.FILE_NOT_IMAGE"),
                (v) => {
                  return isImage(v);
                }
              );
          } else {
            return Yup.string().required(
              getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
            );
          }
        }),
        rules: Yup.mixed().when("gameRulesType", {
          is: "text",
          then: enableGameRules
            ? Yup.mixed()
              .test("is-empty", getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"), (value) => {
                // Check if the editor content is empty
                const content = value?.getCurrentContent().getPlainText().trim();
                return content !== ""; // Returns true if there's content
              })
            : Yup.mixed(),
        }),
        gameRulesFile: Yup.mixed().when("gameRulesType", {
          is: "file",
          then: enableGameRules ? Yup.lazy((value) => {
            if (value instanceof File) {
              return Yup.mixed()
                .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .test(
                  "fileSize",
                  getLang(lang, "message.error.FILE_TOO_LARGE"),
                  (value) => !isFileExceedLimit(value.size, 12, "MB")
                )
                .test(
                  "fileFormat",
                  getLang(lang, "message.error.INVALID_FILE_TYPE"),
                  (value) =>
                    value &&
                    [
                      "application/pdf",
                      "application/msword", // doc
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
                    ].includes(value.type)
                );
            } else {
              return Yup.string().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              );
            }
          }) : null,
          otherwise: Yup.mixed().nullable(),
        }),
        gameRulesLink: Yup.mixed().when("gameRulesType", {
          is: "link",
          then: enableGameRules ? Yup.string()
            .url(getLang(lang, "message.error.INVALID_URL"))
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
            : Yup.string()
        }),
        tnc: Yup.mixed().when("tncType", {
          is: "text",
          then: Yup.mixed()
            .test("is-empty", getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"), (value) => {
              // Check if the editor content is empty
              const content = value?.getCurrentContent().getPlainText().trim();
              return content !== ""; // Returns true if there's content
            }),
          otherwise: Yup.mixed(),
        }),
        tncFile: Yup.mixed().when("tncType", {
          is: "file",
          then: Yup.lazy((value) => {
            if (value instanceof File) {
              return Yup.mixed()
                .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .test(
                  "fileSize",
                  getLang(lang, "message.error.FILE_TOO_LARGE"),
                  (value) => !isFileExceedLimit(value.size, 12, "MB")
                )
                .test(
                  "fileFormat",
                  getLang(lang, "message.error.INVALID_FILE_TYPE"),
                  (value) =>
                    value &&
                    [
                      "application/pdf",
                      "application/msword", // doc
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
                    ].includes(value.type)
                );
            } else {
              return Yup.string().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              );
            }
          }),
          otherwise: Yup.mixed().nullable(),
        }),
        tncLink: Yup.mixed().when("tncType", {
          is: "link",
          then: Yup.string()
            .url(getLang(lang, "message.error.INVALID_URL"))
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        }),
      })}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        getFieldProps,
        resetForm,
      }) => (
        <Box component={"form"} onSubmit={handleSubmit} mt={2}>
          <Paper style={{ padding: "20px" }} elevation={0}>
            <Box
              mb={2.5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                <b>
                  {getLang(
                    lang,
                    type === 2
                      ? "label.INSTANT_WIN_INFORMATION"
                      : "label.LUCKY_DRAW_INFORMATION"
                  )}
                </b>
              </Typography>
              {isEditable && (
                <Button
                  style={{
                    backgroundColor: "#ff1515",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setFieldValue("showConfirmDialog", true);
                  }}
                  disabled={isSubmitting}
                >
                  <Typography variant="body2" style={{ color: "white" }}>
                    <b>{getLang(lang, "label.CLEAR_ALL")}</b>
                  </Typography>
                </Button>
              )}
            </Box>

            <div style={{ marginTop: "20px" }}>
              <Typography
                variant="body2"
                className={clsx(classes.required, classes.fieldLabel)}
              >
                {getLang(
                  lang,
                  type === 2
                    ? "label.INSTANT_WIN_NAME"
                    : "label.LUCKY_DRAW_NAME"
                )}
              </Typography>
              <TextField
                {...textFieldProps}
                {...getFieldProps("name")}
                type={"text"}
                placeholder={getLang(
                  lang,
                  type === 2
                    ? "placeholder.ENTER_INSTANT_WIN_NAME"
                    : "placeholder.ENTER_LUCKY_DRAW_NAME"
                )}
                name="name"
                value={values.name}
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                  if (!values.isEditing) {
                    setFieldValue("isEditing", true);
                  }
                }}
                error={touched.name && errors.name}
                helperText={ErrorMessage({ name: "name" })}
                disabled={isSubmitting || !isEditable}
                inputProps={{
                  style: { color: "black" },
                }}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <Typography variant="body2" className={classes.fieldLabel}>
                {getLang(lang, "label.DESCRIPTION")}
              </Typography>
              <TextField
                {...textFieldProps}
                {...getFieldProps("description")}
                type={"text"}
                placeholder={getLang(lang, "placeholder.DESCRIPTION")}
                name="description"
                value={values.description}
                onChange={(e) => {
                  setFieldValue("description", e.target.value);
                  if (!values.isEditing) {
                    setFieldValue("isEditing", true);
                  }
                }}
                disabled={isSubmitting || !isEditable}
                inputProps={{
                  style: { color: "black" },
                }}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <Typography
                variant="body2"
                className={clsx(classes.required, classes.fieldLabel)}
              >
                {getLang(
                  lang,
                  type === 2
                    ? "label.INSTANT_WIN_IMAGE"
                    : "label.LUCKY_DRAW_IMAGE"
                )}
              </Typography>

              <Box>
                <Typography
                  variant="caption"
                  style={{
                    color: "#818D91",
                    marginBottom: 8,
                  }}
                >
                  {getLang(lang, "paragraph.RECOMMENDED_LD_BANNER_SPECS")}
                </Typography>
                {/* {Boolean(values.image) &&
                    typeof values.image === "string" &&
                    isValidURL(values.image) && (
                      <a
                        href={values.image}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginBottom: "4px",
                        }}
                      >
                        {new URL(values.image).pathname.split("/").pop()}
                      </a>
                    )} */}
                <Box className={classes.dropzoneContainer}>
                  <Dropzone
                    onDrop={(file) => {
                      setFieldValue("image", file[0]);
                      setFieldValue("imagePath", URL.createObjectURL(file[0]));
                      if (!values.isEditing) {
                        setFieldValue("isEditing", true);
                      }

                      handleDrop(file);
                    }}
                    noClick
                    noKeyboard
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    disabled={isSubmitting || !isEditable}
                  >
                    {({ getRootProps }) => (
                      <div {...getRootProps()}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          name="image"
                          onChange={(e) => {
                            let imgFile = e.currentTarget.files[0];
                            if (!values.isEditing) {
                              setFieldValue("isEditing", true);
                            }
                            setFieldValue(
                              "imagePath",
                              URL.createObjectURL(imgFile)
                            );
                            setFieldValue("image", imgFile);
                            // handleImgChange(e);
                          }}
                          onClick={(e) => (e.target.value = null)}
                          disabled={isSubmitting || !isEditable}
                        />
                        <label htmlFor="contained-button-file">
                          <ButtonBase
                            component="span"
                            className={classnames(classes.mr1, {
                              [classes.dragging]: dragging,
                            })}
                          >
                            {values.imagePath ? (
                              <img
                                src={values.imagePath}
                                alt="ldImage"
                                className={classes.ldImage}
                              />
                            ) : Boolean(values.image) &&
                              typeof values.image === "string" &&
                              isValidURL(values.image) ? (
                              <img
                                src={values.image}
                                alt="ldImage"
                                className={classes.ldImage}
                              />
                            ) : (
                              <UploadImg color="#3A4D5480" />
                            )}
                            {dragging ? (
                              <Box mt={1}>
                                {getLang(lang, "label.DROP_HERE")}
                              </Box>
                            ) : (
                              <Box
                                mt={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="inherit"
                                  style={{
                                    textTransform: "none",
                                    textAlign: "center",
                                  }}
                                >
                                  {Boolean(values.image) &&
                                    typeof values.image === "string" &&
                                    isValidURL(values.image) &&
                                    new URL(values.image).pathname
                                      .split("/")
                                      .pop()}
                                  {Boolean(values.image) &&
                                    typeof values.image !== "string" &&
                                    values.image?.name}
                                </Typography>

                                {isEditable && (
                                  <Typography
                                    variant="body2"
                                    color="inherit"
                                    style={{
                                      textTransform: "none",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <b>
                                      {getLang(lang, "paragraph.DROP_FILES")}
                                      <span>
                                        &nbsp;
                                        {getLang(lang, "paragraph.BROWSE")}
                                      </span>
                                    </b>
                                  </Typography>
                                )}
                              </Box>
                            )}
                          </ButtonBase>
                        </label>
                      </div>
                    )}
                  </Dropzone>
                </Box>
                <Typography
                  style={{
                    color: touched.image && errors.image ? "#f44336" : null,
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {ErrorMessage({ name: "image" })}
                </Typography>
              </Box>
            </div>

            <Box style={{ marginTop: "20px" }}>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="body2"
                  className={clsx(enableGameRules ? classes.required : null, classes.fieldLabel)}
                >
                  {getLang(lang, "label.GAME_RULES")}
                </Typography>
                <FormControlLabel
                  style={{ marginLeft: "11px" }}
                  control={
                    <AntSwitch
                      checked={enableGameRules}
                      onChange={(event) => {
                        handleGameRulesChange(event);
                        if (!values.isEditing) {
                          setFieldValue("isEditing", true);
                        }
                      }}
                    />
                  }
                />
              </div>
              {enableGameRules && (
                <RadioGroup
                  row
                  name="gameRulesType"
                  value={values.gameRulesType}
                  onChange={(e) => {
                    if (!isSubmitting && isEditable) {
                      setFieldValue("gameRulesType", e.target.value);
                      if (!values.isEditing) {
                        setFieldValue("isEditing", true);
                      }
                    }
                  }}
                  disabled={isSubmitting || isEditable}
                >
                  <FormControlLabel
                    value="text"
                    control={<Radio />}
                    label={
                      <Typography variant="body2">
                        {getLang(lang, "label.TEXT")}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="file"
                    control={<Radio />}
                    label={
                      <Typography variant="body2">
                        {getLang(lang, "label.UPLOAD_DOCUMENT")} (.doc, .docx,
                        .pdf)
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio />}
                    label={
                      <Typography variant="body2">
                        {getLang(lang, "label.EXTERNAL_LINK")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
              {enableGameRules && values.gameRulesType === "text" && (
                <div style={{ display: "flex" , flexDirection: "column"}}>
                  <Editor
                    editorState={values.rules}
                    name="rules"
                    wrapperClassName={(errors.rules && touched.rules) ? classes.errorEditor : classes.editor}
                    editorStyle={{
                      height: "200px",
                      maxHeight: "1000px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      resize: "vertical",
                    }}
                    placeholder={getLang(lang, "placeholder.ENTER_WRITE_UP_HERE")}
                    // toolbarHidden={type === TYPES[2]}
                    // editorState={
                    //   type === TYPES[2]
                    //     ? transformHTMLToRaw(initialValues.html)
                    //     : editorState
                    // }
                    readOnly={!isEditable || isSubmitting}
                    onEditorStateChange={(editorState) => {
                      setFieldValue("rules", editorState);
                      if (!values.isEditing) {
                        setFieldValue("isEditing", true);
                      }
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "list",
                        "emoji",
                        "link",
                        "textAlign",
                        "blockType",
                        "fontSize",
                        "image",
                      ],
                      inline: {
                        inDropdown: true,
                        options: ["bold", "italic", "underline", "strikethrough"],
                        className: classes.dropdownToolbar,
                      },
                      list: {
                        inDropdown: true,
                        className: classes.dropdownToolbar,
                      },
                      textAlign: {
                        inDropdown: true,
                        className: classes.dropdownToolbar,
                      },
                      blockType: {
                        inDropdown: true,
                        // className: classes.dropdownToolbar
                      },
                      link: {
                        inDropdown: true,
                        // className: classes.dropdownToolbar
                      },
                      fontSize: {
                        // className: classes.dropdownToolbar
                      },
                      emoji: {
                        // className: classes.dropdownToolbar
                      },
                      image: {
                        defaultSize: {
                          height: "auto",
                          width: "400px",
                        },
                      },
                    }}
                  // editorClassName={classes.editor}
                  // toolbarClassName={classes.toolbar}
                  />
                  {(errors.rules && touched.rules) && (
                    <Typography
                      style={{
                        color: "#f44336",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      {errors.rules}
                    </Typography>
                  )}
                </div>
              )}
              {enableGameRules && values.gameRulesType === "file" && (
                <>
                  {Boolean(values.gameRulesFile) &&
                    typeof values.gameRulesFile === "string" &&
                    isValidURL(values.gameRulesFile) && (
                      <a
                        href={values.gameRulesFile}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginBottom: "4px",
                        }}
                      >
                        {new URL(values.gameRulesFile).pathname
                          .split("/")
                          .pop()}
                      </a>
                    )}
                  <Box className={classes.dropzoneContainer}>
                    <Dropzone
                      onDrop={(file) => {
                        setFieldValue("gameRulesFile", file[0]);
                        if (!values.isEditing) {
                          setFieldValue("isEditing", true);
                        }
                        handleDrop(file);
                      }}
                      noClick
                      noKeyboard
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      name="gameRulesFile"
                      disabled={isSubmitting || !isEditable}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept=".doc,.docx,.pdf,"
                            className={classes.input}
                            id="rules-file"
                            type="file"
                            // ref={inputRulesRef}
                            onChange={(e) => {
                              setFieldValue(
                                "gameRulesFile",
                                e.currentTarget.files[0]
                              );
                              if (!values.isEditing) {
                                setFieldValue("isEditing", true);
                              }
                              // handleRulesChange(e);
                            }}
                            onClick={(e) => (e.target.value = null)}
                            disabled={isSubmitting || !isEditable}
                          />
                          <label htmlFor="rules-file">
                            <ButtonBase
                              component="span"
                              className={classnames(classes.mr1, {
                                [classes.dragging]: dragging,
                              })}
                            >
                              <UploadImg color="#3A4D5480" />
                              {dragging ? (
                                <Box mt={1}>
                                  {getLang(lang, "label.DROP_HERE")}
                                </Box>
                              ) : (
                                <Box
                                  mt={1}
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="button"
                                    color="inherit"
                                    style={{
                                      textTransform: "none",
                                      fontSize: "0.8125rem",
                                    }}
                                  >
                                    {Boolean(values.gameRulesFile) &&
                                      typeof values.gameRulesFile ===
                                      "string" &&
                                      isValidURL(values.gameRulesFile) &&
                                      new URL(values.gameRulesFile).pathname
                                        .split("/")
                                        .pop()}
                                    {Boolean(values.gameRulesFile) &&
                                      typeof values.gameRulesFile !==
                                      "string" &&
                                      values.gameRulesFile?.name}
                                    {isEditable &&
                                      (!Boolean(values.gameRulesFile) ||
                                        (typeof values.gameRulesFile ===
                                          "string" &&
                                          !isValidURL(
                                            values.gameRulesFile
                                          ))) && (
                                        <>
                                          {getLang(
                                            lang,
                                            "paragraph.DROP_FILES"
                                          )}
                                          <span>
                                            &nbsp;
                                            {getLang(lang, "paragraph.BROWSE")}
                                          </span>
                                        </>
                                      )}
                                  </Typography>
                                </Box>
                              )}
                            </ButtonBase>
                          </label>
                        </div>
                      )}
                    </Dropzone>
                  </Box>
                  <Typography
                    style={{
                      color: errors.gameRulesFile ? "#f44336" : null,
                      fontSize: "12px",
                      marginTop: "4px",
                    }}
                  >
                    {errors.gameRulesFile}
                  </Typography>
                </>
              )}
              {enableGameRules && values.gameRulesType === "link" && (
                <TextField
                  {...getFieldProps("gameRulesLink")}
                  {...textFieldProps}
                  type={"text"}
                  name="gameRulesLink"
                  value={values.gameRulesLink}
                  onChange={(e) => {
                    setFieldValue("gameRulesLink", e.target.value);
                    if (!values.isEditing) {
                      setFieldValue("isEditing", true);
                    }
                  }}
                  error={
                    touched.gameRulesLink && errors.gameRulesLink ? true : false
                  }
                  placeholder={getLang(lang, "placeholder.ENTER_URL_LINK")}
                  helperText={ErrorMessage({
                    name: "gameRulesLink",
                  })}
                  disabled={!isEditable || isSubmitting}
                  inputProps={{
                    style: { color: "black" },
                  }}
                />
              )}
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <Typography
                variant="body2"
                className={clsx(classes.required, classes.fieldLabel)}
              >
                {getLang(lang, "label.TERMS_AND_CONDITIONS")}
              </Typography>
              <RadioGroup
                row
                name="tncType"
                value={values.tncType}
                onChange={(e) => {
                  if (!isSubmitting && isEditable) {
                    setFieldValue("tncType", e.target.value);
                    if (!values.isEditing) {
                      setFieldValue("isEditing", true);
                    }
                  }
                }}
                disabled={isSubmitting || !isEditable}
              >
                <FormControlLabel
                  value="text"
                  control={<Radio />}
                  label={
                    <Typography variant="body2">
                      {getLang(lang, "label.TEXT")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label={
                    <Typography variant="body2">
                      {getLang(lang, "label.UPLOAD_DOCUMENT")} (.doc, .docx,
                      .pdf)
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="link"
                  control={<Radio />}
                  label={
                    <Typography variant="body2">
                      {getLang(lang, "label.EXTERNAL_LINK")}
                    </Typography>
                  }
                />
              </RadioGroup>
              {values.tncType === "text" && (
                <div style={{ display: "flex" , flexDirection: "column"}}>
                  <Editor
                    editorState={values.tnc}
                    name="tnc"
                    wrapperClassName={(errors.tnc && touched.tnc) ? classes.errorEditor : classes.editor}
                    editorStyle={{
                      height: "200px",
                      maxHeight: "1000px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      resize: "vertical",
                    }}
                    placeholder={getLang(lang, "placeholder.ENTER_WRITE_UP_HERE")}
                    readOnly={!isEditable || isSubmitting}
                    // toolbarHidden={type === TYPES[2]}
                    // editorState={
                    //   type === TYPES[2]
                    //     ? transformHTMLToRaw(initialValues.html)
                    //     : editorState
                    // }
                    onEditorStateChange={(editorState) => {
                      setFieldValue("tnc", editorState);
                      if (!values.isEditing) {
                        setFieldValue("isEditing", true);
                      }
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "list",
                        "emoji",
                        "link",
                        "textAlign",
                        "blockType",
                        "fontSize",
                        "image",
                      ],
                      inline: {
                        inDropdown: true,
                        options: ["bold", "italic", "underline", "strikethrough"],
                        className: classes.dropdownToolbar,
                      },
                      list: {
                        inDropdown: true,
                        className: classes.dropdownToolbar,
                      },
                      textAlign: {
                        inDropdown: true,
                        className: classes.dropdownToolbar,
                      },
                      blockType: {
                        inDropdown: true,
                        // className: classes.dropdownToolbar
                      },
                      link: {
                        inDropdown: true,
                        // className: classes.dropdownToolbar
                      },
                      fontSize: {
                        // className: classes.dropdownToolbar
                      },
                      emoji: {
                        // className: classes.dropdownToolbar
                      },
                      image: {
                        defaultSize: {
                          height: "auto",
                          width: "400px",
                        },
                      },
                    }}
                  // editorClassName={classes.editor}
                  // toolbarClassName={classes.toolbar}
                  />
                  {(errors.tnc && touched.tnc) && (
                    <Typography
                      style={{
                        color: "#f44336",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      {errors.tnc}
                    </Typography>
                  )}
                </div>
              )}
              {values.tncType === "file" && (
                <>
                  {Boolean(values.tncFile) &&
                    typeof values.tncFile === "string" &&
                    isValidURL(values.tncFile) && (
                      <a
                        href={values.tncFile}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginBottom: "4px",
                        }}
                      >
                        {new URL(values.tncFile).pathname.split("/").pop()}
                      </a>
                    )}
                  <Box className={classes.dropzoneContainer}>
                    <Dropzone
                      onDrop={(file) => {
                        setFieldValue("tncFile", file[0]);
                        if (!values.isEditing) {
                          setFieldValue("isEditing", true);
                        }
                        handleDrop(file);
                      }}
                      noClick
                      noKeyboard
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      name="tncFile"
                      disabled={isSubmitting || !isEditable}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept=".doc,.docx,.pdf,"
                            className={classes.input}
                            id="tnc-file"
                            type="file"
                            // ref={inputRulesRef}
                            onChange={(e) => {
                              setFieldValue(
                                "tncFile",
                                e.currentTarget.files[0]
                              );
                              if (!values.isEditing) {
                                setFieldValue("isEditing", true);
                              }
                              // handleRulesChange(e);
                            }}
                            onClick={(e) => (e.target.value = null)}
                            disabled={isSubmitting || !isEditable}
                          />
                          <label htmlFor="tnc-file">
                            <ButtonBase
                              component="span"
                              className={classnames(classes.mr1, {
                                [classes.dragging]: dragging,
                              })}
                            >
                              <UploadImg color="#3A4D5480" />
                              {dragging ? (
                                <Box mt={1}>
                                  {getLang(lang, "label.DROP_HERE")}
                                </Box>
                              ) : (
                                <Box
                                  mt={1}
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="button"
                                    color="inherit"
                                    style={{
                                      textTransform: "none",
                                      fontSize: "0.8125rem",
                                    }}
                                  >
                                    {Boolean(values.tncFile) &&
                                      typeof values.tncFile === "string" &&
                                      isValidURL(values.tncFile) &&
                                      new URL(values.tncFile).pathname
                                        .split("/")
                                        .pop()}
                                    {Boolean(values.tncFile) &&
                                      typeof values.tncFile !== "string" &&
                                      values.tncFile?.name}
                                    {isEditable &&
                                      (!Boolean(values.tncFile) ||
                                        (typeof values.tncFile === "string" &&
                                          !isValidURL(values.tncFile))) && (
                                        <>
                                          {getLang(
                                            lang,
                                            "paragraph.DROP_FILES"
                                          )}
                                          <span>
                                            &nbsp;
                                            {getLang(lang, "paragraph.BROWSE")}
                                          </span>
                                        </>
                                      )}
                                  </Typography>
                                </Box>
                              )}
                            </ButtonBase>
                          </label>
                        </div>
                      )}
                    </Dropzone>
                  </Box>
                  <Typography
                    style={{
                      color: errors.tncFile ? "#f44336" : null,
                      fontSize: "12px",
                      marginTop: "4px",
                    }}
                  >
                    {errors.tncFile}
                  </Typography>
                </>
              )}
              {values.tncType === "link" && (
                <TextField
                  {...getFieldProps("tncLink")}
                  {...textFieldProps}
                  type={"text"}
                  name="tncLink"
                  value={values.tncLink}
                  onChange={(e) => {
                    setFieldValue("tncLink", e.target.value);
                    if (!values.isEditing) {
                      setFieldValue("isEditing", true);
                    }
                  }}
                  placeholder={getLang(lang, "placeholder.ENTER_URL_LINK")}
                  error={touched.tncLink && errors.tncLink ? true : false}
                  helperText={ErrorMessage({
                    name: "tncLink",
                  })}
                  disabled={!isEditable || isSubmitting}
                  inputProps={{
                    style: { color: "black" },
                  }}
                />
              )}
            </Box>
          </Paper>

          <Box
            mt={2.5}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {!uuid && (
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      history.replace(ROOT);
                    }}
                    disabled={isSubmitting}
                  >
                    {getLang(lang, "label.DISCARD")}
                  </Button>
                </Box>
              )}
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#6AAF68" }}
                disabled={isSubmitting}
                startIcon={
                  isSubmitting &&
                  !values.goToPreview && (
                    <CircularProgress
                      style={{ width: 15, height: 15, color: "white" }}
                    />
                  )
                }
                onClick={() => {
                  setFieldValue("goToPreview", false);
                  handleSubmit();
                }}
              >
                {getLang(lang, "label.NEXT")}
              </Button>
            </Box>
            {isPublished && isEditable && (
              <Box>
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: "#6AAF68" }}
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting &&
                    !!values.goToPreview && (
                      <CircularProgress
                        style={{ width: 15, height: 15, color: "white" }}
                      />
                    )
                  }
                  onClick={() => {
                    setFieldValue("goToPreview", true);
                    handleSubmit();
                  }}
                >
                  {getLang(lang, "label.SAVE_AND_PREVIEW")}
                </Button>
              </Box>
            )}
          </Box>
          <GeneralDialog
            isOpen={values.showConfirmDialog}
            handleClose={() => setFieldValue("showConfirmDialog", false)}
            handleProceed={() => {
              resetForm();
              if (!values.isEditing) {
                setFieldValue("isEditing", true);
              }
            }}
            title={getLang(lang, "label.CONFIRMATION")}
            description={getLang(lang, "paragraph.CLEAR_FORM_QUESTION")}
            type="danger"
            icon={<img src={AlertIcon} alt="alert" />}
          />
        </Box>
      )}
    </Formik>
  );
}
