import axios from "axios";

export default async function updateConsumerLoyaltyPoint(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/campaign/api/admin/wallets/update-loyalty-point`, {
        sub: payload.sub,
        isPositive: payload.isPositive,
        value: payload.value,
        balancePoint: payload.balancePoint,
        description: payload.description,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
}
