import axios from "axios";

const getUnassignSnListByProduct = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/serialnumber/api/v1/serialnumber/unassign/${parameters.id}/serialnumbers`,
        {
          length: parameters.length,
          start: parameters.start,
          order: parameters.order,
          search: parameters.search,
          product_uuid: parameters.product_uuid,
          status: parameters.status,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getUnassignSnListByProduct;
