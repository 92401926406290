import React from "react";
import {
  Backdrop,
  Box,
  Paper,
  IconButton,
  makeStyles,
  Typography,
  Button,
  // Select,
  // MenuItem,
  // FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import * as Yup from "yup";
import { Formik } from "formik";
import { Add as AddIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import SingleProductBindingPanel from "../../panel/singleProductBindingPanel";
import { progressStatus } from "lib/constants/luckyDrawProgress";

const styles = makeStyles((theme) => ({
  root: {
    height: "80%",
  },
  selectRoot: {
    padding: 12,
    backgroundColor: "white",
  },
  table: {
    "& .MuiTableRow-root:hover": {
      backgroundColor: "white",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "8px 12px",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "#F8F8F8",
    },
  },
}));

export default function LuckyDrawDuplicateDialogComponent({
  isOpen,
  handleClose,
  lang,
  isSubmitting,
  luckyDraw,
  handleSubmit,
  isLoading,
  bindingInfo,
  hasLuckyDrawEditAccessRight,
}) {
  const classes = styles();

  const isPublished = luckyDraw?.progress === progressStatus.published;

  const isEditable = !luckyDraw?.draw_start_at && !luckyDraw?.end_at;
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      {!!luckyDraw && (
        <Paper elevation={0} className={classes.root}>
          <Box px={3} pb={3} pt={2}>
            <Box
              className={classes.header}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="body1"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {getLang(lang, "label.BINDING_INFORMATION")}
              </Typography>
              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            {isLoading ? (
              <Box>
                <Skeleton variant="text" animation="wave" height={25} />
                <Skeleton variant="text" animation="wave" height={25} />
                <Skeleton variant="text" animation="wave" height={25} />
                <Skeleton variant="text" animation="wave" height={25} />
              </Box>
            ) : (
              <Formik
                initialValues={{
                  bindingType: bindingInfo.bindingType ?? 1,
                  products: bindingInfo.products ?? [],
                  serialNumbers: bindingInfo.serialNumbers ?? [],
                  sequenceNumbers: bindingInfo.sequenceNumbers ?? [],
                }}
                validationSchema={Yup.object({
                  products: Yup.array(
                    Yup.object().shape({
                      product: Yup.object()
                        .nullable()
                        .required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        ),
                      nodes: Yup.array().min(
                        1,
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      ),
                    })
                  ).min(
                    1,
                    getLang(lang, "message.error.PRODUCT_BINDING_CANNOT_EMPTY")
                  ),
                })}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    {/* <Box display="flex" alignItems="center" mt={2}>
                      <Box mr={1}>
                        <Typography variant="body2">
                          <b>
                            {getLang(lang, "label.BINDING_WITH")}:{" "}
                            <span style={{ color: "#E21B1B" }}>*</span>
                          </b>
                        </Typography>
                      </Box>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", maxWidth: 400 }}
                      >
                        <Select
                          value={formik.values.bindingType}
                          onChange={(e) => {
                            if (hasLuckyDrawEditAccessRight) {
                              formik.setFieldValue(
                                "bindingType",
                                e.target.value
                              );
                            }
                          }}
                          classes={{ root: classes.selectRoot }}
                          placeholder={getLang(lang, "placeholder.SELECT_ONE")}
                          disabled={!hasLuckyDrawEditAccessRight || !isEditable}
                          style={{
                            color: "black",
                          }}
                        >
                          <MenuItem value={1}>
                            {getLang(lang, "label.PRODUCTS")}
                          </MenuItem>
                          <MenuItem value={2}>
                            {getLang(lang, "label.SERIAL_NUMBER_RANGES")}
                          </MenuItem>
                          <MenuItem value={3}>
                            {getLang(lang, "label.SEQUENCE_NUMBER_RANGES")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box> */}
                    <Box mt={3}>
                      <Typography variant="body1">
                        <b>
                          {getLang(
                            lang,
                            formik.values.bindingType === 2
                              ? "label.SERIAL_NUMBER_RANGES"
                              : formik.values.bindingType === 3
                              ? "label.SEQUENCE_NUMBER_RANGES"
                              : "label.PRODUCTS"
                          )}
                        </b>
                      </Typography>
                    </Box>
                    <Box mt={1} position="relative">
                      <TableContainer style={{ maxHeight: "55dvh" }}>
                        <Table
                          stickyHeader
                          aria-label="sn table"
                          size="small"
                          className={classes.table}
                        >
                          <TableHead
                            style={{ height: "45px", background: "white" }}
                          >
                            <TableRow>
                              <TableCell width="10%">
                                <Typography variant="body2" align="center">
                                  <b>{getLang(lang, "label.NUM")}</b>
                                </Typography>
                              </TableCell>
                              <TableCell width="30%">
                                <Typography variant="body2" align="center">
                                  <b>{getLang(lang, "label.PRODUCT")}</b>
                                </Typography>
                              </TableCell>
                              <TableCell width="20%">
                                <Typography variant="body2" align="center">
                                  <b>{getLang(lang, "label.ATTRIBUTE")}</b>
                                </Typography>
                              </TableCell>
                              <TableCell width="40%">
                                <Typography variant="body2" align="center">
                                  <b>
                                    {getLang(lang, "label.BRANCH_INVOLVED")}
                                  </b>
                                </Typography>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          {!!formik.values.products.length && (
                            <TableBody>
                              {formik.values.products.map((product, index) => (
                                <SingleProductBindingPanel
                                  key={index}
                                  index={index}
                                  formik={formik}
                                  product={product}
                                  isSubmitting={isSubmitting}
                                  isEditable={isEditable}
                                  isPublished={isPublished}
                                  initialValues={bindingInfo.products ?? []}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {!formik.values.products.length &&
                        (() => {
                          const newProducts = [...formik.values.products];
                          newProducts.push({
                            product: null,
                            attribute: "",
                            nodes: [],
                          });
                          formik.setFieldValue("products", newProducts);
                        })()}
                    </Box>
                    <FormHelperText
                      error={
                        !!formik.touched.products &&
                        !!formik.errors.products &&
                        typeof formik.errors.products === "string"
                      }
                    >
                      {!!formik.touched.products &&
                      !!formik.errors.products &&
                      typeof formik.errors.products === "string"
                        ? formik.errors.products
                        : ""}
                    </FormHelperText>
                    {!!hasLuckyDrawEditAccessRight && isEditable && (
                      <>
                        <Box mt={2} mb={1}>
                          <Button
                            variant="text"
                            startIcon={<AddIcon color="secondary" />}
                            onClick={() => {
                              const newProducts = [...formik.values.products];
                              newProducts.push({
                                product: null,
                                attribute: "",
                                nodes: [],
                              });
                              formik.setFieldValue("products", newProducts);
                            }}
                            style={{
                              textTransform: "none",
                            }}
                          >
                            <Typography color="secondary" variant="body2">
                              <b>{getLang(lang, "label.ADD_PRODUCT")}</b>
                            </Typography>
                          </Button>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={3}
                        >
                          <Button
                            variant="outlined"
                            type="button"
                            onClick={handleClose}
                          >
                            {getLang(lang, "label.CANCEL")}
                          </Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            disabled={!isEditable || isSubmitting}
                            startIcon={
                              !!isSubmitting ? (
                                <CircularProgress
                                  style={{
                                    width: 15,
                                    height: 15,
                                    color: "white",
                                  }}
                                />
                              ) : null
                            }
                          >
                            {getLang(lang, "label.CONFIRM")}
                          </Button>
                        </Box>
                      </>
                    )}
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Paper>
      )}
    </Dialog>
  );
}
