import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import LiveDrawBg from "assets/img/liveDrawBg.png";
import LiveDrawTop from "assets/img/liveDrawTop.png";
import LiveDrawBottom from "assets/img/liveDrawBottom.png";
import DrawButton from "assets/img/drawButton.png";
import { drawType } from "modules/lucky-draw/constants";
import { LiveDrawPanel } from "modules/lucky-draw/components/panel";
import LiveDrawTitle from "assets/img/luckyDrawTitle.png";
import grandPrize from "assets/img/grandPrize.png";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${LiveDrawBg})`,
    width: "60%",
    margin: "auto"
  },
  topBg: {
    position: "fixed",
    top: 0,
    display: "flex"
  },
  bottomBg: {
    width: "100%",
    display: "flex",
    position: "fixed",
    bottom: 0
  },
  drawButton: {
    maxWidth: 180,
    cursor: "pointer"
  },
  prizeContainer: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    zIndex: "999",
    background: "linear-gradient(180deg, #FFF2EE 18.23%, #FFD7CB 100%)",
    borderRadius: "19px",
    padding: theme.spacing(3),
    width: "50%",
    flexDirection: "column",
    marginBottom: 30
  },
  prizeTitle: {
    fontSize: 25,
    textShadow: "0px 5px 5px rgba(66, 0, 0, 0.25)",
    background: "radial-gradient(50% 50% at 50% 50%, #FF9921 0%, #FF5C38 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold"
  },
  prizeName: {
    fontSize: 28,
    fontWeight: "bold",
    margin: "6px 0px"
  },
  prizeQuantity: {
    color: "#FF7D2C",
    fontWeight: "bold"
  },
  prizeImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  prizeImageContainer: {
    maxWidth: "75%",
    height: 300,
    border: "4px solid #FF6A23",
    backgroundColor: "white",
    borderRadius: 16,
    margin: "auto",
    marginTop: 20,
    overflow: "hidden"
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-25%"
  },
  textTitle: {
    color: "#fff",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

export default function LiveDrawPageComponent({ prizes, handleDraw, title, lang }) {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <img src={LiveDrawTop} alt="top" width="100%" />

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "-50%"
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="80%"
          pb={2}
        >
          <img src={LiveDrawTitle} alt="title" width="100%" />

          <Box position="absolute" pt={4} textAlign="center">
            <Typography variant="caption" style={{ color: "#fff" }}>
              {getLang(lang,"label.LUCKY_DRAW")}
            </Typography>
            <Typography variant="h6" className={classes.textTitle}>
              {title}
            </Typography>
          </Box>
        </Box>

        {prizes.map((item, index) => (
          <Box key={index} className={classes.prizeContainer}>
            {item.order === "1" && (
              <Box mb={-2}>
                <img src={grandPrize} alt="grand" width="20%" />
              </Box>
            )}
            <Typography className={classes.prizeTitle}>{item.title}</Typography>
            <Typography className={classes.prizeName}>{item.name}</Typography>
            <Typography className={classes.prizeQuantity}>
              {item.quantity} {getLang(lang,"label.UNIT_LOWERCASE")}
            </Typography>
            <Box className={classes.prizeImageContainer}>
              <img
                src={item.image}
                className={classes.prizeImage}
                alt="prize"
              />
            </Box>
            <Box
              style={{
                margin: "auto",
                marginTop: 15,
                width: "90%"
              }}
            >
              {item.drawType === drawType.allAtOnce && !item.isDrawed ? (
                <img
                  src={DrawButton}
                  alt="drawButton"
                  className={classes.drawButton}
                  onClick={() => handleDraw(item.id)}
                />
              ) : (
                item.winners.map((winner, winnerIndex) => (
                  <LiveDrawPanel
                    key={winnerIndex}
                    index={winnerIndex}
                    winnerName={winner.name}
                    serialNumber={winner.serialNumber}
                    contactNumber={winner.contactNumber}
                    startDraw={item.isDrawed}
                    winnerId={winner.id}
                  />
                ))
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <img src={LiveDrawBottom} alt="bottom" width="100%" />
    </Box>
  );
}
