import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import instantLuckyDrawApi from "app/api/instantLuckyDraw";
import { timezoneOffSetFormatTime } from "lib/helper";

const INSTANTLUCKYDRAW = "instantLuckyDraw";

export const fetchInstantLuckyDrawListing = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchInstantLuckyDrawListing`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .getAllInstantLuckyDraw(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createInstantLuckyDraw = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/createInstantLuckyDraw`,
  async (payload, thunkApi) => {
    const timezone = thunkApi.getState().profile.timezone;
    return instantLuckyDrawApi
      .createInstantLuckyDraw({
        title: payload.instantLuckyDrawDetail.name,
        description: payload.instantLuckyDrawDetail.description,
        imgUrl: payload.instantLuckyDrawDetail.image.split("?")[0],
        game: payload.instantLuckyDrawDetail.game,
        tncUrl: payload.instantLuckyDrawDetail.termsAndConditions.split("?")[0],
        metadata: payload.instantLuckyDrawRegister,
        productId: payload.productId,
        startAt: timezoneOffSetFormatTime(
          payload.instantLuckyDrawDetail.eventPeriodStart,
          timezone
        ),
        endAt: timezoneOffSetFormatTime(
          payload.instantLuckyDrawDetail.eventPeriodEnd,
          timezone
        ),
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchInstantLuckyDrawDetail = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchInstantLuckyDrawDetail`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .getInstantLuckyDrawDetail({ id: payload })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateInstantLuckyDrawDetail = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/updateInstantLuckyDrawDetail`,
  async (payload, thunkApi) => {
    const timezone = thunkApi.getState().profile.timezone;
    const info = {
      ...payload,
      tncUrl: payload.instantLuckyDrawDetail
        ? payload.instantLuckyDrawDetail.termsAndConditions
        : undefined,
      title: payload.instantLuckyDrawDetail
        ? payload.instantLuckyDrawDetail.name
        : undefined,
      description: payload.instantLuckyDrawDetail
        ? payload.instantLuckyDrawDetail.description
        : undefined,
      imgUrl: payload.instantLuckyDrawDetail
        ? payload.instantLuckyDrawDetail.image
          ? payload.instantLuckyDrawDetail.image.split("?")[0]
          : undefined
        : undefined,
      startAt: payload.instantLuckyDrawDetail.eventPeriodStart
        ? timezoneOffSetFormatTime(
            payload.instantLuckyDrawDetail.eventPeriodStart,
            timezone
          )
        : undefined,
      endAt: payload.instantLuckyDrawDetail.eventPeriodEnd
        ? timezoneOffSetFormatTime(
            payload.instantLuckyDrawDetail.eventPeriodEnd,
            timezone
          )
        : undefined,
      game: payload.instantLuckyDrawDetail
        ? payload.instantLuckyDrawDetail.game
        : undefined,
      metadata: payload.metadata,
      id: payload.instantLuckyDrawDetail.id,
    };
    return instantLuckyDrawApi
      .updateInstantLuckyDraw(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchJSONURL = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchJSONURL`,
  async (payload, thunkApi) => {
    return fetch(payload)
      .then((res) => res.json())
      .then((out) => {
        return out;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchInstantLuckyDrawParticipantTable = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchInstantLuckyDrawParticipantTable`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.getAllParticipant
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchParticipantDetail = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchParticipantDetail`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.getParticipantDetail
      .get({ id: payload })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const changeInstantLuckyDrawStatus = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/changeInstantLuckyDrawStatus`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .changeStatus({ id: payload.id, status: payload.status })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchAllInstantPrizeLegacy = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchAllInstantPrizeLegacy`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.getAllPrizeLegacy
      .get(payload)
      .then((response) => {
        response.data.id = payload.instantLuckyDrawId;
        response.data.type = payload.type;
        return response.data;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchAllInstantPrize = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchAllInstantPrize`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .getAllPrize(payload)
      .then((response) => {
        response.data.id = payload.instantLuckyDrawId;
        response.data.type = payload.type;
        return response.data;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createPrize = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/createPrize`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.prize
      .post({
        instantLuckyDrawId: payload.instantLuckyDrawId,
        title: payload.prizeName,
        imgUrl: payload.prizeImage.split("?")[0],
        order: payload.order,
        quantity: payload.quantity,
        description: payload.description,
        fileKey: payload.fileKey,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updatePrize = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/updatePrize`,
  async (payload, thunkApi) => {
    const info = {
      instantLuckyDrawId: payload.instantLuckyDrawId,
      title: payload.prizeName,
      imgUrl: payload.prizeImage,
      order: payload.order,
      quantity: payload.quantity,
      description: payload.description,
      fileKey: payload.fileKey,
      isGiftCode: payload.isGiftCode,
      id: payload.id,
    };
    return instantLuckyDrawApi.prize
      .put(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchMoreSerialNumber = createAction(
  `${INSTANTLUCKYDRAW}/fetchMoreSerialNumber`
);

export const fetchSerialNumberList = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/fetchSerialNumberList`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.getAllSerialNumber
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const searchSerialNumber = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/searchSerialNumber`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.searchSerialNumber
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
)

export const adjustQuantity = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/adjustQuantity`,
  async (payload, thunkApi) => {
    const info = {
      id: payload.id,
      isPreset: payload.isPreset,
      quantity: payload.quantity,
      serialNumber: payload.serialNumber,
      fileKey: payload.fileKey,
    };
    return instantLuckyDrawApi.adjustQuantity
      .put(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const changePrizeStatus = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/changePrizeStatus`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.prizeStatus
      .put({ id: payload.id, status: payload.status })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const setWinner = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/setWinner`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.winner
      .post({
        instantLuckyDrawId: payload.instantLuckyDrawId,
        winnerField: payload.winnerField,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const changeInstantLuckyDrawProgress = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/changeInstantLuckyDrawProgress`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi.changeProgress
      .put({
        id: payload.id,
        progress: payload.progress,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateInstantLuckyDrawPrizes = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/updateInstantLuckyDrawPrizes`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .updateInstantLuckyDrawPrizes({
        instantLuckyDrawId: payload.instantLuckyDrawId,
        hasWinRate: payload.hasWinRate,
        deletedPrizeUuids: payload.deletedPrizeUuids,
        prizes: payload.prizes,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const presetWinner = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/setWinner`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .presetWinner({
        instantLuckyDrawId: payload.instantLuckyDrawId,
        winnerField: payload.winnerField,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const publishInstantLuckyDraw = createAsyncThunk(
  `${INSTANTLUCKYDRAW}/publishInstantLuckyDraw`,
  async (payload, thunkApi) => {
    return instantLuckyDrawApi
      .publishInstantLuckyDraw({
        id: payload.id,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
