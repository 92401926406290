import React from "react";
import BoxInfoCardComponent from "./boxInfoCard.component";

export default function BoxInfoCardContainer({
  hasStockflowDealerViewAccessRight,
  box,
  language,
  isFetching,
  lang,
  isInvoice = false,
}) {
  return (
    <BoxInfoCardComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      box={box}
      language={language}
      isFetching={isFetching}
      lang={lang}
      isInvoice={isInvoice}
    />
  );
}
