export const SET_WINNER_LUCKY_DRAW_LOADING = "SET_WINNER_LUCKY_DRAW_LOADING";

export const PRIZE_TYPE_RANDOM = 1;
export const PRIZE_TYPE_PRESET = 2;
export const DUPLICATE_LUCKY_DRAW_LOADING = "DUPLICATE_LUCKY_DRAW_LOADING";
export const TYPE_TRADITIONAL_RAFFLE = 1;
export const TYPE_INSTANT_WIN = 2;
export const TYPE_INTERVAL_RAFFLE = 3;

export const JOIN_END_AT_NONE = '0';
export const JOIN_END_AT_COUNT_DOWN = '1';
export const JOIN_END_AT_EXT_STIE = '2';

export const PRIZE_RANKING = {
  '1': '1st Prize',
  '2': '2nd Prize',
  '3': '3rd Prize',
  '4': '4th Prize',
  '5': '5th Prize',
  '0': 'Unranked Prize',
}

export const PRIZE_DRAW_ONE_BY_ONE = 1;
export const PRIZE_DRAW_ALL = 2;