import React from "react";
import GroupListTable from "../../table/groupListTable";
import { Formik } from "formik";
import { Box, Button, makeStyles } from "@material-ui/core";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import FilterSvg from "assets/svg/filter.svg";
import BranchSearch from "modules/stockflow/components/select/branchSearch";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "10fr 2fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function GroupListPanelComponent({
  hasGroupAddAccessRight,
  hasGroupEditAccessRight,
  groupList,
  isFetching,
  isError,
  totalFiltered,
  tableFilter,
  handlePageChange,
  handleRowPerPageChange,
  handleSearch,
  clearSearch,
  resetFilter,
  handleReload,
  paginationOptions,
  isSearchResult,
  isFilterOpen,
  setIsFilterOpen,
  handleViewDetail,
  lang,
  openCreateDialog,
  handleDeleteGroup,
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          dateRange: {
            from: tableFilter.fromDate,
            to: tableFilter.toDate,
          },
          branch: tableFilter.branch,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_GROUP_NAME")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootID },
                }}
                {...formik.getFieldProps("search")}
              />
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                disabled={isFetching}
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <BranchSearch
                    placeholder={getLang(lang, "placeholder.BRANCH")}
                    value={formik.values.branch}
                    handleChange={(value) =>
                      formik.setFieldValue("branch", value)
                    }
                    isEmpty={formik.values.branch?.length === 0}
                    styles={{
                      padding: "0.7em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(branch) => {
                      let temp = [...formik.values.branch];
                      if (temp.length > 0) {
                        const filteredBranchs = temp.filter(
                          (item) => item.node_id !== branch.node_id
                        );
                        temp = [...filteredBranchs];
                      }
                      formik.setFieldValue("branch", temp);
                    }}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    disabled={isFetching}
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={resetFilter}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>
      <GroupListTable
        rows={groupList}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        hasGroupEditAccessRight={hasGroupEditAccessRight}
        hasGroupAddAccessRight={hasGroupAddAccessRight}
        rowsPerPage={tableFilter.rowsPerPage}
        page={tableFilter.page}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        paginationOptions={paginationOptions}
        handleViewDetail={handleViewDetail}
        lang={lang}
        openCreateDialog={openCreateDialog}
        handleDeleteGroup={handleDeleteGroup}
      />
    </>
  );
}
