import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConsumerListingPanelComponent from './consumerListingPanel.component'
import EditLoyaltyPointDialogContainer from "../../../components/dialog/editLoyaltyPointDialog";
import { fetchConsumerListing } from 'modules/consumer/redux/action/consumer.action'
import { selectLoading } from 'modules/notification'
import { generatePaginationOptions } from "lib/helper";

export default function ConsumerListingPanelContainer() {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [search, setSearch] = useState("")
    const [paginationOptions, setPaginationOptions] = useState([]);
    const dispatch = useDispatch()
    const totalRecords = useSelector(state => state.consumer.consumerList.totalRecords)
    const consumers = useSelector(state => state.consumer.consumerList.data)
    const isFetching = useSelector(state => selectLoading(state, fetchConsumerListing.typePrefix))
    const [consumerDetails, setConsumerDetails] = useState({});
    const [isEditLoyaltyPointDialogOpen, setIsEditLoyaltyPointDialogOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(event.target.value)
    };

    const handleSearch = value => {
        setPage(0)
        setSearch(value)
    }

    useEffect(() => {
        dispatch(fetchConsumerListing({
            length: rowsPerPage,
            start: rowsPerPage * page,
            search: search
        }))
    }, [rowsPerPage, page, search, dispatch])

    useEffect(() => {
        setPaginationOptions(generatePaginationOptions(totalRecords))
    }, [totalRecords])

    const handleReload = () => {
        dispatch(
            fetchConsumerListing({
                length: rowsPerPage,
                start: rowsPerPage * page,
                search: search
            })
        );
    };

    const handleEditLoyaltyPoint = ({ item }) => {
        setConsumerDetails(item);
        setIsEditLoyaltyPointDialogOpen(true);
    };

    return (
        <>
            <ConsumerListingPanelComponent
                consumers={consumers}
                isFetching={isFetching}
                page={page}
                search={search}
                rowsPerPage={rowsPerPage}
                totalRecords={totalRecords}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleSearch={handleSearch}
                handleReload={handleReload}
                paginationOptions={paginationOptions}
                handleEditLoyaltyPoint={handleEditLoyaltyPoint}
            />
            <EditLoyaltyPointDialogContainer
                isOpen={isEditLoyaltyPointDialogOpen}
                handleClose={() => setIsEditLoyaltyPointDialogOpen(false)}
                consumerDetails={consumerDetails}
                handleReload={handleReload}
            />
        </>
    )
}