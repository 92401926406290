import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import Input from "components/input/inputTextField";
import FilterSvg from "assets/svg/filter.svg";
import { Formik } from "formik";
import clsx from "clsx";
// import { DateRangePicker } from "rsuite";
import DateRangePicker from "components/input/dateRangePicker";
import { getLang } from "app/feature/constants";
import { STOCK_TAKE_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import SearchableSelect from "components/select/searchableSelect";
import StockTakeListTable from "modules/stockflow/components/table/stockTakeListTable";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5.5fr 2fr 1.5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 0.5fr) minmax(0, 1fr)",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
  datePicker: {
    "& .rs-picker-default .rs-picker-toggle.rs-btn": {
      borderRadius: 4,
      backgroundColor: "#eceff0 !important",
      border: "none !important",
    },
    "& .rs-picker-toggle-value": {
      fontSize: "14px 1important",
    },
  },
}));

export default function CheckoutListingComponent({
  stocktakes,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  isFilterOpen,
  language,
  setIsFilterOpen,
  tableFilter,
  changeOrder,
  lang,
  resetFilter,
  handleViewDetail,
  handleCancel,
  hasStockflowEditAccessRight,
}) {
  const classes = useStyle();

  const statusDropdown = [
    { name: "-", value: "" },
    { name: getLang(lang, "label.IN_PROGRESS"), value: "pending" },
    { name: getLang(lang, "label.COMPLETED"), value: "completed" },
    { name: getLang(lang, "label.CANCELLED"), value: "cancelled" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          branch: tableFilter.branch,
          dateRange: {
            from: tableFilter.fromDate,
            to: tableFilter.toDate,
          },
          search: tableFilter.search,
          status: tableFilter.status
            ? statusDropdown.find((v) => v.value === tableFilter.status)
            : null,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <BranchSearch
                placeholder={getLang(lang, "placeholder.BRANCH")}
                value={formik.values.branch}
                handleChange={(value) => formik.setFieldValue("branch", value)}
                isEmpty={formik.values.branch?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(branch) => {
                  let temp = [...formik.values.branch];
                  if (temp.length > 0) {
                    const filteredBranchs = temp.filter(
                      (item) => item.node_id !== branch.node_id
                    );
                    temp = [...filteredBranchs];
                  }
                  formik.setFieldValue("branch", temp);
                }}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.SELECT_DATE_RANGE")}
                disabled={isFetching}
                disableFuture={false}
                className={clsx({
                  [classes.emptyField]: !formik.values.dateRange,
                })}
                id={"created-picker-dialog"}
                value={formik.values.dateRange}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.values.dateRange ? "#A4A6A8" : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.SELECT_DATE_RANGE")}
              />
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <SearchableSelect
                    value={formik.values.status}
                    handleChange={(value) => {
                      formik.setFieldValue("status", value);
                    }}
                    placeholder={getLang(lang, "placeholder.STATUS")}
                    disabled={formik.isSubmitting || isFetching}
                    searchable={false}
                    panelStyle={{ width: "249px" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot },
                    }}
                    dropdownItem={statusDropdown}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    isEmpty={!formik.values.status}
                    returnType="object"
                  />
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(lang, "placeholder.SEARCH_NAME")}
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#eceff0",
                        minWidth: "50px",
                        height: "100%",
                      },
                      classes: { input: classes.inputRootID },
                    }}
                    {...formik.getFieldProps("search")}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={resetFilter}
                    disabled={isFetching}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>
      <StockTakeListTable
        rows={stocktakes}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
        url={STOCK_TAKE_LIST_PAGE}
        handleCancel={handleCancel}
        hasStockflowEditAccessRight={hasStockflowEditAccessRight}
      />
    </>
  );
}
