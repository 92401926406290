import axios from "axios";

const endpoint_url = "/campaignV2/customer/instantWin/cardDetail";

const getInstantWinCardDetail = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          instantWinUuid: parameters.id,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getInstantWinCardDetail;
