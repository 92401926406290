import React from "react"
import { 
  makeStyles, 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Link
} from "@material-ui/core"
import { TablePaginationActions } from "components/tablePagination"
import AndroidIcon from "assets/img/androidIcon.png"
import IosIcon from "assets/img/iosIcon.png"
import WindowsIcon from "assets/img/windowIcon.png"
import Safari from "assets/img/safariIcon.png"
import Chrome from "assets/img/chromeIcon.png"
import Firefox from "assets/img/firefoxIcon.png"
import InternetExplorer from "assets/img/internetExplorerIcon.png"
import MicrosoftEdge from "assets/img/microsoftEdgeIcon.png"
import Opera from "assets/img/operaIcon.png"
import Netscape from "assets/img/netscapeIcon.png"
import RefreshTable from "components/table/refreshTable"
import * as FlagIcons from'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import { ROOT as CONSUMER_ROOT } from "modules/consumer"
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { KeyboardArrowDown } from "@material-ui/icons"
import clsx from "clsx"
import moment from "moment"
import "moment/min/locales.min"
import DefaultImage from "assets/img/defaultPhoto.png"
import { UnknownFlagIcon } from "modules/reporting/assets/svg"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    minWidth: 0,
    position: "relative",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "25px",
    minWidth: "25px",
    height: "25px",
    marginRight: "10px", 
    borderRadius: "50%",
    "& img": {
      height: "25px",
      width: "25px",
      borderRadius: "50%",
    },
  },
  chip: {
    padding: "4px 8px",
    borderRadius: 4,
    marginLeft: 5,
    color: "white"
  },
  valid: {
    backgroundColor: "#32D583"
  },
  suspend: {
    backgroundColor: "#F97066"
  },
  flag: {
    height: 18,
    width: 24,
    marginRight: 5,
    marginTop: 3
  },
  deviceIcon: {
    height: 23,
    width: 23,
    objectFit: "contain"
  }
}))

export default function ScanHistoryTableComponent({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  handleReload,
  paginationOptions,
  changeOrder,
  snScanLimit,
  lang,
  handleViewDetail
}) {
  const classes = useStyle()

  const browserIcon = {
    "Safari": Safari,
    "Edge": MicrosoftEdge,
    "MSIE": InternetExplorer,
    "Opera": Opera,
    "Chrome": Chrome,
    "Firefox": Firefox,
    "Netscape": Netscape
  }

  const getFlagIcon = (countryCode) => {
    if (!hasFlag(countryCode)) {
      return (
        <Box className={classes.flag}>
          <UnknownFlagIcon />
        </Box>
      )
    }

    const FlagIcon = FlagIcons[countryCode];
    return (
      <Box  className={classes.flag}>
        <FlagIcon  title={countryCode} />
      </Box>
    )
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="scan table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>{getLang(lang, "label.CONSUMER")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.SCAN_COUNT")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === 'location'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "location"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("location", order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SCAN_LOCATION")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === 'created_at'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "created_at"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("created_at", order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.CONTACT_NUMBER")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.GENDER")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.AGE")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === 'scan_device'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "scan_device"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("scan_device", order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.PLATFORM")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === 'browser'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "browser"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("browser", order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.BROWSER")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.DEVICE")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={11} />
                  ))
                  : rows.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <StyledTableCell style={{ maxWidth: "40vw" }}>
                        <Box display="flex" alignItems="center">
                          <Box className={classes.profilePhoto}>
                            {!row.user || !row.user.picture ? (
                              <img src={DefaultImage} alt="default-profile" />
                            ) : (
                              <img src={row.user.picture} alt={row.user.name} />
                            )}
                          </Box>
                          {row.user ? (
                            <Link
                              href={`${CONSUMER_ROOT}/${row.user.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(`${CONSUMER_ROOT}/${row.user.uuid}`)
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography variant="body2">{row.user.name}</Typography>
                            </Link>
                          ) : (
                            <Typography variant="body2">{getLang(lang, "label.UNKNOWN")}</Typography>
                          )}
                          <Typography variant="body2">{row.product_name}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="body2" style={{ minWidth: 30 }}>
                            {row.scan_count.toLocaleString("en-US")}/{snScanLimit ? snScanLimit.toLocaleString("en-US") : 'No Limit'}
                          </Typography>
                          <Box 
                            className={clsx(classes.chip, {
                            [classes.valid]: row.status === "active",
                            [classes.suspend]: row.status !== "active",
                          })}>
                            <Typography variant="body2">
                              {row.status === "active" ? getLang(lang, "label.VALID") : getLang(lang, "label.SUSPENDED")}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.location_country && row.location_country !== "Unknown" ? (
                          <Box display="flex" alignItems="center">
                            {getFlagIcon(row.location_country_code)}
                            <Typography variant="body2">
                              {row.location_country && row.location_state ? (
                                `${row.location_country}, ${row.location_state}`
                              ) : (
                                row.location_country || row.location_state
                              )}
                            </Typography>
                          </Box>
                        ) : (
                          <Typography display="inline" color="textSecondary" variant="body2">{getLang(lang, "label.UNKNOWN")}</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          {row.user ? row.user.email : <Typography display="inline" variant="body2" color="textSecondary">{getLang(lang, "label.UNKNOWN")}</Typography>}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          {row.scan_date ? moment(row.scan_date).format('ll LTS') : <Typography display="inline" variant="body2" color="textSecondary">{getLang(lang, "label.UNKNOWN")}</Typography>}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          {row.user && row.user.phone_number ? (
                            `${row.user.phone_code ? `${row.user.phone_code} ` : ""}${row.user.phone_number}`
                          ) : (
                            <Typography display="inline" color="textSecondary" variant="body2">{getLang(lang, "label.UNKNOWN")}</Typography>
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          {row.user && row.user.gender ? (
                            row.user.gender === "Male" ? getLang(lang, 'label.MALE') : getLang(lang, 'label.FEMALE')
                          ) : (
                            <Typography display="inline" variant="body2" color="textSecondary">{getLang(lang, "label.UNKNOWN")}</Typography>
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          {row.user && row.user.date_of_birth ? (
                            Math.floor(moment(row.scan_date).diff(moment(row.user.date_of_birth, "DD MMM YYYY"), 'years', true))
                          ) : (
                            <Typography display="inline" color="textSecondary" variant="body2">{getLang(lang, "label.UNKNOWN")}</Typography>
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.scan_device ? (
                          <img className={classes.deviceIcon} src={row.scan_device === "mac" ? IosIcon : row.scan_device === "windows" ? WindowsIcon : AndroidIcon} alt="device"/>
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            {getLang(lang, "label.UNKNOWN")}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.browser ? (
                          <img className={classes.deviceIcon} src={browserIcon[row.browser]} alt="browser"/>
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            {getLang(lang, "label.UNKNOWN")}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="body2" color={row.device === "Unknown" ? "textSecondary" : "textPrimary"}>
                          {getLang(lang, `label.${row.device.toUpperCase()}`)}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="scan table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
              <StyledTableCell>
                  {getLang(lang, "label.CONSUMER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SCAN_COUNT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SCAN_LOCATION")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EMAIL")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.CONTACT_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.GENDER")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.AGE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PLATFORM")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BROWSER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DEVICE")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body2" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              No scan history
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
