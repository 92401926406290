import React from "react";
import UnassignTableComponent from "./unassignTable.component";

function UnassignTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  retryUnassign,
  viewDetail
}) {
  return (
    <UnassignTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      language={language}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      retryUnassign={retryUnassign}
      viewDetail={viewDetail}
    />
  );
}

export default UnassignTableContainer;
