import React, { useContext } from "react";
import {
  Backdrop,
  Box,
  Paper,
  Divider,
  makeStyles,
  Typography,
  Dialog,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TranditionalRaffleWinnerCard from "../../card/tranditionalRaffleWinnerCard";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { getLang } from "app/feature/constants";
import { PrizeContext } from "../tranditionalRaffleWinnerDialog/tranditionalRaffleWinnerDialog.container";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";

const style = (theme) => ({
  root: {
    height: "80%",
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  publishButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  discardButton: {
    color: theme.palette.error.main,
  },
});

const useStyles = makeStyles(style);

export default function TranditionalRaffleWinnerDialogComponent({
  isOpen,
  handleClose,
  handleSaveWinner,
  winnerType,
  changeToUpdate,
  changeToView,
  handleConfirmWinner,
  progress,
  lang,
  isFetching,
  selectedLuckyDraw,
  hasLuckyDrawV2ViewAccessRight,
  item,
  toggleItem,
  expandAll,
  collapseAll,
  handleDownloadWinner,
  isExporting,
}) {
  const classes = useStyles();
  const { prizeList, isEdit } = useContext(PrizeContext);

  let isFirstUnrankedPrize = true;

  let isPublished = selectedLuckyDraw?.progress === progressStatus.published;

  let drawStartAt = selectedLuckyDraw?.draw_start_at
    ? moment.utc(selectedLuckyDraw.draw_start_at).local()
    : null;
  let endAt = selectedLuckyDraw?.end_at
    ? moment.utc(selectedLuckyDraw.end_at).local()
    : null;

  let isEditable =
    !isPublished ||
    ((!drawStartAt || moment().isBefore(drawStartAt)) &&
      (!endAt || moment().isBefore(endAt)));

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(
              lang,
              hasLuckyDrawV2ViewAccessRight && isEditable
                ? "label.SET_WINNER"
                : "label.VIEW_WINNER"
            )}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => {
              handleClose();
              collapseAll();
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />

        <Box
          pt={1}
          pb={0}
          px={3}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box>
            <Box display="flex" justifyContent="flex-end" pr={1}>
              <Button onClick={expandAll}>
                {getLang(lang, "label.EXPAND_ALL")}
              </Button>
              <Button onClick={collapseAll}>
                {getLang(lang, "label.COLLAPSE_ALL")}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box height="60vh" overflow="auto">
          {isFetching ? (
            <Box px={3} mt={2}>
              <Skeleton variant="text" animation="wave" height={30} />
              <Skeleton variant="text" animation="wave" height={30} />
              <Skeleton variant="text" animation="wave" height={30} />
              <Skeleton variant="text" animation="wave" height={30} />
            </Box>
          ) : prizeList?.length === 0 ? null : (
            prizeList.map((value, index) => {
              const isNewRank =
                index === 0
                  ? true
                  : value.rank_position === 0 && isFirstUnrankedPrize
                  ? true
                  : value.rank_position > prizeList[index - 1].rank_position;
              if (value.rank_position === 0) {
                isFirstUnrankedPrize = false;
              }
              return (
                <TranditionalRaffleWinnerCard
                  key={index}
                  prizeIndex={index}
                  prizeDetail={value}
                  isExpand={item.includes(index)}
                  onToggle={() => toggleItem(index)}
                  isNewRank={isNewRank}
                  selectedLuckyDraw={selectedLuckyDraw}
                />
              );
            })
          )}
        </Box>

        <Box mt="auto">
          <Divider />
          <Box
            display="flex"
            justifyContent={
              [progressStatus.published].includes(
                selectedLuckyDraw?.progress
              ) && selectedLuckyDraw?.draw_end_at
                ? "space-between"
                : "flex-end"
            }
            p={2}
          >
            {[progressStatus.published].includes(selectedLuckyDraw?.progress) &&
              selectedLuckyDraw?.draw_end_at && (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      padding: "5px 12px",
                    }}
                    disableElevation
                    onClick={() => {
                      handleDownloadWinner(selectedLuckyDraw?.uuid);
                    }}
                    startIcon={
                      isExporting && (
                        <CircularProgress style={{ width: 10, height: 10 }} />
                      )
                    }
                    disabled={isFetching || isExporting}
                  >
                    {getLang(lang, "label.DOWNLOAD_WINNER_LIST")}
                  </Button>
                </Box>
              )}

            {isEditable ? (
              <Box display="flex" justifyContent="flex-end">
                {isEdit ? (
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.publishButton }}
                    disableElevation
                    type="submit"
                    onClick={changeToView}
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.publishButton }}
                  disableElevation
                  type="submit"
                  onClick={!isEdit ? changeToUpdate : handleConfirmWinner}
                >
                  {!isEdit
                    ? getLang(lang, "label.EDIT")
                    : getLang(lang, "label.SAVE")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
