import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Paper,
  Tooltip,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import { ErrorMessage } from "formik";
import { getLang } from "app/feature/constants";
import { AddIcon as AddIconGreen } from "components/icon";
import {
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@material-ui/icons";
import AdditionalFieldSelect from "../../select/additionalFieldSelect";
import DustBinIcon from "assets/svg/dustBin.svg";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
  button: {
    "&:not(:last-child)": {
      marginLeft: "10px",
    },
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "25px",
    [theme.breakpoints.down("sm")]: {
      rowGap: "25px",
      gridTemplateColumns: "1fr",
    },
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    border: "2px solid #e1e1e1",
    backgroundColor: "#f7f7f7",
    borderRadius: 4,
  },
  inputContainer: {
    display: "grid",
    gridTemplateColumns: "2.5fr 7.5fr",
    columnGap: "20px",
    margin: "8px 0",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 7fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      row: "4px",
    },
  },
  mappingContainer: {
    display: "grid",
    gridTemplateColumns: "5.5fr 1fr 3.5fr",
    alignItems: "flex-start",
  },
}));

export default function IntegrationPathSettingCardComponent({
  lang,
  isSubmitting,
  hasIntegrationEditAccessRight,
  setting,
  formik,
  phaseIndex,
  adtInfoParams,
  isEdit,
  userLang,
}) {
  const classes = useStyle();

  return (
    <Paper elevation={3} style={{ marginTop: 16 }}>
      <Box px={2} py={1}>
        <Typography variant="body2">
          <b>{getLang(lang, `label.${setting.type}`)}</b>
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <Box className={classes.inputContainer}>
          <Box>
            <Typography variant="body2">
              <b>{getLang(lang, `label.API_PATH`)}</b>
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2">{setting.api_path}</Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="body2">
            <b>
              {getLang(
                lang,
                setting.mapping_type === 2
                  ? "label.DATA_MAPPING"
                  : "label.ADDITIONAL_INFO_MAPPING"
              )}
            </b>
          </Typography>
        </Box>
        {setting.mapping_type === 1 && (
          <Box>
            <Box display="flex" alignItems="center" mt={1.5} mb={1}>
              <Box className={classes.mappingContainer} flex={1}>
                <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                  <Typography variant="body2">
                    <b>{getLang(lang, "label.JSON_VALUE_PATH")}</b>
                  </Typography>
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        {getLang(lang, "tooltips.JSON_VALUE_PATH_TOOLTIP")}
                      </Typography>
                    }
                    placement="top"
                  >
                    <InfoOutlinedIcon style={{ fontSize: 18 }} />
                  </Tooltip>
                </Box>
                <Box />
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">
                    <b>{getLang(lang, "label.ADDITIONAL_INFO_KEY")}</b>
                  </Typography>
                </Box>
              </Box>
              {isEdit && <Box width={38} />}
            </Box>
            {!setting.settings.length && !isEdit ? (
              <Box minHeight={38}>
                <Typography>-</Typography>
              </Box>
            ) : (
              setting.settings.map((item, i) => (
                <Box display="flex" alignItems="center" key={i}>
                  <Box className={classes.mappingContainer} flex={1}>
                    <Box display="flex" alignItems="center" minHeight={38}>
                      {isEdit ? (
                        <TextField
                          {...formik.getFieldProps(
                            `pathSettings[${phaseIndex}].settings[${i}].path`
                          )}
                          fullWidth
                          size="small"
                          margin="none"
                          placeholder={getLang(lang, `label.PATH`)}
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                          }}
                          disabled={
                            !hasIntegrationEditAccessRight || isSubmitting
                          }
                          inputProps={{
                            className: classes.textFieldInput,
                          }}
                          value={item.path || ""}
                          error={
                            formik.errors.pathSettings?.[phaseIndex]
                              ?.settings?.[i]?.path &&
                            formik.touched.pathSettings?.[phaseIndex]
                              ?.settings?.[i]?.path
                          }
                          helperText={ErrorMessage({
                            name: `pathSettings[${phaseIndex}].settings[${i}].path`,
                          })}
                        />
                      ) : (
                        <Typography variant="body2">
                          {item.path || "-"}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="center" pt={1}>
                      <ArrowForwardRoundedIcon />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                      minHeight={38}
                    >
                      {isEdit ? (
                        <AdditionalFieldSelect
                          adtInfoParams={adtInfoParams}
                          value={item.additional_info}
                          handleChange={(prevField, currentField) => {
                            formik.setFieldValue(
                              `pathSettings[${phaseIndex}].settings[${i}].additional_info`,
                              currentField
                            );

                            let ids = [
                              ...formik.values.selectedIds.filter(
                                (id) => id !== prevField?.id
                              ),
                            ];
                            ids.push(currentField.id);
                            formik.setFieldValue("selectedIds", [
                              ...new Set(ids),
                            ]);
                          }}
                          placeholder={getLang(lang, "label.ADDITIONAL_INFO")}
                          disabled={
                            !hasIntegrationEditAccessRight || isSubmitting
                          }
                          style={{
                            height: 38,
                            width: "100%",
                            backgroundColor: "#f7f7f7",
                            border: "2px solid #e1e1e1",
                          }}
                          selectedIds={formik.values.selectedIds}
                        />
                      ) : (
                        <Typography variant="body2">
                          {item.additional_info
                            ? item.additional_info.title[userLang] ??
                              item.additional_info.title["EN"] ??
                              Object.values(item.additional_info.title)[0]
                            : "-"}
                        </Typography>
                      )}
                      {isEdit && (
                        <FormHelperText
                          error={
                            !!formik.errors.pathSettings?.[phaseIndex]
                              ?.settings?.[i]?.additional_info &&
                            formik.touched.pathSettings?.[phaseIndex]
                              ?.settings?.[i]?.additional_info
                          }
                        >
                          {ErrorMessage({
                            name: `pathSettings[${phaseIndex}].settings[${i}].additional_info`,
                          })}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                  {isEdit && (
                    <Box>
                      <IconButton
                        disabled={
                          !hasIntegrationEditAccessRight || isSubmitting
                        }
                        onClick={() => {
                          let settings = [
                            ...formik.values.pathSettings[phaseIndex].settings,
                          ];
                          settings.splice(i, 1);

                          if (!settings.length) {
                            settings.push({
                              uuid: "",
                              path: "",
                              additional_info: null,
                            });
                          }

                          formik.setFieldValue(
                            `pathSettings[${phaseIndex}].settings`,
                            settings
                          );
                        }}
                      >
                        <img src={DustBinIcon} alt="delete" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))
            )}
            {isEdit && (
              <Box
                display="flex"
                justifyContent={
                  setting.settings.length ? "flex-end" : "flex-start"
                }
              >
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<AddIconGreen style={{ fontSize: 14 }} />}
                  onClick={() => {
                    let settings = [
                      ...setting.settings,
                      {
                        uuid: "",
                        path: "",
                        additional_info: null,
                      },
                    ];

                    formik.setFieldValue(
                      `pathSettings[${phaseIndex}].settings`,
                      settings
                    );
                  }}
                  disabled={!hasIntegrationEditAccessRight || isSubmitting}
                >
                  {getLang(lang, "label.ADD_MAPPING")}
                </Button>
              </Box>
            )}
          </Box>
        )}
        {setting.mapping_type === 2 && (
          <Box>
            <Box className={classes.inputContainer} alignItems="center">
              <Box>
                <Typography variant="body2">
                  <b>{getLang(lang, `label.DATA_NAME`)}</b>
                </Typography>
              </Box>
              <Box
                flex={1}
                style={{ gap: 8, height: 38 }}
                display="flex"
                alignItems="center"
              >
                <Typography variant="body2">
                  <b>{getLang(lang, "label.JSON_VALUE_PATH")}</b>
                </Typography>
                <Tooltip
                  title={
                    <Typography variant="body2">
                      {getLang(lang, "tooltips.JSON_VALUE_PATH_TOOLTIP")}
                    </Typography>
                  }
                  placement="top"
                >
                  <InfoOutlinedIcon style={{ fontSize: 18 }} />
                </Tooltip>
              </Box>
            </Box>
            {setting.settings.map((item, i) => (
              <Box className={classes.inputContainer} key={i} mt={2}>
                <Box mt={1}>
                  <Typography variant="body2">
                    <b>{getLang(lang, `label.${item.key}`)}</b>
                  </Typography>
                </Box>
                <Box flex={1} minHeight={38} display="flex" alignItems="center">
                  {isEdit ? (
                    <TextField
                      {...formik.getFieldProps(
                        `pathSettings[${phaseIndex}].settings[${i}].value`
                      )}
                      fullWidth
                      size="small"
                      margin="none"
                      placeholder={getLang(lang, `label.${item.key}`)}
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                      }}
                      disabled={!hasIntegrationEditAccessRight || isSubmitting}
                      inputProps={{
                        className: classes.textFieldInput,
                      }}
                      value={item.value || ""}
                      error={
                        formik.errors.pathSettings?.[phaseIndex]?.settings?.[i]
                          ?.value &&
                        formik.touched.pathSettings?.[phaseIndex]?.settings?.[i]
                          ?.value
                      }
                      helperText={ErrorMessage({
                        name: `pathSettings[${phaseIndex}].settings[${i}].value`,
                      })}
                    />
                  ) : (
                    <Typography variant="body2">{item.value || "-"}</Typography>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Paper>
  );
}
