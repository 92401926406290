import React from "react";
import LuckyDrawListingTable from "../../table/luckyDrawListingTable";
import { Box } from "@material-ui/core";
import WithProductDateProgressSearch from "../../../../../components/search/withProductDateProgressSearch";

export default function LuckyDrawListingPanelComponent({
  hasLuckyDrawEditAccessRight,
  isFetching,
  tableFilter,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleStatusChange,
  handleFetchLuckyDrawList,
  paginationOptions,
  language,
  productDropdown,
  isFetchingProduct,
  lang,
  isError,
  handleLiveDraw,
  handleAddLuckyDraw,
  handleAddInstantWin,
  handleBinding,
  handleDownloadWinner,
  handlePublished,
  handleEnd,
  handleJoinClose,
}) {
  return (
    <Box>
      <WithProductDateProgressSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />

      <LuckyDrawListingTable
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        isFetching={isFetching}
        page={tableFilter.page}
        search={tableFilter.search}
        rowsPerPage={tableFilter.rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleStatusChange={handleStatusChange}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        paginationOptions={paginationOptions}
        lang={lang}
        language={language}
        isError={isError}
        handleLiveDraw={handleLiveDraw}
        handleAddLuckyDraw={handleAddLuckyDraw}
        handleAddInstantWin={handleAddInstantWin}
        handleBinding={handleBinding}
        handleDownloadWinner={handleDownloadWinner}
        handlePublished={handlePublished}
        handleEnd={handleEnd}
        handleJoinClose={handleJoinClose}
      />
    </Box>
  );
}
