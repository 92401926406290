import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  Backdrop,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Formik } from "formik";
import DraggablePrizeCard from "../../card/draggablePrizeCard";
import * as Yup from "yup";
import RefreshTable from "components/table/refreshTable";
import { Skeleton } from "@material-ui/lab";

const style = (theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#ebedeec2",
    overflowY: "auto",
    padding: "16px",
    maxHeight: "70vh",
  },
  footer: {},
});

const useStyles = makeStyles(style);

export default function LiveDrawSetupDialogComponent({
  isOpen,
  handleClose,
  prizes,
  handleSubmit,
  lang,
  isSubmitting,
  isError,
  isFetching,
  handleReload,
}) {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.LIVE_DRAW_SETUP")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>

      <Divider />

      <Formik
        initialValues={{
          prizes: prizes.map((prize) => ({
            ...prize,
            draw_method: prize.draw_method || 1,
            draw_duration: prize.draw_duration || 3,
          })),
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          prizes: Yup.array().of(
            Yup.object({
              draw_method: Yup.number().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              ),
              draw_duration: Yup.number()
                .moreThan(
                  0,
                  getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                    value: 0,
                  })
                )
                .max(
                  120,
                  getLang(lang, "message.error.MAX_DRAW_DURATION", {
                    total: 120,
                  })
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
            })
          ),
        })}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.container} p={2}>
              <Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    {getLang(lang, "paragraph.LIVE_DRAW_SETUP_DESC")}
                  </Typography>
                </Box>
                {isFetching ? (
                  <Box>
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                  </Box>
                ) : isError ? (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RefreshTable handleReload={handleReload} />
                  </Box>
                ) : (
                  <DndProvider backend={HTML5Backend}>
                    {formik.values.prizes.map((prize, index) => (
                      <DraggablePrizeCard
                        key={prize.id}
                        index={index}
                        lang={lang}
                        updatePrize={(val) => {
                          formik.setFieldValue("prizes", val);

                          // if (!formik.values.isUpdated) {
                          //   formik.setFieldValue("isUpdated", true);
                          // }
                        }}
                        prize={prize}
                        prizes={formik.values.prizes}
                        isSubmitting={isSubmitting}
                        formik={formik}
                      />
                    ))}
                  </DndProvider>
                )}
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={2}
            >
              <Button
                variant="text"
                disabled={isSubmitting}
                disableElevation
                onClick={() => {
                  formik.resetForm();
                  handleClose();
                }}
                style={{ marginRight: "4px" }}
              >
                {getLang(lang, "label.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                startIcon={
                  isSubmitting && (
                    <CircularProgress
                      style={{ width: 15, height: 15, color: "white" }}
                    />
                  )
                }
              >
                {getLang(lang, "label.START_NOW")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
