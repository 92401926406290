/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import LiveDrawPageComponent from "./liveDraw.page";
import { LUCKY_DRAW_V2_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { addAlert } from "modules/notification";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { getApiLang } from "app/feature/constants";

function LiveDrawPageContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const hasLuckyDrawViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const uuid = match.params.luckyDrawID;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [luckyDraw, setLuckyDraw] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [hasRankedPrizes, setHasRankedPrizes] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (!hasLuckyDrawViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasLuckyDrawViewAccessRight]);

  useEffect(() => {
    if (
      luckyDraw &&
      (Number(luckyDraw.type) !== 1 ||
        luckyDraw.progress !== "published" ||
        !!luckyDraw.end_at)
    ) {
      history.goBack();
    }
  }, [history, luckyDraw]);

  useEffect(() => {
    getData();

    document.onfullscreenchange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    return () => {
      document.onfullscreenchange = null;
    };
  }, []);

  const getData = () => {
    setIsFetching(true);
    luckyDrawV2Api
      .getLuckyDrawLiveDrawInfo(uuid)
      .then((res) => {
        setLuckyDraw(res.data.lucky_draw);

        let prizeList = res.data.lucky_draw_prizes;
        if (res.data.lucky_draw.draw_end_at) {
          setShowResult(true);
        }

        // for display purpose
        let isRankPrizeExist = prizeList.reduce((a, b) => {
          return b.rank_position && Number(b.rank_position) > 0;
        }, false);
        setHasRankedPrizes(isRankPrizeExist);

        setPrizes(prizeList);
      })
      .catch((error) => showAlertMessage(error.code))
      .finally(() => setIsFetching(false));
  };

  if (!hasLuckyDrawViewAccessRight) return <LoadingComponent />;

  const handleDraw = (prizeUuid) => {
    setIsDrawing(true);
    luckyDrawV2Api
      .drawLuckyDraw({ uuid, prizeUuid })
      .then((res) => {
        let prizeIndex = prizes.findIndex((prize) => prize.uuid === prizeUuid);
        let tempPrizes = [...prizes];
        tempPrizes[prizeIndex] = res.data;
        setPrizes(tempPrizes);

        setTimeout(() => {
          showGif();
        }, Number(res.data?.draw_duration || 0) * 1000 + 100);
      })
      .catch((error) => showAlertMessage(error.code))
      .finally(() => {
        setIsDrawing(false);
      });
  };

  const showAlertMessage = (code) => {
    dispatch(
      addAlert({
        severity: "error",
        message: getApiLang(lang, code),
      })
    );
  };

  const handleButtonAction = (prize) => {
    if (!showResult) {
      if (prize?.winners.length === Number(prize.quantity)) {
        if (currentIndex !== prizes.length - 1) {
          let tempPrizes = [...prizes];
          tempPrizes[currentIndex] = {
            ...prize,
            winners: prize.winners.map((winner) => ({
              ...winner,
              is_new_draw: false,
            })),
          };
          setPrizes(tempPrizes);
          setCurrentIndex(currentIndex + 1);
        } else {
          getData();
          setShowResult(true);
        }
      } else {
        handleDraw(prize.uuid);
      }
    } else {
      history.replace(ROOT);
    }
  };

  const handleNextPage = (prize) => {
    if (currentIndex < prizes.length - 1) {
      if (prize?.winners?.length === Number(prize?.quantity)) {
        let tempPrizes = [...prizes];
        tempPrizes[currentIndex] = {
          ...prize,
          winners: prize.winners.map((winner) => ({
            ...winner,
            is_new_draw: false,
          })),
        };
        setPrizes(tempPrizes);

        setCurrentIndex(currentIndex + 1);
      }
    }
  };

  const handlePreviousPage = (prize) => {
    if (currentIndex > 0) {
      let tempPrizes = [...prizes];
      tempPrizes[currentIndex] = {
        ...prize,
        winners: prize.winners.map((winner) => ({
          ...winner,
          is_new_draw: false,
        })),
      };
      setPrizes(tempPrizes);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleFullScreen = (e) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullScreen(false);
    } else {
      document.body.requestFullscreen();
      setIsFullScreen(true);
    }
  }

  const showGif = () => {
    const gif = document.getElementById("confetti");

    // Remove the GIF from the DOM to reset it
    const src = gif.src;
    gif.src = ""; // Remove the GIF src temporarily
    gif.style.display = "block"; // Show the GIF

    // Re-assign the src back after a slight delay to reset the animation
    setTimeout(() => {
      gif.src = src;
    }, 10);

    // Hide the GIF after 8 seconds
    setTimeout(() => {
      gif.style.display = "none"; // Hide the GIF
    }, 8050); // 8000 milliseconds = 8 seconds
  };

  return (
    <LiveDrawPageComponent
      prizes={prizes}
      luckyDraw={luckyDraw}
      lang={lang}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      isLoading={isFetching}
      isDrawing={isDrawing}
      handleButtonAction={handleButtonAction}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
      handleFullScreen={handleFullScreen}
      isFullScreen={isFullScreen}
      showResult={showResult}
      hasRankedPrizes={hasRankedPrizes}
    />
  );
}

export default LiveDrawPageContainer;
