import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import InputTextField from "components/input/inputTextField";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    height: 200,
  },
  list: {
    paddingTop: 3,
    paddingBottom: 3,
    display: "flex",
  },
  listImage: {
    marginRight: "0.5em",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  listContainer: {
    padding: 0,
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: "#6e6e6e14",
    },
  },
}));

export default function ProductSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  value,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: "20em" },
        ...PaperProps,
      }}
    >
      {searchable && (
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0,
              },
            }}
            inputProps={{
              onChange: () => {
                handleSearch(textRef.current.value);
              },
              style: {
                padding: 8,
              },
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      <Box
        style={{ maxHeight: "250px", overflow: "auto", scrollbarWidth: "thin" }}
      >
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 ? (
          <List className={classes.listContainer}>
            {items.map((item, index) => {
              return (
                <Box key={index}>
                  <ListItem
                    button
                    onClick={() => {
                      handleChange(item);
                      handleClose();
                    }}
                    selected={value && value.id === item.id}
                  >
                    <Box className={classes.list}>
                      <Box className={classes.listImage}>
                        <img
                          src={item.picture ? item.picture : DefaultImg}
                          style={{ width: "30px", height: "30px" }}
                          alt={item.id}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Typography className={classes.name} variant="body2">
                          {item.name}
                        </Typography>
                        {!!item.attributes && !!item.attributes.length && (
                          <Typography
                            className={classes.attributeText}
                            variant="body2"
                          >
                            {item.attributes.join(", ")}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                </Box>
              );
            })}
          </List>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant="body1">{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
}
