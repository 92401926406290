import {
  fetchInstantLuckyDrawListing,
  createInstantLuckyDraw,
  fetchInstantLuckyDrawDetail,
  fetchJSONURL,
  updateInstantLuckyDrawDetail,
  fetchInstantLuckyDrawParticipantTable,
  fetchParticipantDetail,
  changeInstantLuckyDrawStatus,
  fetchAllInstantPrizeLegacy,
  createPrize,
  updatePrize,
  searchSerialNumber,
  // fetchSerialNumberList,
  // fetchMoreSerialNumber,
  adjustQuantity,
  changePrizeStatus,
  setWinner,
  changeInstantLuckyDrawProgress,
  fetchAllInstantPrize,
  updateInstantLuckyDrawPrizes,
  presetWinner,
  publishInstantLuckyDraw,
} from "./instant-lucky-draw.action";
import { setLoading, addAlert } from "modules/notification";
import {
  setInstantLuckyDrawDetailFromApi,
  setInstantLuckyDrawTNC,
  setInstantLuckyDrawDetailUpdate,
  setParticipantTable,
  setParticipantDetail,
} from "./instant-lucky-draw.slice";
import { getApiLang } from "app/feature/constants";

const fetchInstantLuckyDrawListTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchInstantLuckyDrawListing.pending.type:
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawListing.typePrefix,
            state: true,
          })
        );
        break;

      case fetchInstantLuckyDrawListing.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawListing.typePrefix,
            state: false,
          })
        );
        break;

      case fetchInstantLuckyDrawListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const createInstantLuckyDrawMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().instantLuckyDraw.pageSetting;

    switch (type) {
      case createInstantLuckyDraw.pending.type:
        dispatch(
          setLoading({ id: createInstantLuckyDraw.typePrefix, state: true })
        );
        break;

      case createInstantLuckyDraw.fulfilled.type:
        // next(setInstantLuckyDrawID(action.payload));
        // next(setDefaultPrize(action.payload));
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        dispatch(
          setLoading({ id: createInstantLuckyDraw.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CREATE_INSTANT_LUCKY_DRAW"
            ),
          })
        );
        break;

      case createInstantLuckyDraw.rejected.type:
        dispatch(
          setLoading({ id: createInstantLuckyDraw.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchInstantLuckyDrawDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchInstantLuckyDrawDetail.pending.type:
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawDetail.typePrefix,
            state: true,
          })
        );
        break;

      case fetchInstantLuckyDrawDetail.fulfilled.type:
        next(setInstantLuckyDrawDetailFromApi(action.payload));
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        break;

      case fetchInstantLuckyDrawDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateInstantLuckyDrawDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().instantLuckyDraw.pageSetting;

    switch (type) {
      case updateInstantLuckyDrawDetail.pending.type:
        dispatch(
          setLoading({
            id: updateInstantLuckyDrawDetail.typePrefix,
            state: true,
          })
        );
        break;

      case updateInstantLuckyDrawDetail.fulfilled.type:
        dispatch(setInstantLuckyDrawDetailUpdate(action.payload));
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        dispatch(
          setLoading({
            id: updateInstantLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_UPDATE_INSTANT_LUCKY_DRAW_DETAIL"
            ),
          })
        );
        break;

      case updateInstantLuckyDrawDetail.rejected.type:
        dispatch(
          setLoading({
            id: updateInstantLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchJSONURLMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchJSONURL.pending.type:
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: true }));
        break;

      case fetchJSONURL.fulfilled.type:
        next(setInstantLuckyDrawTNC(action.payload));
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        break;

      case fetchJSONURL.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchInstantLuckyDrawParticipantTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchInstantLuckyDrawParticipantTable.pending.type:
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawParticipantTable.typePrefix,
            state: true,
          })
        );
        break;

      case fetchInstantLuckyDrawParticipantTable.fulfilled.type:
        next(setParticipantTable(action.payload));
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawParticipantTable.typePrefix,
            state: false,
          })
        );
        break;

      case fetchInstantLuckyDrawParticipantTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchInstantLuckyDrawParticipantTable.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchParticipantDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchParticipantDetail.pending.type:
        dispatch(
          setLoading({
            id: fetchParticipantDetail.typePrefix,
            state: true,
          })
        );
        break;

      case fetchParticipantDetail.fulfilled.type:
        next(setParticipantDetail(action.payload));
        dispatch(
          setLoading({
            id: fetchParticipantDetail.typePrefix,
            state: false,
          })
        );
        break;

      case fetchParticipantDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchParticipantDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const changeInstantLuckyDrawStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case changeInstantLuckyDrawStatus.pending.type:
        dispatch(
          setLoading({
            id: changeInstantLuckyDrawStatus.typePrefix,
            state: true,
          })
        );
        break;
      case changeInstantLuckyDrawStatus.fulfilled.type:
        dispatch(
          setLoading({
            id: changeInstantLuckyDrawStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_INSTANT_LUCKY_DRAW_STATUS"
            ),
          })
        );
        break;
      case changeInstantLuckyDrawStatus.rejected.type:
        dispatch(
          setLoading({
            id: changeInstantLuckyDrawStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const fetchAllInstantPrizeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchAllInstantPrize.pending.type:
        dispatch(
          setLoading({ id: fetchAllInstantPrize.typePrefix, state: true })
        );
        break;
      case fetchAllInstantPrize.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchAllInstantPrize.typePrefix,
            state: false,
          })
        );
        break;
      case fetchAllInstantPrize.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchAllInstantPrize.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const fetchAllInstantPrizeLegacyMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchAllInstantPrizeLegacy.pending.type:
        dispatch(
          setLoading({ id: fetchAllInstantPrizeLegacy.typePrefix, state: true })
        );
        break;
      case fetchAllInstantPrizeLegacy.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchAllInstantPrizeLegacy.typePrefix,
            state: false,
          })
        );
        break;
      case fetchAllInstantPrizeLegacy.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchAllInstantPrizeLegacy.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const createPrizeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().instantLuckyDraw.pageSetting;

    switch (type) {
      case createPrize.pending.type:
        dispatch(setLoading({ id: createPrize.typePrefix, state: true }));
        break;

      case createPrize.fulfilled.type:
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        dispatch(setLoading({ id: createPrize.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_PRIZE_INSTANT"),
          })
        );
        break;

      case createPrize.rejected.type:
        dispatch(setLoading({ id: createPrize.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updatePrizeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updatePrize.pending.type:
        dispatch(
          setLoading({
            id: updatePrize.typePrefix,
            state: true,
          })
        );
        break;

      case updatePrize.fulfilled.type:
        dispatch(
          setLoading({
            id: updatePrize.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_PRIZE_INSTANT"),
          })
        );
        break;

      case updatePrize.rejected.type:
        dispatch(
          setLoading({
            id: updatePrize.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

// const fetchMoreSerialNumberListMiddleware =
//   ({ dispatch, getState }) =>
//   (next) =>
//   (action) => {
//     next(action);
//     const { type } = action;
//     if (type === fetchMoreSerialNumber.type) {
//       const p = getState().instantLuckyDraw.serialNumberList;
//       const id = getState().instantLuckyDraw.InstantLuckyDrawDialogInfo.info.id;
//       const start = p.data.length;
//       const search = getState().instantLuckyDraw.searchForSerialNumber;
//       const excludeList = getState().instantLuckyDraw.excludeWinnerList;
//       const exclude = [];
//       excludeList.forEach((element, index) => exclude.push(element.enc));

//       const fetchInfo = {
//         id,
//         length: 25,
//         start: start,
//         search: search,
//         status: "active",
//         exclude: exclude,
//       };

//       dispatch(fetchSerialNumberList(fetchInfo));
//     }
//   };

// const fetchSerialNumberListMiddleware =
//   ({ dispatch, getState }) =>
//   (next) =>
//   (action) => {
//     next(action);
//     const { type, payload } = action;

//     switch (type) {
//       case fetchSerialNumberList.pending.type:
//         dispatch(
//           setLoading({
//             id: fetchSerialNumberList.typePrefix,
//             state: true,
//           })
//         );
//         break;

//       case fetchSerialNumberList.fulfilled.type:
//         dispatch(
//           setLoading({
//             id: fetchSerialNumberList.typePrefix,
//             state: false,
//           })
//         );
//         break;

//       case fetchSerialNumberList.rejected.type:
//         const lang = getState().constant.languages;
//         dispatch(
//           setLoading({
//             id: fetchSerialNumberList.typePrefix,
//             state: false,
//           })
//         );
//         dispatch(
//           addAlert({
//             severity: "error",
//             message: getApiLang(lang, payload.code),
//           })
//         );
//         break;

//       default:
//         break;
//     }
//   };

const searchSerialNumberMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const { type, payload } = action;

    switch (type) {
      case searchSerialNumber.pending.type:
        dispatch(
          setLoading({
            id: searchSerialNumber.typePrefix,
            state: true,
          })
        );
        break;

      case searchSerialNumber.fulfilled.type:
        dispatch(
          setLoading({
            id: searchSerialNumber.typePrefix,
            state: false,
          })
        );
        break;

      case searchSerialNumber.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: searchSerialNumber.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const adjustQuantityMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().instantLuckyDraw.pageSetting;

    switch (type) {
      case adjustQuantity.pending.type:
        dispatch(
          setLoading({
            id: adjustQuantity.typePrefix,
            state: true,
          })
        );
        break;
      case adjustQuantity.fulfilled.type:
        dispatch(
          setLoading({
            id: adjustQuantity.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_ADJUST_QUANTITY"),
          })
        );
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        break;
      case adjustQuantity.rejected.type:
        dispatch(
          setLoading({
            id: adjustQuantity.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const changePrizeStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case changePrizeStatus.pending.type:
        dispatch(
          setLoading({
            id: changePrizeStatus.typePrefix,
            state: true,
          })
        );
        break;
      case changePrizeStatus.fulfilled.type:
        dispatch(
          setLoading({
            id: changePrizeStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CHANGE_PRIZE_STATUS"),
          })
        );
        break;
      case changePrizeStatus.rejected.type:
        dispatch(
          setLoading({
            id: changePrizeStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const setWinnerMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case setWinner.pending.type:
        dispatch(
          setLoading({
            id: setWinner.typePrefix,
            state: true,
          })
        );
        break;

      case setWinner.fulfilled.type:
        dispatch(
          setLoading({
            id: setWinner.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_SET_WINNER"),
          })
        );
        break;

      case setWinner.rejected.type:
        dispatch(
          setLoading({
            id: setWinner.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const changeInstantLuckyDrawProgressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().instantLuckyDraw.pageSetting;

    switch (type) {
      case changeInstantLuckyDrawProgress.pending.type:
        dispatch(
          setLoading({
            id: changeInstantLuckyDrawProgress.typePrefix,
            state: true,
          })
        );
        break;
      case changeInstantLuckyDrawProgress.fulfilled.type:
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        dispatch(
          setLoading({
            id: changeInstantLuckyDrawProgress.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CHANGE_INSTANT_LUCKY_DRAW_PROGRESS"
            ),
          })
        );
        break;
      case changeInstantLuckyDrawProgress.rejected.type:
        dispatch(
          setLoading({
            id: changeInstantLuckyDrawProgress.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateInstantLuckyDrawPrizesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateInstantLuckyDrawPrizes.pending.type:
        dispatch(
          setLoading({
            id: updateInstantLuckyDrawPrizes.typePrefix,
            state: true,
          })
        );
        break;

      case updateInstantLuckyDrawPrizes.fulfilled.type:
        dispatch(
          setLoading({
            id: updateInstantLuckyDrawPrizes.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CREATE_INSTANT_LUCKY_DRAW"
            ),
          })
        );
        break;

      case updateInstantLuckyDrawPrizes.rejected.type:
        dispatch(
          setLoading({
            id: updateInstantLuckyDrawPrizes.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const presetWinnerMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case presetWinner.pending.type:
        dispatch(
          setLoading({
            id: presetWinner.typePrefix,
            state: true,
          })
        );
        break;

      case presetWinner.fulfilled.type:
        dispatch(
          setLoading({
            id: presetWinner.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_SET_WINNER"),
          })
        );
        break;

      case presetWinner.rejected.type:
        dispatch(
          setLoading({
            id: presetWinner.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const publishInstantLuckyDrawMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().instantLuckyDraw.pageSetting;

    switch (type) {
      case publishInstantLuckyDraw.pending.type:
        dispatch(
          setLoading({
            id: publishInstantLuckyDraw.typePrefix,
            state: true,
          })
        );
        break;
      case publishInstantLuckyDraw.fulfilled.type:
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        dispatch(
          setLoading({
            id: publishInstantLuckyDraw.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CHANGE_INSTANT_LUCKY_DRAW_PROGRESS"
            ),
          })
        );
        break;
      case publishInstantLuckyDraw.rejected.type:
        dispatch(
          setLoading({
            id: publishInstantLuckyDraw.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const instantLuckyDrawMiddleware = [
  fetchInstantLuckyDrawListTableMiddleware,
  createInstantLuckyDrawMiddleware,
  fetchInstantLuckyDrawDetailMiddleware,
  updateInstantLuckyDrawDetailMiddleware,
  fetchJSONURLMiddleware,
  fetchInstantLuckyDrawParticipantTableMiddleware,
  fetchParticipantDetailMiddleware,
  changeInstantLuckyDrawStatusMiddleware,
  fetchAllInstantPrizeMiddleware,
  fetchAllInstantPrizeLegacyMiddleware,
  createPrizeMiddleware,
  updatePrizeMiddleware,
  // fetchMoreSerialNumberListMiddleware,
  // fetchSerialNumberListMiddleware,
  searchSerialNumberMiddleware,
  adjustQuantityMiddleware,
  changePrizeStatusMiddleware,
  setWinnerMiddleware,
  changeInstantLuckyDrawProgressMiddleware,
  updateInstantLuckyDrawPrizesMiddleware,
  publishInstantLuckyDrawMiddleware,
  presetWinnerMiddleware,
];
