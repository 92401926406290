import { Box, Divider, makeStyles, Tab, Tabs, useTheme, withStyles } from "@material-ui/core";
import React from "react";
import SwipeableViews from "react-swipeable-views";

import { 
    CategoryPage,
    BrandPage,
    ModelPage,
    ProductListPage,
    SuspendedProductPage,
} from "./pages";

const useStyles = makeStyles({
    header__wrapper: {
        backgroundColor: "#FFFFFF",
        paddingTop: "0px",
        paddingLeft: "32px",
        paddingRight: "32px",

    },
    header__title: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#344054",
        fontFamily: "Roboto",
    },
    fullWidth: {
        width: "100%",
        margin: "0px"
    }
})

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box>
                {children}
            </Box>
            )}
        </div>
    );
}

export default function ProductReport({
    filter,
    pageHandler,
    pageMode,
    translate
}) {
    const classes = useStyles();
    const theme = useTheme();
    // const [pageValue, setPageValue] = useState(0);

    // page tab navigation -- START
    const CustomizeTabs = withStyles({
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "50px",
                width: "100%",
                backgroundColor: "#344054",
            }
        }
    })((props) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);

    const CustomizeTab = withStyles((theme) => ({
        root: {
            textTransform: 'none',
            color: '#344054',
            fontWeight: 500,
            fontSize: "14px",
            '&:focus': {
                opacity: 1,
            },
            minWidth: "80px",
        },
        // }))((props) => <Tab to={props.to} component={Link} disableRipple {...props} />);
    }))((props) => <Tab disableRipple {...props} />);

    const handlePageValueChange = (event, newValue) => {
        pageHandler.setProductPageValue(newValue);

        if(newValue === 0) {
            pageMode.setPage("product-category");
        } else if(newValue === 1) {
            pageMode.setPage("product-brand");
        } else if(newValue === 2) {
            pageMode.setPage("product-model");
        } else if(newValue === 3) {
            pageMode.setPage("product-list");
        } else if(newValue === 4) {
            pageMode.setPage("suspended-product");
        }

    }

    const handlePageChangeIndex = (index) => {
        pageHandler.setProductPageValue(index);

        if(index === 0) {
            pageMode.setPage("product-category");
        } else if(index === 1) {
            pageMode.setPage("product-brand");
        } else if(index === 2) {
            pageMode.setPage("product-model");
        } else if(index === 3) {
            pageMode.setPage("product-list");
        } else if(index === 4) {
            pageMode.setPage("suspended-product");
        }
    }


    return (
        <>
            <Box className={classes.header__wrapper}>

                {/* page navigation */}
                <CustomizeTabs value={pageHandler.productPageValue} onChange={handlePageValueChange} >
                    <CustomizeTab label={translate("label.CATEGORY")}/>
                    <CustomizeTab label={translate("label.BRAND")}/>
                    <CustomizeTab label={translate("label.MODEL")} />
                    <CustomizeTab label={translate("label.PRODUCT_LIST")} />
                    <CustomizeTab label={translate("label.SUSPENDED_PRODUCT")} />
                </CustomizeTabs>
            </Box>
            <Divider className={classes.fullWidth}/>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={pageHandler.productPageValue}
                onChangeIndex={handlePageChangeIndex}
            >
                <TabPanel value={pageHandler.productPageValue} index={0} dir={theme.direction}>
                    <CategoryPage 
                        filter={filter} 
                        pageMode = {pageMode}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.productPageValue} index={1} dir={theme.direction}>
                    <BrandPage 
                        filter={filter}
                        pageMode = {pageMode}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.productPageValue} index={2} dir={theme.direction}>
                    <ModelPage 
                        filter={filter}
                        pageMode = {pageMode}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.productPageValue} index={3} dir={theme.direction}>
                    <ProductListPage 
                        filter={filter}
                        pageMode = {pageMode}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.productPageValue} index={4} dir={theme.direction}>
                    <SuspendedProductPage 
                        filter={filter}
                        pageMode = {pageMode}
                        translate={translate}
                    />
                </TabPanel>
            </SwipeableViews>
        </>
    )

}