import React, { useState } from "react";
import ParticipantsListTableComponent from "./participantsListTable.component";
import ParticipantDetailDialog from "modules/lucky-draw-v2/components/dialog/participantDetailDialog";

export default function ParticipantsListTableContainer({
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  handleFetchLuckyDrawList,
  paginationOptions,
  lang,
  isError,
  luckyDrawUUID
}) {
  const [participantUuid, setParticipantUuid] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleViewDetails = (uuid) => {
    setParticipantUuid(uuid);
    setIsModalOpen(true);
  };

  return (
    <>
      <ParticipantsListTableComponent
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        language={language}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        isLoadingLDParticipantError={isError}
        paginationOptions={paginationOptions}
        lang={lang}
        handleViewDetails={handleViewDetails}
      />
      <ParticipantDetailDialog
        participantUuid={participantUuid}
        isOpen={isModalOpen}
        handleClose={closeModal}
        luckyDrawUUID={luckyDrawUUID}
      />
    </>
  );
}
