import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/serialnumber/unassign-list";

const getUnassignListing = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, {
        length: parameters.length,
        start: parameters.start,
        order: parameters.order,
        node_uuids: parameters.node_uuids,
        status: parameters.status,
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getUnassignListing;
