import axios from "axios";

const getUnassignDetail = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/serialnumber/unassign/detail/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getUnassignDetail;
