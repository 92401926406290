/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import LuckyDrawDetailCardComponent from "./luckyDrawDetailCard.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { getLuckyDrawCardDetail } from "modules/lucky-draw/redux";
import moment from "moment";

export default function LuckyDrawDetailCardContainer({ id }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    selectLoading(state, getLuckyDrawCardDetail.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const uiSetting = useSelector((state) => state.setting.uiSetting);

  const [luckyDraw, setLuckyDraw] = useState(null);
  const [countdown, setCountdown] = useState({
    day: 0,
    hour: 0,
    min: 0,
    sec: 0,
  });
  const [drawCountdown, setDrawCountdown] = useState({
    day: 0,
    hour: 0,
    min: 0,
    sec: 0,
  });

  const intervalRef = useRef(null);
  const drawIntervalRef = useRef(null);

  useEffect(() => {
    dispatch(getLuckyDrawCardDetail({ id }))
      .then(unwrapResult)
      .then((data) => {
        setLuckyDraw(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (luckyDraw) {
      if (luckyDraw.version === "current") {
        let isStart = moment().isAfter(
          moment.utc(luckyDraw.join_open_at).local()
        );
        if (!isStart) {
          checkJoinStartCountdown(luckyDraw);
        } else {
          checkJoinCloseCountdown(luckyDraw);
        }
      } else {
        if (!!luckyDraw.end_at) {
          let isJoinEnd = moment().isAfter(
            moment.utc(luckyDraw.end_at).local()
          );
          if (!isJoinEnd) {
            intervalRef.current = setInterval(() => {
              let tempCountdown = updateCountdown({
                targetDate: moment.utc(luckyDraw.end_at).local(),
              });
              setCountdown(tempCountdown);
            }, 1000);
          }
        }
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [luckyDraw]);

  const checkJoinStartCountdown = (luckyDraw) => {
    intervalRef.current = setInterval(() => {
      let tempCountdown = updateCountdown({
        targetDate: moment.utc(luckyDraw.join_open_at).local(),
      });
      setCountdown(tempCountdown);

      let countdownEnd =
        !tempCountdown.day &&
        !tempCountdown.hour &&
        !tempCountdown.min &&
        !tempCountdown.sec;
      if (countdownEnd && intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (countdownEnd && luckyDraw.join_close_at) {
        checkJoinCloseCountdown(luckyDraw);
      }
    }, 1000);
  };

  const checkJoinCloseCountdown = (luckyDraw) => {
    if (!!luckyDraw.join_close_at) {
      let isJoinEnd = moment().isAfter(
        moment.utc(luckyDraw.join_close_at).local()
      );
      if (!isJoinEnd) {
        intervalRef.current = setInterval(() => {
          let tempCountdown = updateCountdown({
            targetDate: moment.utc(luckyDraw.join_close_at).local(),
          });
          setCountdown(tempCountdown);

          let countdownEnd =
            !tempCountdown.day &&
            !tempCountdown.hour &&
            !tempCountdown.min &&
            !tempCountdown.sec;
          if (countdownEnd && intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (luckyDraw && luckyDraw.version === "current") {
      let isJoinEnd = luckyDraw.join_close_at
        ? moment().isAfter(moment.utc(luckyDraw.join_close_at).local())
        : false;
      if (
        (!!luckyDraw.participant_joined || isJoinEnd) &&
        luckyDraw.join_end_action === 1 &&
        luckyDraw.draw_at &&
        moment().isBefore(moment.utc(luckyDraw.draw_at).local())
      ) {
        drawIntervalRef.current = setInterval(() => {
          let tempCountdown = updateCountdown({
            targetDate: moment.utc(luckyDraw.draw_at).local(),
          });
          setDrawCountdown(tempCountdown);

          let countdownEnd =
            !tempCountdown.day &&
            !tempCountdown.hour &&
            !tempCountdown.min &&
            !tempCountdown.sec;
          if (countdownEnd && drawIntervalRef.current) {
            clearInterval(drawIntervalRef.current);
          }
        }, 1000);
      }
    }

    return () => {
      if (drawIntervalRef.current) {
        clearInterval(drawIntervalRef.current);
      }
    };
  }, [luckyDraw]);

  const updateCountdown = ({ targetDate }) => {
    var seconds = Math.floor(
      moment.duration(targetDate.diff(moment())).asSeconds()
    );
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    return {
      day: days > 0 ? days : 0,
      hour: hours - days * 24,
      min: minutes - hours * 60,
      sec: seconds - minutes * 60,
    };
  };

  return (
    <LuckyDrawDetailCardComponent
      luckyDraw={luckyDraw}
      lang={lang}
      isLoading={isLoading}
      uiSetting={uiSetting}
      countdown={countdown}
      drawCountdown={drawCountdown}
    />
  );
}
