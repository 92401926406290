import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Tabs, Divider } from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import CompanyInfoPanel from "../../components/panel/companyInfoPanel";
import ProductPageSettingPanel from "../../components/panel/productPageSettingPanel";
import CurrencyConfigSettingPanel from "../../components/panel/currencyConfigSettingPanel";
import StockModuleSettingPanel from "modules/account/components/panel/stockModuleSettingPanel";
import { getLang } from "app/feature/constants";
import ConsumerEngagementSettingPanel from "modules/account/components/panel/consumerEngagementSettingPanel";
import IntegrationSettingPanel from "modules/account/components/panel/integrationSettingPanel";

const style = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "7fr 3fr",
    gridTemplayeRows: "auto auto",
    columnGap: theme.spacing(2),
    gridTemplateAreas: "'topbar .''tab .'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'topbar topbar''tab tab'",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'topbar topbar''tab tab'",
    },
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function AccountPageComponent({
  handleChange,
  activeIndex,
  tabLabels,
  handleAddCurrency,
  isAddCurrencyDialogOpen,
  lang,
  availableTabs,
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box>
      <Box>
        <Box gridArea="topbar">
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.COMPANY")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {tabLabels[activeIndex]}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box gridArea="tab">
          <Tabs
            value={activeIndex}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              indicator: classes.indicator,
              scrollButtons: classes.scroll,
            }}
          >
            {tabLabels.map((item, index) => {
              return <CustomTab key={index} {...a11yProps(index, item)} />;
            })}
          </Tabs>
        </Box>
      </Box>
      <Box>
        {tabLabels.map((tab, index) => {
          if (tab === availableTabs.info) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <CompanyInfoPanel />
              </TabPanel>
            );
          }
          if (tab === availableTabs.product) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <ProductPageSettingPanel />
              </TabPanel>
            );
          }
          if (tab === availableTabs.currency) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <CurrencyConfigSettingPanel
                  handleAddCurrency={handleAddCurrency}
                  isAddDialogOpen={isAddCurrencyDialogOpen}
                />
              </TabPanel>
            );
          }
          if (tab === availableTabs.stock) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <StockModuleSettingPanel />
              </TabPanel>
            );
          }
          if (tab === availableTabs.engagement) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <ConsumerEngagementSettingPanel />
              </TabPanel>
            );
          }
          if (tab === availableTabs.integration) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <IntegrationSettingPanel />
              </TabPanel>
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
}
