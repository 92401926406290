import React, { useState } from 'react';
import PrizeImgInputComponent from './prize-img-input.component';
import Modal from '../modal';
import { ImageEditorPanel } from 'components/panel';
import { setImgFormatAndSize } from 'lib/FileHelper';
import { addAlert } from 'modules/notification';
import { useDispatch } from 'react-redux';
import { getLang } from 'app/feature/constants';

function PrizeImgInputContainer({
  lang,
  handleOnResult,
  children,
}) {
  const dispatch = useDispatch();
  const [isCropping, setCropping] = useState(false);
  const [bufferImg, setBufferImg] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [fileName, setFileName] = useState(null);
  const allowedImageExtension = ['.jpeg', '.jpg', '.png', '.webp'];

  const handleImgChange = (e) => {
    try {
      const file = e.currentTarget.files[0];
      // sometimes createObjectURL fail (overload resolution failed)
      const image = URL.createObjectURL(file);
  
      if (!ValidImageFormat(file.name)) {
        dispatch(
          addAlert({ severity: "error", message: getLang(lang,"message.error.INVALID_IMAGE_FORMAT") })
        );
        return;
      }
      
      setFileName(file.name);
      setBufferImg(image);
      setModalOpen(true);
      setCropping(true);
    } catch (e) {
      dispatch(
        addAlert({ severity: "error", message: getLang(lang,"message.error.SOMETHING_WENT_WRONG") })
      );
    }
  }

  const ValidImageFormat = (file) => {
    for (let index = 0; index < allowedImageExtension.length; index++) {
      if (file.indexOf(allowedImageExtension[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleResult = async (base64Image) => {
    
    base64Image = await setImgFormatAndSize(base64Image, 1024, 1024, 'image/webp');
    fetch(base64Image)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], fileName, { type: "image/webp" });
      
      handleOnResult(file);

      setCropping(false);
      setModalOpen(false);
    })
  };

  return (
    <>
      <PrizeImgInputComponent handleImgChange={handleImgChange}>
        {children}
      </PrizeImgInputComponent>
      <Modal
        isOpen={isModalOpen}
        handleClose={handleClose}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        {isCropping && (
          <ImageEditorPanel
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
      </Modal>
    </>
  );
}

export default PrizeImgInputContainer;
