import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ROOT } from "../../config/product-mgmt.route";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  PRODUCT_TEMPLATE_EDIT,
  PRODUCT_TEMPLATE_VIEW
} from "lib/constants/accessRights";
import { setPageWorkspaceLanguage } from "modules/product-mgmt/redux/slice/productPage.slice";
import LoadingComponent from "components/loading";
import EditTemplateComponent from "./edit-template.page";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  editTemplate,
  fetchCustomTemplateHtml,
  fetchTemplate,
  fetchTemplateSettings
} from "../../redux/action/template.action";
import { getConsumerPageSetting } from "../../../account/redux";
import {
  setCustomTemplateWorkspacePreviewState,
  setTemplateEditorMode
} from "../../redux/slice/products.slice";
import { uploadJSONToS3 } from "../../../../app/feature";
import { UploadType } from "../../../../lib/constants/aws_s3";
import { getAllProducts } from "../../redux/action/products.action";
import { ProductPagePreviewDialog } from "modules/product-mgmt/components/dialog";

function EditTemplateContainer({
  hasViewAccessRight,
  hasEditAccessRight,
  lang,
  isFetching,
  fetchTemplate,
  getConsumerPageSetting,
  handleSave,
  workspaceSetting,
  template,
  customTemplateHtml,
  isPreviewingGenuine,
  isPreviewFetching
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const id = match.params.templateId;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const companyUuid = useSelector(state => state.account.id);
  const productLanguages = useSelector(state => state.productPage.lang);
  const currentLanguage = useSelector(state => state.productPage.pageWorkspaceLanguage);
  const productDropdown = useSelector(state => state.products.allProducts);
  const isFetchingProduct = useSelector(state => selectLoading(state, getAllProducts.typePrefix));
  const dispatch = useDispatch();
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isTemplateLoading, setTemplateLoading] = useState(false);

  const onSetLanguage = (language) => {
    dispatch(setPageWorkspaceLanguage(language));
  }

  // useEffect(() => {
  //   if (!hasViewAccessRight) {
  //     history.push('/admin/dashboard')
  //   }
  // }, [history, hasViewAccessRight])

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      fetchTemplate(id)
        .then(unwrapResult)
        .catch(() => history.push(ROOT))
    } else {
      history.push(ROOT);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getConsumerPageSetting();
    dispatch(setCustomTemplateWorkspacePreviewState(1));
  }, [getConsumerPageSetting, dispatch]);

  if (!hasViewAccessRight) return <LoadingComponent />;

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    dispatch(setTemplateEditorMode(false));

    if (template.isCustom) {
      handleSave({
        id,
        name: values.name,
        product_ids: values.products.map(e => e.id),
        settings_json_url: template.url,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(setTemplateEditorMode(true));
        });
      return;
    }

    await dispatch(uploadJSONToS3({
      uploadType: UploadType.productPageUiTemplate,
      data: workspaceSetting,
      id: companyUuid,
      fileName: `${Date.now()}-${values.name}.json`
    })).then(unwrapResult)
      .then((url) => {
        handleSave({
          id,
          name: values.name,
          product_ids: values.products.map(e => e.id),
          settings_json_url: url,
        })
          .then(() => {
            setIsSubmitting(false);
            dispatch(setTemplateEditorMode(true));
          });
      });
  }

  const onPreviewGenuineChange = (event) => {
    setTemplateLoading(true);
    dispatch(setCustomTemplateWorkspacePreviewState(event.target.value));
    dispatch(fetchCustomTemplateHtml({ template: template.url, isGenuine: event.target.value }))
      .then(() => setTemplateLoading(false));
  }

  const handlePreview = () => {
    setIsPreviewDialogOpen(true)
  }

  return (
    <>
      <EditTemplateComponent
        hasEditAccessRight={hasEditAccessRight}
        currentLanguage={currentLanguage}
        productLanguages={productLanguages}
        isLoading={isFetching}
        lang={lang}
        onSetLanguage={onSetLanguage}
        handleSave={handleSubmit}
        template={template}
        isSubmitting={isSubmitting}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
        customTemplateHtml={customTemplateHtml}
        isPreviewingGenuine={isPreviewingGenuine}
        onPreviewGenuineChange={onPreviewGenuineChange}
        isPreviewFetching={isPreviewFetching}
        handlePreview={handlePreview}
        isTemplateLoading={isTemplateLoading}
      />
      <ProductPagePreviewDialog
        isOpen={isPreviewDialogOpen}
        handleClose={() => setIsPreviewDialogOpen(false)}
        isEditTemplate={true}
      />
    </>
  );
}

const mapStateToProps = state => ({
  hasViewAccessRight: state.profile.accessRights.includes(PRODUCT_TEMPLATE_VIEW),
  hasEditAccessRight: state.profile.accessRights.includes(PRODUCT_TEMPLATE_EDIT),
  lang: state.constant.languages,
  isFetching: selectLoading(state, fetchTemplate.typePrefix) ||
    selectLoading(state, fetchTemplateSettings.typePrefix) ||
    selectLoading(state, getConsumerPageSetting.typePrefix) ||
    selectLoading(state, getAllProducts.typePrefix),
  template: state.products.template,
  workspaceSetting: state.products.template.workspaceSetting.setting,
  customTemplateHtml: state.products.template.workspaceSetting.customHtml,
  isPreviewingGenuine: state.products.template.workspaceSetting.isGenuine,
});

const mapDispatchToProps = dispatch => ({
  fetchTemplate: id => dispatch(fetchTemplate({ id })),
  getConsumerPageSetting: () => dispatch(getConsumerPageSetting()),
  handleSave: (payload) => dispatch(editTemplate(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTemplateContainer);
