import React, { memo, useState, useCallback, useRef } from "react";
import DashboardScanCountMapComponent from "./map.component";
import { useSelector } from "react-redux";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function DashboardScanCountMapContainer({
  mapScans,
  displayAllProduct,
  selectedProductIds,
  revealProduct,
  isMain,
  products,
}) {
  const locale = useSelector((state) => state.profile.language);
  const lang = useSelector((state) => state.constant.languages);

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [center, setCenter] = useState({
    lat: 3.139,
    lng: 101.6869,
  });

  const initialZoomRef = useRef(5);
  const hasInitialZoomRef = useRef(false);
  const initialCenterRef = useRef({
    lat: 3.139,
    lng: 101.6869,
  });
  const markerRef = useRef([]);
  const markerClusterRef = useRef(null);
  const showProductRef = useRef(false);
  const initialLoadRef = useRef(true);

  const { isLoaded } = useLoadScript({
    id: `google-map-script-${locale}`,
    googleMapsApiKey: API_KEY,
    language: locale,
  });

  useEffect(() => {
    if (!initialLoadRef.current && map) {
      showProductRef.current = revealProduct;
      markerRef.current.forEach((marker) => {
        marker.map = null;
      });
      markerRef.current = [];

      if (markerClusterRef.current) {
        markerClusterRef.current.clearMarkers(false);
        markerClusterRef.current = null;
      }

      initMap(map);
    }
    initialLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealProduct, displayAllProduct, selectedProductIds]);

  const updateZoomLevel = () => {
    if (map) {
      let tempZoom = map.getZoom();
      if (!hasInitialZoomRef.current) {
        tempZoom = tempZoom > 15 ? 15 : tempZoom;
        initialZoomRef.current = tempZoom > 15 ? 15 : tempZoom;

        let tempCenter = map.getCenter();
        initialCenterRef.current = {
          lat: tempCenter.lat(),
          lng: tempCenter.lng(),
        };
        hasInitialZoomRef.current = true;
      }
      setCenter(initialCenterRef.current);
      setZoomLevel(tempZoom);
    }
  };

  const onLoad = useCallback(async function callback(map) {
    map.setOptions({ mapId: "898db239baad3210" });
    let newBoundary = new window.google.maps.LatLngBounds();

    let hasScanHistory = false;
    mapScans.forEach((item) => {
      if (item.lat && item.lng) {
        newBoundary.extend({
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lng),
        });
        hasScanHistory = true;
      }
    });

    if (hasScanHistory) {
      map.fitBounds(newBoundary);
    }
    setMap(map);
    initMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(map) {
    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers(false);
      markerClusterRef.current = null;
    }
    setMap(null);
  }, []);

  async function initMap(map) {
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );

    let markers = mapScans.reduce((m, d) => {
      const mark = new AdvancedMarkerElement({
        map,
        content: buildMarker(d),
        position: {
          lat: parseFloat(d.lat),
          lng: parseFloat(d.lng),
        },
      });
      mark.id = `${d.scanId}_${d.status}_${d.report}`;
      markerRef.current.push(mark);
      return [...m, mark];
    }, []);

    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers(false);
    }

    markerClusterRef.current = new MarkerClusterer({
      map,
      markers,
      algorithm: new SuperClusterAlgorithm({
        radius: 160,
        maxZoom: 30,
      }),
      renderer: {
        render: (cluster, stats) => {
          let result = cluster.markers.reduce(
            (a, b) => {
              let infoData = b.id.split("_");
              a.scanId.push(infoData[0]);

              if (infoData[1] === "active") {
                a.active += 1;
              } else {
                a.suspend += 1;
              }

              if (infoData[2] === "1") {
                a.hasReport += 1;
              }
              return a;
            },
            { active: 0, suspend: 0, hasReport: 0, scanId: [] }
          );

          return new AdvancedMarkerElement({
            map,
            content: buildClusterContent(cluster, result),
            position: cluster.position,
          });
        },
      },
    });
  }

  const buildMarker = (scan) => {
    const content = document.createElement("div");
    content.innerHTML = `<div class="${scan.status}Pin"></div>`;
    return content;
  };

  const buildClusterContent = (cluster, result) => {
    const content = document.createElement("div");

    let status = "suspend";

    let productPics = [];
    let productIds = [];
    if (!isMain) {
      let scans = result.scanId
        ? mapScans.filter((d) => result.scanId.includes(d.scanId.toString()))
        : [];
      if (scans.length > 0) {
        for (var i = 0; i < scans.length; i++) {
          let temp = scans[i];
          if (!productIds.includes(temp.productUuid)) {
            let prd = products.find((p) => p.uuid === temp.productUuid);
            if (prd) {
              productPics.push(prd.picture);
              productIds.push(temp.productUuid);
            }
          }
        }
      }
    }

    let total = result.active + result.suspend;
    let suspendRatio = result.suspend / total;

    content.innerHTML = `
      <div class="clusterSection">
        <div
          class="${status}Cluster"
          style="
            background-image: conic-gradient(
              #F97066 ${360 * suspendRatio}deg,
              #35CA91 ${360 * suspendRatio}deg
            )
          "
        >
          <span>${
            cluster.count > 1000
              ? `${Math.floor(cluster.count / 1000)}k`
              : cluster.count
          }</span>
          ${!!result.hasReport ? `<div class="report reportCluster" />` : ""}
        </div>
      </div>
    `;

    return content;
  };

  return (
    <DashboardScanCountMapComponent
      locale={locale}
      isLoaded={isLoaded}
      center={center}
      zoomLevel={zoomLevel}
      updateZoomLevel={updateZoomLevel}
      onLoad={onLoad}
      onUnmount={onUnmount}
      lang={lang}
    />
  );
}

DashboardScanCountMapContainer.defaultProps = {
  mapScans: [],
  isMain: true,
  displayAllProduct: true,
  selectedProductIds: [],
};

export default memo(DashboardScanCountMapContainer);
