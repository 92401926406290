import React, { useState, useEffect } from "react";
import { addAlert } from "modules/notification";
import CustomSerialNumberRangePanelComponent from "./customSerialNumberRangePanel.component";

function CustomSerialNumberRangePanelContainer({
  formik,
  disabled,
  lang,
  availableQuantity,
  checkSNAvailability,
  updateQuantity,
  isSNexceedRange,
}) {
  const [isSameRange, setIsSameRange] = useState(false);
  const [isExceedLimit, setIsExceedLimit] = useState(false);

  useEffect(() => {
    setIsSameRange(false);
  }, [formik.values.format]);

  const onCustomRangeChange = (event, formik, name) => {
    formik.setFieldValue(name, event.target.value);

    const format = formik.values.format.split(",");
    const length = parseInt(format[1]);

    setIsExceedLimit(name && name.toString().length !== length);
    setIsSameRange(false);
  };

  const submit = (formik) => {
    const range = formik.getFieldProps("generateRanges").value;
    let sameRange = false;

    let total = range.reduce((a, b) => {
      if (!isNaN(b.start) && !isNaN(b.end)) {
        const start = Number(b.start);
        const end = Number(b.end);
        a += end - start + 1;
      }
      return a;
    }, 0);
    updateQuantity(total);

    let tempRange = [];
    range.forEach((number, index) => {
      const start = Number(number.start);
      const end = Number(number.end);

      let overlap = range.findIndex((r, i) => {
        if (index !== i) {
          return (
            (r.start <= start && r.end >= end) ||
            (r.start >= start && r.start <= end) ||
            (r.end >= start && r.end <= end)
          );
        }
        return false;
      });

      if (overlap >= 0) {
        sameRange = true;
      }

      const format = formik.values.format.split(",");
      const length = format[1];

      tempRange.push({
        start: start.toString().padStart(length, '0'),
        end: end.toString().padStart(length, '0'),
      });
    });
    formik.setFieldValue("generateRanges", tempRange)

    if (!sameRange) {
      const [prefix, length, postfix] = formik.values.format.split(',');
      const serialNumberGenRanges = formik.values.generateRanges.map((range) => {
        return {
          start: prefix + range.start.toString().padStart(length, '0') + postfix,
          end: prefix + range.end.toString().padStart(length, '0') + postfix,
        };
      });

      checkSNAvailability(formik, true, serialNumberGenRanges);
      //value check for submit assign serial number
      formik.setFieldValue("isSerialNumber", true);
      setIsSameRange(sameRange);
    } else {
      setIsSameRange(sameRange);
    }
  };

  return (
    <CustomSerialNumberRangePanelComponent
      formik={formik}
      addAlert={addAlert}
      onCustomRangeChange={onCustomRangeChange}
      isSameRange={isSameRange}
      lang={lang}
      isExceedLimit={isExceedLimit}
      isSNexceedRange={isSNexceedRange}
      isInputDisable={disabled}
      availableQuantity={availableQuantity}
      submit={submit}
    />
  );
}

export default CustomSerialNumberRangePanelContainer;
