import React, { createContext, useState, useContext } from 'react';

// Create a Context for the Lucky Draw
const LuckyDrawContext = createContext();

// Create a provider component
export const LuckyDrawProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [bindingInfo, setBindingInfo] = useState({
    bindingType: 1,
    products: [],
    serialNumbers: [],
    sequenceNumbers: [],
  });
  const [excludeProdBindList, setExcludeProdBindList] = useState([]);
  return (
    <LuckyDrawContext.Provider value={{
      data,setData, 
      bindingInfo,setBindingInfo,
      excludeProdBindList, setExcludeProdBindList
       }}>
      {children}
    </LuckyDrawContext.Provider>
  );
};

// Custom hook to use the Lucky Draw context
export const useLuckyDraw = () => {
  return useContext(LuckyDrawContext);
};