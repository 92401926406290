import ConfirmationDialog from "components/dialog/confirmation";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParticipantsListPanelComponent from "./participantsListPanel.component";
import { addAlert, selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";
import { getApiLang, getLang } from "app/feature/constants";
import { getAllProducts } from "../../../../product-mgmt/redux/action/products.action";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { useParams } from "react-router-dom";

const ParticipantsListPanelContainer = forwardRef((_, ref) => {
  const { luckyDrawUUID } = useParams();
  const dispatch = useDispatch();
  const productDropdown = useSelector((state) => state.products.allProducts);
  const isFetchingProduct = useSelector((state) =>
    selectLoading(state, getAllProducts.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredTotalRecords, setFilteredTotalRecords] = useState(0);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  // TODO: isLiveDrawSetupDialogOpen needed?
  // const [isLiveDrawSetupDialogOpen setIsLiveDrawSetupDialogOpen] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  // TODO: selectedLiveDrawUuid needed?
  // const [selectedLiveDrawUuid, setSelectedLiveDrawUuid] = useState(null);
  const [selectedLiveDrawUuid] = useState(null);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  });
  const [search, setSearch] = useState();

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    getData();
    // TODO: remove below line and do it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = () => {
    if (isError) setIsError(false);
    setIsFetching(true);
    luckyDrawV2Api
      .getLuckyDrawParticipants({
        uuid: luckyDrawUUID,
        limit: tableFilter.rowsPerPage,
        skip: tableFilter.page * tableFilter.rowsPerPage,
        search: search,
      })
      .then((response) => {
        setData(response.data.data.data);
        setTotalRecords(response.data.data.records_total);
        setFilteredTotalRecords(response.data.data.records_filtered);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const handleFetchLuckyDrawList = () => {
    // handleFetchLuckyDrawList
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    setSearch(values);
  };

  const handlePublished = () => {
    luckyDrawV2Api
      .publishLuckyDraw({ luckyDrawUuid: selectedLiveDrawUuid })
      .then(() => {
        setIsConfirmationDialogOpen(false);
        // setIsLiveDrawSetupDialogOpen(true);
        getData();
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tableFilter]);
  return (
    <>
      <ParticipantsListPanelComponent
        data={data}
        isFetching={isFetching}
        tableFilter={tableFilter}
        totalRecords={filteredTotalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
        lang={lang}
        language={language}
        isError={isError}
        luckyDrawUUID={luckyDrawUUID}
      />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
        handleProceed={handlePublished}
        type={"success"}
        remark={getLang(lang, "paragraph.PUBLISH_LD_REMARK")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.PUBLISH_LD_QUESTION")}
      />
    </>
  );
});

export default ParticipantsListPanelContainer;
