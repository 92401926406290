/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import SetWinnerSelectComponent from "./setWinnerSelect.component";
import SetWinnerPanelContainer from "./setWinnerPanel.component";
import PropTypes from "prop-types";
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";
import luckyDrawV2Api from "app/api/luckyDrawV2";

function SetWinnerSelectContainer({
  luckyDrawUuid,
  currentWinnerList,
  placeholder,
  handleChange,
  value,
  excludeList,
  disabled,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const [anchorEl, setAnchorEl] = useState(null);
  const [serialNumberData, setSerialNumberData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
  });

  const isFetchingRef = useRef(false);
  const dataRef = useRef([]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (value, enc) => {
    handleChange(value, enc);
    handleClose();
  };

  useEffect(() => {
    getSerialNumberList();
  }, [filter]);

  const handleSearch = (value) => {
    setSerialNumberData([]);
    setTotalRecords(0);
    dataRef.current = [];
    setFilter({
      ...filter,
      page: 0,
      search: value,
    });
  };

  const getSerialNumberList = async () => {
    if (!isFetchingRef.current) {
      if (isError) setIsError(false);
      setIsFetching(true);
      isFetchingRef.current = true;

      await luckyDrawV2Api
        .getSerialNumberList({
          uuid: luckyDrawUuid,
          length: filter.rowsPerPage,
          start: filter.page * filter.rowsPerPage,
          search: filter.search,
        })
        .then((response) => {
          const data = response.data.data;
          setTotalRecords(data.records_total);

          if (filter.page === 0) {
            dataRef.current = data.serial_numbers;
          } else {
            dataRef.current = [...dataRef.current, ...data.serial_numbers];
          }
          setSerialNumberData(dataRef.current);
        })
        .catch((error) => {
          setIsError(true);
        })
        .finally(() => {
          setIsFetching(false);
          isFetchingRef.current = false;
        });
    }
  };

  const handleFetchMoreItems = () => {
    if (serialNumberData.length < totalRecords) {
      setFilter({
        ...filter,
        page: filter.page + 1,
      });
    }
  };

  const handleReload = () => {
    setSerialNumberData([]);
    setTotalRecords(0);
    dataRef.current = [];
    setFilter({
      page: 0,
      rowsPerPage: 25,
      search: "",
    });
  };

  return (
    <>
      <SetWinnerSelectComponent
        disabled={disabled}
        handleClick={handleOpen}
        value={value}
        placeholder={getLang(lang, "placeholder.SELECT_SERIAL_NUMBER")}
      />
      <SetWinnerPanelContainer
        anchorEl={anchorEl}
        isOpen={!!anchorEl}
        handleClose={handleClose}
        handleListKeyDown={handleListKeyDown}
        isFetching={isFetching}
        value={value}
        serialNumberData={serialNumberData}
        excludeList={excludeList}
        handleFetchMoreItems={handleFetchMoreItems}
        handleSearch={handleSearch}
        lang={lang}
        totalRecords={totalRecords}
        isError={isError}
        handleReload={handleReload}
      />
    </>
  );
}

SetWinnerSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default SetWinnerSelectContainer;
