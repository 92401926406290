import React from "react";
import { makeStyles, ButtonBase, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "8px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    overflow: "hidden",
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
}));

export default function LuckyDrawSelectComponent({
  anchorRef,
  handleClick,
  value,
  styles,
  disabled,
  textStyles,
  lang,
  placeholder,
  luckyDraws,
}) {
  const classes = useStyle();

  const getDisplay = () => {
    let display = placeholder;
    if (value && luckyDraws.length) {
      let ld = luckyDraws.find((s) => s.uuid === value);
      if (ld) {
        display = ld.title;
      }
    }
    return display;
  };

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      style={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      <Typography
        variant="body2"
        className={clsx(
          classes.inputText,
          getDisplay() !== placeholder && classes.filled
        )}
        style={{
          ...textStyles,
        }}
      >
        {getDisplay()}
      </Typography>
      <ArrowDropDownIcon fontSize="small" />
    </ButtonBase>
  );
}
