import React from "react";
import { GeneralSearch } from "components/search";
import TagTable from "../../components/table/tagTable";

export default function TagComponent({
  handleOpenModal,
  isFetching,
  tagSerialNumberData,
  page,
  search,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isLoading,
  length,
  handleSearch,
  handleReload,
  paginationOptions,
  lang,
  hasAdtInfoViewAccessRight,
  handleViewDetail,
}) {
  return (
    <>
      <GeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        isFetching={isFetching}
      />

      <TagTable
        isFetching={isFetching}
        tagSerialNumberData={tagSerialNumberData}
        rowsPerPage={rowsPerPage}
        page={page}
        search={search}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoading={isLoading}
        length={length}
        handleAddSerialNumberPattern={handleOpenModal}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
        hasAdtInfoViewAccessRight={hasAdtInfoViewAccessRight}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}
