import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Button,
} from "@material-ui/core";
import {
  CloseRounded as CloseRoundedIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
  KeyboardBackspace as BackIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import DefaultImg from "assets/img/img-default.png";
import moment from "moment";
import UnassignProductSNListTable from "../../table/unassignProductSNListTable";
import {
  UNASSIGN_BATCH_STATUS_FAIL,
  UNASSIGN_BATCH_STATUS_SUCCESS,
  UNASSIGN_BATCH_STATUS_SUCCESS_WARNING,
} from "modules/serial-number/constants";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import SearchableSelect from "components/select/searchableSelect";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#EBEDEE",
    overflowY: "auto",
    padding: "16px 16px 34px 16px",
  },
  productRow: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  productList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 2fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function UnassignDetailDialogComponent({
  isOpen,
  handleClose,
  lang,
  unassign,
  list,
  isFetching,
  isError,
  handleReload,
  viewProduct,
  selectedProduct,
  handleChangePage,
  handleChangeRowsPerPage,
  changeOrder,
  handlePrdReload,
  paginationOptions,
  totalFiltered,
  tableFilter,
  handleSearch,
  isDetailFetching,
  isDetailError,
}) {
  const classes = useStyle();

  let isSuccess = unassign?.status
    ? [
        UNASSIGN_BATCH_STATUS_SUCCESS,
        UNASSIGN_BATCH_STATUS_SUCCESS_WARNING,
      ].includes(unassign.status)
    : false;

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.UNASSIGN_DETAIL")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        {isDetailFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : isDetailError ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            pt={2}
            pb={3}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold", paddingBottom: "8px" }}
            >
              {getLang(lang, "label.NO_DATA_RECEIVED")}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "label.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.REFRESH")}
            </Button>
          </Box>
        ) : (
          !!unassign && (
            <>
              {!!selectedProduct && (
                <Box
                  display="flex"
                  alignItems="center"
                  mb={2}
                  fontWeight="bold"
                >
                  <Box mr={1.2}>
                    <BackIcon
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => viewProduct(null)}
                    />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="primary">
                      <b>{getLang(lang, "label.PRODUCT_DETAILS")}</b>
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box className={classes.productRow}>
                {!!selectedProduct ? (
                  <>
                    <Box display="flex" alignItems="center" p={1}>
                      <Box className={classes.productPhoto} mr={2}>
                        {!selectedProduct?.picture ? (
                          <img src={DefaultImg} alt="emptyProduct" />
                        ) : (
                          <img
                            src={selectedProduct.picture}
                            alt={selectedProduct.name}
                          />
                        )}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Typography variant="body1">
                          <b>{selectedProduct.name}</b>
                        </Typography>
                        {!!selectedProduct.attributes &&
                          !!selectedProduct.attributes.length && (
                            <Typography
                              className={classes.attributeText}
                              variant="body2"
                            >
                              {selectedProduct.attributes.join(", ")}
                            </Typography>
                          )}
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box p={1} flex={1}>
                        <Typography variant="body1">
                          <b>
                            {isSuccess
                              ? selectedProduct.success_quantity
                              : selectedProduct.quantity}
                          </b>
                        </Typography>
                        <Typography variant="body2">
                          {isSuccess
                            ? getLang(lang, "label.SUCCESS")
                            : getLang(lang, "label.SERIAL_NUMBER")}
                        </Typography>
                      </Box>
                      {isSuccess && (
                        <Box
                          p={1}
                          flex={1}
                          style={{ borderLeft: "1px solid #dddddd" }}
                        >
                          <Typography variant="body1">
                            <b>{selectedProduct.failed_quantity}</b>
                          </Typography>
                          <Typography variant="body2">
                            {getLang(lang, "label.DENIED")}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box p={2}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Box width="25%">
                          <Typography variant="body1">
                            {getLang(lang, "label.BRANCH")}
                          </Typography>
                        </Box>
                        <Box width="75%%">
                          <Typography variant="body1">
                            <b>{unassign.node.name}</b>
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Box width="25%">
                          <Typography variant="body1">
                            {getLang(lang, "label.CREATED_DATE")}
                          </Typography>
                        </Box>
                        <Box width="75%%">
                          <Typography variant="body1">
                            <b>{moment(unassign.created_at).format("ll")}</b>
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box width="25%">
                          <Typography variant="body1">
                            {getLang(lang, "label.STATUS")}
                          </Typography>
                        </Box>
                        <Box width="75%%">
                          <Typography
                            variant="body1"
                            display="inline"
                            style={{
                              color:
                                unassign.status === UNASSIGN_BATCH_STATUS_FAIL
                                  ? "#FF0000"
                                  : unassign.status ===
                                      UNASSIGN_BATCH_STATUS_SUCCESS ||
                                    unassign.status ===
                                      UNASSIGN_BATCH_STATUS_SUCCESS_WARNING
                                  ? "#32D583"
                                  : "#989898",
                            }}
                          >
                            {unassign.status === UNASSIGN_BATCH_STATUS_FAIL
                              ? getLang(lang, "label.FAILED")
                              : unassign.status ===
                                  UNASSIGN_BATCH_STATUS_SUCCESS ||
                                unassign.status ===
                                  UNASSIGN_BATCH_STATUS_SUCCESS_WARNING
                              ? getLang(lang, "label.SUCCESS")
                              : getLang(lang, "label.PROCESSING")}
                          </Typography>
                          {unassign.status ===
                            UNASSIGN_BATCH_STATUS_SUCCESS_WARNING && (
                            <Typography
                              variant="body2"
                              display="inline"
                              style={{
                                color: "#FDB022",
                                textTransform: "capitalize",
                                marginLeft: 4,
                              }}
                            >
                              ({getLang(lang, "label.WARNING")})
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
              <Box p={2} mt={2} style={{ background: "white" }}>
                {!!selectedProduct ? (
                  <>
                    <Box mb={2}>
                      <Formik
                        initialValues={{
                          search: tableFilter.search,
                          status: tableFilter.status,
                        }}
                        onSubmit={handleSearch}
                        enableReinitialize
                      >
                        {(formik) => (
                          <form onSubmit={formik.handleSubmit}>
                            <Box className={classes.searchContainer}>
                              <Input
                                hiddenLabel
                                variant="filled"
                                size="small"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.SEARCH"
                                )}
                                margin="none"
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#eceff0",
                                  },
                                  classes: { input: classes.inputRoot },
                                }}
                                {...formik.getFieldProps("search")}
                              />
                              <SearchableSelect
                                value={formik.values.status}
                                isEmpty={formik.values.status === ""}
                                handleChange={(value) => {
                                  formik.setFieldValue("status", value);
                                }}
                                placeholder={getLang(
                                  lang,
                                  "placeholder.STATUS"
                                )}
                                disabled={isFetching}
                                searchable={false}
                                style={{ height: "34px" }}
                                panelStyle={{ width: "249px" }}
                                PaperProps={{
                                  classes: { root: classes.paperRoot },
                                }}
                                dropdownItem={[
                                  getLang(lang, "label.SUCCESS"),
                                  getLang(lang, "label.DENIED"),
                                ]}
                                emptyMessage={getLang(
                                  lang,
                                  "paragraph.STATUS_NOT_FOUND"
                                )}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              />
                              <Button
                                variant="text"
                                disableElevation
                                color="primary"
                                size="small"
                                onClick={() => {
                                  formik.resetForm();
                                  handleSearch();
                                }}
                              >
                                {getLang(lang, "label.CLEAR")}
                              </Button>
                              <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                size="small"
                                type="submit"
                              >
                                {getLang(lang, "label.SEARCH")}
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>

                    <UnassignProductSNListTable
                      rows={list}
                      isFetching={isFetching}
                      isError={isError}
                      handleReload={handlePrdReload}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      totalFiltered={totalFiltered}
                      paginationOptions={paginationOptions}
                      tableFilter={tableFilter}
                      changeOrder={changeOrder}
                      lang={lang}
                      unassign={unassign}
                    />
                  </>
                ) : (
                  <Box className={classes.productList}>
                    {unassign.products.map((item, i) => (
                      <Box key={i}>
                        <Box className={classes.productRow}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={1}
                          >
                            <Box display="flex" alignItems="center">
                              <Box className={classes.productPhoto} mr={2}>
                                {!item?.picture ? (
                                  <img src={DefaultImg} alt="emptyProduct" />
                                ) : (
                                  <img src={item.picture} alt={item.name} />
                                )}
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="flex-start"
                              >
                                <Typography variant="body1">
                                  <b>{item.name}</b>
                                </Typography>
                                {!!item.attributes &&
                                  !!item.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {item.attributes.join(", ")}
                                    </Typography>
                                  )}
                              </Box>
                            </Box>
                            <ChevronRightRoundedIcon
                              color="primary"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                viewProduct(item);
                              }}
                            />
                          </Box>
                          <Box display="flex">
                            <Box p={1} flex={1}>
                              <Typography variant="body1">
                                <b>
                                  {isSuccess
                                    ? item.success_quantity
                                    : item.quantity}
                                </b>
                              </Typography>
                              <Typography variant="body2">
                                {isSuccess
                                  ? getLang(lang, "label.SUCCESS")
                                  : getLang(lang, "label.SERIAL_NUMBER")}
                              </Typography>
                            </Box>
                            {isSuccess && (
                              <Box
                                p={1}
                                flex={1}
                                style={{ borderLeft: "1px solid #dddddd" }}
                              >
                                <Typography variant="body1">
                                  <b>{item.failed_quantity}</b>
                                </Typography>
                                <Typography variant="body2">
                                  {getLang(lang, "label.DENIED")}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </>
          )
        )}
      </Box>
    </Dialog>
  );
}
