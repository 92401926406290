export const ROOT = "/admin/product";
export const NEW_PRODUCT_PAGE = `${ROOT}/new`;
export const FALLBACK = `${ROOT}/invalid`;
export const CATEGORY = `${ROOT}/category`;
export const BRAND_AND_MODEL = `${ROOT}/brand-and-model`;
export const ATTRIBUTES = `${ROOT}/attributes`;
export const GENERATE_PRODUCT = `${ROOT}/generate-product`;
export const BULK_EDIT = `${ROOT}/bulk-edit`
export const PRODUCT_PAGE = `${ROOT}/:productId`;
export const TEMPLATE_PAGE = `${ROOT}/template/:templateId`;
