import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider,
  Button,
} from "@material-ui/core";
import CustomTab from "components/tab";
import WarrantySettingForm from "../form/warrantySettingForm";
import WarrantyRegisterForm from "../form/regSettingForm";
import WarrantyExtensionPanel from "../panel/warrantyExtensionPanel";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  publishButton: {
    color: theme.palette.common.white,
  },
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function TabComponent({
  earlyAccess,
  hasWarrantyEditAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  formRef,
  settingType,
  registerType,
  extensionType,
  changeToUpdate,
  changeBackView,
  handleUpdate,
  viewWarrantyOnly,
  isFormEditing,
  lang,
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box>
      <Box px={3} pt={1}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => (
            <CustomTab
              key={index}
              style={{ maxWidth: 286 }}
              {...a11yProps(index, item)}
              disabled={
                (settingType !== "view" ||
                  registerType !== "view" ||
                  extensionType !== "view") &&
                activeIndex !== index
              }
            />
          ))}
        </Tabs>
      </Box>
      <Divider />

      <Box>
        <TabPanel value={activeIndex} index={0}>
          <Box className={classes.panel}>
            <WarrantySettingForm
              earlyAccess={earlyAccess}
              type={settingType}
              changeBackView={changeBackView}
            >
              {() =>
                hasWarrantyEditAccessRight && viewWarrantyOnly ? (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      px={2}
                      pt={1}
                      pb={1}
                    >
                      {settingType === "edit" ? (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            changeBackView(e);
                          }}
                          type="button"
                          style={{ marginRight: "4px" }}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {settingType === "edit" ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type={"submit"}
                        >
                          {getLang(lang, "label.SAVE")}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type={"button"}
                          onClick={(e) => {
                            e.preventDefault();
                            changeToUpdate("edit");
                          }}
                        >
                          {getLang(lang, "label.EDIT")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                ) : null
              }
            </WarrantySettingForm>
          </Box>
        </TabPanel>
        <TabPanel value={activeIndex} index={1}>
          <WarrantyRegisterForm type={registerType} formRef={formRef}>
            {() =>
              hasWarrantyEditAccessRight && viewWarrantyOnly ? (
                <Box mt="auto">
                  <Divider />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    px={2}
                    pt={1}
                    pb={1}
                  >
                    {registerType === "edit" ? (
                      <Button
                        onClick={(e) => {
                          changeBackView(e);
                        }}
                        type="button"
                        style={{ marginRight: "4px" }}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      classes={{ root: classes.publishButton }}
                      disableElevation
                      type={"button"}
                      onClick={() => {
                        registerType === "edit"
                          ? handleUpdate()
                          : changeToUpdate("edit");
                      }}
                    >
                      {registerType === "edit"
                        ? getLang(lang, "label.SAVE")
                        : getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                </Box>
              ) : null
            }
          </WarrantyRegisterForm>
        </TabPanel>
        <TabPanel value={activeIndex} index={2}>
          <WarrantyExtensionPanel
            type={extensionType}
            changeBackView={changeBackView}
            changeToUpdate={changeToUpdate}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
