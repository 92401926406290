import React, { useRef } from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import ProductSelect from "../../select/productSelect";
import ProgressSelect from "../../select/progressSelect";
import DatepickerDialog from "components/dialog/datepicker/datepickerDialog.container";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns:
      "minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr) minmax(0, 1fr) minmax(0, 1fr)",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      justifySelf: "flex-end"
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: "500"
  }
}));

export default function WithProductDateProgressSearchComponent({
  handleSearch,
  lang,
  productDropdown,
  isFetching,
  productsSelected,
  setProductsSelected,
  progressSelected,
  setProgressSelected,
  startPeriod,
  setStartPeriod,
  endPeriod,
  setEndPeriod,
  handleClear,
  clearDate
}) {
  const classes = useStyle();
  const textRef = useRef();

  const statusOptions = [
    { id: 0, name: getLang(lang, "label.DRAFT") },
    { id: 1, name: getLang(lang, "label.READY_TO_PUBLISH") },
    { id: 2, name: getLang(lang, "label.AWAITING_START") },
    { id: 3, name: getLang(lang, "label.JOIN_PERIOD_OPEN") },
    { id: 4, name: getLang(lang, "label.JOIN_PERIOD_CLOSED") },
    { id: 5, name: getLang(lang, "label.LIVE_DRAW_IN_PROGRESS") },
    { id: 6, name: getLang(lang, "label.LIVE_DRAW_COMPLETED") },
    { id: 7, name: getLang(lang, "label.EVENT_CONCLUDED") },
  ];

  return (
    <Box p={2} mb={1} className={classes.searchContainer}>
      <InputTextField
        variant="filled"
        inputRef={textRef}
        size="small"
        placeholder={getLang(lang, "placeholder.SEARCH")}
        margin="none"
        InputProps={{
          disableUnderline: true,
          margin: "none",
          style: {
            borderRadius: "4px",
            backgroundColor: "#ECEFF0",
          },
          classes: { input: classes.inputRoot },
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter')
            handleSearch(textRef.current.value)
        }}
      />
      <ProductSelect
        searchable={true}
        value={productsSelected}
        placeholder={getLang(lang, 'placeholder.SELECT_A_PRODUCT')}
        searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
        isClearable={true}
        emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
        dropdownItem={productDropdown}
        isFetching={isFetching}
        handleChange={(value) => {
          let temp = [...productsSelected];
          if (temp.length === 0) {
            temp.push(value);
          }
          else {
            const isProductExist = temp.find((product) => product.id === value.id);

            if (isProductExist === undefined) {
              temp.push(value);
            }
            else {
              let filter = temp.filter(product => product.id !== value.id);
              temp = [...filter];
            }
          }

          setProductsSelected(temp);
        }}
        handleDeleteChip={(value) => {
          let temp = [...productsSelected];

          const isProductExist = temp.find(product => product.id === value.id);
          if (isProductExist) {
            let filter = temp.filter(product => product.id !== value.id);
            temp = [...filter];

            setProductsSelected(temp);
          }
        }}
        handleClear={() => {
          setProductsSelected([]);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        isEmpty={productDropdown.length === 0}
        disabled={false}
      />
      <DatepickerDialog
        handleChange={(startPeriod) => {
          setStartPeriod(startPeriod.format("YYYY-MM-DD HH:mm"));
        }}
        value={startPeriod}
        label={getLang(lang, 'placeholder.SELECT_START_PERIOD')}
        enableTime={true}
        displayFormat="DD MMMM YYYY HH:mm"
        clearDate={clearDate}
      />
      <DatepickerDialog
        handleChange={(endPeriod) => {
          setEndPeriod(endPeriod.format("YYYY-MM-DD HH:mm"));
        }}
        value={endPeriod}
        label={getLang(lang, 'placeholder.SELECT_END_PERIOD')}
        enableTime={true}
        displayFormat="DD MMMM YYYY HH:mm"
        clearDate={clearDate}
      />
      <ProgressSelect
        searchable={true}
        value={progressSelected}
        placeholder={getLang(lang, 'placeholder.SELECT_PROGRESS')}
        searchPlaceholder={getLang(lang, 'placeholder.SEARCH_PROGRESS_NAME')}
        isClearable={true}
        emptyMessage={getLang(lang, 'message.info.NO_PROGRESS_FOUND')}
        dropdownItem={statusOptions}
        isFetching={isFetching}
        handleChange={(value) => {
          let temp = [...progressSelected];
          if (temp.length === 0) {
            temp.push(value);
          }
          else {
            const isProgressExist = temp.find((progress) => progress.id === value.id);

            if (isProgressExist === undefined) {
              temp.push(value);
            }
            else {
              let filter = temp.filter(progress => progress.id !== value.id);
              temp = [...filter];
            }
          }

          setProgressSelected(temp);
        }}
        handleDeleteChip={(value) => {
          let temp = [...progressSelected];

          const isProgressExist = temp.find(progress => progress.id === value.id);
          if (isProgressExist) {
            let filter = temp.filter(progress => progress.id !== value.id);
            temp = [...filter];

            setProgressSelected(temp);
          }
        }}
        handleClear={() => {
          setProgressSelected([]);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        isEmpty={statusOptions.length === 0}
        disabled={false}
      />
      <Button
        color="primary"
        variant="text"
        size="small"
        disableElevation
        onClick={() => {
          textRef.current.value = "";
          handleClear();
        }}
      >
        {getLang(lang, "label.CLEAR")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        size="small"
        onClick={() => handleSearch(textRef.current.value)}
      >
        {getLang(lang, "label.SEARCH")}
      </Button>
    </Box>
  );
}
