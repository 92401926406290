import React from "react";
import { makeStyles, ButtonBase, Typography, Box } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.25em 0.75em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    border: "1px solid #D0D5DD",
    height: "3.2em",
    overflow: "hidden",
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  selectedField: {
    display: "flex",
    alignItems: "center",
  },
  disabledField: {
    backgroundColor: "#eceff0",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0,
  },
}));

export default function ProductSelectFieldComponent({
  handleClick,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  lang,
}) {
  const classes = useStyles();

  const displayValue = () => {
    return (
      <Typography
        variant="body2"
        className={clsx(classes.inputText, {
          [classes.filled]: !isEmpty,
        })}
        style={textStyle}
      >
        {isEmpty ? placeholder : value?.name}
      </Typography>
    );
  };

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      {displayValue()}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {open ? (
          <ArrowDropUpIcon fontSize="small" />
        ) : (
          <ArrowDropDownIcon fontSize="small" />
        )}
      </Box>
    </ButtonBase>
  );
}
