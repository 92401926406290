/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ParticipantDetailDialogComponent from "./participantDetailDialog.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";

function ParticipantDialogContainer({
  participantUuid,
  isOpen,
  handleClose,
  luckyDrawUUID,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const [isLoading, setIsLoading] = useState(false);
  const [participantDetail, setParticipantDetails] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setParticipantDetails(null);
    } else {
      if (participantUuid) {
        getData(participantUuid);
      }
    }
  }, [isOpen, participantUuid]);

  const getData = (uuid) => {
    setIsLoading(true);
    luckyDrawV2Api
      .getLuckyDrawParticipantDetails({ uuid, luckyDrawUuid: luckyDrawUUID })
      .then((response) => setParticipantDetails(response.data.data))
      .catch((error) => {
        dispatch(addAlert({ message: error.message, type: "error" }));
      })
      .finally(() => setIsLoading(false));
  };

  const handleView = async (value) => {
    window.open(value, "_blank");
  };

  return (
    <ParticipantDetailDialogComponent
      isOpen={isOpen}
      isLoading={isLoading}
      handleClose={handleClose}
      participantDetail={participantDetail}
      handleView={handleView}
      lang={lang}
      language={language}
    />
  );
}

ParticipantDialogContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ParticipantDialogContainer;
