import React, { useRef } from "react";
import {
  makeStyles,
  Box,
  Button,
  MenuItem,
  TextField
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import EmployeeTable from "../../table/employeeTable";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 3fr 2fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  },
  selectTextfieldInput: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderRadius: "5px",
  },
  selectTextfieldInputDivider: {
    height: "70%",
    width: "2px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  }
}));

export default function EmployeePanelComponent({
  handleSearch,
  isFetching,
  data,
  page,
  rowsPerPage,
  totalRecords,
  handleSearchTypeChange,
  handleChangePage,
  handleChangeRowsPerPage,
  search,
  roleDropdown,
  roleSelect,
  setRoleSelect,
  clearSearch,
  branchDropdown,
  branchSelect,
  setBranchSelect,
  handleCreate,
  handleReload,
  paginationOptions,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <>
      <Box p={2} mb={2} className={classes.searchContainer}>
        <Box className={classes.selectTextfieldInput}>
          <TextField
            select
            value={search.type}
            variant="filled"
            size="small"
            margin="none"
            style={{ minWidth: "85px" }}
            InputProps={{
              disableUnderline: true,
              margin: "none",
              style: {
                backgroundColor: "#ECEFF0",
              },
              classes: { input: classes.inputRoot }
            }}
          >
            <MenuItem value="name" onClick={() => handleSearchTypeChange("name")}>
              {getLang(lang, "label.NAME")}
            </MenuItem>
            <MenuItem value="email" onClick={() => handleSearchTypeChange("email")}>
              {getLang(lang, "label.EMAIL")}
            </MenuItem>
          </TextField>
          <Box className={classes.selectTextfieldInputDivider} />
          <InputTextField
            variant="filled"
            size="small"
            margin="none"
            placeholder={getLang(lang, "placeholder.SEARCH")}
            inputRef={textRef}
            InputProps={{
              disableUnderline: true,
              margin: "none",
              style: { backgroundColor: "#ECEFF0" }
            }}
            inputProps={{
              style: {
                padding: "7px",
                paddingTop: "8px",
                fontWeight: 500
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isFetching)
              handleSearch(textRef.current.value)
            }}
          />
        </Box>
        <TextField
          id="filled-select-currency"
          select
          value={branchSelect}
          variant="filled"
          size="small"
          margin="none"
          InputProps={{
            disableUnderline: true,
            margin: "none",
            style: {
              borderRadius: "4px",
              backgroundColor: "#ECEFF0"
            },
            classes: { input: classes.inputRoot }
          }}
        >
          <MenuItem value="All" onClick={() => setBranchSelect("All")}>
            {getLang(lang, "label.ALL_BRANCH")}
          </MenuItem>
          {branchDropdown.map((value, index) => (
            <MenuItem
              value={value.nodeId}
              key={index}
              onClick={() => setBranchSelect(value.nodeId)}
            >
              {value.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="filled-select-currency"
          select
          value={roleSelect}
          variant="filled"
          size="small"
          margin="none"
          InputProps={{
            disableUnderline: true,
            margin: "none",
            style: {
              borderRadius: "4px",
              backgroundColor: "#ECEFF0"
            },
            classes: { input: classes.inputRoot }
          }}
        >
          <MenuItem value="All" onClick={() => setRoleSelect("All")}>
            {getLang(lang, "label.ALL_ROLE")}
          </MenuItem>
          {roleDropdown.map((value, index) => (
            <MenuItem
              value={value.userGroupId}
              key={index}
              onClick={() => setRoleSelect(value.userGroupId)}
            >
              {value.name}
            </MenuItem>
          ))}
        </TextField>

        <Button
          variant="text"
          disableElevation
          color="secondary"
          size="small"
          disabled={isFetching}
          onClick={() => {
            textRef.current.value = "";
            clearSearch();
          }}
        >
          {getLang(lang, "label.CLEAR")}
        </Button>
        
        <Button
          variant="contained"
          disableElevation
          color="primary"
          size="small"
          disabled={isFetching}
          onClick={() => handleSearch(textRef.current.value)}
        >
          {getLang(lang, "label.SEARCH")}
        </Button>
      </Box>
      <EmployeeTable
        data={data}
        isFetching={isFetching}
        page={page}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        search={search}
        handleCreate={handleCreate}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </>
  );
}
