import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import CustomTemplateInteractiveEditorComponent from "./customTemplateInteractiveEditor.component";

function CustomTemplateInteractiveEditorContainer({
  previewMode,
  customTemplateHtml
}) {
  const uiSetting = useSelector((state) => state.setting.uiSetting);

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
  };


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CustomTemplateInteractiveEditorComponent
        previewMode={previewMode}
        uiSetting={uiSetting}
        customTemplateHtml={customTemplateHtml}
      />
    </DragDropContext>
  );
}

export default CustomTemplateInteractiveEditorContainer;
