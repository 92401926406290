import React, { useEffect, useState } from "react";
import AssignSerialNumberDialogComponent from "./assignSerialNumberDialog.component";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { assignSerialNumber } from "modules/serial-number";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  checkSerialNumberAvailability,
  getUnassignedSerialNumberByBranch,
  resetSNAvailabilityList,
  resetUnassignedSerialNumberList,
} from "modules/serial-number/redux";
import { FEATURE_SERIAL_NUMBER_ASSIGN } from "lib/constants/featureAccessRight";
import { checkFeatureAvailability } from "modules/account/redux";

function AssignSerialNumberDialogContainer({
  isOpen,
  handleClose,
  product,
  branchList,
  assignSerialNumber,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const snAvailabilityList = useSelector(
    (state) => state.serialNumber.snAvailabilityList.data
  );

  const isSNexceedRange = useSelector(
    (state) => state.serialNumber.snAvailabilityList.isSNexceedRange
  );

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetSNAvailabilityList());
      dispatch(resetUnassignedSerialNumberList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOpen)
      dispatch(checkFeatureAvailability(FEATURE_SERIAL_NUMBER_ASSIGN));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = async (values) => {
    const isSerialNumber = values.isSerialNumber;
    setIsLoading(true);
    let ranges = values.generateRanges;
    if (isSerialNumber) { // if is serial number range, then add prefix and postfix to the range for backend
      const [prefix, length, postfix] = values.format.split(',');
      ranges = ranges.map((range) => {
        return {
          start: prefix + range.start.toString().padStart(length, '0') + postfix,
          end: prefix + range.end.toString().padStart(length, '0') + postfix,
        };
      });
    }
    assignSerialNumber({
      product_id: product.id,
      branch_uuid: selectedBranch.uuid,
      format: values.format,
      type: values.isCustomQuantity ? 2 : 1,
      quantity: values.quantity ? Number(values.quantity) : 0,
      ranges: ranges,
      is_serial_number: isSerialNumber,
    })
      .then(unwrapResult)
      .then((batch) => {
        setSuccess(true);
        setIsLoading(false);
      })
      .finally(() => {
        setConfirmationDialogOpen(false);
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    handleClose();
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const fetchAvailableSN = (formik, value) => {
    setSelectedBranch(value);
    formik.setFieldValue("format", "");
    formik.setFieldValue("availQty", 0);
  };

  const handleRetrySNList = () => {
    dispatch(getUnassignedSerialNumberByBranch(selectedBranch.uuid));
  };

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setSelectedBranch(null);
        dispatch(resetUnassignedSerialNumberList());
      }
    };
  }, [dispatch, isOpen]);

  const checkSNAvailability = (formik, isSerialNumber = false, serialNumberGenRanges = null) => {
    dispatch(
      checkSerialNumberAvailability({
        branch_uuid: formik.values.branch.uuid,
        format: formik.values.format,
        type: formik.values.isCustomQuantity ? 2 : 1,
        quantity: Number(formik.values.quantity || 0),
        ranges: isSerialNumber ? serialNumberGenRanges : formik.values.generateRanges,
        is_serial_number: isSerialNumber,
      })
    );
    var tableView = document.getElementById("preview-table");
    if (tableView) {
      tableView.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    formik.setFieldValue("preview", true);
  };

  const resetAvailabilityList = () => {
    dispatch(resetSNAvailabilityList());
  };

  return (
    <AssignSerialNumberDialogComponent
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      product={product}
      handleSubmit={handleSubmit}
      success={success}
      isLoading={isLoading}
      lang={lang}
      handleRetry={handleRetrySNList}
      branchList={branchList}
      selectedBranch={selectedBranch}
      fetchAvailableSN={fetchAvailableSN}
      checkSNAvailability={checkSNAvailability}
      reloadSnAvailability={checkSNAvailability}
      confirmationDialogOpen={confirmationDialogOpen}
      setConfirmationDialogOpen={setConfirmationDialogOpen}
      snAvailabilityList={snAvailabilityList}
      resetAvailabilityList={resetAvailabilityList}
      isSNexceedRange={isSNexceedRange}
    />
  );
}

AssignSerialNumberDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  assignSerialNumber: (payload) => dispatch(assignSerialNumber(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(AssignSerialNumberDialogContainer);
