import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import ActionButton from "components/button/actionDetailButton";
import { TablePaginationActions } from "components/tablePagination";
import {
  ASSIGN_BATCH_STATUS_FAIL,
  ASSIGN_BATCH_STATUS_SUCCESS,
} from "modules/serial-number/constants";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import { KeyboardArrowDown } from "@material-ui/icons";
import AssignStatusProgress from "../../progress/assignStatusProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function AssignTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  language,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  setSelectedBatch,
  retryAssign,
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {rows.length > 0 || isFetching ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="assign table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.NUM")}
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BATCH_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.QUANTITY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order =
                          tableFilter.order === "asc" ? "desc" : "asc";
                        changeOrder(order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE_CREATED")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell rowSpan={2}>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={7} />
                    ))
                  : rows.map((row, index) => {
                      let actionItems = [
                        {
                          label: (
                            <Typography variant="caption">
                              {getLang(lang, "label.EXPORT_AS")}
                            </Typography>
                          ),
                          action: () => {
                            setSelectedBatch(row);
                          },
                          disabled:
                            row.status !== ASSIGN_BATCH_STATUS_FAIL &&
                            row.status !== ASSIGN_BATCH_STATUS_SUCCESS,
                        },
                      ];

                      if (row.status === ASSIGN_BATCH_STATUS_FAIL) {
                        actionItems.push({
                          label: (
                            <Box display="flex" justifyContent="center">
                              <Typography
                                variant="caption"
                                style={{ marginRight: 3 }}
                              >
                                {getLang(lang, "label.RETRY_FAIL_ASSIGN")}
                              </Typography>
                              {!!row.is_generate ? (
                                <Tooltip
                                  title={getLang(
                                    lang,
                                    "message.info.RETRY_IN_SN_BATCH"
                                  )}
                                  arrow
                                >
                                  <ErrorOutlineIcon
                                    style={{
                                      fontSize: 18,
                                      pointerEvents: "auto",
                                    }}
                                  />
                                </Tooltip>
                              ) : row.retry_count >= 3 ? (
                                <Tooltip
                                  title={getLang(
                                    lang,
                                    "message.info.ASSIGN_RETRY_LIMIT_REACH"
                                  )}
                                  arrow
                                >
                                  <ErrorOutlineIcon
                                    style={{
                                      fontSize: 18,
                                      pointerEvents: "auto",
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                            </Box>
                          ),
                          action: () => {
                            retryAssign(row);
                          },
                          disabled: row.retry_count >= 3 || !!row.is_generate,
                        });
                      }

                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StickyLeftTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ borderBottom: "0px" }}
                            >
                              {index + 1 + rowsPerPage * page}
                            </StyledTableCell>
                          </StickyLeftTableCell>
                          <StyledTableCell>{row.batchNumber}</StyledTableCell>
                          <StyledTableCell>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                              >
                                {row.product?.name ?? "-"}
                              </Typography>
                              {!!row.product?.attributes &&
                                !!row.product.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {row.product.attributes.join(", ")}
                                  </Typography>
                                )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>{row.quantity.toLocaleString("en-US")}</StyledTableCell>
                          <StyledTableCell>
                            <AssignStatusProgress
                              assignUuid={row.uuid}
                              initialProgress={row.progress}
                              initialStatus={row.status}
                            />
                          </StyledTableCell>
                          <StyledTableCell>{moment(row.date).format('ll')}</StyledTableCell>
                          <StickyRightTableCell>
                            <StyledTableCell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ActionButton
                                  actionItems={actionItems}
                                  popperStyle={{ zIndex: 999 }}
                                />
                              </div>
                            </StyledTableCell>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              classes: classes.toolbar,
            }}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
            }
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="assign table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.NUM")}
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BATCH_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.QUANTITY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.DATE_CREATED")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "paragraph.NO_UNASSIGN_HISTORY")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
