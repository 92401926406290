import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT, LUCKY_DRAW_PARTICIPANT } from "./config/lucky-draw.route";
import { MainPage, LuckyDrawParticipantPage } from "./pages";

export default function LuckyDrawModule() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route
        exact
        path={LUCKY_DRAW_PARTICIPANT}
        component={LuckyDrawParticipantPage}
      />
      {/* <Route exact path={`${LD_LIVE_DRAW}/:luckyDrawID`} component={LuckyDrawParticipantPage} /> */}
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT };
