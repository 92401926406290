import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import Input from "components/input/inputTextField";
import FilterSvg from "assets/svg/filter.svg";
import { Formik } from "formik";
import clsx from "clsx";
import DateRangePicker from "components/input/dateRangePicker";
import { getLang } from "app/feature/constants";
import SearchableSelect from "components/select/searchableSelect";
import TransferListTable from "modules/stockflow/components/table/transferListTable";
import TransferSenderSelect from "modules/stockflow/components/select/transferSenderSelect";
import TransferReceiverSelect from "modules/stockflow/components/select/transferReceiverSelect";
import { RECEIVE_TRANSFER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 2fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function ReceiveTransferListingComponent({
  transfers,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  language,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  isExporting,
  isFilterOpen,
  setIsFilterOpen,
  tableFilter,
  changeOrder,
  lang,
  resetFilter,
  handleViewDetail,
}) {
  const classes = useStyle();

  const statusDropdown = [
    { name: "-", value: "" },
    { name: getLang(lang, "label.PENDING"), value: "pending" },
    { name: getLang(lang, "label.RECEIVED"), value: "received" },
    { name: getLang(lang, "label.REJECTED"), value: "rejected" },
    { name: getLang(lang, "label.CANCELLED"), value: "cancelled" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          dateRange: {
            from: tableFilter.fromDate,
            to: tableFilter.toDate,
          },
          status: tableFilter.status
            ? statusDropdown.find((v) => v.value === tableFilter.status)
            : null,
          transferFrom: tableFilter.transferFrom,
          transferTo: tableFilter.transferTo,
          respondDate: tableFilter.respondDate,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_TRANSFER_ID")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootID },
                }}
                {...formik.getFieldProps("search")}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.TRANSFER_DATE")}
                disabled={isFetching}
                disableFuture={false}
                className={clsx({
                  [classes.emptyField]:
                    !formik.getFieldProps("dateRange").value,
                })}
                id={"created-picker-dialog"}
                value={formik.getFieldProps("dateRange").value}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.getFieldProps("dateRange").value
                    ? "#A4A6A8"
                    : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.TRANSFER_DATE")}
              />
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <SearchableSelect
                    value={formik.values.status}
                    handleChange={(value) => {
                      formik.setFieldValue(
                        "status",
                        !value.value ? null : value
                      );
                    }}
                    placeholder={getLang(lang, "placeholder.STATUS")}
                    disabled={formik.isSubmitting || isFetching}
                    searchable={false}
                    panelStyle={{ width: "249px" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot },
                    }}
                    dropdownItem={statusDropdown}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    isEmpty={!formik.values.status}
                    returnType="object"
                  />
                  <TransferSenderSelect
                    value={formik.values.transferFrom}
                    handleChange={(value) =>
                      formik.setFieldValue("transferFrom", value)
                    }
                    placeholder={getLang(lang, "placeholder.TRANSFER_FROM")}
                    disabled={isFetching}
                  />
                  <TransferReceiverSelect
                    value={formik.values.transferTo}
                    handleChange={(value) =>
                      formik.setFieldValue("transferTo", value)
                    }
                    placeholder={getLang(lang, "placeholder.TRANSFER_TO")}
                    disabled={isFetching}
                  />
                  <DateRangePicker
                    label=""
                    disabled={formik.isSubmitting || isFetching}
                    placeholder={getLang(lang, "placeholder.RESPOND_DATE")}
                    disableFuture={false}
                    id="date-picker"
                    className={clsx({
                      [classes.emptyField]:
                        !formik.getFieldProps("respondDate").value,
                    })}
                    value={formik.values.respondDate}
                    handleChange={(value) => {
                      formik.setFieldValue("respondDate", value);
                    }}
                    InputPropStyle={{
                      color: !formik.getFieldProps("respondDate").value
                        ? "#A4A6A8"
                        : "#000000",
                      fontWeight: "normal",
                    }}
                    inputProps={{
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    disabled={isFetching}
                    onClick={resetFilter}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <TransferListTable
        rows={transfers}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
        url={RECEIVE_TRANSFER_LIST_PAGE}
        type="receive"
      />
    </>
  );
}
