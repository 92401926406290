export const progressStatus = {
  new: "new",
  setBasic: "set_basic",
  setParticipant: "set_participant",
  setPeriod: "set_period",
  setPrize: "set_prize",
  setProduct: "set_product",
  setWinner: "set_winner",
  confirmWinner: "confirm_winner",
  published: "published",
};
