import {
  fetchBranchDropdown,
  fetchMeAndBranchDropdown,
  publishNewProduct,
  createNewProduct,
  getProductDropdown,
  updateProductInfo,
  updateProductPhoto,
  getProductPage,
  updateProductPage,
  updateProductUi,
  uploadProductPageJson,
  getProduct,
  deleteProduct,
  updateProductStatus,
  quickUpdateProduct,
  updateBulkProduct,
  getProductDropdownByBranch,
  createBulkProduct,
  fetchMeAndBranchDropdownSpecial,
  getAllProducts
} from "../action/products.action";
import {
  addProductCategories,
  getAllProductCategoryListing,
  editProductCategory,
  deleteProductCategory,
  updateCategoryStatus,
  updateCategoriesStatus
} from "../action/productCategory.action";
import {
  getAllBrandModelsListing,
  addBrandModel,
  editBrandModel,
  deleteBrandModels,
  updateBrandModelStatus,
  updateBrandModelsStatus,
  addModelsToExistingBrand,
} from "../action/brandModels.action"
import {
  getAllAttributeListing,
  deleteAttribute,
  getAttributeDropdown,
  addAttribute,
  editAttribute,
  updateAttributeStatus,
  updateAttributesStatus
} from "../action/attribute.action"
import {
  setProductPicture,
  loadProductPage,
  setProductPageError,
  setHasSaved,
  setProductUpdatedTime,
  setProductUuid
} from "../slice/productPage.slice";
import {
  setBranchDropdown
} from "../slice/products.slice";
import { setLoading, addAlert } from "modules/notification";
import { v4 as uuidv4 } from "uuid";
import { uploadImageToS3 } from "app/feature";
import {UploadType} from "lib/constants/aws_s3";
import { isDataUrl } from "lib/generalUtility";
import moment from "moment";
import { getApiLang, getLang } from "app/feature/constants";
import {
  createTemplate, editProductTemplate,
  editTemplate, fetchCustomTemplateHtml, fetchOverrideWarningList, fetchPreviewTemplateSettings,
  fetchTemplate, fetchTemplateDropdown,
  fetchTemplates,
  fetchTemplateSettings
} from "../action/template.action";

const fetchBranchDropdownMiddleware = ({ dispatch }) => next => action => {
  next(action);
  const { type } = action;

  switch (type) {
    case fetchBranchDropdown.pending.type:
      dispatch(setLoading({ id: fetchBranchDropdown.typePrefix, state: true }));
      break;

    case fetchBranchDropdown.fulfilled.type:
      dispatch(
        setLoading({ id: fetchBranchDropdown.typePrefix, state: false })
      );
      break;

    case fetchBranchDropdown.rejected.type:
      dispatch(
        setLoading({ id: fetchBranchDropdown.typePrefix, state: false })
      );
      break;

    default:
      break;
  }
};

const fetchMeAndBranchDropdownMiddleware = ({ dispatch }) => next => action => {
  next(action);
  const { type } = action;

  switch (type) {
    case fetchMeAndBranchDropdown.pending.type:
      dispatch(setLoading({ id: fetchMeAndBranchDropdown.typePrefix, state: true }));
      break;

    case fetchMeAndBranchDropdown.fulfilled.type:
      dispatch(
        setLoading({ id: fetchMeAndBranchDropdown.typePrefix, state: false })
      );
      break;

    case fetchMeAndBranchDropdown.rejected.type:
      dispatch(
        setLoading({ id: fetchMeAndBranchDropdown.typePrefix, state: false })
      );
      break;

    default:
      break;
  }
};

const fetchMeAndBranchDropdownSpecialMiddleware = ({ dispatch }) => next => action => {
  next(action);
  const { type } = action;

  switch (type) {
    case fetchMeAndBranchDropdownSpecial.pending.type:
      dispatch(setLoading({ id: fetchMeAndBranchDropdownSpecial.typePrefix, state: true }));
      break;

    case fetchMeAndBranchDropdownSpecial.fulfilled.type:
      dispatch(
        setLoading({ id: fetchMeAndBranchDropdownSpecial.typePrefix, state: false })
      );
      break;

    case fetchMeAndBranchDropdownSpecial.rejected.type:
      dispatch(
        setLoading({ id: fetchMeAndBranchDropdownSpecial.typePrefix, state: false })
      );
      break;

    default:
      break;
  }
};

const publishNewProductMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  const { type } = action;

  if (type === publishNewProduct.type) {
    dispatch(setLoading({ id: type, state: true }));
    dispatch(setHasSaved(false));
    dispatch(uploadProductPageJson({ isNew: true }));
  }
};

const updateProductPageMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  const { type } = action;

  if (type === updateProductPage.type) {
    dispatch(setLoading({ id: type, state: true }));
    dispatch(setHasSaved(false));
    dispatch(uploadProductPageJson({ isNew: false }));
  }
};

const uploadProductPageJsonMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  const { type, payload } = action;
  if (type.includes(uploadProductPageJson.typePrefix)) {
    const isNew = action.meta.arg.isNew;
    const loadingId = isNew ? publishNewProduct.type : updateProductPage.type;

    switch (type) {
      case uploadProductPageJson.rejected.type:
        const lang = getState().constant.languages
        dispatch(setLoading({ id: loadingId, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, "error.ERROR_UPLOAD_PRODUCT")
          })
        );
        break;

      case uploadProductPageJson.fulfilled.type:
        if (isNew) dispatch(createNewProduct(payload));
        else dispatch(updateProductUi(payload));
        break;

      default:
        break;
    }
  }
};

const createNewProductMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  const { type, payload } = action;
  if (type.includes(createNewProduct.typePrefix)) {
    const loadingId = publishNewProduct.type;

    switch (type) {
      case createNewProduct.rejected.type:
        const lang = getState().constant.languages
        dispatch(setLoading({ id: loadingId, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );
        break;

      case createNewProduct.fulfilled.type:
        dispatch(setProductUuid(payload.data.uuid));
        dispatch(setLoading({ id: loadingId, state: false }));
        break;

      default:
        break;
    }
  }
};

const updateProductUiMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  if (type.includes(updateProductUi.typePrefix)) {
    const loadingId = updateProductPage.type;

    switch (type) {
      case updateProductUi.rejected.type:
        dispatch(setHasSaved(false));
        dispatch(setLoading({ id: loadingId, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );
        break;

      case updateProductUi.fulfilled.type:
        dispatch(setHasSaved(true));
        dispatch(setLoading({ id: loadingId, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_PRODUCT_UI")
          })
        );
        break;

      default:
        break;
    }
  }
};

const getProductDropdownMiddleware = ({ dispatch, getState }) => next => async action => {
  next(action);

  const { type, payload } = action

  switch (type) {
    case getProductDropdown.pending.type:
      dispatch(setLoading({ id: getProductDropdown.typePrefix, state: true }));
      break;
    case getProductDropdown.fulfilled.type:
      dispatch(setLoading({ id: getProductDropdown.typePrefix, state: false }));
      break;
    case getProductDropdown.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: getProductDropdown.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;
    default:
      break;
  }
};

const getProductDropdownByBranchMiddleware = ({ dispatch, getState }) => next => async action => {
  next(action);

  const { type, payload } = action

  switch (type) {
    case getProductDropdownByBranch.pending.type:
      dispatch(setLoading({ id: getProductDropdownByBranch.typePrefix, state: true }));
      break;
    case getProductDropdownByBranch.fulfilled.type:
      dispatch(setLoading({ id: getProductDropdownByBranch.typePrefix, state: false }));
      break;
    case getProductDropdownByBranch.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: getProductDropdownByBranch.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;
    default:
      break;
  }
};

const updateProductInfoMiddleware = ({ dispatch, getState }) => next => async action => {

  const { type, payload } = action;
  const lang = getState().constant.languages

  if (type.includes(updateProductInfo.typePrefix)) {
    switch (type) {
      case updateProductInfo.pending.type:
        next(action);
        dispatch(setLoading({ id: updateProductInfo.typePrefix, state: true }));
        break;

      case updateProductInfo.fulfilled.type:
        next(action);
        dispatch(
          setLoading({ id: updateProductInfo.typePrefix, state: false })
        );
        dispatch(setLoading({ id: updateProductPhoto.type, state: false }));
        dispatch(setProductUpdatedTime(new moment().format("DD MMM YYYY, HH:mm:ss")));

        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_PRODUCT_INFO")
          })
        );
        break;

      case updateProductInfo.rejected.type:
        next(action);
        dispatch(
          setLoading({ id: updateProductInfo.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );
        break;

      default:
        break;
    }
  } else {
    next(action);
  }
};

const updateProductPhotoMiddleware = ({
  dispatch,
  getState
}) => next => async action => {
  const { type } = action;
  const uploadType = UploadType.productImage;
  const lang = getState().constant.languages

  if (type === updateProductPhoto.type) {
    const companyId = getState().account.id;
    const loadingId = updateProductPhoto.type;
    const base64Image = action.payload;

    const fileName = `${uuidv4()}.webp`;
    if (base64Image && isDataUrl(base64Image)) {
      next({ ...action, payload: { fileName, base64Image, companyId } });

      dispatch(setLoading({ id: loadingId, state: true }));

      dispatch(
        uploadImageToS3({
          fileName,
          base64Image,
          id: companyId,
          uploadType: uploadType
        })
      );
    } else {
      next(action);
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, "error.ERROR_UPDATE_PRODUCT_PHOTO")
        })
      );
    }
  } else if (
    type.includes(uploadImageToS3.typePrefix) &&
    action.meta.arg.uploadType === uploadType
  ) {
    next(action);

    switch (type) {
      case uploadImageToS3.rejected.type:
        dispatch(setLoading({ id: updateProductPhoto.type, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, "error.ERROR_UPDATE_PRODUCT_PHOTO")
          })
        );
        break;

      case uploadImageToS3.fulfilled.type:
        const url = `${action.payload.split("?")[0]}`;

        next(setProductPicture(url));
        dispatch(updateProductInfo());

        break;

      default:
        break;
    }
  } else {
    next(action);
  }
};

const getProductPageMiddleware = ({
  dispatch,
  getState
}) => next => async action => {
  next(action);
  const { type, payload } = action;

  switch (type) {
    case getProductPage.pending.type:
      dispatch(setLoading({ id: getProductPage.typePrefix, state: true }));
      break;

    case getProductPage.fulfilled.type:
      const {
        backgroundColor,
        columns,
        components,
        lang,
        createdAt,
        updatedAt
      } = action.payload;

      dispatch(setLoading({ id: getProductPage.typePrefix, state: false }));
      dispatch(
        loadProductPage({
          ...getState().productPage,
          backgroundColor: backgroundColor,
          columns: columns,
          components: components,
          lang: lang,
          pageCreatedAt: createdAt,
          pageUpdatedAt: updatedAt
        })
      );
      break;

    case getProductPage.rejected.type:
      const languages = getState().constant.languages;
      dispatch(setProductPageError(true));
      dispatch(setLoading({ id: getProductPage.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(languages, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const getProductMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  if (type.includes(getProduct.typePrefix)) {
    const loadingId = getProduct.typePrefix;

    switch (type) {
      case getProduct.pending.type:
        dispatch(setLoading({ id: loadingId, state: true }));
        break;

      case getProduct.rejected.type:
        const lang = getState().constant.languages
        dispatch(setLoading({ id: loadingId, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );
        break;

      case getProduct.fulfilled.type:
        dispatch(
          loadProductPage({
            productId: payload.uuid,
            productName: payload.name,
            template: payload.template,
            templateUrl: payload.template_url,
            desc: payload.description,
            nodes: payload.nodes,
            picture: payload.picture,
            scanLimit: payload.scanlimit,
            createdAt: payload.created_at,
            updatedAt: payload.updated_at,
            attributes: payload.attributeoptions,
            brand: payload.brand === null ? { id: "-1", name: "" } : payload.brand,
            model: payload.model === null ? { id: "-1", name: "" } : payload.model,
            currencyCode: payload.currency_code,
            currencyExp: payload.currency_exp,
            price: payload.unit_price,
            sku: payload.sku,
            category: payload.category,
            incentivePoint: payload.incentive_point,
            warrantyInfo: payload.warranty_setting_info,
            customFields: payload.custom_fields
          })
        );

        dispatch(
          setBranchDropdown(
            payload.nodes.map(node => ({
              node_id: node.id,
              name: node.name,
              parent_node_id: node.parent_id
            }))
          )
        );
        dispatch(setLoading({ id: loadingId, state: false }));
        break;

      default:
        break;
    }
  }
};

const deleteProductMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case deleteProduct.pending.type:
      next(setLoading({ id: deleteProduct.typePrefix, state: true }));
      break;
    case deleteProduct.fulfilled.type:
      next(addAlert({
        severity: "success",
        message: getApiLang(lang, payload.code)
      }))
      next(setLoading({ id: deleteProduct.typePrefix, state: false }));
      break;
    case deleteProduct.rejected.type:
      if (payload.code === "ERROR_PRODUCT_FOUND_IN_CAMPAIGNS") {
        let msg = payload.data;
        msg.forEach((value) => {
          value = getLang(lang, "label." + value);
        });
        next(addAlert({
          severity: "error",
          message: `${getApiLang(lang, payload.code)}: ${msg.join(", ")}`
        }))
      }
      else {
        next(addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        }))
      }
      next(setLoading({ id: deleteProduct.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

const updateProductStatusMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case updateProductStatus.pending.type:
      next(setLoading({ id: updateProductStatus.typePrefix, state: true }));
      break;
    case updateProductStatus.fulfilled.type:
      next(addAlert({
        severity: "success",
        message: getApiLang(lang, "success.SUCCESS_UPDATE_PRODUCT_STATUS")
      }))
      next(setLoading({ id: updateProductStatus.typePrefix, state: false }));
      break;
    case updateProductStatus.rejected.type:
      next(addAlert({
        severity: "error",
        message: getApiLang(lang, payload.code)
      }))
      next(setLoading({ id: updateProductStatus.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

const addProductCategoriesMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case addProductCategories.pending.type:
      dispatch(setLoading({ id: addProductCategories.typePrefix, state: true }));
      break;
    case addProductCategories.fulfilled.type:
      dispatch(setLoading({ id: addProductCategories.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_ADD_PRODUCT_CATEGORY") }));
      break;
    case addProductCategories.rejected.type:
      dispatch(setLoading({ id: addProductCategories.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const getAllProductCategoryListingMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case getAllProductCategoryListing.pending.type:
      dispatch(setLoading({ id: getAllProductCategoryListing.typePrefix, state: true }));
      break;
    case getAllProductCategoryListing.fulfilled.type:
      dispatch(setLoading({ id: getAllProductCategoryListing.typePrefix, state: false }));
      break;
    case getAllProductCategoryListing.rejected.type:
      dispatch(setLoading({ id: getAllProductCategoryListing.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(
            lang,
            payload.code === "error.ERROR_ACCESS_DENIED"
              ? payload.code
              : "error.ERROR_GET_PRODUCT_CATEGORY_LIST"
          ),
        })
      );
      break;
    default:
      break;
  }
}

const editProductCategoryMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case editProductCategory.pending.type:
      dispatch(setLoading({ id: editProductCategory.typePrefix, state: true }));
      break;
    case editProductCategory.fulfilled.type:
      dispatch(setLoading({ id: editProductCategory.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_CATEGORY') }));
      break;
    case editProductCategory.rejected.type:
      dispatch(setLoading({ id: editProductCategory.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const updateCategoryStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case updateCategoryStatus.pending.type:
      dispatch(setLoading({ id: updateCategoryStatus.typePrefix, state: true }));
      break;
    case updateCategoryStatus.fulfilled.type:
      dispatch(setLoading({ id: updateCategoryStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_CATEGORY_STATUS') }));
      break;
    case updateCategoryStatus.rejected.type:
      dispatch(setLoading({ id: updateCategoryStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const updateCategoriesStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages
  switch (type) {
    case updateCategoriesStatus.pending.type:
      dispatch(setLoading({ id: updateCategoriesStatus.typePrefix, state: true }));
      break;
    case updateCategoriesStatus.fulfilled.type:
      dispatch(setLoading({ id: updateCategoriesStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_CATEGORIES_STATUS') }));
      break;
    case updateCategoriesStatus.rejected.type:
      dispatch(setLoading({ id: updateCategoriesStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_PRODUCT_CATEGORIES_STATUS') }));
      break;
    default:
      break;
  }
}

const deleteProductCategoryMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case deleteProductCategory.pending.type:
      dispatch(setLoading({ id: deleteProductCategory.typePrefix, state: true }));
      break;
    case deleteProductCategory.fulfilled.type:
      dispatch(setLoading({ id: deleteProductCategory.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_DELETE_PRODUCT_CATEGORY') }));
      break;
    case deleteProductCategory.rejected.type:
      dispatch(setLoading({ id: deleteProductCategory.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const getAllBrandModelsListingMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages
  switch (type) {
    case getAllBrandModelsListing.pending.type:
      dispatch(setLoading({ id: getAllBrandModelsListing.typePrefix, state: true }));
      break;
    case getAllBrandModelsListing.fulfilled.type:
      dispatch(setLoading({ id: getAllBrandModelsListing.typePrefix, state: false }));
      break;
    case getAllBrandModelsListing.rejected.type:
      dispatch(setLoading({ id: getAllBrandModelsListing.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_BRAND_MODELS_LIST') }));
      break;
    default:
      break;

  }

}

const getAllAttributeListingMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages
  switch (type) {
    case getAllAttributeListing.pending.type:
      dispatch(setLoading({ id: getAllAttributeListing.typePrefix, state: true }));
      break;
    case getAllAttributeListing.fulfilled.type:
      dispatch(setLoading({ id: getAllAttributeListing.typePrefix, state: false }));
      break;
    case getAllAttributeListing.rejected.type:
      dispatch(setLoading({ id: getAllAttributeListing.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_PRODUCT_ATTRIBUTE_LIST') }));
      break;
    default:
      break;
  }
}

const deleteAttributeMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case deleteAttribute.pending.type:
      dispatch(setLoading({ id: deleteAttribute.typePrefix, state: true }));
      break;
    case deleteAttribute.fulfilled.type:
      dispatch(setLoading({ id: deleteAttribute.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_DELETE_PRODUCT_ATTRIBUTE') }));
      break;
    case deleteAttribute.rejected.type:
      dispatch(setLoading({ id: deleteAttribute.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const getAttributeDropdownMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case getAttributeDropdown.pending.type:
      dispatch(setLoading({ id: getAttributeDropdown.typePrefix, state: true }))
      break;
    case getAttributeDropdown.fulfilled.type:
      dispatch(setLoading({ id: getAttributeDropdown.typePrefix, state: false }))
      break;
    case getAttributeDropdown.rejected.type:
      dispatch(setLoading({ id: getAttributeDropdown.typePrefix, state: false }))
      break;
    default:
      break;
  }
}

const quickUpdateProductMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case quickUpdateProduct.pending.type:
      dispatch(setLoading({ id: quickUpdateProduct.typePrefix, state: true }))
      break;
    case quickUpdateProduct.fulfilled.type:
      dispatch(setLoading({ id: quickUpdateProduct.typePrefix, state: false }))
      break;
    case quickUpdateProduct.rejected.type:
      dispatch(setLoading({ id: quickUpdateProduct.typePrefix, state: false }))
      break;
    default:
      break;
  }
}

const updateAttributeStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case updateAttributeStatus.pending.type:
      dispatch(setLoading({ id: updateAttributeStatus.typePrefix, state: true }));
      break;
    case updateAttributeStatus.fulfilled.type:
      dispatch(setLoading({ id: updateAttributeStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_ATTRIBUTE_STATUS') }));
      break;
    case updateAttributeStatus.rejected.type:
      dispatch(setLoading({ id: updateAttributeStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const updateAttributesStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages
  switch (type) {
    case updateAttributesStatus.pending.type:
      dispatch(setLoading({ id: updateAttributesStatus.typePrefix, state: true }));
      break;
    case updateAttributesStatus.fulfilled.type:
      dispatch(setLoading({ id: updateAttributesStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_ATTRIBUTES_STATUS') }));
      break;
    case updateAttributesStatus.rejected.type:
      dispatch(setLoading({ id: updateAttributesStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_PRODUCT_ATTRIBUTES_STATUS') }));
      break;
    default:
      break;
  }
}

const addBrandModelMiddleware = ({ dispatch, getState }) => next => action => {
  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case addBrandModel.pending.type:
      dispatch(setLoading({ id: addBrandModel.typePrefix, state: true }));
      break;
    case addBrandModel.fulfilled.type:
      dispatch(setLoading({ id: addBrandModel.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_ADD_PRODUCT_BRAND_MODELS') }));
      break;
    case addBrandModel.rejected.type:
      dispatch(setLoading({ id: addBrandModel.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }

  next(action);
}

const addModelsToExistingBrandMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case addModelsToExistingBrand.pending.type:
      dispatch(setLoading({ id: addModelsToExistingBrand.typePrefix, state: true }));
      break;
    case addModelsToExistingBrand.fulfilled.type:
      dispatch(setLoading({ id: addModelsToExistingBrand.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_ADD_MODELS_INTO_EXISTING_BRAND') }));
      break;
    case addModelsToExistingBrand.rejected.type:
      dispatch(setLoading({ id: addModelsToExistingBrand.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const editBrandModelMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case editBrandModel.pending.type:
      dispatch(setLoading({ id: editBrandModel.typePrefix, state: true }));
      break;
    case editBrandModel.fulfilled.type:
      dispatch(setLoading({ id: editBrandModel.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_BRAND') }));
      break;
    case editBrandModel.rejected.type:
      dispatch(setLoading({ id: editBrandModel.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const updateBrandModelStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages
  switch (type) {
    case updateBrandModelStatus.pending.type:
      dispatch(setLoading({ id: updateBrandModelStatus.typePrefix, state: true }));
      break;
    case updateBrandModelStatus.fulfilled.type:
      dispatch(setLoading({ id: updateBrandModelStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_BRAND_STATUS') }));
      break;
    case updateBrandModelStatus.rejected.type:
      dispatch(setLoading({ id: updateBrandModelStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_PRODUCT_BRAND_STATUS') }));
      break;
    default:
      break;
  }
}

const updateBrandModelsStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages
  switch (type) {
    case updateBrandModelsStatus.pending.type:
      dispatch(setLoading({ id: updateBrandModelsStatus.typePrefix, state: true }));
      break;
    case updateBrandModelsStatus.fulfilled.type:
      dispatch(setLoading({ id: updateBrandModelsStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_PRODUCT_BRANDS_STATUS') }));
      break;
    case updateBrandModelsStatus.rejected.type:
      dispatch(setLoading({ id: updateBrandModelsStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_PRODUCT_BRANDS_STATUS') }));
      break;
    default:
      break;
  }
}

const deleteBrandModelsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case deleteBrandModels.pending.type:
      dispatch(setLoading({ id: deleteBrandModels.typePrefix, state: true }));
      break;
    case deleteBrandModels.fulfilled.type:
      dispatch(setLoading({ id: deleteBrandModels.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_DELETE_PRODUCT_BRAND') }));
      break;
    case deleteBrandModels.rejected.type:
      dispatch(setLoading({ id: deleteBrandModels.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const addAttributeMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case addAttribute.pending.type:
      dispatch(setLoading({ id: addAttribute.typePrefix, state: true }));
      break;
    case addAttribute.fulfilled.type:
      dispatch(setLoading({ id: addAttribute.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_ADD_PRODUCT_ATTRIBUTE') }));
      break;
    case addAttribute.rejected.type:
      dispatch(setLoading({ id: addAttribute.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const editAttributeMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case editAttribute.pending.type:
      dispatch(setLoading({ id: editAttribute.typePrefix, state: true }));
      break;
    case editAttribute.fulfilled.type:
      dispatch(setLoading({ id: editAttribute.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_ADD_PRODUCTS_ATTRIBUTE') }));
      break;
    case editAttribute.rejected.type:
      dispatch(setLoading({ id: editAttribute.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const updateBulkProductMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case updateBulkProduct.pending.type:
      next(setLoading({ id: updateBulkProduct.typePrefix, state: true }));
      break;
    case updateBulkProduct.fulfilled.type:
      next(addAlert({
        severity: "success",
        message: getApiLang(lang, "success.SUCCESS_UPDATE_SELECTED_PRODUCT")
      }))
      next(setLoading({ id: updateBulkProduct.typePrefix, state: false }));
      break;
    case updateBulkProduct.rejected.type:
      next(addAlert({
        severity: "error",
        message: getApiLang(lang, payload.code)
      }))
      next(setLoading({ id: updateBulkProduct.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

const createBulkProductMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case createBulkProduct.pending.type:
      dispatch(setLoading({ id: createBulkProduct.typePrefix, state: true }));
      break;
    case createBulkProduct.fulfilled.type:
      dispatch(setLoading({ id: createBulkProduct.typePrefix, state: false }));
      break;
    case createBulkProduct.rejected.type:
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, payload.code)
      }))
      dispatch(setLoading({ id: createBulkProduct.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

const getAllProductsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case getAllProducts.pending.type:
      dispatch(setLoading({ id: getAllProducts.typePrefix, state: true }));
      break;
    case getAllProducts.fulfilled.type:
      dispatch(setLoading({ id: getAllProducts.typePrefix, state: false }));
      break;
    case getAllProducts.rejected.type:
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, payload.code)
      }))
      dispatch(setLoading({ id: getAllProducts.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

const fetchTemplatesMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case fetchTemplates.pending.type:
      dispatch(setLoading({ id: fetchTemplates.typePrefix, state: true }))
      break;
    case fetchTemplates.fulfilled.type:
      dispatch(setLoading({ id: fetchTemplates.typePrefix, state: false }))
      break;
    case fetchTemplates.rejected.type:
      dispatch(setLoading({ id: fetchTemplates.typePrefix, state: false }))
      break;
    default:
      break;
  }
}

const createTemplateMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { payload, type } = action;
  const lang = getState().constant.languages

  switch (type) {
    case createTemplate.pending.type:
      dispatch(setLoading({ id: createTemplate.typePrefix, state: true }))
      break;
    case createTemplate.fulfilled.type:
      dispatch(setLoading({ id: createTemplate.typePrefix, state: false }))
      dispatch(addAlert({
        severity: "success",
        message: getLang(lang, 'message.success.SUCCESS_CREATE_TEMPLATE')
      }))
      break;
    case createTemplate.rejected.type:
      dispatch(setLoading({ id: createTemplate.typePrefix, state: false }))
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, "error." + payload.code)
      }))
      break;
    default:
      break;
  }
}

const fetchTemplateMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { payload, type } = action;
  const lang = getState().constant.languages
  const isGenuine = getState().products.template.workspaceSetting.isGenuine;

  switch (type) {
    case fetchTemplate.pending.type:
      dispatch(setLoading({id: fetchTemplate.typePrefix, state: true}))
      break;
    case fetchTemplate.fulfilled.type:
      if (payload.is_custom_ui) {
        dispatch(fetchCustomTemplateHtml({template: payload.settings_json_url, isGenuine}));
      }
      else {
        dispatch(fetchTemplateSettings());
      }

      dispatch(setLoading({id: fetchTemplate.typePrefix, state: false}));
      break;
    case fetchTemplate.rejected.type:
      dispatch(setLoading({id: fetchTemplate.typePrefix, state: false}))
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, "error." + payload.code)
      }))
      break;
    default:
      break;
  }
}

const fetchTemplateSettingsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { payload, type } = action;
  const lang = getState().constant.languages

  switch (type) {
    case fetchTemplateSettings.pending.type:
      dispatch(setLoading({id: fetchTemplateSettings.typePrefix, state: true}))
      break;
    case fetchTemplateSettings.fulfilled.type:
      dispatch(setLoading({id: fetchTemplateSettings.typePrefix, state: false}));
      break;
    case fetchTemplateSettings.rejected.type:
      dispatch(setLoading({id: fetchTemplateSettings.typePrefix, state: false}))
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, "error." + payload.code)
      }))
      break;
    default:
      break;
  }
}

const fetchPreviewTemplateSettingsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { payload, type } = action;
  const lang = getState().constant.languages

  switch (type) {
    case fetchPreviewTemplateSettings.pending.type:
      dispatch(setLoading({id: fetchPreviewTemplateSettings.typePrefix, state: true}))
      break;
    case fetchPreviewTemplateSettings.fulfilled.type:
      dispatch(setLoading({id: fetchPreviewTemplateSettings.typePrefix, state: false}));
      break;
    case fetchPreviewTemplateSettings.rejected.type:
      dispatch(setLoading({id: fetchPreviewTemplateSettings.typePrefix, state: false}))
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, "error." + payload?.code)
      }))
      break;
    default:
      break;
  }
}

const editTemplateMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { payload, type } = action;
  const lang = getState().constant.languages

  switch (type) {
    case editTemplate.pending.type:
      dispatch(setLoading({ id: editTemplate.typePrefix, state: true }))
      break;
    case editTemplate.fulfilled.type:
      dispatch(setLoading({ id: editTemplate.typePrefix, state: false }))
      dispatch(addAlert({
        severity: "success",
        message: getLang(lang, 'message.success.SUCCESS_UPDATE_TEMPLATE')
      }))
      break;
    case editTemplate.rejected.type:
      dispatch(setLoading({ id: editTemplate.typePrefix, state: false }))
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, "error." + payload.code)
      }))
      break;
    default:
      break;
  }
}

const fetchTemplateDropdownMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case fetchTemplateDropdown.pending.type:
      dispatch(setLoading({ id: fetchTemplateDropdown.typePrefix, state: true }))
      break;
    case fetchTemplateDropdown.fulfilled.type:
      dispatch(setLoading({ id: fetchTemplateDropdown.typePrefix, state: false }))
      break;
    case fetchTemplateDropdown.rejected.type:
      dispatch(setLoading({ id: fetchTemplateDropdown.typePrefix, state: false }))
      break;
    default:
      break;
  }
}

const fetchOverrideWarningListMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case fetchOverrideWarningList.pending.type:
      dispatch(setLoading({ id: fetchOverrideWarningList.typePrefix, state: true }))
      break;
    case fetchOverrideWarningList.fulfilled.type:
      dispatch(setLoading({ id: fetchOverrideWarningList.typePrefix, state: false }))
      break;
    case fetchOverrideWarningList.rejected.type:
      dispatch(setLoading({ id: fetchOverrideWarningList.typePrefix, state: false }))
      break;
    default:
      break;
  }
}

const fetchCustomTemplateHtmlMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case fetchCustomTemplateHtml.pending.type:
      dispatch(setLoading({ id: fetchCustomTemplateHtml.typePrefix, state: true }))
      break;
    case fetchCustomTemplateHtml.fulfilled.type:
      dispatch(setLoading({ id: fetchCustomTemplateHtml.typePrefix, state: false }))
      break;
    case fetchCustomTemplateHtml.rejected.type:
      dispatch(setLoading({ id: fetchCustomTemplateHtml.typePrefix, state: false }))
      break;
    default:
      break;
  }
}

const editProductTemplateMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { payload, type } = action;
  const lang = getState().constant.languages

  switch (type) {
    case editProductTemplate.pending.type:
      dispatch(setLoading({ id: editProductTemplate.typePrefix, state: true }))
      break;
    case editProductTemplate.fulfilled.type:
      dispatch(setLoading({ id: editProductTemplate.typePrefix, state: false }))
      dispatch(addAlert({
        severity: "success",
        message: getLang(lang, 'message.success.SUCCESS_UPDATE_TEMPLATE')
      }))
      break;
    case editProductTemplate.rejected.type:
      dispatch(setLoading({ id: editProductTemplate.typePrefix, state: false }))
      dispatch(addAlert({
        severity: "error",
        message: getApiLang(lang, payload.code)
      }))
      break;
    default:
      break;
  }
}

export default [
  fetchBranchDropdownMiddleware,
  fetchMeAndBranchDropdownMiddleware,
  fetchMeAndBranchDropdownSpecialMiddleware,
  publishNewProductMiddleware,
  createNewProductMiddleware,
  getProductDropdownMiddleware,
  updateProductInfoMiddleware,
  updateProductPhotoMiddleware,
  getProductPageMiddleware,
  updateProductPageMiddleware,
  uploadProductPageJsonMiddleware,
  updateProductUiMiddleware,
  getProductMiddleware,
  deleteProductMiddleware,
  updateProductStatusMiddleware,
  addProductCategoriesMiddleware,
  getAllProductCategoryListingMiddleware,
  editProductCategoryMiddleware,
  deleteProductCategoryMiddleware,
  updateCategoryStatusMiddleware,
  updateCategoriesStatusMiddleware,
  getAllBrandModelsListingMiddleware,
  addBrandModelMiddleware,
  editBrandModelMiddleware,
  updateBrandModelStatusMiddleware,
  updateBrandModelsStatusMiddleware,
  deleteBrandModelsMiddleware,
  getAllAttributeListingMiddleware,
  deleteAttributeMiddleware,
  getAttributeDropdownMiddleware,
  addAttributeMiddleware,
  editAttributeMiddleware,
  updateAttributeStatusMiddleware,
  updateAttributesStatusMiddleware,
  quickUpdateProductMiddleware,
  updateBulkProductMiddleware,
  getProductDropdownByBranchMiddleware,
  createBulkProductMiddleware,
  addModelsToExistingBrandMiddleware,
  getAllProductsMiddleware,
  fetchTemplatesMiddleware,
  createTemplateMiddleware,
  fetchTemplateMiddleware,
  fetchTemplateSettingsMiddleware,
  editTemplateMiddleware,
  fetchPreviewTemplateSettingsMiddleware,
  fetchTemplateDropdownMiddleware,
  fetchOverrideWarningListMiddleware,
  fetchCustomTemplateHtmlMiddleware,
  editProductTemplateMiddleware
];
