import React, { useEffect, useRef, useState } from "react";
import { makeStyles, ButtonBase, Typography, Box } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  filledWithNum: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    maxWidth: "90%",
    alignSelf: "center",
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.25em",
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
  tooltip: {
    maxHeight: "150px",
    overflow: "auto",
    display: "grid",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255,255,255,1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#ccd1d3",
    },
  },
  tooltipChip: {
    backgroundColor: "#FFF",
    margin: "0.5em 0.3em 0.5em 0.3em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
}));

export default function BranchSelectComponent({
  handleClick,
  value,
  styles,
  placeholder,
  disabled,
  textStyles,
  lang,
  drawerOpen,
  extraChip,
  adjustField,
  isEmpty,
  selectAll,
}) {
  const classes = useStyle();
  const ref = useRef(null);
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Add event when user zoom in/out or resize screen size
    const handleResize = () => {
      setDimension({
        width: window.innerHeight,
        height: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    adjustField(ref);
    // eslint-disable-next-line
  }, [value, dimension]);

  useEffect(() => {
    setTimeout(() => {
      adjustField(ref);
      window.dispatchEvent(new Event("resize"));
    }, 200);
    // eslint-disable-next-line
  }, [drawerOpen]);

  return (
    <ButtonBase
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...styles }}
    >
      <Box
        ref={ref}
        style={{
          display: "flex",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "1",
        }}
      >
        <Box display="flex" alignItems="center">
          {!isEmpty ? (
            selectAll ? (
              getLang(lang, "label.ALL_BRANCHES")
            ) : (
              value?.map((item, i) => (
                <Typography
                  variant="body2"
                  style={{
                    whiteSpace: "noWrap",
                    display: extraChip?.count
                      ? i <= value.length - extraChip.count - 1
                        ? "inline-flex"
                        : "none"
                      : "inline-flex",
                  }}
                  key={i}
                >
                  {item.name}
                  {i !== value.length - 1 ? "," : ""}&nbsp;
                </Typography>
              ))
            )
          ) : (
            <Typography variant="body2" style={{ color: "#a4a6a8" }}>
              {placeholder}
            </Typography>
          )}
        </Box>
        {extraChip.count >= 1 && !isEmpty && !selectAll ? (
          <Typography variant="body2" style={{ whiteSpace: "noWrap" }}>
            ... + {extraChip.count}
          </Typography>
        ) : null}
      </Box>
      <ArrowDropDownIcon fontSize="small" />
    </ButtonBase>
  );
}
