import React, { useState } from "react";
import { addAlert } from "modules/notification";
import CustomRangePanelComponent from "./customRangePanel.component";

function CustomRangePanelContainer({
  formik,
  disabled,
  lang,
  type,
  availableQuantity,
  checkSNAvailability,
  updateQuantity,
  isSNexceedRange,
}) {
  const [isSameRange, setIsSameRange] = useState(false);
  const [isExceedLimit, setIsExceedLimit] = useState(false);

  const onCustomRangeChange = (event, formik, name, index) => {
    let exceedLimit = false;

    if (type === "sequence_number") {
      formik.setFieldValue(name, event.target.value);
    } else {
      //no used, serial number got new component, there got validation
      // let format = formik.values.format.split(",");
      // let digitLength = format[1];
      // if (Number(event.target.value).toString().length <= digitLength) {
      //   formik.setFieldValue(name, event.target.value);
      // } else {
      //   exceedLimit = true;
      // }
    }

    setIsExceedLimit(exceedLimit);
    setIsSameRange(false);
  };

  const submit = (formik) => {
    let range = formik.getFieldProps("generateRanges").value;
    let sameRange = false;

    let total = range.reduce((a, b) => {
      if (!isNaN(b.start) && !isNaN(b.end)) {
        const start = Number(b.start);
        const end = Number(b.end);
        a += end - start + 1;
      }
      return a;
    }, 0);
    updateQuantity(total);

    let tempRange = [];
    range.forEach((number, index) => {
      const start = Number(number.start);
      const end = Number(number.end);

      let overlap = range.findIndex((r, i) => {
        if (index !== i) {
          return (
            (r.start <= start && r.end >= end) ||
            (r.start >= start && r.start <= end) ||
            (r.end >= start && r.end <= end)
          );
        }
        return false;
      });

      if (overlap >= 0) {
        sameRange = true;
      }

      tempRange.push({
        start,
        end,
      });
    });
    formik.setFieldValue("generateRanges", tempRange)

    if (!sameRange) {
      checkSNAvailability(formik);
      //value check for submit assign serial number
      formik.setFieldValue("isSerialNumber", false);
      setIsSameRange(sameRange);
    } else {
      setIsSameRange(sameRange);
    }
  };

  return (
    <CustomRangePanelComponent
      formik={formik}
      addAlert={addAlert}
      onCustomRangeChange={onCustomRangeChange}
      isSameRange={isSameRange}
      lang={lang}
      isExceedLimit={isExceedLimit}
      isInputDisable={disabled}
      availableQuantity={availableQuantity}
      submit={submit}
      isSNexceedRange={isSNexceedRange}
    />
  );
}

export default CustomRangePanelContainer;
