import axios from "axios";

const checkSNUnassignAvailability = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/serialnumber/api/v1/serialnumber/unassign/check-availability", {
        type: parameters.type,
        ranges: parameters.ranges,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default checkSNUnassignAvailability;
