import { createSlice } from "@reduxjs/toolkit";
import { getCurrencyDropdown } from "../action/productPage.action";
import { generateId } from "../../utils/productPage.util";
import languages from "lib/constants/languages";
import {
  editProductTemplate,
  fetchTemplateDropdown,
} from "../action/template.action";

export const PRODUCT_PAGE = "productPage";

const initialState = {
  uuid: "",
  productName: "",
  template: null,
  templateDropdown: [],
  desc: "",
  category: "",
  brand: {
    name: "",
    id: "-1",
  },
  model: {
    name: "",
    id: "-1",
  },
  attributes: [],
  sku: "",
  currencyCode: "",
  currencyExp: 2,
  currencyName: "",
  incentivePoint: 0,
  price: 0,
  scanLimit: 0,
  nodes: [],
  picture: "",
  lang: [languages[0].code],
  productId: "",
  backgroundColor: "#ffffff",
  components: {
    authentication: {
      id: "authentication",
      type: "authentication",
      content: {
        messages: [
          {
            lang: "EN",
            value: "",
          },
        ],
      },
      isVisible: true,
    },
  },
  columns: {
    id: "column-1",
    compIds: ["authentication"],
  },
  pageWorkspaceLanguage: "EN",
  currencyDropdown: [],
  lastNewProductStep: null,
  error: false,
  hasSaved: false,
  createdAt: "",
  updatedAt: "",
  pageCreatedAt: "",
  pageUpdatedAt: "",
  isEditorMode: false,
  warrantyInfo: {
    warrantySettingId: "",
    warrantyName: "",
    warrantyDuration: 0,
  },
  isInProductPage: false,
  customFields: [],
};

const productPageSlice = createSlice({
  name: PRODUCT_PAGE,
  initialState,
  reducers: {
    setProductInfo(state, action) {
      const { payload } = action;
      state.productName = payload.productName;
      state.desc = payload.desc;
      state.nodes = payload.nodes;
      state.scanLimit = payload.scanLimit;
      state.category = payload.category;
      state.brand = payload.brand;
      state.model = payload.model;
      state.attributes = payload.attributes;
      state.sku = payload.sku;
      state.currencyCode = payload.currencyCode;
      state.price = payload.price;
      state.incentivePoint = payload.incentivePoint;
      state.warrantyInfo.warrantySettingId =
        payload.warrantyInfo.warrantySettingId;
      state.warrantyInfo.warrantyName = payload.warrantyInfo.warrantyName;
      state.warrantyInfo.warrantyDuration =
        payload.warrantyInfo.warrantyDuration;
      state.customFields = payload.customFields;
    },
    setProductPicture(state, action) {
      state.picture = action.payload;
    },
    resetProductPage() {
      return initialState;
    },
    addComponent(state, action) {
      const componentId = generateId(
        action.payload.type,
        state.columns.compIds
      );

      state.columns.compIds = [...state.columns.compIds, componentId];
      state.components[componentId] = {
        id: componentId,
        type: action.payload.type,
        content: action.payload.content,
        isVisible: true,
      };
    },
    addLanguage(state, action) {
      state.lang = [...state.lang, action.payload];
    },
    deleteLanguage(state, action) {
      state.lang = state.lang.filter((lang) => lang !== action.payload);
    },
    reorderComponent(state, action) {
      const { id, sourceIndex, destinationIndex } = action.payload;
      const newCompIds = [...state.columns.compIds];
      newCompIds.splice(sourceIndex, 1);
      newCompIds.splice(destinationIndex, 0, id);
      state.columns.compIds = newCompIds;
    },
    deleteComponent(state, action) {
      const id = action.payload;
      const newComponents = { ...state.components };
      delete newComponents[id];

      state.components = newComponents;
      state.columns.compIds = state.columns.compIds.filter(
        (compId) => compId !== id
      );
    },
    hideComponent(state, action) {
      const id = action.payload;
      state.components[id].isVisible = false;
    },
    showComponent(state, action) {
      const id = action.payload;
      state.components[id].isVisible = true;
    },
    showMultipleComponents(state, action) {
      const { startIndex, endIndex } = action.payload;
      const compIds = state.columns.compIds.slice(startIndex, endIndex + 1);
      compIds.forEach((id) => {
        state.components[id].isVisible = true;
      });
    },
    updateComponent(state, action) {
      const { id, content } = action.payload;
      state.components[id].content = content;
      state.hasSaved = false;
    },
    resetPageComponent(state) {
      state.components = {};
      state.columns.compIds = [];
    },
    setLastNewProductStep(state, action) {
      state.lastNewProductStep = action.payload;
    },
    loadProductPage(state, action) {
      const { payload } = action;
      let attributeTemp = [];

      payload.attributes.map((attribute) => {
        attributeTemp.push({
          id: attribute.id,
          name: attribute.name,
          options: attribute.options,
          optionsId: attribute.optionsId,
        });

        return null;
      });

      state.pageWorkspaceLanguage = initialState.pageWorkspaceLanguage;

      state.productName = payload.productName;
      state.nodes = payload.nodes.map((node) => ({
        ...node,
        node_id: node.id,
        node_parent_id: node.parent_id,
      }));
      state.template = payload.template;
      state.templateAfter = payload.template;
      state.templateUrl = payload.templateUrl;
      state.picture = payload.picture;
      state.lang = payload.lang;
      state.productId = payload.productId;
      state.backgroundColor = payload.backgroundColor;
      state.components = payload.components;
      state.columns.compIds = payload.columns ? payload.columns.compIds : [];
      state.scanLimit = payload.scanLimit;
      state.incentivePoint = payload.incentivePoint;
      state.desc = payload.desc;
      state.error = false;
      state.hasSaved = false;
      state.createdAt = payload.createdAt;
      state.updatedAt = payload.updatedAt;
      state.pageCreatedAt = payload.pageCreatedAt;
      state.pageUpdatedAt = payload.pageUpdatedAt;
      state.sku = payload.sku;
      state.category = payload.category;
      state.price = payload.price;
      state.currencyCode = payload.currencyCode;
      state.currencyExp = payload.currencyExp;
      state.attributes = attributeTemp;
      state.brand = payload.brand;
      state.model = payload.model;
      state.warrantyInfo.warrantySettingId = payload.warrantyInfo.id ?? "";
      state.warrantyInfo.warrantyName =
        payload.warrantyInfo.warranty_name ?? "";
      state.warrantyInfo.warrantyDuration =
        payload.warrantyInfo.warranty_duration ?? 0;
      state.customFields = payload.customFields ?? [];
    },
    setProductPageError(state, action) {
      state.error = action.payload;
    },
    setHasSaved(state, action) {
      state.hasSaved = action.payload;
    },
    setProductUpdatedTime(state, action) {
      state.updatedAt = action.payload;
    },
    setEditorMode(state, action) {
      state.isEditorMode = action.payload;
    },
    setProductWarrantyInfo(state, action) {
      const { payload } = action;

      if (!payload.data) return;

      state.warrantyInfo.warrantySettingId =
        payload.data.warranty_setting_id ||
        state.warrantyInfo.warrantySettingId;
      state.warrantyInfo.warrantyName =
        payload.data.warranty_name || state.warrantyInfo.warrantyName;
      state.warrantyInfo.warrantyDuration =
        payload.data.duration_month || state.warrantyInfo.warrantyDuration;
    },
    resetProductWarrantyInfo(state) {
      state.warrantyInfo = initialState.warrantyInfo;
    },
    setIsInProductPage(state, action) {
      state.isInProductPage = action.payload;
    },
    setPageWorkspaceLanguage(state, action) {
      state.pageWorkspaceLanguage = action.payload;
    },
    setProductTemplateChange(state, action) {
      state.templateAfter = action.payload.uuid;
    },
    setProductUuid: (state, action) => {
      state.uuid = action.payload;
    },
  },
  extraReducers: {
    [getCurrencyDropdown.fulfilled]: (state, action) => {
      const { payload } = action;

      state.currencyDropdown = [...payload];
    },
    [fetchTemplateDropdown.fulfilled]: (state, action) => {
      state.templateDropdown = action.payload;
    },
    [editProductTemplate.fulfilled]: (state, action) => {
      state.template = state.templateAfter;
    },
  },
});

export const {
  addComponent,
  addLanguage,
  deleteLanguage,
  reorderComponent,
  deleteComponent,
  hideComponent,
  showComponent,
  showMultipleComponents,
  updateComponent,
  setProductInfo,
  setProductPicture,
  resetProductPage,
  resetPageComponent,
  setLastNewProductStep,
  loadProductPage,
  setProductPageError,
  setHasSaved,
  setProductUpdatedTime,
  setEditorMode,
  setProductWarrantyInfo,
  resetProductWarrantyInfo,
  setIsInProductPage,
  setPageWorkspaceLanguage,
  setProductUuid,
  setProductTemplateChange,
} = productPageSlice.actions;

export const productPageReducer = productPageSlice.reducer;
