import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import ActionButton from "components/button/actionDetailButton";
import { TablePaginationActions } from "components/tablePagination";
import { UNASSIGN_BATCH_STATUS_FAIL } from "modules/serial-number/constants";
import RefreshTable from "components/table/refreshTable";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import { KeyboardArrowDown } from "@material-ui/icons";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import moment from "moment";
import "moment/min/locales.min";
import UnassignStatusProgress from "../../progress/unassignStatusProgress";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  button: {
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function UnassignTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  language,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  retryUnassign,
  viewDetail,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {rows.length > 0 || isFetching ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="unassign table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell style={{ textAlign: "center" }}>
                    <Box px={1}>{getLang(lang, "label.NUM")}</Box>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.QUANTITY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order =
                          tableFilter.order === "asc" ? "desc" : "asc";
                        changeOrder(order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE_CREATED")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StickyRightTableCell style={{ textAlign: "center" }}>
                    <Box px={1}>{getLang(lang, "label.ACTION")}</Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={7} />
                    ))
                  : rows.map((row, index) => {
                      let actionItems = [
                        {
                          label: (
                            <Typography variant="caption">
                              {getLang(lang, "label.VIEW_DETAIL")}
                            </Typography>
                          ),
                          action: () => {
                            viewDetail(row);
                          },
                        },
                      ];

                      if (row.status === UNASSIGN_BATCH_STATUS_FAIL) {
                        actionItems.push({
                          label: (
                            <Box display="flex" justifyContent="center">
                              <Typography
                                variant="caption"
                                style={{ marginRight: 3 }}
                              >
                                {getLang(lang, "label.RETRY_FAIL_UNASSIGN")}
                              </Typography>
                              {!row.is_retry_available ? (
                                <Tooltip
                                  title={getLang(
                                    lang,
                                    "message.info.ASSIGN_RETRY_LIMIT_REACH"
                                  )}
                                  arrow
                                >
                                  <ErrorOutlineIcon
                                    style={{
                                      fontSize: 18,
                                      pointerEvents: "auto",
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                            </Box>
                          ),
                          action: () => {
                            retryUnassign(row);
                          },
                          disabled: !row.is_retry_available,
                        });
                      }

                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StickyLeftTableCell style={{ textAlign: "center" }}>
                            <Box px={1}>{index + 1 + rowsPerPage * page}</Box>
                          </StickyLeftTableCell>
                          <StyledTableCell>{row.branch?.name}</StyledTableCell>
                          <StyledTableCell>
                            {row.products.map((product, idx) => (
                              <Box key={product.uuid} mt={idx ? 1 : 0}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex" alignItems="center">
                                    <Box className={classes.productPhoto}>
                                      {product.picture === "" ? (
                                        <img
                                          src={DefaultImg}
                                          alt="emptyProduct"
                                        />
                                      ) : (
                                        <img
                                          src={product.picture}
                                          alt={product.name}
                                        />
                                      )}
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      ml={1}
                                      flex={1}
                                    >
                                      <Typography
                                        className={classes.name}
                                        variant="caption"
                                      >
                                        {product.name}
                                      </Typography>
                                      {!!product.attributes &&
                                        !!product.attributes.length && (
                                          <Typography
                                            className={classes.attributeText}
                                            variant="caption"
                                          >
                                            {product.attributes.join(", ")}
                                          </Typography>
                                        )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.quantity.toLocaleString("en-US")}
                          </StyledTableCell>
                          <StyledTableCell>
                            <UnassignStatusProgress
                              unassignId={row.id}
                              initialProgress={row.progress}
                              initialStatus={row.status}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment(row.date).format("ll")}
                          </StyledTableCell>
                          <StickyRightTableCell>
                            <Box display="flex" justifyContent="center" px={1}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </Box>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              classes: classes.toolbar,
            }}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(
                lang,
                "label.OF"
              )} ${count.toLocaleString("en-US")}`
            }
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="unassign table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <Box px={1}>{getLang(lang, "label.NUM")}</Box>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.QUANTITY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.DATE_CREATED")}
                  </StyledTableCell>
                  <StickyRightTableCell style={{ width: "67px" }}>
                    {getLang(lang, "label.ACTION")}
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "paragraph.NO_ASSIGN_BATCH")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
