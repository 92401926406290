/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntegrationSettingCardComponent from "./integrationSettingCard.component";
import { DKSH_INTEGRATION_SETTING_EDIT } from "lib/constants/accessRights";
import accountApi from "app/api/account";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import serialNumApi from "app/api/serialnumber";

export const IntegrationContext = createContext();

export default function IntegrationSettingCardContainer({
  integration,
  handleUpdateData,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const hasIntegrationEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(DKSH_INTEGRATION_SETTING_EDIT)
  );
  const [isFetching, setIsFetching] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [pathSettings, setPathSettings] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isFetchingAdt, setIsFetchingAdt] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [totalAdtRecords, setTotalAdtRecords] = useState(0);
  const [adtHasNextPage, setAdtHasNextPage] = useState(true);
  const [isErrorAdt, setIsErrorAdt] = useState(false);
  const [filter, setFilter] = useState({
    rowsPerPage: 15,
    page: 0,
    search: "",
  });

  const isFetchingRef = useRef(true);

  useEffect(() => {
    if (integration) {
      getPathSettingData();
    }
  }, [integration]);

  const getPathSettingData = () => {
    setIsFetching(true);
    accountApi
      .getIntegrationPathSetting(integration.uuid)
      .then((res) => {
        setPathSettings(res.data);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleSubmit = (values) => {
    setIsSubmitting(true);

    let dataToUpdate = [];
    values.settings.forEach((settingGroup) => {
      settingGroup.settings.forEach((setting) => {
        dataToUpdate.push({
          uuid: setting.uuid,
          value: setting.value,
        });
      });
    });

    let pathSettingsToUpdate = [];
    values.pathSettings.forEach((pathSetting) => {
      pathSettingsToUpdate.push({
        uuid: pathSetting.uuid,
        settings: pathSetting.settings.map((setting) => {
          if (pathSetting.mapping_type === 1) {
            return {
              uuid: setting.uuid,
              path: setting.path,
              additional_info_uuid: setting.additional_info?.id,
            };
          } else
            return {
              uuid: setting.uuid,
              value: setting.value,
            };
        }),
      });
    });

    accountApi
      .updateIntegrationSetting({
        uuid: integration.uuid,
        settings: dataToUpdate,
        phase_settings: pathSettingsToUpdate,
      })
      .then((res) => {
        let settingGroups = [];

        let settings = {};
        res.data.settings.forEach((element) => {
          if (settings.hasOwnProperty(element.group)) {
            settings[element.group].push(element);
          } else {
            settings[element.group] = [element];
          }
        });
        Object.keys(settings).forEach((key) => {
          settingGroups.push({ group: key, settings: settings[key] });
        });
        res.data.settings = settingGroups;
        handleUpdateData(res.data);
        getPathSettingData();
        setIsEdit(false);

        dispatch(
          addAlert({
            severity: "success",
            message: getLang(
              lang,
              "message.success.SUCCESS_UPDATE_INTEGRATION_SETTINGS"
            ),
          })
        );
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    getAdditionalInfoTitles();
  }, [filter]);

  const getAdditionalInfoTitles = () => {
    if (adtHasNextPage) {
      setIsErrorAdt(false);
      setIsFetchingAdt(true);
      isFetchingRef.current = true;
      serialNumApi
        .getAdtInfoTitles({
          length: filter.rowsPerPage,
          start: filter.rowsPerPage * filter.page,
          search: filter.search,
        })
        .then((res) => {
          setTotalAdtRecords(res.data.recordsFiltered);
          let data = [];
          if (filter.page === 0) {
            data = [...res.data.data];
          } else {
            data = [...additionalFields, ...res.data.data];
          }
          if (data.length >= res.data.recordsFiltered) {
            setAdtHasNextPage(false);
          } else {
            setAdtHasNextPage(true);
          }
          setAdditionalFields(data);
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
          setIsErrorAdt(true);
        })
        .finally(() => {
          setIsFetchingAdt(false);
          isFetchingRef.current = false;
        });
    }
  };

  const handleFetchMoreAdtInfos = () => {
    if (!adtHasNextPage) return;
    if (!isFetchingRef.current) {
      setFilter({
        ...filter,
        page: filter.page + 1,
      });
    }
  };

  const handleReloadAdtInfos = () => {
    setAdtHasNextPage(true);
    setAdditionalFields([]);
    setFilter({
      ...filter,
      page: 0,
    });
  };

  const handleSearchAdtInfos = (search) => {
    setAdtHasNextPage(true);
    setAdditionalFields([]);
    setFilter({
      ...filter,
      page: 0,
      search: search,
    });
  };

  return (
    <IntegrationContext.Provider
      value={{
        adtInfos: additionalFields,
        isFetchingAdt: isFetchingAdt,
        isErrorAdt: isErrorAdt,
        totalRecords: totalAdtRecords,
        hasNextPage: adtHasNextPage,
        handleFetchMoreAdtInfos: handleFetchMoreAdtInfos,
        handleReload: handleReloadAdtInfos,
        handleSearch: handleSearchAdtInfos,
        isSubmitting: isSubmitting,
      }}
    >
      <IntegrationSettingCardComponent
        integration={integration}
        lang={lang}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        hasIntegrationEditAccessRight={hasIntegrationEditAccessRight}
        pathSettings={pathSettings}
        isFetching={isFetching}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </IntegrationContext.Provider>
  );
}
