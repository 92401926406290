import React from "react";
import {
  Box,
  IconButton,
  Typography,
  TableRow,
  TableCell,
  FormHelperText,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { ErrorMessage } from "formik";
import BranchSelect from "../../select/branchSelect";
import ProductSelect from "../../select/productSelect";
import DustBinIcon from "assets/svg/dustBin.svg";
import { useLuckyDraw } from "../../../context/luckyDraw.context";

export default function SingleProductBindingPanelComponent({
  index,
  product,
  lang,
  isSubmitting,
  formik,
  branchList,
  productList,
  hasLuckyDrawEditAccessRight,
  isFetching,
  isEditable,
  isPublished,
  isInitialValue,
}) {
  const { excludeProdBindList } = useLuckyDraw();

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell width="10%">
        <Typography variant="body2" align="center">
          {index + 1}
        </Typography>
      </TableCell>
      <TableCell width="30%">
        {hasLuckyDrawEditAccessRight ? (
          <Box width="100%">
            <ProductSelect
              searchable={true}
              value={product.product}
              placeholder={getLang(lang, "placeholder.PRODUCT")}
              searchPlaceholder={getLang(
                lang,
                "placeholder.SEARCH_A_PRODUCT_NAME"
              )}
              emptyMessage={getLang(lang, "message.info.NO_PRODUCT_FOUND")}
              dropdownItem={productList.filter((product) => {
                // if product id is in excludeProdBindList, then filter it out
                if (excludeProdBindList.includes(product.id)) {
                  return false;
                }
                return product;
              })}
              isEmpty={!product.product}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 4,
                width: "100%",
                maxWidth: "300px",
              }}
              handleChange={(value) => {
                if (
                  hasLuckyDrawEditAccessRight &&
                  isEditable &&
                  (!isPublished || !isInitialValue)
                ) {
                  formik.setFieldValue(`products[${index}].product`, value);
                  // reset nodes when product is changed
                  formik.setFieldValue(`products[${index}].nodes`, []);
                }
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              disabled={isSubmitting || (isPublished && isInitialValue)}
            />
            <FormHelperText
              error={
                formik.touched.products?.[index]?.product &&
                formik.errors.products?.[index]?.product
              }
            >
              {ErrorMessage({
                name: `products[${index}].product`,
              })}
            </FormHelperText>
          </Box>
        ) : (
          <Typography variant="body2" align="center">
            {product.product?.name || "-"}
          </Typography>
        )}
      </TableCell>
      <TableCell width="20%">
        <Box>
          <Typography variant="body2" align="center">
            {!!product.product?.attributes &&
            !!product.product?.attributes.length
              ? product.product.attributes.join(", ")
              : "-"}
          </Typography>
        </Box>
      </TableCell>
      <TableCell width="40%">
        <Box width="100%" overflow="hidden">
          <BranchSelect
            placeholder={getLang(lang, "placeholder.BRANCH")}
            disabled={isSubmitting || (isPublished && isInitialValue)}
            dropdownItem={branchList}
            value={product.nodes}
            handleChange={(value) => {
              if (
                hasLuckyDrawEditAccessRight &&
                isEditable &&
                (!isPublished || !isInitialValue)
              ) {
                formik.setFieldValue(`products[${index}].nodes`, value);
              }
            }}
            isEmpty={product.nodes?.length === 0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              backgroundColor: "white",
              border: "1px solid #D8D8D8",
            }}
            isFetching={isFetching}
          />
          <FormHelperText
            error={
              !!formik.touched.products?.[index]?.nodes &&
              !!formik.errors.products?.[index]?.nodes
            }
          >
            {ErrorMessage({
              name: `products[${index}].nodes`,
            })}
          </FormHelperText>
        </Box>
      </TableCell>
      <TableCell>
        {isEditable &&
          hasLuckyDrawEditAccessRight &&
          (!isPublished || !isInitialValue) && (
            <IconButton
              disabled={isSubmitting}
              onClick={() => {
                let products = [...formik.values.products];
                products.splice(index, 1);
                formik.setFieldValue("products", products);
              }}
            >
              <img src={DustBinIcon} alt="delete" />
            </IconButton>
          )}
      </TableCell>
    </TableRow>
  );
}
