import { addAlert, selectLoading } from "modules/notification";
import React from "react";
import { connect } from "react-redux";
import SerialNumTableComponent from "./serialNumTable.component";
import { useHistory } from "react-router-dom";
import { GENERATE_SN, ROOT } from "../../../config/serial-number.route";
import { fetchTableLength } from "../../../redux";

function SerialNumTableContainer({
  hasSerialNumberEditAccessRight,
  hasSerialNumberGenerateAccessRight,
  isFetching,
  isLengthFetching,
  listingSerialNumberData,
  page,
  language,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  length,
  search,
  addSuccessAlert,
  handleReload,
  handleStatusChange,
  paginationOptions,
  lang,
  handleViewDetail,
  showAdditionalInfo = false,
  additionalFields,
  isSearchResult,
  userLang,
  isError,
  displayLang,
  isBranchDropdownLoading,
  isProductDropdownLoading,
}) {
  const history = useHistory();

  const handleAddSerialNumber = () => {
    history.push(GENERATE_SN);
  };

  const handleViewSerialNumberDetails = ({ enc }) => {
    handleViewDetail(`${ROOT}/details/${enc}`);
  };

  return (
    <SerialNumTableComponent
      hasSerialNumberEditAccessRight={hasSerialNumberEditAccessRight}
      hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
      rows={listingSerialNumberData}
      page={page}
      rowsPerPage={rowsPerPage}
      language={language}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      isLengthFetching={isLengthFetching}
      disabledPagination={isFetching || isLengthFetching}
      length={length}
      search={search}
      addSuccessAlert={addSuccessAlert}
      handleAddSerialNumber={handleAddSerialNumber}
      handleReload={handleReload}
      handleViewSerialNumberDetails={handleViewSerialNumberDetails}
      handleStatusChange={handleStatusChange}
      paginationOptions={paginationOptions}
      lang={lang}
      showAdditionalInfo={showAdditionalInfo}
      additionalFields={additionalFields}
      isSearchResult={isSearchResult}
      userLang={userLang}
      isError={isError}
      displayLang={displayLang}
      isBranchDropdownLoading={isBranchDropdownLoading}
      isProductDropdownLoading={isProductDropdownLoading}
    />
  );
}

const mapStateToProps = (state) => ({
  userLang: state.profile.language,
  isLengthFetching: selectLoading(state, fetchTableLength.typePrefix),
  displayLang: state.additionalInfo.displayLang,
});

const mapDispatchToProps = (dispatch) => ({
  addSuccessAlert: (value) =>
    dispatch(addAlert({ severity: "success", message: value })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SerialNumTableContainer);
