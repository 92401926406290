import axios from "axios";

export default function getIntegrationPathSetting(uuid) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/account/api/v1/dksh-integration-settings/phase-settings?uuid=${uuid}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
