import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AddIcon } from "components/icon";
import LuckyDrawListingPanel from "modules/lucky-draw-v2/components/panel/luckyDrawListingPanel";
import LuckyDrawDuplicateDialog from "modules/lucky-draw-v2/components/dialog/luckyDrawDuplicate";
import { getLang } from "app/feature/constants";
import { useLuckyDraw } from "modules/lucky-draw-v2/context/luckyDraw.context";

export default function LuckyDrawPageComponent({
  hasLuckyDrawAddAccessRight,
  handleAddLuckyDraw,
  handleAddInstantWin,
  lang,
}) {
  
  const {data} = useLuckyDraw();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicateDialog = () => {
    setOpenDuplicateDialog(true);
    handleClose();
  };

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const childRef = useRef();

  return (
    <Box>
      <LuckyDrawDuplicateDialog
        isOpen={openDuplicateDialog}
        handleClose={() => setOpenDuplicateDialog(false)}
        handleReload={() => childRef.current.reloadData()}
      />
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              {getLang(lang, "label.LUCKY_DRAW")}
            </Typography>
          </Box>
          {hasLuckyDrawAddAccessRight ? (
            <Box>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                startIcon={
                  <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                }
              >
                {getLang(lang, "label.LUCKY_DRAW")}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleAddLuckyDraw}>
                  {getLang(lang, "label.CREATE_TRADITIONAL_RAFFLE")}
                </MenuItem>
                {/* <MenuItem onClick={handleAddLuckyDraw}>Create Interval Raffle</MenuItem> */}
                <MenuItem onClick={handleAddInstantWin}>
                  {getLang(lang, "label.CREATE_INSTANT_WIN")}
                </MenuItem>
                { data.length > 0 &&
                <MenuItem onClick={handleDuplicateDialog}>
                  {getLang(lang, "label.DUPLICATE_LUCKY_DRAW")}
                </MenuItem>
                }
              </Menu>
            </Box>
          ) : null}
        </Box>
        <Divider />
      </Box>
      <Box
        gridArea="panel"
        style={{
          paddingTop: "1rem",
        }}
      >
        <Box>
          <LuckyDrawListingPanel ref={childRef} />
        </Box>
      </Box>
    </Box>
  );
}
