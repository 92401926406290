import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop/index.js";
import { selectLoading } from "modules/notification";
// import { resetDealerIncentivePoint, updateDealerIncentivePoint } from "modules/stockflow/redux";
import EditLoyaltyPointDialogComponent from "./editLoyaltyPointDialog.component";
import { updateConsumerLoyaltyPoint } from "modules/consumer/redux/action/consumer.action";

export default function EditLoyaltyPointDialogContainer({
  isOpen,
  handleClose,
  consumerDetails,
  handleReload,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateConsumerLoyaltyPoint.typePrefix)
  );

  const handleChangePointPolarity = (polarity, formik) => {
    formik.setFieldValue("selectedPointPolarity", polarity);
    let val = formik.values.value;
    let newBal = 0;
    if (polarity === "increase") {
      newBal = +consumerDetails.point + +val;
    } else {
      newBal = +consumerDetails.point - +val;
    }
    formik.setFieldValue("balance", newBal);
  };

  const handleChangePointValue = (value, formik) => {
    let val = +value;
    let newBal = 0;
    if (formik.values.selectedPointPolarity === "increase") {
      newBal = +consumerDetails.point + val;
    } else {
      newBal = +consumerDetails.point - val;
    }
    formik.setFieldValue("value", val);
    formik.setFieldValue("balance", newBal);
  };

  const handleSubmit = async (values) => {
    const body = {
      sub: consumerDetails.sub,
      isPositive: values.selectedPointPolarity === "increase" ? true : false,
      value: values.value,
      balancePoint: values.balance,
      description: values.remark,
    };
    dispatch(showBackdrop());
    await dispatch(updateConsumerLoyaltyPoint(body))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        handleReload();
        handleClose();
      })
      .catch(() => dispatch(hideBackdrop()));
  };

  return (
    <EditLoyaltyPointDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      isSubmitting={isSubmitting}
      consumerDetails={consumerDetails}
      handleChangePointPolarity={handleChangePointPolarity}
      handleChangePointValue={handleChangePointValue}
      handleSubmit={handleSubmit}
      lang={lang}
    />
  );
}
