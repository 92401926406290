import React from "react"
import { makeStyles, Typography, Box, Tabs } from "@material-ui/core"
import CustomTab from "components/tab"
import { getLang } from "app/feature/constants"
import DealerOrderListPanel from "../dealerOrderListPanel"

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  container: {
    background: "white",
    borderRadius: 12,
    marginTop: 16,
    border: "1px solid #D0D5DD",
    padding: "4px 0px 8px"
  },
}))

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

export default function DealerOrderPanelComponent({ 
  tabLabels,
  activeIndex,
  handleChange,
  lang
}) {
  const classes = useStyles()

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" style={{ textTransform: "none" }} children={label} />,
    }
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" px={3}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />
          })}
        </Tabs>
      </Box>
      <Box
        style={{
          position: "relative",
        }}
      >
        {tabLabels.map((item, index) => {
          let type = "new"

          if (item === getLang(lang, "label.ACCEPTED")) {
            type="accepted"
          } else if (item === getLang(lang, "label.REJECTED")) {
            type="rejected"
          } else if (item === getLang(lang, "label.CANCELLED")) {
            type="cancelled"
          }

          return (
            <TabPanel value={activeIndex} index={index} key={index}>
              <Box className={classes.panel}>
                <DealerOrderListPanel type={type} />
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  )
}
