import axios from "axios";

const endpoint_url = "/campaignV2/customer/options/duplicateLuckyDraws";
const post_endpoint_url = "/campaignV2/customer/luckyDraw/duplicate";
const get = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          ...params,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({ luckyDrawUuid, inheritType }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(post_endpoint_url, {
        uuid: luckyDrawUuid,
        inheritType: inheritType,
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const duplicateLuckyDraws = {
  get,
  post,
};

export default duplicateLuckyDraws;
