import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ParticipantsListPanel from "modules/lucky-draw-v2/components/panel/ParticipantsListPanel";
import { getLang } from "app/feature/constants";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";

export default function LuckyDrawParticipantsComponent({
  luckyDraw,
  lang,
  handleDownloadReport,
  isError,
  isFetching,
  handleReload,
}) {
  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <Box>
      <Box gridArea="topbar">
        <Box display="flex" alignItems="center">
          <BackButton />
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: "bold", marginLeft: 5 }}
          >
            {getLang(
              lang,
              Number(luckyDraw?.type) === 2
                ? "label.INSTANT_WIN_PARTICIPANT"
                : "label.LUCKY_DRAW_PARTICIPANT"
            )}
          </Typography>
        </Box>
        <Box my={1.5}>
          <Divider />
        </Box>
        {!isError && !isFetching && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <Typography variant="h6" color="primary">
                {getLang(
                  lang,
                  Number(luckyDraw?.type) === 2
                    ? "label.INSTANT_WIN"
                    : "label.LUCKY_DRAW"
                )}{" "}
                - {luckyDraw?.title}
              </Typography>
            </Box>
            <Box>
              <Button
                style={{ backgroundColor: "white" }}
                variant="outlined"
                color="primary"
                endIcon={<DownloadIcon />}
                onClick={handleDownloadReport}
              >
                {getLang(lang, "label.DOWNLOAD_REPORT")}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        gridArea="panel"
        style={{
          paddingTop: "1rem",
        }}
      >
        <Box>
          {isFetching ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : isError ? (
            <RefreshTable handleReload={handleReload} />
          ) : (
            <ParticipantsListPanel />
          )}
        </Box>
      </Box>
    </Box>
  );
}
