import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";
import {
  getAssignListing,
  getAssignProgress,
  getUnassignListing,
  getUnassignProgress,
  retryUnassignFailJob,
  unassignSerialNumber,
  retryAssignFailJob,
  getAssignFailJob,
  getUnassignSnListByProduct,
  checkSNUnassignAvailability,
  getUnassignDetail,
} from "../action/assignBatch.action";

export const getAssignListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getAssignListing.pending.type:
        dispatch(setLoading({ id: getAssignListing.typePrefix, state: true }));
        break;
      case getAssignListing.fulfilled.type:
        dispatch(setLoading({ id: getAssignListing.typePrefix, state: false }));
        break;
      case getAssignListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getAssignListing.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getAssignProgressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getAssignProgress.pending.type:
        dispatch(setLoading({ id: getAssignProgress.typePrefix, state: true }));
        break;
      case getAssignProgress.fulfilled.type:
        dispatch(
          setLoading({ id: getAssignProgress.typePrefix, state: false })
        );
        break;
      case getAssignProgress.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getAssignProgress.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getAssignFailJobMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getAssignFailJob.pending.type:
        dispatch(setLoading({ id: getAssignFailJob.typePrefix, state: true }));
        break;
      case getAssignFailJob.fulfilled.type:
        dispatch(setLoading({ id: getAssignFailJob.typePrefix, state: false }));
        break;
      case getAssignFailJob.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getAssignFailJob.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const retryAssignFailJobMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case retryAssignFailJob.pending.type:
        dispatch(
          setLoading({ id: retryAssignFailJob.typePrefix, state: true })
        );
        break;
      case retryAssignFailJob.fulfilled.type:
        dispatch(
          setLoading({ id: retryAssignFailJob.typePrefix, state: false })
        );
        break;
      case retryAssignFailJob.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: retryAssignFailJob.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const unassignSerialNumberMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case unassignSerialNumber.pending.type:
        dispatch(
          setLoading({ id: unassignSerialNumber.typePrefix, state: true })
        );
        break;
      case unassignSerialNumber.fulfilled.type:
        dispatch(
          setLoading({ id: unassignSerialNumber.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UNASSIGN_IN_PROGRESS"),
          })
        );
        break;
      case unassignSerialNumber.rejected.type:
        dispatch(
          setLoading({ id: unassignSerialNumber.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getUnassignListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getUnassignListing.pending.type:
        dispatch(
          setLoading({ id: getUnassignListing.typePrefix, state: true })
        );
        break;
      case getUnassignListing.fulfilled.type:
        dispatch(
          setLoading({ id: getUnassignListing.typePrefix, state: false })
        );
        break;
      case getUnassignListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getUnassignListing.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getUnassignProgressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getUnassignProgress.pending.type:
        dispatch(
          setLoading({ id: getUnassignProgress.typePrefix, state: true })
        );
        break;
      case getUnassignProgress.fulfilled.type:
        dispatch(
          setLoading({ id: getUnassignProgress.typePrefix, state: false })
        );
        break;
      case getUnassignProgress.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getUnassignProgress.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const retryUnassignFailJobMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case retryUnassignFailJob.pending.type:
        dispatch(
          setLoading({ id: retryUnassignFailJob.typePrefix, state: true })
        );
        break;
      case retryUnassignFailJob.fulfilled.type:
        dispatch(
          setLoading({ id: retryUnassignFailJob.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UNASSIGN_IN_PROGRESS"),
          })
        );
        break;
      case retryUnassignFailJob.rejected.type:
        dispatch(
          setLoading({ id: retryUnassignFailJob.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getUnassignSnListByProductMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getUnassignSnListByProduct.pending.type:
        dispatch(
          setLoading({ id: getUnassignSnListByProduct.typePrefix, state: true })
        );
        break;
      case getUnassignSnListByProduct.fulfilled.type:
        dispatch(
          setLoading({
            id: getUnassignSnListByProduct.typePrefix,
            state: false,
          })
        );
        break;
      case getUnassignSnListByProduct.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getUnassignSnListByProduct.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const checkSNUnassignAvailabilityMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case checkSNUnassignAvailability.pending.type:
        dispatch(
          setLoading({
            id: checkSNUnassignAvailability.typePrefix,
            state: true,
          })
        );
        break;
      case checkSNUnassignAvailability.fulfilled.type:
        dispatch(
          setLoading({
            id: checkSNUnassignAvailability.typePrefix,
            state: false,
          })
        );
        break;
      case checkSNUnassignAvailability.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: checkSNUnassignAvailability.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getUnassignDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getUnassignDetail.pending.type:
        dispatch(
          setLoading({
            id: getUnassignDetail.typePrefix,
            state: true,
          })
        );
        break;
      case getUnassignDetail.fulfilled.type:
        dispatch(
          setLoading({
            id: getUnassignDetail.typePrefix,
            state: false,
          })
        );
        break;
      case getUnassignDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getUnassignDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };
export default [
  getAssignListingMiddleware,
  getAssignProgressMiddleware,
  getAssignFailJobMiddleware,
  retryAssignFailJobMiddleware,
  unassignSerialNumberMiddleware,
  getUnassignListingMiddleware,
  getUnassignProgressMiddleware,
  retryUnassignFailJobMiddleware,
  getUnassignSnListByProductMiddleware,
  checkSNUnassignAvailabilityMiddleware,
  getUnassignDetailMiddleware,
];
