import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
  Popover,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputTextField from "components/input/inputTextField";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: theme.spacing(1),
    minWidth: 0,
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
  menuListOdd: {
    backgroundColor: "#f8f8f8",
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "250px",
  },
  emptyContainer: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
  },
  menuItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    placeItems: "flex-start",
  },
  menuList: {
    padding: "4px 0px",
  },
  errorContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

export default function SetTranditionalRaffleWinnerPanelComponent({
  anchorEl,
  isOpen,
  handleClose,
  handleListKeyDown,
  isOpenList,
  isFetching,
  value,
  participants,
  excludeList,
  fetchMoreData,
  handleSearch,
  lang,
  totalRecords,
  handleReload,
  isError,
}) {
  const classes = useStyle();
  const textRef = useRef();

  let excludeEncs = excludeList.map((e) => e.enc);
  let dataList = excludeList.length
    ? participants.filter((s) => !excludeEncs.includes(s.enc))
    : participants;

  return (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Paper className={classes.paper}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Box>
              <InputTextField
                inputRef={textRef}
                variant="filled"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ paddingRight: "6px" }}
                    >
                      <Button
                        type="button"
                        className={classes.button}
                        onClick={() => handleSearch(textRef.current.value)}
                      >
                        <SearchIcon fontSize="small" />
                      </Button>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: "#fff",
                  },
                }}
                inputProps={{
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch(textRef.current.value);
                      return false;
                    }
                  },
                  style: {
                    padding: "14px",
                  },
                }}
                placeholder={getLang(lang, "placeholder.SEARCH_CONSUMER")}
              />
            </Box>

            <Divider />
            {!!isError ? (
              <Box py={1.5} px={2} className={classes.errorContainer}>
                <Typography
                  variant="body2"
                  style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                >
                  {getLang(lang, "paragraph.SOMETHING_WENT_WRONG")}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReload}
                  disableElevation
                >
                  {getLang(lang, "label.RETRY")}
                </Button>
              </Box>
            ) : (
              <InfiniteScroll
                dataLength={participants.length}
                next={fetchMoreData}
                hasMore={participants.length < totalRecords}
                loader={<LoadingComponent height={100} />}
                height={200}
                style={{ position: "relative" }}
                endMessage={
                  isFetching ? (
                    <LoadingComponent height={100} />
                  ) : !participants.length ? (
                    <Box px={1.5} py={4}>
                      <Typography
                        variant="body2"
                        style={{ textAlign: "center" }}
                      >
                        <b>{getLang(lang, "paragraph.NO_CONSUMER")}</b>
                      </Typography>
                    </Box>
                  ) : null
                }
              >
                <>
                  {dataList.map((field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <MenuList
                          autoFocusItem={isOpenList}
                          id="menu-list-grow"
                          onClick={() => {
                            handleListKeyDown(field.serial_number, field.enc);
                          }}
                          className={clsx(classes.menuList, {
                            [classes.menuListOdd]: index % 2 === 0,
                          })}
                        >
                          <MenuItem className={classes.menuItemContainer}>
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{
                                paddingLeft: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              {field.serial_number}
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                paddingLeft: "8px",
                                color: "rgba(58, 77, 84, 0.33)",
                              }}
                            >
                              {field.name}
                              {/* &nbsp;.&nbsp;{field.contactNumber} */}
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      </React.Fragment>
                    );
                  })}
                </>
                <Divider />
                {participants.length > 0 &&
                  excludeEncs.filter((e) => e.enc !== value.enc).length ===
                    participants.length && (
                    <Box py={10} className={classes.emptyContainer}>
                      <Typography variant="body1">
                        {getLang(lang, "paragraph.NO_CONSUMER")}
                      </Typography>
                    </Box>
                  )}
              </InfiniteScroll>
            )}
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
