import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function ParticipantsListTableComponent({
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  handleFetchLuckyDrawList,
  isLoadingLDParticipantError,
  paginationOptions,
  lang,
  handleViewDetails,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <>
      {(data ?? []).length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PHONE_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EMAIL")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.JOIN_DATE_TIME")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow key={index} columnCount={8} />
                  ))
                : data.map((participant, index) => {
                    return (
                      <StyledTableRow key={index} hover tabIndex={-1}>
                        <StickyLeftTableCell>
                          <StyledTableCell>
                            {index + 1 + rowsPerPage * page}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>
                          {participant.name || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {participant.contact_no || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {participant.email || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {participant.serial_number}
                        </StyledTableCell>
                        <StyledTableCell>
                          {participant.product_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment.utc(participant.created_at).local().format("lll")}
                        </StyledTableCell>
                        <StickyRightTableCell>
                          <StyledTableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleViewDetails(participant.uuid);
                              }}
                            >
                              {getLang(lang, "label.DETAILS")}
                            </Button>
                          </StyledTableCell>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !search &&
        !isLoadingLDParticipantError && (
          <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
            <Table className={classes.table} size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.NUM")}
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PHONE_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.EMAIL")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.JOIN_DATE_TIME")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "message.info.NO_LD_PARTICIPANT")}
              </Typography>
            </Box>
          </Box>
        )
      )}

      {!isFetching &&
        data.length === 0 &&
        search &&
        !isLoadingLDParticipantError && (
          <Alert severity="info">
            {getLang(lang, "message.info.NO_LD_PARTICIPANT")}
          </Alert>
        )}

      {!isFetching && isLoadingLDParticipantError && (
        <RefreshTable handleReload={handleFetchLuckyDrawList} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
          }
        />
      )}
    </>
  );
}
