/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrizeInfoFormComponent from "./prizeInfoForm.component";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import { getUploadS3FilePath } from "lib/helper";
import { dataURLtoFile } from "lib/generalUtility";
import { uploadFile } from "app/feature/s3/s3.utility";
import { toBase64 } from "lib/FileHelper";
import { v4 as uuidv4 } from "uuid";
import { progressStatus } from "lib/constants/luckyDrawProgress";

export default function PrizeInfoFormContainer({
  uuid,
  initialValues,
  setCurrentStep,
  getLuckyDrawDetail,
  type,
  currentStep,
  progress,
  ldCurrentState,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disbursements, setDisbursements] = useState([]);

  const formRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    setDisbursements(disbursementInfos);
  }, [disbursementInfos]);

  useEffect(() => {
    if (initialValues && formRef.current) {
      let hasDefaultPrize =
        type === 2 && initialValues.some((v) => !!v.isDefault);
      let isNewlyCreated = progress && progress === progressStatus.setPeriod;

      let initialPrizeList = JSON.parse(JSON.stringify(initialValues));
      let prizeList =
        type === 1
          ? initialPrizeList
          : initialPrizeList?.filter((v) => !v.isDefault);

      formRef.current.setValues({
        prizes:
          type === 1
            ? prizeList
            : prizeList.length
            ? prizeList
            : [
                {
                  id: `item-${Date.now()}`,
                  title: "",
                  imgUrl: null,
                  quantity: 1,
                  prizeType: 1,
                  disbursementCompanyId: null,
                  creditAmount: 0,
                  unlimitedQty: false,
                  collapsed: false,
                },
              ],
        defaultPrize:
          type === 2
            ? isNewlyCreated
              ? {
                  id: `item-${Date.now()}-default`,
                  title: "Thank you!",
                  description:
                    "Thank you for participating! The wheel has spun, but luck wasn't on your side this time. Better luck next time!",
                  imgUrl: null,
                  quantity: 1,
                  isDefault: 1,
                  prizeType: 1,
                  disbursementCompanyId: null,
                  creditAmount: 0,
                  unlimitedQty: false,
                }
              : initialPrizeList?.find((v) => !!v.isDefault) ?? null
            : null,
        enableLossState: isNewlyCreated ? true : hasDefaultPrize,
        deletedPrizeUuids: [],
      });
    }
  }, [initialValues]);

  const uploadImageToS3 = async ({ uploadType, base64Image, id, fileName }) => {
    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id,
      fileName,
    });

    const file = await dataURLtoFile(base64Image, filePath);
    const result = await uploadFile({ filePath, file });

    if (!result.success) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FAILED_UPLOAD_IMAGE"),
        })
      );
      return null;
    }

    return result.url;
  };

  const transformPrizes = async (values) => {
    let prizes = values.prizes;

    let processedPrizes = [];

    if (type === 1) {
      for (let index = 0; index < prizes.length; index++) {
        let prize = prizes[index];

        for (let itemIndex = 0; itemIndex < prize.length; itemIndex++) {
          let item = prize[itemIndex];
          let temp = {
            ...item,
            imgUrl: Boolean(item.imgUrl)
              ? typeof item.imgUrl === "string"
                ? item.imgUrl
                : await uploadImageToS3({
                    uploadType: "lucky_draw_prize_image",
                    base64Image: await toBase64(item.imgUrl),
                    id: item.id,
                    fileName: `${uuidv4()}_${item.imgUrl.name}`,
                  })
              : null,
            rank_position: index === 5 ? 0 : index + 1,
            sequence: itemIndex + 1,
            redemptionCodeTag: null,
          };
          processedPrizes.push(temp);
        }
      }
    } else {
      for (let i = 0; i < prizes.length; i++) {
        let temp = {
          id: prizes[i].id,
          uuid: prizes[i].uuid,
          title: prizes[i].title,
          description: prizes[i].description,
          imgUrl: Boolean(prizes[i].imgUrl)
            ? typeof prizes[i].imgUrl === "string"
              ? prizes[i].imgUrl
              : await uploadImageToS3({
                  uploadType: "lucky_draw_prize_image",
                  base64Image: await toBase64(prizes[i].imgUrl),
                  id: prizes[i].id,
                  fileName: `${uuidv4()}_${prizes[i].imgUrl.name}`,
                })
            : null,
          quantity: prizes[i].unlimitedQty ? 0 : prizes[i].quantity,
          prizeType: prizes[i].prizeType,
          creditValue: prizes[i].creditAmount,
          sequence: i + 1,
          redemptionCodeTag: null,
          disbursementCompanyId:
            prizes[i].prizeType === 2
              ? prizes[i].disbursementCompanyId ?? 0
              : 0,
          isDefault: 0,
        };
        processedPrizes.push(temp);
      }
      if (values.enableLossState && values.defaultPrize) {
        let temp = {
          ...values.defaultPrize,
          imgUrl: Boolean(values.defaultPrize.imgUrl)
            ? typeof values.defaultPrize.imgUrl === "string"
              ? values.defaultPrize.imgUrl
              : await uploadImageToS3({
                  uploadType: "lucky_draw_prize_image",
                  base64Image: await toBase64(values.defaultPrize.imgUrl),
                  id: values.defaultPrize.id,
                  fileName: `${uuidv4()}_${values.defaultPrize.imgUrl.name}`,
                })
            : null,
          quantity: 1,
          sequence: processedPrizes.length + 1,
          redemptionCodeTag: null,
          disbursementCompanyId: 0,
          isDefault: 1,
        };
        processedPrizes.push(temp);
      }
    }

    return processedPrizes;
  };

  const handleSubmit = async (values) => {
    let isNewlyCreated = progress && progress === progressStatus.setPeriod;

    if (
      (values.isEditing &&
        (!ldCurrentState ||
          (ldCurrentState !== progressStatus.ended &&
            ldCurrentState !== progressStatus.drawStart))) ||
      isNewlyCreated
    ) {
      setIsSubmitting(true);
      let prizes = await transformPrizes(values);

      const body = {
        luckyDrawUuid: uuid,
        prizes: prizes,
        deletedPrizeUuids: values.deletedPrizeUuids,
      };
      luckyDrawV2Api
        .updatePrize(body)
        .then(() => {
          if (isMounted.current) {
            getLuckyDrawDetail(uuid);

            const params = new URLSearchParams(window.location.search);
            params.set(
              "step",
              values.goToPreview ? (type === 2 ? 6 : 5) : currentStep + 2
            );
            window.history.replaceState(null, null, `?${params}`);
            setCurrentStep(
              values.goToPreview ? (type === 2 ? 5 : 4) : currentStep + 1
            );
          }
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
        })
        .finally(() => {
          if (isMounted.current) {
            setIsSubmitting(false);
          }
        });
    } else {
      nextStep(values.goToPreview);
    }
  };

  const nextStep = (goToPreview, skipPrizeSetup) => {
    const params = new URLSearchParams(window.location.search);
    if (skipPrizeSetup) {
      params.set("step", type === 2 ? 5 : 4);
      setCurrentStep(type === 2 ? 4 : 3);
    } else {
      params.set(
        "step",
        goToPreview ? (type === 2 ? 6 : 5) : currentStep + 2
      );
      setCurrentStep(
        goToPreview ? (type === 2 ? 5 : 4) : currentStep + 1
      );
    }
    window.history.replaceState(null, null, `?${params}`);
  }

  const handlePrevious = () => {
    const params = new URLSearchParams(window.location.search);
    params.set("step", currentStep);
    window.history.replaceState(null, null, `?${params}`);
    setCurrentStep(currentStep - 1);
  };

  const handleOnDragEnd = (source, destination, formik) => {
    // Dropped outside the list
    if (!destination) return;

    // traditional raffle
    if (type === 1) {
      // Moving items within the same list
      if (source.droppableId === destination.droppableId) {
        const items = Array.from(
          formik.values.prizes[Number(source.droppableId)]
        );
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);

        const newPrizes = Array.from(formik.values.prizes);
        newPrizes[Number(destination.droppableId)] = items;
        formik.setFieldValue("prizes", newPrizes);
      } else {
        // Moving items between lists
        const sourceItems = Array.from(
          formik.values.prizes[Number(source.droppableId)]
        );
        const destItems = Array.from(
          formik.values.prizes[Number(destination.droppableId)]
        );
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);

        const newPrizes = Array.from(formik.values.prizes);
        newPrizes[Number(source.droppableId)] = sourceItems;
        newPrizes[Number(destination.droppableId)] = destItems;
        formik.setFieldValue("prizes", newPrizes);
      }

      if (!formik.values.isEditing) {
        formik.setFieldValue("isEditing", true);
      }
    }

    // instant win
    if (type === 2) {
      if (source.droppableId === destination.droppableId) {
        const items = Array.from(formik.values.prizes);
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);
        formik.setFieldValue("prizes", items);
      }

      if (!formik.values.isEditing) {
        formik.setFieldValue("isEditing", true);
      }
    }
  };

  return (
    <PrizeInfoFormComponent
      uuid={uuid}
      initialValues={initialValues}
      lang={lang}
      handleSubmitForm={handleSubmit}
      isSubmitting={isSubmitting}
      formRef={formRef}
      handlePrevious={handlePrevious}
      handleOnDragEnd={handleOnDragEnd}
      type={type}
      disbursementInfos={disbursements}
      ldCurrentState={ldCurrentState}
      progress={progress}
      nextStep={nextStep}
    />
  );
}
