/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import LuckyDrawDuplicateDialogComponent from "./luckyDrawDuplicateDialog.component";
import { useSelector, useDispatch } from "react-redux";
import optionsApi from "app/api/options";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { getApiLang, getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";

export default function LuckyDrawDuplicateDialogContainer({
  isOpen,
  handleClose,
  handleReload,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const headerType = useSelector((state) => state.luckyDraw.headerType);
  const [luckyDraws, setLuckyDraws] = useState([]);
  const [totalFiltered, setTotalFiltered] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [responseBody, setResponseBody] = useState({
    luckyDrawUuid: "",
    inheritType: 0,
  });

  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    inheritType: 0,
  });

  const getDuplicateLuckyDraws = async () => {
    setIsFetching(true);
    const response = await optionsApi.duplicateLuckyDraws
      .get({
        limit: filter.rowsPerPage,
        skip: filter.page * filter.rowsPerPage,
        search: filter.search,
      })
      .then((response) => {
        setLuckyDraws([...luckyDraws, ...response.data.lucky_draws]);
        setTotalFiltered(response.data.records_filtered);
        return response.data;
      })
      .catch((error) => error)
      .finally(() => setIsFetching(false));
    return response;
  };

  const handleConfirmClose = (confirm) => {
    setIsConfirmOpen(false);
  };

  const handleSubmit = () => {
    setIsConfirmOpen(true);
  };

  const handleProceed = async () => {
    setIsLoading(true);
    await luckyDrawV2Api.duplicateLuckyDraws
      .post(responseBody)
      .then((response) => {
        dispatch(
          addAlert({
            message: getLang(
              lang,
              "message.success.SUCCESS_DUPLICATE_LUCKY_DRAW"
            ),
            type: "success",
          })
        );
        return response;
      })
      .catch((error) =>
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        )
      )
      .finally(() => {
        setIsLoading(false);
        handleConfirmClose();
        handleClose();
        handleReload();
      });
  };

  useEffect(() => {
    if (isOpen) {
      getDuplicateLuckyDraws();
    } else {
      setLuckyDraws([]);
      setTotalFiltered(0);
    }
  }, [isOpen, filter]);

  const handleSearch = (search) => {
    setLuckyDraws([]);
    setTotalFiltered(0);
    setFilter({ ...filter, search: search });
  };

  const handleFetchMoreItems = () => {
    if (luckyDraws.length < totalFiltered) {
      const newFilter = { ...filter, page: filter.page + 1 };
      setFilter(newFilter);
    }
  };

  return (
    <>
      <GeneralDialog
        isOpen={isConfirmOpen}
        handleClose={handleConfirmClose}
        handleProceed={handleProceed}
        isLoading={isLoading}
        type="danger"
        description={getLang(lang, "paragraph.DUPLICATE_LUCKY_DRAW_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
        icon={<img src={AlertIcon} alt="alert" />}
      />

      <LuckyDrawDuplicateDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        headerType={headerType}
        luckyDraws={luckyDraws}
        handleSubmit={handleSubmit}
        getDuplicateLuckyDraws={getDuplicateLuckyDraws}
        isFetching={isFetching}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        handleFetchMoreItems={handleFetchMoreItems}
        setResponseBody={setResponseBody}
        responseBody={responseBody}
      />
    </>
  );
}
