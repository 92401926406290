import React from "react"
import { useSelector } from "react-redux";
import DeleteTemplateDialogComponent from './deleteTemplateDialog.component';

export default function DeleteTemplateDialogContainer({
  isOpen,
  handleClose,
  handleConfirm,
  products,
  name,
}) {
  const lang = useSelector(state => state.constant.languages)
  return (
    <DeleteTemplateDialogComponent
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      isOpen={isOpen}
      lang={lang}
      products={products}
      name={name}
    />
  )
}
