import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  Link
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import DefaultPhoto from "../../../utils/image/defaultPhoto.png";
import moment from "moment";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";

const style = (theme) => ({
  infoContainer: {
    width: 700,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  header: {
    paddingTop: "4px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  image: {
    height: 100,
    width: 100,
    overflow: "hidden",
    borderRadius: "50%",
    display: "flex",
    marginTop: theme.spacing(2),
    "& img": {
      flexShrink: 0,
      objectFit: "cover"
    }
  },
  textStyle: {
    fontWeight: "bold"
  },
  photoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
});

const useStyles = makeStyles(style);

export default function ParticipantDetailDialogComponent({
  isOpen,
  handleClose,
  participantDetail,
  handleView,
  lang,
  language
}) {
  const classes = useStyles();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
;
  
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <Box className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang,"label.DETAILS")}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Divider />

      <Grid container className={classes.infoContainer}>
        <Grid item xs={12} sm={7} md={7} style={{ width: "100%" }}>
          <Box p={3} pt={2}>
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang,"label.PARTICIPANT_DETAILS")}
            </Typography>

            {participantDetail.customField.map((field, index) => {
              return (
                <Box key={index} pt={2}>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={5} style={{ width: "100%" }}>
                      <Typography variant="body2" color="primary">
                        {field.variantName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      style={{ width: "100%", display: "flex" }}
                    >
                      {(field.value.contactCode) ?
                        (<Typography
                          variant="body2"
                          color="primary"
                          className={classes.textStyle}
                        >
                          {field.value.contactCode} {field.value.contactNumber}
                        </Typography>)
                        : field.value.includes("https://") ? (
                          <Link
                            variant="body2"
                            href="#"
                            color="primary"
                            underline="always"
                            onClick={() => handleView(field.value)}
                            className={classes.textStyle}
                            style={{
                              color: "royalblue"
                            }}
                          >
                            {getLang(lang, "label.VIEW")}
                          </Link>
                        ) : (field.type === "multipleChoiceMultiSelect" && field.value) ?
                          (<ul style={{
                            margin: "0",
                            padding: "0",
                            paddingLeft: "16px",
                            listStyle: "square"
                          }}
                          >
                            {field.value.map((response) => {
                              return <li> <Typography variant='body2' color="primary" className={classes.textStyle}>{response} </Typography></li>
                            })}
                          </ul>
                          ) : (
                            <Typography
                              variant="body2"
                              color="primary"
                              className={classes.textStyle}
                            >
                              {field.value || "-"}
                            </Typography>
                          )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
            {[
              {
                label: getLang(lang,"label.SERIAL_NUMBER"),
                name: participantDetail.serialNumber
              },
              {
                label: getLang(lang,"label.PRODUCT_NAME"),
                name: participantDetail.productName
              },
              {
                label: getLang(lang,"label.DATE_JOINED"),
                name: moment(participantDetail.createdAt).format("lll")
              },
            ].map((field, index) => {
              return (
                <Box key={index} pt={2}>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={5} style={{ width: "100%" }}>
                      <Typography variant="body2" color="primary">
                        {field.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} style={{ width: "100%", display: "flex" }}>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {field.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid
          item
          sm={1}
          md={1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={12} sm={4} md={4} className={classes.photoContainer}>
          <Box p={3} pt={2} textAlign="center">
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang,"label.PHOTO")}
            </Typography>

            <Box display="flex" justifyContent="center">
              <Box className={classes.image}>
                {participantDetail.consumerProfileUrl ? (
                  <img
                    src={participantDetail.consumerProfileUrl}
                    alt="profile"
                  />
                ) : (
                  <img src={DefaultPhoto} alt="default profile" />
                )}
              </Box>
            </Box>

            {participantDetail.consumerProfileUrl ? null : (
              <Typography
                variant="caption"
                style={{ color: "rgba(58, 77, 84, 0.5)" }}
              >
                {getLang(lang,"label.PHOTO_UNAVAILABLE")}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
