import React, { useState } from "react";
import LuckyDrawListingTableComponent from "./luckyDrawListingTable.component";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { getLang } from "app/feature/constants";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import WinnerDialog from "../../dialog/winnerDialog";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import TranditionalRaffleWinnerDialog from "../../dialog/tranditionalRaffleWinnerDialog";
import {
  TYPE_TRADITIONAL_RAFFLE,
  TYPE_INSTANT_WIN,
} from "modules/lucky-draw-v2/constants";
import { useLuckyDraw } from "modules/lucky-draw-v2/context/luckyDraw.context";
export default function LuckyDrawListingTableContainer({
  hasLuckyDrawEditAccessRight,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  handleStatusChange,
  handleFetchLuckyDrawList,
  paginationOptions,
  lang,
  isError,
  handleLiveDraw,
  handleAddLuckyDraw,
  handleAddInstantWin,
  handleBinding,
  handleDownloadWinner,
  handlePublished,
  handleEnd,
  handleJoinClose,
}) {
  const history = useHistory();
  const { data } = useLuckyDraw();
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [prizeDialogOpen, setPrizeDialogOpen] = useState(false);
  const [selectedLuckyDrawUuid, setSelectedLuckyDrawUuid] = useState(null);
  const [trPrizeDialogOpen, setTrPrizeDialogOpen] = useState(false);

  const handleView = async (uuid) => {
    let ld = data.find((d) => d.uuid === uuid);
    if (hasLuckyDrawEditAccessRight) {
      if (ld.progress === progressStatus.setBasic) {
        // open period page
        history.push(
          `${ROOT}/${
            Number(ld.type) === 2 ? "instant-win" : "traditional-raffle"
          }/new?lucky_draw=${uuid}&step=${2}`
        );
      } else if (ld.progress === progressStatus.setPeriod) {
        // open prize page
        history.push(
          `${ROOT}/${
            Number(ld.type) === 2 ? "instant-win" : "traditional-raffle"
          }/new?lucky_draw=${uuid}&step=${3}`
        );
      } else if (ld.progress === progressStatus.setPrize) {
        // open participant page
        history.push(
          `${ROOT}/${
            Number(ld.type) === 2 ? "instant-win" : "traditional-raffle"
          }/new?lucky_draw=${uuid}&step=${Number(ld.type) === 2 ? 5 : 4}`
        );
      } else {
        history.push(
          `${ROOT}/${
            Number(ld.type) === 2 ? "instant-win" : "traditional-raffle"
          }/${uuid}?step=${Number(ld.type) === 2 ? 6 : 5}`
        );
      }
    } else {
      history.push(
        `${ROOT}/${
          Number(ld.type) === 2 ? "instant-win" : "traditional-raffle"
        }/${uuid}?step=${Number(ld.type) === 2 ? 6 : 5}`
      );
    }
  };

  async function handleViewParticipants(id) {
    history.push(`${ROOT}/${id}/participants`);
  }

  async function handleViewPrize(uuid) {
    let ld = data.find((d) => d.uuid === uuid);
    history.push(
      `${ROOT}/${
        Number(ld.type) === 2 ? "instant-win" : "traditional-raffle"
      }/${uuid}?step=3`
    );
  }

  async function handleViewWinner(uuid, progress, type) {
    if (parseInt(type) === TYPE_TRADITIONAL_RAFFLE) {
      setTrPrizeDialogOpen(true);
    }

    if (parseInt(type) === TYPE_INSTANT_WIN) {
      setPrizeDialogOpen(true);
    }

    setSelectedLuckyDrawUuid(uuid);
  }

  async function handleLiveProgress() {
    // handleLiveProgress
  }

  return (
    <>
      <LuckyDrawListingTableComponent
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleView={handleView}
        handleStatusChange={handleStatusChange}
        handleViewParticipants={handleViewParticipants}
        handleViewPrize={handleViewPrize}
        handleViewWinner={handleViewWinner}
        handleLiveDraw={handleLiveDraw}
        handleDownloadWinner={handleDownloadWinner}
        language={language}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        isLoadingLuckyDrawError={isError}
        paginationOptions={paginationOptions}
        lang={lang}
        handleAddLuckyDraw={handleAddLuckyDraw}
        handleAddInstantWin={handleAddInstantWin}
        handleBinding={handleBinding}
        handlePublished={handlePublished}
        handleEnd={handleEnd}
        handleJoinClose={handleJoinClose}
      />
      <WinnerDialog
        isOpen={prizeDialogOpen}
        handleClose={() => setPrizeDialogOpen(false)}
        luckyDrawUuid={selectedLuckyDrawUuid}
        selectedLuckyDraw={
          selectedLuckyDrawUuid
            ? data.find((d) => d.uuid === selectedLuckyDrawUuid)
            : null
        }
      />
      <TranditionalRaffleWinnerDialog
        isOpen={trPrizeDialogOpen}
        handleClose={() => setTrPrizeDialogOpen(false)}
        luckyDrawUuid={selectedLuckyDrawUuid}
        selectedLuckyDraw={
          selectedLuckyDrawUuid
            ? data.find((d) => d.uuid === selectedLuckyDrawUuid)
            : null
        }
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleLiveProgress}
        type={"success"}
        remark={getLang(lang, "paragraph.PUBLISH_LD_REMARK")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.PUBLISH_LD_QUESTION")}
      />
    </>
  );
}
