import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  CircularProgress, Button, Select, MenuItem
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import PropTypes from "prop-types";
import { BackButton } from "components/button";
import clsx from 'clsx';
import { getLang } from "app/feature/constants";
import TemplateInteractiveEditor from "../../components/templateInteractiveEditor";
import LanguageSelect from "../../../../components/select/languageSelect";
import { PlayIconSvg } from "../../utils/staticSvg";
import ComponentMenuPanel from "../../components/panel/componentMenuPanel";
import { ErrorMessage, Formik } from "formik";
import Input from "../../../../components/input/inputTextField";
import ProductSearch from "../../../../components/search/productSearch";
import ProductChips from "../../../../components/chips/productChips";

const useStyle = makeStyles(theme => ({
  root: {
    gridTemplateAreas: "'top .' 'main side'",
    gridTemplateColumns: "1fr 330px",
    [theme.breakpoints.up("lg")]: {
      display: "grid"
    },
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'top' 'main' 'side'",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2)
    }
  },
  fullWidthGrid: {
    gridTemplateAreas: "'top' 'main'",
    gridTemplateColumns: "1fr"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },

  sideBanner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  secondaryButton: {
    color: theme.palette.common.white,
  },
  panelContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    marginTop: 20,
  },
  componentPanel: {
    [theme.breakpoints.up("md")]: {
      maxWidth: '500px',
    },
  },
  workspacePanel: {
    flex: "0.9",
    [theme.breakpoints.up("md")]: {
      flex: "0.9",
      marginBottom: 0,
    },
  },
  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "4px 4px 0px 0px"
  },
  scroll: {
    width: "min-content"
  },
  tabContainer: {
    backgroundColor: "white",
    padding: "0px 20px",
    borderRadius: "6px 6px 0px 0px"
  },
  required: {
    "&::after": {
      content: "' *'",
      color: "#E21B1B",
      marginLeft: "3px"
    },
  },
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function EditTemplateComponent({
  currentLanguage,
  productLanguages,
  hasEditAccessRight,
  isLoading,
  lang,
  onSetLanguage,
  isSubmitting,
  handleSave,
  template,
  productDropdown,
  isFetchingProduct,
  customTemplateHtml,
  isPreviewingGenuine,
  onPreviewGenuineChange,
  isPreviewFetching,
  handlePreview,
  isTemplateLoading,
}) {
  const classes = useStyle();

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Box gridArea="top">
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <BackButton />
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.LANDING_PAGE_TEMPLATE")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.EDIT")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {!isLoading && (
        <Formik
          enableReinitialize
          initialValues={{
            name: template.name ?? '',
            products: (template.assignedProducts ?? []).map((product) => {
              return productDropdown.find((p) => p.id === product);
            }),
          }}
          validate={(values) => {
            const errors = {};
            const noSpacingRegex = /^[^\s]+$/;
            const nameError = noSpacingRegex.test(values.name)
              ? undefined
              : getLang(lang, "label.NO_SPACING_ALLOWED");
            if (nameError) {
              errors.name = nameError;
            }
            return errors;
          }}
          onSubmit={values => handleSave(values)}
        >
          {(formik) => (
            <>
              <Box p={2} mb={2} className={classes.searchContainer} style={{ padding: "10px", display: "block" }}>
                <form>
                  <Box display="flex">
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold", fontSize: 15, margin: "8px", display: "flex", lineHeight: "inherit" }}
                      className={clsx(classes.required, classes.fieldLabel)}
                    >
                      {getLang(lang, "label.NAME")}
                    </Typography>
                    <Input
                      hiddenLabel
                      varaint="filled"
                      placeholder={getLang(lang, "placeholder.ENTER_TEMPLATE_NAME")}
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#eceff0",
                          height: "36.5px"
                        },
                        classes: { input: classes.inputRoot }
                      }}
                      error={formik.touched.name && formik.errors.name}
                      helperText={ErrorMessage({
                        name: 'name'
                      })}
                      disabled={isSubmitting}
                      {...formik.getFieldProps("name")}
                      style={{ width: "25%" }}
                    />
                    {hasEditAccessRight && (
                      <Box
                        display="flex"
                        style={{ gap: 10, marginLeft: "auto", height: "36.5px" }}
                      >
                        <Button
                          variant="outlined"
                          disableElevation
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            formik.resetForm();
                          }}
                        >
                          {getLang(lang, "label.RESET")}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => formik.handleSubmit()}
                        >
                          {getLang(lang, "label.SAVE")}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </form>
              </Box>
              <Box className={clsx(classes.root, classes.panelContainer)}>
                <Box className={clsx(classes.workspacePanel, classes.componentPanel)} style={{ flex: 0.4 }}>
                  <Box>
                    <Box p={2} mb={2} style={{ backgroundColor: 'white' }}>
                      <Box mb={1}>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          {getLang(lang, "label.ASSIGNED_PRODUCTS")}:
                        </Typography>
                      </Box>
                      <ProductSearch
                        handleChange={(value) => formik.setFieldValue('products', value)}
                        isFetchingProduct={isFetchingProduct}
                        productDropdown={productDropdown}
                        value={formik.values.products}
                        disabled={isSubmitting}
                        editTemplate={true}
                      />
                      <Box mt={2} style={{ display: "flex", flexWrap: "wrap" }}>
                        {formik.values.products.length > 0 ? (
                          <ProductChips
                            value={formik.values.products}
                          />
                        )
                          : ""}
                      </Box>
                    </Box>
                    {!template.isCustom && (
                      <ComponentMenuPanel isTemplateEditor />
                    )}
                  </Box>
                </Box>
                <Box className={classes.workspacePanel}>
                  <Box gridArea="main">
                    <Box style={{
                      borderRadius: "6px 6px 0px 0px",
                      overflow: "hidden"
                    }}>
                      <Box style={{
                        backgroundColor: "white",
                        padding: "12px 16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}>
                        {template.isCustom ?
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", fontSize: 15 }}
                            >
                              {getLang(lang, "label.PAGE_WORKSPACE")}
                            </Typography>
                            <Box pl={2}>
                              <Select
                                value={isPreviewingGenuine}
                                onChange={onPreviewGenuineChange}
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#ffffff",
                                    height: "36.5px"
                                  },
                                  classes: { input: classes.inputRoot }
                                }}
                              >
                                <MenuItem value={1}>{getLang(lang, "label.GENUINE_PAGE")}</MenuItem>
                                <MenuItem value={0}>{getLang(lang, "label.SUSPENDED_PAGE")}</MenuItem>
                              </Select>
                            </Box>
                          </Box> :
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", fontSize: 15 }}
                            >
                              {getLang(lang, "label.PAGE_WORKSPACE")}
                            </Typography>
                          </Box>}
                        <Box display="flex">
                          {!template.isCustom ?
                            <Box px={1}>
                              <LanguageSelect
                                value={currentLanguage}
                                options={productLanguages || ["EN"]}
                                disabled={!productLanguages}
                                onSelect={(language) => {
                                  onSetLanguage(language);
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              />
                            </Box> : null}
                          <Button
                            className={classes.previewButton}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handlePreview}
                            startIcon={<PlayIconSvg color="white" />}
                          >
                            {getLang(lang, "label.PREVIEW_LAYOUT")}
                          </Button>
                        </Box>
                      </Box>
                      {isPreviewFetching ? (
                        <Box
                          gridArea="main" mt={6}
                          style={{
                            textAlign: "center"
                          }}
                        >
                          <CircularProgress color="primary" />
                        </Box>
                      ) : template.isCustom ? (
                        <Box
                          style={{
                            padding: "25px 0px",
                            backgroundColor: "#E4E7EC",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          {isTemplateLoading || !customTemplateHtml  ?
                            <Box
                              gridArea="main"
                              style={{
                                textAlign: "center"
                              }}
                            >
                              <CircularProgress color="primary" />
                            </Box> :
                            <iframe title="custom-template" scrolling="no" srcDoc={customTemplateHtml} width="100%" height="100%" style={{ border: "none", maxWidth: "370px" }} onLoad={(e) => e.target.style.height = e.target.contentWindow.document.body.scrollHeight + 8 + 'px'}></iframe>
                          }
                        </Box>
                      ) : (
                        <Box
                          style={{
                            padding: "25px 0px",
                            backgroundColor: "#E4E7EC",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <TemplateInteractiveEditor />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Formik>
      )}
      {isLoading && (
        <>
          <Box
            gridArea="main" mt={6}
            style={{
              textAlign: "center"
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        </>
      )}
    </>
  );
}
