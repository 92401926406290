import React from "react";
import {
  Box,
  Link,
  makeStyles,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import { StyledTableCell } from "../../table";
import DefaultImg from "../../../assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function ProductCountDetailPopoverComponent({
  products,
  itemTranslator,
  isOpen,
  anchorEl,
  handleOpen,
  handleClose,
  lang,
}) {
  const classes = useStyle();

  return (
    <StyledTableCell>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={products.length > 0 ? handleOpen : () => null}
      >
        {products.length > 0 ? (
          <Link
            color="secondary"
            href="#"
            onClick={(event) => event.preventDefault()}
          >
            {products.length.toLocaleString("en-US")}
          </Link>
        ) : (
          <Box>{products.length.toLocaleString("en-US")}</Box>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          maxHeight="80dvh"
          style={{ scrollbarWidth: "thin", overflow: "auto" }}
        >
          <MenuList>
            {products
              .map((product) => itemTranslator(product))
              .map((product, index) => (
                <Box key={index} px={2} py={1}>
                  <Box display="flex" alignItems="center">
                    <img
                      src={product.imageUrl ? product.imageUrl : DefaultImg}
                      style={{
                        width: "28px",
                        height: "28px",
                        marginRight: "0.5em",
                      }}
                      alt={product.id}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      pl={1}
                    >
                      {!!product.attributes && !!product.attributes.length ? (
                        <>
                          <Box
                            style={{
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="body2" display="inline">
                                {product.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                display="inline"
                                className={classes.attributeText}
                              >
                                {product.attributes.join(", ")}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          <Typography variant="body2">
                            {product.name}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {product.nodes && product.nodes.length > 0 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      px={1}
                      py={0.5}
                      mt={0.5}
                      style={{ backgroundColor: "#f4f4f4" }}
                    >
                      {product.nodes.map((node, index) => (
                        <Box key={index}>
                          <Typography variant="caption">{node.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
          </MenuList>
        </Box>
      </Popover>
    </StyledTableCell>
  );
}
