import { createAsyncThunk } from '@reduxjs/toolkit';
import productApi from 'app/api/product';
import axios from "axios";

export const PRODUCTS = "products";

export const createTemplate = createAsyncThunk(
  `${PRODUCTS}/createTemplate`,
  async (payload, { rejectWithValue }) => {
    return productApi.createTemplate(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const fetchTemplates = createAsyncThunk(
  `${PRODUCTS}/fetchTemplates`,
  async (payload, { rejectWithValue }) => {
    return productApi.fetchTemplates(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const fetchTemplate = createAsyncThunk(
  `${PRODUCTS}/fetchTemplate`,
  async (payload, { rejectWithValue }) => {
    return productApi.fetchTemplate(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteTemplate = createAsyncThunk(
  `${PRODUCTS}/deleteTemplate`,
  async (payload, { rejectWithValue }) => {
    return productApi.deleteTemplate(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const editTemplate = createAsyncThunk(
  `${PRODUCTS}/editTemplate`,
  async (payload, { rejectWithValue }) => {
    return productApi.editTemplate(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const editProductTemplate = createAsyncThunk(
  `${PRODUCTS}/editProductTemplate`,
  async (payload, { rejectWithValue }) => {
    return productApi.editProductTemplate(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const fetchTemplateSettings = createAsyncThunk(
  `${PRODUCTS}/fetchTemplateSettings`,
  async (payload, thunkApi) => {
    const url = thunkApi.getState().products.template.url;

    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    return await instance.get(url)
  }
);

export const fetchPreviewTemplateSettings= createAsyncThunk(
  `${PRODUCTS}/fetchPreviewTemplateSettings`,
  async (payload, thunkApi) => {
    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];
    return await instance.get(payload)
  }
);

export const fetchTemplateDropdown = createAsyncThunk(
  `${PRODUCTS}/fetchTemplateDropdown`,
  async (payload, { rejectWithValue }) => {
    return productApi.fetchTemplateDropdown()
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const fetchOverrideWarningList = createAsyncThunk(
  `${PRODUCTS}/fetchOverrideWarningList`,
  async (payload, { rejectWithValue }) => {
    return productApi.fetchOverrideWarningList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const fetchCustomTemplateHtml  = createAsyncThunk(
  `${PRODUCTS}/fetchCustomTemplateHtml`,
  async (payload, { rejectWithValue }) => {
    return productApi.fetchCustomTemplateHtml(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)
