import React from 'react'
import BonanzaListingTable from '../../table/bonanzaListingTable'
import { Box } from '@material-ui/core'
import WithProductGeneralSearch from "../../../../../components/search/withProductGeneralSearch";

export default function BonanzaListingPanelComponent({
  hasBonanzaAddAccessRight,
  hasBonanzaEditAccessRight,
  hasBonanzaActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreate,
  handleStatusChange,
  handleReload,
  paginationOptions,
  productDropdown,
  isFetchingProduct,
}) {
  return (
    <Box>
      <WithProductGeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        productDropdown={productDropdown}
        isFetching={isFetching}
        isFetchingProduct={isFetchingProduct}
      />
      <BonanzaListingTable
        hasBonanzaAddAccessRight={hasBonanzaAddAccessRight}
        hasBonanzaEditAccessRight={hasBonanzaEditAccessRight}
        hasBonanzaActivateOrDeactivateAccessRight={hasBonanzaActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </Box>
  )
}
