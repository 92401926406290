import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import CounterfeitTable from "../../table/counterfeitTable";
import { Formik } from "formik";
import DateRangePicker from "components/input/dateRangePicker";
import { getLang } from "app/feature/constants";
import clsx from "clsx";
import Input from "components/input/inputTextField";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootOrder: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function CounterfeitPanelComponent({
  isFetching,
  counterfeitReportData,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  isLoading,
  totalFiltered,
  handleReload,
  paginationOptions,
  lang,
  tableFilter,
  clearSearch,
  changeOrder
}) {
  const classes = useStyle();

  return (
    <Box pt={2}>
      <Formik
        initialValues={{
          search: tableFilter.search,
          dateRange:
            tableFilter.fromDate && tableFilter.toDate
              ? {
                  from: tableFilter.fromDate,
                  to: tableFilter.toDate,
                }
              : null,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootOrder },
                }}
                {...formik.getFieldProps("search")}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.REPORTED_DATE")}
                disabled={isFetching}
                disableFuture={true}
                className={clsx({
                  [classes.emptyField]:
                    !formik.getFieldProps("dateRange").value,
                })}
                id={"created-picker-dialog"}
                value={formik.getFieldProps("dateRange").value}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.getFieldProps("dateRange").value
                    ? "#A4A6A8"
                    : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.REPORTED_DATE")}
              />
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <CounterfeitTable
        isFetching={isFetching}
        counterfeitReportData={counterfeitReportData}
        rowsPerPage={tableFilter.rowsPerPage}
        page={tableFilter.page}
        search={tableFilter.search}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoading={isLoading}
        totalFiltered={totalFiltered}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
      />
    </Box>
  );
}
