import React from "react";
import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  TextField,
  Divider,
  Typography,
  // CircularProgress,
} from "@material-ui/core";
import { Formik } from "formik";
import FilterSvg from "assets/svg/filter.svg";
// import { DownloadSVG } from "modules/stockflow/utils/static"
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import BoxListTable from "modules/stockflow/components/table/boxListTable";
import clsx from "clsx";
//import StockProductSelect from "modules/stockflow/components/select/stockProductSelect";
import StockProductSearch from "modules/stockflow/components/select/stockProductSearch";
import DateRangePicker from "components/input/dateRangePicker";
import StockHolderSearch from "modules/stockflow/components/select/stockHolderSearch";
import { getLang } from "app/feature/constants";

// const API_HOST = process.env.REACT_APP_API_HOST_URL

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 2fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function BoxListingComponent({
  hasStockflowInventoryViewAccessRight,
  hasStockflowDealerViewAccessRight,
  boxes,
  isFetching,
  selectedIds,
  isSelectAll,
  selectedCount,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  language,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  handleSelectBox,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  token,
  paginationOption,
  tableFilter,
  changeOrder,
  isFilterOpen,
  setIsFilterOpen,
  // exportListing,
  isExporting,
  lang,
  resetFilter,
  handleViewDetail,
}) {
  const classes = useStyle();

  const columnDropdown = [
    { name: getLang(lang, "label.BOX_CODE"), value: "box" },
    { name: getLang(lang, "label.SERIAL_NUMBER"), value: "serial_number" },
    { name: getLang(lang, "label.SEQUENCE_NUMBER"), value: "sequence_no" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          searchColumn: tableFilter.searchColumn || columnDropdown[0].value,
          search: tableFilter.search,
          products: tableFilter.products,
          createdAt: tableFilter.createdAt,
          updateAt: tableFilter.updateAt,
          branch: tableFilter.branch,
          locatedAt: tableFilter.locatedAt,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Box display="flex" className={classes.dropdownSearchBox}>
                <TextField
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.searchColumn}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  {columnDropdown.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("searchColumn", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider orientation="vertical" className={classes.divider} />
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput,
                  }}
                  {...formik.getFieldProps("search")}
                />
              </Box>
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <StockProductSearch
                    placeholder={getLang(lang, "placeholder.PRODUCTS")}
                    value={formik.values.products}
                    handleChange={(value) => {
                      formik.setFieldValue("products", value);
                    }}
                    isEmpty={formik.values.products?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(product) => {
                      let temp = [...formik.values.products];
                      if (temp.length > 0) {
                        const filteredProducts = temp.filter(
                          (item) => item !== product
                        );
                        temp = [...filteredProducts];
                      }
                      formik.setFieldValue("products", temp);
                    }}
                  />
                  {/* <StockProductSearch
                      placeholder={getLang(lang, "placeholder.PRODUCT")}
                      value={formik.values.products}
                      handleChange={(value) => formik.setFieldValue("product", value)}
                      isEmpty={formik.values.products?.length === 0}
                      styles={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                      }}
                      handleChipDelete={(product) => {
                        let temp = [...formik.values.products];
                        if (temp.length > 0) {
                          const filteredProducts = temp.filter((item) => item !== product.id);
                          temp = [...filteredProducts];
                        }
                        formik.setFieldValue("product", temp);
                      }}
                    /> */}
                  <DateRangePicker
                    label=""
                    placeholder={getLang(lang, "placeholder.CREATED_DATE")}
                    disabled={isFetching}
                    disableFuture={false}
                    className={clsx({
                      [classes.emptyField]:
                        !formik.getFieldProps("createdAt").value,
                    })}
                    id={"created-picker-dialog"}
                    value={formik.getFieldProps("createdAt").value}
                    handleChange={(v) => {
                      formik.setFieldValue("createdAt", v);
                    }}
                    InputPropStyle={{
                      color: !formik.getFieldProps("createdAt").value
                        ? "#A4A6A8"
                        : "#000000",
                      fontWeight: "normal",
                    }}
                    inputProps={{
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  />
                  <DateRangePicker
                    label=""
                    placeholder={getLang(lang, "placeholder.LAST_UPDATED_DATE")}
                    disabled={isFetching}
                    disableFuture={false}
                    className={clsx({
                      [classes.emptyField]:
                        !formik.getFieldProps("updatedAt").value,
                    })}
                    id={"created-picker-dialog"}
                    value={formik.getFieldProps("updatedAt").value}
                    handleChange={(v) => {
                      formik.setFieldValue("updatedAt", v);
                    }}
                    InputPropStyle={{
                      color: !formik.getFieldProps("updatedAt").value
                        ? "#A4A6A8"
                        : "#000000",
                      fontWeight: "normal",
                    }}
                    inputProps={{
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  />
                  <BranchSearch
                    placeholder={getLang(lang, "placeholder.BRANCH")}
                    value={formik.values.branch}
                    handleChange={(value) =>
                      formik.setFieldValue("branch", value)
                    }
                    isEmpty={formik.values.branch?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(branch) => {
                      let temp = [...formik.values.branch];
                      if (temp.length > 0) {
                        const filteredBranchs = temp.filter(
                          (item) => item.node_id !== branch.node_id
                        );
                        temp = [...filteredBranchs];
                      }
                      formik.setFieldValue("branch", temp);
                    }}
                  />
                  {/* <StockHolderSelect
                      value={formik.values.locatedAt}
                      handleChange={(value) => formik.setFieldValue("locatedAt", value)}
                      placeholder={getLang(lang, "placeholder.LOCATED_AT")}
                      disabled={isFetching}
                      type="box"
                    /> */}
                  <StockHolderSearch
                    placeholder={getLang(lang, "placeholder.LOCATED_AT")}
                    value={formik.values.locatedAt}
                    handleChange={(value) => {
                      formik.setFieldValue("locatedAt", value);
                    }}
                    type="box"
                    isEmpty={formik.values.locatedAt?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(holder) => {
                      let temp = [...formik.values.locatedAt];
                      if (temp.length > 0) {
                        const filteredStockHolder = temp.filter(
                          (item) => item !== holder
                        );
                        temp = [...filteredStockHolder];
                      }
                      formik.setFieldValue("locatedAt", temp);
                    }}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={resetFilter}
                    disabled={isFetching}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {selectedCount !== 0 ? (
              isSelectAll && selectedCount === totalFiltered ? (
                <Typography variant="body2" style={{ color: "#FDB022" }}>
                  {getLang(lang, "paragraph.SELECTED_ALL_BOX", {
                    count: selectedCount,
                  })}
                </Typography>
              ) : (
                <Typography variant="body2" color="primary">
                  {selectedCount > 1
                    ? getLang(lang, "paragraph.SELECTED_BOXES_COUNT", {
                        count: selectedCount,
                      })
                    : getLang(lang, "paragraph.SELECTED_BOX_COUNT", {
                        count: selectedCount,
                      })}
                </Typography>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
          disableElevation
          onClick={() => {
            // exportListing()
          }}
          startIcon={isExporting ? <CircularProgress style={{ width: 10, height: 10 }}/> : <DownloadSVG />}
          disabled={isExporting || isFetching || (((!isSelectAll && !selectedIds.length) || (isSelectAll && selectedIds.length === totalFiltered)) && totalFiltered > 0)}
        >
          EXPORT XLS
        </Button> */}
      </Box>
      <BoxListTable
        hasStockflowInventoryViewAccessRight={
          hasStockflowInventoryViewAccessRight
        }
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        rows={boxes}
        isFetching={isFetching}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        language={language}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        handleSelectBox={handleSelectBox}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        isError={isError}
        token={token}
        paginationOption={paginationOption}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}
