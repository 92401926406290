import React from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import SearchableSelect from "components/select/searchableSelect";
import Input from "components/input/inputTextField";
import BranchSearch from "modules/stockflow/components/select/branchSearch"
//import BranchSelect from "modules/stockflow/components/select/branchSelect";
import HandoffListTable from "modules/warranty/components/table/handoffListTable";
import ExportToExcelSelect from "../../../../../components/select/exportToExcelSelect";
import DateRangePicker from "../../../../../components/input/dateRangePicker";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns:
      "minmax(0, 3.25fr) minmax(0, 5.5fr) minmax(0, 3fr) 3fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: "500"
  },
  paperRoot: {
    width: "217px",
  },
}));

export default function ReplacementHandoffPanelComponent({
  list,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  isExporting,
  handleExport,
}) {
  const classes = useStyle();

  const statusDropdown = [
    { name: "-", value: "" },
    { name: getLang(lang, "label.PENDING"), value: 1 },
    { name: getLang(lang, "label.APPROVED"), value: 2 },
    { name: getLang(lang, "label.REJECTED"), value: 3 },
  ];

  const exportDropdown = [
    { name: "Excel", value: "xlsx" },
  ];

  return (
    <Box gridArea="panel">
      <Formik
        initialValues={{
          branch: tableFilter.branch,
          search: tableFilter.search,
          status: tableFilter.status
            ? statusDropdown.find((v) => v.value === tableFilter.status)
            : null,
          dateRange:
            tableFilter.fromDate && tableFilter.toDate
              ? {
                from: tableFilter.fromDate,
                to: tableFilter.toDate,
              }
              : null,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={1} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_REFERENCE_CODE")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "38px",
                  },
                  classes: { input: classes.inputRootID },
                }}
                {...formik.getFieldProps("search")}
              />
             <BranchSearch
                placeholder={getLang(lang, "placeholder.BRANCH")}
                value={formik.values.branch}
                handleChange={(value) => formik.setFieldValue("branch", value)}
                isEmpty={formik.values.branch?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(branch) => {
                  let temp = [...formik.values.branch];
                  if (temp.length > 0) {
                    const filteredBranchs = temp.filter((item) => item.node_id !== branch.node_id);
                    temp = [...filteredBranchs];
                  }
                  formik.setFieldValue("branch", temp);
                }}
              />
              <SearchableSelect
                value={formik.values.status}
                handleChange={(value) => {
                  formik.setFieldValue("status", value);
                }}
                placeholder={getLang(lang, "placeholder.STATUS")}
                disabled={formik.isSubmitting || isFetching}
                searchable={false}
                panelStyle={{ width: "249px" }}
                PaperProps={{
                  classes: { root: classes.paperRoot },
                }}
                dropdownItem={statusDropdown}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                isEmpty={!formik.values.status}
                returnType="object"
                style={{
                  height: 38,
                }}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.RESPONSE_DATE")}
                disabled={isFetching}
                disableFuture={true}
                className={clsx({
                  [classes.emptyField]:
                    !formik.getFieldProps("dateRange").value,
                })}
                id={"created-picker-dialog"}
                value={formik.getFieldProps("dateRange").value}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.getFieldProps("dateRange").value
                    ? "#A4A6A8"
                    : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.RESPONSE_DATE")}
              />
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box display="flex" width="100%" justifyContent="end" alignItems="center" mb={1}>
        <ExportToExcelSelect
          disabled={isExporting || isFetching}
          dropdownItems={exportDropdown}
          isLoading={isExporting}
          handleSelect={(type) => handleExport(type)}
        />
      </Box>
      <HandoffListTable
        rows={list}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        page={page}
        language={language}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </Box>
  );
}
