import React from "react";

import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Tabs
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

import Dialog from "components/dialog/customDialog";
import CustomTab from "components/tab/customTab";
import ConsumerClaimDetailPanel from "../../panel/consumerClaimDetailPanel";
import ReimbursementDetailPanel from "../../panel/reimbursementDetailPanel";

const style = (theme) => ({
  header: {
    position: "sticky",
    top: 0,
    width: "100%",
    backgroundColor: "white",
    paddingTop: "4px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function ClaimDetailDialogComponent({
  isOpen,
  handleClose,
  handleTableReload,

  //  TABS  //
  activeIndex,
  handleChange,
  tabLabels,

  //  CLAIM TAB  //
  claimItem,
  handleClaimProcessed,

  //  REIMBURSEMENT TAB  //
  reimbursementItem,
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth="80%"
    >
      <Box className={classes.header}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>

        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box>
        <TabPanel value={activeIndex} index={0}>
          <ConsumerClaimDetailPanel
            claimItem={claimItem}
            handleProcessed={handleClaimProcessed}
          />
        </TabPanel>

        <TabPanel value={activeIndex} index={1}>
          <ReimbursementDetailPanel
            claimItem={claimItem}
            reimbursementItem={reimbursementItem}
            handleTableReload={handleTableReload}
          />
        </TabPanel>
      </Box>
    </Dialog>
  )
}