import React from "react";
import UnassignProductSNListTableComponent from "./unassignProductSNListTable.component";

// used in stocktake, transfer and receive transfer
export default function UnassignProductSNListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  unassign
}) {
  return (
    <UnassignProductSNListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      unassign={unassign}
    />
  );
}
