import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import {
  UNASSIGN_BATCH_STATUS_FAIL,
  UNASSIGN_BATCH_STATUS_PROCESSING,
  UNASSIGN_BATCH_STATUS_SUCCESS,
  UNASSIGN_BATCH_STATUS_SUCCESS_WARNING,
} from "modules/serial-number/constants";
import HelpIcon from "@material-ui/icons/Help";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function UnassignProductSNListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  unassign,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="sn table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "sequence_number"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order =
                          tableFilter.order === "asc" ? "desc" : "asc";
                        changeOrder("sequence_number", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SEQUENCE_NO")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SERIAL_NO")}
                  </StyledTableCell>
                  {(unassign.status === UNASSIGN_BATCH_STATUS_SUCCESS ||
                    unassign.status ===
                      UNASSIGN_BATCH_STATUS_SUCCESS_WARNING) && (
                    <StyledTableCell>
                      <Box display="flex" alignItems="center">
                        {getLang(lang, "label.STATUS")}
                        <Box ml={0.5} display="flex">
                          <LightTooltip
                            title={
                              <Box>
                                <Box style={{ color: "#FF0000" }}>
                                  <b>{getLang(lang, "label.DENIED")}</b>
                                </Box>
                                <Box>
                                  {getLang(
                                    lang,
                                    "tooltips.UNASSIGN_DENIED_MESSAGE"
                                  )}
                                </Box>
                              </Box>
                            }
                          >
                            <HelpIcon style={{ fontSize: 16, marginLeft: 6 }} />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={
                          unassign.status ===
                            UNASSIGN_BATCH_STATUS_PROCESSING ||
                          unassign.status === UNASSIGN_BATCH_STATUS_FAIL
                            ? 2
                            : 3
                        }
                      />
                    ))
                  : rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Typography variant="caption">
                              {row.sequence_number}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography variant="caption">
                              {row.serial_number}
                            </Typography>
                          </StyledTableCell>
                          {(unassign.status === UNASSIGN_BATCH_STATUS_SUCCESS ||
                            unassign.status ===
                              UNASSIGN_BATCH_STATUS_SUCCESS_WARNING) && (
                            <StyledTableCell>
                              <Typography
                                variant="caption"
                                style={{
                                  color:
                                    row.status === "failed"
                                      ? "#FF0000"
                                      : "#32D583",
                                }}
                              >
                                {row.status === "failed"
                                  ? getLang(lang, "label.DENIED")
                                  : getLang(lang, "label.SUCCESS")}
                              </Typography>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={tableFilter.rowsPerPage}
            page={tableFilter.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box style={{ boxShadow: "0px 3px 6px 2px #00000011" }}>
          <Table
            className={classes.table}
            aria-label="point table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.SEQUENCE_NO")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NO")}
                </StyledTableCell>
                {(unassign.status === UNASSIGN_BATCH_STATUS_SUCCESS ||
                  unassign.status ===
                    UNASSIGN_BATCH_STATUS_SUCCESS_WARNING) && (
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "message.info.NO_RECORD_EXIST")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
