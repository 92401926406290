import React from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  RemoveCircleOutlineOutlined as RemoveCircleIcon,
  AddCircleOutlineOutlined as AddCircleIcon,
  WarningRounded as WarningIcon,
} from "@material-ui/icons";
import AlertSvgIcon from "assets/svg/alert.svg";
import { getLang } from "app/feature/constants";
import { Field, Formik } from "formik";
import InputTextField from "components/input/inputTextField";
import GeneralDialog from "components/dialog/generalDialog";
import SnAvailabilityPreviewTable from "../../table/snAvailabilityPreviewTable";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    width: 1000,
    maxWidth: "95vw",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  form: {
    backgroundColor: "#F2F4F7",
    maxHeight: "77vh",
    overflowY: "auto",
    padding: 16,
  },
  squareButton: {
    height: 45,
    width: 45,
    minWidth: "fit-content",
    padding: 0,
    backgroundColor: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function RenderFormikField({
  name,
  onChange,
  value,
  disabled,
  placeholder,
  error,
  formik,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <InputTextField
              {...field}
              value={value}
              hiddenLabel={true}
              variant="filled"
              fullWidth
              type={formik.values.type === 2 ? "number" : "text"}
              size="small"
              disabled={disabled}
              onKeyDown={(e) => {
                if (formik.values.type === 2) {
                  const invalidChars = ["-", "+", "e", "."];
                  if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              placeholder={placeholder}
              InputStyle={{
                backgroundColor: "white",
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
              }}
              inputProps={{
                min: 0,
                // onBlur: () => {
                //   onBlur(name, index, value, range);
                // },
              }}
              error={error ? true : false}
              onChange={({ target: { value } }) => {
                if (formik.values.type === 2) {
                  const regex = /^[0-9]+$/;
                  if (value && !value.match(regex)) {
                    return;
                  }
                }

                onChange(value);
              }}
              helperText={error}
            />
          );
        }}
      </Field>
    </>
  );
}

export default function UnassignDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  lang,
  isSubmitting,
  validate,
  handlePreview,
  resetSNAvailabilityList,
  snAvailabilityList,
}) {
  const classes = useStyle();

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      // maxWidth="md"
      classes={{
        paper: classes.root,
      }}
      // fullWidth
    >
      <Paper elevation={0}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.UNASSIGN_SERIAL_NUMBER")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Box>
          <Formik
            initialValues={{
              type: 1,
              ranges: [
                {
                  from: "",
                  to: "",
                },
              ],
              quantity: 0,
              errors: { ranges: [] },
              hasOverlapped: false,
              showSaveConfirm: false,
              preview: false,
            }}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <DialogContent className={classes.form}>
                    <Box>
                      <Box mb={2}>
                        <Paper elevation={0}>
                          <Box p={2}>
                            <Box>
                              <Typography variant="body1">
                                <b>{getLang(lang, "label.RANGE")}</b>
                              </Typography>
                            </Box>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={formik.values.type}
                                onChange={({ target: { value } }) => {
                                  if (formik.values.type !== parseInt(value)) {
                                    formik.setFieldValue(
                                      "type",
                                      parseInt(value)
                                    );
                                    formik.setFieldValue("ranges", [
                                      {
                                        from: "",
                                        to: "",
                                      },
                                    ]);
                                    formik.setFieldValue("errors", {
                                      ranges: [],
                                    });
                                    formik.setFieldValue(
                                      "hasOverlapped",
                                      false
                                    );

                                    resetSNAvailabilityList();
                                    formik.setFieldValue("preview", false);
                                    formik.setFieldValue("quantity", 0);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value={1}
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography
                                      variant="body1"
                                      style={{ marginRight: 4 }}
                                    >
                                      {getLang(lang, "label.SERIAL_NUMBER")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value={2}
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography
                                      variant="body1"
                                      style={{ marginRight: 4 }}
                                    >
                                      {getLang(lang, "label.SEQUENCE_NUMBER")}
                                    </Typography>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                            {formik.values.ranges.map((range, i) => (
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                mt={1}
                                key={i}
                              >
                                <RenderFormikField
                                  name={`ranges.${i}.from`}
                                  onChange={(value) => {
                                    let ranges = [...formik.values.ranges];
                                    ranges[i] = {
                                      ...ranges[i],
                                      from: value,
                                    };
                                    formik.setFieldValue("ranges", ranges);

                                    let errors = [
                                      ...formik.values.errors.ranges,
                                    ];
                                    delete errors[i]?.from;
                                    formik.setFieldValue(
                                      "errors.ranges",
                                      errors
                                    );

                                    formik.setFieldValue(
                                      "hasOverlapped",
                                      false
                                    );
                                  }}
                                  placeholder={getLang(lang, "label.FROM")}
                                  value={range.from}
                                  formik={formik}
                                  type={formik.values.type}
                                  error={
                                    formik.values.errors.ranges.length >= i &&
                                    formik.values.errors.ranges[i]?.from
                                      ? formik.values.errors.ranges[i]?.from
                                      : null
                                  }
                                  disabled={isSubmitting}
                                />
                                <Box px={1.5} mt={0.5}>
                                  <Typography variant="h6">-</Typography>
                                </Box>
                                <RenderFormikField
                                  name={`ranges.${i}.to`}
                                  onChange={(value) => {
                                    let ranges = [...formik.values.ranges];
                                    ranges[i] = {
                                      ...ranges[i],
                                      to: value,
                                    };
                                    formik.setFieldValue("ranges", ranges);

                                    let errors = [
                                      ...formik.values.errors.ranges,
                                    ];
                                    delete errors[i]?.to;
                                    formik.setFieldValue(
                                      "errors.ranges",
                                      errors
                                    );

                                    formik.setFieldValue(
                                      "hasOverlapped",
                                      false
                                    );
                                  }}
                                  placeholder={getLang(lang, "label.TO")}
                                  value={range.to}
                                  formik={formik}
                                  type={formik.values.type}
                                  error={
                                    formik.values.errors.ranges.length >= i &&
                                    formik.values.errors.ranges[i]?.to
                                      ? formik.values.errors.ranges[i]?.to
                                      : null
                                  }
                                  disabled={isSubmitting}
                                />
                                <Box display="flex" mt={-0.5} ml={0.5}>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      let temp = [...formik.values.ranges];
                                      temp.splice(i, 1);
                                      formik.setFieldValue("ranges", temp);

                                      let errors = [
                                        ...formik.values.errors.ranges,
                                      ];
                                      errors.splice(i, 1);
                                      formik.setFieldValue(
                                        "errors.ranges",
                                        errors
                                      );

                                      formik.setFieldValue(
                                        "hasOverlapped",
                                        false
                                      );
                                    }}
                                    disabled={
                                      formik.values.ranges.length <= 1 ||
                                      isSubmitting
                                    }
                                  >
                                    <Box className={classes.squareButton}>
                                      <RemoveCircleIcon
                                        style={{
                                          color: "#F97066",
                                          opacity:
                                            formik.values.ranges.length <= 1
                                              ? 0.7
                                              : 1,
                                        }}
                                      />
                                    </Box>
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      let temp = [...formik.values.ranges];
                                      temp.push({
                                        from: "",
                                        to: "",
                                      });
                                      formik.setFieldValue("ranges", temp);
                                    }}
                                    disabled={isSubmitting}
                                  >
                                    <Box className={classes.squareButton}>
                                      <AddCircleIcon
                                        style={{ color: "#6AAF68" }}
                                      />
                                    </Box>
                                  </IconButton>
                                </Box>
                              </Box>
                            ))}
                            {formik.values.hasOverlapped && (
                              <Box m={1}>
                                <span
                                  style={{
                                    color: "#f44336",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {getLang(
                                    lang,
                                    "message.error.RANGES_OVRELAPPED"
                                  )}
                                </span>
                              </Box>
                            )}
                            <Box
                              mt={3}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Box display="flex" alignItems="center" mr={2}>
                                <WarningIcon
                                  style={{ fontSize: 20, marginRight: 8 }}
                                />
                                <Typography variant="caption">
                                  {getLang(
                                    lang,
                                    "message.info.UNASSIGN_REMARK"
                                  )}
                                </Typography>
                              </Box>
                              <Button
                                variant="contained"
                                color="secondary"
                                classes={{ root: classes.btnSecondary }}
                                onClick={() => {
                                  let valid = validate(formik);
                                  if (valid) {
                                    handlePreview(formik);
                                  }
                                }}
                                disabled={isSubmitting}
                              >
                                {getLang(lang, "label.PREVIEW")}
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                        {formik.values.preview && (
                          <Paper elevation={0} style={{ marginTop: 16 }}>
                            <SnAvailabilityPreviewTable
                              handleReload={handlePreview}
                              type={formik.values.type}
                              formik={formik}
                            />
                          </Paper>
                        )}
                      </Box>
                    </Box>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Box>
                      <Button
                        autoFocus
                        onClick={(e) => {
                          handleClose();
                        }}
                        disabled={isSubmitting}
                        color="primary"
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        autoFocus
                        disabled={
                          isSubmitting ||
                          !formik.values.quantity ||
                          !snAvailabilityList.length
                        }
                        onClick={() => {
                          let valid = validate(formik);
                          if (valid) {
                            // formik.handleSubmit();
                            formik.setFieldValue("showSaveConfirm", true);
                          }
                        }}
                      >
                        {isSubmitting
                          ? getLang(lang, "label.LOADING")
                          : getLang(lang, "label.SUBMIT")}
                      </Button>
                    </Box>
                  </DialogActions>

                  <GeneralDialog
                    isOpen={!!formik.values.showSaveConfirm}
                    handleClose={() => {
                      formik.setFieldValue("showSaveConfirm", false);
                    }}
                    handleProceed={() => {
                      formik.setFieldValue("showSaveConfirm", false);
                      formik.handleSubmit();
                    }}
                    title={getLang(lang, "label.UNASSIGN_SERIAL_NUMBER")}
                    description={getLang(
                      lang,
                      "paragraph.UNASSIGN_SN_QUESTION"
                    )}
                    type="success"
                    proceedLabel={getLang(lang, "label.CONFIRM")}
                    icon={<img src={AlertSvgIcon} alt="" />}
                  />
                </form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Dialog>
  );
}
