import React, { useRef } from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "12fr 3fr 3fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      justifySelf: "flex-end"
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: "500"
  }
}));

export default function GeneralSearchComponent({ handleSearch, isFetching, lang }) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Box p={2} mb={2} className={classes.searchContainer}>
      <InputTextField
        variant="filled"
        inputRef={textRef}
        size="small"
        placeholder={getLang(lang, "placeholder.SEARCH")}
        margin="none"
        InputProps={{
          disableUnderline: true,
          margin: "none",
          style: {
            borderRadius: "4px",
            backgroundColor: "#ECEFF0",
          },
          classes: { input: classes.inputRoot },
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !isFetching)
          handleSearch(textRef.current.value)
        }}
      />
      <Button
        color="primary"
        variant="text"
        size="small"
        disableElevation
        disabled={isFetching}
        onClick={() => handleSearch((textRef.current.value = ""))}
      >
        {getLang(lang, "label.CLEAR")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        size="small"
        disabled={isFetching}
        onClick={() => handleSearch(textRef.current.value)}
      >
        {getLang(lang, "label.SEARCH")}
      </Button>
    </Box>
  );
}
