import React from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  bannerContainer: {
    borderRadius: 8,
    position: "relative",
    width: "100%",
    height: "auto",
    "& img": {
      borderRadius: 8,
      width: "100%",
      height: "100%",
    },
  },
  divider: {
    borderBottom: "2px solid #f3f3f3",
    // boxShadow: "0px 2px 4px 0px #a5a5a5",
  },
  label: {
    background:
      "linear-gradient(90deg, #6BDA8E 0%, #4D98D9 34.8%, #7077F7 62.65%, #CB62F6 100%);",
    padding: "2px 8px",
    borderRadius: 8,
    textTransform: "uppercase",
    fontWeight: 900,
    color: "white",
    width: "fit-content",
    textShadow: "-1px 0 #67D099, 1px 0 #949BC8, 1px 0 #AA80DF, 1px 0 #C065F6",
  },
  prizeContainer: {
    backgroundColor: "#f2f2f2",
    borderRadius: 8,
    padding: 12,
    position: "relative",
    "& img": {
      width: "100%",
      height: "auto",
      borderRadius: 8,
      objectFit: "cover",
    },
  },
  prizeQty: {
    position: "absolute",
    top: -8,
    right: -8,
    background: "linear-gradient(90deg, #67D298 0%, #5D89E7 50%, #B767F6 100%)",
    borderRadius: 50,
    padding: 3,
    "& div": {
      background: "white",
      borderRadius: 50,
      minWidth: 35,
      aspectRatio: "1/1",
      padding: 4,
      color: "black",
    },
  },
  button: {
    backgroundColor: "white",
    padding: 8,
    boxShadow: "0px 4px 0px 0px #A28EB7",
    borderRadius: 28,
    width: "100%",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

export default function InstantWinDetailPreviewPanelComponent({
  instantWin,
  lang,
}) {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.divider} />
      {instantWin ? (
        <Box p={2} pb={3} style={{ color: "black", overflowX: "hidden" }}>
          <Box className={classes.bannerContainer}>
            <img src={instantWin.basicInfo?.image} alt="lucky-draw-img" />
          </Box>
          <Box mt={1}>
            <Box display="flex" alignItems="center" mb={0.5} flexWrap="wrap">
              <Box mr={1} mt={0.5}>
                <Typography variant="body1">
                  <b>{instantWin.basicInfo?.name}</b>
                </Typography>
              </Box>
              <Box className={classes.label} mt={0.5}>
                <Typography variant="body2">
                  <b>{getLang(lang, "label.INSTANT_WIN")}</b>
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1">
              {instantWin.basicInfo?.description}
            </Typography>
          </Box>

          {instantWin.basicInfo?.rules ? (
            <Box mt={2.5}>
              <Box display="flex" alignItems="center" mb={0.5}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.GAME_RULES")}</b>
                </Typography>
                <Box
                  borderRadius={50}
                  ml={1}
                  height={19}
                  width={19}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    backgroundColor: "#1A385EB2",
                    cursor: "pointer",
                  }}
                >
                  <ChevronRightRoundedIcon style={{ color: "white" }} />
                </Box>
              </Box>
            </Box>
          ) : null}
          <Box mt={3}>
            <Box mb={0.5}>
              <Typography variant="body1">
                <b>{getLang(lang, "label.OUR_PRIZES")}</b>
              </Typography>
            </Box>
            {instantWin.prizeInfo
              .filter((p) => !Number(p.is_default))
              .map((prize, i) => (
                <Box key={i} mt={i ? 3 : 0} className={classes.prizeContainer}>
                  <Box>
                    <img src={prize.image_url || DefaultImg} alt="prize" />
                  </Box>
                  <Box mt={1} mb={0.5}>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ color: "black" }}
                    >
                      <b>{prize.name}</b>
                    </Typography>
                  </Box>
                  {!!prize.quantity && (
                    <Box className={classes.prizeQty}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="body1">
                          <b>x{prize.quantity}</b>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>

          <Box my={4} display="flex" justifyContent="center">
            <Typography variant="caption" style={{ cursor: "pointer" }}>
              <u>{getLang(lang, "label.TERMS_AND_CONDITIONS")}</u>
            </Typography>
          </Box>
          <Box>
            <Button
              className={classes.button}
              style={{
                border: "1px solid black",
              }}
            >
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                <b>{getLang(lang, "label.PLAY_NOW")}</b>
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box px={1}>
          <Skeleton variant="text" height={25} />
          <Skeleton variant="text" height={25} />
          <Skeleton variant="text" height={25} />
          <Skeleton variant="text" height={25} />
        </Box>
      )}
    </>
  );
}
