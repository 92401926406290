import React from "react";
import {
  makeStyles,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import PageWorkspacePanel from "../pageWorkspacePanel";
import ComponentMenuPanel from "../componentMenuPanel";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary,
  },
  secondaryButton: {
    color: theme.palette.common.white,
  },
  panelContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    marginTop: 20,
  },
  workspacePanel: {
    flex: "0.9",
    marginBottom: 25,
    [theme.breakpoints.up("md")]: {
      flex: "0.9",
      marginBottom: 0,
    },
  },
}));

export default function LandingPagePanelComponent({
  hasProductEditAccessRight,
  handleNavigateEditor,
  isLoading,
  isError,
  hasLoaded,
  isEditorMode,
  handleCancel,
  handleSubmit,
  lang,
  template,
  templateAfter
}) {
  const classes = useStyles();

  return (
    <Box>
      {isLoading && (
        <Box
          style={{
            textAlign: "center",
            paddingTop: "40px",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {isError && getLang(lang, "paragraph.ERROR_IN_LANDING_PAGE")}
      {hasLoaded && !isLoading && (
        <Box display="flex" flexDirection="column">
          <Box className={classes.panelContainer}>
            <Box className={classes.workspacePanel} mr={3} style={{ flex: 0.4 }}>
              <ComponentMenuPanel />
            </Box>
            <Box className={classes.workspacePanel}>
              <PageWorkspacePanel />
            </Box>
          </Box>
          <Box mt="auto" style={{ backgroundColor: "white" }}>
            {hasProductEditAccessRight && (!templateAfter || templateAfter === -1) && (!template || template === -1) && (
              <>
                <Divider />
                {!isEditorMode ? (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    py={2}
                    px={3}
                    style={{ gap: 20 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      classes={{ root: classes.secondaryButton }}
                      disableElevation
                      type="button"
                      onClick={handleNavigateEditor}
                    >
                      {getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    py={2}
                    px={3}
                    style={{ gap: 20 }}
                  >
                    <Button
                      variant="outlined"
                      disableElevation
                      type="button"
                      onClick={handleCancel}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      classes={{ root: classes.secondaryButton }}
                      disableElevation
                      type="button"
                      onClick={handleSubmit}
                    >
                      {getLang(lang, "label.SAVE")}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
