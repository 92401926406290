import React from "react";
import { useSelector } from "react-redux";
import SetWinnerTableComponent from "./setWinnerTable.component";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { PRIZE_TYPE_PRESET } from "modules/lucky-draw-v2/constants";
export default function SetWinnerTableContainer({
  dialog,
  luckyDrawUuid,
  handleAddWinner,
  handleDelete,
  quantity,
  winners,
  handleChange,
  balance,
  index,
  lang,
  prizeList,
  prize,
  setPrizeList,
  excludeList,
  setExcludeList,
  isEditable,
  isUnlimited
}) {
  const hasInstantLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT)
  );
  const addWinner = async () => {
    handleAddWinner(index);
  };

  const handleAddWinnerIntoPrize = async (winnerIndex, serialNumber, enc) => {
    const info = {
      index: index,
      winnerIndex: winnerIndex,
      serialNumber: serialNumber,
      prize_type: PRIZE_TYPE_PRESET,
      enc: enc,
    };

    const updatedPrizeList = [...prizeList];
    const prize = updatedPrizeList[index];
    const updatedWinners = [...prize.winners];

    while (updatedWinners.length <= winnerIndex) {
      updatedWinners.push({});
    }

    updatedWinners[winnerIndex] = { ...updatedWinners[winnerIndex], ...info };
    updatedPrizeList[index] = { ...prize, winners: updatedWinners };

    let encs = updatedPrizeList.reduce((acc, prize) => {
      let winner = prize.winners;
      winner.forEach((element) => {
        if (element.enc) {
          acc.push({ enc: element.enc });
        }
      });

      return acc;
    }, []);

    setPrizeList(updatedPrizeList);
    setExcludeList(encs);
  };

  const deleteWinner = async (winnerIndex) => {
    handleDelete(index, winnerIndex);
  };
  return (
    <SetWinnerTableComponent
      luckyDrawUuid={luckyDrawUuid}
      hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
      dialog={dialog}
      quantity={quantity}
      winners={winners.map((winner) => {
        return {
          ...winner,
          serialNumber: winner.serialNumber,
        };
      })}
      handleChange={handleChange}
      balance={balance}
      handleAddWinner={addWinner}
      handleDelete={deleteWinner}
      handleAddWinnerIntoPrize={handleAddWinnerIntoPrize}
      lang={lang}
      prizeList={prizeList}
      prize={prize}
      excludeList={excludeList}
      isEditable={isEditable}
      isUnlimited={isUnlimited}
    />
  );
}
