import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { convertToNumberString } from "lib/generalUtility";
//import PerfectScrollbar from "react-perfect-scrollbar"
import { getLang } from "app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import RefreshTable from "components/table/refreshTable";
import HelpIcon from "@material-ui/icons/Help";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 16,
    background: "white",
    borderRadius: 4,
  },
  tableContainer: {
    maxHeight: 350,
    overflowY: "auto",
    border: "1px solid #D0D5DD",
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    maxHeight: 500,
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th": {
      backgroundColor: "white",
      color: "#98A2B3",
    },
  },
  greenLabel: {
    color: "#6AAF68",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  filterSection: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  filterBox: {
    height: 34,
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
  },
  paperRoot: {
    width: "217px",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  name: {
    whiteSpace: "normal",
  },
}));

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: "#3A4D54",
    color: "white",
    padding: 16,
  },
})(Tooltip);

export default function SnAvailabilityPreviewTableComponent({
  isLoading,
  error,
  list,
  handleReload,
  lang,
  type,
}) {
  const classes = useStyle();

  return (
    <Paper elevation={0} className={classes.container} id="preview-table">
      {!isLoading && error && <RefreshTable handleReload={handleReload} />}
      {(isLoading || !!list.length) && (
        <Box>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="sn table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px", background: "white" }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">
                      {getLang(lang, "label.NUM")}
                    </Typography>
                  </TableCell>
                  {type === 1 && (
                    <>
                      <TableCell>
                        <Box>
                          <Typography variant="body2">
                            <b>{getLang(lang, "label.SERIAL_NO")}</b>
                          </Typography>
                          <Typography variant="body2">
                            {getLang(lang, "label.FROM")}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell style={{ verticalAlign: "bottom" }}>
                        <Typography variant="body2">
                          {getLang(lang, "label.TO")}
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <Box>
                      <Typography variant="body2">
                        <b>{getLang(lang, "label.SEQUENCE_NO")}</b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.FROM")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ verticalAlign: "bottom" }}>
                    <Typography variant="body2">
                      {getLang(lang, "label.TO")}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.QUANTITY")}
                  </TableCell>
                  <TableCell>{getLang(lang, "label.PRODUCT")}</TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.STATUS")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow
                        key={index}
                        columnCount={type === 1 ? 8 : 6}
                      />
                    ))
                  : list.map((sn, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell>
                            <Typography color="textSecondary">
                              <b>{index + 1}</b>
                            </Typography>
                          </TableCell>
                          {type === 1 && (
                            <>
                              <TableCell>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="body2"
                                    style={{ marginRight: 8 }}
                                  >
                                    <b>{sn.from_sn}</b>
                                  </Typography>
                                  <Typography>
                                    <b>-</b>
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>{sn.to_sn}</b>
                                </Typography>
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="body2"
                                style={{ marginRight: 8 }}
                              >
                                <b>{sn.from_seq_num}</b>
                              </Typography>
                              <Typography>
                                <b>-</b>
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              <b>{sn.to_seq_num}</b>
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              color={
                                sn.quantity <= 0
                                  ? "textSecondary"
                                  : "textPrimary"
                              }
                              variant="body2"
                            >
                              <b>{convertToNumberString(sn.quantity)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {sn.product ? (
                                <Box display="flex" alignItems="center">
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    flex={1}
                                  >
                                    <Typography variant="body2">
                                      {sn.product.name}
                                    </Typography>
                                    {!!sn.product.attributes &&
                                      !!sn.product.attributes.length && (
                                        <Typography
                                          className={classes.attributeText}
                                          variant="caption"
                                        >
                                          {sn.product.attributes.join(", ")}
                                        </Typography>
                                      )}
                                  </Box>
                                </Box>
                              ) : (
                                "-"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {sn.is_available ? (
                              <Typography
                                style={{ color: "#32D583" }}
                                variant="body2"
                              >
                                {getLang(lang, "label.AVAILABLE")}
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Box mr={0.5}>
                                  <Typography
                                    style={{ color: "#98A2B3" }}
                                    variant="body2"
                                  >
                                    {getLang(lang, "label.SKIPPED")}
                                  </Typography>
                                </Box>
                                <StyledTooltip
                                  placement="top"
                                  title={
                                    <>
                                      <Box mb={0.5}>
                                        <Typography
                                          color="inherit"
                                          variant="body2"
                                        >
                                          {getLang(lang, "label.SKIPPED")}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                      >
                                        {getLang(
                                          lang,
                                          "paragraph.SN_SKIPPED_UNASSIGN_TOOLTIP"
                                        )}
                                      </Typography>
                                    </>
                                  }
                                >
                                  <HelpIcon fontSize="small" />
                                </StyledTooltip>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {!isLoading && !error && !list.length && (
        <Box>
          <Table
            className={classes.table}
            aria-label="sn table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <TableCell>{getLang(lang, "label.NUM")}</TableCell>
                {type === 1 && (
                  <>
                    <TableCell>
                      <Box>
                        <Typography variant="body2">
                          <b>{getLang(lang, "label.SERIAL_NO")}</b>
                        </Typography>
                        <Typography variant="body2">
                          {getLang(lang, "label.FROM")}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "bottom" }}>
                      <Typography variant="body2">
                        {getLang(lang, "label.TO")}
                      </Typography>
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <Box>
                    <Typography variant="body2">
                      <b>{getLang(lang, "label.SEQUENCE_NO")}</b>
                    </Typography>
                    <Typography variant="body2">
                      {getLang(lang, "label.FROM")}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ verticalAlign: "bottom" }}>
                  <Typography variant="body2">
                    {getLang(lang, "label.TO")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {getLang(lang, "label.QUANTITY")}
                </TableCell>
                <TableCell align="right">
                  {getLang(lang, "label.PRODUCT")}
                </TableCell>
                <TableCell align="right">
                  {getLang(lang, "label.STATUS")}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "paragraph.SN_AVAILABILITY_NO_RESULT")}
            </Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
