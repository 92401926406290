import React from "react";
import MarketplaceComponent from "./marketplace.component";
import { useSelector } from "react-redux";

export default function MarketplaceContainer({ id, isTemplateEditor }) {
  const { content, isVisible } = useSelector(state => isTemplateEditor
    ? state.products.template.workspaceSetting.setting.components[id]
    : state.productPage.components[id]);
  const lang = useSelector(state => state.constant.languages);

  return (
    isVisible && <MarketplaceComponent content={content} lang={lang}/>
  );
}
