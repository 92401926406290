import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import {ErrorMessage, Formik} from "formik"
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 500,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "16px",
    maxHeight: "calc(100vh - 260px)",
    overflowY: "auto",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
}))

export default function CreateTemplateDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  lang
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant='subtitle2'
            color='primary'
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.ADD_TEMPLATE")}
          </Typography>
          <IconButton color='primary' onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            name: "",
          }}
          validate={(values) => {
            const errors = {};
            const noSpacingRegex = /^[^\s]+$/;
            const nameError = noSpacingRegex.test(values.name)
              ? undefined
              : getLang(lang, "label.NO_SPACING_ALLOWED");
            if (nameError) {
              errors.name = nameError;
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={e => { e.preventDefault() }}>
              <Box>
                <Box className={classes.content}>
                  <Box display="flex" flexDirection="row" style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ marginBottom: "10px" }}
                    >
                      {getLang(lang,"label.TEMPLATE_NAME")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                    </Typography>
                    <Box pl={2}>
                      <Input
                        hiddenLabel
                        varaint="filled"
                        placeholder={getLang(lang,"placeholder.ENTER_TEMPLATE_NAME")}
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          style: {
                            borderRadius: "4px",
                            backgroundColor: "#eceff0",
                            height: "36.5px"
                          },
                          classes: { input: classes.inputRoot }
                        }}
                        style={{
                          width: "100%"
                        }}
                        error={formik.touched.name && formik.errors.name}
                        helperText={ErrorMessage({
                          name: 'name'
                        })}
                        {...formik.getFieldProps("name")}
                      />
                      <Typography variant="caption" color="textSecondary">
                        {getLang(lang,"paragraph.TEMPLATE_NAME_CONSTRAINT")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.buttonContainer}>
                  <Button
                    variant='contained'
                    disableElevation
                    color="secondary"
                    disabled={formik.values.name === ""}
                    onClick={formik.handleSubmit}
                  >
                    {getLang(lang,"label.ADD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  )
}
