import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Input from "components/input/inputTextField";
import FilterSvg from "assets/svg/filter.svg";
import { Formik } from "formik";
import clsx from "clsx";
import DateRangePicker from "components/input/dateRangePicker";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import StockoutListTable from "modules/stockflow/components/table/stockoutListTable";
//import StockoutRequestorSelect from "modules/stockflow/components/select/stockoutRequestorSelect";
import StockoutRequestorSearch from "modules/stockflow/components/select/stockoutRequestorSearch";
import StockoutReceiverSearch from "modules/stockflow/components/select/stockoutReceiverSearch";
import { getLang } from "app/feature/constants";
import StockoutCustomFieldSearchDialog from "modules/stockflow/components/dialog/stockoutCustomFieldSearchDialog";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1.6fr 1fr 1.6fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "1rem",
    fontWeight: 500,
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr)) 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  filterNewFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr)) 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function StockoutListingComponent({
  hasStockflowStockoutViewAccessRight,
  hasStockflowDealerViewAccessRight,
  type,
  language,
  stockouts,
  isFetching,
  selectedIds,
  isSelectAll,
  selectedCount,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  handleSelectStockout,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  paginationOptions,
  isExporting,
  isFilterOpen,
  setIsFilterOpen,
  tableFilter,
  changeOrder,
  lang,
  resetFilter,
  handleViewDetail,
  hasCustomFields,
}) {
  const classes = useStyle();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          dateRange: {
            from: tableFilter.fromDate,
            to: tableFilter.toDate,
          },
          branch: tableFilter.branch,
          respondDate: tableFilter.respondDate,
          stockoutFrom: tableFilter.stockoutFrom,
          stockoutTo: tableFilter.stockoutTo,
          customFields: tableFilter.customFields,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_STOCKOUT_ID")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootID },
                }}
                {...formik.getFieldProps("search")}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.STOCKOUT_DATE")}
                disabled={isFetching}
                disableFuture={false}
                className={clsx({
                  [classes.emptyField]:
                    !formik.getFieldProps("dateRange").value,
                })}
                id={"created-picker-dialog"}
                value={formik.getFieldProps("dateRange").value}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.getFieldProps("dateRange").value
                    ? "#A4A6A8"
                    : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.STOCKOUT_DATE")}
              />
              <Button
                style={{ padding: "3px 40px" }}
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={clsx({
                    [classes.filterFieldContainer]: type !== "new",
                    [classes.filterNewFieldContainer]: type === "new",
                  })}
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  <BranchSearch
                    placeholder={getLang(lang, "placeholder.BRANCH")}
                    value={formik.values.branch}
                    handleChange={(value) =>
                      formik.setFieldValue("branch", value)
                    }
                    isEmpty={formik.values.branch?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(branch) => {
                      let temp = [...formik.values.branch];
                      if (temp.length > 0) {
                        const filteredBranchs = temp.filter(
                          (item) => item.node_id !== branch.node_id
                        );
                        temp = [...filteredBranchs];
                      }
                      formik.setFieldValue("branch", temp);
                    }}
                  />
                  <StockoutRequestorSearch
                    value={formik.values.stockoutFrom}
                    handleChange={(value) =>
                      formik.setFieldValue("stockoutFrom", value)
                    }
                    placeholder={getLang(lang, "placeholder.STOCKOUT_FROM")}
                    type={type}
                    isEmpty={formik.values.stockoutFrom?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(requestor) => {
                      let temp = [...formik.values.stockoutFrom];
                      if (temp.length > 0) {
                        const filteredStockoutFrom = temp.filter(
                          (item) => item !== requestor
                        );
                        temp = [...filteredStockoutFrom];
                      }
                      formik.setFieldValue("stockoutFrom", temp);
                    }}
                  />
                  <StockoutReceiverSearch
                    value={formik.values.stockoutTo}
                    handleChange={(value) =>
                      formik.setFieldValue("stockoutTo", value)
                    }
                    placeholder={getLang(lang, "placeholder.STOCKOUT_TO")}
                    type={type}
                    isEmpty={formik.values.stockoutTo?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(receiver) => {
                      let temp = [...formik.values.stockoutTo];
                      if (temp.length > 0) {
                        const filteredStockoutTo = temp.filter(
                          (item) => item !== receiver
                        );
                        temp = [...filteredStockoutTo];
                      }
                      formik.setFieldValue("stockoutTo", temp);
                    }}
                  />
                  {/* <StockoutReceiverSelect
                      value={formik.values.stockoutTo}
                      handleChange={(value) =>
                        formik.setFieldValue("stockoutTo", value)
                      }
                      placeholder={getLang(lang, "placeholder.STOCKOUT_TO")}
                      disabled={isFetching}
                      type={type}
                    /> */}
                  {type !== "new" && (
                    <DateRangePicker
                      label=""
                      disabled={formik.isSubmitting || isFetching}
                      placeholder={getLang(lang, "placeholder.RESPOND_DATE")}
                      disableFuture={false}
                      id="date-picker"
                      className={clsx({
                        [classes.emptyField]:
                          !formik.getFieldProps("respondDate").value,
                      })}
                      value={formik.values.respondDate}
                      handleChange={(value) => {
                        formik.setFieldValue("respondDate", value);
                      }}
                      InputPropStyle={{
                        color: !formik.getFieldProps("respondDate").value
                          ? "#A4A6A8"
                          : "#000000",
                        fontWeight: "normal",
                      }}
                      inputProps={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    />
                  )}
                  {hasCustomFields && (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection={isSmallScreen ? "column" : "row"}
                      pl={isSmallScreen ? 0 : 2}
                    >
                      <Typography
                        variant="body2"
                        style={{ textWrap: "nowrap", paddingRight: 8 }}
                      >
                        <b>{getLang(lang, "label.CUSTOM_FIELDS")}</b>
                      </Typography>
                      <StockoutCustomFieldSearchDialog
                        customFields={formik.values.customFields}
                        disabled={isFetching}
                        handleChange={(values) => {
                          formik.setFieldValue("customFields", values);
                        }}
                        handleClear={() => {
                          formik.setFieldValue("customFields", []);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    disabled={isFetching}
                    onClick={resetFilter}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {selectedCount !== 0 ? (
              isSelectAll && selectedCount === totalFiltered ? (
                <Typography variant="body2" style={{ color: "#FDB022" }}>
                  {getLang(lang, "paragraph.SELECTED_ALL_RECORDS", {
                    count: selectedCount,
                  })}
                </Typography>
              ) : (
                <Typography variant="body2" color="primary">
                  {selectedCount > 1
                    ? getLang(lang, "paragraph.SELECTED_RECORDS_COUNT", {
                        count: selectedCount,
                      })
                    : getLang(lang, "paragraph.SELECTED_RECORD_COUNT", {
                        count: selectedCount,
                      })}
                </Typography>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
          disableElevation
          onClick={() => {
            // exportListing()
          }}
          startIcon={isExporting ? <CircularProgress style={{ width: 10, height: 10 }}/> : <DownloadSVG />}
          disabled={isExporting || isFetching || (((!isSelectAll && !selectedIds.length) || (isSelectAll && selectedIds.length === totalFiltered)) && totalFiltered > 0)}
        >
          EXPORT XLS
        </Button> */}
      </Box>
      <StockoutListTable
        hasStockflowStockoutViewAccessRight={
          hasStockflowStockoutViewAccessRight
        }
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        type={type}
        rows={stockouts}
        isFetching={isFetching}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        isError={isError}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        language={language}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        handleSelectStockout={handleSelectStockout}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}
