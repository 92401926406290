import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/detail";

const getLuckyDrawDetails = (uuid) => {
  const params = new URLSearchParams([['uuid', uuid]]);

  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, { params })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getLuckyDrawDetails;
