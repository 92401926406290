import React from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import {getLang} from "../../../app/feature/constants";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: "auto"
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6
  }
}));

function LanguageSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  handleClick,
  id,
  style,
  options,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  lang,
}) {
  const classes = useStyle();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      <Box className={classes.perfectScrollBar}>
        <Box>
          <MenuList
            id='menu-list-grow'
            className={classes.list}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => handleClick(option)}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 8 }}>
                    {getLang(lang, `constant.language.${option.toUpperCase()}`)}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Box>
    </Popover>
  )
}

export default LanguageSelectPanelComponent
