import React from "react"
import { makeStyles, Typography, Box, Tabs } from "@material-ui/core"
import CustomTab from "components/tab"
import GiftRedemptionListPanel from "../giftRedemptionListPanel"
import LoyaltyPointTable from "../../table/loyaltyPointHistoryTable"
import { getLang } from "app/feature/constants"

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px"
  },
}))

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

export default function GiftPointRedemptionPanelComponent({ 
  tabLabels,
  activeIndex,
  handleChange,
  lang
}) {
  const classes = useStyles()

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" style={{ textTransform: "none" }} children={label} />,
    }
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={1} px={3}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {activeIndex !== null && tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />
          })}
        </Tabs>
      </Box>
      <Box
        style={{
          position: "relative",
        }}
      >
        {tabLabels.map((item, index) => {
          if (item === getLang(lang, "label.GIFT_REDEMPTION")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <GiftRedemptionListPanel />
              </TabPanel>
            )
          }
          if (item === getLang(lang, "label.LOYALTY_POINT_HISTORY")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <LoyaltyPointTable />
              </TabPanel>
            )
          }
          return null
        })}
      </Box>
    </>
  )
}
