import React, { useState, useEffect } from "react";
import TiktokComponent from "./tiktok.component";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";

export default function TiktokContainer({ id, isTemplateEditor }) {
  const {
    isVisible,
    content: { URL }
  } = useSelector(state => isTemplateEditor
    ? state.products.template.workspaceSetting.setting.components[id]
    : state.productPage.components[id]);

  const [isFetchingEmbed, setIsFetchingEmbed] = useState(true);
  const [embedData, setEmbedData] = useState(null);

  useEffect(() => {
    if (!URL) {
      setIsFetchingEmbed(false);
      return;
    }
    (async function () {
      setIsFetchingEmbed(true);
      const response = await fetch(
        `https://www.tiktok.com/oembed?url=${URL}`
      ).then(res => res.json());
      setEmbedData(response.html);
      setIsFetchingEmbed(false);
    })();
  }, [URL]);

  if (URL === "")
    return <SkeletonFrame />;
  else
    return (
      isVisible && (
        <TiktokComponent
          isFetchingEmbed={isFetchingEmbed}
          embedData={embedData}
        />
      )
    );
}

function SkeletonFrame() {
  return (
    <div
      style={{
        backgroundColor: "white",
        border: "1px solid lightgrey",
        borderRadius: 10,
        marginBottom: 16,
        minHeight: "max-content",
        display: "flex",
        flexFlow: "column nowrap",
        overflow: "hidden"
      }}
    >
      <Skeleton variant="rect" height={170} animation={false} />
      <div style={{ padding: "16px" }}>
        <Skeleton variant="rect" animation={false} height={50} />
      </div>
    </div>
  );
}
