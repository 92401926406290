import React from "react"
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Radio,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from 'components/tablePagination';
import RefreshTable from 'components/table/refreshTable';
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import moment from "moment";
import ProductChips from "../../../../../components/chips/productChips";

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th:first-child, td:first-child": {
      width: 37,
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#4BCB0F"
  },
  deactiveColor: {
    color: "#9CA6A9"
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  }
}));

export default function LandingPageTemplateListTableComponent({
  hasEditAccessRight,
  hasDeleteAccessRight,
  data,
  isFetching,
  selectedId,
  paginationOptions,
  isError,
  search,
  handleReload,
  page,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleSelect,
  handleDelete,
  handleEdit,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell padding='checkbox' />
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.TYPE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ASSIGNED_PRODUCT")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.LAST_UPDATED")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={5} />
                ))
              ) : data.map((item, index) => {
                const actionItems = []

                actionItems.push(
                  {
                    label: getLang(lang, "label.EDIT"),
                    disabled: !hasEditAccessRight,
                    action: () => handleEdit(item.id)
                  },
                  {
                    label: getLang(lang, "label.DELETE"),
                    divider: true,
                    errorText: true,
                    action: () => handleDelete(item.id),
                    disabled: !hasDeleteAccessRight || item.productCount > 0,
                    tooltip: item.productCount > 0 ? getLang(lang, "tooltips.CANNOT_DELETE_TEMPLATE", { count: item.productCount }) : null
                  }
                )

                return (
                  <StyledTableRow key={index} hover tabIndex={-1}>
                    <StickyLeftTableCell>
                      <StyledTableCell padding='checkbox'>
                        <Radio
                          checked={selectedId?.id === item.id}
                          onClick={() => handleSelect(item)}
                        />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                    <StyledTableCell>{item.name.length > 20 ? `${item.name.substring(0, 20)}...` : item.name}</StyledTableCell>
                    <StyledTableCell>{getLang(lang, item.type)}</StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      <Box style={{ display: "flex", flexWrap: "wrap" }}>
                        {item.assignedProduct.length > 0 ? (
                          <ProductChips
                            value={item.assignedProduct}
                          />
                        )
                          : "-"}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{moment(item.lastUpdated).format('DD MMMM YYYY HH:mm:ss')}</StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isError || !isFetching ? (
        <Box>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.TYPE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ASSIGNED_PRODUCT")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.LAST_UPDATED")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {search ? getLang(lang, "paragraph.TEMPLATE_NOT_EXIST") : getLang(lang, "paragraph.NO_TEMPLATE_CREATED")}
            </Typography>
            {!search ? (
              <Box mt={2}>
                <Button
                  variant='contained'
                  disableElevation
                  color="primary"
                  style={{ textTransform: "uppercase" }}
                  onClick={() => handleCreate()}
                >
                  {getLang(lang, "label.ADD_TEMPLATE")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>


      ) : null}

      {!isFetching && isError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalFiltered}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
