/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SnAvailabilityPreviewTableComponent from "./snAvailabilityPreviewTable.component";
import { useDispatch, useSelector } from "react-redux";
import {
  checkSNUnassignAvailability,
  resetSNUnassignAvailabilityList,
} from "modules/serial-number/redux";
import { selectLoading } from "modules/notification";

function SnAvailabilityPreviewTableContainer({ handleReload, type, formik }) {
  const dispatch = useDispatch();
  const snAvailabilityList = useSelector(
    (state) => state.assignBatch.snAvailabilityList.data
  );
  const isError = useSelector(
    (state) => state.assignBatch.snAvailabilityList.isError
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, checkSNUnassignAvailability.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    return () => {
      dispatch(resetSNUnassignAvailabilityList());
    };
  }, []);

  useEffect(() => {
    if (snAvailabilityList.length) {
      let totalQty = snAvailabilityList.reduce((total, sn) => {
        if (sn.is_available) {
          total += sn.quantity;
        }
        return total;
      }, 0);
      formik.setFieldValue("quantity", totalQty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snAvailabilityList]);

  return (
    <SnAvailabilityPreviewTableComponent
      isLoading={isLoading}
      error={isError}
      list={snAvailabilityList}
      handleReload={handleReload}
      lang={lang}
      type={type}
    />
  );
}

export default SnAvailabilityPreviewTableContainer;
