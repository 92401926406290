import getAllInstantLuckyDraw from "./getAllInstantLuckyDraw.api";
import getAllParticipant from "./getAllParticipant.api";
import getParticipantDetail from "./getParticipantDetail.api";
import changeStatus from "./changeStatus.api";
import getAllPrize from "./getAllPrize.api";
import getAllPrizeLegacy from "./legacy/getAllPrizeLegacy.api";
import prize from "./legacy/prize.api";
import getAllSerialNumber from "./getAllSerialNumber.api";
import searchSerialNumber from "./searchSerialNumber.api";
import adjustQuantity from "./legacy/adjustQuantity.api";
import prizeStatus from "./legacy/prizeStatus.api";
import winner from "./legacy/winner.api";
import getWinnerExcel from "./getWinnerExcel.api";
import getParticipantExcel from "./getParticipantExcel.api";
import changeProgress from "./legacy/changeProgress.api";
import giftCodeExcel from "./legacy/giftCodeExcel.api";
import createInstantLuckyDraw from "./createInstantLuckyDraw.api";
import updateInstantLuckyDrawPrizes from "./updateInstantLuckyDrawPrizes.api";
import presetWinner from "./presetWinner.api";
import getInstantLuckyDrawDetail from "./getInstantLuckyDrawDetail.api";
import publishInstantLuckyDraw from "./publishInstantLuckyDraw.api";
import updateInstantLuckyDraw from "./updateInstantLuckyDraw.api";

const instantLuckyDrawApi = {
  getAllInstantLuckyDraw,
  getAllParticipant,
  getParticipantDetail,
  changeStatus,
  getAllPrize,
  getAllPrizeLegacy,
  prize,
  getAllSerialNumber,
  searchSerialNumber,
  adjustQuantity,
  prizeStatus,
  winner,
  getWinnerExcel,
  getParticipantExcel,
  changeProgress,
  giftCodeExcel,
  createInstantLuckyDraw,
  updateInstantLuckyDrawPrizes,
  presetWinner,
  getInstantLuckyDrawDetail,
  publishInstantLuckyDraw,
  updateInstantLuckyDraw,
};

export default instantLuckyDrawApi;
