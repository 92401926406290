import React from "react";
import {
  Dialog,
  makeStyles,
  Box,
  Button, DialogContent, DialogContentText, DialogActions, Avatar, Chip, Typography
} from "@material-ui/core";
import {getLang} from "../../../../../app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "30px 25px",
    minWidth: 400,
    borderRadius: 12,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogTitle: {
    paddingTop: 0,
    paddingBottom: 10,
    color: "#101828",
    fontWeight: "500",
    fontSize: "18px !important",
  },
  customDialogPaperWithXs: {
    maxWidth: 360,
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5,
  },
  dialogRemarks: {
    color: "#FD646F",
  },
  cancelButton: {
    border: "1px solid #D0D5DD",
    color: "#344054",
  },
  dangerProccedButton: {
    color: "white",
    background: "#F04438",
    "&:hover": {
      background: "#F04438",
    },
  },
  successProceedButton: {
    color: "white",
    background: "#6AAF68",
    "&:hover": {
      background: "#6AAF68",
    },
  },
  iconContainer: {
    display: "flex",
    marginTop: 12,
    marginBottom: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.15em",
    marginTop: "0.15em",
    border: "none",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
  },
}));

export default function DeleteTemplateDialogComponent({
  isOpen,
  handleClose,
  handleConfirm,
  lang,
  products,
  name,
}) {
  const classes = useStyle();

  return (
    <Dialog
      classes={{
        paper: classes.root,
        paperWidthXs: classes.customDialogPaperWithXs,
      }}
      open={!!isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      maxWidth="sm"
    >
      <DialogContent>
        <Box pb={1}>
          <DialogContentText>
            <Typography variant="body3" style={{wordWrap: "break-word"}}>
              {products.length > 0 ? getLang(lang, "paragraph.DELETE_TEMPLATE_PRODUCT_1") : getLang(lang, "paragraph.DELETE_TEMPLATE", {name})}
            </Typography>
          </DialogContentText>
        </Box>
        {products.length > 0 && (
          <>
            <Box pb={2}>
              {products.map(e => (
                <Chip
                  avatar={<Avatar alt="Product" src={e.picture} />}
                  variant="outlined"
                  label={e.name}
                  size="small"
                  className={classes.chip}
                />
              ))}
            </Box>
            <Box pb={2}>
              <DialogContentText>
                <Typography variant="body3" style={{wordWrap: "break-word"}}>
                  {getLang(lang, "paragraph.DELETE_TEMPLATE", {name})}
                </Typography>
              </DialogContentText>
            </Box>
            <Box>
              <DialogContentText classes={{ root: classes.description }}>
                {getLang(lang, "paragraph.DELETE_TEMPLATE_PRODUCT_2")}
              </DialogContentText>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.customDialogActionRoot }}>
        <Button
          style={{ height: 44 }}
          fullWidth
          variant="outlined"
          onClick={handleClose}
          classes={{root: classes.cancelButton}}
        >
          {getLang(lang, "label.CANCEL")}
        </Button>
        <Button
          style={{ height: 44 }}
          fullWidth
          onClick={handleConfirm}
          variant="contained"
          className={classes.dangerProccedButton}
        >
          {getLang(lang, "label.CONFIRM")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
