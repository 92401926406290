import axios from "axios";

const getAllSerialNumber = ({
  uuid,
  length,
  start,
  search,
  order,
  column,
  draw,
  status,
  exclude,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/serialnumbers/campaign/${uuid}`, {
        params: {
          length: length,
          start: start,
          search: search,
          order: order,
          column: column,
          draw: draw,
          status: status,
          enc: exclude
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAllSerialNumber;
