import React from "react";
import {
  Box,
  Button,
  Divider,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { Formik } from "formik";
import { getLang } from "../../../../../app/feature/constants";
import FilterSvg from "assets/svg/filter.svg";
import { capitalizeEveryWord } from "lib/helper";

import SearchableSelect from "components/select/searchableSelect";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import ProductSearch from "modules/stockflow/components/select/productSearch";
import WarrantyReimbursementListTable from "../../table/warrantyReimbursementListTable";
import ClaimDetailDialog from "../../dialog/claimDetailDialog";
import ExportExcelSelect from "modules/warrantee/components/select/exportExcelReimbursement";

const style = (theme) => ({
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
});

const useStyles = makeStyles(style);

export default function BranchClaimPanelComponent({
  lang,

  //  SEARCH & FILTER  //
  pageSetting,
  language,
  handleSearch,
  isFilterOpen,
  setIsFilterOpen,
  productDropdown,

  //  TABLE LIST  //
  isFetching,
  rows,
  handleReload,

  //  DETAIL MODEL  //
  handleViewModel,
  handleCloseModel,

  // EXPORT //
  isExporting,
  handleExport,

  //  PAGINATION  //
  page,
  length,
  rowPerPage,
  rowPerPageOption,
  handlePageChange,
  handleRowPerPageChange,
}) {
  const classes = useStyles();

  const columnDropdown = [
    // { name: `${getLang(lang, "label.WARRANTY_REIMBURSEMENT")} ${getLang(lang, "label.REFERENCE_CODE")}`, value: "warranty_reimbursement_reference_code" },
    { name: `${getLang(lang, "label.WARRANTEE_CLAIM")} ${getLang(lang, "label.REFERENCE_CODE")}`, value: "warrantee_claim_reference_code" },
  ];

  const statusDropdown = [
    { name: getLang(lang, "label.ALL"), value: "" },
    { name: getLang(lang, "label.PENDING"), value: "pending" },
    { name: getLang(lang, "label.APPROVED"), value: "approved" },
    { name: getLang(lang, "label.REJECTED"), value: "rejected" },
  ];

  const exportDropdown = [
    { name: "Excel", value: "xlsx" },
  ];


  return (
    <>
      <Box display="flex" justifyContent="flex-end" marginBottom="1em">
        <ExportExcelSelect
          disabled={isExporting || isFetching}
          dropdownItem={exportDropdown}
          isLoading={isExporting}
          handleSelection={(type) => handleExport(type)}
        />
      </Box>
      <Formik
        initialValues={{
          search: pageSetting.search,
          searchColumn: pageSetting.searchColumn,
          branch: pageSetting.branch,
          product: pageSetting.product,
          status: pageSetting.status,
        }}
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Box display="flex" className={classes.dropdownSearchBox}>
                <TextField
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.searchColumn}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  {columnDropdown.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("searchColumn", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider orientation="vertical" className={classes.divider} />
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput,
                  }}
                  {...formik.getFieldProps("search")}
                />
              </Box>

              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                disabled={isFetching}
                onClick={() =>
                  formik.resetForm({
                    values: {
                      search: "",
                      searchColumn: "warranty_reimbursement_reference_code",
                      branch: formik.values.branch,
                      product: formik.values.product,
                      status: formik.values.status,
                    },
                  })
                }
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>

            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box>
                  <Box
                    className={classes.filterFieldContainer}
                    style={{ marginBottom: "12px" }}
                  >
                    <BranchSearch
                      placeholder={getLang(lang, "placeholder.BRANCH")}
                      value={formik.values.branch}
                      handleChange={(value) => formik.setFieldValue("branch", value)}
                      isEmpty={formik.values.branch?.length === 0}
                      styles={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                      }}
                      handleChipDelete={(branch) => {
                        let temp = [...formik.values.branch];
                        if (temp.length > 0) {
                          const filteredBranchs = temp.filter((item) => item.node_id !== branch.node_id);
                          temp = [...filteredBranchs];
                        }
                        formik.setFieldValue("branch", temp);
                      }}
                    />
                    <ProductSearch
                      searchable={true}
                      value={formik.values.product}
                      placeholder={getLang(lang, 'placeholder.SEARCH_BY_PRODUCT')}
                      searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
                      isClearable={false}
                      emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
                      dropdownItem={productDropdown}
                      isFetching={false}
                      isEmpty={formik.values.product?.length === 0}
                      selectedProduct={formik.values.product}
                      style={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                      }}
                      handleSelectAll={(selectAll) => {
                        if (selectAll) {
                          formik.setFieldValue("product", productDropdown)
                        } else {
                          formik.setFieldValue("product", [])
                        }
                      }}
                      handleChange={(value) => {
                        let temp = [...formik.values.product]

                        if (temp.length === 0) {
                          temp.push(value)
                        } else {
                          const isProductExist = temp.find((product) => product.id === value.id)

                          if (isProductExist === undefined) {
                            temp.push(value)
                          } else {
                            let filter = temp.filter((product) => product.id !== value.id)

                            temp = [...filter]
                          }
                        }
                        formik.setFieldValue("product", [...temp])
                      }}
                      handleChipDelete={(product) => {
                        let temp = [...formik.values.product];
                        if (temp.length > 0) {
                          const filteredProducts = temp.filter((item) => item.id !== product.id);
                          temp = [...filteredProducts];
                        }
                        formik.setFieldValue("product", temp);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      disabled={false}
                    />
                    {/* <ProductSelect
                      searchable={true}
                      value={formik.values.product}
                      placeholder={getLang(
                        lang,
                        "placeholder.SEARCH_BY_PRODUCT"
                      )}
                      searchPlaceholder={getLang(
                        lang,
                        "placeholder.SEARCH_A_PRODUCT_NAME"
                      )}
                      isClearable={false}
                      emptyMessage={getLang(
                        lang,
                        "message.info.NO_PRODUCT_FOUND"
                      )}
                      dropdownItem={productDropdown}
                      isFetching={false}
                      isEmpty={formik.values.product?.length === 0}
                      selectedProduct={formik.values.product}
                      style={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        height: "2.5rem",
                        overflow: "hidden",
                      }}
                      handleSelectAll={(selectAll) => {
                        if (selectAll) {
                          formik.setFieldValue("product", productDropdown);
                        } else {
                          formik.setFieldValue("product", []);
                        }
                      }}
                      handleChange={(value) => {
                        let temp = [...formik.values.product];

                        if (temp.length === 0) {
                          temp.push(value);
                        } else {
                          const isProductExist = temp.find(
                            (product) => product.id === value.id
                          );

                          if (isProductExist === undefined) {
                            temp.push(value);
                          } else {
                            let filter = temp.filter(
                              (product) => product.id !== value.id
                            );

                            temp = [...filter];
                          }
                        }
                        formik.setFieldValue("product", [...temp]);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      disabled={false}
                    /> */}

                    <SearchableSelect
                      value={capitalizeEveryWord(formik.values.status)}
                      isEmpty={formik.values.status === ""}
                      handleChange={(value) => {
                        formik.setFieldValue("status", value);
                      }}
                      //placeholder={getLang(lang, "placeholder.SELECT_STATUS")}
                      placeholder={getLang(lang, "placeholder.STATUS")}
                      disabled={isFetching}
                      searchable={false}
                      style={{ height: "2.5rem" }}
                      panelStyle={{ width: "249px" }}
                      PaperProps={{
                        classes: { root: classes.paperRoot },
                      }}
                      dropdownItem={statusDropdown}
                      emptyMessage={getLang(lang, "paragraph.STATUS_NOT_FOUND")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      returnType="value"
                    />
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="text"
                      disableElevation
                      color="secondary"
                      size="small"
                      disabled={isFetching}
                      onClick={() =>
                        formik.resetForm({
                          values: {
                            search: formik.values.search,
                            searchColumn: formik.values.searchColumn,
                            branch: [],
                            product: [],
                            status: "",
                          },
                        })
                      }
                      style={{
                        marginRight: "8px",
                      }}
                    >
                      {`${getLang(lang, "label.CLEAR")} ${getLang(
                        lang,
                        "label.FILTER"
                      )}`}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <WarrantyReimbursementListTable
        lang={lang}
        language={language}
        //  TABLE LIST  //
        isFetching={isFetching}
        rows={rows}
        handleReload={handleReload}
        //  DETAIL MODEL  //
        handleView={handleViewModel}
        //  PAGINATION  //
        page={page}
        length={length}
        rowPerPage={rowPerPage}
        rowPerPageOption={rowPerPageOption}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
      />
      <ClaimDetailDialog
        handleClose={handleCloseModel}
        fromPanel={1}
        handleTableReload={handleReload}
      />
    </>
  );
}
