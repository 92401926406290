import React from "react";
import FacebookComponent from "./facebook.component";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { isFacebookUrlValid } from "../../../utils/productPage.util";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export default function FacebookContainer({ id, isTemplateEditor }) {
  const {
    isVisible,
    content: {
      pageURL,
      tabs: { timeline, events, messages },
      useSmallHeader,
      hideCoverPhoto,
      showFriendsFaces
    }
  } = useSelector(state => isTemplateEditor
    ? state.products.template.workspaceSetting.setting.components[id]
    : state.productPage.components[id]);

  const isTabExist = timeline || events || messages;
  const isEventOnly = events && !timeline && !messages;

  function getPath() {
    if (!isFacebookUrlValid(pageURL)) {
      return "";
    }

    const stripedDomain = pageURL.split("facebook.com/")[1];

    const pageId = stripedDomain.split("/")[0];
    const path = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${pageId}%2F&tabs${isTabExist ? "=" : ""
      }${timeline ? "timeline%2C%20" : ""}${events ? "events%2C%20" : ""}${messages ? "messages" : ""
      }&width=337&height=${isTabExist ? "500" : "214"
      }&small_header=${useSmallHeader}&adapt_container_width=true&hide_cover=${hideCoverPhoto}&show_facepile=${showFriendsFaces}&appId=${FACEBOOK_APP_ID}`;

    return path;
  }

  if (getPath() === "") {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid lightgrey",
          borderRadius: 10,
          padding: 16,
          marginBottom: 16
        }}
      >
        <Skeleton variant="rect" height={50} animation={false} />
        <br />
        <Skeleton variant="text" animation={false} width={70} />
        <Skeleton variant="text" animation={false} />
        <Skeleton variant="text" animation={false} />
      </div>
    );
  }

  return (
    isVisible && (
      <FacebookComponent
        path={getPath()}
        isTabExist={isTabExist}
        isEventOnly={isEventOnly}
      />
    )
  );
}
