import React from "react"
import { useSelector } from "react-redux";
import CreateTemplateDialogComponent from './createTemplateDialog.component';

export default function CreateTemplateDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
}) {
  const lang = useSelector(state => state.constant.languages)
  return (
    <CreateTemplateDialogComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      lang={lang}
    />
  )
}
