import React from 'react'
import { useSelector } from 'react-redux'
import ProductPagePreviewDialogComponent from './productPagePreviewDialog.component'

export default function ProductPagePreviewDialogContainer({
    isOpen,
    handleClose,
    isEditTemplate
}) {
    const lang = useSelector(state => state.constant.languages);
    const templateAfter = useSelector(state => state.productPage.templateAfter);
    const templateDropdown = useSelector(state => state.productPage.templateDropdown);
    const selectedTemplate = templateDropdown.find(template => template.uuid === templateAfter);
    const isCustomUI = selectedTemplate ? selectedTemplate.is_custom_ui : false;
    const customTemplateHtml = useSelector(state => state.products.template.workspaceSetting.customHtml);
    const templateIsCustom = useSelector(state => state.products.template.isCustom);

    return (
        <ProductPagePreviewDialogComponent
            isOpen={isOpen}
            handleClose={handleClose}
            lang={lang}
            templateAfter={templateAfter}
            isCustomUI={isCustomUI}
            customTemplateHtml={customTemplateHtml}
            isEditTemplate={isEditTemplate}
            templateIsCustom={templateIsCustom}
        />
    )
}
