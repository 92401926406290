import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop/index.js";
import { selectLoading } from "modules/notification";
import { getDealerIncentivePoint, resetDealerIncentivePoint, updateDealerIncentivePoint } from "modules/stockflow/redux";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIncentivePointDialogComponent from "./editIncentivePointDialog.component";

export default function EditIncentivePointDialogContainer({
  isOpen,
  handleClose,
  id,
  fetchDealerListing,
}) {
  const lang = useSelector(state => state.constant.languages);
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => selectLoading(state, getDealerIncentivePoint.typePrefix));
  const isSubmitting = useSelector((state) => selectLoading(state, updateDealerIncentivePoint.typePrefix));
  const details = useSelector((state) => state.stockflowDealer.incentivePoint);

  const [selectedPointPolarity, setSelectedPointPolarity] = useState("increase");
  const [newPoint, setNewPoint] = useState(0);
  const [newBalance, setNewBalance] = useState(details.pointBalance);
  const [remark, setRemark] = useState("");

  const [newPointError, setNewPointError] = useState("");

  useEffect(() => {
    setNewBalance(details.pointBalance);
  }, [details])

  const handleChangePointPolarity = (event) => {
    setSelectedPointPolarity(event.target.value)
    let newValue = 0;
    if (event.target.value === "increase") {
      newValue = +details.pointBalance + +newPoint;
    }
    else {
      newValue = +details.pointBalance - +newPoint;
    }
    setNewBalance(newValue)
  }

  const handleDialogClose = () => {
    setSelectedPointPolarity("increase")
    dispatch(resetDealerIncentivePoint())
    handleClose()
  }

  const handleChangePointValue = (value) => {
    setNewPoint(+value)
    let newValue = 0;
    if (selectedPointPolarity === "increase") {
      newValue = +details.pointBalance + +value;
    }
    else {
      newValue = +details.pointBalance - +value;
    }
    setNewBalance(newValue)
  }

  const handleChangeRemark = (value) => {
    setRemark(value)
  }

  const handleSubmit = async () => {
    if(newPoint !== 0) {
      const body = {
        uuid: id,
        isPositive: selectedPointPolarity === "increase",
        value: newPoint,
        remark: remark
      }
      dispatch(showBackdrop())
      await dispatch(updateDealerIncentivePoint(body))
        .then(unwrapResult)
        .then(() => {
          fetchDealerListing();
          dispatch(hideBackdrop())
          handleDialogClose()
        })
        .catch(() => dispatch(hideBackdrop()));
    }
    else {
      setNewPointError("Value cannot be zero");
    }
  }


  return (
    <EditIncentivePointDialogComponent
      isOpen={isOpen}
      handleClose={handleDialogClose}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      details={details}
      handleChangePointPolarity={handleChangePointPolarity}
      selectedPointPolarity={selectedPointPolarity}
      handleChangePointValue={handleChangePointValue}
      newBalance={newBalance}
      handleChangeRemark={handleChangeRemark}
      handleSubmit={handleSubmit}
      newPointError={newPointError}
      setNewPointError={setNewPointError}
      lang={lang}

    />
  )
}