import React from "react";
import { Formik } from "formik";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import UnassignTable from "modules/serial-number/components/table/unassignTable";
import SearchableSelect from "components/select/searchableSelect";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "8fr 4fr 2fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function UnassignListingComponent({
  isFetching,
  assignList,
  tableFilter,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  totalFiltered,
  handleSearch,
  handleReload,
  paginationOptions,
  lang,
  changeOrder,
  clearSearch,
  isError,
  retryUnassign,
  viewDetail,
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          nodes: tableFilter.nodes,
          status: tableFilter.status,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <BranchSearch
                placeholder={getLang(lang, "placeholder.BRANCH")}
                value={formik.values.nodes}
                handleChange={(value) => formik.setFieldValue("nodes", value)}
                isEmpty={formik.values.nodes?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(branch) => {
                  let temp = [...formik.values.nodes];
                  if (temp.length > 0) {
                    const filteredBranchs = temp.filter(
                      (item) => item.node_id !== branch.node_id
                    );
                    temp = [...filteredBranchs];
                  }
                  formik.setFieldValue("nodes", temp);
                }}
              />
              <SearchableSelect
                value={formik.values.status}
                isEmpty={formik.values.status === ""}
                handleChange={(value) => {
                  formik.setFieldValue("status", value);
                }}
                placeholder={getLang(lang, "placeholder.STATUS")}
                disabled={isFetching}
                searchable={false}
                style={{ height: "34px" }}
                panelStyle={{ width: "249px" }}
                PaperProps={{
                  classes: { root: classes.paperRoot },
                }}
                dropdownItem={[
                  getLang(lang, "label.PROCESSING_WORD"),
                  getLang(lang, "label.SUCCESS"),
                  getLang(lang, "label.FAILED"),
                ]}
                emptyMessage={getLang(lang, "paragraph.STATUS_NOT_FOUND")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              />
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box pl={1} pb={1}>
        <Typography>
          <b>{getLang(lang, "label.UNASSIGN_HISTORY")}</b>
        </Typography>
      </Box>
      <UnassignTable
        rows={assignList}
        isFetching={isFetching}
        isError={isError}
        language={language}
        handleReload={handleReload}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowsPerPage}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        retryUnassign={retryUnassign}
        viewDetail={viewDetail}
      />
    </>
  );
}
