/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import PrizeTypeSelectComponent from "./prizeTypeSelect.component";
import PrizeTypePanelComponent from "./prizeTypeSelectPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function PrizeSelectContainer({ handleChange, value, disabled }) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);

  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );
  const lang = useSelector((state) => state.constant.languages);
  const [prizeTypes, setPrizeTypes] = useState([]);

  useEffect(() => {
    let index = 2;
    let tempTypes = [
      {
        id: 0,
        currency: null,
        type: "item",
        provider_name: getLang(lang, "label.ITEM"),
      },
      // {
      //   id: 1,
      //   currency: null,
      //   type: "redemption",
      //   provider_name: getLang(lang, "label.REDEMPTION_CODE"),
      // },
    ];
    if (disbursementInfos?.length) {
      disbursementInfos.forEach((info) => {
        tempTypes.push({
          id: index,
          disbursement_id: info.id,
          currency: info.currency,
          type: "credit",
          provider_name: info.provider_name,
        });
        index++;
      });
    }
    setPrizeTypes(tempTypes);
  }, [disbursementInfos]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelect = (type) => {
    handleChange(type);
    handleClose();
  };

  return (
    <>
      <PrizeTypeSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        disabled={disabled}
        prizeTypes={prizeTypes}
        lang={lang}
      />
      <PrizeTypePanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        handleSelect={handleSelect}
        prizeTypes={prizeTypes}
        lang={lang}
      />
    </>
  );
}
PrizeSelectContainer.defaultProps = {
  placeholder: "Select prize(s)",
};

PrizeSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default PrizeSelectContainer;
