import React from "react";
import CustomTab from "components/tab";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Divider,
  Typography,
  Tabs
  // CircularProgress,
} from "@material-ui/core";
// import { DownloadSVG } from "modules/stockflow/utils/static"
import { getLang } from "app/feature/constants";
import SummaryByBranch from "./pages/summary-by-branch";
import SummaryByDealer from "./pages/summary-by-dealer";
import DealerInventory from "./pages/dealer-inventory";
import BranchInventory from "./pages/branch-inventory";

const useStyle = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ProductSummaryComponent({
  handleChange,
  activeIndex,
  tabLabels,
  lang
}) {
  const classes = useStyle();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <>
      <Divider className={classes.fullWidth}/>
      <Box gridArea="panel">
        <Box>
          <Box>
              <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>

          <Box>
              {activeIndex !== null &&
                tabLabels.map((item, index) => {
                  
                if (item === getLang(lang, "label.SUM_BY_BRANCH")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <SummaryByBranch />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.SUM_BY_DEALER")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <SummaryByDealer />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.BRANCH_INVENTORY")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <BranchInventory />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.DEALER_INVENTORY")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <DealerInventory />
                      </Box>
                    </TabPanel>
                  );
                }
                return null;
                })}
          </Box>
        </Box>
      </Box>
    </>
  );
}
