import React from "react";
import { Typography, Box } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  UNASSIGN_BATCH_STATUS_FAIL,
  UNASSIGN_BATCH_STATUS_SUCCESS,
  UNASSIGN_BATCH_STATUS_SUCCESS_WARNING,
} from "modules/serial-number/constants";

export default function UnassignStatusProgressComponent({
  lang,
  progress,
  status,
}) {
  return (
    <Box>
      <Typography
        variant="caption"
        display="inline"
        style={{
          color:
            status === UNASSIGN_BATCH_STATUS_FAIL
              ? "#FF0000"
              : status === UNASSIGN_BATCH_STATUS_SUCCESS ||
                status === UNASSIGN_BATCH_STATUS_SUCCESS_WARNING
              ? "#32D583"
              : "#989898",
        }}
      >
        {status === UNASSIGN_BATCH_STATUS_FAIL
          ? getLang(lang, "label.FAILED")
          : status === UNASSIGN_BATCH_STATUS_SUCCESS ||
            status === UNASSIGN_BATCH_STATUS_SUCCESS_WARNING
          ? getLang(lang, "label.SUCCESS")
          : `${progress}%`}
      </Typography>
      {status === UNASSIGN_BATCH_STATUS_SUCCESS_WARNING && (
        <Typography
          variant="caption"
          display="inline"
          style={{
            color: "#FDB022",
            textTransform: "capitalize",
            marginLeft: 4,
          }}
        >
          ({getLang(lang, "label.WARNING")})
        </Typography>
      )}
    </Box>
  );
}
