import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/participantDetail";

const getLuckyDrawParticipantDetails = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          uuid: params.uuid,
          luckyDrawUuid: params.luckyDrawUuid,
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getLuckyDrawParticipantDetails;
