/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LuckyDrawCreateEditFormComponent from "./luckyDrawCreateEditForm.component";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "query-string";

export default function LuckyDrawPage({
  uuid,
  initialValues,
  type,
  getLuckyDrawDetail,
  isLoading,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState(0);
  const [newUuid, setNewUuid] = useState(null);
  const [updatedLuckyDrawInfo, setUpdatedLuckyDrawInfo] = useState(null);

  const qsParams = qs.parse(location.search);
  const luckyDrawUuid = qsParams.lucky_draw || "";
  const step = qsParams.step || 0;

  useEffect(() => {
    if (step) {
      setCurrentStep(parseInt(step - 1));
    }
  }, []);

  useEffect(() => {
    if (luckyDrawUuid && !newUuid) {
      setNewUuid(luckyDrawUuid);

      if (step) {
        setCurrentStep(parseInt(step - 1));
      }
    }
  }, [luckyDrawUuid, step]);

  useEffect(() => {
    setUpdatedLuckyDrawInfo(initialValues);
  }, [initialValues]);

  const handleStepChange = (step) => {
    setCurrentStep(step);

    const params = new URLSearchParams(window.location.search);
    params.set("step", step + 1);
    window.history.replaceState(null, null, `?${params}`);
  };

  return (
    <LuckyDrawCreateEditFormComponent
      lang={lang}
      uuid={uuid || newUuid}
      setNewUuid={setNewUuid}
      initialValues={updatedLuckyDrawInfo}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      getLuckyDrawDetail={getLuckyDrawDetail}
      type={type}
      handleStepChange={handleStepChange}
      isLoading={isLoading}
    />
  );
}
