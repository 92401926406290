import { Box, Button, ButtonBase, Chip, makeStyles, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.25em 0.75em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    border: "1px solid #D0D5DD",
    width: "100%",
    overflow: "hidden",
  },
  inputText: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  disabledField: {
    backgroundColor: "#eceff0"
  },
  clearButton: {
    minWidth: 0
  },
  filled: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center"
  },
  filledWithNum: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    alignSelf: "center"
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.15em",
    marginTop: "0.15em",
    border: "none",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
  },
  tooltip: {
    maxHeight: "150px",
    overflow: "auto",
    display: "grid",
    '&::-webkit-scrollbar': {
      width: '0.3em'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(255,255,255,1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '2px',
      backgroundColor: '#ccd1d3',
    }
  },
  tooltipChip: {
    backgroundColor: "#FFF",
    margin: "0.5em 0.3em 0.5em 0.3em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
  }
}));

const CustomizeTooltip = withStyles({
  tooltip: {
    backgroundColor: "#f7f7f7",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
  },
  arrow: {
    scale: 2,
    color: "#f7f7f7",
    top: "-4px !important",
    "&::before": {
      boxShadow: "0px 5px 0px -2px rgba(0,0,0,0.2), 0px 0px 2px -1px rgba(0,0,0,0.44), 0px 0px 5px -2px rgba(0,0,0,0.42)"
    }
  }
})(Tooltip);

CustomizeTooltip.propTypes = {
  arrow: PropTypes.bool,
  interactive: PropTypes.bool,
  title: PropTypes.any,
  children: PropTypes.node
};
export default function ProgressSelectComponent({
  handleClick,
  handleClear,
  value,
  placeholder,
  disabled,
  style,
  id,
  open,
  textStyle,
  isClearable,
  lang,
  adjustField,
  extraChip,
  drawerOpen,
  handleDeleteChip,
  handleChange,
}) {
  const classes = useStyles();

  const ref = useRef(null);
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Add event when user zoom in/out or resize screen size
    const handleResize = () => {
      setDimension({
        width: window.innerHeight,
        height: window.innerWidth,
      });
      adjustField(ref);
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    adjustField(ref);
    // eslint-disable-next-line
  }, [handleChange, dimension])

  useEffect(() => {
    setTimeout(() => {
      adjustField(ref);
      window.dispatchEvent(new Event('resize'));
    }, 200);
    // eslint-disable-next-line
  }, [drawerOpen]);

  const getDisplay = () => {
    let display = placeholder;
    if (value.length > 0) {
      display = value[0].name;
    }
    return display;
  };

  const displayValue = () => {
    return (
      <Box ref={ref} style={{ display: "flex", flexWrap: "wrap", overflow: "hidden", textOverflow: "ellipsis", flex: "2" }}>
        <Typography
          variant="body2"
          noWrap
          style={extraChip.allOverflow ? { textOverflow: "clip" } : { textStyle }}
          className={clsx(
            classes.inputText,
            value.length > 1 && classes.filledWithNum,
            (getDisplay() !== placeholder) && classes.filled,
          )}
        >
          {value.length === 0 ? (
            <span
              className={clsx(classes.inputText, {
                [classes.filled]: value.length > 0
              })}
              style={textStyle}
            >
              {placeholder}
            </span>
          ) : (
            value.map((item) => (
              <Chip
                variant="outlined"
                label={item.name}
                size="small"
                className={classes.chip}
                onDelete={() => handleDeleteChip(item)}
                style={extraChip.allOverflow ? { visibility: "hidden" } : {}}
              />
            ))
          )}
        </Typography>
      </Box>
    )
  }

  const extraList = () => {
    return <div className={classes.tooltip}>
      {
        extraChip.overflowChild.map((item) => (
          <Chip
            label={item.name}
            size="small"
            className={classes.tooltipChip}
            onDelete={() => handleDeleteChip(item)}
          />
        ))
      }
    </div>
  }

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ paddingLeft: 6, paddingRight: 6, borderRadius: "4px", backgroundColor: "#ECEFF0", borderColor: "#ECEFF0", ...style }}
    >
      {displayValue()}
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {(extraChip.count >= 1 && value.length > 0)
          ?
          <CustomizeTooltip title={extraList()} arrow interactive>
            <Chip
              label={"+ " + extraChip.count}
              size="small"
              className={classes.chip}
              style={{ left: extraChip.offset, position: "absolute", marginLeft: 3 }}
            />
          </CustomizeTooltip>
          : null}
        {isClearable && value.length !== 0 && (
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation()
              handleClear()
            }}
          >
            <ClearIcon style={{ fontSize: 10 }} />
          </Button>
        )}
        {open ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        )}
      </Box>
    </ButtonBase>
  )
}
