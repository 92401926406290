import React, { useState, useEffect } from "react";
import BranchSelectComponent from "./branchSelect.component";
import BranchSelectPanelComponent from "./branchSelectPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function BranchSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  isFetching = false,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  isEmpty,
  textStyles,
}) {
  const drawerOpen = useSelector((state) => state.app.drawerOpen);
  const lang = useSelector((state) => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const open = Boolean(anchorEl);
  const [extraChip, setExtraChip] = useState({
    count: 0,
    offset: 0,
    allOverflow: false,
  });
  const [isSelectAll, setIsSelectAll] = useState(true);

  useEffect(() => {
    setBranchList(dropdownItem);
  }, [dropdownItem]);

  useEffect(() => {
    setIsSelectAll(value?.length === dropdownItem?.length);
  }, [value, dropdownItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleSearch("");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (val) => {
    let text = val.toLowerCase();

    let temp = dropdownItem.filter(
      (i) => !!i.name.toLowerCase().includes(text)
    );

    setBranchList(temp);
  };

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];

    if (!children) return;

    // max width is 90%. Use 0.9 to calc field width
    let panelWidth =
      ref.current?.scrollWidth * 0.9 > 250
        ? 250
        : ref.current?.scrollWidth * 0.9;
    let childWidth = 0;

    if (children.length > 0) {
      // Need to exclude placeholder
      children = [...children];

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index) => {
        let panelContentWidth = p.getBoundingClientRect().width;

        // get exact width + padding with decimal
        childWidth +=
          parseInt(window.getComputedStyle(child).marginRight) +
          child.getBoundingClientRect().width;

        // Check if overflow
        if (panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return index !== children.length - 1
            ? childWidth + 10.5 < panelWidth
            : childWidth + 0.5 < panelWidth;
        }

        return panelWidth > panelContentWidth;
      }).length;

      if (inRangeCount > 0) {
        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);
        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count: extraCount,
          offset: lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow: false,
          overflowChild: outRangeChild,
        });
      } else {
        setExtraChip({
          count: value.length,
          offset: 0,
          allOverflow: true,
          overflowChild: value,
        });
      }
    } else {
      setExtraChip({
        count: 0,
        offset: 0,
        allOverflow: false,
        overflowChild: [],
      });
    }
  };

  const handleSelectBranch = (branch) => {
    let newList = [...value];

    if (value.find((v) => v.node_id === branch.node_id)) {
      newList = value.filter((v) => v.node_id !== branch.node_id);
    } else {
      newList.push(branch);
    }

    handleChange(newList);
  };

  const handleSelectAll = (selectAll) => {
    if (selectAll) {
      handleChange(dropdownItem);
    } else {
      handleChange([]);
    }
  };

  return (
    <>
      <BranchSelectComponent
        handleClick={handleClick}
        value={value}
        styles={style}
        placeholder={placeholder}
        disabled={disabled}
        textStyles={textStyles}
        lang={lang}
        drawerOpen={drawerOpen}
        extraChip={extraChip}
        adjustField={adjustField}
        isEmpty={isEmpty}
        selectAll={isSelectAll}
      />
      <BranchSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        branchDropdown={branchList}
        handleSearch={handleSearch}
        isFetching={isFetching}
        handleChange={handleSelectBranch}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        lang={lang}
        isEmpty={isEmpty}
        selectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
      />
    </>
  );
}
BranchSelectContainer.defaultProps = {
  placeholder: "Select Branch(s)",
};

BranchSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default BranchSelectContainer;
