import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  // MenuList,
  // MenuItem,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import {
  Search as SearchIcon
} from "@material-ui/icons"
import { Skeleton } from '@material-ui/lab';
import InputTextField from 'components/input/inputTextField';

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    height: 200
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6
  }
}));

export default function BranchSingleFlatSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  searchText,
  selectedValue
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: {minWidth: "45em"},
        ...PaperProps
      }}
    >
      {searchable && (
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0
              }
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(textRef.current.value)
                  return false
                }
              },
              style: {
                padding: 8
              }
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 ? (
          <List>
            {items.map((item, index) => {
                return (
                  <Box key={index}>
                    <ListItem
                      button
                      onClick={() => {
                        handleChange(item);
                        handleClose();
                      }}
                      selected={selectedValue(item)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  </Box>
                )
            })}
          </List>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  )
}
