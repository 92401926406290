import React from "react";
import InstantWinDetailPreviewPanelComponent from "./instantWinDetailPreviewPanel.component";

function InstantWinDetailPreviewPanelContainer({ instantWin, lang }) {
  return (
    <InstantWinDetailPreviewPanelComponent instantWin={instantWin} lang={lang} />
  );
}

export default InstantWinDetailPreviewPanelContainer;
