import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import InputTextField from "components/input/inputTextField";
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from "app/feature/constants";
import defaultPhoto from "assets/img/default-profile-picture.png";
import { Formik } from "formik";
import * as Yup from "yup";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none",
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "0.25em",
    fontSize: "1rem",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      maxWidth: "40em",
    },
    "@media (min-width: 600px)": {
      width: "60em",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  containerBackground: {
    backgroundColor: "#EBEDEE",
  },
  content: {
    margin: "1em",
    backgroundColor: "#FFFFFF",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectRoot: {
    padding: "8px 14px",
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  fieldContainer: {
    paddingTop: "1em",
    margin: "1em",
    paddingBottom: "1em",
  },
  field: {
    display: "flex",
    alignItems: "flex-start",
  },
  field2: {
    display: "flex",
    alignItems: "center",
  },
  consumerField: {
    display: "flex",
    alignItems: "center",
  },
  consumerProfileTierCombo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.5em",
  },
  profilePhotoConsumer: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "33px",
    height: "33px",
    marginRight: "0.25em",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    "& img": {
      borderRadius: "50%",
      height: "33px",
      width: "33px",
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  dialogFooter: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    margin: "1em",
  },
}));

export default function EditLoyaltyPointDialogComponent({
  isOpen,
  handleClose,
  isSubmitting,
  consumerDetails,
  handleChangePointPolarity,
  selectedPointPolarity,
  handleChangePointValue,
  handleSubmit,
  lang,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold", fontSize: "initial" }}
          >
            {getLang(lang, "label.EDIT_CONSUMER_LOYALTY_POINT")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            balance: consumerDetails.point,
            value: 0,
            selectedPointPolarity: "increase",
            remark: "",
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            balance: Yup.number()
              .min(
                0,
                getLang(
                  lang,
                  "message.error.LOYALTY_BALANCE_VALIDATE_MIN_NUMBER",
                  {
                    value: 0,
                  }
                )
              )
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          })}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box className={classes.containerBackground}>
                {isOpen ? (
                  <>
                    <Box className={classes.content}>
                      <Box className={classes.fieldContainer}>
                        <Grid container className={classes.field}>
                          <Grid item xs={12} sm={12}>
                            <Box className={classes.consumerField}>
                              <Box className={classes.consumerProfileTierCombo}>
                                <Box className={classes.profilePhotoConsumer}>
                                  <img
                                    src={
                                      consumerDetails.profilePicture ??
                                      defaultPhoto
                                    }
                                    alt={
                                      consumerDetails.firstName +
                                      " " +
                                      (consumerDetails.lastName
                                        ? consumerDetails.lastName
                                        : "")
                                    }
                                  />
                                </Box>
                              </Box>
                              <Typography variant="body1">
                                <b>
                                  {consumerDetails.firstName +
                                    " " +
                                    (consumerDetails.lastName
                                      ? consumerDetails.lastName
                                      : "")}
                                </b>
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "1em",
                            }}
                          >
                            <Typography variant="body2" color="primary">
                              {getLang(
                                lang,
                                "label.CURRENT_LOYALTY_POINT_BALANCE"
                              )}
                            </Typography>
                            &nbsp;
                            <Typography variant="body2" color="error">
                              *
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "1em",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 400 }}
                            >
                              {convertToBigNum(
                                consumerDetails.point.toLocaleString()
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingTop: "0.75em",
                              marginTop: "1em",
                            }}
                          >
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.LOYALTY_POINT_ADJUSTMENT")}
                            </Typography>
                            &nbsp;
                            <Typography variant="body2" color="error">
                              *
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            style={{ marginTop: "1em" }}
                          >
                            <Box>
                              <RadioGroup
                                aria-label="change-point-polarity"
                                name="change-point-polarity"
                                value={formik.values.selectedPointPolarity}
                                onChange={(event) =>
                                  handleChangePointPolarity(
                                    event.target.value,
                                    formik
                                  )
                                }
                                className={classes.radioGroup}
                              >
                                <FormControlLabel
                                  value="increase"
                                  control={<Radio />}
                                  label={`(+) ${getLang(
                                    lang,
                                    "label.INCREASE"
                                  )}`}
                                  style={{ marginRight: "3em" }}
                                />
                                <FormControlLabel
                                  value="decrease"
                                  control={<Radio />}
                                  label={`(-) ${getLang(
                                    lang,
                                    "label.DECREASE"
                                  )}`}
                                />
                              </RadioGroup>
                              <InputTextField
                                required
                                variant="outlined"
                                size="small"
                                disabled={isSubmitting}
                                fullWidth
                                value={formik.values.value}
                                placeholder={getLang(
                                  lang,
                                  "placeholder.ENTER_A_NUMBER"
                                )}
                                onChange={(e) => {
                                  handleChangePointValue(
                                    e.target.value,
                                    formik
                                  );
                                }}
                                type="number"
                                InputStyle={{
                                  backgroundColor: "#FFFFFF",
                                }}
                                InputProps={{
                                  inputProps: { min: 0 },
                                  defaultValue: 0,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Box
                                        px={1}
                                        borderRight="1px solid rgba(0, 0, 0, 0.1)"
                                      >
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {selectedPointPolarity === "increase"
                                            ? "+"
                                            : "-"}
                                        </Typography>
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                                error={!!formik.errors.balance}
                                helperText={formik.errors.balance}
                                onKeyDown={(e) => {
                                  const invalidChars = ["-", "+", "e", "."];
                                  if (invalidChars.includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginTop: "1em" }}
                          >
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.REMARK")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginTop: "1em",
                            }}
                          >
                            <InputTextField
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              fullWidth
                              multiline={true}
                              rows={4}
                              placeholder={getLang(
                                lang,
                                "placeholder.REMARK_TO_YOUR_CONSUMER"
                              )}
                              value={formik.values.remark}
                              onChange={(e) => {
                                formik.setFieldValue("remark", e.target.value);
                              }}
                              InputStyle={{
                                backgroundColor: "#FFFFFF",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                      <Box className={classes.fieldContainer}>
                        <Grid container style={{ marginBottom: "1em" }}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.NEW_LOYALTY_POINT_BALANCE")}
                            </Typography>
                            &nbsp;
                            <Typography variant="body2" color="error">
                              *
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {/* {convertToBigNum(newBalance.toLocaleString())} */}
                              {formik.values.balance !== undefined
                                ? convertToBigNum(
                                    formik.values.balance.toLocaleString()
                                  )
                                : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box
                    classes={classes.content}
                    style={{ textAlign: "center" }}
                  >
                    <Box className={classes.fieldContainer}>
                      <CircularProgress color="primary" />
                    </Box>
                  </Box>
                )}
              </Box>
              <Divider />
              <Box className={classes.dialogFooter}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !formik.values.value}
                >
                  {getLang(lang, "label.UPDATE")}
                </Button>
                <Button
                  color="primary"
                  onClick={() => handleClose()}
                  disabled={isSubmitting}
                  style={{ marginRight: "1em" }}
                >
                  {getLang(lang, "label.CANCEL")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  );
}
