import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from 'modules/notification';
import {
  resetTemplatesListing,
  openCreationDialog,
  closeCreationDialog,
} from "modules/product-mgmt/redux/slice/products.slice"
import { PRODUCT_ATTRIBUTE_VIEW, PRODUCT_ATTRIBUTE_EDIT, PRODUCT_ATTRIBUTE_DELETE } from "lib/constants/accessRights"
import LandingPageTemplateListPanelComponent from './landingPageTemplateListPanel.component';
import { generatePaginationOptions } from "lib/helper";
import {
  createTemplate,
  deleteTemplate, fetchCustomTemplateHtml,
  fetchPreviewTemplateSettings,
  fetchTemplates
} from "../../../redux/action/template.action";
import CreateTemplateDialog from "../../dialog/createTemplateDialog";
import {uploadJSONToS3} from "../../../../../app/feature";
import {UploadType} from "../../../../../lib/constants/aws_s3";
import DeleteTemplateDialog from "../../dialog/deleteTemplateDialog";
import { setProductTemplateChange } from "../../../redux/slice/productPage.slice";

function LandingPageTemplateListPanelContainer() {
  const dispatch = useDispatch()
  const history = useHistory();

  const companyUuid = useSelector(state => state.account.id);
  const isCreateDialogOpen = useSelector(state => state.products.isOpen)
  const hasViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_VIEW));
  const hasEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_EDIT));
  const hasDeleteAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_DELETE));
  const totalFiltered = useSelector(state => state.products.templateList.totalFiltered);
  const data = useSelector(state => state.products.templateList.data);
  const isFetching = useSelector(state => selectLoading(state, fetchTemplates.typePrefix));
  const isError = useSelector(state => state.products.templateList.isError)
  const lang = useSelector(state => state.constant.languages);
  const previewSetting = useSelector(state => state.products.template.workspaceSetting.setting);
  const customTemplateHtml = useSelector(state => state.products.template.workspaceSetting.customHtml);
  const isPreviewLoading = useSelector(state => selectLoading(state, fetchPreviewTemplateSettings.typePrefix) || selectLoading(state, fetchCustomTemplateHtml.typePrefix));
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleteId, setDeleteId] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    page: 0,
    rowsPerPage: 25
  })

  useEffect(() => {
    if (selectedId) {
      if (selectedId.isCustom) {
        dispatch(fetchCustomTemplateHtml({ template: selectedId.url, isGenuine: true }))
      }
      else {
        dispatch(fetchPreviewTemplateSettings(selectedId.url));
      }
    }
  }, [selectedId, dispatch]);

  useEffect(()=>{
    if(!hasViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasViewAccessRight])

  useEffect(() => {
    return () => {
      dispatch(resetTemplatesListing())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setProductTemplateChange({ uuid: -1, name: '(No Template)'}));
  }, [dispatch]);

  useEffect(() => {
    setSelectedId(null);
    dispatch(fetchTemplates({
      length: filter.rowsPerPage,
      page: filter.page,
      search: filter.search,
    }))
  }, [
    dispatch,
    filter
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleFetch = () => {
    setFilter((prev) => ({
      ...prev,
      page: 0
    }))
  }

  const handleReload = () => {
    dispatch(fetchTemplates({
      length: filter.rowsPerPage,
      page: filter.page,
      search: filter.search,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleChangeRowsPage = event => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleSearch = (value) => {
    setFilter({
      search: value.search,
      page: 0,
      rowsPerPage: filter.rowsPerPage
    })
  }

  const handleSelect = (id) => {
    setSelectedId(id);
  }

  const handleCreate = () => {
    dispatch(openCreationDialog());
  }

  const handleCreateSubmit = async (values) => {
    const newTemplateJson = {
      "backgroundColor": "#ffffff",
      "columns": {
        "id": "column-1",
        "compIds": [
          "authentication"
        ]
      },
      "components": {
        "authentication": {
          "id": "authentication",
          "type": "authentication",
          "content": {
            "messages": [
              {
                "lang": "EN",
                "value": ""
              }
            ]
          },
          "isVisible": true
        }
      },
      "lang": [
        "EN"
      ]
    };

    await dispatch(uploadJSONToS3({
      uploadType: UploadType.productPageUiTemplate,
      data: newTemplateJson,
      id: companyUuid,
      fileName: `${Date.now()}-${values.name}.json`
    })).then(unwrapResult)
      .then((url) => {
        dispatch(createTemplate({
          name: values.name,
          settings_json_url: url,
        }))
          .then(unwrapResult)
          .then((data) => {
            history.push(`/admin/product/template/${data.id}`);
          })
      });
  }

  const handleEdit = (id) => {
    history.push(`/admin/product/template/${id}`)
  }

  const handleDelete = (id) => {
    setIsDeleteDialogOpen(true)
    setDeleteId(id)
  }

  const handleSubmitDelete = () => {
    dispatch(deleteTemplate({
      id: deleteId
    }))
      .then(unwrapResult)
      .then(() => handleFetch())
      .finally(() => {
        setIsDeleteDialogOpen(false)
      })
  }

  return (
    <>
      <LandingPageTemplateListPanelComponent
        hasEditAccessRight={hasEditAccessRight}
        hasDeleteAccessRight={hasDeleteAccessRight}
        data={data}
        totalFiltered={totalFiltered}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPage}
        handleReload={handleReload}
        handleSearch={handleSearch}
        isFetching={isFetching}
        selectedId={selectedId}
        paginationOptions={paginationOptions}
        page={filter.page}
        search={filter.search}
        statusFilter={filter.status}
        rowsPerPage={filter.rowsPerPage}
        handleCreate={handleCreate}
        isError={isError}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        lang={lang}
        previewSetting={previewSetting}
        isPreviewLoading={isPreviewLoading}
        isCustomUi={selectedId?.isCustom ?? false}
        customTemplateHtml={customTemplateHtml}
      />
      <CreateTemplateDialog
        handleClose={() => dispatch(closeCreationDialog())}
        handleSubmit={handleCreateSubmit}
        isOpen={isCreateDialogOpen}
        lang={lang}
      />
      <DeleteTemplateDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={handleSubmitDelete}
        products={selectedId?.assignedProduct ?? []}
        name={selectedId?.name}
      />
    </>
  )
}

export default LandingPageTemplateListPanelContainer;
