export const participantFieldTypeChoice = [
  {
    labelType: "label.TEXT_FIELD",
    value: "textField",
    type: "textField",
    fieldType: 2, // 1 - standard, 2 - custom
  },
  {
    labelType: "label.RADIO_BUTTONS_SINGLE_SELECTION",
    value: "multipleChoice",
    type: "multipleChoice",
    fieldType: 2,
  },
  {
    labelType: "label.CHECKBOX_MULTIPLE_SELECTION",
    value: "multipleChoiceMultiSelect",
    type: "multipleChoiceMultiSelect",
    fieldType: 2,
  },
  {
    labelType: "label.ADDRESS_PCIKER",
    value: "address",
    type: "address",
    fieldType: 2,
  },
  {
    labelType: "label.FILE_UPLOAD",
    value: "uploadFile",
    type: "uploadFile",
    fieldType: 2,
  },
  {
    labelType: "label.IMAGE_UPLOAD",
    value: "uploadImage",
    type: "uploadImage",
    fieldType: 2,
  },
  {
    labelType: "label.CONTACT_NUMBER",
    value: "contactNumber",
    type: "contactNumber",
    fieldType: 2,
  },
  {
    labelType: "label.DROPDOWN_LIST",
    value: "dropdownlist",
    type: "dropdownlist",
    fieldType: 2,
  },
];

export const defaultParticipantFormFields = [
  {
    fieldName: "name",
    labelType: "",
    type: "textField",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Name",
    choice: [],
    description: "Name (Standard Info)"
  },
  {
    fieldName: "email",
    labelType: "",
    type: "textField",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Email",
    choice: [],
    description: "Email (Standard Info)"
  },
  {
    fieldName: "contactNumber",
    labelType: "",
    type: "contactNumber",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Contact Number",
    choice: [],
    description: "Contact Number (Standard Info)"
  },
  {
    fieldName: "address",
    labelType: "",
    type: "address",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Address",
    choice: [],
    description: "Address (Standard Info)"
  },
  {
    fieldName: "gender",
    labelType: "",
    type: "multipleChoice",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Gender",
    choice: ["Male", "Female", "Others"],
    description: "Gender (Standard Info)"
  },
];

export const additionalParticipantFormFields = [
  ...defaultParticipantFormFields,
  {
    fieldName: "proofOfPurchase",
    labelType: "",
    type: "uploadImage",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Proof of Purchase",
    choice: [],
    description: "Proof of Purchase (Standard Info)"
  },
  {
    fieldName: "purchaseLocation",
    labelType: "",
    type: "textField",
    mandatory: true,
    visible: true,
    enableDelete: true,
    editing: false,
    fieldType: 1,
    variantName: "Purchase Location",
    choice: [],
    description: "Purchase Location (Standard Info)"
  }
];

export const ldState = {
  notPublished: "notPublished",
  published: "published",
  preview: "preview",
  joinStart: "joinStart",
  joinEnd: "joinEnd",
  drawStart: "drawStart",
  ended: "ended",
};
