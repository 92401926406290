/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import IntegrationPathSettingCardComponent from "./integrationPathSettingCard.component";
import { DKSH_INTEGRATION_SETTING_EDIT } from "lib/constants/accessRights";
import { IntegrationContext } from "../integrationSettingCard/integrationSettingCard.container";

export default function IntegrationPathSettingCardContainer({
  setting,
  phaseIndex,
  formik,
  isEdit,
}) {
  const {
    adtInfos,
    isFetchingAdt,
    isErrorAdt,
    totalRecords,
    hasNextPage,
    handleFetchMoreAdtInfos,
    handleReload,
    handleSearch,
    isSubmitting,
  } = useContext(IntegrationContext);

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const hasIntegrationEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(DKSH_INTEGRATION_SETTING_EDIT)
  );

  return (
    <IntegrationPathSettingCardComponent
      lang={lang}
      isSubmitting={isSubmitting}
      hasIntegrationEditAccessRight={hasIntegrationEditAccessRight}
      setting={setting}
      formik={formik}
      phaseIndex={phaseIndex}
      adtInfoParams={{
        adtInfos: adtInfos,
        isFetching: isFetchingAdt,
        isError: isErrorAdt,
        totalRecords: totalRecords,
        hasNextPage: hasNextPage,
        handleFetchMoreAdtInfos: handleFetchMoreAdtInfos,
        handleReload: handleReload,
        handleSearch: handleSearch,
      }}
      isEdit={isEdit}
      userLang={language}
    />
  );
}
