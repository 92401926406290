import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ArticleImage,
  AuthenticationImage,
  FacebookImage,
  GalleryImage,
  InstagramImage,
  MarketplaceImage,
  TikTokImage,
  UrlImage,
  YoutubeImage,
  WarrantyImage,
  AdditionalInfoImage,
  LoyaltyCardImage,
  SurveyImage,
  LuckyDrawImage,
  InstantWinImage
} from "../../../utils/img/componentMenu";
import { getLang } from "app/feature/constants";
import parse from "html-react-parser";

const useStyle = makeStyles(() => ({
  accordionContent: {
    backgroundColor: "#E4E7EC",
    display: "flex",
    flexDirection: "column",
    maxHeight: 450,
    overflowY: "scroll",
  },
  componentImage: {
    width: "90%",
    height: "100%",
    maxHeight: 260,
    objectFit: "contain",
  },
  description: {
    fontWeight: "normal",
    fontSize: 13,
    color: "#98A2B3",
    margin: "8px 0px 5px 0px",
  },
  imgContainer: {
    padding: 10,
    backgroundColor: "#F2F4F7",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
  },
  expandIcon: {
    padding: 5,
  },
  accordionHeader: {
    margin: "0px !important",
  },
  accordionBar: {
    minHeight: "40px !important",
  },
  accordion: {
    margin: "0px !important",
    "&:before": {
      opacity: "1 !important",
    },
  },
}));

export default function ComponentTabComponent({
  handleClick,
  enableAddMarketplace,
  enableAddAuthentication,
  enableAddWarranty,
  enableAddAdditionalInfo,
  enableAddLoyaltyPoint,
  enableAddSurvey,
  enableAddLuckyDraw,
  enableAddInstantWin,
  isEditorMode,
  lang,
  isEarlyAccess,
  hasAdtInfoViewAccessRight,
}) {
  const [expanded, setExpanded] = useState("productInfo");

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <ComponentPanel
        panelName="productInfo"
        title={getLang(lang, "label.PRODUCT_INFO")}
        types={[
          ...(hasAdtInfoViewAccessRight
            ? [
                {
                  label: getLang(lang, "label.ADDITIONAL_INFORMATION"),
                  key: enableAddAdditionalInfo ? "additional information" : "",
                  description: getLang(
                    lang,
                    "paragraph.ADDITIONAL_INFORMATION_DESCRIPTION",
                    {
                      path: `<span>
                      <b>
                        ${getLang(lang, "label.QR_CODE")} > ${getLang(
                        lang,
                        "label.ADDITIONAL_INFO"
                      )}
                      </b>
                    </span>`,
                    }
                  ),
                  image: AdditionalInfoImage,
                },
              ]
            : []),
          ...(isEarlyAccess
            ? [
                {
                  label: getLang(lang, "label.WARRANTY"),
                  key: enableAddWarranty ? "warranty" : "",
                  description: getLang(lang, "paragraph.WARRANTY_DESCRIPTION"),
                  image: WarrantyImage,
                },
              ]
            : []),
          {
            label: getLang(lang, "label.AUTHENTICATION"),
            key: enableAddAuthentication ? "authentication" : "",
            description: getLang(lang, "paragraph.AUTHENTICATION_DESCRIPTION"),
            image: AuthenticationImage,
          },
          {
            label: getLang(lang, "label.ARTICLE"),
            key: "text",
            description: getLang(lang, "paragraph.ARTICLE_DESCRIPTION"),
            image: ArticleImage,
          },
          {
            label: getLang(lang, "label.URL_LINK"),
            key: "website",
            description: getLang(lang, "paragraph.WEBSITE_DESCRIPTION"),
            image: UrlImage,
          },
          {
            label: getLang(lang, "label.MARKETPLACE"),
            key: enableAddMarketplace ? "marketplace" : "",
            description: getLang(lang, "paragraph.MARKETPLACE_DESCRIPTION"),
            image: MarketplaceImage,
          },
        ]}
        handleClick={handleClick}
        expanded={expanded}
        handleExpand={handleExpand}
        isEditorMode={isEditorMode}
        lang={lang}
      />
      <ComponentPanel
        panelName="branding"
        title={getLang(lang, "label.BRANDING")}
        types={[
          {
            label: getLang(lang, "label.IMAGE_GALLERY"),
            key: "gallery",
            description: getLang(lang, "paragraph.IMAGE_DESCRIPTION"),
            image: GalleryImage,
          },
        ]}
        handleClick={handleClick}
        expanded={expanded}
        handleExpand={handleExpand}
        isEditorMode={isEditorMode}
        lang={lang}
      />
      <ComponentPanel
        panelName="socialMedia"
        title={getLang(lang, "label.SOCIAL_MEDIA")}
        types={[
          {
            label: getLang(lang, "label.YOUTUBE"),
            key: "video",
            description: getLang(lang, "paragraph.YOUTUBE_DESCRIPTION"),
            image: YoutubeImage,
          },
          {
            label: getLang(lang, "label.FACEBOOK"),
            key: "facebook",
            description: getLang(lang, "paragraph.FACEBOOK_DESCRIPTION"),
            image: FacebookImage,
          },
          {
            label: getLang(lang, "label.INSTAGRAM"),
            key: "instagram",
            description: getLang(lang, "paragraph.INSTAGRAM_DESCRIPTION"),
            image: InstagramImage,
          },
          {
            label: getLang(lang, "label.TIKTOK"),
            key: "tiktok",
            description: getLang(lang, "paragraph.TIKTOK_DESCRIPTION"),
            image: TikTokImage,
          },
        ]}
        handleClick={handleClick}
        expanded={expanded}
        handleExpand={handleExpand}
        isEditorMode={isEditorMode}
        lang={lang}
      />
      <ComponentPanel
        panelName="Campaign"
        title={getLang(lang, "label.CAMPAIGN")}
        types={[
          {
            label: getLang(lang, "label.LOYALTY_CARD"),
            key: enableAddLoyaltyPoint ? "loyalty point" : "",
            description: getLang(lang, "paragraph.LOYALTY_DESCRIPTION"),
            image: LoyaltyCardImage,
          },
          {
            label: getLang(lang, "label.SURVEY_CARD"),
            key: enableAddSurvey ? "survey" : "",
            description: getLang(lang, "paragraph.SURVEY_DESCRIPTION"),
            image: SurveyImage,
          },
          {
            label: getLang(lang, "label.LUCKY_DRAW"),
            key: enableAddLuckyDraw ? "lucky draw" : "",
            description: getLang(lang, "paragraph.LUCKY_DRAW_DESCRIPTION"),
            image: LuckyDrawImage,
          },
          {
            label: getLang(lang, "label.INSTANT_WIN"),
            key: enableAddInstantWin ? "instant win" : "",
            description: getLang(lang, "paragraph.INSTANT_WIN_DESCRIPTION"),
            image: InstantWinImage,
          },
        ]}
        handleClick={handleClick}
        expanded={expanded}
        handleExpand={handleExpand}
        isEditorMode={isEditorMode}
        lang={lang}
      />
    </Box>
  );
}

function ComponentPanel({
  panelName,
  title,
  types = [],
  handleClick,
  expanded,
  handleExpand,
  isEditorMode,
  lang,
}) {
  const classes = useStyle();
  return (
    <Accordion
      square
      expanded={expanded === panelName}
      onChange={handleExpand(panelName)}
      elevation={0}
      style={{
        margin: 0,
      }}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelName}-content`}
        id={`${panelName}-header`}
        classes={{
          root: classes.accordionBar,
          content: classes.accordionHeader,
          expandIcon: classes.expandIcon,
        }}
      >
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContent}>
        {types.map((item, index) => (
          <ComponentCard
            key={index}
            title={item.label}
            description={item.description}
            id={item.key}
            image={item.image}
            handleClick={item.key === 'facebook' ? null : () => handleClick(item.key)}
            isEditorMode={isEditorMode}
            lang={lang}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

function ComponentCard({
  title,
  description,
  id,
  image,
  handleClick,
  isEditorMode,
  lang,
}) {
  const classes = useStyle();

  return (
    <Paper elevation={0} style={{ padding: 10, margin: "10px 0px" }}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="subtitle2" className={classes.description}>
        {parse(description.toString())}
      </Typography>
      <Box className={classes.imgContainer}>
        <img src={image} className={classes.componentImage} alt="component" />
      </Box>
      {isEditorMode && (
        <Box mt={1.5} display="flex" justifyContent="flex-end">
          {handleClick && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
              disabled={id === ""}
              onClick={handleClick}
            >
              {getLang(lang, "label.ADD")}
            </Button>
          )}
        </Box>
      )}
    </Paper>
  );
}
