import React from "react";
import GalleryComponent from "./gallery.component";
import { useSelector } from "react-redux";

export default function GalleryContainer({ id, isTemplateEditor }) {
  const {
    isVisible,
    content: { images, order }
  } = useSelector(state => isTemplateEditor
    ? state.products.template.workspaceSetting.setting.components[id]
    : state.productPage.components[id]);
  const lang = useSelector(state => state.constant.languages);
  const isEnableFullscreenBtn = useSelector(state => isTemplateEditor
    ? state.products.template.workspaceSetting.setting.components[id].content.isEnableFullscreenBtn ?? false
    : state.productPage.components[id].content.isEnableFullscreenBtn ?? false);

  return (
    isVisible && <GalleryComponent order={order} images={images} lang={lang} isEnableFullscreenBtn={isEnableFullscreenBtn}/>
  );
}
