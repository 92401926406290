import axios from "axios";

const endpoint_url = "/campaignV2/customer/options/serialNumberList";

const getSerialNumberList = ({
  uuid,
  length,
  start,
  search,
  order,
  status,
  exclude,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          uuid: uuid,
          length: length,
          start: start,
          search: search,
          order: order,
          status: status,
          enc: exclude
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getSerialNumberList;
