import React, { useCallback } from "react";
import DraggablePrizeCardComponent from "./draggablePrizeCard.component";
import { cloneDeep } from "lodash";

export default function DraggablePrizeCardContainer({
  index,
  lang,
  prizes,
  prize,
  updatePrize,
  isSubmitting,
  formik,
}) {
  const moveCard = useCallback((dragIndex, hoverIndex, prizeList) => {
    let temp = cloneDeep(prizeList);

    let itemToMove = temp[dragIndex];
    temp.splice(dragIndex, 1);
    temp.splice(hoverIndex, 0, itemToMove);

    updatePrize(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
//Item, Credit value, Redemption code
  return (
    <DraggablePrizeCardComponent
      index={index}
      moveCard={moveCard}
      lang={lang}
      prize={prize}
      prizeList={prizes}
      updatePrize={updatePrize}
      isSubmitting={isSubmitting}
      formik={formik}
    />
  );
}
