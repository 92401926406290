import { getLang } from "app/feature/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";

export const actionList = ({
  luckyDraw,
  hasLuckyDrawEditAccessRight,
  lang,
  handleView,
  handleBinding,
  handleViewPrize,
  handleViewParticipants,
  handleViewWinner,
  handleDownloadWinner,
  handleLiveDraw,
  handlePublished,
  handleJoinClose,
  handleEnd,
}) => {
  let actionItems = [];
  let disableViewParticipant = luckyDraw.participants_count === 0;
  let disableBinding = [
    progressStatus.setBasic,
  ].includes(luckyDraw.progress);

  // manually close
  if (
    luckyDraw.progress === progressStatus.published &&
    Number(luckyDraw.type) === 1 &&
    !luckyDraw.join_close_at &&
    hasLuckyDrawEditAccessRight
  ) {
    actionItems.push({
      label: getLang(lang, "label.END_JOIN_PERIOD"),
      action: () => handleJoinClose(luckyDraw.uuid),
      errorText: true,
    });
  }

  // view detail
  actionItems.push({
    label: hasLuckyDrawEditAccessRight
      ? getLang(lang, "label.VIEW_EDIT_DETAIL")
      : getLang(lang, "label.VIEW_DETAIL"),
    action: () => handleView(luckyDraw.uuid),
  });

  // prize
  if (
    [
      progressStatus.new,
      progressStatus.setBasic,
      progressStatus.setPeriod,
    ].includes(luckyDraw.progress)
  ) {
    //only after progressed to set period can set prize
    if (luckyDraw.progress === progressStatus.setPeriod) {
      if (hasLuckyDrawEditAccessRight) {
        actionItems.push({
          label: getLang(lang, "label.SET_PRIZE"),
          action: () => handleViewPrize(luckyDraw.uuid, luckyDraw.progress),
        });
      }
    } else {
      actionItems.push({
        label: getLang(lang, "label.SET_PRIZE"),
        disabled: true,
        required: true,
        requiredText: getLang(lang, "label.REQUIRED_SET_PERIOD"),
      });
    }
  } else {
    actionItems.push({
      label: hasLuckyDrawEditAccessRight
        ? getLang(lang, "label.VIEW_EDIT_PRIZE")
        : getLang(lang, "label.VIEW_PRIZE"),
      action: () => handleViewPrize(luckyDraw.uuid, luckyDraw.progress),
    });
  }

  // binding
  actionItems.push({
    label: getLang(lang, "label.PRODUCT_BINDING"),
    action: () => handleBinding(luckyDraw.uuid),
    disabled: disableBinding,
  });

  // winner
  if (Number(luckyDraw.type) === 2) {
    if (
      luckyDraw.progress !== progressStatus.published &&
      hasLuckyDrawEditAccessRight
    ) {
      actionItems.push({
        label: getLang(lang, "label.SET_WINNER"),
        disabled: luckyDraw.progress !== progressStatus.setProduct,
        required: luckyDraw.progress !== progressStatus.setProduct,
        requiredText:
          luckyDraw.progress !== progressStatus.setProduct
            ? getLang(lang, "label.REQUIRED_SET_BINDING_CONFIGURATION")
            : null,
        action:
          luckyDraw.progress !== progressStatus.setProduct
            ? () => {}
            : () =>
                handleViewWinner(
                  luckyDraw.uuid,
                  luckyDraw.progress,
                  luckyDraw.type
                ),
      });
    } else {
      actionItems.push({
        label:
          !luckyDraw.end_at && hasLuckyDrawEditAccessRight
            ? getLang(lang, "label.VIEW_EDIT_WINNER")
            : getLang(lang, "label.VIEW_WINNER"),
        action: () =>
          handleViewWinner(luckyDraw.uuid, luckyDraw.progress, luckyDraw.type),
      });
    }
  } else if (Number(luckyDraw.type) === 1) {
    if (luckyDraw.progress === progressStatus.published) {
      actionItems.push({
        label:
          !luckyDraw.draw_start_at &&
          !luckyDraw.end_at &&
          hasLuckyDrawEditAccessRight
            ? getLang(lang, "label.SET_WINNER")
            : getLang(lang, "label.VIEW_WINNER"),
        action: () =>
          handleViewWinner(luckyDraw.uuid, luckyDraw.progress, luckyDraw.type),
      });
    }
  }

  if (
    hasLuckyDrawEditAccessRight &&
    luckyDraw.progress !== progressStatus.published
  ) {
    actionItems.push({
      label: getLang(lang, "label.PUBLISH"),
      disabled: luckyDraw.progress !== progressStatus.setProduct || luckyDraw.prize_quantity === 0,
      required: luckyDraw.progress !== progressStatus.setProduct || luckyDraw.prize_quantity === 0,
      requiredText:
        luckyDraw.progress !== progressStatus.setProduct ?
        getLang(lang, "label.REQUIRED_SET_BINDING_CONFIGURATION") :
        luckyDraw.prize_quantity === 0 ?
        getLang(lang, "label.LUCKY_DRAW_PRIZE_NOT_SET") :
        '',
      action:
        luckyDraw.progress !== progressStatus.setProduct || luckyDraw.prize_quantity === 0
          ? () => {}
          : () => handlePublished(luckyDraw.uuid, luckyDraw.progress),
    });
  }

  if ([progressStatus.published].includes(luckyDraw.progress)) {
    if (luckyDraw.draw_end_at) {
      // download winner
      actionItems.push({
        label: getLang(lang, "label.DOWNLOAD_WINNER_LIST"),
        action: () => handleDownloadWinner(luckyDraw.uuid),
      });
    }

    // view participant
    actionItems.push({
      label: getLang(lang, "label.VIEW_PARTICIPANTS"),
      action: () => handleViewParticipants(luckyDraw.uuid),
      disabled: disableViewParticipant,
    });

    // live draw
    if (Number(luckyDraw.type) === 1 && !luckyDraw.end_at) {
      actionItems.push({
        label: getLang(lang, "label.LIVE_DRAW"),
        action: () => handleLiveDraw(luckyDraw.uuid, luckyDraw.progress),
      });
    }

    // end lucky draw
    if (!luckyDraw.end_at && hasLuckyDrawEditAccessRight) {
      actionItems.push({
        label: getLang(lang, "label.END_LUCKY_DRAW"),
        action: () => handleEnd(luckyDraw.uuid),
        errorText: true,
      });
    }
  }

  return actionItems;
};
