import axios from "axios";

export function editProductTemplate(payload) {
  return new Promise((resolve, reject) => {
    axios.put(`/serialnumber/api/v1/product-ui-template/product/${payload.id}`, {
      template_id: payload.templateId,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  })
}
