import { selectLoading } from "modules/notification";
import { getProductData, getScanAgainstSuspendedData, getTotalScanTrending, getTotalSuspendedTrending, getTopSuspendedProductTrending, getTopSuspendedQrTrending } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductPanel from './productPanel.component';
import exportJsonAsXlsx from "../../../../../lib/exportHelper";

export default function ProductPanelContainer({
    currentFilter,
    urlTotalScan,
    urlTotalSuspended,
    urlTotalDistributionPerformance,
    urlProduct,
    urlSuspendedProduct,
    urlSuspendedQr,
    urlScanAgainstSuspended,
    pageType,
    translate
}){
    const dispatch = useDispatch();
    const dispatchTrending = useDispatch();
    const dispatchProduct = useDispatch();

    // (1) trending data -- start
    // (a) get trending: total scan qr data
    const isFetchingTotalScanTrending = useSelector(state => selectLoading(state, getTotalScanTrending.typePrefix));
    const totalScanTrendingData = useSelector(state => state.reporting.trending.totalScanData);

    // (b) get trending: total suspended qr data
    const isFetchingTotalSuspendedTrending = useSelector(state => selectLoading(state, getTotalSuspendedTrending.typePrefix));
    const totalSuspendedTrendingData = useSelector(state => state.reporting.trending.totalSuspendedData);

    // (c) get trending: total distribution performance data
    // const isFetchingTotalDistributionPerformance = useSelector(state => selectLoading(state, getTotalDistributionPerformance.typePrefix));
    // const totalDistributionPerformanceData = useSelector(state => state.reporting.trending.totalDistributionPerformanceData);

    // (d) get trending: top suspended product data
    const isFetchingTopSuspendedProductTrending = useSelector(state => selectLoading(state, getTopSuspendedProductTrending.typePrefix));
    const topSuspendedProductTrendingData = useSelector(state => state.reporting.trending.topSuspendedProductData);

    // (e) get trending: top suspended qr data
    const isFetchingTopSuspendedQrTrending = useSelector(state => selectLoading(state, getTopSuspendedQrTrending.typePrefix));
    const topSuspendedQrTrendingData = useSelector(state => state.reporting.trending.topSuspendedQrData);

    // (1) trending data -- end

    // (2) product data -- start
    const isFetchingProductData = useSelector(state => selectLoading(state, getProductData.typePrefix));
    const productData = useSelector(state => state.reporting.product.productDatalist);
    // (2) product data -- end

    const isFetchingScanAgainstSuspendedData = useSelector(state => selectLoading(state, getScanAgainstSuspendedData.typePrefix));
    const scanAgainstSuspendedData = useSelector(state => state.reporting.trending.scanAgainstSuspendedData);


    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "total_count",
        orderDirection: "desc",
    })
    
    useEffect(() => {

        dispatchTrending(getTotalScanTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            period: currentFilter.period,
            category_uuid: currentFilter.categoryUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalScan
        }));

        dispatchTrending(getTotalSuspendedTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            period: currentFilter.period,
            category_uuid: currentFilter.categoryUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalSuspended
        }));

        dispatchTrending(getTopSuspendedProductTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlSuspendedProduct
        }));

        dispatchTrending(getTopSuspendedQrTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlSuspendedQr
        }));

        // dispatchTrending(getTotalDistributionPerformance({
        //     countryCode: currentFilter.countryCode,
        //     city_uuid: currentFilter.cityUuid,
        //     branch_uuid: currentFilter.branchUuid,
        //     brand_uuid: currentFilter.brandUuid,
        //     model_uuid: currentFilter.modelUuid,
        //     period: currentFilter.period,
        //     category_uuid: currentFilter.categoryUuid,
        //     start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
        //     end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
        //     url: urlTotalDistributionPerformance
        // }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTrending, currentFilter]);

    useEffect(() => {
        dispatchProduct(getProductData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlProduct
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProduct, tableFilter, currentFilter]);

    useEffect(() => {
        dispatch(getScanAgainstSuspendedData({
            url: urlScanAgainstSuspended,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: currentFilter.dateRange[0],
            end_date: currentFilter.dateRange[1]
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.PRODUCTS"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.TOTAL_COUNT"),
            field: 'total_count',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SCAN_WEIGHTAGE"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SUSPENDED"),
            field: 'total_suspended',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.WARRANTY"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SURVEY"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.LUCKY_DRAW"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.INSTANT_LUCKY_DRAW"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.LOYALTY_PROGRAM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
    ]

    const suspendedProductColumns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.PRODUCT"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SUSPENDED"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.VALID_SCAN"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        }
    ]

    const suspendedQrColumns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SERIAL_NUMBER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SUSPENDED"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.VALID_SCAN"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        }
    ]

    const onQrScanExportClick = () => {
        const data = productData.rows.map((e, i) => ({
            "No.": i + 1,
            "Product(s)": e.name,
            "Total Count": e.total_count,
            "Scan Weightage": e.total_percentage,
            "Suspended": e.total_suspended,
            "Warranty": e.total_warranty,
            "Survey": e.total_survey,
            "Lucky Draw": e.total_luckydraw,
            "Instant Lucky Draw": e.total_instantluckydraw,
            "Loyalty Program": e.total_loyalty,
        }));

        exportJsonAsXlsx(`QRCodeScan_${moment().format('YYYYMMDD')}.xlsx`, data);
    }

    return (
        <ProductPanel  
            status={{
                isFetchingTotalScanTrending,
                isFetchingTotalSuspendedTrending,
                // isFetchingTotalDistributionPerformance,
                isFetchingProductData,
                isFetchingScanAgainstSuspendedData,
                isFetchingTopSuspendedProductTrending,
                isFetchingTopSuspendedQrTrending,
            }}
            data={{
                totalScanTrendingData,
                totalSuspendedTrendingData,
                // totalDistributionPerformanceData,
                productData,
                scanAgainstSuspendedData,
                topSuspendedProductTrendingData,
                topSuspendedQrTrendingData,
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columns = {columns}

            suspendedProductColumns={suspendedProductColumns}

            suspendedQrColumns={suspendedQrColumns}

            currentFilter={currentFilter}

            pageType={pageType}

            translate={translate}

            onQrScanExportClick={onQrScanExportClick}
        />
    )
} 
