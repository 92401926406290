import React from "react";
import LandingPageTemplateListTableComponent from './landingPageTemplateListTable.component';

export default function LandingPageTemplateListTableContainer({
  hasEditAccessRight,
  hasDeleteAccessRight,
  selectedId,
  paginationOptions,
  data,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  totalFiltered,
  rowsPerPage,
  handleCreate,
  handleSelect,
  handleDelete,
  handleEdit,
  lang
}) {
  return (
    <LandingPageTemplateListTableComponent
      hasEditAccessRight={hasEditAccessRight}
      hasDeleteAccessRight={hasDeleteAccessRight}
      selectedId={selectedId}
      paginationOptions={paginationOptions}
      data={data}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      search={search}
      totalFiltered={totalFiltered}
      handleCreate={handleCreate}
      handleSelect={handleSelect}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      lang={lang}
    />
  )
}
