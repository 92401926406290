import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) total scan qr -- start
export const getTotalScanTrending = createAsyncThunk(
    "reporting/getTotalScanTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalScanTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            model_uuid: payload.model_uuid,
            branch_uuid: payload.branch_uuid,
            brand_uuid: payload.brand_uuid,
            category_uuid: payload.category_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) total scan qr -- end

// (2) total suspended qr -- start
export const getTotalSuspendedTrending = createAsyncThunk(
    "reporting/getTotalSuspendedTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalSuspendedTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,   
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => {
                return response;
            })
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) total suspended qr -- end

// (3) total distribution performance -- start
export const getTotalDistributionPerformance = createAsyncThunk(
    "reporting/getTotalDistributionPerformance",
    async (payload, thunkApi) => {
        return reportingApi.getTotalDistributionPerformance({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (3) total distribution performance -- end

// (4) total reported qr -- start
export const getTotalReportedTrending = createAsyncThunk(
    "reporting/getTotalReportedTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalReportedTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (4) total reported qr -- end

// (5) campaign engaged -- start
export const getCampaignEngagedTrending = createAsyncThunk(
    "reporting/getCampaignEngagedTrending",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignEngagedTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (5) campaign engaged -- end

// (6) active dealer -- start
export const getActiveDealerTrending = createAsyncThunk(
    "reporting/getActiveDealerTrending",
    async (payload, thunkApi) => {
        return reportingApi.getActiveDealerTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (6) active dealer -- end

// (7) inactive dealer -- start
export const getInactiveDealerTrending = createAsyncThunk(
    "reporting/getInactiveDealerTrending",
    async (payload, thunkApi) => {
        return reportingApi.getInactiveDealerTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (7) inactive dealer -- end

// (8) dealer stock out -- start
export const getDealerStockOutTrending = createAsyncThunk(
    "reporting/getDealerStockOutTrending",
    async (payload, thunkApi) => {
        return reportingApi.getDealerStockOutTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (8) dealer stock out -- end

// (9) total recall -- start
export const getTotalRecallTrending = createAsyncThunk(
    "reporting/getTotalRecallTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalRecallTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (9) total recall -- end

// (10) new dealer -- start
export const getNewDealerTrending = createAsyncThunk(
    "reporting/getNewDealerTrending",
    async (payload, thunkApi) => {
        return reportingApi.getNewDealerTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (10) new dealer -- end

// (7) suspended scan qr -- start
export const getSuspendedScanQrData = createAsyncThunk(
    "reporting/getSuspendedScanQrData",
    async (payload, thunkApi) => {
        return reportingApi.getSuspendedScanQrData({
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (7) suspended scan qr -- end

// (12) total campaign survey -- start
export const getTotalCampaignSurveyTrending = createAsyncThunk(
    "reporting/getTotalCampaignSurveyTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalCampaignSurveyTrending({
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (12) total campaign survey -- end

// (13) campaign trending -- start
export const getCampaignTrendingData = createAsyncThunk(
    "reporting/getCampaignTrendingData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTrendingData({
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,


            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (13) campaign trending -- end

// (14) scan against suspended data -- start
export const getScanAgainstSuspendedData = createAsyncThunk(
    "reporting/getScanAgainstSuspendedData",
    async (payload, thunkApi) => {
        return reportingApi.getScanAgainstSuspendedData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            model_uuid: payload.model_uuid,
            branch_uuid: payload.branch_uuid,
            brand_uuid: payload.brand_uuid,
            category_uuid: payload.category_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (14) scan against suspended data -- end

// (15) request stockout -- start
export const getRequestStockoutTrending = createAsyncThunk(
    "reporting/getRequestStockoutTrending",
    async (payload, thunkApi) => {
        return reportingApi.getRequestStockoutTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (15) request stockout -- end

// (16) total package -- start
export const getTotalPackageTrending = createAsyncThunk(
    "reporting/getTotalPackageTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalPackageTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (16) total package -- end

// (17) total recalled package -- start
export const getTotalRecalledPackageTrending = createAsyncThunk(
    "reporting/getTotalRecalledPackageTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTotalRecalledPackageTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (17) total recalled package -- end

// (18) total item total package -- start
export const getItemTotalPackageTrending = createAsyncThunk(
    "reporting/getItemTotalPackageTrending",
    async (payload, thunkApi) => {
        return reportingApi.getItemTotalPackageTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (18) total item total package -- end

// (19) total item total request -- start
export const getItemTotalRequestTrending = createAsyncThunk(
    "reporting/getItemTotalRequestTrending",
    async (payload, thunkApi) => {
        return reportingApi.getItemTotalRequestTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (19) total item total request -- end

// (20) total item total reject -- start
export const getItemTotalRejectTrending = createAsyncThunk(
    "reporting/getItemTotalRejectTrending",
    async (payload, thunkApi) => {
        return reportingApi.getItemTotalRejectTrending({
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (20) total item total reject -- end

// (21) top suspended product -- start
export const getTopSuspendedProductTrending = createAsyncThunk(
    "reporting/getTopSuspendedProductTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTopSuspendedProductTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,  
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (21) top suspended product -- end

// (22) top suspended qr -- start
export const getTopSuspendedQrTrending = createAsyncThunk(
    "reporting/getTopSuspendedQrTrending",
    async (payload, thunkApi) => {
        return reportingApi.getTopSuspendedQrTrending({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (22) top suspended qr -- end
