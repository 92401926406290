import React, { useEffect, useState, useCallback } from "react";
import LandingPagePanelComponent from "./landingPagePanel.component";
import { connect, useSelector, useDispatch } from "react-redux";
import { getProductPage, updateProductPage } from "../../../redux/action/products.action";
import { selectLoading } from "modules/notification";
import { PRODUCT_PRODUCT_LIST_EDIT } from "lib/constants/accessRights";
import { setEditorMode } from "modules/product-mgmt/redux/slice/productPage.slice";
import { getConsumerPageSetting } from "modules/account/redux";
import { resetAvailableSurveys } from "modules/survey/redux/slice";
import { fetchTemplateDropdown } from "../../../redux/action/template.action";

function LandingPagePanelContainer({
  enableEditorMode,
  disableEditorMode,
  getProductPage,
  isLoading,
  isError,
  createdAt,
  updatedAt,
  isEditorMode,
  getConsumerPageSetting,
  template,
  templateAfter
}) {
  const hasProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT))
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    dispatch(fetchTemplateDropdown());

    return () => {
      dispatch(resetAvailableSurveys());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProduct = useCallback(() => {
    getProductPage();
    getConsumerPageSetting();
    setHasLoaded(true);
  }, [getProductPage, getConsumerPageSetting]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const handleNavigateEditor = () => {
    if (hasProductEditAccessRight) {
      enableEditorMode();
    }
  };

  const handleCancel = () => {
    if (hasProductEditAccessRight) {
      disableEditorMode()
      getProductPage();
    }
  }

  const handleSubmit = () => {
    dispatch(updateProductPage())
    disableEditorMode()
  }

  return (
    <LandingPagePanelComponent
      hasProductEditAccessRight={hasProductEditAccessRight}
      handleNavigateEditor={handleNavigateEditor}
      isLoading={isLoading}
      isError={isError}
      hasLoaded={hasLoaded}
      createdAt={createdAt}
      updatedAt={updatedAt}
      isEditorMode={isEditorMode}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      lang={lang}
      template={template}
      templateAfter={templateAfter}
    />
  );
}

const mapStateToProps = state => ({
  isError: state.productPage.error,
  isLoading: selectLoading(state, getProductPage.typePrefix) || selectLoading(state, fetchTemplateDropdown.typePrefix),
  createdAt: state.productPage.pageCreatedAt,
  updatedAt: state.productPage.pageUpdatedAt,
  isEditorMode: state.productPage.isEditorMode,
  template: state.productPage.template,
  templateAfter: state.productPage.templateAfter,
});

const mapDispatchToProps = dispatch => ({
  fetchTemplateDropdown: () => dispatch(fetchTemplateDropdown()),
  enableEditorMode: () => dispatch(setEditorMode(true)),
  disableEditorMode: () => dispatch(setEditorMode(false)),
  getProductPage: () => dispatch(getProductPage()),
  getConsumerPageSetting: () => dispatch(getConsumerPageSetting())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPagePanelContainer);
