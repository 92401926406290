import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { ProductPagePreviewDialog } from '../../dialog'
import PageWorkspacePanelComponent from './pageWorkspacePanel.component'
import {setPageWorkspaceLanguage} from "../../../redux/slice/productPage.slice";
import {fetchCustomTemplateHtml, fetchPreviewTemplateSettings} from "../../../redux/action/template.action";
import {unwrapResult} from "@reduxjs/toolkit";

export default function PageWorkspacePanelContainer() {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const lang = useSelector(state => state.constant.languages);
  const productLanguages = useSelector(state => state.productPage.lang);
  const currentLanguage = useSelector(state => state.productPage.pageWorkspaceLanguage);
  const dispatch = useDispatch();
  const templateAfter = useSelector(state => state.productPage.templateAfter);
  const templateDropdown = useSelector(state => state.productPage.templateDropdown);
  const customTemplateHtml = useSelector(state => state.products.template.workspaceSetting.customHtml);
  const [isCustomUi, setIsCustomUi] = useState(false);
  useEffect(() => {
    if (templateAfter && templateAfter !== -1) {
      const templateSelected = templateDropdown.find(template => template.uuid === templateAfter);
      if (templateSelected?.is_custom_ui) {
        dispatch(fetchCustomTemplateHtml({template: templateSelected.settings_json_url, isGenuine: true}))
          .then(unwrapResult)
          .then(() => setIsCustomUi(true));
      }
      else {
        dispatch(fetchPreviewTemplateSettings(templateSelected?.settings_json_url))
          .then(unwrapResult)
          .then(() => setIsCustomUi(false));
      }
    }
    else {
      setIsCustomUi(false)
    }
  }, [templateAfter, dispatch, templateDropdown]);

  const handlePreview = () => {
      setIsPreviewDialogOpen(true)
  }

  const onSetLanguage = (language) => {
    dispatch(setPageWorkspaceLanguage(language));
  }

  return (
    <>
      <PageWorkspacePanelComponent
        handlePreview={handlePreview}
        lang={lang}
        productLanguages={productLanguages}
        currentLanguage={currentLanguage}
        onSetLanguage={onSetLanguage}
        templateAfter={templateAfter}
        isCustomUi={isCustomUi}
        customTemplateHtml={customTemplateHtml}
      />
      <ProductPagePreviewDialog
        isOpen={isPreviewDialogOpen}
        handleClose={() => setIsPreviewDialogOpen(false)}
        isEditTemplate={false}
      />
    </>
  )
}
