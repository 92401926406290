import axios from "axios";

const endpoint_url = "/campaignV2/customer/instantWin/dropdownList";

const getAvailableInstantWinDropdown = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          limit: parameters.length,
          skip: parameters.start,
          search: parameters.search,
          product_uuid: parameters.product_uuid,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAvailableInstantWinDropdown;
