import moment from "moment";

const API_HOST = process.env.REACT_APP_API_HOST_URL;

const saveFile = async (blob) => {
  const a = document.createElement("a");
  const date = moment().format("YYYYMMDD");
  a.download = `participants${date}.csv`;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const exportParticipantsList = async ({ uuid, token }) => {
  let response = await fetch(
    `${API_HOST}/campaignV2/customer/luckyDraw/participantsExport?uuid=${uuid}`,
    {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  let blob = await response.blob();

  saveFile(blob);
};

export default exportParticipantsList;
