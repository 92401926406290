import axios from "axios";

const getLuckyDrawLiveDrawInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/campaignV2/customer/luckyDrawSession/getDrawSession?uuid=${uuid}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getLuckyDrawLiveDrawInfo;
