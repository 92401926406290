import { createSlice } from "@reduxjs/toolkit";
import { ASSIGN_BATCH } from "../../constants";
import {
  checkSNUnassignAvailability,
  getAssignListing,
  getUnassignDetail,
  getUnassignListing,
  getUnassignSnListByProduct,
} from "../action/assignBatch.action";
import { getAssignFailJob } from "../action/assignBatch.action";
import { getExportFileDropdown } from "../action";

const initialState = {
  assignBatchTable: {
    data: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  selectedBatchFailJob: [],
  isFetchFailJobError: false,
  exportFile: [],
  isOpenUnassignDialog: false,
  unassignTable: {
    data: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  unassignSnList: {
    data: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  snAvailabilityList: {
    data: [],
    isError: false,
  },
  unassignDetail: {
    data: null,
    isError: false,
  },
};

const assignBatchSlice = createSlice({
  name: ASSIGN_BATCH,
  initialState,
  reducers: {
    resetAssignList(state) {
      state.assignBatchTable = initialState.assignBatchTable;
    },
    openUnassignDialog(state) {
      state.isOpenUnassignDialog = true;
    },
    closeUnassignDialog(state) {
      state.isOpenUnassignDialog = false;
    },
    resetUnasisgnSnList(state) {
      state.unassignSnList = initialState.unassignSnList;
    },
    resetSNUnassignAvailabilityList(state) {
      state.snAvailabilityList = initialState.snAvailabilityList;
    },
    resetUnassignDetail(state) {
      state.unassignDetail = initialState.unassignDetail;
    },
  },
  extraReducers: {
    [getAssignListing.pending]: (state) => {
      state.assignBatchTable = initialState.assignBatchTable;
    },
    [getAssignListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.assignBatchTable.totalRecords = payload.recordsTotal;
      state.assignBatchTable.totalFiltered = payload.recordsFiltered;

      let temp = [];
      payload.data.forEach((item) => {
        temp.push({
          id: item.id,
          uuid: item.uuid,
          batchNumber: item.batch_number,
          product: item.product,
          status: item.status,
          progress: item.progress,
          date: item.created_at,
          quantity: item.quantity,
          // fromSeqNum:item.fromSeqNum,
          // toSeqNum:item.toSeqNum,
          retry_count: item.retry_count,
        });
      });
      state.assignBatchTable.data = temp;
    },
    [getAssignListing.rejected]: (state) => {
      state.assignBatchTable.isError = true;
    },
    [getAssignFailJob.pending]: (state) => {
      state.selectedBatchFailJob = initialState.selectedBatchFailJob;
      state.isFetchFailJobError = false;
    },
    [getAssignFailJob.fulfilled]: (state, action) => {
      const { payload } = action;
      state.selectedBatchFailJob = payload;
    },
    [getExportFileDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      state.exportFile = payload;
    },
    [getAssignFailJob.rejected]: (state) => {
      state.isFetchFailJobError = true;
    },
    [getUnassignListing.pending]: (state) => {
      state.unassignTable = initialState.unassignTable;
    },
    [getUnassignListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.unassignTable.totalRecords = payload.recordsTotal;
      state.unassignTable.totalFiltered = payload.recordsFiltered;

      let temp = [];
      payload.data.forEach((item) => {
        temp.push({
          id: item.id,
          uuid: item.uuid,
          branch: item.node,
          products: item.products,
          status: Number(item.status),
          date: item.created_at,
          quantity: item.total_qr,
          is_retry_available: item.is_retry_available,
        });
      });
      state.unassignTable.data = temp;
    },
    [getUnassignListing.rejected]: (state) => {
      state.assignBatchTable.isError = true;
    },
    [getUnassignSnListByProduct.pending]: (state) => {
      state.unassignSnList = initialState.unassignSnList;
    },
    [getUnassignSnListByProduct.fulfilled]: (state, action) => {
      const { payload } = action;
      state.unassignSnList.totalRecords = payload.recordsTotal;
      state.unassignSnList.totalFiltered = payload.recordsFiltered;
      state.unassignSnList.data = payload.data;
    },
    [getUnassignSnListByProduct.rejected]: (state) => {
      state.unassignSnList.isError = true;
    },
    [checkSNUnassignAvailability.pending]: (state) => {
      state.snAvailabilityList = initialState.snAvailabilityList;
    },
    [checkSNUnassignAvailability.rejected]: (state) => {
      state.snAvailabilityList.isError = true;
    },
    [checkSNUnassignAvailability.fulfilled]: (state, action) => {
      const { payload } = action;

      state.snAvailabilityList.data = payload;
      state.snAvailabilityList.isError = false;
    },
    [getUnassignDetail.pending]: (state) => {
      state.unassignDetail = initialState.unassignDetail;
    },
    [getUnassignDetail.rejected]: (state) => {
      state.unassignDetail.isError = true;
    },
    [getUnassignDetail.fulfilled]: (state, action) => {
      const { payload } = action;

      state.unassignDetail.data = payload;
      state.unassignDetail.isError = false;
    },
  },
});

export const {
  resetAssignList,
  openUnassignDialog,
  closeUnassignDialog,
  resetUnasisgnSnList,
  resetSNUnassignAvailabilityList,
  resetUnassignDetail,
} = assignBatchSlice.actions;
export const assignBatchReducer = assignBatchSlice.reducer;
