import axios from "axios";

export function fetchCustomTemplateHtml(payload) {
  return new Promise((resolve, reject) => {
    axios.post(`/consumer/admin/api/v1/template-preview`, {
      template: payload.template,
      is_genuine: payload.isGenuine,
    })
      .then((response) => resolve({...response.data, data: response}))
      .catch((error) => reject(error.response.data))
  })
}
