import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import defaultPhoto from "assets/img/default-profile-picture.png";
import googleLoginIcon from "assets/img/googleLoginIcon.png";
import facebookLoginIcon from "assets/img/facebookLoginIcon.png";
import lineLoginIcon from "assets/img/lineLoginIcon.png";
import appleLoginIcon from "assets/img/appleLoginIcon.png";
import { TablePaginationActions } from "components/tablePagination";
import { ActionButton } from "components/button";
import { Alert } from "@material-ui/lab";
import RefreshTable from "components/table/refreshTable";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/consumer/config/consumer.route";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell"
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px",
  },
}));

export default function ConsumerTableComponent({
  consumers,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  isLoadingConsumerError,
  handleReload,
  paginationOptions,
  handleEditLoyaltyPoint,
  lang,
}) {
  const classes = useStyle()
  const history = useHistory()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {consumers.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="counterfeir report table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PROFILE_PICTURE")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EMAIL")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.CONTACT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.GENDER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DATE_OF_BIRTH")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LOYALTY_POINT_BALANCE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SOCIAL_MEDIA_LOGIN")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.JOINED_DATE")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow key={index} columnCount={11} />
                  ))
                : consumers.map((item, index) => {
                    const actionItems = [
                      {
                        label: getLang(lang, "label.VIEW_DETAILS"),
                        action: () => {
                          history.push(`${ROOT}/${item.id}`);
                        },
                      },
                      {
                        label: getLang(lang, "label.EDIT_LOYALTY_POINT"),
                        action: () =>
                          handleEditLoyaltyPoint({ item }),
                      }
                    ];

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <StyledTableCell>
                            {index + 1 + rowsPerPage * page}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>
                          <img
                            src={item.profilePicture ?? defaultPhoto}
                            alt="profile"
                            style={{
                              width: 60,
                              height: 60,
                              objectFit: "contain",
                              borderRadius: "50%",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.firstName +
                            " " +
                            (item.lastName ? item.lastName : "")}
                        </StyledTableCell>
                        <StyledTableCell>{item.email}</StyledTableCell>
                        <StyledTableCell>
                          {item.phoneCode ? `${item.phoneCode} ` : ""}
                          {item.phoneNumber || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.gender
                            ? item.gender === "Male"
                              ? getLang(lang, "label.MALE")
                              : getLang(lang, "label.FEMALE")
                            : "-"}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </StyledTableCell>
                        <StyledTableCell>{item.dateOfBirth ? moment(item.dateOfBirth).format('ll') : "-"}</StyledTableCell>
                        <StyledTableCell>{(item.point || "0").toLocaleString("en-US")}</StyledTableCell>
                        <StyledTableCell align="center">
                          {!(item.googleId === null || item.googleId === "") ? (
                            <img
                              src={googleLoginIcon}
                              alt="Google Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={googleLoginIcon}
                              alt="Google Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {!(
                            item.facebookId === null || item.facebookId === ""
                          ) ? (
                            <img
                              src={facebookLoginIcon}
                              alt="Facebook Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={facebookLoginIcon}
                              alt="Facebook Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {!(item.lineId === null || item.lineId === "") ? (
                            <img
                              src={lineLoginIcon}
                              alt="Line Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={lineLoginIcon}
                              alt="Line Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {!(item.appleId === null || item.appleId === "") ? (
                            <img
                              src={appleLoginIcon}
                              alt="Apple Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={appleLoginIcon}
                              alt="Apple Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell>{moment(item.joinedDate).format('lll')}</StyledTableCell>
                        <StickyRightTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </div>
                          </StyledTableCell>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !search &&
        !isLoadingConsumerError && (
          <Box>
            <Table
              className={classes.table}
              size="small"
              aria-label="counterfeir report table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PROFILE_PICTURE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.EMAIL")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CONTACT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.GENDER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.DATE_OF_BIRTH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.LOYALTY_POINT_BALANCE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SOCIAL_MEDIA_LOGIN")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.JOINED_DATE")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "paragraph.NO_CONSUMER")}
              </Typography>
            </Box>
          </Box>
        )
      )}

      {!isFetching && consumers.length === 0 && search && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_RECORD_EXIST")}
        </Alert>
      )}

      {!isFetching && isLoadingConsumerError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && consumers.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
