/* eslint-disable react-hooks/exhaustive-deps */
import ConfirmationDialog from "components/dialog/confirmation";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import LuckyDrawListingPanelComponent from "./luckyDrawListingPanel.component";
import { addAlert, selectLoading } from "modules/notification";
import { LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { getApiLang, getLang } from "app/feature/constants";
import { getAllProducts } from "../../../../product-mgmt/redux/action/products.action";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import LiveDrawSetupDialog from "../../dialog/liveDrawSetupDialog";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import {
  INSTANT_WIN_CREATE,
  LUCKY_DRAW_CREATE,
  ROOT,
} from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import { useHistory } from "react-router-dom";
import LuckyDrawBindingDialog from "../../dialog/luckyDrawBindingDialog";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import { useLuckyDraw } from "../../../context/luckyDraw.context";
const LuckyDrawListingPanelContainer = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );

  const productDropdown = useSelector((state) => state.products.allProducts);
  const isFetchingProduct = useSelector((state) =>
    selectLoading(state, getAllProducts.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const accessToken = useSelector((state) => state.session.accessToken);

  const { data, setData } = useLuckyDraw();
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredTotalRecords, setFilteredTotalRecords] = useState(0);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isEndJoinDialogOpen, setIsEndJoinDialogOpen] = useState(false);
  const [isEndLuckyDrawDialogOpen, setIsEndLuckyDrawDialogOpen] =
    useState(false);
  const [isLiveDrawSetupDialogOpen, setIsLiveDrawSetupDialogOpen] =
    useState(false);
  // const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [selectedLiveDrawUuid, setSelectedLiveDrawUuid] = useState(null);
  const [selectedPublishUuid, setSelectedPublishUuid] = useState(null);
  const [selectedUpdateLD, setSelectedUpdateLD] = useState(null);
  const [selectedBindingLD, setSelectedBindingLD] = useState(null);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    product: [],
    startPeriod: null,
    endPeriod: null,
    progress: [],
  });

  useImperativeHandle(ref, () => ({
    reloadData() {
      getData();
    },
  }));

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [tableFilter]);

  const getData = () => {
    if (isError) setIsError(false);
    setIsFetching(true);
    const params = {
      limit: tableFilter.rowsPerPage,
      skip: tableFilter.page * tableFilter.rowsPerPage,
      search: tableFilter.search,
      product: tableFilter.product,
      startPeriod: tableFilter.startPeriod,
      endPeriod: tableFilter.endPeriod,
      progress: tableFilter.progress,
    };
    luckyDrawV2Api
      .getLuckyDraws(params)
      .then((response) => {
        setData(response.data.lucky_draws);
        setTotalRecords(response.data.records_total);
        setFilteredTotalRecords(response.data.records_filtered);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const handleFetchLuckyDrawList = () => {
    // handleFetchLuckyDrawList
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: values.searchString,
      product: values.product.map((item) => item.id),
      startPeriod: values.startPeriod ? new Date(values.startPeriod).toISOString() : null,
      endPeriod: values.endPeriod ? new Date(values.endPeriod).toISOString() : null,
      progress: values.progress.map((item) => item.id),
    };
    setTableFilter(newData);
  };

  const handleStatusChange = ({ currentStatus, uuid }) => {
    // if (hasLuckyDrawActivateOrDeactivateAccessRight) {
    //   setSelectedUpdateUuid(uuid);
    //   if (currentStatus === "active") {
    //     setIsDeactivateDialogOpen(true);
    //   } else {
    //     setIsActivateDialogOpen(true);
    //   }
    // }
  };

  const handleStatusSubmit = (status) => {
    setIsStatusUpdating(true);
    luckyDrawV2Api
      .updateStatus({
        luckyDrawUuid: selectedUpdateLD.uuid,
        status: status ? "active" : "inactive",
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsStatusUpdating(false);
        setSelectedUpdateLD(null);
        setIsDeactivateDialogOpen(false);
        setIsActivateDialogOpen(false);
      });
  };

  const handleLiveDraw = (uuid, progress) => {
    setSelectedLiveDrawUuid(uuid);
    let ld = data.find((d) => d.uuid === uuid);
    if (ld && ld.draw_start_at) {
      history.push(`${ROOT}/live-draw/${uuid}`);
    } else {
      if (progress === progressStatus.published) {
        setIsLiveDrawSetupDialogOpen(true);
      } else {
        setIsConfirmationDialogOpen(true);
      }
    }
  };

  const handlePublished = (uuid, progress) => {
    if (progress === progressStatus.setProduct) {
      setSelectedPublishUuid(uuid);
      setIsConfirmationDialogOpen(true);
    }
  };

  const updateProgress = (isLiveDraw) => {
    let uuid = isLiveDraw ? selectedLiveDrawUuid : selectedPublishUuid;
    luckyDrawV2Api.publishLuckyDraw({ luckyDrawUuid: uuid }).then(() => {
      setIsConfirmationDialogOpen(false);
      if (isLiveDraw) {
        setIsLiveDrawSetupDialogOpen(true);
      }
      getData();
    });
  };

  const handleAddLuckyDraw = () => {
    history.push(LUCKY_DRAW_CREATE);
  };

  const handleAddInstantWin = () => {
    history.push(INSTANT_WIN_CREATE);
  };

  const handleBinding = (uuid) => {
    let selectedLuckyDraw = data.find((d) => d.uuid === uuid);
    setSelectedBindingLD(selectedLuckyDraw);
  };

  const handleDownloadWinner = (id) => {
    dispatch(showBackdrop());
    luckyDrawV2Api
      .exportWinnerList({ id: id, token: accessToken })
      .finally(() => {
        dispatch(hideBackdrop());
        return;
      });
  };

  const handleEnd = (uuid) => {
    let selected = data.find((d) => d.uuid === uuid);
    setSelectedUpdateLD(selected);
    setIsEndLuckyDrawDialogOpen(true);
  };

  const handleJoinClose = (uuid) => {
    let selected = data.find((d) => d.uuid === uuid);
    setSelectedUpdateLD(selected);
    setIsEndJoinDialogOpen(true);
  };

  const handleProceesEndJoin = () => {
    setIsStatusUpdating(true);
    luckyDrawV2Api
      .endLuckyDrawJoinPeriod({ uuid: selectedUpdateLD.uuid })
      .then(() => {
        getData();
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setSelectedUpdateLD(null);
        setIsEndJoinDialogOpen(false);
        setIsStatusUpdating(false);
      });
  };

  const handleProceesEndLuckyDraw = () => {
    setIsStatusUpdating(true);
    luckyDrawV2Api
      .endLuckyDraw({ uuid: selectedUpdateLD.uuid })
      .then(() => {
        getData();
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setSelectedUpdateLD(null);
        setIsEndLuckyDrawDialogOpen(false);
        setIsStatusUpdating(false);
      });
  };

  return (
    <>
      <LuckyDrawListingPanelComponent
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        isFetching={isFetching}
        tableFilter={tableFilter}
        totalRecords={filteredTotalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleStatusChange={handleStatusChange}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
        lang={lang}
        language={language}
        isError={isError}
        handleDownloadWinner={handleDownloadWinner}
        handleLiveDraw={handleLiveDraw}
        handleAddLuckyDraw={handleAddLuckyDraw}
        handleAddInstantWin={handleAddInstantWin}
        handleBinding={handleBinding}
        handlePublished={handlePublished}
        handleEnd={handleEnd}
        handleJoinClose={handleJoinClose}
      />
      <LuckyDrawBindingDialog
        isOpen={!!selectedBindingLD}
        handleClose={() => {
          setSelectedBindingLD(null);
        }}
        handleReload={() => getData()}
        luckyDraw={selectedBindingLD}
      />
      <LiveDrawSetupDialog
        isOpen={isLiveDrawSetupDialogOpen}
        handleClose={() => {
          setIsLiveDrawSetupDialogOpen(false);
          setSelectedLiveDrawUuid(null);
        }}
        uuid={selectedLiveDrawUuid}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang, "paragraph.DEACTIVATE_LD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        description={getLang(lang, "paragraph.ACTIVATE_LD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
        handleProceed={() => {
          let isLiveDraw = !!selectedLiveDrawUuid;
          updateProgress(isLiveDraw);
        }}
        type={"success"}
        remark={getLang(lang, "paragraph.PUBLISH_LD_REMARK")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.PUBLISH_LD_QUESTION")}
      />
      <GeneralDialog
        isOpen={isEndJoinDialogOpen}
        handleClose={() => setIsEndJoinDialogOpen(false)}
        handleProceed={() => {
          handleProceesEndJoin();
        }}
        type="danger"
        isLoading={isStatusUpdating}
        description={getLang(lang, "paragraph.END_LD_JOIN_PERIOD_QUESTION")}
        title={getLang(lang, "label.END_JOIN_PERIOD")}
        icon={<img src={AlertIcon} alt="alert" />}
      />
      <GeneralDialog
        isOpen={isEndLuckyDrawDialogOpen}
        handleClose={() => setIsEndLuckyDrawDialogOpen(false)}
        handleProceed={() => {
          handleProceesEndLuckyDraw();
        }}
        type="danger"
        isLoading={isStatusUpdating}
        description={
          selectedUpdateLD?.draw_start_at
            ? getLang(lang, "paragraph.END_LD_QUESTION")
            : getLang(lang, "paragraph.END_LD_LIVE_DRAW_WARNING_QUESTION")
        }
        title={getLang(lang, "label.END_LUCKY_DRAW")}
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  );
});

export default LuckyDrawListingPanelContainer;
