import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/presetWinner";

const presetWinner = ({ luckyDrawUuid, winnerField }) => {
  const body = {
    luckyDrawUuid: luckyDrawUuid,
    winnerField: winnerField,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default presetWinner;
