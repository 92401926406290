import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBranchDropdown,
  PRODUCTS,
  publishNewProduct,
  createNewProduct,
  getProductListing,
  fetchMeAndBranchDropdown,
  quickUpdateProduct,
  getBulkEditProductListing,
  getProductDropdown,
  getProductDropdownByBranch,
  getAllProducts,
  fetchMeAndBranchDropdownSpecial,
  updateProductStatus,
} from "../action/products.action";
import {
  getAllProductCategoryListing,
  updateCategoryStatus,
  getProductCategoryDropdown,
} from "../action/productCategory.action";
import {
  getAllBrandModelsListing,
  getBrandModelDropdown,
  getBrandDropdownWithNoBrand,
  updateBrandModelStatus,
} from "../action/brandModels.action";
import {
  getAllAttributeListing,
  updateAttributeStatus,
  getAttributeDropdown,
} from "../action/attribute.action";
import {
  fetchCustomTemplateHtml,
  fetchPreviewTemplateSettings,
  fetchTemplate,
  fetchTemplates,
  fetchTemplateSettings
} from "../action/template.action";
import {generateId} from "../../utils/productPage.util";

const initialState = {
  product: {},
  pages: [],
  branchDropdown: [],
  hasCreatedNewProduct: false,
  products: [],
  allProducts: [],
  selectedId: [],
  isSelectAll: false,
  isQuickEdit: false,
  totalRecords: 0,
  totalFiltered: 0,
  isLoadingProductError: false,
  productsDropdown: [],
  searchForProduct: "",
  categoryList: {
    totalRecords: 0,
    totalFiltered: 0,
    data: [],
    selectedId: [],
    isSelectAll: false,
    isError: false,
    isEdit: false,
    categoryDropdown: [],
  },
  brandModelList: {
    totalRecords: 0,
    totalFiltered: 0,
    hasNoBrandRecord: false,
    loading: false,
    error: null,
    selectedId: [],
    isSelectAll: false,
    data: [],
    isError: false,
    isEdit: false,
    brandModelDetails: {
      id: "",
      name: "",
      models: [],
      status: 0,
    },
    brandModelDropdown: [],
    brandDropdownWithNoBrand: [],
  },
  attributeList: {
    totalRecords: 0,
    totalFiltered: 0,
    data: [],
    selectedId: [],
    isSelectAll: false,
    details: {
      id: "",
      name: "",
      status: 0,
      options: [],
    },
    isError: false,
    isEdit: false,
    attributeDropdown: [],
  },
  bulkEditListFilter: {
    isselectedall: false,
    uuids: [],
    search: "",
    nodes: [],
    category: "",
    brand: [],
    model: [],
    attribute: [],
    attributeoption: [],
  },
  bulkEditFieldUpdateDetails: {
    category: "",
    brand: "",
    model: "",
    attribute: null,
    nodes: null,
    scanLimit: "",
    sku: "",
    currencyCode: "",
    unitPrice: "",
    incentivePoint: "",
    status: "",
  },
  templateList: {
    totalRecords: 0,
    totalFiltered: 0,
    data: [],
    selectedId: [],
    isSelectAll: false,
    isError: false,
    preview: null,
  },
  template: {
    id: null,
    name: null,
    url: null,
    isCustom: false,
    lastUpdated: null,
    assignedProducts: null,
    overrideWarningList: null,
    initialSetting: {
      lang: ['EN'],
      backgroundColor: '#ffffff',
      components: {
        authentication: {
          id: "authentication",
          type: "authentication",
          content: {
            messages: [
              {
                lang: "EN",
                value: ""
              }
            ]
          },
          isVisible: true
        }
      },
      columns: {
        id: 'column-1',
        compIds: ['authentication'],
      },
    },
    workspaceSetting: {
      pageWorkspaceLanguage: "EN",
      hasSaved: false,
      isEditorMode: true,
      setting: null,
      customHtml: null,
      isGenuine: 1,
    },
    error: false,
  }
};

const productsSlice = createSlice({
  name: PRODUCTS,
  initialState,
  reducers: {
    setBulkEditListFilter(state, action) {
      state.bulkEditListFilter = action.payload;
    },
    setBranchDropdown(state, action) {
      state.branchDropdown = action.payload;
    },
    setProductSearch(state, action) {
      const { payload } = action;
      state.searchForProduct = payload;
    },
    resetProductSearch(state) {
      state.searchForProduct = initialState.searchForProduct;
    },
    openCreationDialog(state) {
      state.isOpen = true;
    },
    closeCreationDialog(state) {
      state.isOpen = false;
    },

    bulkUpdateStatus(state, action) {
      const { payload } = action;
      if (payload.update.isselectedall) {
        state.products.forEach((product) => {
          if (!payload.update.uuid.includes(product.id)) {
            product.status = payload.update.status;
          }
        });
      } else if (payload.update.uuid.length > 0) {
        payload.update.uuid.forEach((uuid) => {
          let index = state.products.findIndex(
            (product) => product.id === uuid
          );

          if (index > -1) {
            state.products[index].status = payload.update.status;
          }
        });
      }
    },

    changeProductQuickEdit(state, action) {
      const { payload } = action;

      state.products[payload.index].isEdit = payload.isEdit;
      state.isQuickEdit = payload.isEdit;
    },
    changeQuickEdit(state, action) {
      const { payload } = action;

      state.categoryList.data[payload.index].isEdit = payload.isEdit;
      state.categoryList.isEdit = payload.isEdit;
    },
    disableCategoryQuickEdit(state) {
      state.categoryList.data = state.categoryList.data.map((c) => {
        c.isEdit = false;
        return c;
      });
      state.categoryList.isEdit = false;
    },
    refreshCategoryQuickEdit(state, action) {
      const { payload } = action;
      state.categoryList.data[payload.index].name = payload.name;
    },
    changeSelectedCategory(state, action) {
      const { payload } = action;

      state.categoryList.data[payload.index].isSelected = payload.isSelected;
      if (
        (!state.categoryList.isSelectAll && payload.isSelected) ||
        (state.categoryList.isSelectAll && !payload.isSelected)
      ) {
        state.categoryList.selectedId = [
          ...state.categoryList.selectedId,
          state.categoryList.data[payload.index].id,
        ];
      } else {
        state.categoryList.selectedId = state.categoryList.selectedId.filter(
          (id) => id !== state.categoryList.data[payload.index].id
        );
      }
    },
    changeBulkSelectedAllCategory(state, action) {
      const { payload } = action;

      state.categoryList.data.forEach((category) => {
        category.isSelected = payload.value;
      });
      state.categoryList.isSelectAll = payload.value;
      state.categoryList.selectedId = [];
    },
    changeBulkSelectedAvailableCategory(state, action) {
      const { payload } = action;
      state.categoryList.data.forEach((category) => {
        category.isSelected = payload.value;
        if (
          (!state.categoryList.isSelectAll && payload.value) ||
          (state.categoryList.isSelectAll && !payload.value)
        ) {
          if (
            state.categoryList.selectedId.findIndex(
              (id) => id === category.id
            ) === -1
          ) {
            state.categoryList.selectedId = [
              ...state.categoryList.selectedId,
              category.id,
            ];
          }
        } else {
          state.categoryList.selectedId = state.categoryList.selectedId.filter(
            (id) => id !== category.id
          );
        }
      });
    },
    setBrandModelEditDetails(state, action) {
      const { payload } = action;
      let modelTemp = [];

      state.brandModelList.brandModelDetails.id = payload.id;
      state.brandModelList.brandModelDetails.name = payload.name;
      state.brandModelList.brandModelDetails.status = payload.status;
      state.brandModelList.brandModelDetails.description = payload.description;
      state.brandModelList.brandModelDetails.imageUrl = payload.imageUrl;

      modelTemp = [...payload.models];

      state.brandModelList.brandModelDetails.models = [...modelTemp];
    },
    addNewFieldEditDetails(state) {
      let modelTemp = [...state.brandModelList.brandModelDetails.models];

      modelTemp.push({
        id: "",
        name: "",
        status: 1,
      });

      state.brandModelList.brandModelDetails.models = [...modelTemp];
    },
    changeSelectedBrandModel(state, action) {
      const { payload } = action;

      state.brandModelList.data[payload.index].isSelected = payload.isSelected;
      if (
        (!state.brandModelList.isSelectAll && payload.isSelected) ||
        (state.brandModelList.isSelectAll && !payload.isSelected)
      ) {
        state.brandModelList.selectedId = [
          ...state.brandModelList.selectedId,
          state.brandModelList.data[payload.index].id,
        ];
      } else {
        state.brandModelList.selectedId =
          state.brandModelList.selectedId.filter(
            (id) => id !== state.brandModelList.data[payload.index].id
          );
      }
    },
    changeBulkSelectedBrandModel(state, action) {
      const { payload } = action;

      state.brandModelList.data.forEach((item) => {
        item.isSelected = item.id !== "-1" && payload.value;
      });
      state.brandModelList.isSelectAll = payload.value;
      state.brandModelList.selectedId = [];
    },
    changeBulkSelectedAvailableBrandModel(state, action) {
      const { payload } = action;
      state.brandModelList.data.forEach((brand) => {
        brand.isSelected = brand.id !== "-1" && payload.value;
        if (brand.id !== "-1") {
          if (
            (!state.brandModelList.isSelectAll && payload.value) ||
            (state.brandModelList.isSelectAll && !payload.value)
          ) {
            if (
              state.brandModelList.selectedId.findIndex(
                (id) => id === brand.id
              ) === -1
            ) {
              state.brandModelList.selectedId = [
                ...state.brandModelList.selectedId,
                brand.id,
              ];
            }
          } else {
            state.brandModelList.selectedId =
              state.brandModelList.selectedId.filter((id) => id !== brand.id);
          }
        }
      });
    },
    changeSelectedAttribute(state, action) {
      const { payload } = action;

      state.attributeList.data[payload.index].isSelected = payload.isSelected;
      if (
        (!state.attributeList.isSelectAll && payload.isSelected) ||
        (state.attributeList.isSelectAll && !payload.isSelected)
      ) {
        state.attributeList.selectedId = [
          ...state.attributeList.selectedId,
          state.attributeList.data[payload.index].id,
        ];
      } else {
        state.attributeList.selectedId = state.attributeList.selectedId.filter(
          (id) => id !== state.attributeList.data[payload.index].id
        );
      }
    },
    changeBulkSelectedAttribute(state, action) {
      const { payload } = action;

      state.attributeList.data.forEach((attribute) => {
        attribute.isSelected = payload.value;
      });
      state.attributeList.isSelectAll = payload.value;
      state.attributeList.selectedId = [];
    },
    changeBulkSelectedAvailableAttribute(state, action) {
      const { payload } = action;
      state.attributeList.data.forEach((attribute) => {
        attribute.isSelected = payload.value;
        if (
          (!state.attributeList.isSelectAll && payload.value) ||
          (state.attributeList.isSelectAll && !payload.value)
        ) {
          if (
            state.attributeList.selectedId.findIndex(
              (id) => id === attribute.id
            ) === -1
          ) {
            state.attributeList.selectedId = [
              ...state.attributeList.selectedId,
              attribute.id,
            ];
          }
        } else {
          state.attributeList.selectedId =
            state.attributeList.selectedId.filter((id) => id !== attribute.id);
        }
      });
    },
    setAttributeEditDetails(state, action) {
      const { payload } = action;
      let temp = [];

      state.attributeList.details.id = payload.id;
      state.attributeList.details.name = payload.name;
      state.attributeList.details.status = payload.status;

      if (payload.options.length === 0) {
        temp.push({
          id: "",
          value: "",
          status: 1,
        });

        state.attributeList.details.options = [...temp];
      } else {
        temp = [...payload.options];

        state.attributeList.details.options = [...temp];
      }
    },
    changeSelectedProduct(state, action) {
      const { payload } = action;

      state.products[payload.index].isSelected = payload.isSelected;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedId = [
          ...state.selectedId,
          state.products[payload.index].id,
        ];
      } else {
        state.selectedId = state.selectedId.filter(
          (id) => id !== state.products[payload.index].id
        );
      }
    },
    changeBulkSelectedProduct(state, action) {
      const { payload } = action;

      state.products.forEach((product) => {
        product.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedId = [];
    },
    changeBulkSelectedAvailableProduct(state, action) {
      const { payload } = action;
      state.products.forEach((product) => {
        product.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedId.findIndex((id) => id === product.id) === -1) {
            state.selectedId = [...state.selectedId, product.id];
          }
        } else {
          state.selectedId = state.selectedId.filter((id) => id !== product.id);
        }
      });
    },
    changeBulkEditFieldUpdate(state, action) {
      const { payload } = action;

      let fieldKeyList = Object.keys(payload) || [];
      if (fieldKeyList.length) {
        fieldKeyList.forEach((fieldkey) => {
          state.bulkEditFieldUpdateDetails[fieldkey] = payload[fieldkey];
          if (fieldkey === "currencyCode") {
            state.bulkEditFieldUpdateDetails["currency_exp"] =
              payload[fieldkey].exp;
          }
        });

        state.products = state.products.map((item) => {
          fieldKeyList.forEach((fieldkey) => {
            if (fieldkey === "nodes") {
              let nodes = [...item["nodes"]];
              if (payload[fieldkey].length) {
                let result = payload[fieldkey].reduce((list, p) => {
                  if (!list.some((n) => n.node_id === p.node_id)) {
                    return list.concat(p);
                  }
                  return list;
                }, nodes);
                item[fieldkey] = result;
              } else {
                item[fieldkey] = item["initialNodes"];
              }
            } else {
              item[fieldkey] = payload[fieldkey];
            }

            if (fieldkey === "currencyCode") {
              item["currency_exp"] = payload[fieldkey].exp;
              item["unitPriceFormatted"] = Number(
                item["unitPrice"]
              ).toLocaleString(undefined, {
                minimumFractionDigits: item["currency_exp"],
                maximumFractionDigits: item["currency_exp"],
              });
            }

            if (fieldkey === "unitPrice") {
              item["unitPriceFormatted"] = Number(
                payload[fieldkey]
              ).toLocaleString(undefined, {
                minimumFractionDigits: item["currency_exp"],
                maximumFractionDigits: item["currency_exp"],
              });
            }
          });
          return item;
        });
      }
    },
    resetBrandModelListing(state) {
      state.brandModelList = initialState.brandModelList;
    },
    resetAttributesListing(state) {
      state.attributeList = initialState.attributeList;
    },
    resetCategoryListing(state) {
      state.categoryList = initialState.categoryList;
    },
    resetProductListing(state) {
      state.product = initialState.product;
      state.branchDropdown = initialState.branchDropdown;
      state.products = initialState.products;
      state.selectedId = initialState.selectedId;
      state.isSelectAll = initialState.isSelectAll;
      state.isQuickEdit = initialState.isQuickEdit;
      state.isLoadingProductError = initialState.isLoadingProductError;
      state.searchForProduct = initialState.searchForProduct;
      state.attributeList.attributeDropdown =
        initialState.attributeList.attributeDropdown;
      state.brandModelList.brandModelDropdown =
        initialState.brandModelList.brandModelDropdown;
      state.categoryList.categoryDropdown =
        initialState.categoryList.categoryDropdown;
    },
    resetBulkEditList(state) {
      // state.bulkEditListFilter = initialState.bulkEditListFilter
      state.bulkEditFieldUpdateDetails =
        initialState.bulkEditFieldUpdateDetails;
    },
    resetProductListingCountings(state) {
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
    },
    resetProductsDropdown(state) {
      state.productsDropdown = initialState.productsDropdown;
    },
    resetTemplatesListing(state) {
      state.templateList = initialState.templateList;
    },
    changeSelectedTemplate(state, action) {
      const { payload } = action;

      state.templateList.data[payload.index].isSelected = payload.isSelected;
      if (
        (!state.templateList.isSelectAll && payload.isSelected) ||
        (state.templateList.isSelectAll && !payload.isSelected)
      ) {
        state.templateList.selectedId = [
          ...state.templateList.selectedId,
          state.templateList.data[payload.index].id,
        ];
      } else {
        state.templateList.selectedId = state.templateList.selectedId.filter(
          (id) => id !== state.templateList.data[payload.index].id
        );
      }
    },
    changeBulkSelectedTemplate(state, action) {
      const { payload } = action;

      state.templateList.data.forEach((template) => {
        template.isSelected = payload.value;
      });
      state.templateList.isSelectAll = payload.value;
      state.templateList.selectedId = [];
    },
    changeBulkSelectedAvailableTemplate(state, action) {
      const { payload } = action;
      state.templateList.data.forEach((template) => {
        template.isSelected = payload.value;
        if (
          (!state.templateList.isSelectAll && payload.value) ||
          (state.templateList.isSelectAll && !payload.value)
        ) {
          if (
            state.templateList.selectedId.findIndex(
              (id) => id === template.id
            ) === -1
          ) {
            state.templateList.selectedId = [
              ...state.templateList.selectedId,
              template.id,
            ];
          }
        } else {
          state.templateList.selectedId =
            state.templateList.selectedId.filter((id) => id !== template.id);
        }
      });
    },
    setTemplateEditorMode(state, action) {
      state.template.workspaceSetting.isEditorMode = action.payload;
    },
    addTemplateComponent(state, action) {
      const componentId = generateId(
        action.payload.type,
        state.template.workspaceSetting.setting.columns.compIds
      );

      state.template.workspaceSetting.setting.columns.compIds = [...state.template.workspaceSetting.setting.columns.compIds, componentId];
      state.template.workspaceSetting.setting.components[componentId] = {
        id: componentId,
        type: action.payload.type,
        content: action.payload.content,
        isVisible: true,
      };
    },
    addTemplateLanguage(state, action) {
      state.template.workspaceSetting.setting.lang = [...state.template.workspaceSetting.setting.lang, action.payload];
    },
    deleteTemplateLanguage(state, action) {
      state.template.workspaceSetting.setting.lang = state.template.workspaceSetting.setting.lang.filter((lang) => lang !== action.payload);
    },
    reorderTemplateComponent(state, action) {
      const { id, sourceIndex, destinationIndex } = action.payload;
      const newCompIds = [...state.template.workspaceSetting.setting.columns.compIds];
      newCompIds.splice(sourceIndex, 1);
      newCompIds.splice(destinationIndex, 0, id);
      state.template.workspaceSetting.setting.columns.compIds = newCompIds;
    },
    deleteTemplateComponent(state, action) {
      const id = action.payload;
      const newComponents = { ...state.template.workspaceSetting.setting.components };
      delete newComponents[id];

      state.template.workspaceSetting.setting.components = newComponents;
      state.template.workspaceSetting.setting.columns.compIds = state.template.workspaceSetting.setting.columns.compIds.filter(
        (compId) => compId !== id
      );
    },
    hideTemplateComponent(state, action) {
      const id = action.payload;
      state.template.workspaceSetting.setting.components[id].isVisible = false;
    },
    showTemplateComponent(state, action) {
      const id = action.payload;
      state.template.workspaceSetting.setting.components[id].isVisible = true;
    },
    showMultipleTemplateComponents(state, action) {
      const { startIndex, endIndex } = action.payload;
      const compIds = state.template.workspaceSetting.setting.columns.compIds.slice(
        startIndex,
        endIndex + 1
      );
      compIds.forEach((id) => {
        state.template.workspaceSetting.setting.components[id].isVisible = true;
      });
    },
    updateTemplateComponent(state, action) {
      const { id, content } = action.payload;
      state.template.workspaceSetting.setting.components[id].content = content;
      state.template.workspaceSetting.hasSaved = false;
    },
    setCustomTemplateWorkspacePreviewState: (state, action) => {
      state.template.workspaceSetting.isGenuine = action.payload;
    }
  },
  extraReducers: {
    [fetchBranchDropdown.fulfilled]: (state, action) => {
      state.branchDropdown = action.payload.data;
    },
    [fetchMeAndBranchDropdown.fulfilled]: (state, action) => {
      state.branchDropdown = action.payload.data;
    },
    [fetchMeAndBranchDropdownSpecial.fulfilled]: (state, action) => {
      state.branchDropdown = action.payload.data;
    },
    [getBrandDropdownWithNoBrand.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.brandModelList.brandDropdownWithNoBrand = action.payload;
    },

    [publishNewProduct]: (state) => {
      state.hasCreatedNewProduct = false;
    },
    [createNewProduct.fulfilled]: (state) => {
      state.hasCreatedNewProduct = true;
    },
    [getProductListing.pending]: (state) => {
      state.products = [];
    },
    [getProductListing.fulfilled]: (state, action) => {
      const { payload } = action;
      let tempArray = [];

      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
      state.isLoadingProductError = false;

      payload.data.forEach((product) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === product.id) !== -1
          : state.selectedId.findIndex((id) => id === product.id) === -1;
        let tempAttribute = [];
        if (product.attributeoptions.length > 0) {
          product.attributeoptions.map((attribute) => {
            tempAttribute.push({
              id: attribute.attribute_uuid,
              name: attribute.name,
              optionsId: attribute.attributeoption_uuid,
              options: attribute.value,
            });

            return null;
          });
        }

        let nodeList = product.nodes.map((node) => ({
          ...node,
          node_id: node.id,
          node_parent_id: node.parent_id,
        }));

        tempArray.push({
          id: product.id,
          attribute: tempAttribute,
          brand:
            product.brand === null ? { id: "-1", name: "" } : product.brand,
          category: product.category,
          currencyCode:
            product.currency_code === null ? "" : product.currency_code,
          currencyExp: product.currency_exp,
          model:
            product.model === null ? { id: "-1", name: "" } : product.model,
          name: product.name,
          nodes: nodeList,
          initialNodes: nodeList,
          picture: product.picture,
          scanLimit: product.scanlimit,
          sku: product.sku,
          status: product.status,
          unitPrice: product.unit_price,
          unitPriceFormatted: Number(product.unit_price).toLocaleString(
            undefined,
            {
              minimumFractionDigits: product.currency_exp,
              maximumFractionDigits: product.currency_exp,
            }
          ),
          incentivePoint: product.incentive_point,
          isSelected: selectedState,
          isEdit: false,
          isAssignedSN: product.is_assigned_sn,
          totalAssigned: product.total_assigned,
          totalAdtInfo: product.total_adt_info,
          warrantyStatus: product.warranty_status
            ? product.warranty_status
            : "Not Assigned",
        });
      });

      state.products = tempArray;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      const { payload } = action;
      let tempArray = [];

      state.totalRecords = payload.recordsTotal;
      state.isLoadingProductError = false;
      payload.data.forEach((product) => {
        tempArray.push({
          id: product.id,
          name: product.name,
          picture: product.picture,
          attributes: product.attributes ?? [],
        });
      });

      state.allProducts = tempArray;
    },
    [getProductListing.rejected]: (state) => {
      state.isLoadingProductError = true;

      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getBulkEditProductListing.pending]: (state) => {
      state.products = [];
    },
    [getBulkEditProductListing.fulfilled]: (state, action) => {
      const { payload } = action;
      let tempArray = [];

      state.totalRecords = payload.recordsFiltered;
      state.totalFiltered = payload.recordsFiltered;
      state.isLoadingProductError = false;

      payload.data.forEach((product) => {
        let tempAttribute = [];
        if (product.attributeoptions.length > 0) {
          product.attributeoptions.map((attribute) => {
            tempAttribute.push({
              id: attribute.attribute_uuid,
              name: attribute.name,
              optionsId: attribute.attributeoption_uuid,
              options: attribute.value,
            });
            return null;
          });
        }

        let nodeList = product.nodes.map((node) => ({
          ...node,
          node_id: node.id,
          node_parent_id: node.parent_id,
        }));
        let newData = {
          id: product.id,
          attribute: state.bulkEditFieldUpdateDetails.attribute?.length
            ? state.bulkEditFieldUpdateDetails.attribute
            : tempAttribute,
          brand:
            state.bulkEditFieldUpdateDetails.brand || product.brand === null
              ? { id: "-1", name: "" }
              : product.brand,
          category:
            state.bulkEditFieldUpdateDetails.category || product.category,
          currencyCode:
            state.bulkEditFieldUpdateDetails.currencyCode ||
            (product.currency_code === null ? "" : product.currency_code),
          currencyExp: product.currency_exp,
          model:
            state.bulkEditFieldUpdateDetails.model ||
            (product.model === null ? { id: "-1", name: "" } : product.model),
          name: product.name,
          nodes: state.bulkEditFieldUpdateDetails.nodes?.length
            ? state.bulkEditFieldUpdateDetails.nodes
            : nodeList,
          initialNodes: nodeList,
          picture: product.picture,
          scanLimit:
            state.bulkEditFieldUpdateDetails.scanLimit || product.scanlimit,
          sku: state.bulkEditFieldUpdateDetails.sku || product.sku,
          status: state.bulkEditFieldUpdateDetails.status || product.status,
          unitPrice:
            state.bulkEditFieldUpdateDetails.price || product.unit_price,
          unitPriceFormatted: Number(product.unit_price).toLocaleString(
            undefined,
            {
              minimumFractionDigits: product.currency_exp,
              maximumFractionDigits: product.currency_exp,
            }
          ),
          incentivePoint:
            state.bulkEditFieldUpdateDetails.incentivePoint ||
            product.incentive_point,
          isSelected:
            (!state.isSelectAll && state.selectedId.includes(product.id)) ||
            (state.isSelectAll && !state.selectedId.includes(product.id)),
        };
        tempArray.push(newData);
      });
      state.products = tempArray;
    },
    [getBulkEditProductListing.rejected]: (state) => {
      state.isLoadingProductError = true;

      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getProductDropdown.fulfilled]: (state, action) => {
      const tempArray = [];

      action.payload.forEach((item) => {
        tempArray.push({
          id: item.product_id,
          name: item.name,
          picture: item.picture,
          warranty_setting_id: item.warranty_setting_id,
          status: item.status,
          attributes: item.attributes ?? [],
        });
      });

      state.productsDropdown = tempArray;
    },
    [getProductDropdownByBranch.pending]: (state) => {
      state.productsDropdown = initialState.productsDropdown;
    },
    [getProductDropdownByBranch.fulfilled]: (state, action) => {
      const tempArray = [];

      action.payload.forEach((item) => {
        tempArray.push({
          id: item.uuid,
          name: item.name,
          picture: item.picture,
          attributes: item.attributes,
        });
      });

      state.productsDropdown = tempArray;
    },
    [getAllProductCategoryListing.fulfilled]: (state, action) => {
      const { payload } = action;

      const temp = [];

      state.categoryList.totalRecords = payload.recordsTotal;
      state.categoryList.totalFiltered = payload.recordsFiltered;

      payload.data.forEach((item) => {
        let selectedState = !state.categoryList.isSelectAll
          ? state.categoryList.selectedId.findIndex((id) => id === item.id) !==
            -1
          : state.categoryList.selectedId.findIndex((id) => id === item.id) ===
            -1;
        temp.push({
          id: item.id,
          name: item.name,
          status: item.status,
          productCount: item.productcount.toLocaleString("en-US"),
          isSelected: selectedState,
          isEdit: false,
        });
      });

      state.categoryList.data = temp;
      state.categoryList.isError = false;
    },
    [getAllProductCategoryListing.rejected]: (state) => {
      state.categoryList.isError = true;
    },
    [updateCategoryStatus.fulfilled]: (state, action) => {
      const { payload } = action;

      const index = state.categoryList.data.findIndex(
        (response) => response.id === payload.id
      );

      if (index > -1) {
        state.categoryList.data[index].status = payload.status;
      }
    },
    [getAllBrandModelsListing.fulfilled]: (state, action) => {
      const { payload } = action;

      const temp = [];

      state.brandModelList.totalRecords = payload.recordsTotal;
      state.brandModelList.totalFiltered = payload.recordsFiltered;
      state.brandModelList.hasNoBrandRecord = payload.hasNoBrandRecord;

      payload.data.forEach((item) => {
        let selectedState = !state.brandModelList.isSelectAll
          ? state.brandModelList.selectedId.findIndex(
              (id) => id === item.id
            ) !== -1
          : state.brandModelList.selectedId.findIndex(
              (id) => id === item.id
            ) === -1;
        temp.push({
          id: item.id,
          name: item.name,
          models: [...item.models],
          description: item.description,
          imageUrl: item.image_url,
          productCount: item.product_count.toLocaleString("en-US"),
          status: item.status,
          isSelected: item.id !== "-1" && selectedState,
        });
      });

      state.brandModelList.data = temp;
      state.brandModelList.isError = false;
    },
    [getAllBrandModelsListing.rejected]: (state) => {
      state.brandModelList.isError = true;
    },
    [getBrandModelDropdown.fulfilled]: (state, action) => {
      const tempArray = [];
      const { payload } = action;

      payload.forEach((item) => {
        const tempModel = [];
        item.models.forEach((model) => {
          tempModel.push({
            id: model.id,
            name: model.name,
            selected: false,
          });
        });

        tempArray.push({
          id: item.id,
          name: item.name,
          selected: false,
          models: [...tempModel],
        });
      });

      state.brandModelList.brandModelDropdown = [...tempArray];
    },
    [updateBrandModelStatus.fulfilled]: (state, action) => {
      const { payload } = action;

      const index = state.brandModelList.data.findIndex(
        (response) => response.id === payload.id
      );

      if (index > -1) {
        state.brandModelList.data[index].status = payload.status;
      }
    },
    [getAllAttributeListing.fulfilled]: (state, action) => {
      const { payload } = action;

      const temp = [];

      state.attributeList.totalRecords = payload.recordsTotal;
      state.attributeList.totalFiltered = payload.recordsFiltered;

      payload.data.forEach((item) => {
        let selectedState = !state.attributeList.isSelectAll
          ? state.attributeList.selectedId.findIndex((id) => id === item.id) !==
            -1
          : state.attributeList.selectedId.findIndex((id) => id === item.id) ===
            -1;
        temp.push({
          id: item.id,
          name: item.name,
          status: item.status,
          productCount: item.products_count.toLocaleString("en-US"),
          options: item.attributeoptions,
          isSelected: selectedState,
        });
      });

      state.attributeList.data = temp;
      state.attributeList.isError = false;
    },
    [getAllAttributeListing.rejected]: (state) => {
      state.attributeList.isError = true;
    },
    [updateAttributeStatus.fulfilled]: (state, action) => {
      const { payload } = action;

      const index = state.attributeList.data.findIndex(
        (response) => response.id === payload.id
      );

      if (index > -1) {
        state.attributeList.data[index].status = payload.status;
      }
    },
    [updateProductStatus.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.products.findIndex(
        (response) => response.id === payload.id
      );

      if (index > -1) {
        state.products[index].status = payload.status;
      }
    },

    [getProductCategoryDropdown.fulfilled]: (state, action) => {
      const { payload } = action;

      state.categoryList.categoryDropdown = payload.data;
    },
    [getAttributeDropdown.fulfilled]: (state, action) => {
      const tempArray = [];
      const { payload } = action;

      payload.forEach((item) => {
        tempArray.push({
          id: item.id,
          name: item.name,
          options: item.attributeoptions,
          isSelected: false,
          delete: false,
        });
      });

      state.attributeList.attributeDropdown = [...tempArray];
    },
    [quickUpdateProduct.fulfilled]: (state, action) => {
      const { payload } = action;
      let attributeTemp = [];

      payload.attributeoptions.map((attribute) => {
        attributeTemp.push({
          id: attribute.attribute_uuid,
          name: attribute.name,
          options: attribute.value,
          optionsId: attribute.attributeoption_uuid,
        });

        return null;
      });

      const index = state.products.findIndex(
        (product) => product.id === payload.uuid
      );

      state.products[index].name = payload.name;
      state.products[index].sku = payload.sku;
      state.products[index].status = payload.status;
      state.products[index].category = payload.category;
      state.products[index].brand =
        payload.brand === null ? { id: "-1", name: "" } : payload.brand;
      state.products[index].model =
        payload.model === null ? { id: "-1", name: "" } : payload.model;
      state.products[index].attribute = attributeTemp;
      state.products[index].nodes = payload.nodes.map((node) => ({
        ...node,
        node_id: node.id,
        node_parent_id: node.parent_id,
      }));
      state.products[index].currencyCode = payload.currency_code;
      state.products[index].unitPrice = parseFloat(payload.unit_price);
      state.products[index].unitPriceFormatted = Number(
        payload.unit_price
      ).toLocaleString(undefined, {
        minimumFractionDigits: payload.currency_exp,
        maximumFractionDigits: payload.currency_exp,
      });
      state.products[index].scanLimit = payload.scanlimit;
      state.products[index].incentivePoint = payload.incentive_point;
      state.products[index].isEdit = false;
      state.isQuickEdit = false;
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      const { payload } = action;

      state.templateList.totalRecords = payload.totalRecords;
      state.templateList.totalFiltered = payload.totalFiltered;
      state.templateList.data = payload.records.map((template) => ({
        id: template.uuid,
        name: template.name,
        isCustom: !!template.is_custom_ui,
        type: template.is_custom_ui ? 'label.CUSTOM' : 'label.NORMAL',
        lastUpdated: template.updated_at,
        assignedProduct: template.products,
        url: template.settings_json_url,
      }));
    },
    [fetchTemplate.rejected]: (state) => {
      state.templateList.isError = true;
    },
    [fetchTemplate.fulfilled]: (state, action) => {
      const { payload } = action;

      state.template.name = payload.name;
      state.template.id = payload.uuid;
      state.template.lastUpdated = payload.updated_at;
      state.template.url = payload.settings_json_url;
      state.template.assignedProducts = payload.products;
      state.template.isCustom = payload.is_custom_ui;
    },
    [fetchTemplates.rejected]: (state) => {
      state.template.error = true;
    },
    [fetchTemplateSettings.fulfilled]: (state, action) => {
      state.template.initialSetting = action.payload.data;
      state.template.workspaceSetting.setting = action.payload.data;
    },
    [fetchPreviewTemplateSettings.pending]: (state) => {
      state.template.workspaceSetting.setting = null;
    },
    [fetchPreviewTemplateSettings.fulfilled]: (state, action) => {
      state.template.workspaceSetting.setting = action.payload.data;
    },
    [fetchCustomTemplateHtml.pending]: (state) => {
      state.template.workspaceSetting.customHtml = null;
    },
    [fetchCustomTemplateHtml.fulfilled]: (state, action) => {
      state.template.workspaceSetting.customHtml = action.payload.data;
    },
  },
});

export const {
  setBulkEditListFilter,
  setBranchDropdown,
  setProductSearch,
  resetProductSearch,
  openCreationDialog,
  closeCreationDialog,
  disableCategoryQuickEdit,
  changeQuickEdit,
  refreshCategoryQuickEdit,
  changeSelectedCategory,
  changeBulkSelectedAllCategory,
  changeBulkSelectedAvailableCategory,
  setBrandModelEditDetails,
  addNewFieldEditDetails,
  changeSelectedAttribute,
  changeBulkSelectedAttribute,
  changeBulkSelectedAvailableAttribute,
  setAttributeEditDetails,
  changeBulkSelectedBrandModel,
  changeSelectedBrandModel,
  changeBulkSelectedAvailableBrandModel,
  changeSelectedProduct,
  changeBulkSelectedProduct,
  changeProductQuickEdit,
  changeBulkSelectedAvailableProduct,
  changeBulkEditFieldUpdate,
  resetBrandModelListing,
  resetAttributesListing,
  resetCategoryListing,
  resetBulkEditList,
  resetProductListing,
  resetProductListingCountings,
  resetProductsDropdown,
  bulkUpdateStatus,
  resetTemplatesListing,
  changeSelectedTemplate,
  changeBulkSelectedTemplate,
  changeBulkSelectedAvailableTemplate,
  addTemplateComponent,
  addTemplateLanguage,
  deleteTemplateLanguage,
  reorderTemplateComponent,
  deleteTemplateComponent,
  hideTemplateComponent,
  showTemplateComponent,
  showMultipleTemplateComponents,
  updateTemplateComponent,
  setTemplateEditorMode,
  setCustomTemplateWorkspacePreviewState,
} = productsSlice.actions;
export const productsReducer = productsSlice.reducer;
