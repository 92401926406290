import React, {} from "react";
import { useSelector } from "react-redux";
import LuckyDrawPageComponent from "./luckyDraw.page";
import { LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import { useHistory } from "react-router-dom";
import {
  INSTANT_WIN_CREATE,
  LUCKY_DRAW_CREATE,
} from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import {LuckyDrawProvider} from "../../context/luckyDraw.context";
function LuckyDrawPageContainer() {
  const history = useHistory();
  const lang = useSelector((state) => state.constant.languages);
  const hasLuckyDrawAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );

  const handleAddLuckyDraw = () => {
    history.push(LUCKY_DRAW_CREATE);
  };
  const handleAddInstantWin = () => {
    history.push(INSTANT_WIN_CREATE);
  };

  return (
    <>
      <LuckyDrawProvider>
        <LuckyDrawPageComponent
          hasLuckyDrawAddAccessRight={hasLuckyDrawAddAccessRight}
          handleAddLuckyDraw={handleAddLuckyDraw}
          handleAddInstantWin={handleAddInstantWin}
          lang={lang}
        />
      </LuckyDrawProvider>
    </>
  );
}

export default LuckyDrawPageContainer;
