import React, { useState, useRef, useEffect } from "react";
import BranchSearchSelectComponent from "./BranchSearchSelect.component";
import BranchPanelComponent from "./branchPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { useLocation } from "react-router-dom";
import qs from "query-string";

function BranchSearchSelectContainer({ 
  placeholder, 
  handleChange, 
  value, 
  disabled, 
  styles, 
  textStyles,
  handleChipDelete,
  isEmpty,
  branchDropdown,
  isBranchDropdownLoading,
}) {
  const lang = useSelector(state => state.constant.languages);
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [selectAll, setSelectAll] = useState(false);
  let flattenBranch = []
  const [branchList, setBranchList] = useState([]);
  const [targetBranch, setTargetBranch] = useState([]);

  const location = useLocation();
  const qsParams = qs.parse(location.search);
  const searchBranch = qsParams.branch || "";
  const drawerOpen = useSelector((state)=>state.app.drawerOpen);
  const [extraChip, setExtraChip] = useState({
    count : 0,
    offset : 0,
    allOverflow : false
  });

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    
    if (!children) return;

    // max width is 90%. Use 0.9 to calc field width
    let panelWidth = ref.current?.scrollWidth * 0.9;
    let childWidth = 0;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index)=>{
  
        let panelContentWidth = p.getBoundingClientRect().width
        // get exact width + padding with decimal
        childWidth += (parseInt(window.getComputedStyle(child).marginRight) + child.getBoundingClientRect().width);
        
        // Check if overflow
        if(panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return (index !== (children.length - 1)) ? (childWidth + 10.5) < panelContentWidth : childWidth + 0.5 < panelContentWidth;
        }

        return (panelWidth > panelContentWidth);
      }).length;

      if (inRangeCount > 0) {

        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);
        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count : extraCount,
          offset : lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow : false,
          overflowChild : outRangeChild
        });
      } else {
        setExtraChip({
          count : value.length,
          offset : 0,
          allOverflow : true,
          overflowChild : value
        });
      }
    } else {
      setExtraChip({
          count : 0,
          offset : 0,
          allOverflow : false,
          overflowChild : []
        });
    }
  }


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    flattenBranch = getAllBranch(branchDropdown, flattenBranch);

    if (flattenBranch.length > 0 && targetBranch.length === 0) {
      if (searchBranch) {
          flattenBranch.forEach((branch) => {
          if (branch.node_id === searchBranch) {
            let temp = [{
              name: branch.name,
              node_id: branch.node_id,
              parent_node_id: branch.parent_node_id,
              remarks: branch.remarks
            }];
            setTargetBranch(temp);
            handleChange(temp);
          }
        });
      }
    }
  }, [branchDropdown, flattenBranch]);

  const getAllBranch = (branchDropdown, target) => {

    for (const branch of branchDropdown) {
      target.push({
        name: branch.name,
        node_id: branch.node_id,
        parent_node_id: branch.parent_node_id,
        remarks: branch.remarks,
      })

      if (branch.child !== undefined && branch.child.length > 0){
          target = getAllBranch(branch.child, target);
      }
    }
    return target;
  };


  useEffect(() => {
    let temp = cloneDeep(value)
    let isSelectAll = true
    for (const masterBranch of flattenBranch) {
      if (!(temp.filter(t => t.node_id === masterBranch.node_id).length === 1)
      ) {
        isSelectAll = false
      }
    }
    setSelectAll(isSelectAll)
  }, [value, flattenBranch]);

  const handleClose = () => {
    setOpen(false);
    setBranchList(flattenBranch);
  };

  const handleClick = () => {
    setOpen(true);
    setBranchList(flattenBranch);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectBranch = (branch) => {
    let temp = cloneDeep(value);

    if (temp.filter(t => t.node_id === branch.node_id).length > 0) {
      temp = temp.filter(t => t.node_id !== branch.node_id)
    } else {
      temp.push({
        name: branch.name,
        node_id: branch.node_id,
        parent_node_id: branch.parent_node_id,
        remarks: branch.remarks
      });
    }

    handleChange(temp);
  };

  const handleSelectAll = async () => { 
    let temp = cloneDeep(value)
    if (selectAll) {
      temp = [];
    } else {
      temp = flattenBranch;
    }
    setSelectAll(!selectAll)
    handleChange(temp)
  };

  const handleBranchSearch = (targetBranch) => {
    if (targetBranch.trim().length === 0) {
      setBranchList(flattenBranch);
      return;
    }
    let branches = [];
    flattenBranch.forEach(branch => {
      let branchName = branch.name ?? "";
      if (branchName.toLowerCase().includes(targetBranch.toLowerCase())) {
        branches.push(branch);
      }
    });

    setBranchList(branches);
  };

  return (
    <>
      <BranchSearchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        targetBranch={targetBranch}
        textStyles={textStyles}
        lang={lang}
        handleChange={handleChange}
        handleChipDelete={handleChipDelete}
        drawerOpen={drawerOpen}
        extraChip={extraChip}
        adjustField={adjustField}
        isEmpty={isEmpty}
      />
      <BranchPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleSelectAll={handleSelectAll}
        handleBranchSearch={handleBranchSearch}
        handleClose={handleClose}
        isFetching={isBranchDropdownLoading}
        branchDropdown={branchList}
        handleSelectBranch={handleSelectBranch}
        lang={lang}
        handleChipDelete={handleChipDelete}
        isEmpty={isEmpty}
      />
    </>
  );
}
BranchSearchSelectContainer.defaultProps = {
  placeholder: "Select Branch(s)"
};

BranchSearchSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default BranchSearchSelectContainer;
