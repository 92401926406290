import React from "react";
import {
  Box,
  Paper,
  makeStyles,
  Button,
  Typography
} from "@material-ui/core";
import { Formik } from "formik";
import WarranteeListTable from "../../components/table/warranteeListTable";
import InputTextField from "components/input/inputTextField";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 4fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      justifySelf: "flex-end"
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  }
});

const useStyles = makeStyles(style);

export default function WarranteeListPageComponent({
  hasWarrantyEditAccessRight,
  handleSubmit,
  handleClear,
  isFetching,
  warranteeListData,
  page,
  handlePageChange,
  handleRowPerPageChange,
  length,
  pageSetting,
  handleReload,
  paginationOptions,
  lang,
  changeOrder,
  tableFilter
}) {
  const classes = useStyles();

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <BackButton />
        <Typography
          variant="h6"
          color="primary"
          style={{ fontWeight: "bold", marginLeft: 5 }}
        >
          {getLang(lang, 'label.WARRANTEE_LIST')}
        </Typography>
      </Box>

      <Box pt={2}>
        <Paper elevation={0}>
          <Formik
            initialValues={{
              search: ""
            }}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box p={2} mb={2} className={classes.searchContainer}>
                  <InputTextField
                    id="search"
                    variant="filled"
                    size="small"
                    margin="none"
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#ECEFF0"
                      },
                      classes: { input: classes.inputRoot }
                    }}
                    {...formik.getFieldProps("search")}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    size="small"
                    disabled={isFetching}
                  >
                    {getLang(lang, 'label.SEARCH')}
                  </Button>

                  <Button
                    color="secondary"
                    variant="text"
                    size="small"
                    disableElevation
                    className={classes.button}
                    disabled={isFetching}
                    onClick={() => handleClear(formik)}
                  >
                    {getLang(lang, 'label.CLEAR_SEARCH')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
        <WarranteeListTable
          hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
          isFetching={isFetching}
          rows={warranteeListData}
          rowsPerPage={pageSetting.length}
          page={page}
          search={pageSetting.search}
          handlePageChange={handlePageChange}
          handleRowPerPageChange={handleRowPerPageChange}
          length={length}
          pageSetting={pageSetting}
          handleReload={handleReload}
          paginationOptions={paginationOptions}
          tableFilter={tableFilter}
          changeOrder={changeOrder}
        />
      </Box>
    </Box>
  );
}
