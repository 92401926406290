import React from "react";
import LuckyDrawListingTable from "../../table/luckyDrawListingTable";
import { Box } from "@material-ui/core";
import WithProductGeneralSearch from "../../../../../components/search/withProductGeneralSearch";

export default function LuckyDrawListingPanelComponent({
  hasLuckyDrawAddAccessRight,
  hasLuckyDrawEditAccessRight,
  hasLuckyDrawActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreate,
  handleStatusChange,
  handleFetchLuckyDrawList,
  paginationOptions,
  language,
  productDropdown,
  isFetchingProduct,
  lang
}) {
  return (
    <Box>
      <WithProductGeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        productDropdown={productDropdown}
        isFetching={isFetching}
        isFetchingProduct={isFetchingProduct}
      />

      <LuckyDrawListingTable
        hasLuckyDrawAddAccessRight={hasLuckyDrawAddAccessRight}
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        hasLuckyDrawActivateOrDeactivateAccessRight={hasLuckyDrawActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        paginationOptions={paginationOptions}
        lang={lang}
        language={language}
      />
    </Box>
  );
}
