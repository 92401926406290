import React from "react";
import TranditionalRaffleWinnerCardComponent from "./tranditionalRaffleWinnerCard.component";
import { useSelector } from "react-redux";

function TranditionalRaffleWinnerCardContainer({ prizeDetail, prizeIndex, isExpand, onToggle, isNewRank }) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <TranditionalRaffleWinnerCardComponent
      prizeDetail={prizeDetail}
      prizeIndex={prizeIndex}
      lang={lang}
      handleSubmit
      isExpand={isExpand}
      onToggle={onToggle}
      isNewRank={isNewRank}
    />
  );
}

export default TranditionalRaffleWinnerCardContainer;
