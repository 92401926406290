import React, { useEffect } from 'react'
import qs from "query-string";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from 'modules/public/redux/action';
import LoadingComponent from 'components/loading';
import { EMAIL_VERIFIED, INVALID_LINK } from 'modules/public/config/public.route';
import { unwrapResult } from '@reduxjs/toolkit';
import {clearVerifyError} from "../../redux/slice/signup.slice";

export default function VerifyEmail() {
	const history = useHistory();
	const params = qs.parse(history.location.search);
	const dispatch = useDispatch();
	const verifyError = useSelector((state) => state.signup.verifyError);

	useEffect(() => {
		switch (verifyError) {
			case "error.ERROR_USER_NOT_FOUND":
				history.push(INVALID_LINK);
				dispatch(clearVerifyError());
				break;
			case "error.ERROR_USER_VERIFIED_BEFORE":
				history.push(EMAIL_VERIFIED);
				dispatch(clearVerifyError());
				break;

			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verifyError])

	useEffect(() => {
		dispatch(verifyEmail({
			email: decodeURIComponent(params.email),
			token: params.email_verification_token
		}))
			.then(unwrapResult)
			.then(() => {
				history.push(EMAIL_VERIFIED)
			})
			.catch(error => {
				history.push(INVALID_LINK)
			})
	}, [dispatch, params, history])

	return <LoadingComponent />
}
