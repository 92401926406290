import React from "react";
import { Box } from "@material-ui/core";
import { GeneralSearch } from "components/search/";
import ParticipantsListTable from "../../table/participantsListTable";

export default function ParticipantsListPanelComponent({
  data,
  isFetching,
  tableFilter,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleFetchLuckyDrawList,
  paginationOptions,
  language,
  lang,
  isError,
  luckyDrawUUID
}) {
  return (
    <Box>
      <GeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
      />

      <ParticipantsListTable
        data={data}
        isFetching={isFetching}
        page={tableFilter.page}
        search={tableFilter.search}
        rowsPerPage={tableFilter.rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        paginationOptions={paginationOptions}
        lang={lang}
        language={language}
        isError={isError}
        luckyDrawUUID={luckyDrawUUID}
      />
    </Box>
  );
}
