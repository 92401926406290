import {
  Box,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  withStyles,
  useTheme,
  Divider,
} from "@material-ui/core";
import { Wrapper } from "modules/reporting/components/layout";
import {
  CAMPAIGN_TYPE_INSTANTLUCKYDRAW,
  CAMPAIGN_TYPE_LOYALTYPROGRAM,
  CAMPAIGN_TYPE_LUCKYDRAW,
  CAMPAIGN_TYPE_SUMMARY,
  CAMPAIGN_TYPE_SURVEY,
  CAMPAIGN_TYPE_WARRANTY,
} from "modules/reporting/constants";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import {
  QRReportPage,
  ProductReportPage,
  ConsumerPage,
  CampaignPage,
  DealershipPage,
} from "../../pages";
import MaintenanceAlert from "modules/reporting/components/alert/maintenance-alert";

const useStyles = makeStyles({
  header__wrapper: {
    backgroundColor: "#FFFFFF",
    paddingTop: "20px",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  header__title: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "28px",
    color: "#344054",
    fontFamily: "Roboto",
  },
  fullWidth: {
    width: "100%",
    margin: "0px",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function MainPage({ filter, pageMode, translate }) {
  // component state declaration -- START
  const classes = useStyles();
  const theme = useTheme();

  const [pageValue, setPageValue] = useState(0);
  const [productPageValue, setProductPageValue] = useState(0);
  const [campaignPageValue, setCampaignPageValue] = useState(0);
  const [campaignTypeValue, setCampaignTypeValue] = useState(
    CAMPAIGN_TYPE_SUMMARY
  );

  const isDev = filter.currentFilter.isDev;

  useEffect(() => {
    if (pageMode.page === "qr") {
      setPageValue(0);
    } else if (pageMode.page === "product-category") {
      setPageValue(1);
      setProductPageValue(0);
    } else if (pageMode.page === "product-brand") {
      setPageValue(1);
      setProductPageValue(1);
    } else if (pageMode.page === "product-model") {
      setPageValue(1);
      setProductPageValue(2);
    } else if (pageMode.page === "product-list") {
      setPageValue(1);
      setProductPageValue(3);
    } else if (pageMode.page === "suspended-product") {
      setPageValue(1);
      setProductPageValue(4);
    } else if (pageMode.page === "consumer") {
      if (isDev) {
        setPageValue(3);
      } else {
        setPageValue(2);
      }
    } else if (pageMode.page === "campaign-summary") {
      setPageValue(4);
      setCampaignPageValue(0);
      setCampaignTypeValue(CAMPAIGN_TYPE_SUMMARY);
    } else if (pageMode.page === "campaign-survey") {
      setPageValue(4);
      setCampaignPageValue(1);
      setCampaignTypeValue(CAMPAIGN_TYPE_SURVEY);
    } else if (pageMode.page === "campaign-lucky-draw") {
      setPageValue(4);
      setCampaignPageValue(2);

      setCampaignTypeValue(CAMPAIGN_TYPE_LUCKYDRAW);
    } else if (pageMode.page === "campaign-instant-lucky-draw") {
      setPageValue(4);
      setCampaignPageValue(3);

      setCampaignTypeValue(CAMPAIGN_TYPE_INSTANTLUCKYDRAW);
    } else if (pageMode.page === "campaign-loyalty-program") {
      setPageValue(4);
      setCampaignPageValue(4);

      setCampaignTypeValue(CAMPAIGN_TYPE_LOYALTYPROGRAM);
    } else if (pageMode.page === "campaign-warranty") {
      setPageValue(4);
      setCampaignPageValue(5);

      setCampaignTypeValue(CAMPAIGN_TYPE_WARRANTY);
    } else if (pageMode.page === "dealership") {
      if (isDev) {
        setPageValue(2);
      }
    } else {
      setPageValue(0);
      pageMode.setPage("qr");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageMode.page]);

  // component state declaration -- END
  // page tab navigation -- START
  const CustomizeTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: "50px",
        width: "100%",
        backgroundColor: "#344054",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const CustomizeTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      color: "#344054",
      fontWeight: 500,
      fontSize: "14px",
      "&:focus": {
        opacity: 1,
      },
      minWidth: "80px",
    },
  }))((props) => <Tab disableRipple {...props} />);

  const handlePageValueChange = (event, newValue) => {
    setPageValue(newValue);

    if (newValue === 0) {
      pageMode.setPage("qr");
    } else if (newValue === 1) {
      pageMode.setPage("product-category");
    } else if (newValue === 2) {
      if (isDev) {
        pageMode.setPage("dealership");
      } else {
        pageMode.setPage("consumer");
      }
    } else if (newValue === 3) {
      pageMode.setPage("consumer");
    } else if (newValue === 4) {
      pageMode.setPage("campaign-summary");
    }
  };

  const handlePageChangeIndex = (index) => {
    setPageValue(index);

    if (index === 0) {
      pageMode.setPage("qr");
    } else if (index === 1) {
      pageMode.setPage("product-category");
    } else if (index === 2) {
      if (isDev) {
        pageMode.setPage("dealership");
      } else {
        pageMode.setPage("consumer");
      }
    } else if (index === 3) {
      pageMode.setPage("consumer");
    } else if (index === 4) {
      pageMode.setPage("campaign-summary");
    }
  };

  // page tab navigation -- END
  return (
    <Wrapper>
      <Box className={classes.header__wrapper}>
        <MaintenanceAlert />

        {/* page title */}
        <Typography variant="h4" className={classes.header__title}>
          {translate("label.REPORTING")}
        </Typography>

        {/* page navigation */}
        {filter.currentFilter.isDev ? (
          <CustomizeTabs value={pageValue} onChange={handlePageValueChange}>
            <CustomizeTab label={translate("label.QR_DATA").toUpperCase()} />
            <CustomizeTab label={translate("label.PRODUCT").toUpperCase()} />
            <CustomizeTab label={translate("label.DEALERSHIP").toUpperCase()} />
            <CustomizeTab label={translate("label.CONSUMER").toUpperCase()} />
            <CustomizeTab label={translate("label.CAMPAIGN").toUpperCase()} />
          </CustomizeTabs>
        ) : (
          <CustomizeTabs value={pageValue} onChange={handlePageValueChange}>
            <CustomizeTab label={translate("label.QR_DATA").toUpperCase()} />
            <CustomizeTab label={translate("label.PRODUCT").toUpperCase()} />
            <CustomizeTab label={translate("label.CONSUMER").toUpperCase()} />
          </CustomizeTabs>
        )}
      </Box>
      <Divider className={classes.fullWidth} />
      {filter.currentFilter.isDev ? (
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={pageValue}
          onChangeIndex={handlePageChangeIndex}
        >
          <TabPanel value={pageValue} index={0} dir={theme.direction}>
            <QRReportPage
              filter={filter}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
          <TabPanel value={pageValue} index={1} dir={theme.direction}>
            <ProductReportPage
              filter={filter}
              pageHandler={{
                productPageValue,
                setProductPageValue,
              }}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
          <TabPanel value={pageValue} index={2} dir={theme.direction}>
            <DealershipPage
              filter={filter}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
          <TabPanel value={pageValue} index={3} dir={theme.direction}>
            <ConsumerPage
              filter={filter}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
          <TabPanel value={pageValue} index={4} dir={theme.direction}>
            <CampaignPage
              filter={filter}
              pageMode={pageMode}
              pageHandler={{
                campaignPageValue,
                setCampaignPageValue,
              }}
              translate={translate}
              campaignType={{
                campaignTypeValue,
                setCampaignTypeValue,
              }}
            />
          </TabPanel>
        </SwipeableViews>
      ) : (
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={pageValue}
          onChangeIndex={handlePageChangeIndex}
        >
          <TabPanel value={pageValue} index={0} dir={theme.direction}>
            <QRReportPage
              filter={filter}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
          <TabPanel value={pageValue} index={1} dir={theme.direction}>
            <ProductReportPage
              filter={filter}
              pageHandler={{
                productPageValue,
                setProductPageValue,
              }}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
          <TabPanel value={pageValue} index={2} dir={theme.direction}>
            <ConsumerPage
              filter={filter}
              pageMode={pageMode}
              translate={translate}
            />
          </TabPanel>
        </SwipeableViews>
      )}
    </Wrapper>
  );
}
