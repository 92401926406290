import axios from "axios";

const checkSerialNumberAvailability = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/serialnumber/api/v1/serialnumber/assign/check-availability", {
        branch_uuid: parameters.branch_uuid,
        format: parameters.format,
        type: parameters.type,
        quantity: parameters.quantity,
        ranges: parameters.ranges,
        is_serial_number: parameters.is_serial_number,
      })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

export default checkSerialNumberAvailability;
