import React from "react";
import RoleTable from "../../table/roleTable";
import { GeneralSearch } from "components/search";

export default function RolePanelComponent({
  currentUserRoleId,
  hasRoleAddAccessRight,
  hasRoleEditAccessRight,
  hasRoleDeleteAccessRight,
  handleSearch,
  isFetching,
  data,
  search,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions
}) {
  return (
    <>
      <GeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        isFetching={isFetching}
      />

      <RoleTable
        currentUserRoleId={currentUserRoleId}
        hasRoleAddAccessRight={hasRoleAddAccessRight}
        hasRoleEditAccessRight={hasRoleEditAccessRight}
        hasRoleDeleteAccessRight={hasRoleDeleteAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </>
  );
}
