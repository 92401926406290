import React from "react";
import { Box } from "@material-ui/core";
import { InstantLuckyDrawTable } from "modules/instant-lucky-draw/components/table";
import WithProductGeneralSearch from "../../../../components/search/withProductGeneralSearch";

export default function ListingComponent({
  hasInstantLuckyDrawAddAccessRight,
  hasInstantLuckyDrawEditAccessRight,
  hasInstantLuckyDrawActivateOrDeactivateAccessRight,
  isFetching,
  page,
  rowsPerPage,
  search,
  data,
  handlePageChange,
  handleRowPerPageChange,
  handleSearch,
  totalRecords,
  handleStatusChange,
  handleFetchInstantLuckyDrawList,
  paginationOptions,
  productDropdown,
  isFetchingProduct,
}) {
  return (
    <Box>
      <WithProductGeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        productDropdown={productDropdown}
        isFetching={isFetching}
        isFetchingProduct={isFetchingProduct}
      />
      <InstantLuckyDrawTable
        hasInstantLuckyDrawAddAccessRight={hasInstantLuckyDrawAddAccessRight}
        hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
        hasInstantLuckyDrawActivateOrDeactivateAccessRight={hasInstantLuckyDrawActivateOrDeactivateAccessRight}
        isFetching={isFetching}
        data={data}
        rowsPerPage={rowsPerPage}
        page={page}
        search={search}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        totalRecords={totalRecords}
        handleStatusChange={handleStatusChange}
        handleFetchInstantLuckyDrawList={handleFetchInstantLuckyDrawList}
        paginationOptions={paginationOptions}
      />
    </Box>
  );
}
