import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Tabs,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import CustomTab from "components/tab";
import StocktakeItemsProductListPanel from "modules/stockflow/components/panel/stocktakeItemsProductListPanel";
import StocktakeItemsBoxListPanel from "modules/stockflow/components/panel/stocktakeItemsBoxListPanel";
import Input from "components/input/inputTextField";
import { Formik } from "formik";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  field: {
    display: "grid",
    gridTemplateColumns: "2fr 6fr",
    columnGap: theme.spacing(2),
    alignItems: "center",
  },
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "20px 16px",
    marginBottom: 16,
  },
  statusChip: {
    color: "#FFFFFF",
    padding: "7px 18px",
    marginLeft: "10px",
    borderRadius: "4px",
  },
  summaryBox: {
    border: "1px solid #D0D5DD",
    display: "grid",
    "& div:not(:last-child)": {
      borderRight: "1px solid #D0D5DD",
    },
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: 4,
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

export default function StocktakeDetailComponent({
  stockTake,
  isFetching,
  isError,
  handleReload,
  lang,
  handleChangeType,
  activeIndex,
  id,
  handleCancel,
  handleEnd,
  handleUpdate,
  isEditing,
  setIsEditing,
  isUpdating,
  boxStatusIndex,
  language,
  changeBoxStatus,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const IN_PROGRESS = "In Progress";
  const COMPLETED = "Completed";
  const CANCELLED = "Cancelled";

  const statusColor = {
    [IN_PROGRESS]: "#FDB022",
    [COMPLETED]: "#32D583",
    [CANCELLED]: "#98A2B3",
  };

  const statusPanelColor = {
    [IN_PROGRESS]: "#FFFCF5",
    [COMPLETED]: "#F6FEF9",
    [CANCELLED]: "#FCFCFD",
  };

  const tabLabels = [
    getLang(lang, "label.PRODUCT"),
    getLang(lang, "label.BOX"),
  ];

  const boxTabLabels = [
    getLang(lang, "label.MATCHED"),
    getLang(lang, "label.EXTRA"),
    getLang(lang, "label.MISSING"),
  ];

  const pendingBoxTabLabels = [
    getLang(lang, "label.MATCHED"),
    getLang(lang, "label.EXTRA"),
  ];

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.STOCK_TAKE")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.VIEW_STOCK_TAKE")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        {!isFetching && !isError && stockTake && (
          <>
            <Box
              display="flex"
              alignItems="center"
              px={2}
              py={2.5}
              mb={2}
              style={{
                backgroundColor: statusPanelColor[stockTake.status],
              }}
            >
              <Box
                className={classes.statusChip}
                style={{
                  backgroundColor: statusColor[stockTake.status],
                }}
              >
                <Typography variant="h5">
                  <b>{getLang(lang, `db_label.${stockTake.status}`)}</b>
                </Typography>
              </Box>
            </Box>
            {stockTake.status === IN_PROGRESS && (
              <Paper className={classes.panel} elevation={3}>
                <Box display="flex" justifyContent="space-between">
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      disableElevation
                      color="error"
                      size="small"
                      style={{
                        color: "#F04438",
                        border: "1px solid #F04438",
                      }}
                      onClick={handleCancel}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      size="small"
                      onClick={handleEnd}
                    >
                      {getLang(lang, "label.END_STOCK_TAKE")}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            )}
            <Formik
              initialValues={{
                name: stockTake.name ?? "",
              }}
              enableReinitialize
              onSubmit={handleUpdate}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Paper
                    className={classes.panel}
                    elevation={3}
                    style={{ paddingBottom: 25 }}
                  >
                    <Box>
                      <Typography variant="h6">
                        <b>{getLang(lang, "label.DETAILS")}</b>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body2">
                        {getLang(lang, "label.BRANCH")}
                      </Typography>
                      <Typography variant="body2">
                        {stockTake.branch}
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body2">
                        {getLang(lang, "label.STOCK_TAKE_PROGRAM_NAME")} (
                        {getLang(lang, "label.OPTIONAL")})
                      </Typography>
                      {stockTake?.status === IN_PROGRESS && isEditing ? (
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(
                            lang,
                            "placeholder.STOCK_TAKE_PROGRAM_NAME"
                          )}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              minWidth: "50px",
                              height: "100%",
                            },
                            classes: { input: classes.inputRootID },
                          }}
                          disabled={isUpdating}
                          {...formik.getFieldProps("name")}
                        />
                      ) : (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">
                            {stockTake.name}
                          </Typography>
                          {stockTake?.status === IN_PROGRESS && (
                            <IconButton
                              aria-label="option"
                              onClick={() => setIsEditing(true)}
                              style={{ marginLeft: 5 }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body2">
                        {getLang(lang, "label.STOCK_TAKE_START_DATE")}
                      </Typography>
                      <Typography variant="body2">
                        {moment
                          .utc(stockTake.start_date)
                          .local()
                          .format("lll")}
                      </Typography>
                    </Box>
                    {stockTake?.end_date && (
                      <Box className={classes.field} mt={2}>
                        <Typography variant="body2">
                          {getLang(lang, "label.STOCK_TAKE_END_DATE")}
                        </Typography>
                        <Typography variant="body2">
                          {moment
                            .utc(stockTake.end_date)
                            .local()
                            .format("lll")}
                        </Typography>
                      </Box>
                    )}
                    {stockTake?.cancel_date && (
                      <Box className={classes.field} mt={2}>
                        <Typography variant="body2">
                          {getLang(lang, "label.STOCK_TAKE_CANCELLED_DATE")}
                        </Typography>
                        <Typography variant="body2">
                          {moment
                            .utc(stockTake.cancel_date)
                            .local()
                            .format("lll")}
                        </Typography>
                      </Box>
                    )}
                    {stockTake?.status === IN_PROGRESS && isEditing && (
                      <Box display="flex" justifyContent="end" mt={2}>
                        <Button
                          variant="contained"
                          disableElevation
                          color="secondary"
                          size="small"
                          type="submit"
                        >
                          {isUpdating ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          ) : (
                            getLang(lang, "label.UPDATE_DETAILS")
                          )}
                        </Button>
                      </Box>
                    )}
                  </Paper>
                </form>
              )}
            </Formik>
            {stockTake.status === COMPLETED ? (
              <>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="h6">
                      <b>{getLang(lang, "label.PRODUCT")}</b>
                    </Typography>
                  </Box>
                  <Box
                    className={classes.summaryBox}
                    style={{ gridTemplateColumns: "repeat(4, 1fr" }}
                    mb={3}
                  >
                    <Box px={2} py={1}>
                      <Typography>
                        <b>{stockTake.product.inventory_quantity}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.INVENTORY_QTY")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography>
                        <b>{stockTake.product.matched}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_MATCHED")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography style={{ color: "#F97066" }}>
                        <b>{stockTake.product.missing}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_MISSING_SERIAL_NO")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography style={{ color: "#FDB022" }}>
                        <b>{stockTake.product.extra}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_EXTRA_SERIAL_NO")}
                      </Typography>
                    </Box>
                  </Box>
                  <StocktakeItemsProductListPanel
                    lang={lang}
                    isSearchable={false}
                    isCompleted={true}
                    id={id}
                  />
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="h6">
                      <b>{getLang(lang, "label.BOX")}</b>
                    </Typography>
                  </Box>
                  <Box
                    className={classes.summaryBox}
                    style={{ gridTemplateColumns: "repeat(4, 1fr" }}
                    mb={3}
                  >
                    <Box px={2} py={1}>
                      <Typography>
                        <b>{stockTake.box.inventory_quantity}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.INVENTORY_QTY")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography>
                        <b>{stockTake.box.matched}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_MATCHED")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography style={{ color: "#F97066" }}>
                        <b>{stockTake.box.missing}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_MISSING_BOX")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography style={{ color: "#FDB022" }}>
                        <b>{stockTake.box.extra}</b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_EXTRA_BOX")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Tabs
                      value={activeIndex}
                      onChange={handleChangeType}
                      aria-label="scrollable auto tabs example"
                      textColor="primary"
                      variant="scrollable"
                      indicatorColor="primary"
                      classes={{
                        indicator: classes.indicator,
                        scrollButtons: classes.scroll,
                      }}
                    >
                      {boxTabLabels.map((item, index) => {
                        return (
                          <CustomTab key={index} {...a11yProps(index, item)} />
                        );
                      })}
                    </Tabs>
                    <Divider />
                  </Box>
                  <Box>
                    <StocktakeItemsBoxListPanel
                      lang={lang}
                      isSearchable={true}
                      isCompleted={false}
                      id={id}
                      status={
                        activeIndex === 1
                          ? "extra"
                          : activeIndex === 2
                          ? "missing"
                          : "matched"
                      }
                    />
                  </Box>
                </Paper>
              </>
            ) : (
              <>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="h6">
                      <b>{getLang(lang, "label.STOCK_TAKE_RESULT")}</b>
                    </Typography>
                  </Box>
                  <Box
                    className={classes.summaryBox}
                    style={{ gridTemplateColumns: "repeat(2, 1fr" }}
                  >
                    <Box px={2} py={1}>
                      <Typography>
                        <b>
                          {Number(
                            stockTake.product.matched + stockTake.product.extra
                          )}
                        </b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_PRODUCT")}
                      </Typography>
                    </Box>
                    <Box px={2} py={1}>
                      <Typography>
                        <b>
                          {Number(stockTake.box.matched + stockTake.box.extra)}
                        </b>
                      </Typography>
                      <Typography>
                        {getLang(lang, "label.TOTAL_BOX")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Tabs
                      value={activeIndex}
                      onChange={handleChangeType}
                      aria-label="scrollable auto tabs example"
                      textColor="primary"
                      variant="scrollable"
                      indicatorColor="primary"
                      classes={{
                        indicator: classes.indicator,
                        scrollButtons: classes.scroll,
                      }}
                    >
                      {tabLabels.map((item, index) => {
                        return (
                          <CustomTab key={index} {...a11yProps(index, item)} />
                        );
                      })}
                    </Tabs>
                    <Divider />
                  </Box>
                  <Box>
                    {activeIndex !== null &&
                      tabLabels.map((item, index) => {
                        if (item === getLang(lang, "label.BOX")) {
                          return (
                            <TabPanel
                              value={activeIndex}
                              index={index}
                              key={index}
                            >
                              <Box>
                                <Box mb={2} mt={-1}>
                                  <Tabs
                                    value={boxStatusIndex}
                                    onChange={changeBoxStatus}
                                    aria-label="scrollable auto tabs example"
                                    textColor="primary"
                                    variant="scrollable"
                                    indicatorColor="primary"
                                    classes={{
                                      indicator: classes.indicator,
                                      scrollButtons: classes.scroll,
                                    }}
                                  >
                                    {pendingBoxTabLabels.map((item, index) => {
                                      return (
                                        <CustomTab
                                          key={index}
                                          {...a11yProps(index, item)}
                                        />
                                      );
                                    })}
                                  </Tabs>
                                </Box>
                                <StocktakeItemsBoxListPanel
                                  lang={lang}
                                  isSearchable={true}
                                  isCompleted={false}
                                  id={id}
                                  status={
                                    boxStatusIndex === 1 ? "extra" : "matched"
                                  }
                                />
                              </Box>
                            </TabPanel>
                          );
                        } else {
                          return (
                            <TabPanel
                              value={activeIndex}
                              index={index}
                              key={index}
                            >
                              <StocktakeItemsProductListPanel
                                lang={lang}
                                isSearchable={true}
                                isCompleted={false}
                                id={id}
                              />
                            </TabPanel>
                          );
                        }
                      })}
                  </Box>
                </Paper>
              </>
            )}
          </>
        )}
        {!isFetching && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isFetching && (
          <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
