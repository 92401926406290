import React from "react";
import { Box, Stepper, Step, makeStyles, StepButton } from "@material-ui/core";
import BasicInfoForm from "../basicInfoForm";
import PeriodInfoForm from "../periodInfoForm";
import PrizeInfoForm from "../prizeInfoForm";
import { getLang } from "app/feature/constants";
import ParticipantInfoForm from "../participantInfoForm";
import WinRateInfoForm from "../winRateInfoForm";
import PreviewForm from "../previewForm";
import { progressStatus } from "lib/constants/luckyDrawProgress";

const useStyles = makeStyles((theme) => ({
  steps: {
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#6AAF68", // circle color (ACTIVE)
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#6AAF68", // circle color (COMPLETED)
    },
  },
}));

export default function LuckyDrawPage({
  uuid,
  setNewUuid,
  initialValues,
  currentStep,
  setCurrentStep,
  lang,
  getLuckyDrawDetail,
  type,
  handleStepChange,
  isLoading,
}) {
  const classes = useStyles();

  const traditionalRaffleSteps = [
    getLang(lang, "label.BASIC"),
    getLang(lang, "label.PERIOD"),
    getLang(lang, "label.PRIZE"),
    getLang(lang, "label.PARTICIPANT"),
    getLang(lang, "label.PREVIEW_SMALL_CAP"),
  ];

  const instantWinSteps = [
    getLang(lang, "label.BASIC"),
    getLang(lang, "label.PERIOD"),
    getLang(lang, "label.PRIZE"),
    getLang(lang, "label.WIN_RATE"),
    getLang(lang, "label.PARTICIPANT"),
    getLang(lang, "label.PREVIEW_SMALL_CAP"),
  ];

  return (
    <Box>
      <Stepper
        style={{
          background: "transparent",
          padding: "1rem 0 0 0",
        }}
        activeStep={currentStep}
        alternativeLabel
        nonLinear
      >
        {(type === 2 ? instantWinSteps : traditionalRaffleSteps).map(
          (label, index) => (
            <Step className={classes.steps} key={label}>
              {/* <StepLabel>{label}</StepLabel> */}
              <StepButton
                onClick={() => {
                  let enableIndex = [0];
                  if (initialValues?.progress) {
                    if (initialValues?.progress !== progressStatus.published) {
                      enableIndex =
                        type === 2 ? [0, 1, 2, 3, 4, 5] : [0, 1, 2, 3, 4];
                      if (
                        [
                          progressStatus.setBasic,
                          progressStatus.setPeriod,
                          progressStatus.setPrize,
                          progressStatus.setPrize,
                        ].includes(initialValues?.progress)
                      ) {
                        if (
                          initialValues.progress === progressStatus.setBasic
                        ) {
                          enableIndex = [0, 1];
                        }
                        if (
                          initialValues.progress === progressStatus.setPeriod
                        ) {
                          enableIndex = [0, 1, 2, type === 1 ? 3 : 4];
                        }
                        if (
                          initialValues.progress === progressStatus.setPrize
                        ) {
                          enableIndex = [0, 1, 2, 3];
                          if (type === 2) {
                            enableIndex.push(4);
                          }
                        }
                        // if (currentStep === 4) {
                        //   enableIndex = type === 1 ? [0, 1, 2, 4] : [0, 1, 2, 5];
                        // }
                        // if (
                        //   currentStep === 5 &&
                        //   initialValues.progress === progressStatus.setPeriod
                        // ) {
                        //   enableIndex = type === 1 ? [0, 1, 2, 4] : [0, 1, 2, 5];
                        // }
                      }

                      if (
                        initialValues?.progress ===
                        progressStatus.setParticipant
                      ) {
                        enableIndex = [0, 1, 2, 3];
                        if (type === 2) {
                          enableIndex.push(4);
                          enableIndex.push(5);
                        } else {
                          enableIndex.push(4);
                        }
                      }
                    } else {
                      enableIndex =
                        type === 2 ? [0, 1, 2, 3, 4, 5] : [0, 1, 2, 3, 4];
                    }
                  }

                  const prizeCount = type === 1 ? initialValues.prizeInfo.filter(i => !!i.length).length : initialValues.prizeInfo.length;
                  if (!prizeCount && type === 2) {
                    enableIndex = enableIndex.filter(i => i !== 3);
                  }

                  if (enableIndex.includes(index)) {
                    handleStepChange(index);
                  }
                }}
                completed={
                  type === 1 ? (
                    currentStep >= 3 && index === 2 ?
                      initialValues?.prizeInfo.filter(i => !!i.length).length :
                      currentStep > index
                  ) : (
                    currentStep > 3 && (index === 2 || index === 3) ?
                      initialValues?.prizeInfo.length :
                      currentStep > index
                  )
                }
              >
                {label}
              </StepButton>
            </Step>
          )
        )}
      </Stepper>
      <Box
        style={{
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        {currentStep === 0 && (
          <BasicInfoForm
            uuid={uuid}
            ldCurrentState={initialValues?.currentState}
            progress={initialValues?.progress}
            setNewUuid={setNewUuid}
            initialValues={initialValues?.basicInfo}
            setCurrentStep={setCurrentStep}
            getLuckyDrawDetail={getLuckyDrawDetail}
            type={type}
          />
        )}
        {currentStep === 1 && (
          <PeriodInfoForm
            uuid={uuid}
            ldCurrentState={initialValues?.currentState}
            progress={initialValues?.progress}
            initialValues={initialValues?.periodInfo}
            setCurrentStep={setCurrentStep}
            getLuckyDrawDetail={getLuckyDrawDetail}
            type={type}
            currentStep={currentStep}
          />
        )}
        {currentStep === 2 && (
          <PrizeInfoForm
            uuid={uuid}
            ldCurrentState={initialValues?.currentState}
            progress={initialValues?.progress}
            initialValues={initialValues?.prizeInfo}
            setCurrentStep={setCurrentStep}
            getLuckyDrawDetail={getLuckyDrawDetail}
            type={type}
            currentStep={currentStep}
          />
        )}

        {/* traditional raffle */}
        {type === 1 && (
          <>
            {currentStep === 3 && (
              <ParticipantInfoForm
                uuid={uuid}
                ldCurrentState={initialValues?.currentState}
                progress={initialValues?.progress}
                initialValues={initialValues?.participantInfo}
                setCurrentStep={setCurrentStep}
                getLuckyDrawDetail={getLuckyDrawDetail}
                type={type}
                currentStep={currentStep}
                prizeInfo={initialValues?.prizeInfo}
              />
            )}
            {currentStep === 4 && (
              <PreviewForm
                uuid={uuid}
                ldCurrentState={initialValues?.currentState}
                progress={initialValues?.progress}
                initialValues={initialValues}
                setCurrentStep={setCurrentStep}
                type={type}
                currentStep={currentStep}
                isLoading={isLoading}
              />
            )}
          </>
        )}

        {/* instant win */}
        {type === 2 && (
          <>
            {currentStep === 3 && (
              <WinRateInfoForm
                uuid={uuid}
                ldCurrentState={initialValues?.currentState}
                progress={initialValues?.progress}
                initialValues={{
                  hasWinRate: initialValues?.winRateInfo?.hasWinRate || 0,
                  prizes: initialValues?.prizeInfo || [],
                }}
                setCurrentStep={setCurrentStep}
                getLuckyDrawDetail={getLuckyDrawDetail}
                type={type}
                currentStep={currentStep}
              />
            )}
            {currentStep === 4 && (
              <ParticipantInfoForm
                uuid={uuid}
                ldCurrentState={initialValues?.currentState}
                progress={initialValues?.progress}
                initialValues={initialValues?.participantInfo}
                setCurrentStep={setCurrentStep}
                getLuckyDrawDetail={getLuckyDrawDetail}
                type={type}
                currentStep={currentStep}
                isFetchingDetail={isLoading}
                prizeInfo={initialValues?.prizeInfo}
              />
            )}
            {currentStep === 5 && (
              <PreviewForm
                uuid={uuid}
                ldCurrentState={initialValues?.currentState}
                progress={initialValues?.progress}
                initialValues={initialValues}
                setCurrentStep={setCurrentStep}
                type={type}
                currentStep={currentStep}
                isLoading={isLoading}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
