import React from 'react'
import Dialog from "components/dialog/customDialog"
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import InteractiveEditor from '../../interactiveEditor';
import CloseIcon from "@material-ui/icons/Close";
import { getLang } from 'app/feature/constants';
import TemplateInteractiveEditor from "../../templateInteractiveEditor";
import CustomTemplateInteractiveEditor from '../../customTemplateInteractiveEditor';

const useStyle = makeStyles(theme => ({
    header: {
        padding: "4px 0px",
        paddingLeft: theme.spacing(3),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}))

export default function ProductPagePreviewDialogComponent({
    isOpen,
    handleClose,
    lang,
    templateAfter,
    isCustomUI,
    customTemplateHtml,
    isEditTemplate,
    templateIsCustom
}) {
    const classes = useStyle();

    return (
        <Dialog
            isOpen={isOpen}
            handleClose={handleClose}
            disableEnforceFocus={true}
            disableAutoFocus={true}
            scroll="paper"
        >
            <Box className={classes.header}>
                <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                >
                    {getLang(lang, "label.PREVIEW_LAYOUT")}
                </Typography>
                <IconButton color="primary" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box style={{
                padding: "25px 0px",
                backgroundColor: "#E4E7EC",
                width: 550,
                display: "flex",
                justifyContent: "center"
            }}>
                {!isEditTemplate ? (
                    !templateAfter || templateAfter === -1 ? (
                        <InteractiveEditor
                            previewMode={true}
                        />
                    ) : isCustomUI ? (
                        <CustomTemplateInteractiveEditor
                            previewMode={true}
                            customTemplateHtml={customTemplateHtml}
                        />
                    ) : (
                        <TemplateInteractiveEditor
                            previewMode={true}
                        />
                    )
                ) : (
                    !templateIsCustom ? (
                        <TemplateInteractiveEditor
                            previewMode={true}
                        />
                    ) :
                        <CustomTemplateInteractiveEditor
                            previewMode={true}
                            customTemplateHtml={customTemplateHtml}
                        />
                )}
            </Box>
        </Dialog>
    )
}
