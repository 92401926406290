/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdditionalFieldSelectComponent from "./additionalFieldSelect.component";
import AdditionalFieldSelectPopoverComponent from "./additionalFieldSelectPopover.component";
import { useSelector } from "react-redux";
import CreateAdtInfoTitleDialog from "modules/serial-number/components/dialog/createAdtInfoTitleDialog";

function AdditionalFieldSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  style,
  textStyle,
  selectedIds,
  adtInfoParams,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const userLang = useSelector((state) => state.profile.language);
  const [dropdown, setDropdown] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setDropdown(
      adtInfoParams.adtInfos.filter(
        (a) => !selectedIds.includes(a.id) || a.id === value?.id
      )
    );
  }, [adtInfoParams, selectedIds]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectField = (field) => {
    handleChange(value, field);
    handleClose();
  };

  const handleAddNewAdtInfo = () => {
    setShowAddDialog(true);
  };

  return (
    <>
      <AdditionalFieldSelectComponent
        handleClick={handleOpen}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        userLang={userLang}
      />
      <AdditionalFieldSelectPopoverComponent
        value={value}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        isFetching={adtInfoParams.isFetching}
        dropdown={dropdown}
        handleSelectField={handleSelectField}
        lang={lang}
        isError={adtInfoParams.isError}
        totalFiltered={adtInfoParams.totalRecords}
        hasNextPage={adtInfoParams.hasNextPage}
        handleFetchMoreItems={adtInfoParams.handleFetchMoreAdtInfos}
        handleReload={adtInfoParams.handleReload}
        handleSearch={adtInfoParams.handleSearch}
        userLang={userLang}
        handleAddNewAdtInfo={handleAddNewAdtInfo}
      />
      {showAddDialog && (
        <CreateAdtInfoTitleDialog
          isOpen={showAddDialog}
          handleClose={() => setShowAddDialog(false)}
          handleReload={adtInfoParams.handleReload}
        />
      )}
    </>
  );
}

export default AdditionalFieldSelectContainer;
