import axios from "axios";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/serialnumbers/campaign/${parameters.id}`, {
        params: {
          search: parameters.search,
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const searchSerialNumber = {
  get
};

export default searchSerialNumber;
