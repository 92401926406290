import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ErrorMessage, Formik, setNestedObjectValues } from "formik";
import { getLang } from "app/feature/constants";
import ManualClose from "assets/img/manual_close.png";
import {
  CalendarTodayRounded as CalendarTodayRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
  ScheduleRounded as ScheduleRoundedIcon,
} from "@material-ui/icons";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import clsx from "clsx";
import * as Yup from "yup";
import {
  JOIN_END_AT_COUNT_DOWN,
  JOIN_END_AT_EXT_STIE,
  JOIN_END_AT_NONE,
} from "modules/lucky-draw-v2/constants";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      display: "block",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
      position: "absolute",
      background: "transparent",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      display: "block",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
      position: "absolute",
      background: "transparent",
    },
  },
  required: {
    "&::after": {
      content: "' *'",
      color: "#E21B1B",
    },
  },
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  selectRoot: {
    padding: 12,
  },
  radioButton: {
    borderWidth: 2,
    textTransform: "none",
    display: "flex",
  },
  input: {
    "&::placeholder": {
      color: "#3A4D5480",
    },
  },
}));

export default function PeriodInfoFormComponent({
  uuid,
  initialValues,
  timezone,
  lang,
  handleSubmitForm,
  isSubmitting,
  formRef,
  handlePrevious,
  type,
  progress,
  ldCurrentState,
  validateFields,
}) {
  const classes = useStyles();
  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense",
  };

  let isPublished = progress && progress === progressStatus.published;
  let isEnded =
    isPublished && ldCurrentState && ldCurrentState === ldState.ended;
  let isEditable =
    !isPublished ||
    !ldCurrentState ||
    (ldCurrentState !== ldState.ended && ldCurrentState !== ldState.drawStart);

  let isJoinStart =
    isPublished &&
    ldCurrentState &&
    [
      ldState.joinStart,
      ldState.joinEnd,
      ldState.drawStart,
      ldState.ended,
    ].includes(ldCurrentState);
  let isJoinEnd =
    isPublished &&
    ldCurrentState &&
    [ldState.joinEnd, ldState.drawStart, ldState.ended].includes(
      ldCurrentState
    );

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        enablePreview: Number(initialValues?.enablePreview ?? 1),
        isAutoClose: Number(initialValues?.isAutoClose ?? 0),
        joinEndAction: initialValues?.joinEndAction ?? "",
        preStartDate: initialValues?.preStartDate ?? "",
        preStartTime: initialValues?.preStartTime ?? "",
        joinOpenDate: initialValues?.joinOpenDate ?? "",
        joinOpenTime: initialValues?.joinOpenTime ?? "",
        joinCloseDate: initialValues?.joinCloseDate ?? "",
        joinCloseTime: initialValues?.joinCloseTime ?? "",
        drawDate: initialValues?.drawDate ?? "",
        drawTime: initialValues?.drawTime ?? "",
        externalLink: initialValues?.externalLink ?? "",
        showConfirmDialog: false,
        showExtendConfirmDialog: false,
        isEditing: false,
        type: type,
        isPreviewValid: true, // check if join open > preview
        isJoinOpenValid: true, // check if join open > preview
        isJoinCloseValid: true, // check if join close > join open
        isDrawPeriodValid: true, // check if draw > join close
        errorMessage: {
          preview: "",
          joinOpen: "",
          joinClose: "",
          draw: "",
        },
        goToPreview: false,
      }}
      validationSchema={Yup.object().shape({
        preStartDate: Yup.string().when("enablePreview", {
          is: (value) => !!value,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        preStartTime: Yup.string().when("enablePreview", {
          is: (value) => !!value,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        joinOpenDate: Yup.string().required(
          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
        ),
        joinOpenTime: Yup.string().required(
          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
        ),
        joinCloseDate: Yup.string().when(["type", "isAutoClose"], {
          is: (type, isAutoClose) =>
            (type === 1 && !!isAutoClose) || type === 2,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        joinCloseTime: Yup.string().when(["type", "isAutoClose"], {
          is: (type, isAutoClose) =>
            (type === 1 && !!isAutoClose) || type === 2,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        joinEndAction: Yup.string().when(["type", "isAutoClose"], {
          is: (type, isAutoClose) => type === 1 && !!isAutoClose,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        drawDate: Yup.string().when(["joinEndAction", "isAutoClose"], {
          is: (value, isAutoClose) =>
            value === JOIN_END_AT_COUNT_DOWN && isAutoClose,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        drawTime: Yup.string().when(["joinEndAction", "isAutoClose"], {
          is: (value, isAutoClose) =>
            value === JOIN_END_AT_COUNT_DOWN && isAutoClose,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        externalLink: Yup.string().when(["joinEndAction", "isAutoClose"], {
          is: (value, isAutoClose) =>
            value === JOIN_END_AT_EXT_STIE && isAutoClose,
          then: Yup.string()
            .url(getLang(lang, "message.error.INVALID_URL"))
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          otherwise: Yup.string().nullable(),
        }),
        isPreviewValid: Yup.boolean().oneOf(
          [true],
          getLang(lang, "message.error.DATE_TIME_MUST_LATER_THAN_TODAY")
        ),
        isJoinOpenValid: Yup.boolean().oneOf(
          [true],
          getLang(lang, "message.error.LD_PREVIEW_PERIOD_GREATER_JOIN_PERIOD")
        ),
        isJoinCloseValid: Yup.boolean().oneOf(
          [true],
          getLang(
            lang,
            "message.error.LD_JOIN_START_PERIOD_GREATER_JOIN_END_PERIOD"
          )
        ),
        isDrawPeriodValid: Yup.boolean().oneOf(
          [true],
          getLang(
            lang,
            "message.error.LD_COUNTDOWN_PERIOD_GREATER_JOIN_END_PERIOD"
          )
        ),
      })}
      onSubmit={handleSubmitForm}
    >
      {(formik) => {
        return (
          <Box
            component={"form"}
            onSubmit={formik.handleSubmit}
            mt={2}
            className={classes.formContainer}
          >
            <Paper style={{ padding: "20px" }} elevation={0}>
              <Box
                mb={2.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">
                  <b>
                    {getLang(
                      lang,
                      type === 2
                        ? "label.INSTANT_WIN_PERIOD_SETUP"
                        : "label.LUCKY_DRAW_PERIOD_SETUP"
                    )}
                  </b>
                </Typography>
                {isEditable && (
                  <Button
                    style={{
                      backgroundColor: "#ff1515",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      formik.setFieldValue("showConfirmDialog", true);
                    }}
                    disabled={isSubmitting}
                  >
                    <Typography variant="body2" style={{ color: "white" }}>
                      <b>{getLang(lang, "label.CLEAR_ALL")}</b>
                    </Typography>
                  </Button>
                )}
              </Box>

              <Box mt={3}>
                <Typography variant="body2" className={classes.fieldLabel}>
                  {getLang(
                    lang,
                    type === 2
                      ? "label.DISPLAY_INSTANT_WIN_BEFORE_EVENT"
                      : "label.DISPLAY_LUCKY_DRAW_BEFORE_EVENT"
                  )}
                </Typography>
                <FormControl>
                  <Box style={{ display: "flex", gap: "10px", marginTop: 8 }}>
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: !formik.values.enablePreview
                          ? "#6AAF68"
                          : "#A0A0A080",
                        borderWidth: 2,
                        color: !formik.values.enablePreview
                          ? "black"
                          : "#A0A0A0",
                      }}
                      className={classes.radioButton}
                      startIcon={
                        <Radio
                          size="small"
                          checked={!formik.values.enablePreview}
                        />
                      }
                      onClick={() => {
                        formik.setFieldValue("enablePreview", 0);
                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }}
                      disabled={isJoinStart || isSubmitting}
                    >
                      {getLang(lang, "label.NO")}
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: !!formik.values.enablePreview
                          ? "#6AAF68"
                          : "#A0A0A080",
                        borderWidth: 2,
                        color: !!formik.values.enablePreview
                          ? "black"
                          : "#A0A0A0",
                      }}
                      className={classes.radioButton}
                      startIcon={
                        <Radio
                          size="small"
                          checked={!!formik.values.enablePreview}
                        />
                      }
                      onClick={() => {
                        formik.setFieldValue("enablePreview", 1);
                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }}
                      disabled={isJoinStart || isSubmitting}
                    >
                      {getLang(lang, "label.YES")}
                    </Button>
                  </Box>
                </FormControl>
              </Box>

              {!!formik.values.enablePreview && (
                <Box mt={3}>
                  <Typography
                    variant="body2"
                    className={clsx(classes.required, classes.fieldLabel)}
                  >
                    {getLang(
                      lang,
                      type === 2
                        ? "label.INSTANT_WIN_PREVIEW_SHOW_AT"
                        : "label.LUCKY_DRAW_PREVIEW_SHOW_AT"
                    )}
                  </Typography>
                  <Box style={{ display: "flex", gap: 8, marginTop: 8 }}>
                    <TextField
                      {...formik.getFieldProps("preStartDate")}
                      inputProps={{
                        style: { height: "0.1em", color: "black" },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ pointerEvents: "none" }}
                          >
                            <CalendarTodayRoundedIcon
                              style={{
                                color: "#3A4D54",
                                fontSize: "18px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      type="date"
                      name="preStartDate"
                      value={formik.values.preStartDate}
                      onChange={(e) => {
                        formik.setFieldValue("preStartDate", e.target.value);

                        if (!formik.values.joinOpenDate) {
                          formik.setFieldValue("joinOpenDate", e.target.value);
                        }
                        if (formik.values.errorMessage.preview) {
                          formik.setFieldValue("errorMessage.preview", "");
                        }
                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }}
                      error={
                        formik.touched.preStartDate &&
                        formik.errors.preStartDate
                          ? true
                          : false
                      }
                      helperText={ErrorMessage({ name: "preStartDate" })}
                      disabled={isSubmitting || isJoinStart}
                    />
                    <TextField
                      {...formik.getFieldProps("preStartTime")}
                      variant="outlined"
                      inputProps={{
                        style: { height: "0.1em", color: "black" },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ pointerEvents: "none" }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              pl={1.5}
                              style={{ gap: 4 }}
                            >
                              <ScheduleRoundedIcon
                                style={{
                                  color: "#3A4D54",
                                  fontSize: "18px",
                                }}
                              />
                              <Typography
                                variant="caption"
                                style={{
                                  color: "#3A4D54",
                                }}
                              >
                                <b>GMT{timezone}</b>
                              </Typography>
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                      type="time"
                      name="preStartTime"
                      value={formik.values.preStartTime}
                      onChange={(e) => {
                        formik.setFieldValue("preStartTime", e.target.value);
                        if (!formik.values.joinOpenTime) {
                          formik.setFieldValue("joinOpenTime", e.target.value);
                        }
                        if (formik.values.errorMessage.preview) {
                          formik.setFieldValue("errorMessage.preview", "");
                        }
                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }}
                      error={
                        formik.touched.preStartTime &&
                        formik.errors.preStartTime
                          ? true
                          : false
                      }
                      helperText={ErrorMessage({ name: "preStartTime" })}
                      disabled={isSubmitting || isJoinStart}
                    />
                  </Box>
                  {!!formik.values.errorMessage.preview && (
                    <FormHelperText error={true}>
                      {formik.values.errorMessage.preview}
                    </FormHelperText>
                  )}
                </Box>
              )}
            </Paper>

            <Paper style={{ padding: "20px", marginTop: "20px" }} elevation={0}>
              <Box>
                <Typography
                  variant="body2"
                  className={clsx(classes.required, classes.fieldLabel)}
                >
                  {getLang(
                    lang,
                    type === 2
                      ? "label.INSTANT_WIN_OPEN_PERIOD"
                      : "label.LUCKY_DRAW_OPEN_PERIOD"
                  )}
                </Typography>
                <Box style={{ display: "flex", gap: 8, marginTop: 8 }}>
                  <TextField
                    {...formik.getFieldProps("joinOpenDate")}
                    inputProps={{ style: { height: "0.1em", color: "black" } }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ pointerEvents: "none" }}
                        >
                          <CalendarTodayRoundedIcon
                            style={{
                              color: "#3A4D54",
                              fontSize: "18px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    type="date"
                    name="joinOpenDate"
                    value={formik.values.joinOpenDate}
                    onChange={(e) => {
                      formik.setFieldValue("joinOpenDate", e.target.value);
                      if (!formik.values.isJoinOpenValid) {
                        formik.setFieldValue("isJoinOpenValid", true);
                      }
                      if (!formik.values.isJoinCloseValid) {
                        formik.setFieldValue("isJoinCloseValid", true);
                      }
                      if (formik.values.errorMessage.joinOpen) {
                        formik.setFieldValue("errorMessage.joinOpen", "");
                      }
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    error={
                      formik.touched.joinOpenDate && formik.errors.joinOpenDate
                        ? true
                        : false
                    }
                    helperText={ErrorMessage({ name: "joinOpenDate" })}
                    disabled={isSubmitting || isJoinStart}
                  />
                  <TextField
                    {...formik.getFieldProps("joinOpenTime")}
                    variant="outlined"
                    inputProps={{ style: { height: "0.1em", color: "black" } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ pointerEvents: "none" }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            pl={1.5}
                            style={{ gap: 4 }}
                          >
                            <ScheduleRoundedIcon
                              style={{
                                color: "#3A4D54",
                                fontSize: "18px",
                              }}
                            />
                            <Typography
                              variant="caption"
                              style={{
                                color: "#3A4D54",
                              }}
                            >
                              <b>GMT{timezone}</b>
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    type="time"
                    name="joinOpenTime"
                    value={formik.values.joinOpenTime}
                    onChange={(e) => {
                      formik.setFieldValue("joinOpenTime", e.target.value);
                      if (!formik.values.isJoinOpenValid) {
                        formik.setFieldValue("isJoinOpenValid", true);
                      }
                      if (!formik.values.isJoinCloseValid) {
                        formik.setFieldValue("isJoinCloseValid", true);
                      }
                      if (formik.values.errorMessage.joinOpen) {
                        formik.setFieldValue("errorMessage.joinOpen", "");
                      }
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    error={
                      formik.touched.joinOpenTime && formik.errors.joinOpenTime
                        ? true
                        : false
                    }
                    helperText={ErrorMessage({ name: "joinOpenTime" })}
                    disabled={isSubmitting || isJoinStart}
                  />
                </Box>
                {!!formik.values.errorMessage.joinOpen && (
                  <FormHelperText error={true}>
                    {formik.values.errorMessage.joinOpen}
                  </FormHelperText>
                )}
              </Box>

              {type === 1 && (
                <>
                  <Box mt={3}>
                    <Typography variant="body2" className={classes.fieldLabel}>
                      {getLang(lang, "label.CLOSE_LD_JOIN_PERIOD_QUESTION")}
                    </Typography>
                    <FormControl>
                      <Box
                        style={{ display: "flex", gap: "10px", marginTop: 8 }}
                      >
                        <Button
                          variant="outlined"
                          style={{
                            borderColor: !formik.values.isAutoClose
                              ? "#6AAF68"
                              : "#A0A0A080",
                            borderWidth: 2,
                            color: !formik.values.isAutoClose
                              ? "black"
                              : "#A0A0A0",
                          }}
                          className={classes.radioButton}
                          startIcon={
                            <Radio
                              size="small"
                              checked={!formik.values.isAutoClose}
                            />
                          }
                          onClick={() => {
                            formik.setFieldValue("isAutoClose", 0);
                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          }}
                          disabled={isSubmitting || !isEditable}
                        >
                          {getLang(lang, "label.MANUALLY_CLOSE")}
                        </Button>
                        <Button
                          variant="outlined"
                          style={{
                            borderColor: !!formik.values.isAutoClose
                              ? "#6AAF68"
                              : "#A0A0A080",
                            borderWidth: 2,
                            color: !!formik.values.isAutoClose
                              ? "black"
                              : "#A0A0A0",
                          }}
                          className={classes.radioButton}
                          startIcon={
                            <Radio
                              size="small"
                              checked={!!formik.values.isAutoClose}
                            />
                          }
                          onClick={() => {
                            formik.setFieldValue("isAutoClose", 1);
                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          }}
                          disabled={isSubmitting || !isEditable}
                        >
                          {getLang(lang, "label.AUTOMATICALLY_CLOSE")}
                        </Button>
                      </Box>
                    </FormControl>
                  </Box>

                  {!formik.values.isAutoClose && (
                    <Box
                      mt={3}
                      p={2.5}
                      style={{
                        background: "#F8F8F8",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        mb={1}
                      >
                        <InfoOutlinedIcon fontSize="small" color="primary" />
                        <Typography
                          variant="body2"
                          className={classes.fieldLabel}
                        >
                          {getLang(lang, "label.HOW_TO_CLOSE_MANUALLY")}
                        </Typography>
                      </Box>
                      <ul>
                        <li>
                          <Typography
                            variant="body2"
                            style={{ color: "#818D91" }}
                          >
                            {getLang(lang, "paragraph.MANUAL_CLOSE_POINT_1")}
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body2"
                            style={{ color: "#818D91" }}
                          >
                            {getLang(lang, "paragraph.MANUAL_CLOSE_POINT_2")}
                          </Typography>
                        </li>
                        <img
                          style={{ marginTop: 8, width: 360 }}
                          src={ManualClose}
                          alt="Manual close instruction"
                        />
                      </ul>
                    </Box>
                  )}

                  {!!formik.values.isAutoClose && (
                    <>
                      <Box mt={3}>
                        <Typography
                          variant="body2"
                          className={clsx(classes.required, classes.fieldLabel)}
                        >
                          {getLang(lang, "label.LUCKY_DRAW_AUTO_CLOSE_PERIOD")}
                        </Typography>
                        <Box style={{ display: "flex", gap: 8, marginTop: 8 }}>
                          <TextField
                            {...formik.getFieldProps("joinCloseDate")}
                            inputProps={{
                              style: { height: "0.1em", color: "black" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <CalendarTodayRoundedIcon
                                    style={{
                                      color: "#3A4D54",
                                      fontSize: "18px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            type="date"
                            name="joinCloseDate"
                            value={formik.values.joinCloseDate}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "joinCloseDate",
                                e.target.value
                              );
                              if (!formik.values.isJoinCloseValid) {
                                formik.setFieldValue("isJoinCloseValid", true);
                              }
                              if (!formik.values.isDrawPeriodValid) {
                                formik.setFieldValue("isJoinCloseValid", true);
                              }
                              if (formik.values.errorMessage.joinClose) {
                                formik.setFieldValue(
                                  "errorMessage.joinClose",
                                  ""
                                );
                              }
                              if (!formik.values.isEditing) {
                                formik.setFieldValue("isEditing", true);
                              }
                            }}
                            error={
                              formik.touched.joinCloseDate &&
                              formik.errors.joinCloseDate
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "joinCloseDate" })}
                            disabled={isSubmitting || !isEditable}
                          />
                          <TextField
                            {...formik.getFieldProps("joinCloseTime")}
                            variant="outlined"
                            inputProps={{
                              style: { height: "0.1em", color: "black" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    pl={1.5}
                                    style={{ gap: 4 }}
                                  >
                                    <ScheduleRoundedIcon
                                      style={{
                                        color: "#3A4D54",
                                        fontSize: "18px",
                                      }}
                                    />
                                    <Typography
                                      variant="caption"
                                      style={{
                                        color: "#3A4D54",
                                      }}
                                    >
                                      <b>GMT{timezone}</b>
                                    </Typography>
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                            type="time"
                            name="joinCloseTime"
                            value={formik.values.joinCloseTime}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "joinCloseTime",
                                e.target.value
                              );
                              if (!formik.values.isJoinCloseValid) {
                                formik.setFieldValue("isJoinCloseValid", true);
                              }
                              if (!formik.values.isDrawPeriodValid) {
                                formik.setFieldValue("isJoinCloseValid", true);
                              }
                              if (formik.values.errorMessage.joinClose) {
                                formik.setFieldValue(
                                  "errorMessage.joinClose",
                                  ""
                                );
                              }
                              if (!formik.values.isEditing) {
                                formik.setFieldValue("isEditing", true);
                              }
                            }}
                            error={
                              formik.touched.joinCloseTime &&
                              formik.errors.joinCloseTime
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "joinCloseTime" })}
                            disabled={isSubmitting || !isEditable}
                          />
                        </Box>
                        {!!formik.values.joinCloseWarning && (
                          <FormHelperText error={true}>
                            {formik.values.joinCloseWarning}
                          </FormHelperText>
                        )}
                      </Box>
                      {!!formik.values.errorMessage.joinClose && (
                        <FormHelperText error={true}>
                          {formik.values.errorMessage.joinClose}
                        </FormHelperText>
                      )}
                    </>
                  )}

                  {!!formik.values.isAutoClose && (
                    <Box mt={3}>
                      <Typography
                        variant="body2"
                        className={clsx(classes.required, classes.fieldLabel)}
                      >
                        {getLang(lang, "label.WHAT_HAPPEN_WHEN_LD_CLOSED")}
                      </Typography>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 8 }}
                      >
                        <Select
                          {...formik.getFieldProps("joinEndAction")}
                          name="joinEndAction"
                          value={formik.values.joinEndAction}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "joinEndAction",
                              e.target.value
                            );
                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          }}
                          classes={{ root: classes.selectRoot }}
                          placeholder={getLang(
                            lang,
                            "placeholder.SELECT_AN_ACTION"
                          )}
                          displayEmpty
                          renderValue={
                            !!formik.values.joinEndAction
                              ? undefined
                              : () =>
                                  getLang(lang, "placeholder.SELECT_AN_ACTION")
                          }
                          error={
                            formik.touched.joinEndAction &&
                            formik.errors.joinEndAction
                              ? true
                              : false
                          }
                          disabled={isSubmitting || !isEditable}
                          style={{ color: "black" }}
                        >
                          <MenuItem value={JOIN_END_AT_NONE}>
                            {getLang(lang, "label.NO_ACTION_NEEDED")}
                          </MenuItem>
                          <MenuItem value={JOIN_END_AT_COUNT_DOWN}>
                            {getLang(lang, "label.START_COUNTDOWN_TIMER")}
                          </MenuItem>
                          <MenuItem value={JOIN_END_AT_EXT_STIE}>
                            {getLang(lang, "label.SHOWS_REDIRECT_BUTTON")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText
                        error={formik.errors.joinEndAction ? true : false}
                      >
                        {ErrorMessage({ name: "joinEndAction" })}
                      </FormHelperText>
                    </Box>
                  )}

                  {!!formik.values.isAutoClose &&
                    formik.values.joinEndAction === JOIN_END_AT_COUNT_DOWN && (
                      <Box mt={3}>
                        <Typography
                          variant="body2"
                          className={clsx(classes.required, classes.fieldLabel)}
                        >
                          {getLang(lang, "label.COUNTDOWN_ENDS_AT")}:
                        </Typography>
                        <Box style={{ display: "flex", gap: 8, marginTop: 8 }}>
                          <TextField
                            {...formik.getFieldProps("drawDate")}
                            inputProps={{
                              style: { height: "0.1em", color: "black" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <CalendarTodayRoundedIcon
                                    style={{
                                      color: "#3A4D54",
                                      fontSize: "18px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            type="date"
                            name="drawDate"
                            value={formik.values.drawDate}
                            onChange={(e) => {
                              formik.setFieldValue("drawDate", e.target.value);
                              if (formik.values.errorMessage.draw) {
                                formik.setFieldValue("errorMessage.draw", "");
                              }
                              if (!formik.values.isEditing) {
                                formik.setFieldValue("isEditing", true);
                              }
                            }}
                            error={
                              formik.touched.drawDate && formik.errors.drawDate
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "drawDate" })}
                            disabled={isSubmitting || !isEditable}
                          />
                          <TextField
                            {...formik.getFieldProps("drawTime")}
                            variant="outlined"
                            inputProps={{
                              style: { height: "0.1em", color: "black" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    pl={1.5}
                                    style={{ gap: 4 }}
                                  >
                                    <ScheduleRoundedIcon
                                      style={{
                                        color: "#3A4D54",
                                        fontSize: "18px",
                                      }}
                                    />
                                    <Typography
                                      variant="caption"
                                      style={{
                                        color: "#3A4D54",
                                      }}
                                    >
                                      <b>GMT{timezone}</b>
                                    </Typography>
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                            type="time"
                            name="drawTime"
                            value={formik.values.drawTime}
                            onChange={(e) => {
                              formik.setFieldValue("drawTime", e.target.value);
                              if (formik.values.errorMessage.draw) {
                                formik.setFieldValue("errorMessage.draw", "");
                              }
                              if (!formik.values.isEditing) {
                                formik.setFieldValue("isEditing", true);
                              }
                            }}
                            error={
                              formik.touched.drawTime && formik.errors.drawTime
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "drawTime" })}
                            disabled={isSubmitting || !isEditable}
                          />
                        </Box>
                        {!!formik.values.errorMessage.draw && (
                          <FormHelperText error={true}>
                            {formik.values.errorMessage.draw}
                          </FormHelperText>
                        )}
                      </Box>
                    )}

                  {formik.values.joinEndAction === JOIN_END_AT_EXT_STIE && (
                    <Box mt={3}>
                      <Typography
                        variant="body2"
                        className={clsx(classes.required, classes.fieldLabel)}
                      >
                        {getLang(lang, "label.EXTERNAL_LINK")}:
                      </Typography>
                      <TextField
                        {...formik.getFieldProps("externalLink")}
                        {...textFieldProps}
                        type={"text"}
                        name="externalLink"
                        value={formik.values.externalLink}
                        onChange={(e) => {
                          formik.setFieldValue("externalLink", e.target.value);
                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }}
                        error={
                          formik.touched.externalLink &&
                          formik.errors.externalLink
                            ? true
                            : false
                        }
                        helperText={ErrorMessage({
                          name: "externalLink",
                        })}
                        disabled={isSubmitting || isEnded}
                        inputProps={{
                          style: { color: "black" },
                        }}
                      />
                    </Box>
                  )}
                </>
              )}

              {type === 2 && (
                <>
                  <Box mt={3}>
                    <Typography
                      variant="body2"
                      className={clsx(classes.required, classes.fieldLabel)}
                    >
                      {getLang(lang, "label.INSTANT_WIN_CLOSE_PERIOD")}
                    </Typography>
                    <Box style={{ display: "flex", gap: 8, marginTop: 8 }}>
                      <TextField
                        {...formik.getFieldProps("joinCloseDate")}
                        inputProps={{
                          style: { height: "0.1em", color: "black" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ pointerEvents: "none" }}
                            >
                              <CalendarTodayRoundedIcon
                                style={{
                                  color: "#3A4D54",
                                  fontSize: "18px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        type="date"
                        name="joinCloseDate"
                        value={formik.values.joinCloseDate}
                        onChange={(e) => {
                          formik.setFieldValue("joinCloseDate", e.target.value);
                          if (!formik.values.isJoinCloseValid) {
                            formik.setFieldValue("isJoinCloseValid", true);
                          }
                          if (!formik.values.isDrawPeriodValid) {
                            formik.setFieldValue("isJoinCloseValid", true);
                          }
                          if (formik.values.errorMessage.joinClose) {
                            formik.setFieldValue("errorMessage.joinClose", "");
                          }
                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }}
                        error={
                          formik.touched.joinCloseDate &&
                          formik.errors.joinCloseDate
                            ? true
                            : false
                        }
                        helperText={ErrorMessage({ name: "joinCloseDate" })}
                        disabled={isSubmitting || !isEditable}
                      />
                      <TextField
                        {...formik.getFieldProps("joinCloseTime")}
                        variant="outlined"
                        inputProps={{
                          style: { height: "0.1em", color: "black" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ pointerEvents: "none" }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                pl={1.5}
                                style={{ gap: 4 }}
                              >
                                <ScheduleRoundedIcon
                                  style={{
                                    color: "#3A4D54",
                                    fontSize: "18px",
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  style={{
                                    color: "#3A4D54",
                                  }}
                                >
                                  <b>GMT{timezone}</b>
                                </Typography>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                        type="time"
                        name="joinCloseTime"
                        value={formik.values.joinCloseTime}
                        onChange={(e) => {
                          formik.setFieldValue("joinCloseTime", e.target.value);
                          if (!formik.values.isJoinCloseValid) {
                            formik.setFieldValue("isJoinCloseValid", true);
                          }
                          if (!formik.values.isDrawPeriodValid) {
                            formik.setFieldValue("isJoinCloseValid", true);
                          }
                          if (formik.values.errorMessage.joinClose) {
                            formik.setFieldValue("errorMessage.joinClose", "");
                          }
                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }}
                        error={
                          formik.touched.joinCloseTime &&
                          formik.errors.joinCloseTime
                            ? true
                            : false
                        }
                        helperText={ErrorMessage({ name: "joinCloseTime" })}
                        disabled={isSubmitting || !isEditable}
                      />
                    </Box>
                    {!!formik.values.errorMessage.joinClose && (
                      <FormHelperText error={true}>
                        {formik.values.errorMessage.joinClose}
                      </FormHelperText>
                    )}
                  </Box>
                </>
              )}
            </Paper>

            <Box
              display="flex"
              alignItems="center"
              mt={2.5}
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" F>
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handlePrevious();
                    }}
                    disabled={isSubmitting}
                  >
                    {getLang(lang, "label.PREVIOUS")}
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: "#6AAF68" }}
                  // type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting &&
                    !formik.values.goToPreview && (
                      <CircularProgress
                        style={{ width: 15, height: 15, color: "white" }}
                      />
                    )
                  }
                  onClick={async () => {
                    formik.setFieldValue("goToPreview", false);
                    let now = moment();
                    validateFields(formik);

                    const errors = await formik.validateForm();

                    if (Object.keys(errors).length === 0) {
                      if (isPublished && isEditable && isJoinEnd) {
                        const joinClose =
                          formik.values.joinCloseDate &&
                          formik.values.joinCloseTime
                            ? moment(
                                `${formik.values.joinCloseDate} ${formik.values.joinCloseTime}`
                              )
                            : null;

                        let isJoinCloseEdited =
                          joinClose &&
                          initialValues &&
                          (initialValues.joinCloseDate !==
                            formik.values.joinCloseDate ||
                            initialValues.joinCloseTime !==
                              formik.values.joinCloseTime);
                        if (isJoinCloseEdited && now.isBefore(joinClose)) {
                          formik.setFieldValue("showExtendConfirmDialog", true);
                          return;
                        }
                      }
                      formik.handleSubmit();
                    } else {
                      formik.setTouched(setNestedObjectValues(errors, true));
                    }
                  }}
                >
                  {getLang(lang, "label.NEXT")}
                </Button>
              </Box>
              {isPublished && isEditable && (
                <Box>
                  <Button
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#6AAF68" }}
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting &&
                      !!formik.values.goToPreview && (
                        <CircularProgress
                          style={{ width: 15, height: 15, color: "white" }}
                        />
                      )
                    }
                    onClick={async () => {
                      formik.setFieldValue("goToPreview", true);
                      let now = moment();
                      validateFields(formik);

                      const errors = await formik.validateForm();

                      if (Object.keys(errors).length === 0) {
                        if (isPublished && isEditable && isJoinEnd) {
                          const joinClose =
                            formik.values.joinCloseDate &&
                            formik.values.joinCloseTime
                              ? moment(
                                  `${formik.values.joinCloseDate} ${formik.values.joinCloseTime}`
                                )
                              : null;

                          let isJoinCloseEdited =
                            joinClose &&
                            initialValues &&
                            (initialValues.joinCloseDate !==
                              formik.values.joinCloseDate ||
                              initialValues.joinCloseTime !==
                                formik.values.joinCloseTime);
                          if (isJoinCloseEdited && now.isBefore(joinClose)) {
                            formik.setFieldValue(
                              "showExtendConfirmDialog",
                              true
                            );
                            return;
                          }
                        }
                        formik.handleSubmit();
                      } else {
                        formik.setTouched(setNestedObjectValues(errors, true));
                      }
                    }}
                  >
                    {getLang(lang, "label.SAVE_AND_PREVIEW")}
                  </Button>
                </Box>
              )}
            </Box>
            <GeneralDialog
              isOpen={formik.values.showConfirmDialog}
              handleClose={() =>
                formik.setFieldValue("showConfirmDialog", false)
              }
              handleProceed={() => {
                formik.resetForm();
                if (!formik.values.isEditing) {
                  formik.setFieldValue("isEditing", true);
                }
              }}
              title={getLang(lang, "label.CONFIRMATION")}
              description={getLang(lang, "paragraph.CLEAR_FORM_QUESTION")}
              type="danger"
              icon={<img src={AlertIcon} alt="alert" />}
            />
            <GeneralDialog
              isOpen={formik.values.showExtendConfirmDialog}
              handleClose={() =>
                formik.setFieldValue("showExtendConfirmDialog", false)
              }
              handleProceed={() => {
                formik.setFieldValue("showExtendConfirmDialog", false);
                formik.handleSubmit();
              }}
              title={getLang(lang, "label.CONFIRMATION")}
              description={getLang(lang, "paragraph.EXTEND_JOIN_CLOSE_PERIOD")}
              type="danger"
              icon={<img src={AlertIcon} alt="alert" />}
            />
          </Box>
        );
      }}
    </Formik>
  );
}
