import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import EmployeePanelComponent from "./employeePanel.component";
import CreateEmployeeDialog from "../../dialog/createEmployeeDialog";
import {
  fetchAllEmployee,
  fetchRoleDropdown,
  fetchFilterBranch
} from "modules/employee/redux";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";

const EmployeePanelContainer = forwardRef((_, ref) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState({
    type: "name",
    value: "",
    branch: "",
    userGroupId: undefined
  });
  const dispatch = useDispatch();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [roleSelect, setRoleSelect] = useState("All");
  const [branchSelect, setBranchSelect] = useState("All");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const employeeData = useSelector((state) => state.employee.employeeList.data);
  const totalRecords = useSelector(
    (state) => state.employee.employeeList.totalRecords
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchAllEmployee.typePrefix)
  );
  const roleDropdown = useSelector((state) => state.employee.roleDropdown);
  const branchDropdown = useSelector((state) => state.employee.branchFilterDropdown);
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    dispatch(
      fetchAllEmployee({
        length: rowsPerPage,
        start: rowsPerPage * page,
        ...search
      })
    );
    dispatch(fetchRoleDropdown());
    dispatch(fetchFilterBranch());
  }, [search, rowsPerPage, page, dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords, [5, 10, 25, 50, 100, 200, 400]))
  }, [totalRecords])

  useImperativeHandle(ref, () => ({
    openCreateEmployeeModal: () => {
      setIsCreateDialogOpen(true);
    },
  }));

  const handleReload = () => {
    dispatch(
      fetchAllEmployee({
        length: rowsPerPage,
        start: rowsPerPage * page,
        ...search
      })
    );
  };

  const handleSearchTypeChange = (type) => {
    setSearch({
      ...search,
      type,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleSearch = async (value) => {
    setPage(0);
    setSearch({
      ...search,
      userGroupId: roleSelect === "All" ? undefined : roleSelect,
      nodeId: branchSelect === "All" ? undefined : branchSelect,
      value,
    });
  };

  const clearSearch = () => {
    setPage(0);
    setRoleSelect("All");
    setBranchSelect("All");
    setSearch({
      type: "name",
      value: "",
      branch: "",
      userGroupId: undefined
    });
  };

  const handleCreate = () => {
      setIsCreateDialogOpen(true)
  }

  return (
    <>
      <EmployeePanelComponent
        data={employeeData}
        isFetching={isFetching}
        page={page}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleSearchTypeChange={handleSearchTypeChange}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        search={search}
        roleDropdown={roleDropdown}
        roleSelect={roleSelect}
        setRoleSelect={setRoleSelect}
        clearSearch={clearSearch}
        branchDropdown={branchDropdown}
        branchSelect={branchSelect}
        setBranchSelect={setBranchSelect}
        handleCreate={handleCreate}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <CreateEmployeeDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
        handleReload={handleReload}
      />
    </>
  );
});

export default EmployeePanelContainer;
