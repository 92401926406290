import React from "react"
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Tabs,
} from "@material-ui/core"
import CustomTab from "components/tab"
import { getLang } from "app/feature/constants"
import { Wrapper } from "modules/reporting/components/layout"
import DealerProfilePanel from "modules/stockflow/components/panel/dealerProfilePanel"
import DealerStockoutPanel from "modules/stockflow/components/panel/dealerStockoutPanel"
import DealerRecallPanel from "modules/stockflow/components/panel/dealerRecallPanel"
import DealerReceivePanel from "modules/stockflow/components/panel/dealerReceivePanel"
import DealerOrderPanel from "modules/stockflow/components/panel/dealerOrderPanel"
import DealerRequestPanel from "modules/stockflow/components/panel/dealerRequestPanel"
import DealerInventoryPanel from "modules/stockflow/components/panel/dealerInventoryPanel"
import DealerRewardPointPanel from "modules/stockflow/components/panel/dealerRewardPointPanel"
import DealerDownlinePanel from "modules/stockflow/components/panel/dealerDownlinePanel"
import DealerDashboardPanel from "modules/stockflow/components/panel/dealerDashboardPanel"

const useStyle = makeStyles(theme => ({
  topbar: {
    backgroundColor: "#FFFFFF",
    padding: "16px 32px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "28px",
    color: "#344054",
    fontFamily: "Roboto",
  },
  fullWidth: {
    width: "100%",
    margin: "0px"
  },
  fullWidthGrid: {
    gridTemplateAreas: "'top' 'main'",
    gridTemplateColumns: "1fr"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },

  sideBanner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "4px 4px 0px 0px"
  },
  scroll: {
    width: "min-content"
  },
  tabContainer: {
    backgroundColor: "white",
    borderRadius: "0.5em"
  }
}))

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

export default function DealerDetailsComponent({
  lang,
  activeIndex,
  handleChange,
  dealer,
  isError,
  isLoading,
  handleReload,
  language,
  tabLabels,
  hasStockflowDealerEditAccessRight
}) {
  const classes = useStyle()
  const tabPanelComponents  = {
    [getLang(lang, "label.DASHBOARD")]: DealerDashboardPanel,
    [getLang(lang, "label.STOCKOUT")]: DealerStockoutPanel,
    [getLang(lang, "label.RECALL")]: DealerRecallPanel,
    [getLang(lang, "label.RECEIVE")]: DealerReceivePanel,
    [getLang(lang, "label.ORDERS")]: DealerOrderPanel,
    [getLang(lang, "label.REQUEST")]: DealerRequestPanel,
    [getLang(lang, "label.INVENTORY")]: DealerInventoryPanel,
    [getLang(lang, "label.REWARD_POINTS")]: DealerRewardPointPanel,
    [getLang(lang, "label.DOWNLINE")]: DealerDownlinePanel,
  };

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" style={{ textTransform: "none" }} children={label} />,
    }
  }

  return (
    <Wrapper>
      <Box className={classes.topbar}>
        <Typography variant="h4" className={classes.title}>{getLang(lang, 'label.DEALER_DETAILS')}</Typography>
      </Box>
      <Divider className={classes.fullWidth}/>
      <Box p={3}>
        <DealerProfilePanel 
          hasStockflowDealerEditAccessRight={hasStockflowDealerEditAccessRight}
          dealer={dealer}
          language={language}
          isError={isError}
          isLoading={isLoading}
          handleReload={handleReload}
          lang={lang}
        />
        {!isLoading && activeIndex !== null && (
          <>
            <Box display="flex" justifyContent="space-between" style={{ backgroundColor: "white" }} mt={0.25} px={3}>
              <Tabs
                value={activeIndex}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item) => {
                  return <CustomTab key={item.index} {...a11yProps(item.index, item.label)} />
                })}
              </Tabs>
            </Box>
            <Box
              style={{
                position: "relative",
              }}
            >
              {tabLabels.map((item) => {
                if (activeIndex === item.index && tabPanelComponents[item.label]) {
                  const Component = tabPanelComponents[item.label];
                  return (
                    <TabPanel value={activeIndex} index={item.index} key={item.index}>
                      <Box className={classes.panel}>
                        <Component />
                      </Box>
                    </TabPanel>
                  );
                }
                return null
              })}
            </Box>
          </>
        )}
      </Box>
    </Wrapper>
  )
}
