import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Paper,
  Chip,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from "formik"
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import LandingPageTemplateListTable
  from '../../table/landingPageTemplateListTable/landingPageTemplateListTable.container';
import TemplateInteractiveEditor from "../../templateInteractiveEditor";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  inputRoot: {
    paddingTop: "10px !important",
    paddingBottom: "8px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontWeight: 550,
    fontSize: "0.875rem"
  },
  paperRoot: {
    width: "217px",
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.15em",
    marginTop: "0.15em",
    border: "none",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
  },
  previewBox: {
    width: "100%",
    marginTop: "24px",
    [theme.breakpoints.up("lg")]: {
      width: "30vw",
      marginLeft: "24px",
      marginTop: 0,
    }
  },
  content: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    }
  },
  dangerProccedButton: {
    color: "white",
    background: "#F04438",
    "&:hover": {
      background: "#F04438",
    },
  },
}));

export default function LandingPageTemplateListPanelComponent({
  hasEditAccessRight,
  hasDeleteAccessRight,
  handleSearch,
  isFetching,
  selectedId,
  paginationOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  statusFilter,
  totalFiltered,
  data,
  rowsPerPage,
  handleCreate,
  isError,
  handleSelect,
  handleDelete,
  handleEdit,
  lang,
  previewSetting,
  isPreviewLoading,
  isCustomUi,
  customTemplateHtml
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: "",
        }}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_LANDING_PAGE_TEMPLATE")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#ECEFF0"
                  },
                  classes: { input: classes.inputRoot }
                }}
                {...formik.getFieldProps("search")}
              />
              <Button
                type="reset"
                variant='text'
                color='secondary'
                size='small'
                disabled={isFetching}
                disableElevation
                onClick={() => {
                  formik.setFieldValue("status", "");
                  formik.setFieldValue("search", "");
                  (search !== "" || statusFilter !== "") && formik.handleReset();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                type="submit"
                size='small'
                color="primary"
                variant="contained"
                disabled={isFetching}
                disableElevation
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box className={classes.content}>
        <Box flexGrow={1}>
          <LandingPageTemplateListTable
            hasEditAccessRight={hasEditAccessRight}
            hasDeleteAccessRight={hasDeleteAccessRight}
            data={data}
            isFetching={isFetching}
            selectedId={selectedId}
            paginationOptions={paginationOptions}
            isError={isError}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleReload={handleReload}
            page={page}
            search={search}
            totalFiltered={totalFiltered}
            rowsPerPage={rowsPerPage}
            handleCreate={handleCreate}
            handleSelect={handleSelect}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            lang={lang}
          />
        </Box>
        {selectedId && (
          <Box className={classes.previewBox}>
            <Box pb={2}>
              <Paper>
                <Box p={2}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    style={{ gap: 10 }}
                  >
                    {!isCustomUi ?
                      <Button
                        variant="outlined"
                        className={classes.dangerProccedButton}
                        disableElevation
                        type="button"
                        onClick={() => handleDelete(selectedId?.id)}
                      >
                        {getLang(lang, "label.DELETE")}
                      </Button> : null}
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      type="button"
                      onClick={() => handleEdit(selectedId?.id)}
                    >
                      {getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {getLang(lang, 'label.NAME')}
                    </Typography>
                    <Box style={{ wordBreak: "break-all" }}>
                      {selectedId?.name}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Box pb={2}>
              <Paper>
                <Box p={2}>
                  <Box>
                    <Typography variant="body2">
                      {getLang(lang, 'label.ASSIGNED_PRODUCT')}
                    </Typography>
                  </Box>
                  {(selectedId?.assignedProduct ?? []).map(e => (
                    <Chip
                      avatar={<Avatar alt="Product" src={e.picture} />}
                      variant="outlined"
                      label={e.name}
                      size="small"
                      className={classes.chip}
                    />
                  ))}
                </Box>
              </Paper>
            </Box>
            <Box>
              <Box gridArea="main">
                <Box style={{
                  borderRadius: "6px 6px 0px 0px",
                  overflow: "hidden"
                }}>
                  <Box style={{
                    backgroundColor: "white",
                    padding: "12px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{fontWeight: "bold", fontSize: 15}}
                    >
                      {getLang(lang, "label.PREVIEW")}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      padding: "25px 0px",
                      backgroundColor: "#E4E7EC",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {isCustomUi ? (
                      <iframe title="custom-template" scrolling="no" srcDoc={customTemplateHtml} width="100%" height="100%" style={{ border: "none", maxWidth: "370px" }} onLoad={(e) => e.target.style.height = e.target.contentWindow.document.body.scrollHeight + 8 + 'px'}></iframe>
                    ) : (
                      !isPreviewLoading && previewSetting ? (
                        <TemplateInteractiveEditor preview />
                      ) : (
                        <CircularProgress color="primary" />
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}
