import React from "react"
import { makeStyles, Typography, Box, Divider, Tabs } from "@material-ui/core"
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons"
import { BackButton } from "components/button"
import CustomTab from "components/tab"
import RefreshTable from "components/table/refreshTable"
import PanelBG from "assets/img/consumer-panel-bg.png"
import DefaultImage from "assets/img/defaultPhoto.png"
import LoyaltyIcon from "assets/img/loyaltyIcon.png"
import moment from "moment"
import ProfilePanel from "modules/consumer/components/panel/profilePanel"
import ScanHistoryPanel from "modules/consumer/components/panel/scanHistoryPanel"
import GiftPointRedemptionPanel from "modules/consumer/components/panel/giftPointRedemptionPanel"
import CampaignListPanel from "modules/consumer/components/panel/campaignListPanel"
import { Skeleton } from "@material-ui/lab"
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  profile: {
    height: 140,
    width: 140,
    minWidth: 140,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    }
  },
  newChip: {
    position: "absolute",
    bottom: 0,
    padding: "5px 15px",
    backgroundColor: "#32D583",
    borderRadius: "50px",
    color: "white"
  },
  profileContainer: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr",
    backgroundImage: "linear-gradient(#FFFFFF, #FFFFF3, #FFFEF2)",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(3)
    }
  },
  loyaltyBar: {
    backgroundImage: "linear-gradient(to right, #FFF0E380, #FFC08980)",
    width: "fit-content",
    padding: "4px 16px",
    borderRadius: "50px",
    marginTop: 5,
    color: "#DC6803",
    "& img": {
      height: "18px",
      minHeight: "18px",
      minWidth: "18px",
      objectFit: "contain",
      marginRight: 16
    }
  },
  panelBG: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "60%",
    objectFit: "contain",
  }
}))

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

export default function ConsumerDetailComponent({
  tabLabels,
  activeIndex,
  handleChange,
  consumer,
  isLoading,
  isError, 
  language,
  handleReload,
  id,
  lang
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" style={{ textTransform: "none" }} children={label} />,
    }
  }

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.CONSUMER")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.CONSUMER_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box gridArea="panel" mt={2}>
        {!isLoading && consumer && (
          <Box>
            <Box className={classes.profileContainer} px={3} py={2.5}>
              <img src={PanelBG} className={classes.panelBG} alt="bg" />
              <Box display="flex" alignItems="center">
                <Box className={classes.profile} mr={1.5}>
                  {consumer.profiles_image_url ? (
                    <img src={consumer.profiles_image_url} alt={consumer.name} />
                  ) : (
                    <img src={DefaultImage} alt="default profile" />
                  )}
                  {!!consumer.is_new && (
                    <Box
                      className={classes.newChip}
                    >
                      <Typography variant="body1">{getLang(lang, "label.NEW")}</Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Typography variant="h5"><b>{consumer.name}</b></Typography>
                  <Box className={classes.loyaltyBar} display="flex" alignItems="center">
                    <img src={LoyaltyIcon} alt="loyalty" />
                    <Typography variant="subtitle2">{getLang(lang, "label.LOYALTY_POINT")}: <b>{consumer.point.toLocaleString("en-US")}</b></Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" alignItems="center" mb={1.5}>
                  <Box style={{ minWidth: 100 }}>
                    <Typography variant="body1" color="textSecondary">{getLang(lang, "label.LAST_LOGIN")}</Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    <b>
                      {consumer.last_login ? moment(consumer.last_login).format('lll') : "-"}
                    </b>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box style={{ minWidth: 100 }}>
                    <Typography variant="body1" color="textSecondary">{getLang(lang, "label.DATE_JOINED")}</Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">:</Typography>
                  <Typography variant="body1" style={{ marginLeft: 10 }}>
                    <b>
                      {moment(consumer.date_joined).format('lll')}
                    </b>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" style={{ backgroundColor: "white" }} mt={0.25} px={3}>
              <Tabs
                value={activeIndex}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item, index) => {
                  return <CustomTab key={index} {...a11yProps(index, item)} />
                })}
              </Tabs>
            </Box>
            <Box
              style={{
                position: "relative",
              }}
            >
              {activeIndex !== null && tabLabels.map((item, index) => {
                if (item === getLang(lang, "label.PROFILE")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <ProfilePanel consumer={consumer} lang={lang} language={language} />
                      </Box>
                    </TabPanel>
                  )
                }
                if (item === getLang(lang, "label.SCAN_HISTORY")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <ScanHistoryPanel />
                      </Box>
                    </TabPanel>
                  )
                }
                if (item === getLang(lang, "label.CAMPAIGN")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <CampaignListPanel />
                      </Box>
                    </TabPanel>
                  )
                }
                if (item === getLang(lang, "label.GIFT_REDEMPTION_POINT")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <GiftPointRedemptionPanel id={id} />
                      </Box>
                    </TabPanel>
                  )
                }
                return null
              })}
            </Box>
          </Box>
        )}
        {!isLoading && isError && (
          <Box className={classes.tabContainer}>
            <Box style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isLoading && (
          <Box gridArea="main" style={{ background: "white" }}>
            <Box px={3} py={2.5} className={classes.profileContainer}>
              <img src={PanelBG} className={classes.panelBG} alt="bg" />
              <Box display="flex" alignItems="center" mr={1} flex={1}>
                <Skeleton variant='rect' width={140} height={140} />
                <Box ml={1} flex={1}>
                  <Skeleton variant="text" height={40}/>
                  <Skeleton variant="text" height={40}/>
                </Box>
              </Box>
              <Box flex={1}>
                <Skeleton variant="text" height={40}/>
                <Skeleton variant="text" height={40}/>
              </Box>
            </Box>
            <Box p={2}>
              <Box flex={1} mb={2}>
                <Skeleton variant="text" height={40} mt={2}/>
                <Skeleton variant="text" height={40} mt={2}/>
              </Box>
              <Box flex={1} mb={2}>
                <Skeleton variant="text" height={40} mt={2}/>
                <Skeleton variant="text" height={40} mt={2}/>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
