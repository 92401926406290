import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/fulfillClaim";

const updateWinnerClaimFulfillmentStatus = ({
  luckyDrawUuid,
  winnerUuid,
  status,
}) => {
  const body = {
    luckyDrawUuid,
    winnerUuid,
    status,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateWinnerClaimFulfillmentStatus;
