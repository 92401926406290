import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Tabs, Divider } from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ProductSummary from "../product-summary";
import PackageListing from "../package-listing";
import BoxListing from "../box-listing";
import { getLang } from "app/feature/constants";
import LooseItemListing from "../loose-item-listing";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function MainPageComponent({
  handleChange,
  activeIndex,
  activeSummaryIndex,
  tabLabels,
  lang,
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              Trackco
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.INVENTORY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>

          <Box>
            {activeIndex !== null &&
              tabLabels.map((item, index) => {
                if (item === getLang(lang, "label.PRODUCT_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <ProductSummary activeSummaryIndex={activeSummaryIndex}/>
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.PACKAGE_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <PackageListing />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.BOX_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <BoxListing />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.LOOSE_ITEM_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <LooseItemListing />
                      </Box>
                    </TabPanel>
                  );
                }
                return null;
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
