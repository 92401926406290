import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/cardDetail";

const getLuckyDrawCardDetail = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          lucky_draw_uuid: parameters.id,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getLuckyDrawCardDetail;
