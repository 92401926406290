import React, { useState } from "react";
import Modal from "../../modal";
import { connect, useSelector } from "react-redux";
import WebsiteForm from "../../form/websiteForm";
import InstagramForm from "../../form/instagramForm";
import TiktokForm from "../../form/tiktokForm";
import FacebookForm from "../../form/facebookForm";
import VideoForm from "../../form/videoForm";
import TextForm from "../../form/textForm";
import AuthForm from "../../form/authForm";
import MarketplaceForm from "../../form/marketplaceForm";
import GalleryForm from "../../form/galleryForm";
import { renameComponentTitle } from "modules/product-mgmt/utils/productPage.util";
import { getLang } from "app/feature/constants";
import AdditionalInfoForm from "../../form/additionalInfoForm";
import { ADDITIONAL_INFO_VIEW } from "lib/constants/accessRights";
import SurveyForm from "../../form/surveyForm";
import LuckyDrawForm from "../../form/luckyDrawForm";
import InstantWinForm from "../../form/instantWinForm";
import ComponentTabComponent from "../componentTab/componentTab.component";
import {addTemplateComponent} from "../../../redux/slice/products.slice";

function TemplateComponentTabContainer({
  addComponent,
  enableAddMarketplace,
  enableAddAuthentication,
  isEditorMode,
  enableAddWarranty,
  enableAddAdditionalInfo,
  enableAddLoyaltyPoint,
  enableAddSurvey,
  enableAddLuckyDraw,
  enableAddInstantWin,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newComponent, setNewComponent] = useState("");
  const lang = useSelector((state) => state.constant.languages);
  const hasAdtInfoViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ADDITIONAL_INFO_VIEW)
  );

  const companyId = useSelector((state) => state.profile.companyID);
  const earlyAccessCompany = [12, 28, 57, 85];
  const isEarlyAccess =
    process.env.REACT_APP_ENV === "DEVELOPMENT" ||
    earlyAccessCompany.includes(companyId);

  const handleClickChip = (component) => {
    setNewComponent(component);

    if (component === "warranty" || component === "loyalty point") {
      addComponent({ type: component, content: null });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddComponent = (values) => {
    addComponent({ type: newComponent, content: values });
    handleCloseModal();
  };

  function getComponentForm() {
    switch (newComponent) {
      case "website":
        return (
          <WebsiteForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "instagram":
        return (
          <InstagramForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "tiktok":
        return (
          <TiktokForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "facebook":
        return (
          <FacebookForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "video":
        return (
          <VideoForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "text":
        return (
          <TextForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "authentication":
        return (
          <AuthForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "marketplace":
        return (
          <MarketplaceForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "gallery":
        return (
          <GalleryForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
          />
        );

      case "additional information":
        if (hasAdtInfoViewAccessRight) {
          return (
            <AdditionalInfoForm
              type="new"
              handleResult={handleAddComponent}
              handleClose={handleCloseModal}
              open={isModalOpen}
            />
          );
        }
        break;

      case "survey":
        return (
          <SurveyForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
            open={isModalOpen}
          />
        );

      case "lucky draw":
        return (
          <LuckyDrawForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
            open={isModalOpen}
          />
        );

      case "instant win":
        return (
          <InstantWinForm
            type="new"
            handleResult={handleAddComponent}
            handleClose={handleCloseModal}
            open={isModalOpen}
          />
        );

      default:
        break;
    }
  }

  return (
    <>
      <ComponentTabComponent
        handleClick={handleClickChip}
        enableAddMarketplace={enableAddMarketplace}
        enableAddAuthentication={enableAddAuthentication}
        enableAddWarranty={enableAddWarranty}
        enableAddAdditionalInfo={enableAddAdditionalInfo}
        enableAddLoyaltyPoint={enableAddLoyaltyPoint}
        enableAddSurvey={enableAddSurvey}
        enableAddLuckyDraw={enableAddLuckyDraw}
        enableAddInstantWin={enableAddInstantWin}
        isEditorMode={isEditorMode}
        lang={lang}
        isEarlyAccess={isEarlyAccess}
        hasAdtInfoViewAccessRight={hasAdtInfoViewAccessRight}
      />
      <Modal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        title={renameComponentTitle(lang, newComponent)}
        helperText={
          newComponent === "gallery"
            ? getLang(lang, "paragraph.IMAGE_IN_CAROUSEL_FORMAT")
            : ""
        }
        maxWidth={newComponent === "additional information" ? "sm" : false}
      >
        {getComponentForm()}
      </Modal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addComponent: (payload) => dispatch(addTemplateComponent(payload)),
});

const mapStateToProps = (state) => ({
  enableAddMarketplace:
    !state.products.template.workspaceSetting.setting?.columns.compIds.includes("marketplace"),
  enableAddAuthentication:
    !state.products.template.workspaceSetting.setting?.columns.compIds.includes("authentication"),
  enableAddWarranty:
    !state.products.template.workspaceSetting.setting?.columns.compIds.includes("warranty"),
  enableAddAdditionalInfo:
    !state.products.template.workspaceSetting.setting?.columns.compIds.includes("additional information"),
  enableAddLoyaltyPoint:
    !state.products.template.workspaceSetting.setting?.columns.compIds.includes("loyalty point"),
  enableAddSurvey:
    state.products.template.workspaceSetting.setting?.columns.compIds.filter((c) => c.startsWith("survey"))?.length < 5,
  enableAddLuckyDraw:
    state.products.template.workspaceSetting.setting?.columns.compIds.filter((c) => c.startsWith("lucky draw"))?.length < 5,
  enableAddInstantWin:
    state.products.template.workspaceSetting.setting?.columns.compIds.filter((c) => c.startsWith("instant win"))?.length < 5,
  isEditorMode: state.products.template.workspaceSetting.isEditorMode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateComponentTabContainer);
