/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import TraditionalRafflePrizePanelComponent from "./traditionalRafflePrizePanel.component";

export default function TraditionalRafflePrizePanelContainer({
  formik,
  isSubmitting,
  ldCurrentState,
  isPublished
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <TraditionalRafflePrizePanelComponent
      lang={lang}
      formik={formik}
      isSubmitting={isSubmitting}
      ldCurrentState={ldCurrentState}
      isPublished={isPublished}
    />
  );
}
