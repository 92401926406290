/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import SingleProductBindingPanelComponent from "./singleProductBindingPanel.component";
import { useSelector, useDispatch } from "react-redux";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { getApiLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import { useLuckyDraw } from "modules/lucky-draw-v2/context/luckyDraw.context";

export default function SingleProductBindingPanelContainer({
  index,
  isSubmitting,
  product,
  formik,
  isEditable,
  isPublished,
  initialValues,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const productList = useSelector((state) => state.products.allProducts);
  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );

  const [branchList, setBranchList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { setExcludeProdBindList } = useLuckyDraw();
  const uuidRef = useRef(null);

  const [isInitialValue, setIsInitialValue] = useState(false);

  useEffect(() => {
    if (product.product && product.product.id !== uuidRef.current) {
      uuidRef.current = product.product.id;
      getData();
    }
  }, [product]);

  useEffect(() => {
    let prdId = null;
    let nodeIds = [];
    if (product.product) {
      prdId = product.product.id;
      nodeIds = product.nodes.map((node) => node.node_id);
    }

    const isInitial = initialValues.some(
      (v) =>
        v.product.id === prdId &&
        v.nodes.every((n) => nodeIds.includes(n.node_id))
    );
    setIsInitialValue(isInitial);
  }, [product, initialValues]);

  useEffect(() => {
    let excludeProdBindList = [];
    formik.values.products.map((p) => {
      p.product && excludeProdBindList.push(p.product.id);
      return p;
    });
    setExcludeProdBindList(excludeProdBindList);
  }, [formik.values.products]);

  const getData = () => {
    setIsFetching(true);
    luckyDrawV2Api
      .getProductNodes({ productUuid: product.product.id })
      .then((res) => {
        setBranchList(res.data);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <>
      <SingleProductBindingPanelComponent
        index={index}
        product={product}
        branchList={branchList}
        productList={productList}
        lang={lang}
        isSubmitting={isSubmitting}
        formik={formik}
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        isFetching={isFetching}
        isEditable={isEditable}
        isPublished={isPublished}
        isInitialValue={isInitialValue}
      />
    </>
  );
}
