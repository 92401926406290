import React, { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Tabs,
  Typography
} from '@material-ui/core'
import DefaultImage from "assets/img/img-default.png";
import CustomTab from 'components/tab';
import PropTypes from "prop-types";
import ProductPageSettingTab from '../../tab/productPageSettingTab';
import ComponentTab from '../../tab/componentTab';
import { getLang } from 'app/feature/constants';
import TemplateComponentTab from "../../tab/templateComponentTab";
import TemplateSettingTab from "../../tab/templateSettingTab";
import BranchSingleFlatSelect from "components/select/branchSingleFlatSelect";

const useStyle = makeStyles(theme => ({
  prodImage: {
    borderRadius: "50%",
    border: "1px solid",
    borderColor: theme.palette.divider,
    height: 54,
    width: 54,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  prodName: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  branch: {
    overflow: "hidden",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block"
  },
  tabRoot: {
    textTransform: "none"
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "8px 8px 0px 0px"
  },
  scroll: {
    width: "min-content"
  }
}))

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function ComponentMenuPanelComponent({
  onTemplateChange,
  templateDropdown,
  isTemplateEditor,
  productInfo,
  branch,
  lang,
  templateAfter,
  onTemplateListClick,
  handleUpdateTemplate,
  selectedTemplate,
  updateDisable,
  hasProductEditTemplateAccessRight,
  hasProductTemplateViewAccessRight
}) {
  const classes = useStyle()
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChangeTab = (event, index) => {
    setActiveIndex(index);
  };

  return (
    <Box style={{ backgroundColor: "white" }}>
      {!isTemplateEditor && (
        <>
          <Box display="flex" py={1} px={2} alignItems="center" flexWrap="wrap">
            <Box className={classes.prodImage}>
              <img src={productInfo.picture || DefaultImage} alt="product" />
            </Box>
            <Box ml={2} overflow="hidden" maxWidth={190}>
              <Typography variant="body2" className={classes.prodName}>
                {productInfo.productName}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.branch}
              >
                {branch}
              </Typography>
            </Box>
          </Box>
          <Divider />
          {hasProductEditTemplateAccessRight || (selectedTemplate && selectedTemplate?.uuid !== -1) ?
            <Box p={2} display="flex">
              <Box pr={1}>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", fontSize: 15, margin: "12px" }}
                >
                  {getLang(lang, "label.TEMPLATE_LABEL")}
                </Typography>
              </Box>
              <BranchSingleFlatSelect
                searchable={true}
                value={selectedTemplate}
                placeholder={getLang(lang, 'placeholder.SELECT_A_TEMPLATE')}
                searchPlaceholder={getLang(lang, 'placeholder.SEARCH_TEMPLATE_NAME')}
                isClearable={false}
                emptyMessage={getLang(lang, 'message.info.NO_TEMPLATE_FOUND')}
                dropdownItem={templateDropdown}
                handleChange={onTemplateChange}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                isEmpty={selectedTemplate === null}
                disabled={!hasProductEditTemplateAccessRight}
                style={{ opacity: hasProductEditTemplateAccessRight ? 1 : 0.5 }}
              />
              {hasProductEditTemplateAccessRight ?
                <Box pl={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    classes={{ root: classes.secondaryButton }}
                    disableElevation
                    type="button"
                    disabled={updateDisable}
                    onClick={handleUpdateTemplate}
                  >
                    {getLang(lang, 'label.UPDATE')}
                  </Button>
                </Box> : null}
            </Box> : null}
          <Divider />
        </>
      )}
      {!templateAfter || templateAfter === -1 ? (
        <>
          <Box px={2}>
            <Tabs
              value={activeIndex}
              onChange={handleChangeTab}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                root: classes.tabRoot,
                indicator: classes.indicator,
                scrollButtons: classes.scroll
              }}
            >
              <CustomTab
                id={`simple-tab-0`}
                {...{ "aria-controls": `simple-tabpanel-0` }}
                label={
                  <Typography variant="body2" style={{ textTransform: "none" }}>
                    {getLang(lang, "label.COMPONENT")}
                  </Typography>
                }
              />
              <CustomTab
                id={`simple-tab-1`}
                {...{ "aria-controls": `simple-tabpanel-1` }}
                label={
                  <Typography variant="body2" style={{ textTransform: "none" }}>
                    {getLang(lang, "label.SETTING")}
                  </Typography>
                }
              />
            </Tabs>
          </Box>
          <Divider />
          <Box>
            <TabPanel value={activeIndex} index={0}>
              {isTemplateEditor ? <TemplateComponentTab /> : <ComponentTab />}
            </TabPanel>
            <TabPanel value={activeIndex} index={1}>
              {isTemplateEditor ? <TemplateSettingTab /> : <ProductPageSettingTab />}
            </TabPanel>
          </Box>
        </>
      ) : (
        hasProductTemplateViewAccessRight && (
          <Box px={4} py={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box pb={2}>
              <Typography align="center">
                {getLang(lang, "paragraph.TEMPLATE_LIST_NAVIGATION")}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={onTemplateListClick}
            >
              {getLang(lang, "label.GO_TO_TEMPLATE_LIST")}
            </Button>
          </Box>
        )
      )}
    </Box>
  )
}
