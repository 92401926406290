import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, makeStyles, Tabs, Typography } from "@material-ui/core";

import { getLang } from "app/feature/constants";

import ConsumerClaimPanel from "modules/claim/component/panel/consumerClaimPanel";
import BranchClaimPanel from "modules/claim/component/panel/branchClaimPanel";
import CustomTab from "../../../../components/tab/customTab";
import ReplacementHandoffPanel from "modules/claim/component/panel/replacementHandoffPanel";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  notifyIndicator: {
    backgroundColor: "#ff311f",
    padding: "2px 7px",
    borderRadius: "16px",
    color: "white",
    display: "flex",
    alignItems: "center",
    fontSize: 10,
  },
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ClaimListPageComponent({
  lang,
  tabLabels,
  activeTab,
  setActiveTab,
  handleChange,
  notificationCount,
}) {
  const classes = useStyles();
  function a11yProps(index, label = "label", notificationCount = 0) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: (
        <Box display="flex" alignItems="center">
          <Typography variant="button" children={label} />
          {!!notificationCount && (
            <Box className={classes.notifyIndicator} ml={1}>
              {notificationCount}
            </Box>
          )}
        </Box>
      ),
    };
  }

  return (
    <Box>
      <Box
        mb={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.WARRANTY_CLAIM")}
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box>
        <Tabs
          value={activeTab || 0}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return (
              <CustomTab
                key={index}
                {...a11yProps(
                  index,
                  item.title,
                  notificationCount[item.identifier]
                )}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {activeTab !== null &&
          tabLabels.map((item, index) => {
            if (item.title === getLang(lang, "label.CONSUMER_CLAIM")) {
              return (
                <TabPanel value={activeTab} index={index} key={index}>
                  <Box className={classes.panel}>
                    <ConsumerClaimPanel lang={lang} />
                  </Box>
                </TabPanel>
              );
            }
            if (
              item.title === getLang(lang, "label.ITEM_REPLACEMENT_HANDOFF")
            ) {
              return (
                <TabPanel value={activeTab} index={index} key={index}>
                  <Box className={classes.panel}>
                    <ReplacementHandoffPanel lang={lang} />
                  </Box>
                </TabPanel>
              );
            }
            if (item.title === getLang(lang, "label.WARRANTY_REIMBURSEMENT")) {
              return (
                <TabPanel value={activeTab} index={index} key={index}>
                  <Box className={classes.panel}>
                    <BranchClaimPanel lang={lang} />
                  </Box>
                </TabPanel>
              );
            }
            return null;
          })}
      </Box>
    </Box>
  );
}
