import getBranchDropdown from './getBranchDropdown.api';
import getProductDropdown from './getProductDropdown.api';
import getAllProductByIds from './getAllProductByIds.api';
import updateProductStatus from './updateProductStatus.api';
import deleteProduct from './deleteProduct.api';
import getMeAndBranchDropdown from './getMeAndBranchDropdown.api';
import getMeAndBranchDropdownSpecial from './getMeAndBranchDropdownSpecial.api';
import updateBulkProductStatus from "./updateBulkProductStatus.api";
import { updateBulkProduct } from "./updateBulkProduct.api";
import { createBulkProduct } from "./createBulkProduct.api";
import { createProductCategories } from './productCategory/addCategories.api';
import { getProductCategoryListing } from './productCategory/getProductCategoryListing.api';
import { deleteCategory } from "./productCategory/deleteCategory.api"
import { editProductCategory } from "./productCategory/editCategory.api"
import { updateCategoriesStatus } from "./productCategory/updateCategoriesStatus.api";
import { updateCategoryStatus } from "./productCategory/updateCategoryStatus.api";
import { getProductCategoryDropdown } from "./productCategory/getProductCategoryDropdown.api";
import { addBrandModel } from "./brandModel/addBrandModel.api";
import { deleteBrandModels } from "./brandModel/deleteBrandModels.api";
import { editBrandModel } from "./brandModel/editBrandModel.api";
import { getBrandDropdown } from "./brandModel/getBrandDropdown.api";
import { getBrandModelListing } from "./brandModel/getBrandModelListing.api";
import { getBrandModelDropdown } from "./brandModel/getBrandModelsDropdown.api";
import { getModelDropdown } from "./brandModel/getModels.api"
import { updateBrandModelStatus } from "./brandModel/updateBrandModelStatus.api";
import { updateBrandModelsStatus } from "./brandModel/updateBrandModelsStatus.api";
import { addAttribute } from "./productAttribute/addAttribute.api"
import { deleteAttribute } from "./productAttribute/deleteAttribute.api"; 
import { editAttribute } from "./productAttribute/editAttribute.api"; 
import { getAttributeDropdown } from "./productAttribute/getAttributeDropdown.api"; 
import { getAttributeListing } from "./productAttribute/getAttributeListing.api"; 
import { updateAttributeStatus } from "./productAttribute/updateAttributeStatus.api"; 
import { updateAttributesStatus } from "./productAttribute/updateAttributesStatus.api";
import quickUpdateProduct from './quickUpdateProduct.api';
import getProductDropdownByBranch from './getProductDropdownByBranch.api';
import { getBrandDropdownWithNoBrand } from './brandModel/getBrandDropdownWithNoBrand.api';
import { addModelsToExistingBrand } from './brandModel/addModelsToExistingBrand.api';
import {setBrandImage} from "./brandModel/setBrandImage.api";
import getCompanyProductCustomFields from './getCompanyProductCustomFields.api';
import {createTemplate} from "./template/createTemplate.api";
import {deleteTemplate} from "./template/deleteTemplate.api";
import {editTemplate} from "./template/editTemplate.api";
import {fetchTemplate} from "./template/fetchTemplate.api";
import {fetchTemplates} from "./template/fetchTemplates.api";
import {fetchOverrideWarningList} from "./template/fetchOverrideWarningList.api";
import {fetchTemplateDropdown} from "./template/fetchTemplateDropdown.api";
import {fetchCustomTemplateHtml} from "./template/fetchCustomTemplateHtml.api";
import {editProductTemplate} from "./template/editProductTemplate.api";

const productApi = {
    getBranchDropdown,
    getProductDropdown,
    getAllProductByIds,
    updateProductStatus,
    deleteProduct,
    getMeAndBranchDropdown,
    getMeAndBranchDropdownSpecial,
    updateBulkProductStatus,
    createProductCategories,
    getProductCategoryListing,
    deleteCategory,
    editProductCategory,
    updateCategoriesStatus,
    updateCategoryStatus,
    getProductCategoryDropdown,
    addBrandModel,
    deleteBrandModels,
    editBrandModel,
    getBrandDropdown,
    getBrandModelListing,
    getBrandModelDropdown,
    getBrandDropdownWithNoBrand,
    getModelDropdown,
    updateBrandModelStatus,
    updateBrandModelsStatus,
    addAttribute,
    deleteAttribute,
    editAttribute,
    getAttributeDropdown,
    getAttributeListing,
    updateAttributeStatus,
    updateAttributesStatus,
    quickUpdateProduct,
    updateBulkProduct,
    getProductDropdownByBranch,
    createBulkProduct,
    addModelsToExistingBrand,
    setBrandImage,
    getCompanyProductCustomFields,
    createTemplate,
    deleteTemplate,
    editTemplate,
    fetchTemplate,
    fetchTemplates,
    fetchTemplateDropdown,
    fetchOverrideWarningList,
    fetchCustomTemplateHtml,
    editProductTemplate,
}

export default productApi;
