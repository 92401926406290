import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  ROOT,
  LUCKY_DRAW_DETAIL,
  LUCKY_DRAW_CREATE,
  LD_LIVE_DRAW,
  INSTANT_WIN_CREATE,
  LUCKY_DRAW_PARTICIPANTS,
  INSTANT_WIN_DETAIL,
} from "./config/lucky-draw-v2.route";
import { MainPage, LuckyDrawDetailPage, LuckyDrawParticipants } from "./pages";
import LuckyDrawCreatePage from "./pages/lucky-draw-create";

export default function LuckyDrawModule() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route
        exact
        path={LUCKY_DRAW_CREATE}
        render={(props) => <LuckyDrawCreatePage {...props} type={1} />}
      />
      <Route
        exact
        path={INSTANT_WIN_CREATE}
        render={(props) => <LuckyDrawCreatePage {...props} type={2} />}
      />
      <Route exact path={LUCKY_DRAW_DETAIL} component={LuckyDrawDetailPage} />
      <Route exact path={LUCKY_DRAW_PARTICIPANTS} component={LuckyDrawParticipants} />
      <Route exact path={INSTANT_WIN_DETAIL} component={LuckyDrawDetailPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT, LD_LIVE_DRAW };
