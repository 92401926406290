import React from "react"
import {
  Box,
  Button,
  makeStyles,
  TextField,
  // CircularProgress,
} from "@material-ui/core"
import { Formik } from "formik"
// import { DownloadSVG } from "modules/stockflow/utils/static"
import BranchSearch from "modules/stockflow/components/select/branchSearch"
import { getLang } from "app/feature/constants"
import LooseItemListTable from "modules/stockflow/components/table/looseItemListTable"

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important"
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto"
    }
  },
  divider: {
    height: "70%",
    placeSelf: "center"
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 550,
    color: theme.palette.primary.main,
    fontSize: "0.875rem"
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD"
  }
}))

export default function LooseItemListingComponent({
  hasStockflowInventoryViewAccessRight,
  looseItems,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  token,
  paginationOptions,
  lang,
  tableFilter,
  handleViewDetail
}) {
  const classes = useStyle()

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          branch: tableFilter.branch,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Box display="flex"
                className={classes.dropdownSearchBox}
              >
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH_LOOSE_ITEM")}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput
                  }}
                  {...formik.getFieldProps("search")}
                />
              </Box>
              <BranchSearch
                placeholder={getLang(lang, "placeholder.BRANCH")}
                value={formik.values.branch}
                handleChange={(value) => formik.setFieldValue("branch", value)}
                isEmpty={formik.values.branch?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(branch) => {
                  let temp = [...formik.values.branch];
                  if (temp.length > 0) {
                    const filteredBranchs = temp.filter((item) => item.node_id !== branch.node_id);
                    temp = [...filteredBranchs];
                  }
                  formik.setFieldValue("branch", temp);
                }}
              />
              <Button
                variant='text'
                disableElevation
                color="secondary"
                size='small'
                disabled={isFetching}
                onClick={() => {
                  clearSearch()
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color='primary'
                size='small'
                type='submit'
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box style={{ display: "flex", justifyContent: "space-between", paddingBottom: "12px" }}>
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
          disableElevation
          onClick={() => {
            // exportListing()
          }}
          startIcon={isExporting ? <CircularProgress style={{ width: 10, height: 10 }}/> : <DownloadSVG />}
          disabled={isExporting || isFetching || (((!isSelectAll && !selectedIds.length) || (isSelectAll && selectedIds.length === totalFiltered)) && totalFiltered > 0)}
        >
          EXPORT XLS
        </Button> */}
      </Box>
      <LooseItemListTable
        hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
        rows={looseItems}
        isFetching={isFetching}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        isError={isError}
        token={token}
        paginationOptions={paginationOptions}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </>
  )
}
