/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import LiveDrawSetupDialogComponent from "./liveDrawSetupDialog.component";
import { useDispatch, useSelector } from "react-redux";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";

function LiveDrawSetupDialogContainer({ isOpen, handleClose, uuid }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.constant.languages);

  const [prizes, setPrizes] = useState([]);
  const [isFetching, setIsFetching] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const prizesRef = useRef([]);

  useEffect(() => {
    if (uuid) {
      getData();
    }
  }, [uuid]);

  const getData = () => {
    if (isError) setIsError(false);
    setIsFetching(true);
    luckyDrawV2Api
      .getLuckyDrawDetails(uuid)
      .then((res) => {
        setPrizes(res.data.data.prizes);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => setIsFetching(false));
  };

  const handleSubmit = (values) => {
    prizesRef.current = values.prizes.map((val, i) => ({
      uuid: val.uuid,
      draw_duration: val.draw_duration,
      draw_type: val.draw_type,
      draw_sequence: i + 1,
    }));
    setShowConfirmDialog(true);
  };

  const updateSetup = () => {
    setShowConfirmDialog(false);
    setIsSubmitting(true);
    luckyDrawV2Api
      .updateLiveDrawSetup({
        luckyDrawUuid: uuid,
        prizes: prizesRef.current,
      })
      .then(() => {
        handleClose();
        history.push(`${ROOT}/live-draw/${uuid}`);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <LiveDrawSetupDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        lang={lang}
        prizes={prizes}
        isSubmitting={isSubmitting}
        isError={isError}
        isFetching={isFetching}
        handleReload={getData}
      />
      <GeneralDialog
        isOpen={showConfirmDialog}
        handleClose={() => {
          setShowConfirmDialog(false);
        }}
        handleProceed={() => {
          updateSetup();
        }}
        title={getLang(lang, "label.START_LIVE_DRAW")}
        description={getLang(lang, "paragraph.START_LIVE_DRAW_QUESTION")}
        type="danger"
        proceedLabel={getLang(lang, "label.START")}
        icon={<img src={AlertIcon} alt="" />}
      />
    </>
  );
}

export default LiveDrawSetupDialogContainer;
