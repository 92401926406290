import React, { useEffect, useState } from "react"
import BranchSingleFlatSelectComponent from "./branchSingleFlatSelect.component"
import BranchSingleFlatSelectPanelComponent from "./branchSingleFlatSelectPanel.component"

function BranchSingleFlatSelectContainer({
  searchable,
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching = false,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  emptyMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [item, setItem] = useState([])
  const open = Boolean(anchorEl)

  useEffect(() => {
    setItem(dropdownItem)
    setSearchText("")
  }, [dropdownItem])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleSearch("")
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectedValue = (selectedValue) => {
    if (isEmpty) {
      return false
    }
    return selectedValue.uuid === value.uuid
  }

  const handleSearch = (val) => {
    let text = val.toLowerCase()

    setSearchText(text)

    let temp = dropdownItem.filter((i) => !!i.name.toLowerCase().includes(text))
    setItem(temp)
  }

  return (
    <>
      <BranchSingleFlatSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
      />
      <BranchSingleFlatSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={item}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        searchText={searchText}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        selectedValue={selectedValue}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
        isEmpty={isEmpty}
      />
    </>
  )
}

export default BranchSingleFlatSelectContainer
