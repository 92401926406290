import React from "react";
import LuckyDrawCreateEditForm from "modules/lucky-draw-v2/components/form/luckyDrawCreateEditForm";
import { Box, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";

export default function LuckyDrawCreatePage({
  lang,
  type,
  initialValues,
  luckyDrawUuid,
  getLuckyDrawDetail,
  isLoading,
}) {
  const history = useHistory();

  return (
    <Box>
      <Box
        mb={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="flex-start" fontWeight="bold">
          <Typography
            variant="body1"
            color="primary"
            style={{ color: "#818D91" }}
            onClick={() => {
              history.push(ROOT);
            }}
          >
            {getLang(lang, "label.LUCKY_DRAW_LISTING")}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon
              color="disabled"
              style={{ color: "#818D91" }}
            />
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="primary"
              style={{ color: "#818D91" }}
            >
              <b>
                {getLang(
                  lang,
                  type === 2
                    ? "label.CREATE_INSTANT_WIN"
                    : "label.CREATE_TRADITIONAL_RAFFLE"
                )}
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
      <LuckyDrawCreateEditForm
        type={type}
        initialValues={initialValues}
        uuid={luckyDrawUuid}
        getLuckyDrawDetail={getLuckyDrawDetail}
        isLoading={isLoading}
      />
    </Box>
  );
}
