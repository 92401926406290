import React from "react";
import { Box } from "@material-ui/core";
import { PointCutTable } from "../../table";
import { GeneralSearch } from "components/search";

export default function PointCutPanelComponent({
  hasPointCutAddAccessRight,
  hasPointCutEditAccessRight,
  hasPointCutActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreate,
  handleStatusChange,
  handleReload,
  paginationOptions
}) {
  return (
    <Box>
      <GeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        isFetching={isFetching}
      />

      <PointCutTable
        hasPointCutAddAccessRight={hasPointCutAddAccessRight}
        hasPointCutEditAccessRight={hasPointCutEditAccessRight}
        hasPointCutActivateOrDeactivateAccessRight={hasPointCutActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </Box>
  );
}
