import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import ProductPageSettingTabComponent from "../productPageSettingTab/productPageSettingTab.component";
import {addTemplateLanguage, deleteTemplateLanguage} from "../../../redux/slice/products.slice";

function TemplateSettingTabContainer({ isEditorMode, lang, addLanguage, deleteLanguage }) {
  const [showLangSelect, setShowLangSelect] = useState(false);
  const languages = useSelector(state => state.constant.languages);

  const handleShowLangSelect = () => {
    setShowLangSelect(true);
  };

  const handleAddLanguage = values => {
    addLanguage(values);
    setShowLangSelect(false);
  };

  const handleDeleteLanguage = lang => {
    deleteLanguage(lang);
  };

  return (
    <ProductPageSettingTabComponent
      handleDelete={handleDeleteLanguage}
      lang={lang}
      showLangSelect={showLangSelect}
      handleShowLangSelect={handleShowLangSelect}
      handleAddLanguage={handleAddLanguage}
      languages={languages}
      isEditorMode={isEditorMode}
    />
  );
}

const mapStateToProps = state => ({
  lang: state.products.template.workspaceSetting.setting.lang,
  isEditorMode: state.products.template.workspaceSetting.isEditorMode,
});

const mapDispatchToProps = dispatch => ({
  addLanguage: payload => dispatch(addTemplateLanguage(payload)),
  deleteLanguage: payload => dispatch(deleteTemplateLanguage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateSettingTabContainer);
