import { createAsyncThunk, createAction, unwrapResult } from "@reduxjs/toolkit";
import serialNumberApi from "app/api/serialnumber";
import productApi from "app/api/product";
import { setLoading } from "modules/notification";
import axios from "axios";
import { prepareProductUiJson } from "modules/product-mgmt/utils/productPage.util";
import { isDataUrl } from "lib/generalUtility";
import { UploadType } from "lib/constants/aws_s3";
import { v4 as uuidv4 } from "uuid";
import { uploadMultipleImageToS3, uploadJSONToS3 } from "app/feature";
import { setProductPicture } from "../slice/productPage.slice";
import {getResizeDimension, setImgFormatAndSize} from "../../../../lib/FileHelper";

export const PRODUCTS = "products";

export const fetchBranchDropdown = createAsyncThunk(
  `${PRODUCTS}/fetchBranchDropdown`,
  async (payload, { rejectWithValue }) => {
    return productApi
      .getBranchDropdown
      .get()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

export const fetchMeAndBranchDropdown = createAsyncThunk(
  `${PRODUCTS}/fetchBranchDropdown`,
  async (payload, { rejectWithValue }) => {
    return productApi
      .getMeAndBranchDropdown
      .get(payload)
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

export const fetchMeAndBranchDropdownSpecial = createAsyncThunk(
  `${PRODUCTS}/fetchBranchDropdownSpecial`,
  async (payload, { rejectWithValue }) => {
    return productApi
      .getMeAndBranchDropdownSpecial
      .get(payload)
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);


export const createNewProduct = createAsyncThunk(
  `${PRODUCTS}/createNewProduct`,
  async (payload, { rejectWithValue, getState }) => {
    const uiPath = payload;
    const {
      productName,
      desc,
      nodes,
      picture,
      scanLimit,
      sku,
      price,
      category,
      currencyCode,
      attributes,
      brand,
      model,
      incentivePoint,
      warrantyInfo,
      customFields
    } = getState().productPage;
    const companyId = getState().account.id;

    return serialNumberApi.product
      .post({
        productName,
        desc,
        companyId,
        nodes: nodes.map(node => node.node_id),
        uiPath: uiPath.split("?")[0],
        picture,
        scanLimit,
        category: category,
        brand: brand.id,
        model: model.id,
        attributeoptions: attributes.map(attribute => attribute.optionsId),
        sku: sku,
        currency_code: currencyCode,
        unit_price: Number(price),
        incentive_point: incentivePoint,
        warrantySettingId:warrantyInfo.warrantySettingId,
        customFields: customFields.map(customField => ({
          uuid: customField.uuid,
          value: customField.value
        }))
      })
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

export const publishNewProduct = createAction(`${PRODUCTS}/publishNewProduct`);

export const getProductListing = createAsyncThunk(
  `${PRODUCTS}/getProductListing`,
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setLoading({ id: getProductListing.typePrefix, state: true }));
    return serialNumberApi.products
      .post(payload)
      .then((response) => {
        dispatch(
          setLoading({ id: getProductListing.typePrefix, state: false })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          setLoading({ id: getProductListing.typePrefix, state: false })
        );

        return rejectWithValue(error);
      });
  }
);
export const getAllProducts = createAsyncThunk(
  `${PRODUCTS}/getAllProducts`,
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setLoading({ id: getAllProducts.typePrefix, state: true }));
    return serialNumberApi.getAllProducts()
      .then((response) => {
        // dispatch(
        //   setLoading({ id: getAllProducts.typePrefix, state: false })
        // );

        return response.data;
      })
      .catch((error) => {
        // dispatch(
        //   setLoading({ id: getAllProducts.typePrefix, state: false })
        // );

        return rejectWithValue(error);
      });
  }
);
export const getBulkEditProductListing = createAsyncThunk(
  `${PRODUCTS}/getBulkEditProductListing`,
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setLoading({ id: getBulkEditProductListing.typePrefix, state: true }));
    return serialNumberApi.getBulkEditProductList(payload)
      .then((response) => {
        dispatch(
          setLoading({ id: getBulkEditProductListing.typePrefix, state: false })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          setLoading({ id: getBulkEditProductListing.typePrefix, state: false })
        );

        return rejectWithValue(error);
      });
  }
);

export const getProductDropdown = createAsyncThunk(
  `${PRODUCTS}/getProductDropdown`,
  async (payload, thunkApi) => {
    return productApi
      .getProductDropdown(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getProductDropdownByBranch = createAsyncThunk(
  `${PRODUCTS}/getProductDropdownByBranch`,
  async (payload, thunkApi) => {
    return productApi
      .getProductDropdownByBranch(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
)

export const updateProductInfo = createAsyncThunk(
  `${PRODUCTS}/updateProductInfo`,
  async (payload, thunkApi) => {
    const { productPage, account } = thunkApi.getState();
    return serialNumberApi.product
      .put({
        id: productPage.productId,
        productName: productPage.productName,
        desc: productPage.desc,
        companyId: account.id,
        nodes: productPage.nodes.map(node => node.node_id),
        picture: productPage.picture,
        scanLimit: productPage.scanLimit,
        category: productPage.category,
        brand: productPage.brand.id,
        model: productPage.model.id,
        attributeoptions: productPage.attributes.map(attribute => attribute.optionsId),
        sku: productPage.sku,
        currency_code: productPage.currencyCode,
        unit_price: Number(productPage.price),
        incentive_point: productPage.incentivePoint,
        warrantySettingId: productPage.warrantyInfo.warrantySettingId,
        customFields: productPage.customFields.map(customField => ({ 
          uuid: customField.uuid,
          value: customField.value
        }))
      })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateProductPhoto = createAction(
  `${PRODUCTS}/updateProductPhoto`
);

export const getProductPage = createAsyncThunk(
  `${PRODUCTS}/getProductPage`,
  async (payload, thunkApi) => {
    const id = thunkApi.getState().productPage.productId;
    const productData = await serialNumberApi.productui
      .get(id)
      .then((res) => res.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    const productUrl = productData.live_ui_path;

    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    const productPage = await instance.get(productUrl);

    return {
      createdAt: productData.created_at,
      updatedAt: productData.updated_at,
      ...productPage.data
    };
  }
);

export const updateProductPage = createAction(`${PRODUCTS}/updateProductPage`);

export const updateProductUi = createAsyncThunk(
  `${PRODUCTS}/updateProductUi`,
  async (payload, { rejectWithValue, getState }) => {
    const { productId, templateAfter } = getState().productPage;

    return serialNumberApi.productui
      .put({
        productId,
        uiPath: payload.split("?")[0],
        lang: "EN",
        liveUiPath: payload.split("?")[0],
        status: "active",
        template: templateAfter === -1 ? null : templateAfter,
      })
      .then((response) => {
        if (response.success) return response;
        else return rejectWithValue(response.message);
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const uploadProductPageJson = createAsyncThunk(
  `${PRODUCTS}/uploadProductPageJson`,
  async (payload, { getState, dispatch }) => {
    const componentTypeContainImage = ["gallery"];
    const uploadTypeJson = UploadType.productPageUi;
    const uploadTypeImage = UploadType.productImage;
    const companyId = getState().account.id;
    const productImage = getState().productPage.picture;
    const productUiData = prepareProductUiJson({ ...getState().productPage });
    const componentWithImageIds = productUiData.columns.compIds.filter((id) =>
      componentTypeContainImage.includes(id.split("-")[0])
    );
    const arrayParams = [];
    componentWithImageIds.forEach((id) => {
      const {
        content: { images, order },
        type,
      } = productUiData.components[id];
      order.forEach((imageId) => {
        const img = images[imageId];
        if (isDataUrl(img.img)) {
          arrayParams.push({
            uploadType: UploadType.productPageComponent,
            base64Image: img.img,
            id: imageId,
            fileName: `${Date.now()}_${imageId}`,
            compId: id,
            componentType: type,
          });
        }
      });
    });

    for (const id of componentWithImageIds) {
      const {
        content: { images, order },
        type,
      } = productUiData.components[id];

      for (const imageId of order) {
        const img = images[imageId];
        if (isDataUrl(img.img)) {
          if (type === 'gallery') {
            if (img.type === 'image/gif') {
              arrayParams.push({
                uploadType: UploadType.productPageComponent,
                base64Image: img.img,
                id: imageId,
                fileName: `${Date.now()}_${imageId}`,
                compId: id,
                componentType: type,
              });
            }
            else {
              const dimension = await getResizeDimension(img.img, 4000, 4000);
              const imageData = await setImgFormatAndSize(img.img, dimension[0], dimension[1], "image/webp");
              let split = `${Date.now()}_${imageId}`.split('.');
              split[split.length - 1] = "webp";

              arrayParams.push({
                uploadType: UploadType.productPageComponent,
                base64Image: imageData,
                id: imageId,
                fileName: split.join('.'),
                compId: id,
                componentType: type,
              });
            }
          }
          else {
            arrayParams.push({
              uploadType: UploadType.productPageComponent,
              base64Image: img.img,
              id: imageId,
              fileName: `${Date.now()}_${imageId}`,
              compId: id,
              componentType: type,
            });
          }
        }
      }
    }

    if (productImage && isDataUrl(productImage)) {
      const imagefileName = `${uuidv4()}.webp`;
      arrayParams.splice(0, 0, {
        fileName: imagefileName,
        base64Image: productImage,
        id: companyId,
        uploadType: uploadTypeImage
      });
    }
    if (arrayParams.length > 0) {
      return dispatch(uploadMultipleImageToS3(arrayParams))
        .then(unwrapResult)
        .then((imageResult) => {
          let productUiData = prepareProductUiJson({
            ...getState().productPage,
          });
          const JsonfileName = `${uuidv4()}.json`;
          const productImageResult = imageResult.find(
            (res) => res.type === uploadTypeImage
          );
          if (productImageResult) {
            const url = `${productImageResult.url.split("?")[0]}`;
            dispatch(setProductPicture(url));
          }
          imageResult
            .slice(productImageResult ? 1 : 0, imageResult.length)
            .forEach((res, index) => {
              const url = `${res.url.split("?")[0]}`;
              const { compId, id } =
                arrayParams[productImageResult ? index + 1 : index];
              productUiData = {
                ...productUiData,
                components: {
                  ...productUiData.components,
                  [compId]: {
                    ...productUiData.components[compId],
                    content: {
                      ...productUiData.components[compId].content,
                      images: {
                        ...productUiData.components[compId].content.images,
                        [id]: {
                          ...productUiData.components[compId].content.images[id],
                          img: url,
                        },
                      },
                    },
                  },
                }
              };
            });
          return dispatch(
            uploadJSONToS3({
              uploadType: uploadTypeJson,
              data: productUiData,
              fileName: JsonfileName
            })
          )
            .then(unwrapResult)
            .then((url) => url);
        });
    } else {
      const JsonfileName = `${uuidv4()}.json`;
      const productUiData = prepareProductUiJson({ ...getState().productPage });
      return dispatch(
        uploadJSONToS3({
          uploadType: uploadTypeJson,
          data: productUiData,
          fileName: JsonfileName
        })
      )
        .then(unwrapResult)
        .then((url) => url);
    }
  }
);

export const getProduct = createAsyncThunk(
  `${PRODUCTS}/getProduct`,
  async (payload, thunkApi) => {

    return serialNumberApi.product
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateProductStatus = createAsyncThunk(
  `${PRODUCTS}/updateProductStatus`,
  async (payload, { rejectWithValue }) => {
    return productApi.updateProductStatus(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateBulkProductStatus = createAsyncThunk(
  `${PRODUCTS}/updateBulkProductStatus`,
  async (payload, { rejectWithValue }) => {
    return productApi.updateBulkProductStatus({
      filter: payload.filter,
      update: payload.update,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateBulkProduct = createAsyncThunk(
  `${PRODUCTS}/updateBulkProduct`,
  async (payload, { rejectWithValue }) => {
    return productApi.updateBulkProduct(payload)
      .then((response) => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const createBulkProduct = createAsyncThunk(
  `${PRODUCTS}/createBulkProduct`,
  async (payload, { rejectWithValue }) => {
    return productApi.createBulkProduct(payload)
      .then((response) => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteProduct = createAsyncThunk(
  `${PRODUCTS}/deleteProduct`,
  async (payload, { rejectWithValue }) => {
    return productApi.deleteProduct(payload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
);

export const quickUpdateProduct = createAsyncThunk(
  `${PRODUCTS}/quickUpdateProduct`,
  async (payload, { rejectWithValue }) => {
    return productApi.quickUpdateProduct({
      id: payload.id,
      name: payload.name,
      nodes: payload.nodes.map(node => node.node_id),
      scanlimit: payload.scanLimit,
      category: payload.category === null ? "" : payload.category.uuid || payload.category.id,
      brand: payload.brand.id === undefined ? "" : payload.brand.id === "-1" ? "" : payload.brand.id,
      model: payload.model.id === undefined ? "" : payload.model.id === "-1" ? "" : payload.model.id,
      attributeoptions: payload.attribute.map(a => a.optionsId),
      sku: payload.sku,
      currency_code: payload.currencyCode,
      unit_price: payload.unitPrice,
      incentive_point: payload.incentivePoint,
      status: payload.status
    })
      .then((response) => response.data)
      .catch(error => rejectWithValue(error))

  }
)

