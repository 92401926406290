import React from "react";
import DraggableParticipantInfoCardComponent from "./draggableParticipantInfoCard.component";
import { cloneDeep } from "lodash";

export default function DraggableParticipantInfoCardContainer({
  index,
  lang,
  infoList,
  info,
  updateInfo,
  formik,
  disabled
}) {
  const deleteInfo = (info) => {
    let temp = cloneDeep(infoList);
    temp.splice(index, 1);
    updateInfo(temp);
  };

  return (
    <DraggableParticipantInfoCardComponent
      index={index}
      lang={lang}
      info={info}
      deleteInfo={deleteInfo}
      formik={formik}
      disabled={disabled}
    />
  );
}
