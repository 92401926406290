import React, { useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import * as Yup from "yup";
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
} from "@material-ui/icons";
import { AddIcon as AddIconGreen } from "components/icon";
import DraggableParticipantInfoCard from "../../card/draggableParticipantInfoCard";
import {
  defaultParticipantFormFields,
  ldState,
  participantFieldTypeChoice,
} from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import AddQuestionPopper from "../../popper";

const useStyles = makeStyles((theme) => ({
  required: {
    "&::after": {
      content: "' *'",
      color: "#E21B1B",
    },
  },
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  selectRoot: {
    padding: 12,
    backgroundColor: "white",
  },
  radioButton: {
    borderWidth: 2,
    textTransform: "none",
    display: "flex",
    backgroundColor: "white",
  },
  input: {
    "&::placeholder": {
      color: "#3A4D5480",
    },
  },
  greyCard: {
    background: "#F8F8F8",
    borderRadius: 4,
    padding: 16,
    boxShadow: "0px 2px 2px 0px #00000026",
  },
  selectedButton: {
    padding: "8px 16px",
    color: "black",
    backgroundColor: "#F8F8F8",
    cursor: "pointer",
  },
  deselectedButton: {
    padding: "8px 16px",
    color: "#A9A9A9",
    border: "1px solid #F8F8F8",
    boxShadow: "0px 2px 2px 0px #00000026",
    cursor: "pointer",
  },
  timelineContainer: {
    width: "90%",
    height: 2,
    backgroundColor: "black",
    position: "relative",
  },
  timelineConnector: {
    height: 2,
    backgroundColor: "transparent",
    flexGrow: 1,
    borderBottom: "2px dotted #D0D5DD",
  },
  timelineItem: {
    position: "absolute",
    top: -10,
    display: "flex",
    justifyContent: "center",
  },
  timelineDot: {
    height: 22,
    display: "flex",
    alignItems: "center",
    "& div": {
      height: 10,
      width: 10,
      borderRadius: "50%",
      backgroundColor: "#98A2B3",
    },
  },
  timelineContent: {
    position: "absolute",
    bottom: 37,
    backgroundColor: "#3A4D54",
    borderRadius: 8,
    padding: "4px 12px",
  },
}));

export default function ParticipantInfoFormComponent({
  uuid,
  initialValues,
  setCurrentStep,
  lang,
  handleSubmitForm,
  isSubmitting,
  formRef,
  handlePrevious,
  prizeInfo,
  handleOnDragEnd,
  ldCurrentState,
  progress,
  type,
  options,
  isAddQuestionOpen,
  handleIsAddQuestionOpen,
}) {
  const classes = useStyles();
  const addQuestionButtonRef = useRef();

  const signin = {
    identifier: "login",
    title: getLang(lang, "label.LOGIN_LABEL"),
  };
  const submitForm = {
    identifier: "submit-form",
    title: getLang(lang, "label.SUBMIT_FORM"),
  };

  let isNewlyCreated =
    progress &&
    (progress === progressStatus.setPrize ||
      progress === progressStatus.setPeriod);
  let isPublished = progress && progress === progressStatus.published;
  let isEditable =
    !isPublished ||
    !ldCurrentState ||
    (ldCurrentState !== ldState.ended && ldCurrentState !== ldState.drawStart);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        signInRequired: isNewlyCreated
          ? 1
          : initialValues
          ? Number(initialValues?.signInRequired)
          : 1,
        submitFormRequired: isNewlyCreated
          ? 1
          : initialValues
          ? Number(initialValues?.submitFormRequired)
          : 1,
        signInEvent: isNewlyCreated ? "1" : initialValues?.signInEvent ?? "",
        submitFormEvent: isNewlyCreated
          ? "1"
          : initialValues?.submitFormEvent ?? "",
        showConfirmDialog: false,
        journey: [
          {
            identifier: "start",
            title: getLang(lang, "label.START"),
          },
          {
            identifier: "lucky-draw",
            title: getLang(
              lang,
              type === 2 ? "label.INSTANT_WIN" : "label.LUCKY_DRAW"
            ),
          },
          {
            identifier: "winning",
            title: getLang(lang, "label.WINNING"),
          },
          {
            identifier: "claim",
            title: getLang(lang, "label.CLAIM_PRIZE"),
          },
        ],
        formFields: initialValues?.formFields?.length
          ? initialValues.formFields.map((f, index) => ({
              ...f,
              id: `item-${Date.now()}-${index}`,
            }))
          : defaultParticipantFormFields.map((f, index) => ({
              ...f,
              id: `item-${Date.now()}-${index}`,
            })),
        isEditing: false,
      }}
      validationSchema={Yup.object({
        signInEvent: Yup.string().when("signInRequired", {
          is: (value) => !!value,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        submitFormEvent: Yup.string().when("submitFormRequired", {
          is: (value) => !!value,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          otherwise: Yup.string().nullable(),
        }),
        formFields: Yup.array().when("submitFormRequired", {
          is: (value) => !!value,
          then: Yup.array()
            .of(
              Yup.object().shape({
                variantName: Yup.string().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
              })
            )
            .min(1, getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          otherwise: Yup.array().nullable(),
        }),
      })}
      onSubmit={handleSubmitForm}
    >
      {(formik) => (
        <Box component={"form"} onSubmit={formik.handleSubmit} mt={2}>
          <Paper style={{ padding: "20px" }} elevation={0}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">
                <b>{getLang(lang, "label.CONSUMER_PARTICIPATE_JOURNEY")}</b>
              </Typography>
            </Box>
            <Box className={classes.greyCard} mt={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={3}
                pb={2}
                px={2}
                height={136}
                bgcolor="white"
              >
                <Box className={classes.timelineContainer}>
                  <Box position="absolute" right={-10} top={-11}>
                    <ChevronRightRoundedIcon style={{ color: "black" }} />
                  </Box>
                  {formik.values.journey.map((item, index) => (
                    <Box
                      key={index}
                      className={classes.timelineItem}
                      style={{
                        left: `${
                          (index / (formik.values.journey.length - 1)) * 90
                        }%`,
                      }}
                    >
                      {["login", "submit-form"].includes(item.identifier) ? (
                        <Box bgcolor="white" borderRadius="50%">
                          <CheckCircleRoundedIcon
                            color="secondary"
                            style={{ fontSize: 22 }}
                          />
                        </Box>
                      ) : (
                        <Box className={classes.timelineDot}>
                          <Box />
                        </Box>
                      )}
                      {["login", "submit-form"].includes(item.identifier) ? (
                        <Box className={classes.timelineContent}>
                          <Typography
                            variant="body2"
                            style={{ whiteSpace: "nowrap", color: "white" }}
                          >
                            {item.title}
                          </Typography>
                        </Box>
                      ) : (
                        <Box position="absolute" top={30}>
                          <Typography
                            variant="body2"
                            style={{ whiteSpace: "nowrap", color: "#98A2B3" }}
                          >
                            {item.title?.toUpperCase()}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.greyCard} mt={2}>
              <Typography variant="body2" className={classes.fieldLabel}>
                {getLang(lang, "label.IS_LOGIN_REQUIRED_CONSUMER")}
              </Typography>
              <FormControl>
                <Box style={{ display: "flex", gap: "10px", marginTop: 8 }}>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: !!formik.values.signInRequired
                        ? "#6AAF68"
                        : "#A0A0A080",
                      borderWidth: 2,
                      color: !!formik.values.signInRequired
                        ? "black"
                        : "#A0A0A0",
                    }}
                    className={classes.radioButton}
                    startIcon={
                      <Radio
                        size="small"
                        checked={!!formik.values.signInRequired}
                      />
                    }
                    onClick={() => {
                      if (formik.values.signInRequired !== 1) {
                        formik.setFieldValue("signInRequired", 1);

                        if (Number(formik.values.signInEvent)) {
                          let tempJourney = [...formik.values.journey];
                          if (Number(formik.values.signInEvent) === 1) {
                            // before joining lucky draw
                            let startIndex = tempJourney.findIndex(
                              (j) => j.identifier === "start"
                            );
                            if (startIndex >= 0) {
                              tempJourney.splice(startIndex + 1, 0, signin);
                            }
                          } else {
                            // before claim prize
                            let winningIndex = tempJourney.findIndex(
                              (j) => j.identifier === "winning"
                            );
                            if (winningIndex >= 0) {
                              tempJourney.splice(winningIndex + 1, 0, signin);
                            }
                          }
                          formik.setFieldValue("journey", tempJourney);
                        }
                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }
                    }}
                    disabled={!isEditable || isSubmitting}
                  >
                    {getLang(lang, "label.YES_ITS_REQUIRED")}
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: !formik.values.signInRequired
                        ? "#6AAF68"
                        : "#A0A0A080",
                      borderWidth: 2,
                      color: !formik.values.signInRequired
                        ? "black"
                        : "#A0A0A0",
                    }}
                    className={classes.radioButton}
                    startIcon={
                      <Radio
                        size="small"
                        checked={!formik.values.signInRequired}
                      />
                    }
                    onClick={() => {
                      formik.setFieldValue("signInRequired", 0);
                      let tempJourney = formik.values.journey.filter(
                        (j) => j.identifier !== "login"
                      );
                      formik.setFieldValue("journey", tempJourney);
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    disabled={!isEditable || isSubmitting}
                  >
                    {getLang(lang, "label.NO_ITS_NOT_REQUIRED")}
                  </Button>
                </Box>
              </FormControl>

              {!!formik.values.signInRequired && (
                <Box mt={3} display="flex" style={{ gap: 12 }}>
                  <Box mt={1.5}>
                    <Typography
                      variant="body2"
                      className={clsx(classes.required, classes.fieldLabel)}
                    >
                      {getLang(lang, "label.CONSUMER_NEED_LOGIN")}
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        {...formik.getFieldProps("signInEvent")}
                        name="signInEvent"
                        value={formik.values.signInEvent}
                        disabled={!isEditable || isSubmitting}
                        onChange={(e) => {
                          formik.setFieldValue("signInEvent", e.target.value);

                          let tempJourney = formik.values.journey.filter(
                            (j) => j.identifier !== "login"
                          );
                          if (Number(e.target.value) === 1) {
                            // before joining lucky draw
                            let startIndex = tempJourney.findIndex(
                              (j) => j.identifier === "start"
                            );
                            if (startIndex >= 0) {
                              tempJourney.splice(startIndex + 1, 0, signin);
                            }
                          } else {
                            // before claim prize
                            let winningIndex = tempJourney.findIndex(
                              (j) => j.identifier === "winning"
                            );
                            if (winningIndex >= 0) {
                              tempJourney.splice(winningIndex + 1, 0, signin);
                            }
                          }
                          formik.setFieldValue("journey", tempJourney);
                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }}
                        classes={{ root: classes.selectRoot }}
                        style={{
                          color: formik.values.signInEvent
                            ? "black"
                            : "#3A4D5480",
                        }}
                        placeholder={getLang(lang, "placeholder.SELECT_EVENT")}
                        displayEmpty
                        renderValue={
                          !!formik.values.signInEvent
                            ? undefined
                            : () => getLang(lang, "placeholder.SELECT_EVENT")
                        }
                        error={
                          formik.touched.signInEvent &&
                          formik.errors.signInEvent
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={"1"}>
                          {getLang(lang, "label.BEFOER_JOINING_LUCKY_DRAW")}
                        </MenuItem>
                        <MenuItem value={"2"}>
                          {getLang(lang, "label.BEFORE_CLAIMING_PRIZE")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText
                      error={formik.errors.signInEvent ? true : false}
                    >
                      {ErrorMessage({ name: "signInEvent" })}
                    </FormHelperText>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.greyCard} mt={2}>
              <Typography variant="body2" className={classes.fieldLabel}>
                {getLang(lang, "label.IS_SUBMIT_FORM_REQUIRED_CONSUMER")}
              </Typography>
              <FormControl>
                <Box style={{ display: "flex", gap: "10px", marginTop: 8 }}>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: !!formik.values.submitFormRequired
                        ? "#6AAF68"
                        : "#A0A0A080",
                      borderWidth: 2,
                      color: !!formik.values.submitFormRequired
                        ? "black"
                        : "#A0A0A0",
                    }}
                    className={classes.radioButton}
                    startIcon={
                      <Radio
                        size="small"
                        checked={!!formik.values.submitFormRequired}
                      />
                    }
                    onClick={() => {
                      if (formik.values.submitFormRequired !== 1) {
                        formik.setFieldValue("submitFormRequired", 1);

                        if (Number(formik.values.submitFormEvent)) {
                          let tempJourney = [...formik.values.journey];
                          if (Number(formik.values.submitFormEvent) === 1) {
                            // before joining lucky draw
                            let ldIndex = tempJourney.findIndex(
                              (j) => j.identifier === "lucky-draw"
                            );
                            if (ldIndex >= 0) {
                              tempJourney.splice(ldIndex, 0, submitForm);
                            }
                          } else {
                            // before claim prize
                            let claimIndex = tempJourney.findIndex(
                              (j) => j.identifier === "claim"
                            );
                            if (claimIndex >= 0) {
                              tempJourney.splice(claimIndex, 0, submitForm);
                            }
                          }
                          formik.setFieldValue("journey", tempJourney);
                        }
                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }
                    }}
                    disabled={!isEditable || isSubmitting}
                  >
                    {getLang(lang, "label.YES_ITS_REQUIRED")}
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: !formik.values.submitFormRequired
                        ? "#6AAF68"
                        : "#A0A0A080",
                      borderWidth: 2,
                      color: !formik.values.submitFormRequired
                        ? "black"
                        : "#A0A0A0",
                    }}
                    className={classes.radioButton}
                    startIcon={
                      <Radio
                        size="small"
                        checked={!formik.values.submitFormRequired}
                      />
                    }
                    onClick={() => {
                      formik.setFieldValue("submitFormRequired", 0);
                      let tempJourney = formik.values.journey.filter(
                        (j) => j.identifier !== "submit-form"
                      );
                      formik.setFieldValue("journey", tempJourney);
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    disabled={!isEditable || isSubmitting}
                  >
                    {getLang(lang, "label.NO_ITS_NOT_REQUIRED")}
                  </Button>
                </Box>
              </FormControl>

              {!!formik.values.submitFormRequired && (
                <Box mt={3} display="flex" style={{ gap: 12 }}>
                  <Box mt={1.5}>
                    <Typography
                      variant="body2"
                      className={clsx(classes.required, classes.fieldLabel)}
                    >
                      {getLang(lang, "label.CONSUMER_NEED_SUBMIT_FORM")}
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        {...formik.getFieldProps("submitFormEvent")}
                        name="submitFormEvent"
                        value={formik.values.submitFormEvent}
                        disabled={!isEditable || isSubmitting}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "submitFormEvent",
                            e.target.value
                          );

                          let tempJourney = formik.values.journey.filter(
                            (j) => j.identifier !== "submit-form"
                          );
                          if (Number(e.target.value) === 1) {
                            // before joining lucky draw
                            let ldIndex = tempJourney.findIndex(
                              (j) => j.identifier === "lucky-draw"
                            );
                            if (ldIndex >= 0) {
                              tempJourney.splice(ldIndex, 0, submitForm);
                            }
                          } else {
                            // before claim prize
                            let claimIndex = tempJourney.findIndex(
                              (j) => j.identifier === "claim"
                            );
                            if (claimIndex >= 0) {
                              tempJourney.splice(claimIndex, 0, submitForm);
                            }
                          }
                          formik.setFieldValue("journey", tempJourney);
                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }}
                        classes={{ root: classes.selectRoot }}
                        style={{
                          color: formik.values.submitFormEvent
                            ? "black"
                            : "#3A4D5480",
                        }}
                        placeholder={getLang(lang, "placeholder.SELECT_EVENT")}
                        displayEmpty
                        renderValue={
                          !!formik.values.submitFormEvent
                            ? undefined
                            : () => getLang(lang, "placeholder.SELECT_EVENT")
                        }
                        error={
                          formik.touched.submitFormEvent &&
                          formik.errors.submitFormEvent
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={"1"}>
                          {getLang(lang, "label.BEFOER_JOINING_LUCKY_DRAW")}
                        </MenuItem>
                        <MenuItem value={"2"}>
                          {getLang(lang, "label.BEFORE_CLAIMING_PRIZE")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText
                      error={formik.errors.submitFormEvent ? true : false}
                    >
                      {ErrorMessage({ name: "submitFormEvent" })}
                    </FormHelperText>
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>

          {!!formik.values.submitFormRequired && (
            <Box mt={2.5}>
              <Paper elevation={0}>
                <Box p={2}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">
                      <b>
                        {getLang(lang, "label.REGISTRATION_FORM_CONFIGURATION")}
                      </b>
                    </Typography>
                    <Box display="flex" mt={1}>
                      {/* previous form */}
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <DragDropContext
                      onDragEnd={({ source, destination }) =>
                        handleOnDragEnd(source, destination, formik)
                      }
                    >
                      <Box dispaly="flex" flexDirection="column">
                        <Droppable droppableId="formList">
                          {(provided) => (
                            <Box
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{ margin: "0 8px", minHeight: 10 }}
                            >
                              {formik.values.formFields.map((info, index) => {
                                return (
                                  <Draggable
                                    key={info.id}
                                    draggableId={info.id}
                                    index={index}
                                    isDragDisabled={!isEditable || isSubmitting}
                                  >
                                    {(provided) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        display="flex"
                                        alignItems="flex-start"
                                        minHeight={50}
                                        style={{
                                          userSelect: "none",
                                          paddingBottom: 16,
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <DraggableParticipantInfoCard
                                          key={index}
                                          index={index}
                                          lang={lang}
                                          updateInfo={(val) => {
                                            formik.setFieldValue(
                                              "formFields",
                                              val
                                            );
                                            if (!formik.values.isEditing) {
                                              formik.setFieldValue(
                                                "isEditing",
                                                true
                                              );
                                            }
                                          }}
                                          info={info}
                                          infoList={formik.values.formFields}
                                          formik={formik}
                                          disabled={!isEditable || isSubmitting}
                                        />
                                      </Box>
                                    )}
                                  </Draggable>
                                );
                              })}

                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </Box>
                    </DragDropContext>
                  </Box>
                  {isEditable && (
                    <Box
                      mb={2.5}
                      px={4}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <AddQuestionPopper
                        isOpen={isAddQuestionOpen}
                        anchorRef={addQuestionButtonRef}
                        options={options.filter(
                          (option) =>
                            !formik.values.formFields.some(
                              (field) => field.fieldName === option.fieldName
                            )
                        )}
                        handleClick={(item) => {
                          if (item.fieldName === "customInputFields") {
                            let formFields = [...formik.values.formFields];

                            let textFieldObj = participantFieldTypeChoice.find(
                              (f) => f.value === "textField"
                            );
                            if (textFieldObj) {
                              let idx = participantFieldTypeChoice.findIndex(
                                (f) => f.value === "textField"
                              );
                              formFields.push({
                                id: `item-${Date.now()}`,
                                fieldName: `custom_${idx}`,
                                type: textFieldObj.value,
                                labelType: textFieldObj.labelType,
                                fieldType: textFieldObj.fieldType,
                                mandatory: true,
                                visible: true,
                                enableDelete: true,
                                editing: false,
                                variantName: "",
                                choice: [],
                              });

                              formik.setFieldValue("formFields", formFields);
                            }
                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          } else {
                            let formFields = [...formik.values.formFields];

                            const fieldType = {
                              contactNumber: "contactNumber",
                              address: "address",
                              gender: "multipleChoice",
                              proofOfPurchase: "uploadImage",
                              default: "textField",
                            };

                            const labelType = {
                              contactNumber: "label.CONTACT_NUMBER",
                              address: "label.ADDRESS_PCIKER",
                              gender: "label.RADIO_BUTTONS_SINGLE_SELECTION",
                              proofOfPurchase: "label.IMAGE_UPLOAD",
                              default: "label.TEXT_FIELD",
                            };

                            formFields.push({
                              id: `item-${Date.now()}`,
                              fieldName: item.fieldName,
                              type:
                                fieldType[item.fieldName] || fieldType.default,
                              labelType:
                                labelType[item.fieldName] || labelType.default,
                              fieldType: 1,
                              mandatory: true,
                              visible: true,
                              enableDelete: true,
                              editing: false,
                              variantName: item.variantName,
                              choice:
                                item.fieldName === "gender"
                                  ? ["Male", "Female", "Others"]
                                  : [],
                            });

                            formik.setFieldValue("formFields", formFields);

                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          }
                          handleIsAddQuestionOpen(false);
                        }}
                        handleClose={() => handleIsAddQuestionOpen(false)}
                      />
                      <Button
                        variant="text"
                        color="secondary"
                        startIcon={<AddIconGreen style={{ fontSize: 14 }} />}
                        ref={addQuestionButtonRef}
                        onClick={() => {
                          handleIsAddQuestionOpen(true);
                        }}
                        disabled={isSubmitting}
                      >
                        {getLang(lang, "label.ADD_QUESTION")}
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#ff1515",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          if (!isSubmitting) {
                            formik.setFieldValue("showConfirmDialog", true);
                          }
                        }}
                        disabled={isSubmitting}
                      >
                        <Typography
                          variant="body2"
                          style={{ color: "white", whiteSpace: "nowrap" }}
                        >
                          <b>{getLang(lang, "label.CLEAR_ALL")}</b>
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  <FormHelperText
                    error={
                      typeof formik.errors.formFields === "string" &&
                      formik.touched.formFields &&
                      formik.errors.formFields
                    }
                    style={{ marginLeft: 10 }}
                  >
                    {typeof formik.errors.formFields === "string"
                      ? ErrorMessage({ name: "formFields" })
                      : ""}
                  </FormHelperText>
                </Box>
              </Paper>
            </Box>
          )}

          <Box display="flex" alignItems="center" mt={2.5}>
            <Box mr={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  let initialPrizeList = prizeInfo
                    ? JSON.parse(JSON.stringify(prizeInfo))
                    : [];
                  let prizeList =
                    type === 1
                      ? initialPrizeList
                      : initialPrizeList?.filter((v) => !v.isDefault);
                  const hasPrize =
                    type === 1
                      ? prizeList.filter((i) => !!i.length).length
                      : prizeList.length;
                  handlePrevious(hasPrize);
                }}
                disabled={isSubmitting}
              >
                {getLang(lang, "label.PREVIOUS")}
              </Button>
            </Box>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#6AAF68" }}
              type="submit"
              disabled={isSubmitting}
              startIcon={
                !!isSubmitting ? (
                  <CircularProgress
                    style={{ width: 15, height: 15, color: "white" }}
                  />
                ) : null
              }
            >
              {getLang(lang, "label.NEXT")}
            </Button>
          </Box>
          <GeneralDialog
            isOpen={formik.values.showConfirmDialog}
            handleClose={() => formik.setFieldValue("showConfirmDialog", false)}
            handleProceed={() => {
              formik.setFieldValue("showConfirmDialog", false);
              formik.setFieldValue(
                "formFields",
                initialValues?.formFields?.length
                  ? initialValues.formFields.map((f, index) => ({
                      ...f,
                      id: `item-${Date.now()}-${index}`,
                    }))
                  : defaultParticipantFormFields.map((f, index) => ({
                      ...f,
                      id: `item-${Date.now()}-${index}`,
                    }))
              );
              if (!formik.values.isEditing) {
                formik.setFieldValue("isEditing", true);
              }
            }}
            title={getLang(lang, "label.CONFIRMATION")}
            description={getLang(lang, "paragraph.CLEAR_FORM_QUESTION")}
            type="danger"
            icon={<img src={AlertIcon} alt="alert" />}
          />
        </Box>
      )}
    </Formik>
  );
}
