import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Divider,
  Link,
} from "@material-ui/core";
import InvoiceImg from "assets/img/invoice.png";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
//import DefaultImg from "assets/img/img-default.png"
import DefaultProfile from "assets/img/defaultPhoto.png";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";
import DefaultImg from "assets/img/defaultPhoto.png";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "77px",
    height: "77px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  greyText: {
    color: "#98A2B3",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    columnGap: theme.spacing(2),
  },
  activeText: {
    color: "#32D583",
  },
  highlightBox: {
    padding: "2px 10px",
    backgroundColor: "#98A2B3",
    color: "#FFFFFF",
    borderRadius: "11px",
    marginRight: "8px",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  employeeIcon: {
    height: 25,
    width: 25,
    borderRadius: "50%",
    margin: "0 5px",
  },
}));

export default function BoxInfoCardComponent({
  hasStockflowDealerViewAccessRight,
  box,
  language,
  isFetching,
  lang,
  isInvoice,
}) {
  const classes = useStyles();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <Paper elevation={0} className={classes.container}>
      {!isFetching && box ? (
        <Box>
          <Box display="flex" mb={2} alignItems="center">
            <Box
              className={classes.productPhoto}
              alignItems="center"
              justifyContent="center"
              mr={2}
            >
              <img src={InvoiceImg} alt="emptyProduct" />
            </Box>
            <Box>
              <Box>
                <Typography variant="h5" style={{ fontWeight: "700" }}>
                  {box.code}
                </Typography>
              </Box>
              {!!isInvoice && !!box.client_name && (
                <Typography variant="body2" style={{ color: "#959595" }}>
                  {box.client_name}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <Box className={classes.field}>
              <Typography variant="body1">
                {getLang(lang, "label.BRANCH_ASSIGNED")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span>{box.node?.name || "-"}</span>
              </Typography>
            </Box>
            {isInvoice && (
              <Box className={classes.field} mt={2}>
                <Typography variant="body1">
                  {getLang(lang, "label.CREATED_BY")}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" color="textSecondary">
                    <span>: </span>
                  </Typography>
                  {box.created_by ? (
                    <Box display="flex" alignItems="center">
                      <img
                        src={box.created_by?.picture || DefaultImg}
                        alt="employee"
                        className={classes.employeeIcon}
                      />
                      <Typography variant="body1" color="textSecondary">
                        <span>{box.created_by?.name || "-"}</span>
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ marginLeft: 4 }}
                    >
                      <span>-</span>
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            {!isInvoice && (
              <Box className={classes.field} mt={2}>
                <Typography variant="body1">
                  {getLang(lang, "label.LOCATED_AT")}
                </Typography>
                <Box display="flex">
                  <Typography color="textSecondary" style={{ marginRight: 5 }}>
                    :
                  </Typography>
                  <span>
                    {!box.located_at ? (
                      <span>-</span>
                    ) : (
                      <Box display="flex" alignItems="center">
                        <Box
                          className={classes.profile}
                          mr={1}
                          ml={0.5}
                          position="relative"
                        >
                          {box.located_at.picture === "" ||
                          !box.located_at.picture ? (
                            // {box.located_at.picture === "" ? (
                            //<img src={box.located_at.type === "branch" ? DefaultImg : DefaultProfile} alt="default profile" />
                            <img src={DefaultProfile} alt="default profile" />
                          ) : (
                            <img
                              src={box.located_at.picture}
                              alt={box.located_at.name}
                            />
                          )}

                          {!!box.located_at.tier && (
                            <Box
                              className={classes.tierIcon}
                              style={{
                                backgroundColor: TIER_ICON_PROPS.find(
                                  ({ tier }) => tier === box.located_at.tier
                                ).color,
                              }}
                            >
                              <Typography className={classes.tierLabel}>
                                T{box.located_at.tier}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {box.located_at.type === "branch" ||
                        !hasStockflowDealerViewAccessRight ? (
                          <Typography variant="body1">-</Typography>
                        ) : (
                          <Link
                            href={
                              box.located_at.type === "dealer"
                                ? `${ROOT}/my-dealer/${box.located_at.uuid}`
                                : "#"
                            }
                            color="secondary"
                            onClick={(e) => {
                              if (box.located_at.type === "dealer") {
                                history.push(
                                  `${ROOT}/my-dealer/${box.located_at.uuid}`
                                );
                                e.preventDefault();
                                return false;
                              }
                            }}
                          >
                            <Typography variant="body1">
                              {box.located_at.name}
                            </Typography>
                          </Link>
                        )}
                      </Box>
                    )}
                  </span>
                </Box>
              </Box>
            )}

            <Box className={classes.field} mt={2}>
              <Typography variant="body1">
                {getLang(lang, "label.CREATED_DATE")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span>{moment.utc(box.created_at).local().format("lll")}</span>
              </Typography>
            </Box>
            <Box className={classes.field} mt={2}>
              <Typography variant="body1">
                {getLang(lang, "label.LAST_UPDATE")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span>{moment.utc(box.updated_at).local().format("lll")}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box display="flex" flex={1} mb={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={1}
            >
              <Skeleton variant="rect" width={77} height={77} />
            </Box>
            <Box>
              <Skeleton variant="text" height={50} width={100} />
            </Box>
          </Box>
          <Divider />
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={40} mt={2} />
        </Box>
      )}
    </Paper>
  );
}
