import React, {useState} from "react";
import { useSelector } from "react-redux";
import WithProductGeneralSearchComponent from "./withProductGeneralSearch.component";

export default function WithProductGeneralSearchContainer({
  handleFilter,
  isFetching,
  isFetchingProduct,
  productDropdown,
}) {
  const handleSearch = async (searchString) => {
    await handleFilter({
      searchString: searchString,
      product: productsSelected,
    });
  };

  const handleClear = async () => {
    setProductsSelected([]);
    await handleFilter({
      searchString: "",
      product: [],
    });
  }

  const lang = useSelector(state => state.constant.languages)
  const [productsSelected, setProductsSelected] = useState([]);

  return <WithProductGeneralSearchComponent
    handleSearch={handleSearch}
    lang={lang}
    isFetching={isFetching}
    isFetchingProduct={isFetchingProduct}
    productDropdown={productDropdown}
    productsSelected={productsSelected}
    setProductsSelected={setProductsSelected}
    handleClear={handleClear}
  />
}
