/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PreviewFormComponent from "./previewForm.component";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import { useHistory } from "react-router-dom";
import { getLang } from "app/feature/constants";

export default function PreviewFormContainer({
  uuid,
  initialValues,
  setCurrentStep,
  currentStep,
  type,
  isLoading,
  ldCurrentState,
}) {
  const history = useHistory();
  const timezones = useSelector((state) => state.constant.timezones);
  const company_timezone = useSelector(
    (state) => state.session.company.timezone
  );
  const timezone = timezones.find((tz) => tz.id === company_timezone);
  const lang = useSelector((state) => state.constant.languages);
  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );
  const [disbursements, setDisbursements] = useState([]);

  useEffect(() => {
    setDisbursements(disbursementInfos);
  }, [disbursementInfos]);

  const initialJourney = [
    {
      identifier: "start",
      title: getLang(lang, "label.START"),
    },
    {
      identifier: "lucky-draw",
      title: getLang(
        lang,
        type === 2 ? "label.INSTANT_WIN" : "label.LUCKY_DRAW"
      ),
    },
    {
      identifier: "winning",
      title: getLang(lang, "label.WINNING"),
    },
    {
      identifier: "claim",
      title: getLang(lang, "label.CLAIM_PRIZE"),
    },
  ];

  const [journey, setJourney] = useState(initialJourney);

  const handleSubmit = () => {
    history.push(ROOT);
  };

  const handlePrevious = () => {
    const params = new URLSearchParams(window.location.search);
    params.set("step", currentStep);
    window.history.replaceState(null, null, `?${params}`);
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    let tempJourney = [...initialJourney];
    if (initialValues?.participantInfo.signInRequired) {
      if (Number(initialValues?.participantInfo.signInEvent) === 1) {
        // before joining lucky draw
        let startIndex = tempJourney.findIndex((j) => j.identifier === "start");
        if (startIndex >= 0) {
          tempJourney.splice(startIndex + 1, 0, {
            identifier: "login",
            title: getLang(lang, "label.LOGIN_LABEL"),
          });
        }
      } else {
        // before claim prize
        let winningIndex = tempJourney.findIndex(
          (j) => j.identifier === "winning"
        );
        if (winningIndex >= 0) {
          tempJourney.splice(winningIndex + 1, 0, {
            identifier: "login",
            title: getLang(lang, "label.LOGIN_LABEL"),
          });
        }
      }
    }

    if (initialValues?.participantInfo.submitFormRequired) {
      if (Number(initialValues?.participantInfo.submitFormEvent) === 1) {
        // before joining lucky draw
        let ldIndex = tempJourney.findIndex(
          (j) => j.identifier === "lucky-draw"
        );
        if (ldIndex >= 0) {
          tempJourney.splice(ldIndex, 0, {
            identifier: "submit-form",
            title: getLang(lang, "label.SUBMIT_FORM"),
          });
        }
      } else {
        // before claim prize
        let claimIndex = tempJourney.findIndex((j) => j.identifier === "claim");
        if (claimIndex >= 0) {
          tempJourney.splice(claimIndex, 0, {
            identifier: "submit-form",
            title: getLang(lang, "label.SUBMIT_FORM"),
          });
        }
      }
    }

    setJourney(tempJourney);
  }, [initialValues]);

  return (
    <PreviewFormComponent
      uuid={uuid}
      initialValues={initialValues}
      timezone={timezone.value}
      setCurrentStep={setCurrentStep}
      lang={lang}
      handleSubmitForm={handleSubmit}
      handlePrevious={handlePrevious}
      type={type}
      disbursementInfos={disbursements}
      journey={journey}
      isLoading={isLoading}
      ldCurrentState={ldCurrentState}
    />
  );
}
