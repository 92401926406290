import React from 'react';

function PrizeImgInputComponent({
  handleImgChange,
  children,
}) {

  return (
    <>{children(handleImgChange)}</>
  );
}

export default PrizeImgInputComponent;
