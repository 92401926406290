/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import UnassignDetailDialogComponent from "./unassignDetailDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";
import {
  getUnassignDetail,
  getUnassignSnListByProduct,
  resetUnasisgnSnList,
  resetUnassignDetail,
} from "modules/serial-number/redux";

function UnassignDetailDialogContainer({ isOpen, handleClose, unassign }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  const isFetching = useSelector((state) =>
    selectLoading(state, getUnassignSnListByProduct.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.assignBatch.unassignSnList.totalFiltered
  );
  const list = useSelector((state) => state.assignBatch.unassignSnList.data);
  const isError = useSelector(
    (state) => state.assignBatch.unassignSnList.isError
  );

  const unassignDetail = useSelector(
    (state) => state.assignBatch.unassignDetail.data
  );
  const isDetailError = useSelector(
    (state) => state.assignBatch.unassignDetail.isError
  );
  const isDetailFetching = useSelector((state) =>
    selectLoading(state, getUnassignDetail.typePrefix)
  );

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "asc",
    orderBy: "sequence_number", // sequence_number|code
    search: "",
    status: "",
  });

  useEffect(() => {
    if (unassign) {
      dispatch(getUnassignDetail(unassign.id));
    } else {
      dispatch(resetUnassignDetail());
    }
  }, [unassign]);

  useEffect(() => {
    return () => {
      dispatch(resetUnasisgnSnList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedProduct) {
      handleDataLoad();
    } else {
      dispatch(resetUnasisgnSnList());
      setPaginationOptions([]);
    }
  }, [selectedProduct, tableFilter]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedProduct(null);
      setTableFilter({
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "sequence_number", // sequence_number|code
        search: "",
        status: "",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      id: unassign.id,
      product_uuid: selectedProduct.uuid,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      search: tableFilter.search,
      status: tableFilter.status.toLowerCase(),
    };
    dispatch(getUnassignSnListByProduct(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setTableFilter(newData);
  };

  const handlePrdReload = () => {
    let newData = {
      ...tableFilter,
      page: 0,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    const newFilter = {
      ...tableFilter,
      page: 0,
      status: values ? values.status : "",
      search: values ? values.search : "",
    };
    setTableFilter(newFilter);
  };

  const viewProduct = (prd) => {
    if (!prd) {
      setTableFilter({
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "sequence_number", // sequence_number|code
        search: "",
        status: "",
      });
    }
    setSelectedProduct(prd);
  };
  return (
    <UnassignDetailDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      unassign={unassignDetail}
      list={list}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleDataLoad}
      viewProduct={viewProduct}
      selectedProduct={selectedProduct}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      changeOrder={changeOrder}
      handlePrdReload={handlePrdReload}
      paginationOptions={paginationOptions}
      totalFiltered={totalFiltered}
      tableFilter={tableFilter}
      handleSearch={handleSearch}
      isDetailFetching={isDetailFetching}
      isDetailError={isDetailError}
    />
  );
}

export default UnassignDetailDialogContainer;
