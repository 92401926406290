import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider,
  Button,
} from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { AddIcon } from "components/icon";
import RequestListing from "../request-transfer-listing";
import { getLang } from "app/feature/constants";
import { useHistory } from "react-router";
import { REQUEST_TRANSFER_CREATE_PAGE } from "modules/stockflow/config/stockflow.route";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
});

const useStyles = makeStyles(style);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

export default function MainPageComponent({
  handleChange,
  activeIndex,
  tabLabels,
  lang,
  hasStockflowEditAccessRight,
}) {
  const classes = useStyles();
  const history = useHistory();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              Trackco
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.REQUEST_TRANSFER")}
              </Typography>
            </Box>
          </Box>
          {hasStockflowEditAccessRight ? (
            <Button
              variant="text"
              color="primary"
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              disableElevation
              onClick={() => {
                history.push(REQUEST_TRANSFER_CREATE_PAGE);
              }}
            >
              {getLang(lang, "label.REQUEST_TRANSFER")}
            </Button>
          ) : null}
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>

          <Box>
            {activeIndex !== null &&
              tabLabels.map((item, index) => {
                let type = "Send";

                if (item === getLang(lang, "label.TRANSFER_REQUEST")) {
                  type = "Receive";
                }

                return (
                  <TabPanel value={activeIndex} index={index} key={index}>
                    <Box className={classes.panel}>
                      <RequestListing type={type} />
                    </Box>
                  </TabPanel>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
