import React from "react";
import { makeStyles, Box, Typography, Link, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Skeleton } from "@material-ui/lab";
import { ROOT as SURVEY_ROOT } from "modules/survey";
import { ROOT as WARRANTY_ROOT } from "modules/warranty";
import { ROOT as LUCK_DRAW_ROOT } from "modules/lucky-draw";
import { ROOT as INSTANT_LUCKY_DRAW_ROOT } from "modules/instant-lucky-draw";
import { ROOT as LUCKY_RDAW_V2_ROOT } from "modules/lucky-draw-v2";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
    width: 20,
    minWidth: 20,
  },
  sequenceIndicator: {
    boxShadow: "none",
    color: "#ffffff",
    backgroundColor: "#35CA91",
    borderRadius: "50%",
    height: 13,
    width: 13,
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: 25,
    borderLeft: "2px dotted #D0D5DD",
  },
  list: {
    paddingInlineStart: "0px",
    marginTop: 0,
    display: "flex",
    flexDirection: "column-reverse",
  },
  dot: {
    color: "#D0D5DD",
    lineHeight: "13px",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    columnGap: theme.spacing(2),
    marginBottom: 4,
  },
  greenText: {
    color: "#32D583",
  },
  errorText: {
    color: "#F97066",
  },
}));

export default function ScanTrackDetailTimelineComponent({
  detailList,
  isLoading,
  lang,
  language,
}) {
  const classes = useStyle();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const SCAN_QR = "scan";
  const LOYALTY_POINT = "loyalty";
  const WARRANTY = "warranty";
  const SURVEY = "survey";
  const LUCKY_DRAW = "lucky draw";
  const INSTANT_LUCKY_DRAW = "instant lucky draw";
  const INSTANT_WIN = "instant win";
  const LUCKY_DRAW_V2 = "traditional raffle";

  return (
    <Box display="flex" flexDirection="column">
      {isLoading ? (
        <Box px={1}>
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </Box>
      ) : (
        <ul className={classes.list}>
          {detailList.map((item, i) => (
            <li key={i} className={classes.item}>
              <Box className={classes.seperator}>
                <Box className={classes.sequenceIndicator} />
                {detailList.length > 1 && !!i && (
                  <>
                    <Box className={classes.connector} />
                    <Box mt={-1} mb={-2}>
                      <ArrowDropUpIcon
                        style={{ color: "#D0D5DD", fontSize: 20 }}
                      />
                    </Box>
                    <Box className={classes.connector} />
                  </>
                )}
              </Box>
              <Box pl={1} flex={1} mb={2}>
                <Typography variant="body1">
                  <b>{getLang(lang, item.title)}</b>
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginBottom: 10 }}
                >
                  {moment(item.date).format("lll")}
                </Typography>
                {[
                  WARRANTY,
                  SURVEY,
                  INSTANT_LUCKY_DRAW,
                  LUCKY_DRAW,
                  LUCKY_DRAW_V2,
                  INSTANT_WIN,
                ].includes(item.type) && (
                  <Box className={classes.field}>
                    <Typography style={{ textTransform: "capitalize" }}>
                      {getLang(
                        lang,
                        `label.${item.type.replace(/ /g, "_").toUpperCase()}`
                      )}
                    </Typography>
                    <Box display="flex">
                      <Typography
                        color="textSecondary"
                        style={{ marginRight: 5 }}
                      >
                        :
                      </Typography>
                      <Typography color="textSecondary">
                        <Link
                          href={
                            item.type === SURVEY
                              ? `${SURVEY_ROOT}/view/${item.detail.uuid}`
                              : item.type === WARRANTY
                              ? `${WARRANTY_ROOT}/View/${item.detail.uuid}`
                              : item.type === LUCKY_DRAW
                              ? `${LUCK_DRAW_ROOT}/View/${item.detail.uuid}`
                              : item.type === INSTANT_LUCKY_DRAW
                              ? `${INSTANT_LUCKY_DRAW_ROOT}/View/${item.detail.uuid}`
                              : item.type === LUCKY_DRAW_V2 ||
                                item.type === INSTANT_WIN
                              ? `${LUCKY_RDAW_V2_ROOT}/${item.detail.uuid}/participants`
                              : "#"
                          }
                          color="secondary"
                          onClick={(e) => {
                            if (item.type === SURVEY) {
                              history.push(
                                `${SURVEY_ROOT}/view/${item.detail.uuid}`
                              );
                            }
                            if (item.type === WARRANTY) {
                              history.push(
                                `${WARRANTY_ROOT}/View/${item.detail.uuid}`
                              );
                            }
                            if (item.type === LUCKY_DRAW) {
                              history.push(
                                `${LUCK_DRAW_ROOT}/View/${item.detail.uuid}`
                              );
                            }
                            if (item.type === INSTANT_LUCKY_DRAW) {
                              history.push(
                                `${INSTANT_LUCKY_DRAW_ROOT}/View/${item.detail.uuid}`
                              );
                            }
                            if (
                              item.type === LUCKY_DRAW_V2 ||
                              item.type === INSTANT_WIN
                            ) {
                              history.push(
                                `${LUCKY_RDAW_V2_ROOT}/${item.detail.uuid}/participants`
                              );
                            }
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{ textAlign: "left" }}
                          >
                            {item.detail.name}
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {item.type === LOYALTY_POINT && (
                  <Box className={classes.field}>
                    <Typography style={{ textTransform: "capitalize" }}>
                      {getLang(lang, "label.LOYALTY_AMOUNT")}
                    </Typography>
                    <Box display="flex">
                      <Typography
                        color="textSecondary"
                        style={{ marginRight: 5 }}
                      >
                        :
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.greenText}
                        style={{ textAlign: "left" }}
                      >
                        +{item.detail?.amount || 0}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {item.type === SCAN_QR && (
                  <>
                    <Box className={classes.field}>
                      <Typography style={{ textTransform: "capitalize" }}>
                        {getLang(lang, "label.SCAN_COUNT")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          color="textSecondary"
                          style={{ marginRight: 5 }}
                        >
                          :
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ textAlign: "left" }}
                        >
                          {item.scan_count.toLocaleString("en-US")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.field}>
                      <Typography style={{ textTransform: "capitalize" }}>
                        {getLang(lang, "label.SCAN_LIMIT")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          color="textSecondary"
                          style={{ marginRight: 5 }}
                        >
                          :
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ textAlign: "left" }}
                        >
                          {item.scan_limit.toLocaleString("en-US")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.field}>
                      <Typography style={{ textTransform: "capitalize" }}>
                        {getLang(lang, "label.STATUS")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          color="textSecondary"
                          style={{ marginRight: 5 }}
                        >
                          :
                        </Typography>
                        {item.status === "active" ? (
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ textAlign: "left" }}
                          >
                            {getLang(lang, "label.GENUINE")}
                          </Typography>
                        ) : item.status === "replace" ? (
                          <Typography
                            variant="body1"
                            style={{ textAlign: "left", color: "#98A2B3" }}
                          >
                            {getLang(lang, "label.REPLACED")}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            className={classes.errorText}
                            style={{ textAlign: "left" }}
                          >
                            {getLang(lang, "label.SUSPENDED")}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.field}>
                      <Typography>{getLang(lang, "label.LOCATION")}</Typography>
                      <Box display="flex">
                        <Typography
                          color="textSecondary"
                          style={{ marginRight: 5 }}
                        >
                          :
                        </Typography>
                        <Box>
                          <Typography color="textSecondary">
                            {item.location?.country ? (
                              `${item.location.city}, ${item.location.state}, ${item.location.country}`
                            ) : (
                              <span>{getLang(lang, "label.UNKNOWN")}</span>
                            )}
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            style={{ color: "#6AAF68" }}
                          >
                            ({item.location.latitude}°
                            {item.location.latitude < 0 ? "S" : "N"},{" "}
                            {item.location.longitude}°
                            {item.location.longitude < 0 ? "W" : "E"})
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                {i !== 0 && (
                  <Box mt={2}>
                    <Divider />
                  </Box>
                )}
              </Box>
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
}
