import React from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AddImage, ErrorSVG } from "modules/lucky-draw/utils/static";
import { ErrorMessage } from "formik";
import { getLang } from "app/feature/constants";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  Add as AddIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DragIndicator as DragIndicatorIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  ExpandLessRounded as ExpandLessRoundedIcon,
} from "@material-ui/icons";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import PrizeImgInput from '../../prize-img-input';

const useStyles = makeStyles((theme) => ({
  required: {
    "&::after": {
      content: "' *'",
      color: "#E21B1B",
    },
  },
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  selectRoot: {
    padding: 11,
  },
  input: {
    "&::placeholder": {
      color: "#3A4D5480",
    },
  },
  prizeTitle: {
    background: "#F7F7F7",
    borderRadius: "4px",
    padding: "5px 10px",
    marginLeft: "56px",
    marginRight: "56px",
    fontWeight: 600,
  },
  prizeImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    borderRadius: "4px",
    display: "block",
    cursor: "pointer",
  },
  smallPrizeImage: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    borderRadius: "4px",
    display: "block",
    cursor: "pointer",
  },
}));

export default function TraditionalRafflePrizePanelComponent({
  formik,
  lang,
  isSubmitting,
  ldCurrentState,
  isPublished,
}) {
  const classes = useStyles();

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense",
  };

  const prizeTitles = [
    getLang(lang, "label.1ST_PRIZE"),
    getLang(lang, "label.2ND_PRIZE"),
    getLang(lang, "label.3RD_PRIZE"),
    getLang(lang, "label.4TH_PRIZE"),
    getLang(lang, "label.5TH_PRIZE"),
    getLang(lang, "label.UNRANKED_PRIZE"),
  ];

  let isEditable =
    !isPublished ||
    !ldCurrentState ||
    (ldCurrentState !== ldState.ended && ldCurrentState !== ldState.drawStart);
  // let isEnded =
  //   isPublished && ldCurrentState && ldCurrentState === ldState.ended;
  // let isDrawing =
  //   isPublished && ldCurrentState && ldCurrentState === ldState.drawStart;

  return (
    <Box>
      {formik.values.prizes.map((prize, index) => (
        <Box key={index}>
          <Box className={classes.prizeTitle}>{prizeTitles[index]}</Box>
          <Droppable droppableId={`${index}`}>
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ margin: "0 8px", minHeight: 10 }}
              >
                {prize.map((item, itemIndex) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={itemIndex}
                    isDragDisabled={!isEditable || isSubmitting}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          display: "flex",
                          alignItems: "start",
                          userSelect: "none",
                          padding: "16px 0",
                          minHeight: "50px",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Box p={1.5}>
                          <DragIndicatorIcon style={{ color: "#3A4D54" }} />
                        </Box>
                        <Box
                          p={2.5}
                          borderRadius={4}
                          flex={1}
                          border="1px solid #DBDBDB"
                        >
                          <Box
                            display="flex"
                            alignItems={
                              item.collapsed ? "center" : "flex-start"
                            }
                            style={{
                              gap: "20px",
                            }}
                          >
                            {!Boolean(item.collapsed) ? (
                              <Box>
                                {Boolean(item.imgUrl) ? (
                                  <img
                                    src={
                                      typeof item.imgUrl === "string"
                                        ? item.imgUrl
                                        : URL.createObjectURL(item.imgUrl)
                                    }
                                    alt="prize"
                                    className={classes.prizeImage}
                                    onClick={() => {
                                      const input = document.getElementById(
                                        `input_${item.id}`
                                      );
                                      input.click();
                                    }}
                                  />
                                ) : (
                                  <Box
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: "#F7F7F7",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const input = document.getElementById(
                                        `input_${item.id}`
                                      );
                                      input.click();
                                    }}
                                  >
                                    <AddImage />
                                  </Box>
                                )}
                                <PrizeImgInput
                                  lang={lang}
                                  handleOnResult={(file) => {
                                    const newPrizes = Array.from(formik.values.prizes);
                                    newPrizes[index][itemIndex].imgUrl = file;
                                    formik.setFieldValue("prizes", newPrizes);
                                    if (!formik.values.isEditing) {
                                      formik.setFieldValue("isEditing", true);
                                    }
                                  }}
                                >
                                  {(handleImgChange) => (
                                    <input
                                      id={`input_${item.id}`}
                                      type="file"
                                      name="imgUrl"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={handleImgChange}
                                      disabled={!isEditable || isSubmitting}
                                    />
                                  )}
                                </PrizeImgInput>
                                <FormHelperText
                                  error={
                                    formik.touched.prizes?.[index]?.[itemIndex]
                                      ?.imgUrl &&
                                    formik.errors.prizes?.[index]?.[itemIndex]
                                      ?.imgUrl
                                  }
                                >
                                  {ErrorMessage({
                                    name: `prizes[${index}][${itemIndex}].imgUrl`,
                                  })}
                                </FormHelperText>
                              </Box>
                            ) : (
                              <Box>
                                {Boolean(item.imgUrl) ? (
                                  <img
                                    src={
                                      typeof item.imgUrl === "string"
                                        ? item.imgUrl
                                        : URL.createObjectURL(item.imgUrl)
                                    }
                                    alt="prize"
                                    className={classes.smallPrizeImage}
                                  />
                                ) : (
                                  <Box
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: "#F7F7F7",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <AddImage />
                                  </Box>
                                )}
                              </Box>
                            )}
                            {!Boolean(item.collapsed) ? (
                              <Box style={{ flex: 1 }}>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    className={classes.fieldLabel}
                                  >
                                    {getLang(lang, "label.PRIZE_NAME")}
                                  </Typography>
                                  <TextField
                                    {...textFieldProps}
                                    {...formik.getFieldProps(
                                      `prizes[${index}][${itemIndex}].title`
                                    )}
                                    fullWidth
                                    variant="outlined"
                                    name="title"
                                    value={item.title}
                                    onChange={(e) => {
                                      const newPrizes = Array.from(
                                        formik.values.prizes
                                      );
                                      newPrizes[index][itemIndex].title =
                                        e.target.value;
                                      formik.setFieldValue("prizes", newPrizes);
                                      if (!formik.values.isEditing) {
                                        formik.setFieldValue("isEditing", true);
                                      }
                                    }}
                                    error={
                                      formik.touched.prizes?.[index]?.[
                                        itemIndex
                                      ]?.title &&
                                      formik.errors.prizes?.[index]?.[itemIndex]
                                        ?.title
                                    }
                                    helperText={ErrorMessage({
                                      name: `prizes[${index}][${itemIndex}].title`,
                                    })}
                                    disabled={!isEditable || isSubmitting}
                                    inputProps={{
                                      style: { color: "black" },
                                    }}
                                  />
                                </Box>
                                <Box
                                  mt={1}
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    marginTop: "10px",
                                  }}
                                >
                                  {/* <Box style={{ flex: 1 }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.fieldLabel}
                                      style={{ marginBottom: 8 }}
                                    >
                                      {getLang(
                                        lang,
                                        "label.PRIZE_TYPE"
                                      )}
                                    </Typography>
                                    <FormControl
                                      fullWidth
                                      variant="outlined"
                                    >
                                      <Select
                                        name="prizeType"
                                        value={item.prizeType}
                                        onChange={(e) => {
                                          const newPrizes =
                                            Array.from(
                                              formik.values.prizes
                                            );
                                          newPrizes[index][
                                            itemIndex
                                          ].prizeType =
                                            e.target.value;
                                          formik.setFieldValue(
                                            "prizes",
                                            newPrizes
                                          );
                                          if (
                                            !formik.values.isEditing
                                          ) {
                                            formik.setFieldValue(
                                              "isEditing",
                                              true
                                            );
                                          }
                                        }}
                                        classes={{
                                          root: classes.selectRoot,
                                        }}
                                        disabled={
                                          isPublished ||
                                          isSubmitting
                                        }
                                      >
                                        <MenuItem value={1}>
                                          {getLang(
                                            lang,
                                            "label.ITEM"
                                          )}
                                        </MenuItem>
                                        <MenuItem value={3}>
                                          {getLang(
                                            lang,
                                            "label.REDEMPTION_CODE"
                                          )}
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box> */}
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      className={classes.fieldLabel}
                                    >
                                      {getLang(lang, "label.QUANTITY")}
                                    </Typography>
                                    <TextField
                                      {...textFieldProps}
                                      {...formik.getFieldProps(
                                        `prizes[${index}][${itemIndex}].quantity`
                                      )}
                                      type="number"
                                      name="prizeQuantity"
                                      value={item.quantity}
                                      onChange={(e) => {
                                        const newPrizes = Array.from(
                                          formik.values.prizes
                                        );
                                        newPrizes[index][itemIndex].quantity =
                                          e.target.value === ""
                                            ? ""
                                            : Number(e.target.value);
                                        formik.setFieldValue(
                                          "prizes",
                                          newPrizes
                                        );
                                        if (!formik.values.isEditing) {
                                          formik.setFieldValue(
                                            "isEditing",
                                            true
                                          );
                                        }
                                      }}
                                      error={
                                        formik.touched.prizes?.[index]?.[
                                          itemIndex
                                        ]?.quantity &&
                                        formik.errors.prizes?.[index]?.[
                                          itemIndex
                                        ]?.quantity
                                      }
                                      helperText={ErrorMessage({
                                        name: `prizes[${index}][${itemIndex}].quantity`,
                                      })}
                                      disabled={!isEditable || isSubmitting}
                                      inputProps={{
                                        style: { color: "black" },
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Box flex={1}>
                                {[
                                  item.title,
                                  `${item.quantity} pc(s)`,
                                  // item.prizeType === 1
                                  //   ? getLang(lang, "label.ITEM")
                                  //   : getLang(lang, "label.REDEMPTION_CODE"),
                                ].join(" • ")}
                              </Box>
                            )}
                            <IconButton
                              aria-label="expand"
                              onClick={(e) => {
                                const newPrizes = Array.from(
                                  formik.values.prizes
                                );
                                newPrizes[index][itemIndex].collapsed =
                                  !Boolean(item.collapsed);
                                formik.setFieldValue("prizes", newPrizes);
                              }}
                            >
                              {Boolean(item.collapsed) ? (
                                <ExpandMoreRoundedIcon />
                              ) : (
                                <ExpandLessRoundedIcon />
                              )}
                            </IconButton>
                          </Box>
                          {!Boolean(item.collapsed) && (
                            <Box mt={2}>
                              <Divider />
                              <Box pt={2} display="flex" alignItems="center">
                                <ErrorSVG />
                                <Typography
                                  variant="caption"
                                  style={{
                                    paddingLeft: "4px",
                                    color: "rgba(58, 77, 84, 0.33)",
                                  }}
                                >
                                  {getLang(
                                    lang,
                                    "paragraph.RECOMMENDED_IMAGE_SIZE_3"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        {isEditable && (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              const newPrizes = Array.from(
                                formik.values.prizes
                              );
                              const deletedUuids = Array.from(
                                formik.values.deletedPrizeUuids
                              );

                              if (Boolean(item.uuid)) {
                                deletedUuids.push(item.uuid);
                              }

                              formik.setFieldValue(
                                "deletedPrizeUuids",
                                deletedUuids
                              );

                              newPrizes[index] = newPrizes[index].filter(
                                (p) => p.id !== item.id
                              );
                              formik.setFieldValue("prizes", newPrizes);

                              if (!formik.values.isEditing) {
                                formik.setFieldValue("isEditing", true);
                              }
                            }}
                            disabled={!isEditable || isSubmitting}
                          >
                            <DeleteOutlinedIcon style={{ color: "#ff1515" }} />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
          {isEditable && (
            <Box ml={7} mb={2.5}>
              <Button
                variant="text"
                startIcon={<AddIcon color="secondary" />}
                onClick={() => {
                  const newPrizes = [...formik.values.prizes];
                  newPrizes[index] = [
                    ...newPrizes[index],
                    {
                      id: `item-${Date.now()}`,
                      title: "",
                      imgUrl: null,
                      quantity: 1,
                      prizeType: 1,
                    },
                  ];
                  formik.setFieldValue("prizes", newPrizes);
                  if (!formik.values.isEditing) {
                    formik.setFieldValue("isEditing", true);
                  }
                }}
                style={{
                  textTransform: "none",
                }}
                disabled={!isEditable || isSubmitting}
              >
                <Typography color="secondary" variant="body2">
                  <b>{getLang(lang, "label.NEW_PRIZE")}</b>
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
