import axios from "axios";

const drawLuckyDraw = ({ uuid, prizeUuid }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/campaignV2/customer/luckyDrawSession/draw`, {
        luckyDrawUuid: uuid,
        luckyDrawPrizeUuid: prizeUuid,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default drawLuckyDraw;
