import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Divider,
  Button,
  Link,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DefaultImg from "assets/img/img-default.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Input from "components/input/inputTextField";
import { Formik } from "formik";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { useHistory } from "react-router-dom";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    padding: "20px 16px",
    borderRadius: "8px !important",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  list: {
    maxHeight: "45vh",
    overflowY: "auto",
    padding: "16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function BoxProductListPanelsComponent({
  products,
  totalProduct,
  isFetching,
  handleSearch,
  lang,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper elevation={0}>
      {!isFetching ? (
        <Box p={0.5} pb={1.5}>
          <Box px={2} pt={2}>
            <Typography variant="body1">
              <b>
                {totalProduct.toLocaleString("en-US")} {getLang(lang, "label.PRODUCTS")}
              </b>
            </Typography>
          </Box>
          <Formik
            initialValues={{
              sn: "",
            }}
            onSubmit={handleSearch}
            onReset={handleSearch}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box p={2} className={classes.searchContainer}>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(
                      lang,
                      "placeholder.SEARCH_SERIAL_NUMBER"
                    )}
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#eceff0",
                      },
                      classes: { input: classes.inputRootProduct },
                    }}
                    {...formik.getFieldProps("sn")}
                  />
                  <Button
                    variant="text"
                    disableElevation
                    color="primary"
                    size="small"
                    onClick={formik.handleReset}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.SEARCH")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Box className={classes.list}>
            {products.length ? (
              products.map((item, index) => (
                <Accordion
                  key={item.id}
                  defaultExpanded={false}
                  style={{ marginTop: index ? 16 : 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.productContainer}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flex={1}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 10 }}>
                          <b>{index + 1}.</b>
                        </Typography>
                        <Box className={classes.productPhoto} mr={2} ml={1}>
                          {item?.picture === "" ? (
                            <img src={DefaultImg} alt="emptyProduct" />
                          ) : (
                            <img src={item.picture} alt={item.name} />
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="flex-start"
                          pl={1}
                          ml={1}
                        >
                          <Typography variant="body1">
                            <b>{item.name}</b>
                          </Typography>
                          {!!item.attributes && !!item.attributes.length && (
                            <Typography
                              className={classes.attributeText}
                              variant="body2"
                            >
                              {item.attributes.join(", ")}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Typography variant="body1">
                        {item.quantity.toLocaleString("en-US")}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box px={5} py={2}>
                      {item.packages.map((p, i) => (
                        <Box key={i}>
                          <Box>
                            <Box>
                              {!!p.children?.length ? (
                                <Link
                                  href={`${ROOT}/inventory/package/${p.uuid}`}
                                  color="secondary"
                                  variant="body1"
                                  onClick={(e) => {
                                    history.push(
                                      `${ROOT}/inventory/package/${p.uuid}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  {i + 1}. {p.code}
                                </Link>
                              ) : (
                                <Typography variant="body1">
                                  {i + 1}. {p.code}
                                </Typography>
                              )}
                            </Box>
                            <Box px={3.5} mt={1}>
                              {p.children.map((child, idx) => (
                                <Box mb={0.25} key={idx}>
                                  {child.type === "Pack" ? (
                                    <Link
                                      href={`${ROOT}/inventory/package/${child.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${ROOT}/inventory/package/${child.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography>{child.code}</Typography>
                                    </Link>
                                  ) : (
                                    <>
                                      <Typography
                                        style={{ marginRight: 3 }}
                                        display="inline"
                                        color="textSecondary"
                                      >
                                        {child.code}
                                      </Typography>
                                      {child.type === "Loose Item" && (
                                        <Typography
                                          display="inline"
                                          color="textSecondary"
                                        >
                                          (
                                          {child.quantity?.toLocaleString(
                                            "en-US"
                                          ) || 0}
                                          )
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                          {i !== item.packages.length - 1 && (
                            <Box my={2}>
                              <Divider />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Box p={5} display="flex" justifyContent="center">
                <Typography variant="body1">
                  <b>{getLang(lang, "paragraph.NO_PRODUCT_EXIST")}</b>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box p={2}>
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
        </Box>
      )}
    </Paper>
  );
}
